import compileReducers from 'services/compile-reducer';
import * as actions from './action-names';

export const initialState = {
  isConnected: true
};

const reducers = {};

reducers[actions.NETWORK_MOBILE_CONNECTED] = (state) => ({
  ...state,
  isConnected: true
});

reducers[actions.NETWORK_MOBILE_DISCONNECTED] = (state) => ({
  ...state,
  isConnected: false
});

export default compileReducers(reducers, initialState);
