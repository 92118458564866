import compileReducers from 'services/compile-reducer';
import {
  PROFILE_MISSING,
  PROFILE_STATUSRESET,
  PROFILE_REQUESTED,
  PROFILE_LOADED,
  PROFILE_LOADERROR,
  PROFILE_SAVEREQUESTED,
  PROFILE_SAVED,
  PROFILE_NEWOTHERSAVED,
  PROFILE_SELFCREATED,
  PROFILE_SAVEERROR,
  PROFILE_LISTREQUESTED,
  PROFILE_LISTLOADED,
  PROFILE_LISTERROR,
  PROFILE_ONBOARDED,
  PROFILE_REMOVED,
  PROFILE_UPDATED,
  PROFILE_PREFERENCES_LOAD,
  PROFILE_DATA_UPDATED
} from './action-names';

const initialState = {
  loading: true,
  loadError: false,
  saving: false,
  saved: false,
  saveError: false,
  loadingList: false,
  listError: false,
  self: {},
  list: [],
  profileMissing: false,
  profilePreferences:{}
};

const reducers = {};

reducers[PROFILE_MISSING] = state => ({
  ...state,
  loading: false,
  loadError: false,
  profileMissing: true
});

reducers[PROFILE_STATUSRESET] = state => ({
  ...state,
  saved: false,
  saveError: false
});

reducers[PROFILE_REQUESTED] = state => ({
  ...state,
  loading: true,
  loadError: false,
  profileMissing: false
});

reducers[PROFILE_LOADED] = (state, profile) => ({
  ...state,
  self: profile,
  loading: false,
  loadError: false,
  profileMissing: false
});

reducers[PROFILE_LOADERROR] = (state, message) => ({
  ...state,
  loading: false,
  loadError: true,
  errorMessage: message
});

reducers[PROFILE_SAVEREQUESTED] = state => ({
  ...state,
  saved: false,
  saveError: false,
  saving: true
});

reducers[PROFILE_SAVED] = (state, profile) => {
  const self = profile.id === state.self.id ? profile : state.self;

  const list = state
    .list
    .map(x => (x.id === profile.id ? profile : x));

  return {
    ...state,
    self,
    saveError: false,
    saving: false,
    saved: true,
    list
  };
};

reducers[PROFILE_NEWOTHERSAVED] = (state, profile) => ({
  ...state,
  saveError: false,
  saving: false,
  saved: true,
  list: [...state.list, { ...profile, isNewProfile: true }]
});

reducers[PROFILE_SELFCREATED] = (state, profile) => ({
  ...state,
  saveError: false,
  saving: false,
  saved: true,
  list: [...state.list, { ...profile, isNewProfile: true }],
  self: profile,
  profileMissing: false
});

reducers[PROFILE_SAVEERROR] = (state, message) => ({
  ...state,
  saving: false,
  saved: false,
  saveError: true,
  errorMessage: message
});

reducers[PROFILE_LISTREQUESTED] = state => ({
  ...state,
  loadingList: true,
  listError: false
});

reducers[PROFILE_LISTLOADED] = (state, profiles) => ({
  ...state,
  loadingList: false,
  listError: false,
  list: profiles,
  profileMissing: state.profileMissing || profiles.length === 0
});

reducers[PROFILE_LISTERROR] = (state, message) => ({
  ...state,
  loadingList: false,
  listError: true,
  errorMessage: message
});

reducers[PROFILE_ONBOARDED] = (state, profileId) => {
  const list = state
    .list
    .map(profile => (profile.id === profileId ? {
      ...profile,
      isNewProfile: false
    } : profile));

  return {
    ...state,
    list
  };
};

reducers[PROFILE_REMOVED] = (state, profileId) => {
  const list = state.list.filter(x => x.id !== profileId);
  return {
    ...state,
    list
  };
};

reducers[PROFILE_UPDATED] = (state, profile) => {
  const self = profile.id === state.self.id ? profile : state.self;

  const list = state
    .list
    .map(x => (x.id === profile.id ? profile : x));

  return {
    ...state,
    self,
    list
  };
};

reducers[PROFILE_PREFERENCES_LOAD] = (state,payload) =>{
  return {
    ...state,
    profilePreferences: payload
  };
};

reducers[PROFILE_DATA_UPDATED] = (state, payload) => {
  const self = payload.profileId === state.self.id ? {...state.self, ...payload.profileData} : state.self;

  const list = state
    .list
    .map(x => (x.id === payload.profileId ? {...x, ...payload.profileData} : x));

  return {
    ...state,
    self,
    list
  };
};

export default compileReducers(reducers, initialState);
