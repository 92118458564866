import React from 'react';
import { useSelector } from 'react-redux';
import local from 'services/localization/local';
import { CardList } from 'shared';
import { getRelationshipsWithAccess } from 'profiles/sharing/sharing-selectors';
import CurrentAccessListItem from './CurrentAccessListItem';

export default function CurrentAccessList() {
  const inviteStatuses = useSelector(getRelationshipsWithAccess);

  return (
    <div>
      {inviteStatuses.length > 0 &&
        (
          <div className='list-container'>
            <h5 className='category-header'>{local.sharing.whoHasAccess}</h5>
            <CardList testId="current-access-list">
              {inviteStatuses.map(item => (
                <CurrentAccessListItem key={item.id} item={item} />
              ))}
            </CardList>
          </div>
        )}
    </div>
  );
}
