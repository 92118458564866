import moment from 'moment';
import { compareDatesAscending, getTimeCategory, momentCalendarDayFormat } from 'services/date-service';

export const getGroupedDosesForTodayAndYesterday = (state, profileId) => {
  const doses = getMedicationDoses(state, profileId);

  const doseGroups = doses.reduce((groups, current) => {
    const recordedAt = moment(current.recordedAt);
    const date = recordedAt.clone().startOf('day').format();

    // Null Check
    if (!groups[date]) groups[date] = {};

    const bucket = getTimeCategory(recordedAt);

    // Null Check
    if (!groups[date][bucket]) groups[date][bucket] = [];

    groups[date][bucket].push(current);

    return groups;
  }, { [moment().startOf('day').format()]: {} });

  // Convert to arrays
  const doseGroupArrays = Object.keys(doseGroups).map(date => ({
    date: date,
    display: moment(date).calendar(null, momentCalendarDayFormat),
    values: Object.keys(doseGroups[date]).map(time => {
      return {
        category: time,
        values: doseGroups[date][time]
      };
    })
  })).sort((a, b) => compareDatesAscending(a.date, b.date));

  return doseGroupArrays;
};

export const getMedicationDoses = (state, profileId) => {
  const doses = state.medicationDose.doses.filter(x => !x.deleted && x.profileId === profileId);
  return doses.sort((x, y) => compareDatesAscending(x.recordedAt, y.recordedAt));
};

const getFilteredMedicationDoses = (state, profileId, date) => {
  let doses = getMedicationDoses(state, profileId);

  if (date) {
    const startOfDate = moment(date).startOf('day');
    const endOfDate = moment(date).endOf('day');

    doses = doses.filter(x => {
      const recordedAtMoment = moment(x.recordedAt);
      if (startOfDate <= recordedAtMoment && recordedAtMoment <= endOfDate) {
        return true;
      }
      return false;
    });
  }

  const medicationId = state.medicationDose.filter.medicationId;
  if (medicationId) {
    doses = doses.filter(x => x.medicationId === medicationId);
  }

  return doses;
};

export const getGroupedMedicationDoses = (state, profileId) => {
  const doses = getFilteredMedicationDoses(state, profileId, state.medicationDose.filter.date);

  let categoryIndex = 0;
  const output = doses.reduce((doseCategoryArray, x) => {
    const category = getTimeCategory(moment(x.recordedAt));
    let activeCategory = doseCategoryArray[categoryIndex];

    if (activeCategory && activeCategory.category === category) {
      activeCategory.values.push(x);
      return doseCategoryArray;
    }

    if (activeCategory && activeCategory.category !== category) {
      categoryIndex += 1;
    }

    doseCategoryArray[categoryIndex] = { category, values: [x] };

    return doseCategoryArray;
  }, []);

  return output;
};

export const dateHasDoseData = (state, profileId, date) => {
  const doses = getFilteredMedicationDoses(state, profileId, date);
  return doses.length > 0;
};

export const getFilteredMedicationName = (state) => {
  const medicationId = state.medicationDose.filter.medicationId;
  if (medicationId) {
    const medication = state.medication.medications.find(x => x.id === medicationId);
    return medication && medication.name;
  }
  return undefined;
};

export const getGroupedMedicationOverdueAndTakeNowDoses = (state) => {
  if(state.medicationDose?.medicationNotificationDoses?.medicationNotifications) {
    return getGroupedMedicationNotification(getFilteredMedicationNotifications(state,false));
  }
};

export const getTodayMedicationOverdueAndTakeNowDoses = (state) => {
  if(state.medicationDose?.medicationNotificationDoses?.medicationNotifications) {
    let mediacationNotifications = getFilteredMedicationNotifications(state,false);

    mediacationNotifications.sort((d1, d2) => {
      const compareDate = compareDatesAscending(d1.scheduledAt, d2.scheduledAt);
      const compareName = d1.name.localeCompare(d2.name);
      return compareDate ||  compareName;
    });
    return mediacationNotifications;
  }
};

export const getLoggedMedicationDoses = (state) => {
  if(state.medicationDose?.medicationNotificationDoses?.medicationNotifications){
    return getFilteredMedicationNotifications(state,true);
  }
};

export const getGroupedMedicationNotification = (doses)=> {
  let categoryIndex = 0;
  const output = doses.reduce((doseCategoryArray, x) => {
    const category = moment(x.scheduledAt).format('hh:mm A');
    let activeCategory = doseCategoryArray[categoryIndex];

    if (activeCategory && activeCategory.category === category) {
      activeCategory.values.push(x);
      return doseCategoryArray;
    }

    if (activeCategory && activeCategory.category !== category) {
      categoryIndex += 1;
    }

    let [isOverdue,overdueBy,isTakenow] =  getDoseStatus(x);

    doseCategoryArray[categoryIndex] = { category, values: [x], isOverdue : isOverdue, overdueBy : overdueBy, isTakenow : isTakenow};

    return doseCategoryArray;
  }, []);

  return output;
};

export const getFilteredMedicationNotifications = (state, isLoggedAndSkippedDoses) => {

  const doses = state.medicationDose?.medicationNotificationDoses?.medicationNotifications.filter(x =>
    isLoggedAndSkippedDoses ? (x.isMedicated || x.isSkipped) : (!x.isMedicated && !x.isSkipped));
  return doses.sort((x, y) => isLoggedAndSkippedDoses ?  compareDatesAscending(y.scheduledAt, x.scheduledAt) : compareDatesAscending(x.scheduledAt, y.scheduledAt));
};

export const getDoseStatus =(dose) =>{

  var isOverdue = moment(dose.scheduledAt).isBefore();

  if(!isOverdue)
    return [isOverdue, 0, false];

  var now = moment(new Date());

  var timeDifferenceInHours = moment.duration(now.diff(moment(dose.scheduledAt))).asHours();

  if(timeDifferenceInHours > 1)
    return [isOverdue,moment(dose.scheduledAt).fromNow(true),false];
  else{
    return [!isOverdue,0,true];
  }
};
