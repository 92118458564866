/* eslint-disable react/jsx-no-leaked-render */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, LinkWrapper } from 'shared';
import { routePaths } from 'services/routing';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveNutritionGoal } from './nutrition-selector';
import local from 'services/localization/local';
import { nutritionSaved } from 'app/request/request-actions';


function NutritionGoal({profileId}) {
  const goal = useSelector(state => getActiveNutritionGoal(state, profileId));
  const content = local.observation.nutritions;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(nutritionSaved(false));
  }, []);

  return (
    <div className='set-nutri-goal'>
      <Button id={goal ? 'change-nutrition-goal' : 'add-nutrition-goal'} color="blue" className="btn btn-primary">
        <LinkWrapper id={goal ? 'change-nutrition-goal-id' : 'add-nutrition-goal-id'} to={routePaths.nutritionGoal}>{goal ? content.changeGoal : content.addGoal}</LinkWrapper>
      </Button>
      {goal &&
      <div className='targetLabel'>
        <div><span>{content.currentGoal} </span> <span className='bold'>{goal.calories} {content.cal}</span></div>
        <div>
          <span className='bold'>{goal.carbs.toFixed(0)}%</span> <span>{content.carbsGoal} | </span>
          <span className='bold'>{goal.fat.toFixed(0)}%</span> <span>{content.fatGoal} | </span>
          <span className='bold'>{goal.protein.toFixed(0)}%</span> <span>{content.proteinGoal} </span>
        </div>
      </div>}
    </div>
  );
}

NutritionGoal.propTypes = {
  profileId: PropTypes.string.isRequired
};

export default NutritionGoal;
