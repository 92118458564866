import calculationLibrary from '@healthchampion/calculation-library';
import { logException } from 'services/appinsights-service';

export function evaluateCondition(condition, variables) {
  try {
    return calculationLibrary.evaluateCondition(condition, variables);
  }
  catch (exception) {
    logException(`Error evaluating condition '${condition}' - '${exception}'`);
    return false;
  }
}
