import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import local from 'services/localization/local';
import { loadInterview } from 'interviews/interview-actions';
import { completeTask, uncompleteTask, completeInterviewTask, checkResource, openInAppUrl, saveInterviewTaskDraftAnswers,currentEnrollmentTaskDetails } from 'programs/programs-actions';
import { ExternalLink, Icon, ProfileLink, LinkWrapper, ProgressBar } from 'shared';
import { routePaths } from 'services/routing';
import { TaskType, ObservationType } from 'services/enums';
import{noImmunizationPathwayId} from 'app/config';
import { redirectFromImmunizationsDisable, redirectFromImmunizationsEnable } from 'health/actions';
import { Redirect } from 'react-router-dom';
import { isMobile } from 'mobile/helpers';

class ProgramTask extends React.Component {
  constructor(props) {
    super(props);
    this.activateTask = this.activateTask.bind(this);
    this.getDate = this.getDate.bind(this);
    this.getTaskClass = this.getTaskClass.bind(this);
    this.renderTaskContent = this.renderTaskContent.bind(this);
  }
  componentDidMount()
  {
    const { task,programId, profileId, programEnrollmentId, actions , redirectFromImmunization } = this.props;
    if(programId === noImmunizationPathwayId && redirectFromImmunization && !task.completed)
    {
      actions.loadInterview(task.interviewId, answers => completeInterviewTask(task.programEnrollmentTaskId, programEnrollmentId, task.interviewId, answers, profileId,true), task.programEnrollmentTaskId, undefined, inappURL => openInAppUrl(inappURL), isMobile);
      //actions.redirectFromImmunizationsEnable();
      //return <Redirect to={routePaths.healthImmunizations} />;
    }
  }

  activateTask() {
    const { task, profileId, programEnrollmentId, actions,programId } = this.props;

    if (task.taskType === TaskType.Checklist) {
      if (task.isConditionallyCompletable === true) {
        return;
      }

      if (task.completed) {
        actions.uncompleteTask(task.programEnrollmentTaskId, programEnrollmentId, profileId);
      } else {
        actions.completeTask(task.programEnrollmentTaskId, programEnrollmentId, profileId);
      }
    } else if (task.taskType === TaskType.Interview) {
      actions.loadInterview(task.interviewId, answers => completeInterviewTask(task.programEnrollmentTaskId, programEnrollmentId, task.interviewId, answers, profileId,false), task.programEnrollmentTaskId, data => saveInterviewTaskDraftAnswers(task.programEnrollmentTaskId, task.interviewId, data, profileId),  inappURL => openInAppUrl(inappURL), isMobile);
    } else if (task.taskType === TaskType.Resource) {
      actions.checkResource(task.resourceId, programEnrollmentId);
    } else if (task.taskType === TaskType.Goal) {
      actions.currentEnrollmentTaskDetails(task.programEnrollmentTaskId,programEnrollmentId ,programId, this.props.task );
    }
  }

  getDate() {
    const { task: { completed, overdue, unavailable, availableDateTime, dueDateTime, completedTime } } = this.props;

    let date = dueDateTime;
    let message = local.programs.taskListDueBy;
    let icon = 'calendarcheck';
    let classname = 'due';

    if (completed) {
      date = completedTime;
      message = local.programs.taskListCompleted;
    } else if (unavailable) {
      date = availableDateTime;
      message = local.programs.taskListNextAt;
    } else if (!dueDateTime) {
      return null;
    } else if (overdue) {
      classname = 'due overdue';
      icon = 'calendaralert';
    } else {
      date = dueDateTime;
    }

    const format = date ? `${local.simpleDateFormat} ${date.minute() || date.hour() ? local.simpleTimeFormat : ''}` : '';
    const dateString = format ? local.formatString(message, date.format(format)) : '';
    return (
      <small className={classname}>
        <Icon symbol={icon} size="1.5em" />

        {dateString}
      </small>
    );
  }

  getTaskClass() {
    const { task: { completed, updating, unavailable }, enabled } = this.props;

    if (updating) {
      return 'updating';
    } else if (completed) {
      return 'completed';
    } else if (unavailable) {
      return 'unavailable';
    } else if (enabled) {
      return 'available';
    } else {
      return 'unavailable';
    }
  }

  getStatusSpan() {
    const { task: { isConditionallyCompletable, taskType, actionUrl } } = this.props;

    if (isConditionallyCompletable === true) {
      if (taskType === TaskType.Checklist && !actionUrl) {
        return <span className='empty-status' />;
      }
    }

    return <span className='status' />;
  }

  renderTaskContent() {
    const { task: { name, quantity, description, percentageComplete }, hideDescription } = this.props;
    let hideHRVValue = false;
    return (
      <React.Fragment>
        {this.getStatusSpan()}

        <div className='task-contents'>
          <div className='task-name'>{name} {quantity > 1 ? `(${quantity})` : ''}</div>
          {name === 'HRV' ? hideHRVValue = true : hideHRVValue = false }

          {percentageComplete == null ? null : <ProgressBar primaryProgress={percentageComplete} />}

          {!hideDescription && !hideHRVValue && (<small>{description}</small>)}

          {this.getDate()}
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { programId, programEnrollmentId, enabled, task: { programEnrollmentTaskId, taskType, observationType, actionUrl, completed, updating, unavailable, name } } = this.props;

    const taskClassName = `task with-padding ${this.getTaskClass()}`;

    if (updating || unavailable || !enabled) {
      return (
        <a className={taskClassName}>
          {this.renderTaskContent()}
        </a>
      );
    } else if (taskType === TaskType.Resource) { // Resource external link
      return (
        <div onClick={this.activateTask}>
          <ExternalLink
            id={`task-resource-${programEnrollmentTaskId}`}
            logName={`program-task-${taskType}`}
            className={taskClassName}
            openNewTab
            href={actionUrl}>
            {this.renderTaskContent()}
          </ExternalLink>
        </div>
      );
    } else if (actionUrl) { // Checklist internal link
      return (
        <div onClick={this.activateTask}>
          <LinkWrapper
            id={`task-internallink-${programEnrollmentTaskId}`}
            logName={`program-task-${taskType}`}
            className={taskClassName}
            to={`${actionUrl}`}>
            {this.renderTaskContent()}
          </LinkWrapper>
        </div>
      );
    } else if (taskType === TaskType.Observation) { // Observation internal link
      const route = completed ? routePaths.programsViewData : routePaths.programsRecordData;
      const isDisableRoute = name === 'HRV' || (observationType === ObservationType.Exercise && completed) ? true : false;
      const finalRoute = !isDisableRoute ? route: '';
      const action = completed ? 'view' : 'record';
      const params = {
        programId,
        programEnrollmentId,
        observationType,
        taskId: programEnrollmentTaskId
      };
      return (
        <ProfileLink
          id={`${action}-observation-${programEnrollmentTaskId}`}
          logName={`program-task-${action}-observation`}
          className={taskClassName}
          to={finalRoute}
          params={params}>
          {this.renderTaskContent()}
        </ProfileLink>
      );
    }
    else if( taskType === TaskType.Goal){
      const route = routePaths.stepTarget;
      return (
        <div onClick={this.activateTask}>
          <LinkWrapper
            id={`task-setgoal-${programEnrollmentTaskId}`}
            logName={`program-task-${taskType}`}
            className={taskClassName}
            to={`${route}`}>
            {this.renderTaskContent()}
          </LinkWrapper>
        </div>
      );
    }
    else {
      return (
        <React.Fragment>
          <a className={taskClassName} onClick={this.activateTask}>
            {this.renderTaskContent()}
          </a>

          {(programId === noImmunizationPathwayId && <Redirect to={routePaths.healthImmunizations} />)}
        </React.Fragment>
      );
    }
  }
}

ProgramTask.propTypes = {
  profileId: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
  programEnrollmentId: PropTypes.string.isRequired,
  redirectFromImmunization:PropTypes.bool,
  task: PropTypes.shape({
    completed: PropTypes.bool.isRequired,
    unavailable: PropTypes.bool.isRequired,
    overdue: PropTypes.bool.isRequired,
    description: PropTypes.string,
    interviewId: PropTypes.string,
    name: PropTypes.string.isRequired,
    observationType: PropTypes.number,
    programEnrollmentTaskId: PropTypes.string.isRequired,
    resourceId: PropTypes.string,
    taskType: PropTypes.number.isRequired,
    actionUrl: PropTypes.string,
    availableDateTime: PropTypes.shape(),
    completedTime: PropTypes.shape(),
    dueDateTime: PropTypes.shape(),
    quantity: PropTypes.number,
    updating: PropTypes.bool,
    percentageComplete: PropTypes.number,
    isConditionallyCompletable: PropTypes.bool,
    hasObservationMetric: PropTypes.bool
  }),
  enabled: PropTypes.bool,
  hideDescription: PropTypes.bool,
  actions: PropTypes.shape({
    completeTask: PropTypes.func.isRequired,
    uncompleteTask: PropTypes.func.isRequired,
    checkResource: PropTypes.func.isRequired,
    loadInterview: PropTypes.func.isRequired,
    saveInterviewTaskDraftAnswers: PropTypes.func.isRequired,
    currentEnrollmentTaskDetails: PropTypes.func.isRequired,
    redirectFromImmunizationsDisable:PropTypes.func,
    redirectFromImmunizationsEnable:PropTypes.func
  }).isRequired
};

ProgramTask.defaultProps = {
  hideDescription: false,
  enabled: true
};

function mapStateToProps(state) {
  return {
    redirectFromImmunization:state.health.redirectFromImmunization
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { completeTask, uncompleteTask, checkResource, loadInterview, completeInterviewTask,redirectFromImmunizationsDisable,redirectFromImmunizationsEnable, saveInterviewTaskDraftAnswers, openInAppUrl,currentEnrollmentTaskDetails };
  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgramTask);
