import { defaultStatus } from 'app/request/request-reducer';

export const getRequest = (state, requestId) => {
  return state.request[requestId] || defaultStatus;
};

export const getRequestSuccess = (state, requestId) => {
  return getRequest(state, requestId).success;
};

export const getRequestProcessing = (state, requestId) => {
  return getRequest(state, requestId).processing;
};

export const getRequestError = (state, requestId) => {
  return getRequest(state, requestId).hasError;
};
