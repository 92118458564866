import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loading } from 'shared';
import local from 'services/localization/local';
import { programsContexts } from 'services/routing';
import { setContext, loadProgramEnrollments, loadOrganizationPrograms, loadPendingProgramInvitesCount } from 'programs/programs-actions';
import * as selectors from 'programs/programs-selectors';
import { getIdentityId, getIsEmailVerified } from 'identity/selectors';
import { loadActiveOrganizations, loadOrganizations } from 'organizations/organizations-actions';
import SuggestedPrograms from './SuggestedPrograms';
import ProgramDashboardTile from './ProgramDashboardTile';
import RecommendedProgramTile from './RecommendedProgramTile';
import AllProgramsLink from './AllProgramsLink';
import { getIsNewProfile } from 'profiles/profiles-selectors';
import { profileOnboarded } from 'profiles/actions';
import './program-dashboard.scss';
import { HasCovidOrganization } from 'organizations/organizations-selectors';
import { FullScreenModal } from 'shared/full-screen-modal/FullScreenModal';

export default function ProgramDashboard() {
  const dispatch = useDispatch();
  const identityId = useSelector(getIdentityId);
  const profileId = useSelector(state => state.selectedProfile.id);
  const { loading, loaded } = useSelector(selectors.getEnrollmentsStatus);
  const programEnrollmentIds = useSelector(selectors.getActiveEnrollmentIdsForHome);
  const organizationProgramsStatus = useSelector(selectors.getOrganizationProgramsStatus);
  const recommendedOrganizationPrograms = useSelector(selectors.getRecommendedPrograms);
  const isNew = useSelector(state => getIsNewProfile(state));
  const [retriesSeconds, setRetriesSeconds] = useState(1);
  const { isCovidOrg = isCovidOrg } = useSelector(state => HasCovidOrganization(state));
  const permissionsnotDismissed = useSelector(state => selectors.getActiveUsersProgramInvitesNotDismissed(state));
  const anyProgramPendingInvitesNotDismissed = (permissionsnotDismissed > 0);
  const emailVerified = useSelector(getIsEmailVerified);

  useEffect(() => {
    if (emailVerified) {
      dispatch(loadPendingProgramInvitesCount(identityId));
    }
  }, [identityId]);

  useEffect(() => {
    dispatch(setContext(programsContexts.default));
  }, []);

  useEffect(() => {
    dispatch(loadProgramEnrollments(profileId));
    dispatch(loadOrganizationPrograms(profileId));
  }, [profileId]);

  useEffect(() => {
    dispatch(loadActiveOrganizations());
    dispatch(loadOrganizations());
  }, [identityId]);

  useEffect(() => {
    if (isNew && !loading && loaded) {
      if (programEnrollmentIds.length > 0) {
        dispatch(profileOnboarded(profileId));
      }

      if (programEnrollmentIds.length === 0 && !loading && loaded) {
        setRetriesSeconds(retriesSeconds * 2);
        const timeoutId = setTimeout(() => {
          dispatch(loadProgramEnrollments(profileId));
        }, retriesSeconds * 1000);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [loaded, loading, isNew]);
  if (loaded && programEnrollmentIds.length === 0 && recommendedOrganizationPrograms.length === 0) {
    return (
      <Fragment>
        {emailVerified && anyProgramPendingInvitesNotDismissed ? <FullScreenModal /> : null}
        <SuggestedPrograms />
      </Fragment>
    );
  }

  const showLoadingTile = (loading && !loaded)
    || (organizationProgramsStatus.loading && !organizationProgramsStatus.loaded);

  const loadingTile = (
    <div className="program-dashboard-tile">
      <Loading />
    </div>
  );

  return (
    <section id="program-section">
      <header>
        <span>{local.dashboard.pathways.activeHeader}</span>

        <AllProgramsLink />
      </header>

      {showLoadingTile ? loadingTile : null}

      {loaded && isCovidOrg && programEnrollmentIds.length === 0 && recommendedOrganizationPrograms.length > 0 ? recommendedOrganizationPrograms.map((program) => (
        <RecommendedProgramTile
          key={program.program.id}
          organizationId={program.organizationId}
          program={program.program} />
      )) : null}

      {programEnrollmentIds.map((programEnrollmentId) => (
        <ProgramDashboardTile key={programEnrollmentId} programEnrollmentId={programEnrollmentId} />
      ))}

      {loaded && emailVerified && anyProgramPendingInvitesNotDismissed ?
        <div>
          <FullScreenModal />
        </div> : null}
    </section>
  );
}
