import React from 'react';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { Page } from 'shared';
import CreateOrganizationForm from './CreateOrganizationForm';

export default function CreateOrganizationPage() {
  return (
    <Page
      id="connect-component"
      backPath={{ to: routePaths.people }}
      centerElement={local.organizations.title}
      readOnlyProfile>
      <div data-testid="create-organization-page">
        <div>{local.organizations.createDescription}</div>
        <br />
        <CreateOrganizationForm />
      </div>
    </Page>
  );
}
