import React from 'react';
import moment from 'moment';
import { momentCalendarDateTimeFormat } from 'services/date-service';

export default function TimelineIconDate({ item, Symbol }) {

  return (
    <div className="icon-date-container">
      <Symbol height="1.5em" width="1.5em" />
      <span>{`${moment(item.timestamp).calendar(null, momentCalendarDateTimeFormat)}`}</span>
    </div>
  );
}
