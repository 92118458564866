import moment from 'moment';
import { HIPAA_CONSENT_ERROR_CODE } from 'services/api/api';
import { getUniqueId } from 'services/helpers';
import { requireHipaaConsent } from 'health/actions';
import * as toastActions from 'app/toaster/actions';
import * as requestQueueActions from 'app/request/request-queue-actions';
import * as api from 'services/api/healthdata-api';
import local from 'services/localization/local';
import * as actions from './actions';
import { types } from './medication-types';

export function loadMedications(profileId) {
  return (dispatch, getState) => {
    dispatch(actions.loadMedications());

    const onSuccess = response => {
      dispatch(actions.loadMedicationSuccess(response.results));
    };

    const onError = () => {
      dispatch(actions.loadMedicationError());
    };
    return api.getMedications(profileId, getState())
      .then(onSuccess).catch(onError);
  };
}

export function saveMedication(input) {
  const { id, profileId, timestamp, name, typicalDose, notes,startAt,frequency,schedules } = input;
  const medication = {
    id,
    profileId,
    timestamp: moment(timestamp).format(),
    name,
    typicalDose,
    notes,
    startAt,
    frequency,
    schedules
  };

  return (dispatch, getState) => {
    const state = getState();
    const requestId = getUniqueId();
    dispatch(actions.saveMedication());
    dispatch(toastActions.addProcessingToast(requestId));

    const onSuccess = () => {
      dispatch(actions.saveMedicationSuccess(medication));
      dispatch(toastActions.completeToastWithSuccess(requestId));
    };

    const onError = error => {
      if (error && error.status === HIPAA_CONSENT_ERROR_CODE) {
        const retryAction = () => saveMedication(input);
        dispatch(toastActions.removeToast(requestId));
        dispatch(requireHipaaConsent(profileId, error.body.code, retryAction));
      } else {
        dispatch(toastActions.completeToastWithError(requestId));
      }

      dispatch(actions.saveMedicationError(requestId));
    };

    if (medication.id) {
      return api.putMedication(medication, profileId, state)
        .then(onSuccess).catch(onError);
    }

    return api.postMedication(medication, profileId, state)
      .then(onSuccess).catch(onError);
  };
}

export function deleteMedication({ profileId, medicationId }) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch(actions.deleteMedication(profileId, medicationId));
    };

    const action = state => api.deleteMedication(medicationId, profileId, state)
      .then(onSuccess);

    dispatch(requestQueueActions.queueRequestWithToast(action, types.DELETE, null, {
      processingMessage: local.deleting,
      successMessage: local.deleted
    }, { profileId }));
  };
}
