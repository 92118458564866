import React from 'react';
import PropTypes from 'prop-types';
import { CardList, Card } from 'shared';
import ProgramTile from 'programs/enrollment/ProgramTile';
import ProgramPermissions from 'programs/permisssions/ProgramPermissions';
import local from 'services/localization/local';

export default function RecommendedProgram({ organizationProgram, organization }) {
  if (!organizationProgram || !organization) {
    return null;
  }

  const { organizationId, program } = organizationProgram;

  return (
    <CardList testId='recommended-program-info'>
      <Card>
        <div className='consent'>
          {local.formatString(local.organizations.dataShared, (<strong>{organization.name}</strong>))}
        </div>
        <ProgramPermissions programId={program.programId} hasProperties />
      </Card>
      <Card>
        <ProgramTile
          program={program}
          organizationId={organizationId}
          showHeroImage
          showEnroll
          showDescription />
      </Card>
    </CardList>
  );
}

RecommendedProgram.propTypes = {
  organizationProgram: PropTypes.shape({
    organizationId: PropTypes.string,
    program: PropTypes.shape()
  }),
  organization: PropTypes.shape({
    name: PropTypes.string
  })
};
