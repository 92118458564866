import React, { Component } from 'react';
import { routePaths } from 'services/routing';
import { ModalWrapper } from 'shared';
import local from 'services/localization/local';
import { NavLink } from 'react-router-dom';
import './changeEmailSignout.scss';
import Logo from 'shared/logos/inline/HcLogoInline';
import { isMobile, openMobileRoute } from 'mobile/helpers';
import { signOut } from 'services/auth0-service';

class ChangeEmailSignOut extends Component {
  constructor(props)
  {
    super(props);
  }
  componentDidMount(){
    setTimeout(()=>{
      if (isMobile) {
        openMobileRoute('signout');
      } else {
        signOut();
      }
    },10000);
  }
  render(){
    return (
      <ModalWrapper isOpen>
        <div id="error-message-modal-changeEmail" data-testid="verified-email" className="modal-container signOutModal">
          <div className="modal-body">
            <div className="signoutLogo"><Logo /></div>
            <div className="verify-successMsg">
              <img src="/static/images/signOut_Success.svg" />
              <div className="error-msg-title">
                <b>{local.formatString(local.accountSettings.confirmMessageFirst, this.props.email ?? '')}</b>
                <br />
                {local.formatString(local.accountSettings.confirmMessageThree, this.props.email ?? '')}
              </div>
              <br></br>
              <p className="infoText"><small>{local.accountSettings.confirmMessageSecond}</small></p>
              <NavLink id="more-signout" className='signOutModalBtn blue btn btn-primary' to={routePaths.signout}>
                <span>{local.linkSignout}</span>
              </NavLink>
            </div>
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

export default ChangeEmailSignOut;
