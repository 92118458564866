import * as  persistentDataReducer from 'app/request/persistent-data-reducer';
import compileReducers from 'services/compile-reducer';
import { ObservationType } from 'services/enums';
import * as actionNames from './action-names';
import { types } from './health-types';

export const stateKeyMap = Object.keys(ObservationType).reduce((map, key) => {
  // the observationType enum int becomes the key and the name becomes the value, ignoring the 0
  if (ObservationType[key]) {
    map[ObservationType[key]] = `${key.charAt(0).toLowerCase()}${key.slice(1)}`;
  }
  return map;
}, {});

const dataState = {
  data: [],
  loading: false,
  loadError: false,
  saving: false,
  saved: false,
  saveError: false,
  selectedId: null
};

const defaultObservationDataStates = Object.values(stateKeyMap)
  .reduce((all, stateName) => ({ ...all, [stateName]: { ...dataState } }), {});

export const initialState = {
  [types.CONDITIONS]: persistentDataReducer.initialState,
  [types.ENCOUNTERS]: persistentDataReducer.initialState,
  [types.CARE_PLAN]: persistentDataReducer.initialState,
  [types.IMMUNIZATIONS]: persistentDataReducer.initialState,
  [types.LAB_RESULTS]: persistentDataReducer.initialState,
  [types.PROCEDURES]: persistentDataReducer.initialState,
  [types.IMPORT_COUNTS]: persistentDataReducer.initialState,
  [types.VACCINATION_PROOF]: persistentDataReducer.initialState,
  [types.TRACK_STEPS]: persistentDataReducer.initialState,
  [types.CURRENT_TARGET]: persistentDataReducer.initialState,
  [types.DASHBOARD_OBSERVATIONS]: persistentDataReducer.initialState,
  [types.HEALTH_DATA_VITALS_OBSERVATIONS]: persistentDataReducer.initialState,
  [types.HIGH_FREQUENCY_OBSERVATIONS]: persistentDataReducer.initialState,
  [types.DASHBOARD_HEALTH_RECORDS]: persistentDataReducer.initialState,
  [types.HEALTH_DATA_RECORDS]: persistentDataReducer.initialState,
  [types.HEALTH_SCORE_TRENDS]: persistentDataReducer.initialState,
  [types.LAB_TRENDS]: persistentDataReducer.initialState,
  [types.NUTRITION_GOALS]: persistentDataReducer.initialState,
  [types.GLUCOSE_GOALS]: persistentDataReducer.initialState,
  [types.LAB_ATTACHMENTS]: persistentDataReducer.initialState,
  [types.UNREAD_MEDICAL_RECORDS_COUNT]: persistentDataReducer.initialState,
  [types.RESET_UNREAD_MEDICAL_RECORDS_COUNT]: persistentDataReducer.initialState,
  [types.SYSTOLIC_GOALS]: persistentDataReducer.initialState,
  [types.DIASTOLIC_GOALS]: persistentDataReducer.initialState,
  [types.PATHWAY_HEALTH_RECORDS]: persistentDataReducer.initialState,
  healthScore: null,
  healthScoreLoading: false,
  healthScoreNotEnoughInfo: false,
  healthScoreError: false,
  requireHipaaConsent: false,
  requireHipaaConsentType: null,
  requireHipaaConsentProfileId: null,
  requireHipaaConsentRetry: null,
  ...defaultObservationDataStates,
  redirectFromImmunization:false,
  highFrequencyData:false
};

const reducers = {
  ...persistentDataReducer.reducers(types.CONDITIONS),
  ...persistentDataReducer.reducers(types.ENCOUNTERS),
  ...persistentDataReducer.reducers(types.IMMUNIZATIONS),
  ...persistentDataReducer.reducers(types.LAB_RESULTS),
  ...persistentDataReducer.reducers(types.PROCEDURES),
  ...persistentDataReducer.reducers(types.IMPORT_COUNTS),
  ...persistentDataReducer.reducers(types.VACCINATION_PROOF),
  ...persistentDataReducer.reducers(types.TRACK_STEPS),
  ...persistentDataReducer.reducers(types.CURRENT_TARGET),
  ...persistentDataReducer.reducers(types.DASHBOARD_OBSERVATIONS),
  ...persistentDataReducer.reducers(types.DASHBOARD_HEALTH_RECORDS),
  ...persistentDataReducer.reducers(types.HEALTH_DATA_VITALS_OBSERVATIONS),
  ...persistentDataReducer.reducers(types.HIGH_FREQUENCY_OBSERVATIONS),
  ...persistentDataReducer.reducers(types.HEALTH_DATA_RECORDS),
  ...persistentDataReducer.reducers(types.HEALTH_SCORE_TRENDS),
  ...persistentDataReducer.reducers(types.CARE_PLAN),
  ...persistentDataReducer.reducers(types.LAB_TRENDS),
  ...persistentDataReducer.reducers(types.NUTRITION_GOALS),
  ...persistentDataReducer.reducers(types.GLUCOSE_GOALS),
  ...persistentDataReducer.reducers(types.LAB_ATTACHMENTS),
  ...persistentDataReducer.reducers(types.UNREAD_MEDICAL_RECORDS_COUNT),
  ...persistentDataReducer.reducers(types.RESET_UNREAD_MEDICAL_RECORDS_COUNT),
  ...persistentDataReducer.reducers(types.SYSTOLIC_GOALS),
  ...persistentDataReducer.reducers(types.DIASTOLIC_GOALS),
  ...persistentDataReducer.reducers(types.PATHWAY_HEALTH_RECORDS)
};

reducers[actionNames.HEALTH_SCORE_REQUESTED] = (state) => {
  return {
    ...state,
    healthScore: null,
    healthScoreNotEnoughInfo: false,
    healthScoreLoading: true,
    healthScoreError: false
  };
};

reducers[actionNames.HEALTH_SCORE_LOADED] = (state, payload) => {
  return {
    ...state,
    healthScore: payload,
    healthScoreNotEnoughInfo: false,
    healthScoreLoading: false,
    healthScoreError: false
  };
};

reducers[actionNames.HEALTH_SCORE_NOTENOUGHINFO] = (state) => {
  return {
    ...state,
    healthScoreNotEnoughInfo: true,
    healthScoreLoading: false,
    healthScoreError: false
  };
};

reducers[actionNames.HEALTH_SCORE_LOADERROR] = (state) => {
  return {
    ...state,
    healthScoreNotEnoughInfo: false,
    healthScoreLoading: false,
    healthScoreError: true
  };
};

reducers[actionNames.HEALTH_OBSERVATION_REQUESTED] = (state, payload) => {
  const stateName = stateKeyMap[payload.observationType];
  return {
    ...state,
    [stateName]: {
      ...state[stateName],
      loading: true,
      loadError: false
    }
  };
};

reducers[actionNames.HEALTH_OBSERVATION_LOADED] = (state, payload) => {
  const stateName = stateKeyMap[payload.observationType];
  return {
    ...state,
    [stateName]: {
      ...state[stateName],
      data: payload.data,
      loading: false,
      loadError: false,
      highFrequencyData:false
    }
  };
};

reducers[actionNames.HIGH_FRQUENCY_DATA_OBSERVATION_LOADED] = (state, payload) => {
  const stateName = stateKeyMap[payload.observationType];
  return {
    ...state,
    [stateName]: {
      ...state[stateName],
      data: payload.data,
      loading: false,
      loadError: false,
      highFrequencyData:payload.highFrequencyData
    }
  };
};
reducers[actionNames.HEALTH_OBSERVATION_LOADERROR] = (state, payload) => {
  const stateName = stateKeyMap[payload.observationType];
  return {
    ...state,
    [stateName]: {
      ...state[stateName],
      loading: false,
      loadError: true,
      errorMessage: payload.error
    }
  };
};
reducers[actionNames.HEALTH_OBSERVATION_RESETFORM] = (state, payload) => {
  const stateName = stateKeyMap[payload.observationType];
  return {
    ...state,
    [stateName]: {
      ...state[stateName],
      saved: false,
      saveError: false
    }
  };
};

reducers[actionNames.HEALTH_OBSERVATION_SAVEREQUESTED] = (state, payload) => {
  const stateName = stateKeyMap[payload.observationType];
  return {
    ...state,
    [stateName]: {
      ...state[stateName],
      saving: true,
      saved: false,
      saveError: false
    }
  };
};

reducers[actionNames.HEALTH_OBSERVATION_SAVED] = (state, payload) => {
  const stateName = stateKeyMap[payload.data.observationType];
  return {
    ...state,
    [stateName]: {
      ...state[stateName],
      saving: false,
      saved: true,
      saveError: false,
      data: [...state[stateName].data, payload.data]
    }
  };
};

reducers[actionNames.HEALTH_OBSERVATION_SAVEERROR] = (state, payload) => {
  const stateName = stateKeyMap[payload.observationType];
  return {
    ...state,
    [stateName]: {
      ...state[stateName],
      saving: false,
      saveError: true,
      errorMessage: payload.message
    }
  };
};

reducers[actionNames.HEALTH_OBSERVATION_DELETE] = (state, payload) => {
  const stateName = stateKeyMap[payload.observationType];
  const updatedData = state[stateName].data.map(observation => {
    if (observation.id === payload.observationId) {
      return { ...observation, deleted: true };
    }
    return observation;
  });

  return {
    ...state,
    [stateName]: {
      ...state[stateName],
      data: updatedData
    }
  };
};

reducers[actionNames.HEALTH_OBSERVATION_DELETE_UNDO] = (state, payload) => {
  const stateName = stateKeyMap[payload.observationType];
  const updatedData = state[stateName].data.map(observation => {
    if (observation.id === payload.observationId) {
      return { ...observation, deleted: false };
    }
    return observation;
  });

  return {
    ...state,
    [stateName]: {
      ...state[stateName],
      data: updatedData
    }
  };
};

reducers[actionNames.HEALTH_OBSERVATION_HISTORY_ITEM_SELECT] = (state, payload) => {
  const stateName = stateKeyMap[payload.observationType];

  return {
    ...state,
    [stateName]: {
      ...state[stateName],
      selectedId: payload.observationId
    }
  };
};

reducers[actionNames.HEALTH_OBSERVATION_HISTORY_ITEM_DESELECT] = (state, payload) => {
  const stateName = stateKeyMap[payload.observationType];

  return {
    ...state,
    [stateName]: {
      ...state[stateName],
      selectedId: null
    }
  };
};

reducers[actionNames.HEALTH_REQUIRE_HIPAA_CONSENT] = (state, payload) => {
  return {
    ...state,
    requireHipaaConsent: true,
    requireHipaaConsentType: payload.errorCode,
    requireHipaaConsentProfileId: payload.profileId,
    requireHipaaConsentRetry: payload.retryAction
  };
};

reducers[actionNames.HEALTH_HIPAA_CONSENT_CLEARED] = (state) => {
  return {
    ...state,
    requireHipaaConsent: false,
    requireHipaaConsentType: null,
    requireHipaaConsentProfileId: null,
    requireHipaaConsentRetry: null
  };
};

reducers[actionNames.REDIRECT_FROM_IMMUNIZATION_ENABLE] = (state) => {
  return {
    ...state,
    redirectFromImmunization: true
  };
};
reducers[actionNames.REDIRECT_FROM_IMMUNIZATION_DISABLE] = (state) => {
  return {
    ...state,
    redirectFromImmunization: false
  };
};

export default compileReducers(reducers, initialState);
