import React from 'react';
import local from 'services/localization/local';
import UpdateProfileContainer from './UpdateProfileContainer';

function AddNewSelfProfileWrapper(props) {
  return (
    <UpdateProfileContainer createSelfProfile centerElement={local.createSelfProfileTitle} hideCancel {...props} />
  );
}

export default AddNewSelfProfileWrapper;
