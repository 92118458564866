import React from 'react';
import PropTypes from 'prop-types';
import { Icon, ModalButton } from 'shared';

export default function FilterToggle(props) {
  return (
    <ModalButton buttonIcon={<Icon symbol="filter" />} bodyContent={props.children} renderBody={props.renderBody} />
  );
}

FilterToggle.propTypes = {
  children: PropTypes.node,
  renderBody: PropTypes.func
};
