import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProfileId } from 'app/selectors';
import { getIdentityId } from 'identity/selectors';
import { getBadgeSummaryDetailsForNotification } from 'badge/BadgeSummary-selectors';
import { loadBadgesForNotification } from 'badge/action-creators';

export default function useBadgeSummaryStatus() {
  const dispatch = useDispatch();
  const identityId = useSelector(getIdentityId);
  const profileId = useSelector(getProfileId);
  const badgeSummary = useSelector(state => state.badgeSummary);
  const { loading, loaded, hasError, data, toNotifyBadge } = useSelector(getBadgeSummaryDetailsForNotification);
  const THREE_SECONDS = 4000;

  useEffect(() => {
    if (identityId && profileId) {
      dispatch(loadBadgesForNotification(profileId));
    }
  }, [profileId, identityId]);

  useEffect(() => {
    if (identityId && profileId && loaded && badgeSummary.showBadgeAchievedScreen?.isShow == true && badgeSummary.showBadgeAchievedScreen?.retryCount > 0) {
      const timeoutId = setTimeout(() => dispatch(loadBadgesForNotification(profileId)), THREE_SECONDS);
      return () => clearTimeout(timeoutId);
    }
  }, [profileId, identityId, badgeSummary.showBadgeAchievedScreen]);

  return {
    loaded,
    loading,
    hasError,
    data,
    toNotifyBadge
  };
}
