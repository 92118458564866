/* eslint-disable react/jsx-max-depth */
import React, { Fragment, useCallback, useState } from 'react';
import { CircleDotFigure, DatePeriod, Icon } from 'shared';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import LabResultPdfModal from '../LabResultPdfModal';
import { useDispatch, useSelector } from 'react-redux';
import { loadDiagnosticReportAttachments } from 'health/action-creators';
import { getProfileId } from 'app/selectors';
import * as persistentDataSelectors from 'app/request/persistent-data-selectors';
import { types } from 'health/health-types';

export default function LabResultsEncounterListItem({provider, issued, encounter, isDocument, labId}){
  const content = local.observation.labResult;
  const carePlanContent = local.healthData.carePlan;
  const [isViewAll, setIsViewAll] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAttachmentId, setSelectedAttachmentId] = useState();
  const defaultViewItemsCount = 4;
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const {loading, data} = useSelector(state => persistentDataSelectors.getType(state.health, types.LAB_ATTACHMENTS, labId));

  const handleClick = useCallback(() => {
    setIsViewAll(!isViewAll);
  }, [isViewAll]);

  function openModal(attachmentId) {
    if(!loading && !data)
      dispatch(loadDiagnosticReportAttachments(labId, profileId));
    setIsModalOpen(true);
    setSelectedAttachmentId(attachmentId);
  }

  function closeModal() {
    setIsModalOpen(false);
    setSelectedAttachmentId(null);
  }

  const filterObservations = (observations) => !isViewAll ? observations.slice(0,4) : observations;
  let i=0;
  let isNoteExist = false;
  const notes = encounter?.observations?.map((observation)=>{
    if(observation?.notes && filterObservations(encounter?.observations).length < 4){
      i++;
      isNoteExist = true;
      return (
        <React.Fragment>
          {i===1 && <div>Observation notes:</div>}
          <ul key={observation?.id}>
            <li>
              {observation?.name} - {observation?.notes}
            </li>
          </ul>
        </React.Fragment>
      );
    }
  });

  return (encounter && encounter?.observations?.length > 0 &&
    <ul className='scrollview-list labResult-container '>
      <li className='scrollview-list-item'>
        <div className='data-row'>
          <div className='item-data item-actions'>
            <div className='d-flex align-items-baseline'>
              <CircleDotFigure />
              <div>
                <DatePeriod value={issued} />
                <div className='labResult-title'>{encounter.name}</div>
                {provider && <small>{`${carePlanContent.provider} : ${provider}`}</small>}
              </div>
            </div>
            {!isDocument ?
              <div className='flex-column'>
                <table className='table table-borderless mt-3 mb-0'>
                  <thead>
                    <tr>
                      <th scope="col">{content.component}</th>
                      <th scope="col">{content.value}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterObservations(encounter.observations).map((observation) =>
                      (
                        <Fragment key={observation.remoteId}>
                          <tr>
                            <td scope="row">{observation.name}</td>
                            <td className={observation.abnormal ? 'observation-abnormal' : undefined}>{observation.value}</td>
                          </tr>
                        </Fragment>
                      ))}
                  </tbody>
                </table>
                {isViewAll &&
                <div className='observation-note'>
                  {notes}
                </div>}
              </div> :
              filterObservations(encounter.observations).map((observation) =>(
                <div key={observation.remoteId} className='labResult-imgBox'>
                  <div className='labResult-preview-loop'>
                    {/* eslint-disable-next-line react/jsx-no-bind */}
                    <a onClick={() => openModal(observation.remoteId)} title='Download pdf document'>
                      <div className='labResult-pdfcontainer'>
                        <Icon symbol="attachment" size="2em" /><span>{observation.name}</span>
                      </div>
                    </a>
                  </div>
                  {isModalOpen && selectedAttachmentId === observation.remoteId && <LabResultPdfModal isOpen closeModal={closeModal} name={observation.name} labId={labId} attachmentId={observation.remoteId} />}
                </div>
              ))}
            {(encounter.observations.length > defaultViewItemsCount || isNoteExist) && <a className={`viewall-link ${isViewAll ? 'collapse-icon' : undefined}`} onClick={handleClick}>{!isViewAll ? carePlanContent.expandAction : carePlanContent.collapseAction}<Icon symbol="arrowdown" /></a>}
          </div>
        </div>
      </li>
    </ul>);
}

LabResultsEncounterListItem.propTypes = {
  provider: PropTypes.string,
  issued: PropTypes.shape(),
  encounter: PropTypes.shape(),
  isDocument: PropTypes.bool,
  labId: PropTypes.string
};
