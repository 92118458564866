/* eslint-disable react/jsx-no-bind */
import './hipaa.scss';
import './hipaa-intro.scss';
import HipaaConsentPage from './HipaaConsentPage';
import HipaaIntroArt from './art/HipaaIntroArt';
import local from 'services/localization/local';
import { ProfileRelationshipType, ErrorCode } from 'services/enums';
import { routePaths } from 'services/routing';
import { Button, Icon, NavAction, Page } from 'shared';

import { getProfile, getSelfProfileId } from 'profiles/list/selectors';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

function HipaaIntroductionPage() {
  const profile = useSelector(getProfile, shallowEqual);
  const selfProfileId = useSelector(getSelfProfileId, shallowEqual);
  const [showHipaaConsent, setShowHipaaConsent] = useState(false);

  const onContinue = () => {
    setShowHipaaConsent(true);
  };

  let contextMessage = null;
  let showCancelButton = false;
  let title = local.hipaaIntroUpdateTitle;

  if (profile.hipaaConsentResult.errorCode == ErrorCode.HipaaConsentExpired) {
    contextMessage = local.hipaaIntroResignContent;
  }
  else if (profile.hipaaConsentResult.errorCode == ErrorCode.HipaaConsentVersionOutdated) {
    contextMessage = local.hipaaIntroUpdateContent;
  }
  else if (profile.isNewProfile || profile.profileRelationship == ProfileRelationshipType.Owner) {
    contextMessage = local.hipaaIntroNewProfileContent;
    showCancelButton = true;
  }
  else {
    contextMessage = local.hipaaIntroNewUserContent;
    title = local.hipaaIntroNewUserTitle;
  }

  const intro = (
    <div className="intro">
      <HipaaIntroArt />
      <br />
      <br />
      <h4><strong>{title}</strong></h4>
      <p>{contextMessage}</p>
    </div>
  );

  const stickyOverlay = (
    <div className="accept-wrapper">
      <div className="sticky-section border-none">
        <Button id="hipaa-continue-button" className="button teal" onClick={onContinue}>{local.continue}</Button>
        {(showCancelButton && <span className="hippa-intro-cancel-btn" ><NavAction text={local.cancel} to={routePaths.root} params={{ profileId: selfProfileId }} /></span>)}
      </div>
    </div>
  );

  const backPath = profile.id !== self ? { to: routePaths.root, params: { profileId: selfProfileId } } : null;

  if (!showHipaaConsent) {
    return (
      <Page
        id='hipaa-consent-page'
        centerElement={<Icon className="center" symbol="hipaaauth" />}
        primaryAction={!showCancelButton && <NavAction mobileOnly text={local.linkSignout} iconSymbol="signout" to={routePaths.signout} />}
        titleTabText={local.hipaaAuthorization}
        backPath={backPath}
        hideBottomNav>
        <div className="hipaa-consent-container">
          <div className="scrollable">
            {intro}
          </div>
        </div>
        <div>
          {stickyOverlay}
        </div>
      </Page>
    );
  }
  return (<HipaaConsentPage />);
}

export default HipaaIntroductionPage;
