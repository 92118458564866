import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { momentCalendarDateTimeFormat } from 'services/date-service';
import { deleteJournalEntry } from 'journal/journal-actions';
import { useDispatch } from 'react-redux';
import { Icon, Card, ConfirmModal } from 'shared';
import local from 'services/localization/local';

export default function JournalEntryListItem({ item }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const handleDelete = () => {
    setShowModal(false);
    const profileId = item.profileId;
    const journalEntryId = item.id;
    dispatch(deleteJournalEntry({ profileId, journalEntryId }));
  };

  const triggerModal = () => setShowModal(!showModal);
  const modal = showModal ? (
    <ConfirmModal
      modalTitle={local.journal.deleteJournal}
      modalPromptText={local.journal.deletePrompt}
      deleteYes={local.journal.deleteYes}
      deleteNo={local.journal.deleteNo}
      handleYes={handleDelete}
      handleNo={triggerModal}
      isOpen />
  ) : null;

  return (
    <div>
      <Card>
        <div className='entry'>
          <div className='timestamp'>
            <div className='entry-date'>
              <span> {`${moment(item.recordedAt).calendar(null, momentCalendarDateTimeFormat)}`}</span>
            </div>
            <div className='actions'>
              <button
                id={`delete-button-${item.id}`}
                data-testid={`delete-button-${item.id}`}
                type="button"
                onClick={triggerModal}>
                <Icon symbol="delete" />
              </button>
            </div>
          </div>
          <strong>
            <p className='entry-title'>{item.title}</p>
          </strong>
          <p className='entry-content'>
            {item.content}
          </p>
        </div>
      </Card>
      {modal}
    </div>
  );
}

JournalEntryListItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    recordedAt: PropTypes.string,
    id: PropTypes.string,
    profileId: PropTypes.string
  })
};
