import { types } from './organizations-types';
import * as persistentDataReducer from 'app/request/persistent-data-reducer';
import * as requestQueueReducer from 'app/request/request-queue-reducer';
import compileReducers from 'services/compile-reducer';
import { actionNames } from './organizations-actions';

export const initialState = {
  [types.ORGANIZATIONS]: persistentDataReducer.initialState,
  [types.ORGANIZATION_DETAILS]: persistentDataReducer.initialState,
  [types.ORGANIZATION_INVITES]: persistentDataReducer.initialState,
  [types.ORGANIZATION_INVITES_COUNT]: persistentDataReducer.initialState,
  [types.ORGANIZATION_ADMINS]: persistentDataReducer.initialState,
  [types.ORGANIZATION_REQUESTS]: requestQueueReducer.initialState,
  [types.ORGANIZATION_MEMBER_TYPE]: persistentDataReducer.initialState,
  [types.ORGANIZATION_RECENT_PENDING_INVITE]: persistentDataReducer.initialState,
  [types.ORGANIZATION_WHITE_LABEL]: persistentDataReducer.initialState
};

const reducers = {
  ...persistentDataReducer.reducers(types.ORGANIZATIONS),
  ...persistentDataReducer.reducers(types.ORGANIZATION_DETAILS),
  ...persistentDataReducer.reducers(types.ORGANIZATION_INVITES),
  ...persistentDataReducer.reducers(types.ORGANIZATION_INVITES_COUNT),
  ...persistentDataReducer.reducers(types.ORGANIZATION_ADMINS),
  ...requestQueueReducer.reducers(types.ORGANIZATION_REQUESTS),
  ...persistentDataReducer.reducers(types.ORGANIZATION_MEMBER_TYPE),
  ...persistentDataReducer.reducers(types.ORGANIZATION_RECENT_PENDING_INVITE),
  ...persistentDataReducer.reducers(types.ORGANIZATION_WHITE_LABEL)
};

reducers[actionNames.MEMBERSHIP_DELETE] = (state, payload) => {
  const { orgId, membershipId } = payload;

  const otherMemberships = state[types.ORGANIZATION_DETAILS][orgId].data.filter(x => x.membershipId !== membershipId);
  return persistentDataReducer.updateDataStatus(state, types.ORGANIZATION_DETAILS, orgId, {
    data: otherMemberships
  });
};

reducers[actionNames.MEMBERSHIP_ADD] = (state, payload) => {
  const { organizationId } = payload;
  const data = state[types.ORGANIZATION_DETAILS][organizationId].data;
  return persistentDataReducer.updateDataStatus(state, types.ORGANIZATION_DETAILS, organizationId, {
    data: [...data, payload]
  });
};

export default compileReducers(reducers, initialState);
