import React from 'react';
import { Route, Switch } from 'react-router';
import NavigateRoute from 'app/layout/navigation/NavigateRoute';
import SignInCallbackWeb from 'identity/signin/SignInCallbackWeb';
import SignInCallbackCapacitor from 'identity/signin/SignInCallbackCapacitor';
import SignOut from 'identity/signout/SignOut';
import RegisterContainer from 'identity/register/RegisterContainer';
import RegistrationThankYou from 'identity/register/RegistrationThankYou';
import ResetPassword from 'identity/reset-password/ResetPassword';
import HipaaIntroductionPage from 'app/privacy-policy-eula/HipaaIntroductionPage';
import { routePaths } from 'services/routing';
import AuthWrapper from './AuthWrapper';

class UnauthenticatedRoutes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path={routePaths.signout} component={SignOut} />

        <Route exact path={routePaths.signinCallback} component={SignInCallbackWeb} />

        <Route exact path={routePaths.signinCapacitor} component={SignInCallbackCapacitor} />

        <Route exact path={routePaths.registerThankyou} component={RegistrationThankYou} />

        <Route exact path={routePaths.register} component={RegisterContainer} />

        <Route exact path={routePaths.resetPassword} component={ResetPassword} />

        <Route exact path={routePaths.navigate} component={NavigateRoute} />

        <Route exact path={routePaths.hipaaIntroductionPage} component={HipaaIntroductionPage} />

        <Route path={routePaths.root} component={AuthWrapper} />

      </Switch>
    );
  }
}

export default UnauthenticatedRoutes;
