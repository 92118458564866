import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Icon, LinkWrapper } from 'shared';
import { routePaths } from 'services/routing';
import local from 'services/localization/local';
import { getProfileId } from 'app/selectors';
import { loadIdentityVerificationStatusData } from 'health/connect/connect-actions';
import { getIdentityVerificationStatus, getIdentityVerifiedStatus } from './connect-selectors';
import * as beaconService from 'services/helpscout-beacon-service';
import { helpScoutBeaconId } from 'app/config';
import './connect.scss';

export default function VerifyIdentity(){
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const email = useSelector(state => state.identity.email);
  const identityVerificationStatus = useSelector(state => getIdentityVerificationStatus(state, profileId));
  const verified = useSelector(state => getIdentityVerifiedStatus(state, profileId));
  const content = local.healthData.connect;
  const name = useSelector(state => state.profile.self.name);
  const [isBeaconInitialized, setBeaconInitialized] = useState(false);
  const [isBeaconOpen, setBeaconOpen] = useState(false);

  useEffect(() => {
    dispatch(loadIdentityVerificationStatusData(profileId, email));
  }, [profileId, email]);

  useEffect(() => {
    if (isBeaconInitialized) {
      beaconService.logout();
      beaconService.setIdentity(name, email);
      beaconService.prefillUserInformation(name, email);
    }
  }, [name, email, profileId]);

  function handleSupportClick() {
    if (isBeaconOpen)
      return;

    if (!isBeaconInitialized) {
      beaconService.initialize(helpScoutBeaconId);
      beaconService.setDefaultConfiguration();
      beaconService.setIdentity(name, email);
      beaconService.prefillUserInformation(name, email);
      beaconService.addCallbackEvent('close', onBeaconClose);
      setBeaconInitialized(true);
    }

    beaconService.open();
    setBeaconOpen(true);
  }

  function onBeaconClose() {
    setBeaconOpen(false);
  }

  if(verified?.isIdentityVerified || identityVerificationStatus?.isIdentityVerificationRequired != true)
    return null;

  return (
    <Card>
      <div className='verify-header verify-div '>
        <div className='header'>
          <Icon symbol="health" size="1.5em" />
          <span>{content.status.header}</span>
        </div>
        <LinkWrapper
          id='verify-identity'
          className={`button btn btn-primary small-btn ${identityVerificationStatus?.identityVerificationAttemptsCount >= 2 ? 'disabled' : ''}`}
          to={routePaths.healthIdentity}>Verify
        </LinkWrapper>
      </div>
      <div className='verify-inst'>
        {identityVerificationStatus?.identityVerificationAttemptsCount > 0 ?
          <div className='warning'>{identityVerificationStatus?.identityVerificationAttemptsCount == 1 ?
            content.identityVerification.warningFirstAttempt :
            <span>{content.identityVerification.warningSecondAttempt}<br />
              <span className='warning-contactSupport'>{content.identityVerification.please}<a href="#" onClick={handleSupportClick}>{content.identityVerification.contactSupport}</a>{content.identityVerification.questions}</span>
            </span>}
          </div> :
          <span>{content.identityVerification.verifyData}</span>}
      </div>
    </Card>
  );
}
