import * as dataSelectors from 'app/request/persistent-data-selectors';
import { compareDatesDescending } from 'services/date-service';
import { types } from './notification-types';

export const isNotificationLoading = (state) => {
  return !!(state.identity.self && dataSelectors.isLoading(state.notification, types.NOTIFICATIONS, state.identity.self.id));
};

export const isNotificationLoadError = (state) => {
  return !!(state.identity.self && dataSelectors.hasError(state.notification, types.NOTIFICATIONS, state.identity.self.id));
};

export const getNotifications = (state) => {
  const notifications = (state.identity.self && dataSelectors.getData(state.notification, types.NOTIFICATIONS, state.identity.self.id)) || [];
  return notifications
    .filter(x => !x.deleted)
    .sort((a, b) => compareDatesDescending(a.timestamp, b.timestamp));
};

export const getSummaryNotifications = (state) => {
  const notifications = getNotifications(state);
  return notifications
    .filter(x => !x.isRead)
    .slice(0, 2);
};

export const hasUnreadNotifications = (state) => {
  return getNotifications(state).some(x => !x.isRead);
};

export const unreadNotificationsCount = (state) => {
  return getNotifications(state).filter(x => !x.isRead).length;
};
