import * as dataSelectors from 'app/request/persistent-data-selectors';
import {INVOICE} from './action-names';

export const getInvoiceDetails = (state) => {

  const identityId = state.identity && state.identity.self.id;
  const invoice = dataSelectors.getType(state.invoice, INVOICE, identityId);

  return {
    loaded: invoice.loaded,
    loading: invoice.loading,
    hasError: invoice.hasError,
    data: invoice.data?.results
  };
};
