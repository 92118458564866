/* eslint-disable react/jsx-no-bind */
import React, {useState} from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { routePaths } from 'services/routing';
import { Button, Icon, NavAction, Page,NonAuthenticatedPage } from 'shared';
import { ErrorCode } from 'services/enums';
import local from 'services/localization/local';
import { getProfile, getSelfProfileId } from 'profiles/list/selectors';
import UserConsent from 'app/privacy-policy-eula/userConsent/UserConsent';
import 'app/privacy-policy-eula/hipaa.scss';
import UserDetailsRegistration from 'identity/profileDetails/UserDetailsRegistration';
import { acceptHipaaConsentPage } from 'profiles/action-creators';

function UserConsentPage() {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile, shallowEqual);
  const selfProfileId = useSelector(getSelfProfileId, shallowEqual);
  const profileName = profile && profile.name || '';
  const [showProfileDetailsPage, setProfileDetailsPage] = useState(false);

  const onAccept = () => {
    if(profile && profile.dateOfBirth && profile.gender){
      dispatch(acceptHipaaConsentPage(profile.id));
    }
    else{
      setProfileDetailsPage(true);
    }
  };

  let name = <i>{profileName}</i>;
  let contextMessage = null;
  let showIntro = false;
  switch (profile.hipaaConsentResult.errorCode) {
    case ErrorCode.HipaaConsentExpired:
      showIntro = true;
      contextMessage = local.formatString(local.hipaaReconsentIntroP1, name);
      break;
    case ErrorCode.HipaaConsentVersionOutdated:
      showIntro = true;
      contextMessage = local.formatString(local.hipaaConsentNewVersionIntroP1, name);
      break;
    default:
      break;
  }

  const intro = (
    <div className="intro">
      <small><strong>{local.hipaaConsentIntroGreeting}</strong></small>
      <br />
      <br />
      <small><strong>{contextMessage}</strong></small>
      <br />
      <br />
      <small>{local.hipaaConsentIntroP2}</small>
    </div>
  );

  const stickyOverlay = (
    <div className="accept-wrapper">
      <div className="sticky-section text-center border-none">
        <Button id="hipaa-accept-button" className="btn btn-primary w-100" color="blue" onClick={onAccept}>{local.agree}</Button>
      </div>
    </div>
  );

  const backPath = profile.id !== self ? { to: routePaths.root, params: { profileId: selfProfileId } } : null;

  if(!showProfileDetailsPage){
    return (
      <Page
        id='hipaa-consent-page'
        centerElement={<Icon className="center" symbol="hipaaauth" />}
        primaryAction={<NavAction mobileOnly text={local.linkSignout} iconSymbol="signout" to={routePaths.signout} />}
        titleTabText={local.hipaaAuthorization}
        backPath={backPath}
        hideBottomNav>
        <NonAuthenticatedPage id="userconsent" topChevronColor='yellow'>
          <div className="hipaa-consent-container">
            <div className="scrollable">
              {showIntro && intro}
              <UserConsent />
            </div>
            {stickyOverlay}
          </div>
        </NonAuthenticatedPage>
      </Page>
    );
  }
  return (<UserDetailsRegistration></UserDetailsRegistration>);
}

export default UserConsentPage;
