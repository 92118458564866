export const formatFeetInches = value => {
  const feet = Math.floor(value / 12);
  const inches = Math.round((value % 12) * 100) / 100;

  const feetDisplay = feet > 0 ? `${feet}'` : '';
  const inchesDisplay = `${inches}"`;

  const formatted = `${feetDisplay} ${inchesDisplay}`.trim();

  return formatted;
};

export const roundToDecimals = (value, decimals = 0) => {
  const multiplier = 10 ** decimals;

  return Math.round(value * multiplier) / multiplier;
};

export const feetInchesToInches = (feet, inches) => {
  const totalInches = Number((Number(feet || 0) * 12) + Number(inches || 0));
  return totalInches;
};
