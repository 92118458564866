import React from 'react';
import PropTypes from 'prop-types';
import withContext from 'app/withContext';
import { Redirect } from 'react-router';
import { buildPath } from 'services/routing';

export function ProfileRedirect(props) {
  // eslint-disable-next-line react/prop-types, no-unused-vars
  const { profileId, params, history, location, match, staticContext, dispatch, to, backpath, ...rest } = props;

  const toObj = backpath ? { pathname: buildPath(to, params), search: profileId ? new URLSearchParams({ profileId }).toString() : '', state: { backpath } } : buildPath(to, { profileId, ...params });

  return <Redirect to={toObj} {...rest} />;
}

ProfileRedirect.propTypes = {
  to: PropTypes.string.isRequired,
  params: PropTypes.shape(),
  // context
  profileId: PropTypes.string
};

export default withContext(ProfileRedirect);
