import { buildUrl, httpGet, httpPost, parseError, parseSuccess } from './api';
import { goalServiceRoot as apiRoot} from 'app/config';

export const getProfileGoals = (state, profileId) =>
  httpGet(buildUrl(apiRoot, '/api/ProfileGoal', { profileId }), state)
    .then(parseSuccess)
    .catch(parseError);

export const saveProfileGoals = (state, profileId, body, utcOffsetMinutes) => {
  var url = buildUrl(apiRoot, '/api/ProfileGoal', { profileId, utcOffsetMinutes });
  return httpPost(url, body, state)
    .catch(parseError);
};

export const getGoalCategories = (state, profileId) =>
  httpGet(buildUrl(apiRoot, '/api/GoalCategory', { profileId}), state)
    .then(parseSuccess)
    .catch(parseError);
