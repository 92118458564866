import { organizationRoot as apiRoot} from 'app/config';
import { httpGet, buildUrl, parseSuccess, parseError} from './api';

export const getChatOrganizationByProfileId = (state, profileId, featureType) => {
  var url = buildUrl(apiRoot,'/api/OrganizationSubscriptionFeature/get-all-orgs', {profileId, featureType });

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};
