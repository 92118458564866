import { request } from 'app/request/persistent-data-actions';
import { types } from 'goal/action-names';
import { getGoalCategories, getProfileGoals, saveProfileGoals } from 'services/api/goalService-api';
import { getProgramInfos, isGoalBasedEnrollment, getProfileObservationGoals, postGoalList, getGoalSummary } from 'services/api/healthdata-api';
import { types as programTypes } from 'programs/programs-types';
import { request as requestActions } from 'app/request/request-actions';
import moment from 'moment';
import * as persistentDataActions from 'app/request/persistent-data-actions';
import local from 'services/localization/local';

export function checkGoalBasedEnrollment(profileId, enrollmentId) {
  return (dispatch) => {
    const action = state => isGoalBasedEnrollment(profileId, enrollmentId, state);
    dispatch(persistentDataActions.request(action, types.IS_GOAL_BASED_ENROLLMENT, enrollmentId));
  };
}

export function loadProfileGoals(profileId) {
  return (dispatch) => {
    const action = state => getProfileGoals(state, profileId);
    return dispatch(request(action, types.GOALS, profileId));
  };
}

export function loadProfileObservationGoals(profileId, enrollmentId) {
  return (dispatch) => {
    const action = state => getProfileObservationGoals(profileId, enrollmentId, state);
    dispatch(persistentDataActions.request(action, types.PROFILE_OBSERVATION_GOALS, profileId));
  };
}

export function saveObservationGoals(profileId, goals, requestId) {
  return (dispatch) => {
    const action = state => postGoalList(profileId, goals, state);

    const requestOptions = {
      showToasts: true,
      processingMessage: local.saving,
      successMessage: local.saved,
      errorMessage: local.error,
      requireHipaaVerification: true
    };

    dispatch(requestActions(action, requestId, {}, requestOptions));
  };
}

export function setStepObservationGoal(profileId, stepGoal) {
  return {
    type: types.SET_STEP_GOAL,
    payload: {
      profileId,
      stepGoal
    }
  };
}

export function setNutitionObservationGoal(profileId, nutritionGoal) {
  return {
    type: types.SET_NUTRITION_GOAL,
    payload: {
      profileId,
      nutritionGoal
    }
  };
}

export function setBloodPressureObservationGoal(profileId, bloodPressureGoal) {
  return {
    type: types.SET_BLOOD_PRESSURE_GOAL,
    payload: {
      profileId,
      bloodPressureGoal
    }
  };
}

export function setBloodGlucoseObservationGoal(profileId, bloodGlucoseGoal) {
  return {
    type: types.SET_BLOOD_GLUCOSE_GOAL,
    payload: {
      profileId,
      bloodGlucoseGoal
    }
  };
}

export function setBloodPressureErrorFlag(profileId, errorFlag) {
  return {
    type: types.SET_BLOOD_PRESSURE_ERROR_FLAG,
    payload: {
      profileId,
      errorFlag
    }
  };
}

export function setNutritionErrorFlag(profileId, errorFlag) {
  return {
    type: types.SET_NUTRITION_ERROR_FLAG,
    payload: {
      profileId,
      errorFlag
    }
  };
}

export function setBloodGlucoseErrorFlag(profileId, errorFlag) {
  return {
    type: types.SET_BLOOD_GLUCOSE_ERROR_FLAG,
    payload: {
      profileId,
      errorFlag
    }
  };
}

export function loadGoalProgramInfos(profileId, programIds) {
  return (dispatch) => {
    const action = state => getProgramInfos(state, profileId, programIds);
    return dispatch(request(action, programTypes.PROGRAM_INFOS, profileId));
  };
}


export function loadGoalCategory(profileId) {
  return (dispatch) => {
    const action = state => getGoalCategories(state, profileId);
    return dispatch(request(action, types.GOAL_CATEGORY, profileId));
  };
}

export function SaveProfileGoals(profileId, body, goalRequestId) {
  const utcOffsetMinutes = moment().utcOffset();
  return (dispatch) => {
    const action = state => saveProfileGoals(state, profileId, body, utcOffsetMinutes);
    dispatch(requestActions(action, goalRequestId, {}, {}));
  };
}

export function updateGoalCategoryId(GoalCategoryId) {
  return {
    type: types.SELECTED_GOAL_ID,
    payload: GoalCategoryId
  };
}

export function clearProfileGoals(profileId) {
  return {
    type: types.CLEAR_GOALS,
    profileId: profileId
  };
}

export function loadGoalSummary(profileId, body, goalId) {
  return (dispatch) => {
    const action = state => getGoalSummary(state, profileId, body);
    return dispatch(request(action, types.GOAL_SUMMARY, goalId));
  };
}

export function clearProfileGoalsData(profileId) {
  return {
    type: types.CLEAR_PROFILE_GOALS,
    profileId: profileId
  };
}
