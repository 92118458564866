import * as api from 'services/api/healthdata-api';
import * as actionsNames from './action-names';
import * as persistentDataActions from 'app/request/persistent-data-actions';

export function loadHealthSummary(profileId) {

  return (dispatch, getState) => {
    const identityId = getState().identity.self.id;
    const action = state => api.getHealthSummary(profileId, state);
    return dispatch(persistentDataActions.request(action, actionsNames.HEALTH_SUMMARY, identityId));
  };
}
