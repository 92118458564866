import * as requestQueueActions from 'app/request/request-queue-actions';
import * as persistentDataActions from 'app/request/persistent-data-actions';
import * as profilesApi from 'services/api/profiles-api';
import { loadProfiles } from 'profiles/action-creators';
import { removeProfileFromList } from 'profiles/actions';
import { selectProfile } from 'profiles/selected/selected-profile-actions';
import { types } from './sharing-types';
import local from 'services/localization/local';

export const actionNames = {
  INVITE_DELETE: 'INVITE_DELETE',
  STATUS_DELETE: 'STATUS_DELETE'
};

export const actions = {
  removeInviteFromList: (identitySelfId, inviteId) => ({
    type: actionNames.INVITE_DELETE,
    payload: {
      identitySelfId,
      inviteId
    }
  }),
  removeStatusFromList: (profileId, identityId) => ({
    type: actionNames.STATUS_DELETE,
    payload: {
      profileId,
      identityId
    }
  })
};

export function createInvite({ profileId, email, requestId, setSubmitting }) {
  return (dispatch) => {
    const onSuccess = () => {
      setSubmitting(false);
    };

    const onError = error => {
      setSubmitting(false);
      throw error;
    };

    const action = state => profilesApi.createProfileInvite({ profileId, email }, state)
      .then(onSuccess)
      .catch(onError);

    return dispatch(makeInviteRequestWithToast(
      action,
      { profileId },
      local.sending,
      local.sent,
      requestId
    ));
  };
}

export function loadIncomingInvites() {
  return (dispatch, getState) => {
    const action = state => profilesApi.getIncomingProfileInvites(state);
    return dispatch(persistentDataActions.request(action, types.INCOMING_PROFILE_INVITES, getState().identity.self.id));
  };
}

export function loadIncomingInvitesCount() {
  return (dispatch, getState) => {
    const action = state => profilesApi.getIncomingProfileInvitesCount(state);
    return dispatch(persistentDataActions.request(action, types.INCOMING_PROFILE_INVITES_COUNT, getState().identity.self.id));
  };
}

export function loadInviteStatusesForProfile() {
  return (dispatch, getState) => {
    const profileId = getState().selectedProfile.id;
    const action = state => profilesApi.getInviteStatuses(profileId, state);

    return dispatch(persistentDataActions.request(action, types.INVITE_STATUSES, profileId, { profileId }));
  };
}

export function deleteInvite(inviteId) {
  return (dispatch, getState) => {
    const onSuccess = () => {
      dispatch(actions.removeStatusFromList(getState().selectedProfile.id, inviteId));
    };

    const action = state => profilesApi.deleteProfileInvite(inviteId, state)
      .then(onSuccess);

    return dispatch(makeInviteRequestWithToast(
      action,
      { inviteId },
      local.deleting,
      local.deleted
    ));
  };
}

export function respondToInvite({ inviteId, accepted }) {
  return (dispatch, getState) => {
    const request = { inviteId, accepted };
    const processingMessage = accepted ? local.sharing.accepting : local.sharing.declining;
    const successMessage = accepted ? local.sharing.accepted : local.sharing.declined;

    const action = state => profilesApi.respondToProfileInvite(request, state)
      .then(onSuccess);

    const onSuccess = () => {
      if (accepted) {
        // if accepted invite, new profile list
        dispatch(loadProfiles());
      }
      dispatch(actions.removeInviteFromList(getState().identity.self.id, inviteId));
    };

    return dispatch(makeInviteRequestWithToast(
      action,
      request,
      processingMessage,
      successMessage
    ));
  };
}

export function revokeAccessAsOwner(identityId) {
  return (dispatch, getState) => {
    const profileId = getState().selectedProfile.id;
    const request = { identityId };
    const onSuccess = () => {
      dispatch(actions.removeStatusFromList(profileId, identityId));
    };

    const action = state => profilesApi.revokeSharingAccess(request, profileId, state)
      .then(onSuccess);

    return dispatch(makeInviteRequestWithToast(
      action,
      request,
      local.sharing.revokingAccess,
      local.sharing.accessRevoked
    ));
  };
}

export function revokeAccessAsUser(identityId, profileId) {
  return (dispatch, getState) => {
    const request = { identityId };
    const selectedProfileId = getState().selectedProfile.id;
    const onSuccess = () => {
      if (profileId === selectedProfileId) {
        const selfProfileId = getState().profile.self.id;
        dispatch(selectProfile(selfProfileId));
      }
      dispatch(removeProfileFromList(profileId));
    };

    const action = state => profilesApi.revokeSharingAccess(request, profileId, state)
      .then(onSuccess);

    return dispatch(makeInviteRequestWithToast(
      action,
      request,
      local.sharing.revokingAccess,
      local.sharing.accessRevoked
    ));
  };
}

function makeInviteRequestWithToast(action, request, processingMessage, successMessage, customRequestId = null) {
  return requestQueueActions.queueRequestWithToast(
    action,
    types.INVITE_REQUESTS,
    customRequestId,
    {
      processingMessage,
      successMessage
    },
    request
  );
}


