import React from 'react';
import moment from 'moment';
import { momentCalendarDayFormat } from 'services/date-service';
import local from 'services/localization/local';
import { ObservationType, Unit } from 'services/enums';
import { routePaths } from 'services/routing';
import { configIds } from 'health/observations/observation-config-ids';
import { baseObservationConfig } from 'health/observations/observation-config-helpers.jsx';
import { GenericObservationDeviceLinkNoData } from 'health/observations/generics';
import StepsVisualizationContainer from 'health/observations/steps/visualization/StepsVisualizationContainer';
import { Steps } from 'shared/fullColorIcons';

const typeDefinition = {
  type: ObservationType.Steps,
  typeUnitMap: {
    [ObservationType.Steps]: Unit.Count
  },
  unitLabelMap: {
    [Unit.Count]: local.units.steps
  }
};

export default {
  ...baseObservationConfig,
  ...typeDefinition,
  id: configIds.steps,
  backPath: { to: routePaths.health },
  centerElement: local.observation.steps.title,
  iconSymbol: Steps,
  noDataText: local.observation.steps.noDataText,
  timestampFormatter: (props) => moment(props.observation.recordedAt).calendar(null, momentCalendarDayFormat),
  renderNoDataComponent: (props) => props && <GenericObservationDeviceLinkNoData message={props.config.noDataText} />,
  // eslint-disable-next-line react/no-multi-comp
  renderChart: (props) => props.observations && <StepsVisualizationContainer steps={props.observations} fromPathway={false} />
};
