import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PublicHealthBadgeIcon from './PublicHealthBadgeIcon';
import local from 'services/localization/local';
import { AlertSeverityStatus } from 'services/enums';
import './public-health-badge.scss';

export default function PublicHealthBadge({ alertSeverityStatus, lastUpdated, profileName, programName, processing, areRequestsProcessing, error }) {
  const text = local.healthBadge;
  let badgeStatus;
  let badgeMessage;
  let headerClass;

  if (alertSeverityStatus === AlertSeverityStatus.Unknown) {
    if (processing || areRequestsProcessing) {
      badgeStatus = text.unknown.calculatingStatus;
    } else if (error) {
      badgeStatus = text.unknown.errorStatus;
    } else {
      badgeStatus = text.unknown.status;
    }

    badgeMessage = text.unknown.message;
    headerClass = 'status-unknown';
  } else if (alertSeverityStatus === AlertSeverityStatus.Verified) {
    badgeMessage = text.verified.message;
    badgeStatus = text.verified.status;
    headerClass = 'status-verified';
  } else {
    badgeMessage = text.unverified.message;
    badgeStatus = text.unverified.status;
    headerClass = 'status-not-verified';
  }

  const updatedOn = local.formatString(text.updated, moment(lastUpdated).fromNow());
  const dayText = local.formatString(text.onDay, moment(lastUpdated).format('dddd'));

  return (
    <div className="public-health-badge-component">
      <header className={headerClass}>
        <h5>{programName}</h5>
        <h3>{profileName}</h3>
        <figure className="phb-indicator">
          <PublicHealthBadgeIcon alertSeverityStatus={alertSeverityStatus} />
        </figure>
        <h4 className="phb-timestamp animation-fade-in">{updatedOn}</h4>
        <h4 className="phb-timestamp animation-fade-in">{dayText}</h4>
        <small className="animation-fade-in">{moment(lastUpdated).format(text.dateAtTime)}</small>
      </header>
      <section>
        <p>
          <strong className="animation-fade-in">{badgeMessage}</strong>
          <span className="animation-fade-in">{badgeStatus}</span>
        </p>
      </section>
    </div>
  );
}

PublicHealthBadge.propTypes = {
  alertSeverityStatus: PropTypes.number.isRequired,
  lastUpdated: PropTypes.string.isRequired,
  profileName: PropTypes.string.isRequired,
  programName: PropTypes.string.isRequired,
  processing: PropTypes.bool.isRequired,
  areRequestsProcessing: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired
};
