import React from 'react';
import PropTypes from 'prop-types';
import { ModalWrapper, Button, Icon } from 'shared';
import UserConsent from './UserConsent';

function UserConsentModal(props) {
  return (
    <ModalWrapper {...props}>
      <div id="hipaa-modal" className="modal-container">
        <div className="modal-header">
          <div></div>
          <Icon symbol="hipaaauth" />
          <Button id="hipaa-authorization-close-button" onClick={props.onRequestClose}><Icon symbol="times" /></Button>
        </div>
        <div className="modal-body">
          <UserConsent />
        </div>
      </div>
    </ModalWrapper>
  );
}

UserConsentModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired
};

export default UserConsentModal;
