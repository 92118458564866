import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import styleVars from 'styles/_variables.scss';
import config from 'health/observations/sleep/sleep.config';

class SleepBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.getOptions = this.getOptions.bind(this);
  }

  getData() {
    const { data } = this.props;

    const output = data.reduce((result, x) => {
      result.labels.push(x.date);
      result.data.push(x.totalSleep);
      result.backgroundColor.push(styleVars.colorTeal);
      return result;
    }, { labels: [], data: [], backgroundColor: [] });

    return {
      labels: output.labels,
      datasets: [{
        data: output.data,
        barPercentage: 0.3,
        categoryPercentage: 1.5,
        backgroundColor: output.backgroundColor
      }]
    };
  }

  getOptions() {
    const { data } = this.props;

    return {
      responsive: true,
      tooltips: {
        enabled: true,
        displayColors: false,
        callbacks: {
          label: function (tooltipItem) {
            const observation = data[tooltipItem.index];
            return [
              config.graphValueFormatter({ observation, config })
            ];
          }
        }
      },
      hover: {
        mode: null
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          display: true,
          gridLines: {
            display: false
          },
          ticks: {
            display: true,
            autoSkip: true,
            maxTicksLimit: 4
          }
        }],
        yAxes: [{
          display: false,
          gridLines: {
            display: false
          },
          ticks: {
            suggestedMin: 0,
            display: true
          }
        }]
      }
    };
  }

  render() {
    const data = this.getData();
    const options = this.getOptions();

    return (
      <Bar
        data={data}
        options={options}
        width={100}
        height={60}
        className="chart-data" />
    );
  }
}

SleepBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape).isRequired
};

export default SleepBarChart;
