import * as selectors from 'app/request/persistent-data-selectors';
import { types } from './devices-types';
const appleHealthLogo = 'https://static.myhc.app/images/apple-health.png';
const appleHealthName = 'Apple Health';
export const googleFitName = 'Google Fit';
import { AppleHealthType, GoogleFitType, googleFitLogo, terraProvider } from 'services/constants';
import { isAndroid } from 'mobile/helpers';
import { getQueryStringParams } from 'services/url-service';
import { HealthDataSource } from 'services/enums';

export const isValidicProfileLoading = (state, profileId) => {
  return selectors.isLoading(state.devices, types.VALIDIC_PROFILE, profileId);
};

export const isGetDevicesLoading = (state, profileId) => {
  return selectors.isLoading(state.devices, types.CONNECTED_DEVICES, profileId);
};

export const isGetDevicesLoaded = (state, profileId) => {
  return selectors.isLoaded(state.devices, types.CONNECTED_DEVICES, profileId);
};

export const isValidicProfileError = (state, profileId) => {
  return selectors.hasError(state.devices, types.VALIDIC_PROFILE, profileId);
};

export const getValidicUrl = (state, profileId) => {
  const data = selectors.getData(state.devices, types.VALIDIC_PROFILE, profileId);
  return data && data.connectionUri;
};

export const getDevicesLoaded = (state, profileId) =>
  selectors.getType(state.devices, types.CONNECTED_DEVICES, profileId).loaded ;


export function getDevices (state, profileId, healthKitUrl, googleFitUrl)  {
  let dataRec = selectors.getData(state.devices, types.CONNECTED_DEVICES, profileId);
  const terraDevicesRec = selectors.getData(state.devices, types.TERRA_DEVICES, profileId);
  let terraConnectedDevices;
  // let disconnectedTerraDevice ;
  if (terraDevicesRec && terraDevicesRec !== undefined && terraDevicesRec.length > 0) {
    terraConnectedDevices = [...terraDevicesRec];
  }

  let data;
  if (dataRec && dataRec !== undefined) {
    data = [...dataRec];
  }

  if (data) {
    data = data.filter(x => x.connected === true);

    if(!isAndroid)
    {
      data = data.filter(x => x.type !== GoogleFitType);
    }

  }
  let sortedSource = data;

  if (data && data !== undefined ) {
    let appleDevice = data.find(device => device.type === AppleHealthType);
    let googleFit= data.find(device => device.type === GoogleFitType);
    if (appleDevice) {
      appleDevice.logo_url = appleHealthLogo;
      appleDevice.connect_url = healthKitUrl;
      appleDevice.display_name = appleHealthName;
    }
    if(googleFit){
      googleFit.logo_url = googleFitLogo;
      googleFit.connect_url = googleFitUrl;
      googleFit.display_name = googleFitName;
    }

  }


  if (data && terraConnectedDevices && terraConnectedDevices.length > 0 )
  {
    data = addTerraDevices(state, profileId, terraConnectedDevices.filter(x => x.isConnected === true), data, true);
  }

  if (data) {
    sortedSource = data.sort((a, b) => {
      const compareName = a.display_name.localeCompare(b.display_name);
      return compareName;
    });
  }
  sortedSource = !sortedSource? [] : sortedSource;
  return sortedSource ;
}

export const getValidicHealthKitMobileUrl = (state, profileId) => {
  return `healthkit?profileid=${profileId}`;
  const data = selectors.getData(state.devices, types.VALIDIC_PROFILE, profileId);
  if (!data || !data.mobileToken || !data.validicUserId || !data.organizationId) {
    return undefined;
  }

  return `healthkit?token=${data.mobileToken}&validicId=${data.validicId}&organizationId=${data.organizationId}`;
};

export const getValidicHealthKitConnectUrl = (state, profileId) => {
  return `healthkitnoconfirm?profileid=${profileId}`;
  const data = selectors.getData(state.devices, types.VALIDIC_PROFILE, profileId);
  if (!data || !data.mobileToken || !data.validicUserId || !data.organizationId) {
    return undefined;
  }

  return `healthkitnoconfirm?token=${data.mobileToken}&validicId=${data.validicId}&organizationId=${data.organizationId}&profileId=${profileId}&connect=true`;
};

export const getValidicHealthKitDisconnectUrl = (state, profileId) => {
  const data = selectors.getData(state.devices, types.VALIDIC_PROFILE, profileId);
  if (!data || !data.mobileToken || !data.validicUserId || !data.organizationId) {
    return undefined;
  }

  return `healthkitnoconfirm?token=${data.mobileToken}&validicId=${data.validicId}&organizationId=${data.organizationId}&profileId=${profileId}&connect=false`;
};


export const getValidicGoogleFitMobileUrl = (state, profileId) => {
  const data = selectors.getData(state.devices, types.VALIDIC_PROFILE, profileId);
  if (!data || !data.mobileToken || !data.validicUserId || !data.organizationId) {
    return undefined;
  }

  return `googlefit?token=${data.mobileToken}&validicId=${data.validicId}&organizationId=${data.organizationId}`;
};


export const getValidicUpdateAppleHealthStatusUrl = (state, profileId) => {
  return `updateapplehealthstatus?profileId=${profileId}`;
};

export const getSyncedData = (state, profileId) => {
  return selectors.getType(state.devices, types.SYNCED_DATA, profileId);
};

export const getDisconnectedDevices = (state, profileId, healthKitUrl, googleFitUrl) => {
  const dataRec = selectors.getData(state.devices, types.DISCONNECTED_DEVICES, profileId);
  const terraDevicesRec = selectors.getData(state.devices, types.TERRA_DEVICES, profileId);
  const activeTerraDevices = getActiveTerraDevices(state,profileId);
  const isAppleConnected = terraDevicesRec && terraDevicesRec.length > 0 && terraDevicesRec.filter(x => x.deviceSourceName === "APPLE" && x.isConnected === true).length > 0;

  let terraConnectedDevices;
  if (terraDevicesRec && terraDevicesRec !== undefined ) {
    terraConnectedDevices = [...terraDevicesRec];
  }

  let data;
  if (dataRec && dataRec !== undefined) {
    data = [...dataRec];
  }
  let sortedSource = data;

  if ( data && data !== undefined ) {
    let appleDevice = data.find(device => device.type === AppleHealthType);
    if (appleDevice) {
      appleDevice.logo_url = appleHealthLogo;
      appleDevice.connect_url = healthKitUrl;
      appleDevice.display_name = appleHealthName;
    }
    else{
      if(!isAppleConnected){
        data.push({
          type: AppleHealthType,
          logo_url : appleHealthLogo,
          connect_url : healthKitUrl,
          display_name : appleHealthName,
          connected : false
        });
      }
    }
    /// Add Google Fit for Android device

    if(isAndroid)
    {
      data.push({
        type:GoogleFitType,
        logo_url : googleFitLogo,
        connect_url : googleFitUrl,
        display_name : googleFitName,
        connected : false
      });
    }
  }

  if (data && terraConnectedDevices && activeTerraDevices && activeTerraDevices.length > 0)
  {
    var filteredTerraDevices = activeTerraDevices.filter(x=>
      !terraConnectedDevices.find(d=> d.deviceSourceName.toLowerCase() === x.deviceSourceName.toLowerCase()
                                                 && d.isConnected == true));
    data = addTerraDevices(state, profileId, filteredTerraDevices, data, false);
  }
  if (data) {
    let DisconnectedDevices = data.filter(x => x.connected === false);
    sortedSource = DisconnectedDevices.sort((a, b) => {
      const compareName = a.display_name.localeCompare(b.display_name);
      return compareName;
    });
  }

  return sortedSource;
};

export const isgetDisconnectedDevicesLoading = (state, profileId) => {
  return selectors.isLoading(state.devices, types.DISCONNECTED_DEVICES, profileId);
};

export const isgetDisconnectedDevicesLoaded = (state, profileId) => {
  return selectors.isLoaded(state.devices, types.DISCONNECTED_DEVICES, profileId);
};

export const getConnectedDevices = (state, profileId) => {
  return selectors.getData(state.devices, types.CONNECTED_DEVICES, profileId);
};

export const getValidicUserId = (state, profileId) => {
  const data = selectors.getData(state.devices, types.VALIDIC_PROFILE, profileId);
  return data && data.validicId;
};

export const getTerraUrl = (state, profileId) => {
  const data = selectors.getData(state.devices, types.TERRA_PROFILE, profileId);
  return data;
};

export const terraRedirectUrlParams = () =>{
  const query = getQueryStringParams(location);
  return query;
};

export const addTerraDevices = (state, profileId, terraConnectedDevices, data, connected) =>{
  const terraActiveDevices = getActiveTerraDevices(state,profileId);
  terraConnectedDevices && terraActiveDevices && terraActiveDevices.length > 0 && terraConnectedDevices.length > 0 && terraConnectedDevices.map(device => {
    if(connected == true){
      if(!data?.find(d => d.type.toLowerCase() === device.deviceSourceName.toLowerCase()
      && d.connected == connected) && device.isConnected == true)
      {
        data.push({
          type: device?.deviceSourceName,
          provider: terraProvider,
          providerUserId: device?.providerUserId,
          logo_url : terraActiveDevices.find(a=>a.deviceSourceName == device?.deviceSourceName)?.logoUrl,
          display_name : device?.deviceDisplayName,
          connect_url : '',
          connected : connected
        });
      }
    }
    else{
      if(!data?.find(d => d.type.toLowerCase() === device.deviceSourceName.toLowerCase()
          && d.connected == connected) && device.isActive == true)
      {
        data.push({
          type: device?.deviceSourceName,
          provider: terraProvider,
          providerUserId: '',
          logo_url : terraActiveDevices.find(a=>a.deviceSourceName == device?.deviceSourceName)?.logoUrl,
          display_name : device?.deviceDisplayName,
          connect_url : '',
          connected : connected
        });
      }
    }
  });

  return data;
};

export const getActiveTerraDevices = (state, profileId) =>{
  const activeDeviceRec = selectors.getData(state.devices, types.ACTIVE_DEVICES, profileId);
  let activeDevices;
  if (activeDeviceRec && activeDeviceRec !== undefined && activeDeviceRec.length > 0) {
    activeDevices = [...activeDeviceRec];
  }

  if(!activeDevices && activeDevices == undefined){
    return null;
  }
  const terraDevices = activeDevices.filter(x=>x.healthDataSource === HealthDataSource.Terra);
  return terraDevices;
};
