export const types = {
  GOALS: 'goals',
  GOAL_CATEGORY: 'goalCategory',
  SELECTED_GOAL_ID:'selectedGoalId',
  CLEAR_GOALS: 'clearGoals',
  GOAL_SUMMARY: 'goalSummary',
  IS_GOAL_BASED_ENROLLMENT: 'isGoalBasedEnrollment',
  PROFILE_OBSERVATION_GOALS: 'profileObservationGoals',
  SET_STEP_GOAL: 'setStepGoal',
  SET_NUTRITION_GOAL: 'setNutritionGoal',
  SET_BLOOD_PRESSURE_GOAL: 'setBloodPressureGoal',
  SET_BLOOD_GLUCOSE_GOAL: 'setBloodGlucoseGoal',
  SET_BLOOD_PRESSURE_ERROR_FLAG: 'setBloodPressureErrorFlag',
  SET_NUTRITION_ERROR_FLAG: 'setNutritionErrorFlag',
  SET_BLOOD_GLUCOSE_ERROR_FLAG: 'setBloodGlucoseErrorFlag',
  PROFILE_GOALS: 'profileGoals',
  CLEAR_PROFILE_GOALS: 'clearProfileGoals'
};

