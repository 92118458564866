/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileId } from 'app/selectors';
import { Button, Page } from 'shared';
import local from 'services/localization/local';
import ObservationGoalCard from './ObservationGoalCard';
import { clearProfileGoalsData, loadProfileObservationGoals, saveObservationGoals, setBloodGlucoseErrorFlag, setBloodGlucoseObservationGoal, setBloodPressureErrorFlag, setBloodPressureObservationGoal, setNutitionObservationGoal, setNutritionErrorFlag, setStepObservationGoal } from './goal-actions';
import { getBloodPressureGoalFormFieldValues, getGoalDetails, getInitialFormDetails, getProfileObservationGoals } from './goal-selectors';
import { buildPath, routePaths } from 'services/routing';
import moment from 'moment';
import { GoalType, ObservationDetailType, ObservationType, RecurringType, Unit } from 'services/enums';
import { ConvertGramsToPercent, getUniqueId } from 'services/helpers';
import { withRouter } from 'react-router';
import withNavigate from 'app/withNavigation';
import * as constant from 'services/constants';

function ObservationGoal(props) {

  const dispatch = useDispatch();
  const programEnrollmentId = props?.match?.params?.programEnrollmentId;
  const programId = props?.match?.params?.programId;
  const profileId = useSelector(getProfileId);
  const profileObservationGoals = useSelector(state => getProfileObservationGoals(state, profileId));
  const goalDetails = useSelector(state => getGoalDetails(state));
  const stepGoal = goalDetails.setStepGoal;
  const nutritionFormInputData = goalDetails.setNutritionGoal;
  const bloodPressureFormInputData = goalDetails.setBloodPressureGoal;
  const bloodPressureErrorFlag = goalDetails.setBloodPressureErrorFlag;
  const nutritionErrorFlag = goalDetails.setNutritionErrorFlag;
  const bloodGlucoseErrorFlag = goalDetails.setBloodGlucoseErrorFlag;
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    dispatch(clearProfileGoalsData(profileId));
  }, []);

  useEffect(() => {
    if (profileId) {
      dispatch(loadProfileObservationGoals(profileId, programEnrollmentId));
    }
  }, [profileId]);

  function onStepsChange(newValue) {
    if (newValue >= 0) {
      dispatch(setStepObservationGoal(profileId, newValue));
    }
  }

  function getInitialFormDataValues() {
    return getInitialFormDetails(profileObservationGoals);
  }

  function disptachNutritionData(input) {
    dispatch(setNutitionObservationGoal(profileId, input));
    let calorieFormValue = input.calorieGoal;
    let carbsFormValue = input.carbs;
    let fatFormValue = input.fat;
    let protienFormValue = input.protein;

    if (validateNutritionFormValues(calorieFormValue, carbsFormValue, fatFormValue, protienFormValue)) {
      dispatch(setNutritionErrorFlag(profileId, true));
    }
    else {
      dispatch(setNutritionErrorFlag(profileId, false));
    }
  }

  function dispatchBloodPressureData(input) {
    dispatch(setBloodPressureObservationGoal(profileId, input));
    let systolicMin = input.systolicMin;
    let systolicMax = input.systolicMax;
    let diastolicMin = input.diastolicMin;
    let diastolicMax = input.diastolicMax;
    if (validateBloodPressureFormValues(systolicMin, systolicMax, diastolicMin, diastolicMax)) {
      dispatch(setBloodPressureErrorFlag(profileId, true));
    }
    else {
      dispatch(setBloodPressureErrorFlag(profileId, false));
    }
  }

  function dispatchBloodGlucoseData(input) {
    dispatch(setBloodGlucoseObservationGoal(profileId, input));
    let fromValue = input.fromValue;
    let toValue = input.toValue;
    if (validateBloodGlucoseFormValues(fromValue, toValue)) {
      dispatch(setBloodGlucoseErrorFlag(profileId, true));
    }
    else {
      dispatch(setBloodGlucoseErrorFlag(profileId, false));
    }
  }

  function validateNutritionFormValues(calorieFormValue, carbsFormValue, fatFormValue, protienFormValue) {
    return calorieFormValue == '' || calorieFormValue == undefined || carbsFormValue == '' || carbsFormValue == undefined || fatFormValue == '' || fatFormValue == undefined || protienFormValue == '' || protienFormValue == undefined;
  }

  function validateBloodPressureFormValues(systolicMin, systolicMax, diastolicMin, diastolicMax) {
    return systolicMin == '' || systolicMin == undefined || systolicMax == '' || systolicMax == undefined || diastolicMin == '' || diastolicMin == undefined || diastolicMax == '' || diastolicMax == undefined;
  }

  function validateBloodGlucoseFormValues(fromValue, toValue) {
    return fromValue == '' || fromValue == undefined || toValue == '' || toValue == undefined;
  }

  function dispatchBloodPressureFormErrorFlag(flag) {
    dispatch(setBloodPressureErrorFlag(profileId, flag));
  }

  function dispatchNutritionFormErrorFlag(flag) {
    dispatch(setNutritionErrorFlag(profileId, flag));
  }

  function dispatchBloodGlucoseFormErrorFlag(flag) {
    dispatch(setBloodGlucoseErrorFlag(profileId, flag));
  }

  function handleComplete() {
    let calories = null;
    let carbs = null;
    let fat = null;
    let protein = nutritionFormInputData.protein;
    let requestId = getUniqueId();
    let observationGoals = [];
    let url = buildPath(routePaths.programsDetails, { programId: programId, programEnrollmentId: programEnrollmentId });

    if (profileObservationGoals && profileObservationGoals.observationTypes.length > 0) {
      if (profileObservationGoals.observationTypes.indexOf(ObservationType.Nutrition) > -1) {
        let calorieFormValue = nutritionFormInputData.calorieGoal;
        let carbsFormValue = nutritionFormInputData.carbs;
        let fatFormValue = nutritionFormInputData.fat;
        let protienFormValue = nutritionFormInputData.protein;

        if (validateNutritionFormValues(calorieFormValue, carbsFormValue, fatFormValue, protienFormValue)) {
          setHasError(true);
          return;
        }

        calories = isNaN(Number(calorieFormValue)) === true ? 0 : Number(calorieFormValue);
        carbs = isNaN(Number(carbsFormValue)) === true ? 0 : Number(carbsFormValue);
        fat = isNaN(Number(fatFormValue)) === true ? 0 : Number(fatFormValue);
        protein = isNaN(Number(protienFormValue)) === true ? 0 : Number(protienFormValue);

        if ((nutritionFormInputData.displayUnit === Unit.Percent) && (carbs + protein + fat) != constant.MAX_NUTRITION_PERCENT) {
          setHasError(true);
          return;
        }

      }
      profileObservationGoals.observationTypes.map((goalType) => {
        if (goalType == ObservationType.BloodGlucose) {
          let bloodGlucoseFormDataGoal = coinBloodGlucoseGoal();
          observationGoals.push(bloodGlucoseFormDataGoal);
        }
        if ((goalType == ObservationType.Systolic) || (goalType == ObservationType.Diastolic)) {
          let systolicFormDataGoal = coinSystolicGoal();
          observationGoals.push(systolicFormDataGoal);
          let diastolicFormDataGoal = coinDiastolicGoal();
          observationGoals.push(diastolicFormDataGoal);
        }
        if (goalType == ObservationType.Nutrition) {
          let nutritionFormDataGoal = coinNutritionObservation(calories, carbs, protein, fat);
          observationGoals.push(nutritionFormDataGoal);
        }
        if (goalType == ObservationType.Steps) {
          let stepObservationGoal = coinStepObservationGoal();
          observationGoals.push(stepObservationGoal);
        }
      });
      dispatch(saveObservationGoals(profileId, observationGoals, requestId));
      props.navigate({ to: url });
    }
  }

  function coinSystolicGoal() {
    return {
      profileId: profileId,
      observationType: ObservationType.Systolic,
      unit: Unit.MillimetersOfMercury,
      startDate: moment().format(),
      frequency: RecurringType.Daily,
      isActive: true,
      goalType: GoalType.RangeValue,
      fromValue: bloodPressureFormInputData.systolicMin,
      toValue: bloodPressureFormInputData.systolicMax
    };
  }

  function coinDiastolicGoal() {
    return {
      profileId: profileId,
      observationType: ObservationType.Diastolic,
      unit: Unit.MillimetersOfMercury,
      startDate: moment().format(),
      frequency: RecurringType.Daily,
      isActive: true,
      goalType: GoalType.RangeValue,
      fromValue: bloodPressureFormInputData.diastolicMin,
      toValue: bloodPressureFormInputData.diastolicMax
    };
  }

  function coinBloodGlucoseGoal() {
    var inputvalues = getBloodPressureGoalFormFieldValues();
    return {
      profileId: profileId,
      observationType: ObservationType.BloodGlucose,
      fromValue: inputvalues.fromValue,
      toValue: inputvalues.toValue,
      unit: Unit.MilligramsPerDeciliter,
      startDate: moment().format(),
      goalType: GoalType.RangeValue,
      frequency: RecurringType.Daily,
      isActive: true
    };
  }

  function coinNutritionObservation(calories, carbs, protein, fat) {
    //Note : If Nutrition form is not touched then we need to convert the grams to percent to store in Db
    let carbsValue = carbs;
    let proteinValue = protein;
    let fatValue = fat;
    if (!nutritionFormInputData.formTouch && nutritionFormInputData.displayUnit == Unit.Grams) {
      carbsValue = ConvertGramsToPercent(carbs, nutritionFormInputData.totalGrams).actualValue;
      proteinValue = ConvertGramsToPercent(protein, nutritionFormInputData.totalGrams).actualValue;
      fatValue = ConvertGramsToPercent(fat, nutritionFormInputData.totalGrams).actualValue;
    }
    return {
      profileId: profileId,
      observationType: ObservationType.Nutrition,
      value: calories,
      unit: Unit.Calories,
      startDate: moment().format(),
      frequency: RecurringType.Daily,
      isActive: true,
      displayUnit: nutritionFormInputData.displayUnit,
      totalForPercentageCalc: nutritionFormInputData.totalGrams,
      goalDetails: [
        {
          observationDetailType: ObservationDetailType.Carbohydrate,
          value: carbsValue ?? 0,
          Unit: Unit.Percent
        },
        {
          observationDetailType: ObservationDetailType.Protein,
          value: proteinValue ?? 0,
          Unit: Unit.Percent
        },
        {
          observationDetailType: ObservationDetailType.Fat,
          value: fatValue ?? 0,
          Unit: Unit.Percent
        }
      ]
    };
  }

  function coinStepObservationGoal() {
    return {
      profileId: profileId,
      observationType: ObservationType.Steps,
      value: stepGoal,
      unit: Unit.Count,
      startDate: moment(constant.stepsStartDateTime).format(),
      frequency: RecurringType.Daily,
      isActive: true
    };
  }

  return (
    <Page
      id='observation-goal-component'
      backPath={{ to: props.location?.state?.backpath?.fromPath }}
      centerElement={local.observationGoalTitle}
      loading={false}>

      <div className="formatted-text observation-goal-note" data-testid='ObservationGoal'>
        <div className="stepGoal-Content">{local.setTargetMessage}</div>
      </div>
      {
        profileObservationGoals && profileObservationGoals.observationTypes.length > 0 ?
          <div>
            {
              profileObservationGoals.observationTypes.map((observationType) => {
                if (observationType == ObservationType.Diastolic) {
                  return null;
                }
                return (<ObservationGoalCard
                  key={observationType}
                  observationType={observationType}
                  hasError={hasError}
                  profileId={profileId}
                  programId={programId}
                  programEnrollmentId={programEnrollmentId}
                  observationTypes={profileObservationGoals.observationTypes}
                  onStepsChange={onStepsChange}
                  initialValues={getInitialFormDataValues()}
                  dispatchBloodGlucoseData={dispatchBloodGlucoseData}
                  dispatchBloodGlucoseFormErrorFlag={dispatchBloodGlucoseFormErrorFlag}
                  dispatchBloodPressureFormErrorFlag={dispatchBloodPressureFormErrorFlag}
                  dispatchNutritionFormErrorFlag={dispatchNutritionFormErrorFlag}
                  dispatchBloodPressureData={dispatchBloodPressureData}
                  disptachNutritionData={disptachNutritionData} />
                );
              })
            }
          </div>
          : null
      }
      <div className="observation-goal-action">
        {
          nutritionErrorFlag || bloodPressureErrorFlag || bloodGlucoseErrorFlag ?
            <Button id="observation-goal-btn" color="blue" className="btn btn-primary" onClick={handleComplete} disabled>
              {local.complete}
            </Button> :
            <Button id="observation-goal-btn" color="blue" className="btn btn-primary" submit onClick={handleComplete} >
              {local.complete}
            </Button>
        }
      </div>

    </Page>
  );
}

export default withNavigate(withRouter(ObservationGoal));
