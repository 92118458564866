import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { routePaths } from 'services/routing';
import { Icon, ModalWrapper, Button, NavIcon } from 'shared';
import useBadgeSummaryStatus from 'badge/useBadgeSummaryStatus';
import local from 'services/localization/local';
import { Link } from 'react-router-dom';
import { getProfileId } from 'app/selectors';
import { updateShowBadgeAchievedScreen, setBadgeNotified } from 'badge/action-creators';
import withContext from 'app/withContext';
import PropTypes from 'prop-types';
import { getBadgeIcon } from './badge-helpers';
import { BadgeType } from 'services/enums';

export function BadgeEarned(props) {
  const dispatch = useDispatch();
  const { toNotifyBadge } = useBadgeSummaryStatus();
  const profileId = useSelector(getProfileId);
  const content = local.healthBadge.notification;
  const [isSplashShown, setSplashShown] = useState(false);

  useEffect(() => {
    if (props.location?.pathname != routePaths.root && toNotifyBadge) {
      dispatch(updateShowBadgeAchievedScreen(false, 0));
      dispatch(setBadgeNotified(profileId, toNotifyBadge.id));
      setSplashShown(true);
    }
  }, [toNotifyBadge]);

  function CloseModal() {
    setSplashShown(false);
  }

  if(props.location.pathname == routePaths.root)
    return null;

  return (
    <ModalWrapper className='card-modal-wrapper upgrade-pathway-modal' isOpen={isSplashShown} onRequestClose={CloseModal} >
      <div className='card-modal achievement-Badge'>
        <div className="content">
          <div className="confetti">
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
            <div className="confetti-piece"></div>
          </div>
          <div>
            <div className="congrats-header">{content.congratulations}</div>
            <div className="congrats-illustrator">
              {toNotifyBadge?.badge?.badgeType?.badgesType != BadgeType.ProgramChallenge ?
                <NavIcon Icon={getBadgeIcon(toNotifyBadge)}></NavIcon>:
                <img className='program-challenge-badge' src={getBadgeIcon(toNotifyBadge)}></img>}
            </div>
            <div className="congrats-content"> {content.earnedABadge} {content.greatWork} </div>
          </div>
          <div className="sticky-button-section action" onClick={CloseModal}>
            <Link to={routePaths.healthBadgeSummary} className="button blue btn btn-primary">{content.viewBadge}</Link>
          </div>
        </div>
        <Button id='close-badge-modal' className='card-modal-close' onClick={CloseModal} data-testid='public-health-badge-modal-close'><Icon symbol='times' /></Button>
      </div>
    </ModalWrapper>
  );
}

BadgeEarned.propTypes = {
  location: PropTypes.shape()
};

export default withContext(BadgeEarned);
