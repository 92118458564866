/* eslint-disable react/button-has-type */
import React,{useRef} from 'react';
import PropTypes from 'prop-types';
import withContext from 'app/withContext';

export function ProgramTab(props) {
  const myRef = useRef(null);
  const pathway = 'pathway-list-';
  const tab = '-tab';
  const onClick = () => {
    props.onClick(props.label);
    if(myRef && myRef.current){
      myRef.current.scrollIntoView();
    }
  };

  return (
    <li
      className={props.activeTab === props.label ? 'tab-list-item tab-list-active' : 'tab-list-item'}
      data-test-id={pathway.concat(props.label,tab)}
      id={pathway.concat(props.label,tab)}
      onClick={(() => onClick())} ref={myRef}>
      {props.label}
    </li>
  );
}
ProgramTab.propTypes = {
  activeTab: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default withContext(ProgramTab);
