import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withContext from 'app/withContext';
import { getProfileName } from 'profiles/list/selectors';
import { getIsCurrentProfileOwned } from 'profiles/profiles-selectors';
import { ProfileLink, Icon } from 'shared';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';

class ManageProfileNav extends Component {
  render() {
    const { isOwned } = this.props;

    return (
      <ul className="scrollview-list">
        {isOwned && (
          <li className="scrollview-list-item">
            <ProfileLink
              id="sharing-admin-link"
              className='with-padding with-chevron'
              to={routePaths.sharingAdmin}>
              <Icon symbol="shareprofile" />
              <span>{local.sharing.sharingTitle}</span>
            </ProfileLink>
          </li>
        )}
      </ul>
    );
  }
}

ManageProfileNav.propTypes = {
  isOwned: PropTypes.bool.isRequired
};

ManageProfileNav.defaultProps = {
};

function mapStateToProps(state) {
  return {
    profileName: getProfileName(state),
    isOwned: getIsCurrentProfileOwned(state)
  };
}

export default withContext(connect(mapStateToProps)(ManageProfileNav));
