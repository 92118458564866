import * as dataSelectors from 'app/request/persistent-data-selectors';
import * as requestQueueSelectors from 'app/request/request-queue-selectors';
import { types } from './organizations-types';
import local from 'services/localization/local';
import { OrganizationMemberRole } from 'services/enums';


export const getOrganizationsForSelf = (state) => {
  const identityId = state.identity && state.identity.self.id;
  const orgsInfo = dataSelectors.getType(state.organization, types.ORGANIZATIONS, identityId);
  const sortedData = orgsInfo.data ? orgsInfo.data.sort((a, b) => {
    return (a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0);
  }) : [];

  const result = { ...orgsInfo, data: sortedData };

  return result;
};

export const getOrganizationTypeById = (state, id) => {
  const orgs = getOrganizationsForSelf(state);
  return { ...orgs, data: orgs.data.find(x => x.id === id) };
};

export const getOrganizationDetails = (organizationId, state) => {
  const orgMembersInfo = dataSelectors.getType(state.organization, types.ORGANIZATION_DETAILS, organizationId);

  const sortedData = orgMembersInfo.data ? orgMembersInfo
    .data
    .filter(x => x.organizationId === organizationId)
    .sort((a, b) => {
      return (a.profileName < b.profileName) ? -1 : ((a.profileName > b.profileName) ? 1 : 0);
    }) : [];

  const organizationName = orgMembersInfo.data && orgMembersInfo.data.length > 0 && orgMembersInfo.data[0].organizationName || local.loading;

  const result = { ...orgMembersInfo, data: sortedData, organizationName };

  return result;
};

export const getCreateOrganizationRequest = (state, requestId) => {
  return requestQueueSelectors.getRequestById(state.organization, types.ORGANIZATION_REQUESTS, requestId);
};

export const getOrganizationInvites = (state) => {
  const identityId = state.identity && state.identity.self.id;
  const invitesInfo = dataSelectors.getType(state.organization, types.ORGANIZATION_INVITES, identityId);
  const filteredInvites = invitesInfo.data ? invitesInfo.data.filter(x => x.role === OrganizationMemberRole.Member) : [];
  const sortedData = filteredInvites.sort((a, b) => {
    return (a.organizationName < b.organizationName) ? -1 : ((a.organizationName > b.organizationName) ? 1 : 0);
  });

  const result = { ...invitesInfo, data: sortedData };

  return result;
};
export const getRecentPendingInviteOrganizationMember = (state) => {
  const identityId = state.identity && state.identity.self.id;
  const invite = dataSelectors.getData(state.organization, types.ORGANIZATION_RECENT_PENDING_INVITE, identityId) || [];
  return invite;
};

export const getOrganizationInvitesCount = (state) => {
  const identityId = state.identity && state.identity.self.id;
  const invitesCount = dataSelectors.getData(state.organization, types.ORGANIZATION_INVITES_COUNT, identityId) || 0;
  return invitesCount;
};

export const getOrganizationInviteStatus = (state, organizationId) => {
  const identityId = state.identity && state.identity.self.id;
  const invitesType = dataSelectors.getType(state.organization, types.ORGANIZATION_INVITES, identityId);
  const membershipsType = dataSelectors.getType(state.organization, types.ORGANIZATION_DETAILS, organizationId);
  return {
    loaded: invitesType.loaded && membershipsType.loaded,
    loading: invitesType.loading && membershipsType.loading,
    hasError: invitesType.hasError && membershipsType.hasError,
    error: invitesType.error || membershipsType.error
  };
};

export const getMembershipExistsForProfile = (state, organizationId, profileId, role) => {
  const memberships = dataSelectors.getData(state.organization, types.ORGANIZATION_DETAILS, organizationId) || [];
  return memberships.find(m => m.profileId === profileId && m.role === role) !== undefined;
};

export const getInviteCodeFromRoute = (props) => {
  return (props && props.match && props.match.params && props.match.params.inviteCode) || '';
};

export const getMembershipIdFromRoute = (props) => {
  return props && props.match && props.match.params && props.match.params.membershipId;
};

export const getInviteByMembershipId = (state, membershipId) => {
  const identityId = state.identity && state.identity.self.id;
  const invites = dataSelectors.getData(state.organization, types.ORGANIZATION_INVITES, identityId) || [];
  return invites.find(x => { return x.organizationMembershipId === membershipId; });
};

export const getInviteResponseRequest = (state, requestId) => {
  return requestQueueSelectors.getRequestById(state.organization, types.ORGANIZATION_REQUESTS, requestId);
};

export const getOrganizationAdminsType = (state, organizationId) => {
  const adminsType = dataSelectors.getType(state.organization, types.ORGANIZATION_ADMINS, organizationId);
  const sortedData = adminsType.data ? adminsType.data.sort((a, b) => {
    const profileNameA = a.profileName || a.email;
    const profileNameB = b.profileName || b.email;
    return profileNameA.localeCompare(profileNameB);
  }) : [];
  return { ...adminsType, data: sortedData };
};

export const getOrganizationAdmins = (state, organizationId) => {
  const admins = dataSelectors.getType(state.organization, types.ORGANIZATION_ADMINS, organizationId);
  return admins;
};

export const HasCovidOrganization = (state) => {
  const identityId = state.identity && state.identity.self.id;
  const { loaded, data } = dataSelectors.getType(state.organization, types.ORGANIZATIONS, identityId);

  let isCovidOrganizationProduct = loaded && data && (data.find(x=> x.isCovidOrg) != undefined && data.find(x=> x.isCovidOrg).isCovidOrg == true) ? true : false;
  return { isCovidOrg: isCovidOrganizationProduct};
};

export const getWhiteLabel = (state) => {
  const identityId = state.identity && state.identity.self.id;
  const {loaded, loading, data} = dataSelectors.getType(state.organization, types.ORGANIZATION_WHITE_LABEL, identityId);
  return{
    loaded,
    loading,
    whiteLabel:data
  };
};
