import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'shared';
import './collapsable-list-view.scss';

function CollapsableListView({ id, title, defaultChecked, show, children }) {
  if (!children || !show) {
    return null;
  }

  return (
    <div className='collapsable-list-view'>
      <input id={id} className="task-section-toggle-checkbox" type="checkbox" defaultChecked={defaultChecked} />
      <label htmlFor={id} className="task-section-toggle">
        <small className="task-section-title"><strong>{title}</strong></small>
        <Icon className="task-section-open-close-indicator" symbol="arrowdown" />
      </label>
      <ul className="scrollview-list collapsable-list">
        {children}
      </ul>
    </div>
  );
}

CollapsableListView.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  show: PropTypes.bool,
  children: PropTypes.node
};

export default CollapsableListView;
