import compileReducers from 'services/compile-reducer';
import * as actions from './programs-actions';
import * as persistentDataReducer from 'app/request/persistent-data-reducer';
import * as requestQueueReducer from 'app/request/request-queue-reducer';
import { actionNames as requestQueueActionNames } from 'app/request/request-queue-actions';
import { programsContexts } from 'services/routing';
import { types } from './programs-types';

export const initialState = {
  [types.INFO]: persistentDataReducer.initialState,
  [types.PROGRAM]: persistentDataReducer.initialState,
  [types.ENROLLMENTS]: persistentDataReducer.initialState,
  [types.DETAILS]: persistentDataReducer.initialState,
  [types.PERMISSIONS]: persistentDataReducer.initialState,
  [types.ENROLL]: requestQueueReducer.initialState,
  [types.COMPLETE]: requestQueueReducer.initialState,
  [types.COMPLETETASK]: requestQueueReducer.initialState,
  [types.REFERRALS]: persistentDataReducer.initialState,
  [types.ORGANIZATION_PROGRAMS]: persistentDataReducer.initialState,
  [types.SEVERITY]: persistentDataReducer.initialState,
  [types.ACTIVE_USERS_PROGRAM_INVITES]: persistentDataReducer.initialState,
  [types.PENDING_PROGRAM_INVITES_SHOWN_COUNT]: persistentDataReducer.initialState,
  [types.SUGGESTED_PATHWAY]: persistentDataReducer.initialState,
  [types.RESOURCE_CONTENT_RATING]: persistentDataReducer.initialState,
  [types.LEADER_BOARD_SUMMARY]: persistentDataReducer.initialState,
  [types.HAS_ENROLLED_IN_ACTIVE_EVENT_PROGRAM]: persistentDataReducer.initialState,
  [types.GET_UPGRADED_OBSERVATIONS] : persistentDataReducer.initialState,
  [types.PENDING_INVITED_PROGRAMS] : persistentDataReducer.initialState,
  [types.DASHBOARD_PROGRAMS] : persistentDataReducer.initialState,
  [types.PROGRAM_INFOS]: persistentDataReducer.initialState,
  context: programsContexts.default,
  resetDetailsLoadCount: false,
  currentEnrollmentTaskInfo: undefined,
  CompleteReEnrollmentLoading:false
};

const reducers = {
  ...persistentDataReducer.reducers(types.INFO),
  ...persistentDataReducer.reducers(types.PROGRAM),
  ...persistentDataReducer.reducers(types.ENROLLMENTS),
  ...persistentDataReducer.reducers(types.DETAILS),
  ...persistentDataReducer.reducers(types.PERMISSIONS),
  ...requestQueueReducer.reducers(types.ENROLL),
  ...requestQueueReducer.reducers(types.COMPLETE),
  ...requestQueueReducer.reducers(types.COMPLETETASK),
  ...persistentDataReducer.reducers(types.REFERRALS),
  ...persistentDataReducer.reducers(types.ORGANIZATION_PROGRAMS),
  ...persistentDataReducer.reducers(types.SEVERITY),
  ...persistentDataReducer.reducers(types.ACTIVE_USERS_PROGRAM_INVITES),
  ...persistentDataReducer.reducers(types.PENDING_PROGRAM_INVITES_SHOWN_COUNT),
  ...persistentDataReducer.reducers(types.SUGGESTED_PATHWAY),
  ...persistentDataReducer.reducers(types.RESOURCE_CONTENT_RATING),
  ...persistentDataReducer.reducers(types.LEADER_BOARD_SUMMARY),
  ...persistentDataReducer.reducers(types.HAS_ENROLLED_IN_ACTIVE_EVENT_PROGRAM),
  ...persistentDataReducer.reducers(types.GET_UPGRADED_OBSERVATIONS),
  ...persistentDataReducer.reducers(types.PENDING_INVITED_PROGRAMS),
  ...persistentDataReducer.reducers(types.DASHBOARD_PROGRAMS),
  ...persistentDataReducer.reducers(types.PROGRAM_INFOS)
};

reducers[actions.PROGRAMS_SET_CONTEXT] = (state, payload) => ({
  ...state,
  context: payload.context
});

reducers[actions.PROGRAMS_RESET_LOAD_COUNT] = (state, payload) => ({
  ...state,
  resetDetailsLoadCount: payload.reset
});

reducers[actions.CURRENT_ENROLLMENT_TASK_DETAILS] = (state, payload) => ({
  ...state,
  currentEnrollmentTaskInfo: payload
});


reducers[actions.PROGRAMS_ADD_ENROLLMENT] = (state, payload) => {
  const { profileId, enrollment } = payload;

  return persistentDataReducer.updateDataStatus(state, types.ENROLLMENTS, profileId, {
    data: [...state[types.ENROLLMENTS][profileId].data, enrollment]
  });
};

reducers[actions.PROGRAMS_UPDATE_ENROLLMENT] = (state, payload) => {
  const { profileId, programEnrollmentId, enrollment } = payload;

  return persistentDataReducer.updateDataStatus(state, types.ENROLLMENTS, profileId, {
    data: state[types.ENROLLMENTS][profileId].data.map(x => x.id === programEnrollmentId ? enrollment : x)
  });
};

reducers[actions.COMPLETE_AND_REENROLLMENT_LOADING] = (state) => {
  return {
    ...state,
    CompleteReEnrollmentLoading: true
  };
};

reducers[actions.COMPLETE_AND_REENROLLMENT_LOADED] = (state) => {
  return {
    ...state,
    CompleteReEnrollmentLoading: false
  };
};


function updateTask(state, programEnrollmentTaskId, programEnrollmentId, taskUpdates) {
  const dataFunction = data => ({
    tasks: data.tasks.map(task =>
      task.programEnrollmentTaskId === programEnrollmentTaskId ?
        {
          ...task,
          ...taskUpdates
        } :
        task
    )
  });

  return persistentDataReducer.updateData(state, types.DETAILS, programEnrollmentId, dataFunction);
}

reducers[actions.PROGRAMS_UPDATE_TASK] = (state, payload) => {
  const { programEnrollmentTaskId, programEnrollmentId, task } = payload;

  return updateTask(state, programEnrollmentTaskId, programEnrollmentId, {
    ...task,
    updating: false
  });
};

reducers[requestQueueActionNames(types.COMPLETETASK).REQUEST_QUEUED] = (state, payload) => {
  const { requestId: programEnrollmentTaskId, params: { programEnrollmentId } } = payload;

  return updateTask(state, programEnrollmentTaskId, programEnrollmentId, {
    updating: true
  });
};

reducers[requestQueueActionNames(types.COMPLETETASK).REQUEST_QUEUE_ERROR] = (state, payload) => {
  const { requestId: programEnrollmentTaskId, params: { programEnrollmentId } } = payload;

  return updateTask(state, programEnrollmentTaskId, programEnrollmentId, {
    updating: false
  });
};

reducers[actions.PROGRAM_DETAILS_LOADED] = (state, payload) => {
  const { programDetails } = payload;

  return {
    ...state,
    programDetails
  };
};

export default compileReducers(reducers, initialState);
