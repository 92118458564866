import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import { buildPath, routePaths } from 'services/routing';
import { ProfileRedirect, ProfileLink } from 'shared';
import ProgramEnrollButton from './ProgramEnrollButton';
import * as selectors from 'programs/programs-selectors';
import './featured-program-tile.scss';

export default function FeaturedProgramTile({ program, allowRedirect }) {
  if (!program) return null;

  const durationMessage = local.formatString((program.durationInDays === 1) ? local.programs.enrollDurationShortSingular : local.programs.enrollDurationShort, program.durationInDays);
  const activeEnrollment = useSelector(state => selectors.getActiveEnrollmentForProgram(state, program.programId));

  if (allowRedirect && activeEnrollment) {
    const { id: programEnrollmentId } = activeEnrollment;
    return <ProfileRedirect to={buildPath(routePaths.programsDetails, { programId: program.programId, programEnrollmentId })} />;
  }

  const heroElement = program.headerImagePath
    ? <div className='hero-image' style={{ backgroundImage: `url(${program.headerImagePath})` }} />
    : <figure className='hero-image'></figure>;

  const iconElement = program.iconPath
    ? <img className='icon-image' src={program.iconPath} />
    : <figure className='icon-image'></figure>;

  const logName = `program-referral-${program.name.toLowerCase().replace(/\s/g, '-')}`;
  return (
    <ProfileLink
      id={`referral-link-${program.id}`}
      logName={logName}
      to={routePaths.programsEnroll}
      params={{programId: program.programId}}>
      <div className="program-feature-tile">
        {heroElement}
        <div className="program-feature-info">
          {iconElement}
          <div className="program-title">{program.name}</div>
          <small className="program-description">{program.shortDescription}</small>
          <small className="program-duration">{durationMessage}</small>
          <ProgramEnrollButton className="program-start-button" programInfoId={program.id} id={logName} />
        </div>

      </div>
    </ProfileLink>
  );
}

FeaturedProgramTile.propTypes = {
  program: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    iconPath: PropTypes.string,
    headerImagePath: PropTypes.string,
    durationInDays: PropTypes.number,
    programId: PropTypes.string,
    shortDescription: PropTypes.string
  }),
  allowRedirect: PropTypes.bool
};
