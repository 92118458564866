import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ModalWrapper } from 'shared';
import { getProgramEnrollmentById, getProgramDetails, getUpgradedObservations } from 'programs/programs-selectors';
import useEnrollmentOutdated from 'programs/shared/useEnrollmentOutdated';
import ProgramReEnroller from './ProgramReEnroller';
import local from 'services/localization/local';
import './program-upgrade-modal.scss';
import { getProfileId } from 'app/selectors';
import { getUniqueId } from 'services/helpers';
import { buildPath, routePaths } from 'services/routing';
import * as selectors from 'programs/programs-selectors';
import * as requestSelectors from 'app/request/request-selectors';
import { completeAndReEnrollInProgram, loadProgramPermissions, loadUpgradedObservations } from 'programs/programs-actions';
import { ProfileRedirect } from 'shared/redirect/ProfileRedirect';

export default function ProgramUpgradeModal({ programEnrollmentId, startOpen = false }) {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(startOpen);
  const [closeUpgradeLink, setCloseUpgradeLink] = useState(false);
  const [isReEnrollProcessed, setIsReEnrollProcessed] = useState(false);
  const closeModal = () =>  setIsModalOpen(false);
  const openModal = () => {
    setIsModalOpen(true);
    setCloseUpgradeLink(true);
  };

  const profileId = useSelector(state => getProfileId(state));
  const completeRequestId = useRef(getUniqueId());

  const enrollmentInfo = useSelector(state => getProgramEnrollmentById(state, programEnrollmentId));
  const enrollmentDetails = useSelector(state => getProgramDetails(state, programEnrollmentId));
  const enrollmentOutdated = useEnrollmentOutdated(programEnrollmentId);
  const upgradedObservations = useSelector(state => getUpgradedObservations(state, enrollmentDetails.programId));
  const activeEnrollment = useSelector(state => selectors.getActiveEnrollmentForProgram(state, enrollmentInfo?.program?.programId));

  const completeRequest = useSelector(state => requestSelectors.getRequest(state, completeRequestId.current));
  const { loading } = useSelector(state => selectors.getCompleteAndReEnrollment(state));

  const isEnrolled = !!activeEnrollment;

  useEffect(() => {
    dispatch(loadUpgradedObservations(enrollmentDetails.programId));
    dispatch(loadProgramPermissions(enrollmentInfo?.program?.programId));
  }, []);

  useEffect(() => {
    if (enrollmentOutdated && upgradedObservations && !upgradedObservations.upgraded && !isReEnrollProcessed && loading == false && enrollmentDetails && !enrollmentDetails.complete) {
      handleSilentReEnroll();
    }
  }, [enrollmentInfo, enrollmentOutdated, upgradedObservations, loading, enrollmentDetails]);

  const handleSilentReEnroll = () => {
    if (enrollmentInfo) {
      const optInOrganizationIds = enrollmentInfo.organizationId ? [enrollmentInfo.organizationId] : [];
      setIsReEnrollProcessed(true);
      dispatch(completeAndReEnrollInProgram(profileId, programEnrollmentId, optInOrganizationIds, completeRequestId.current, true));
    }
  };

  if (completeRequest.success && isReEnrollProcessed && isEnrolled) {
    const routeParams = {
      programId: activeEnrollment.program.programId,
      programEnrollmentId: activeEnrollment.id
    };

    return <ProfileRedirect to={buildPath(routePaths.programsDetails, routeParams)} />;
  }

  if (!enrollmentInfo || !enrollmentOutdated || !upgradedObservations) {
    return null;
  }

  if (enrollmentOutdated && upgradedObservations && !upgradedObservations.upgraded) {
    return null;
  }

  return (
    <React.Fragment>
      {
        !closeUpgradeLink &&
        <div className='banner pathway-upgrade-modal-toggle' onClick={openModal} data-testid='toggle'>
          <span>{local.programs.upgradeAvailableBanner}</span>
        </div>
      }

      <ModalWrapper className='card-modal-wrapper upgrade-pathway-modal' isOpen={isModalOpen} onRequestClose={closeModal}>
        <div className='card-modal'>
          <div className="content">
            <p className="modal-header-title">{local.programs.upgradeContentHeader}</p>
            <div className="content-inner">
              <p>{local.formatString(local.programs.upgradeObservationHeaderContent, enrollmentInfo.program.name)}</p>
              <ul>
                {upgradedObservations.observationTypes.map((observationType, index) =>
                  (
                    <li className='li-style' key={'observationType-' + index}>{observationType}</li>
                  ))}
              </ul>
              <br />
              <p>{local.programs.upgradeObservationFooterContent1}</p>
              <p>{local.programs.upgradeObservationFooterContent2}</p>
            </div>
            <div className="sticky-button-section action">
              <ProgramReEnroller
                enrollProgramId={enrollmentInfo.program.programId}
                completedEnrollmentId={programEnrollmentId}
                closeModalRequest={closeModal}
                upgrade />
            </div>
          </div>
        </div>
      </ModalWrapper>

    </React.Fragment>
  );
}

ProgramUpgradeModal.propTypes = {
  programEnrollmentId: PropTypes.string.isRequired,
  startOpen: PropTypes.bool
};
