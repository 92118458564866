import { defaultStatus } from './persistent-data-reducer';

export const getType = (state, type, uniqueId) => {
  if (!state[type]) throw `Invalid type in selectors: ${type}`;

  return (uniqueId && state[type][uniqueId] && state[type][uniqueId]) || defaultStatus;
};

export const getData = (state, type, uniqueId) => {
  return getType(state, type, uniqueId).data;
};

export const isLoaded = (state, type, uniqueId) => {
  const types = Array.isArray(type) ? type : [type];

  return types.reduce((prev, item) =>
    (prev && getType(state, item, uniqueId).loaded), true);
};

export const isLoading = (state, type, uniqueId) => {
  const types = Array.isArray(type) ? type : [type];

  return types.reduce((prev, item) =>
    (prev || getType(state, item, uniqueId).loading), false);
};

export const hasError = (state, type, uniqueId) => {
  const types = Array.isArray(type) ? type : [type];

  return types.reduce((prev, item) =>
    (prev || getType(state, item, uniqueId).hasError), false);
};
