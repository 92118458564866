import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { logClick } from 'services/appinsights-service';
import './textAction.scss';

class TextAction extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { id, logName, onClick } = this.props;

    logClick(logName || id);
    onClick();
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { id, children, logName, ...rest } = this.props;
    return (
      <a id={id} onClick={this.onClick} className="hc-link" {...rest}>
        {children}
      </a>
    );
  }
}

TextAction.propTypes = {
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  logName: PropTypes.string,
  children: PropTypes.node
};

export default TextAction;
