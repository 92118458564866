/* eslint-disable react/prop-types */
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ExternalLink, Icon, DataRow } from 'shared';
import ResourceRating from 'shared/data-row/ResourceRating';
import { checkResource, createResourceRating, updateResourceRating as updateRating, loadResourceRatingDetails } from 'programs/programs-actions';
import ProgramResourceComments from 'programs/details/ProgramResourceComments';
import * as selectors from 'programs/programs-selectors';

export default function ProgramResource({ resource, programEnrollmentId, organizationId, resourceRating, profileId, isCovidOrganization }) {
  const { programResourceId, actionUrl, name, description } = resource;
  const [showModal, setModal] = useState(false);
  var [rating, setResourceRating] = useState(null);
  const dispatch = useDispatch();
  const resourcesRatingStatus = useSelector(state => selectors.getProgramEnrollmentResourcesRatingStatus(state, programEnrollmentId));

  function updateResourceRating(isHelpful) {
    setResourceRating(isHelpful);
    setModal(true);
  }
  function updateResourceComments(comments) {
    return {
      id: resourceRating?.id != '' ? resourceRating?.id : '',
      comments: comments,
      isHelpful: rating,
      programEnrollmentId: programEnrollmentId,
      programResourceId: programResourceId
    };
  }
  const onClick = useCallback(
    () => dispatch(checkResource(programResourceId, programEnrollmentId)),
    [dispatch, programResourceId, programEnrollmentId]
  );

  function handleClose(comments) {
    setModal(false);
    saveResourceRating(updateResourceComments(comments));
  }

  function handleSubmit(comments) {
    setModal(false);
    saveResourceRating(updateResourceComments(comments));
  }

  function saveResourceRating(model) {
    setModal(false);
    if (resourceRating && resourceRating.id != '') {
      dispatch(updateRating(model, profileId),
        resourcesRatingStatus.loaded = true
      );
    } else {
      dispatch(createResourceRating(model, profileId),
        resourcesRatingStatus.loaded = true
      );
    }
    dispatch(loadResourceRatingDetails(programEnrollmentId, profileId, organizationId));
  }
  useEffect(() => {
    if (resourceRating.id != '') {
      setResourceRating(resourceRating.isHelpful);
    }
  }, []);
  return (
    <div>
      <div className="resource-section" id="resourceRating" onClick={onClick}>
        <ExternalLink
          id={`resource-${programResourceId}`}
          logName="pathway-resource"
          openNewTab
          href={actionUrl}>
          <DataRow
            id={programResourceId}
            visual={<Icon symbol="healthprograms" />}
            head={name}
            subhead={description}>
          </DataRow>
        </ExternalLink>
        {(isCovidOrganization == false) && <ResourceRating onClick={updateResourceRating} rating={rating} ></ResourceRating>}
      </div>
      {showModal && <ProgramResourceComments onClose={handleClose} onSubmit={handleSubmit} resourceRating={rating}></ProgramResourceComments>}
    </div>
  );
}
ProgramResource.propTypes = {
  programEnrollmentId: PropTypes.string.isRequired,
  resource: PropTypes.shape({
    programResourceId: PropTypes.string.isRequired,
    actionUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string
  }).isRequired,
  resourceRating: PropTypes.shape({
    programEnrollmentId: PropTypes.string,
    programResourceId: PropTypes.string,
    id: PropTypes.string,
    isHelpful: PropTypes.bool,
    comments: PropTypes.string
  })
};


