import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import {
  ChatComposite,
  fromFlatCommunicationIdentifier,
  useAzureCommunicationChatAdapter
} from '@azure/communication-react';
import React, {
  useMemo, useEffect
} from 'react';
import moment from 'moment/moment';
import { Loading } from 'shared';
import { Divider } from '@fluentui/react-northstar';
import { any } from 'prop-types';
import { resetUnReadChatMessageCount } from 'chat/action-creators';
import { useDispatch } from 'react-redux';
import { messageType, system } from 'services/constants';


export default function ChatCompositePage(props) {
  const { participantDetail, profileId } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetUnReadChatMessageCount(profileId, participantDetail?.threadId));
  }, [profileId]);

  const credential = useMemo(() => {
    try {
      return new AzureCommunicationTokenCredential(participantDetail?.token);
    } catch {
      return undefined;
    }
  }, [participantDetail?.token]);

  const userId = useMemo(
    () => fromFlatCommunicationIdentifier(participantDetail?.communicationUserId),
    [participantDetail?.communicationUserId]
  );

  function getChatDivider(date) {
    return moment(date).calendar(null, { lastWeek: 'MMM DD', lastDay: '[Yesterday]', sameDay: '[Today]', nextDay: '[Tomorrow]', nextWeek: 'MMM DD', sameElse: 'MMM DD' });
  }

  let dividerDate = '';
  let dividerElement = '';
  let calendarDate = any;

  const onRenderMessage = (messageProps, defaultOnRender) => {
    if (messageProps) {
      if (messageProps.message.messageType === system) {
        return <div></div>;
      }
      const createdOn = moment(messageProps.message.createdOn).format(moment.HTML5_FMT.DATE);
      if (messageProps.message.messageType === messageType) {
        dividerElement = '';
        if (dividerDate != createdOn) {
          calendarDate = getChatDivider(createdOn);
          dividerElement = <div className='chat-divider'><Divider content={calendarDate} important /></div>;
        }
        dividerDate = createdOn;

        return (
          <div> {dividerElement ? dividerElement : null}
            <div className={'messageBox' + ' ' + (messageProps.message.mine ? 'messageBoxMine' : 'messageBoxOthers')}>
              <div className='senderCardHeader'>
                <div className='senderDisplayName'>
                  {messageProps.message.mine ? '' : messageProps.message.senderDisplayName}
                </div>
                <div className='senderMsgTime'>{moment(messageProps.message.createdOn).format('h:mm A')}</div>
              </div>
              <div>{messageProps.message.content}</div>
            </div>
          </div>
        );
      }
    }
    return defaultOnRender ? defaultOnRender(messageProps) : <div> </div>;
  };

  const adapter = useAzureCommunicationChatAdapter({
    endpoint: participantDetail?.endpointUrl,
    userId,
    displayName: participantDetail?.displayName,
    credential,
    threadId: participantDetail?.threadId
  });

  if (adapter) {
    return (
      <div className='chat-composite'>
        {participantDetail?.token ? <ChatComposite adapter={adapter} options={{ topic: false }} onRenderMessage={onRenderMessage} /> : null}
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover" />
      </div>
    );
  }

  return <Loading></Loading>;
}




