export const types = {
  INVITE_REQUESTS: 'inviteRequests',
  INCOMING_PROFILE_INVITES: 'incomingProfileInvites',
  INCOMING_PROFILE_INVITES_COUNT: 'incomingProfileInvitesCount',
  INVITE_STATUSES: 'inviteStatuses',
  PROFILE_BADGES: 'profileBadges',
  SHOW_BADGE_ACHIEVED_SCREEN : 'showBadgeAchievedScreen',
  IS_SHOW : 'isShow',
  RETRY_COUNT: 'retryCount',
  BADGES_FOR_NOTIFICATION: 'badgesForNotification',
  BADGES_ICONS:'badgeIcons'
};
