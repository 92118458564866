import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalWrapper, Button, Icon } from 'shared';
import { leaveProgram } from 'programs/programs-actions';
import local from 'services/localization/local';
import './program-action-sheet.scss';

export default function ProgramActionSheet(props) {
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const dispatch = useDispatch();
  const unenroll = () => dispatch(leaveProgram(props.programEnrollmentId, props.profileId, props.requestId));
  const openSheet = () => setIsSheetOpen(true);
  const closeSheet = () => setIsSheetOpen(false);
  const closeConfirm = () => setIsConfirmOpen(false);

  return (
    <React.Fragment>
      <Button id="action-modal-toggle-button" type="button" onClick={openSheet} color="transparent" data-testid='toggle'>
        <Icon symbol="more" />
      </Button>

      <ModalWrapper isOpen={isSheetOpen} closeTimeoutMS={200} onRequestClose={closeSheet}>
        <div className="modal-container action-sheet">
          <div className="modal-header">
            <p></p>
            <Icon symbol="healthprograms" />
            <p></p>
          </div>
          <div className="modal-body">
            <Button id="modal-close-button" onClick={() => { closeSheet(); setIsConfirmOpen(true); }} data-testid='quit-pathway-initiate'>{local.programs.detailsUnenrollButton}</Button>
            <div className="confirm-section">
              <Button id="modal-close-button" onClick={closeSheet} data-testid='close'>{local.cancel}</Button>
            </div>
          </div>
        </div>
      </ModalWrapper>

      <ModalWrapper isOpen={isConfirmOpen} onRequestClose={closeConfirm}>
        <div id="quit-pathway-modal" className="modal-container">
          <div className="modal-header">
            <Button id="close-quit-pathway-button" onClick={closeConfirm} data-testid='close-quit-pathway-button'><Icon symbol="times" /></Button>
            <Icon symbol="healthprograms" />
            <p></p>
          </div>
          <div className="modal-body">
            <div className="content">
              <h4><Icon symbol="error" size="2em" /></h4>
              <h4>{local.programs.detailsUnenrollHeader}</h4>
              <p>{local.programs.detailsUnenrollText}</p>
            </div>
          </div>
          <div className="modal-footer">
            <Button id='cancel-quit-pathway-button' data-testid='cancel-quit-pathway-button' onClick={closeConfirm}>{local.cancel}</Button>
            <Button id='confirm-quit-pathway-button' data-testid='confirm-quit-pathway-button' onClick={unenroll}>{local.programs.detailsUnenrollButton}</Button>
          </div>
        </div>
      </ModalWrapper>
    </React.Fragment >
  );
}
