import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { routePaths } from 'services/routing';
import local from 'services/localization/local';
import { loadPrograms } from 'programs/programs-actions';
import { LinkWrapper, Icon } from 'shared';

export default function SuggestedPrograms() {
  const dispatch = useDispatch();
  const profileId = useSelector(state => state.selectedProfile.id);

  useEffect(() => {
    dispatch(loadPrograms(profileId));
  }, [profileId]);

  return (
    <section>
      <LinkWrapper id="dashboard-explore-pathways" to={routePaths.programs}>
        <div className="explore-pathways-icon-container">
          <Icon symbol="healthprograms" />
        </div>
        <div className="explore-pathways-text-container">
          <strong>{local.dashboard.pathways.header}</strong>
          <br />
          <small>{local.dashboard.pathways.description}</small>
        </div>
      </LinkWrapper>
    </section>
  );
}
