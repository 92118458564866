import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getProfile } from 'profiles/list/selectors';
import { getLocalizedRole } from 'services/organization-service';
import { Button, ProfileRedirect } from 'shared';
import { getUniqueId } from 'services/helpers';
import { acceptOrganizationInvite, declineOrganizationInvite } from 'organizations/organizations-actions';
import { getInviteResponseRequest, getMembershipExistsForProfile } from 'organizations/organizations-selectors';
import { ErrorCode, OrganizationMemberRole, OrganizationFeatureType } from 'services/enums';
import { routePaths } from 'services/routing';
import local from 'services/localization/local';
import { getProgramPermissions } from 'programs/programs-selectors';
import OrganizationPermissions from 'organizations/organization-invites/details/OrganizationPermissions';
import './invite-details.scss';
import { loadChatOrganizationDetail } from 'chat/action-creators';
import { getProfileId } from 'app/selectors';


export default function OrganizationInviteDetails({ invite, hasProperties, pathwayInvite }) {
  const currentProfile = useSelector(getProfile);
  const [programs, setPrograms] = useState([]);
  const dispatch = useDispatch();
  const [requestId] = useState(getUniqueId());
  const organizationId = invite ? (invite || {}).organizationId : (pathwayInvite || {}).organizationId;
  const membershipId = invite ? (invite || {}).organizationMembershipId : (pathwayInvite || {}).memberId;
  const role = invite ? (invite || {}).role : (pathwayInvite || {}).role;
  const organizationName = invite ? (invite || {}).organizationName : (pathwayInvite || {}).organizationName;

  const profileId = useSelector(getProfileId);
  const chatFeatureType = OrganizationFeatureType.Chat;
  const { hasError, error, success, processing, params } = useSelector(state => getInviteResponseRequest(state, requestId));
  const permissionStatus = useSelector(state => getProgramPermissions(state, membershipId, hasProperties));
  const existingMembership = useSelector(state => getMembershipExistsForProfile(state, organizationId, currentProfile.id, role));

  if (!invite && !pathwayInvite) {
    return null;
  }

  const membershipExistsErrorResponse = (params && params.profileId === currentProfile.id)
    && (hasError && error && error.body && error.body.code === ErrorCode.InviteExistingRelationship);
  const requestedRole = getLocalizedRole(role).toLowerCase();
  const expectationString = role === OrganizationMemberRole.Admin ? local.organizations.expectationMessageAdmin : local.organizations.expectationMessage;
  const acceptDisabled = !currentProfile.isOwned;

  const handleAccept = () => dispatch(acceptOrganizationInvite(requestId, organizationId, membershipId, currentProfile.id, getSelectedPermissions()));
  const handleDecline = () => dispatch(declineOrganizationInvite(requestId, organizationId, membershipId, currentProfile.id, getSelectedPermissions()));

  const getSelectedPermissions = () => {
    var newPermissions = [];
    var permission = permissionStatus.data.map(p => p.permission);
    if (programs) {
      var selectedPrograms = programs.filter(x => x.isSelected === true).map(x => x.id);
      permission.map((enrollProgram) => {
        if (selectedPrograms.indexOf(enrollProgram['value']) > -1) {
          newPermissions.push(enrollProgram);
        }
      });
      return newPermissions;
    }
    return null;
  };

  useEffect(() => {
    dispatch(loadChatOrganizationDetail(profileId, chatFeatureType));
  }, [success]);

  if (success) {
    return <ProfileRedirect to={routePaths.people} />;
  }

  let messaging;
  if (invite) {
    if (!currentProfile.isOwned) {
      messaging = (
        <small data-testid='cannot-accept-shared'>
          <strong>
            {local.organizations.cannotRespond}
          </strong>
        </small>);
    } else if (existingMembership || membershipExistsErrorResponse) {
      messaging = (
        <small data-testid='membership-exists-message'>
          {local.formatString(local.organizations.membershipAlreadyExists, (<strong>{currentProfile.name}</strong>), organizationName)}
        </small>);
    } else {
      messaging = (
        <small data-testid='invite-expectation-text'>
          {local.formatString(expectationString, requestedRole, organizationName, (<strong>{currentProfile.name}</strong>))}
        </small>
      );
    }
  }

  const showButtons = (pathwayInvite || !(!currentProfile.isOwned || existingMembership || membershipExistsErrorResponse)) && permissionStatus.ready;
  const programsSelected = programs && programs.length > 0 ? programs.filter(x => x.isSelected === true).length > 0 : true;
  return (
    <div className='invite-details-container' data-testid='invite-details'>
      <div className='organization-invite-info'>
        <div className="organization-description">
          <h6>{organizationName}</h6>
          <p>
            {(invite || {}).organizationDescription || (pathwayInvite || {}).organizationDescription}
          </p>
        </div>
        <OrganizationPermissions updatePrograms={setPrograms} admins={invite ? invite.admins : null} organizationId={organizationId} organizationMembershipId={membershipId} hasProperties={hasProperties} />
      </div>
      <div className="messaging">
        {messaging}
      </div>
      {showButtons ? <div className='button-container form-row' data-testid='buttons'>
        <a id='decline-org-invite' className='btn btn-secondary button' onClick={handleDecline} style={{ pointerEvents: !programsSelected ? 'none' : 'auto' }} disabled={processing}>{local.decline}</a>
        <Button id='accept-org-invite' className='btn btn-primary' color='teal' onClick={handleAccept} disabled={acceptDisabled || processing || !programsSelected}>{local.accept}</Button>
                     </div> : null}
    </div>
  );
}

OrganizationInviteDetails.propTypes = {
  hasProperties: PropTypes.bool,
  invite: PropTypes.shape({
    admins: PropTypes.arrayOf(PropTypes.shape()),
    organizationId: PropTypes.string,
    organizationMembershipId: PropTypes.string,
    organizationDescription: PropTypes.string,
    organizationName: PropTypes.string,
    role: PropTypes.number,
    profileId: PropTypes.string
  })
};
