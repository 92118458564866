import compileReducers from 'services/compile-reducer';
import * as persistentDataReducer from 'app/request/persistent-data-reducer';
import { types } from './devices-types';

export const initialState = {
  [types.VALIDIC_PROFILE]: persistentDataReducer.initialState,
  [types.CONNECTED_DEVICES]: persistentDataReducer.initialState,
  [types.SYNCED_DATA]: persistentDataReducer.initialState,
  [types.DISCONNECTED_DEVICES]: persistentDataReducer.initialState,
  [types.TERRA_PROFILE]: persistentDataReducer.initialState,
  [types.TERRA_DEVICES]: persistentDataReducer.initialState,
  [types.TERRA_USER_INFO]: persistentDataReducer.initialState,
  [types.ACTIVE_DEVICES]: persistentDataReducer.initialState
};

const reducers = {
  ...persistentDataReducer.reducers(types.VALIDIC_PROFILE),
  ...persistentDataReducer.reducers(types.CONNECTED_DEVICES),
  ...persistentDataReducer.reducers(types.SYNCED_DATA),
  ...persistentDataReducer.reducers(types.DISCONNECTED_DEVICES),
  ...persistentDataReducer.reducers(types.TERRA_PROFILE),
  ...persistentDataReducer.reducers(types.TERRA_DEVICES),
  ...persistentDataReducer.reducers(types.TERRA_USER_INFO),
  ...persistentDataReducer.reducers(types.ACTIVE_DEVICES)
};

export default compileReducers(reducers, initialState);
