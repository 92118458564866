import React from 'react';
import { LinkWrapper } from 'shared';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import ActivityLilChart from 'activity/charts/ActivityLilChart';
import StepsLilChart from 'health/observations/steps/chart/StepsLilChart';
import HeartRateLilChart from 'health/observations/heart-rate/HeartRateLilChart';
import TemperatureLilChart from 'health/observations/temperature/TemperatureLilChart';
import BodyMeasurementsLilChart from 'health/body-measurements/BodyMeasurementsLilChart';
import TimelineLilChart from 'timeline/TimelineLilChart';
import './lil-charts.scss';

export default function LilChartSection() {
  return (
    <section>
      <header>
        <span>{local.dashboard.data.header}</span>
        <LinkWrapper id="allHealthDataLink" to={routePaths.health}>{local.dashboard.allResults}</LinkWrapper>
      </header>
      <BodyMeasurementsLilChart />
      <HeartRateLilChart />
      <TemperatureLilChart />
      <ActivityLilChart />
      <StepsLilChart />
      <TimelineLilChart />
    </section>
  );
}
