/* eslint-disable react/jsx-max-depth */
import React, { useEffect, useRef, useState } from 'react';
import { Avatar,Card, Page, Icon, NavIcon, LinkWrapper, WeeklyDatePicker, DataLoadingStatus } from 'shared';
import PropTypes from 'prop-types';
import './program-leader-board.scss';
import { getProfileId } from 'app/selectors';
import { getProfile } from 'profiles/list/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { loadLeaderBoardSummary, setContext, loadHasEnrolledInActiveEventProgram } from 'programs/programs-actions';
import { getLeaderBoardSummaryDetails } from 'programs/programs-selectors';
import * as selectors from 'programs/programs-selectors';
import { ProfileRedirect } from 'shared/redirect/ProfileRedirect';
import { buildPath, programsContexts, routePaths } from 'services/routing';
import { Rank01, Rank02, Rank03 } from 'shared/fullColorIcons';
import moment from 'moment';
import local from 'services/localization/local';
import Page404 from 'app/page404/Page404';
import { HealthEventType, ObservationType, RecurringType } from 'services/enums';
import {loadConnectedDevices } from 'devices/devices-actions';
import { getDevices } from 'devices/devices-selectors.js';
import { configIds } from 'health/observations/observation-config-ids';

export default function ProgramLeaderBoard(props) {
  const dispatch = useDispatch();
  const backpath = props.location.state?.backpath ?? routePaths.root;
  const programEnrollmentId = selectors.getProgramEnrollmentIdFromRoute(props);
  const profileId = useSelector(getProfileId);
  const profile = useSelector(getProfile);
  const { program } = useSelector(state => selectors.getProgramEnrollmentById(state, programEnrollmentId)) || {};
  const initialProfileId = useRef (null);
  const { loading, loaded, data } = useSelector(getLeaderBoardSummaryDetails);
  const leaders = data?.leaders;
  const currentUser = leaders ? leaders.find(d => d.isCurrentProfile) : {};
  const content = local.programs.leaderBoard;
  const hasEnrolledInActiveEventProgram = useSelector(state => selectors.getHasEnrolledInActiveEventProgram(state, program?.programId));
  const [selectedDate, setSelectedDate] = useState(moment().format(moment.HTML5_FMT.DATE));
  const deviceConnected = useSelector(state => getDevices(state, profileId));

  useEffect(() => {
    dispatch(loadConnectedDevices(profileId));
  }, [profileId]);
  useEffect(() => {
    if (program && program.programId){
      initialProfileId.current = profileId;
      dispatch(loadHasEnrolledInActiveEventProgram(profileId, program.programId));

      if(programEnrollmentId)
        dispatch(setContext(programsContexts.leaderBoard));
    }
  }, [profileId, program?.programId, programEnrollmentId]);

  useEffect(() => {
    if (program && program.programId && hasEnrolledInActiveEventProgram && hasEnrolledInActiveEventProgram.data){
      if(hasEnrolledInActiveEventProgram.data.recurringType === RecurringType.Monthly)
        dispatch(loadLeaderBoardSummary(profileId, program.programId));
      else if(hasEnrolledInActiveEventProgram.data.recurringType === RecurringType.Daily)
        dispatch(loadLeaderBoardSummary(profileId, program.programId, moment().format()));
    }
  }, [hasEnrolledInActiveEventProgram?.data?.recurringType]);

  useEffect(() => {
    if(data && hasEnrolledInActiveEventProgram && hasEnrolledInActiveEventProgram.data
      && hasEnrolledInActiveEventProgram.data.recurringType === RecurringType.Daily){
      onChanged(getDatePickerEndDate());
    }
  }, [data?.eventEndDate, hasEnrolledInActiveEventProgram?.data?.recurringType]);

  if (initialProfileId.current && initialProfileId.current !== profileId){
    const programId = selectors.getProgramIdFromRoute(props);
    return <ProfileRedirect to={routePaths.programsEnroll} params={{ programId }} />;
  }

  if (loaded && !data.leaders) {
    return <Page404 />;
  }

  function onChanged(date){
    dispatch(loadLeaderBoardSummary(profileId, program.programId, date));
    setSelectedDate(date);
  }

  const getDatePickerEndDate = () =>
    moment(data.eventEndDate) < moment() ? moment(data.eventEndDate).format(moment.HTML5_FMT.DATE) : moment().format(moment.HTML5_FMT.DATE);

  const getUnit = () => {
    switch(data?.healthEventType){
      case HealthEventType.Observation:{
        switch(data?.observationType)
        {
          case ObservationType.Steps:
            return content.steps;
          case ObservationType.FloorsClimbed:
            return content.floors;
          default:
            return content.count;
        }
      }
      case HealthEventType.HealthIndex:{
        return content.healthIndex;
      }
      default:
        return content.count;
    }
  };

  const getFormattedValue = (value) => {
    if(value != undefined && value != null){
      let formattedValue = value.toLocaleString();
      if(data?.healthEventType == HealthEventType.HealthIndex){
        if(value > 0)
          formattedValue = `+${value}`;
        return `${formattedValue}%`;
      }
      return formattedValue;
    }
  };

  const getTrendsLink = () =>{
    switch(data?.healthEventType){
      case HealthEventType.Observation:{
        switch(data?.observationType)
        {
          case ObservationType.Steps:
            return {to: buildPath(routePaths.healthObservation, {configId: configIds.steps}), params: {profileId}};
          case ObservationType.FloorsClimbed:
            return {to: buildPath(routePaths.healthObservation, {configId: configIds.floorsClimbed}), params: {profileId}};
          default:
            return {to: routePaths.health};
        }
      }
      case HealthEventType.HealthIndex:{
        return {to: routePaths.healthIndex};
      }
      default:
        return {to: routePaths.health};
    }
  };

  return (
    <Page
      id="program-leader-board-page"
      backPath={{ to: backpath }}
      centerElement={program?.name}>
      {program && leaders &&
        <div>
          {hasEnrolledInActiveEventProgram && hasEnrolledInActiveEventProgram.data
          && hasEnrolledInActiveEventProgram.data.recurringType === RecurringType.Daily
          && <WeeklyDatePicker
            value={selectedDate} onChange={onChanged}
            enabledDateRange={{startDate: moment(data.eventStartDate).format(moment.HTML5_FMT.DATE), endDate:getDatePickerEndDate()}} />}
          <DataLoadingStatus loading={loading}>
            <div className="leaderBoard-header">
              <div>
                <div>{content.duration} {moment(data.eventStartDate).format('MMM DD')} - {moment(data.eventEndDate).format('MMM DD')}</div>
              </div>
              <LinkWrapper
                id='view-tasks-link'
                to={buildPath(routePaths.programsDetails, { programId: program.programId, programEnrollmentId })}
                params={{profileId}}
                backpath={{backpath: routePaths.programLeaderBoard, fromLeaderBoard:true }}>
                {content.viewTasks}
              </LinkWrapper>
            </div>
            <div className="myRankWrapper">
              <Avatar profile={profile} />
              <Card>
                <div className="leaderBoard-myRank">
                  <div className="myRank">
                    <span className="label">{content.myRank}</span>
                    <span>{currentUser.rank}</span>
                  </div>

                  <div className="rankProfile">
                    <span className="profile-name">{currentUser.name}</span>
                    <LinkWrapper
                      id='profile-edit-link'
                      to={routePaths.peopleEdit}
                      backpath={buildPath(routePaths.programLeaderBoard, { programId: program.programId, programEnrollmentId, profileId })}>
                      <Icon className="edit-icon" symbol="edit" />
                    </LinkWrapper>
                  </div>

                  <LinkWrapper
                    id='profile-edit-link'
                    {...getTrendsLink()}
                    backpath={buildPath(routePaths.programLeaderBoard, { programId: program.programId, programEnrollmentId, profileId })}>
                    <div className="stepCount">
                      <span className="label">{getUnit()}</span>
                      <span>{getFormattedValue(currentUser.value)}</span>
                    </div>
                  </LinkWrapper>
                </div>
                {(deviceConnected === null || deviceConnected === undefined || deviceConnected.length === 0) &&
                <div className='connect-device'>
                  <p className='device-text'>
                    {local.devices.deviceConnectExplanation}
                  </p>
                  <LinkWrapper id='device-connect-leaderboard' className="button btn btn-primary" to={routePaths.connectDevice} backpath={buildPath(routePaths.programLeaderBoard, { programId: program.programId, programEnrollmentId, profileId })}>
                    {local.devices.connectButton}
                  </LinkWrapper>
                </div> }
              </Card>
            </div>
            <Card>
              <div className='top-On-leaderBoard'>
                <div className="rank02 top-rank">
                  <div className="rank-avatar">
                    <NavIcon Icon={Rank02} />
                  </div>
                  <div className="rank-userName">
                    {leaders[1]?.name}
                  </div>
                  <div className="rankValue">
                    {getFormattedValue(leaders[1]?.value)}
                    <div className="label">{getUnit()}</div>
                  </div>
                </div>

                <div className="rank01 top-rank">
                  <div className="rank01-avatar">
                    <NavIcon Icon={Rank01} />
                  </div>
                  <div className="rank-userName">
                    {leaders[0]?.name}
                  </div>
                  <div className="rankValue">
                    {getFormattedValue(leaders[0]?.value)}
                    <div className="label">{getUnit()}</div>
                  </div>
                </div>

                <div className="rank03 top-rank">
                  <div className="rank-avatar">
                    <NavIcon Icon={Rank03} />
                  </div>
                  <div className="rank-userName">
                    {leaders[2]?.name}
                  </div>
                  <div className="rankValue">
                    {getFormattedValue(leaders[2]?.value)}
                    <div className="label">{getUnit()}</div>
                  </div>
                </div>
              </div>
              <div className="down-leaderBoard">
                {leaders.map((leader, index) => index >= 3 && index < 10 &&
              (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className={`leaderBoard-list ${leader.isCurrentProfile ? 'myRankList' : ''}`}>
                  <div>{leader.rank}</div>
                  <div>
                    <span className="leaderBoard-userName">{leader.name}</span>
                    {leader.isCurrentProfile && <span> {content.me}</span>}
                  </div>
                  <div>{getFormattedValue(leader.value)}</div>
                </div>
              )
                )}
              </div>
            </Card>
          </DataLoadingStatus>
        </div>}
    </Page>);
}

ProgramLeaderBoard.propTypes = {
  location: PropTypes.shape()
};
