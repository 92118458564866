import React from 'react';
import { useField } from 'formik';
import SubLabelSection from 'shared/form-fields/SubLabelSection';
import local from 'services/localization/local';
import 'shared/form-fields/form-fields.scss';

export default function DateTextFields(props) {
  const { label } = props;
  const [monthField, monthMeta] = useField('month');
  const [dayField, dayMeta] = useField('day');
  const [yearField, yearMeta] = useField('year');

  const hasMonthError = !!(monthMeta.touched && monthMeta.error);
  const hasDayError = !!(dayMeta.touched && dayMeta.error);
  const hasYearError = !!(yearMeta.touched && yearMeta.error);

  return (
    <div className="form-row combined-fields">
      <label className="form-label">{label}</label>
      <div className="fieldWrapper">
        <div className="formField">
          <input
            className={hasMonthError ? 'inputError' : ''}
            type="tel"
            placeholder={local.formMonthLabel}
            {...monthField} />
          <label htmlFor='month'>{local.formMonthLabel}</label>
          <SubLabelSection subLabel="MM" showError={hasMonthError} error={monthMeta.error} />
        </div>
        <div className="formField">
          <input
            className={hasDayError ? 'inputError' : ''}
            type="tel"
            placeholder={local.formDayLabel}
            {...dayField} />
          <label htmlFor='day'>{local.formDayLabel}</label>
          <SubLabelSection subLabel="DD" showError={hasDayError} error={dayMeta.error} />
        </div>
        <div className="formField">
          <input
            className={hasYearError ? 'inputError' : ''}
            type="tel"
            placeholder={local.formYearLabel}
            {...yearField} />
          <label htmlFor='year'>{local.formYearLabel}</label>
          <SubLabelSection subLabel="YYYY" showError={hasYearError} error={yearMeta.error} />
        </div>
      </div>
    </div>
  );
}
