import PropTypes from 'prop-types';
import useMarkHealthDataAccessed from 'health/useMarkHealthDataAccessed';

// Why does this exist? For backwards compatibility with class components.
export default function HealthDataImportCountTracker({ healthDataType }) {
  useMarkHealthDataAccessed(healthDataType);
  return null;
}


HealthDataImportCountTracker.propTypes = {
  healthDataType: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired
};
