/* eslint-disable no-unused-vars */
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'shared/form-fields';
import * as validators from 'services/fields/field-validators';
import { Button, LinkWrapper } from 'shared';
import local from 'services/localization/local';
import { numMaxLength4 } from 'services/fields/field-normalizers';
import './blood-glucose.scss';
import { getBloodPressureGoalFormFieldValues } from 'goal/goal-selectors';


function BloodGlucoseGoalForm(props) {
  const { handleSubmit, isProcessing, backPath, average } = props;
  const content = local.observation.bloodGlucose;
  const isObservationGoal = (props.isObservationGoal != undefined) ? props.isObservationGoal : false;

  function disptachBloodGlucoseDatatoObservationForm() {
    if (isObservationGoal) {
      var input = getBloodPressureGoalFormFieldValues();
      props.dispatchBloodGlucoseData(input);
      var invalidFlag = validators.number(input.fromValue) != undefined || validators.number(input.toValue) != undefined || validators.required(input.fromValue) != undefined || validators.required(input.toValue) != undefined || validators.validateRange(input.toValue, { fromValue: input.fromValue }) != undefined;
      props.dispatchBloodGlucoseFormErrorFlag(invalidFlag);
    }
  }

  return (
    <form id="bloodGlucoseGoalForm" onSubmit={handleSubmit}>
      <label className="form-label add-on-label" htmlFor="fromValue-input">{content.glucoseRange}</label>
      <div className="form-row space-label">
        <Field
          readOnly={isProcessing}
          name="fromValue"
          component={TextField}
          label="space_label"
          type="tel"
          autoFocus={isObservationGoal ? false : true}
          normalize={numMaxLength4}
          onChange={disptachBloodGlucoseDatatoObservationForm}
          onBlur={disptachBloodGlucoseDatatoObservationForm}
          validate={[validators.required, validators.number]} />
        <div className='range-to'>to</div>
        <Field
          readOnly={isProcessing}
          name="toValue"
          component={TextField}
          label="space_label"
          type="tel"
          normalize={numMaxLength4}
          onChange={disptachBloodGlucoseDatatoObservationForm}
          onBlur={disptachBloodGlucoseDatatoObservationForm}
          validate={[validators.required, validators.number, validators.validateRange]} />
      </div>
      {
        !isObservationGoal &&
        <div className='targetLabel'>
          {content.averageGlucose}<span className='bold'>{`${average} ${content.unit}`}</span>{content.averagePeriod}
        </div>
      }
      {
        !isObservationGoal &&
        <div className="form-row slider-row">
          <LinkWrapper id="glucose-goal-form-cancel" className="button btn btn-secondary" to={backPath}>{local.formCancel}</LinkWrapper>
          <Button
            id="slider-value-submit"
            className="btn btn-primary"
            submit
            disabled={isProcessing}
            color="blue">
            {local.formSave}
          </Button>
        </div>
      }
    </form>
  );
}

export default reduxForm({
  form: 'glucoseGoal',
  enableReinitialize: true
})(BloodGlucoseGoalForm);
