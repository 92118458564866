import {getType} from 'app/request/persistent-data-selectors';
import { getProfileId } from 'app/selectors';
import { getDevices, getDisconnectedDevices, getValidicGoogleFitMobileUrl, getValidicHealthKitConnectUrl } from 'devices/devices-selectors';
import { isAndroid } from 'mobile/helpers';
import {OBSERVATION_CONNECTIONS} from 'profile-observation-connections/action-names';
import { GoogleFitType, ManuallyTrackedObservations } from 'services/constants';
import { ObservationConnectionStatus, ObservationType } from 'services/enums';
import local from 'services/localization/local';

export const getObservationConnections = (state) => {
  const identityId = state.identity && state.identity.self.id;
  const {loaded, loading, hasError, data} = getType(state.observationConnections, OBSERVATION_CONNECTIONS, identityId);
  return{
    loaded,
    loading,
    hasError,
    data
  };
};

export const helper = (data, validicConnectedDevices, googleFitConnected) =>{
  const programObservationDevices = data?.programObservationTypes?.filter(x=>x.observationType !== ObservationType.LabResult && x.observationType !== ObservationType.Immunization);
  let profileConnections = [];
  let manuallyTracked = [];
  let notManualObservationConnections = [];
  let profileObservationsConnectionList = [];
  let filteredConnectedObservationDevices = [];
  let connectDevicesList=[];

  data?.profileObservationConnections?.map((x)=>{
    profileConnections.push({
      observationType: x.observationType,
      status: x.status,
      deviceSourceName: x.deviceSourceName,
      observationName: local.observationsTitle[x.observationType]
    });
  });
  profileConnections?.sort((a,b) => {
    return a.observationName.localeCompare(b.observationName);
  });

  const connectedObservationDevices = profileConnections?.filter(x=>x.status === ObservationConnectionStatus.DeviceConnected);
  const deactivatedObservationDevices = profileConnections?.filter(x=>x.status === ObservationConnectionStatus.Deactivated);
  const manuallyTrackingObservationDevices = profileConnections?.filter(x=>x.status === ObservationConnectionStatus.Manual);

  programObservationDevices?.filter(x=> ManuallyTrackedObservations.includes(x.observationType))
    .map((x)=>{
      manuallyTracked.push({
        observationType: x.observationType,
        supportedDeviceSourceNames: x.supportedDeviceSourceNames,
        observationName: local.observationsTitle[x.observationType]
      });
    });


  programObservationDevices?.filter(x=> !ManuallyTrackedObservations.includes(x.observationType))
    .map((x)=>{
      notManualObservationConnections.push({
        observationType: x.observationType,
        supportedDeviceSourceNames: x.supportedDeviceSourceNames,
        observationName: local.observationsTitle[x.observationType]
      });
    });
  notManualObservationConnections?.sort((a,b) => {
    return a.observationName.localeCompare(b.observationName);
  });

  data && data?.profileObservationConnections && profileObservationsConnectionList.push(...data?.profileObservationConnections);
  notManualObservationConnections?.map((x)=>{
    const observationConnection = profileObservationsConnectionList?.find(y=>y.observationType === x.observationType);
    if(!observationConnection){
      connectDevicesList.push({
        observationType : x.observationType,
        supportedDeviceSourceNames: x.supportedDeviceSourceNames,
        observationName: local.observationsTitle[x.observationType]
      });
    }
  });

  connectedObservationDevices.map((x) => {
    const isExist  = validicConnectedDevices?.find(y=>y.type === x.deviceSourceName);

    const progObservation = programObservationDevices?.find(z=>z.observationType === x.observationType);

    if(x.deviceSourceName === GoogleFitType && !googleFitConnected ||
      (!isExist && x.deviceSourceName !== GoogleFitType)){
      connectDevicesList.push({
        observationType: progObservation.observationType,
        supportedDeviceSourceNames: progObservation.supportedDeviceSourceNames,
        observationName: local.observationsTitle[progObservation.observationType]
      });
    }

    if(isAndroid && googleFitConnected && x.deviceSourceName === GoogleFitType ||
      (isExist && isExist.type !== GoogleFitType)){
      filteredConnectedObservationDevices.push({
        observationType : x.observationType,
        deviceSourceName: x.deviceSourceName,
        status: x.status,
        observationName: local.observationsTitle[x.observationType]
      });
    }
  });

  connectDevicesList?.sort((a,b) => {
    return a.observationName.localeCompare(b.observationName);
  });
  filteredConnectedObservationDevices?.sort((a,b) => {
    return a.observationName.localeCompare(b.observationName);
  });
  manuallyTracked?.sort((a,b) => {
    return a.observationName.localeCompare(b.observationName);
  });

  return{
    connectDevicesList,
    filteredConnectedObservationDevices,
    manuallyTrackingObservationDevices,
    manuallyTracked,
    deactivatedObservationDevices,
    programObservationDevices
  };
};

export const getAllDevicesFromValidic = (state) => {
  const profileId = getProfileId(state);
  const healthkit = getValidicHealthKitConnectUrl(state, profileId);
  const googleFitUrl = getValidicGoogleFitMobileUrl(state, profileId);
  const validicConnectedDevices = getDevices(state, profileId, healthkit, googleFitUrl);
  const validicDisconnectedDevices = getDisconnectedDevices(state, profileId, healthkit, googleFitUrl);
  let totalDevices = [];
  validicDisconnectedDevices && totalDevices.push(...validicDisconnectedDevices);
  validicConnectedDevices && totalDevices.push(...validicConnectedDevices.filter(x=>x.connected === true));

  return {totalDevices, validicDisconnectedDevices, validicConnectedDevices};
};

