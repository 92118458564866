import React from 'react';
import { NotVerifiedMessage } from 'shared';
import local from 'services/localization/local';

export default function PendingInviteMessage() {
  return(
    <div>
      <NotVerifiedMessage message={local.notVerifiedForInvites} />
    </div>
  );
}
