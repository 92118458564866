import React from 'react';

export default function HipaaIntroArt() {
  return (
    <svg id="hipaa-intro-art" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1442.52 1138.12">
      <defs>
        <linearGradient id="a" x1="178.74" y1="761.01" x2="1618.44" y2="761.01" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#fbbae2" />
          <stop offset="0.13" stopColor="#fbc6e5" />
          <stop offset="0.42" stopColor="#fcdaeb" />
          <stop offset="0.71" stopColor="#fde6ef" />
          <stop offset="1" stopColor="#fdeaf0" />
        </linearGradient>
        <linearGradient id="b" x1="1290.99" y1="867.14" x2="167.32" y2="177.21" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e58def" />
          <stop offset="0.02" stopColor="#e391f0" />
          <stop offset="0.18" stopColor="#d7aff4" />
          <stop offset="0.35" stopColor="#cec6f7" />
          <stop offset="0.54" stopColor="#c7d7f9" />
          <stop offset="0.74" stopColor="#c3e1fb" />
          <stop offset="1" stopColor="#c2e4fb" />
        </linearGradient>
        <linearGradient id="c" x1="1396.47" y1="1108.89" x2="181.52" y2="888.51" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#e58def" />
          <stop offset="0.19" stopColor="#e392f0" />
          <stop offset="0.42" stopColor="#dda1f2" />
          <stop offset="0.67" stopColor="#d3b9f5" />
          <stop offset="0.94" stopColor="#c6dbfa" />
          <stop offset="1" stopColor="#c2e4fb" />
        </linearGradient>
        <linearGradient id="d" x1="1303.55" y1="1025.6" x2="190.14" y2="892.95" xlinkHref="#c" />
        <linearGradient id="e" x1="1433.65" y1="1135.09" x2="50.51" y2="1100.29" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#bc7cac" />
          <stop offset="0.2" stopColor="#bb81af" />
          <stop offset="0.44" stopColor="#b990b9" />
          <stop offset="0.7" stopColor="#b5a8c8" />
          <stop offset="0.98" stopColor="#b0cade" />
          <stop offset="1" stopColor="#b0cde0" />
        </linearGradient>
        <linearGradient id="f" x1="304.49" y1="535.27" x2="530.4" y2="261.64" xlinkHref="#a" />
        <linearGradient id="g" x1="765.65" y1="627.31" x2="1122" y2="627.31" xlinkHref="#a" />
        <linearGradient id="h" x1="586.91" y1="361.19" x2="943.27" y2="361.19" xlinkHref="#a" />
        <linearGradient id="i" x1="586.91" y1="397.34" x2="943.27" y2="397.34" xlinkHref="#a" />
        <linearGradient id="j" x1="586.91" y1="433.49" x2="943.27" y2="433.49" xlinkHref="#a" />
        <linearGradient id="k" x1="586.91" y1="469.64" x2="943.27" y2="469.64" xlinkHref="#a" />
        <linearGradient id="l" x1="586.91" y1="505.79" x2="943.27" y2="505.79" xlinkHref="#a" />
        <linearGradient id="m" x1="586.91" y1="541.94" x2="943.27" y2="541.94" xlinkHref="#a" />
        <linearGradient id="n" x1="357.74" y1="625.32" x2="943.26" y2="625.32" xlinkHref="#a" />
        <linearGradient id="o" x1="357.74" y1="691.65" x2="943.26" y2="691.65" xlinkHref="#a" />
        <linearGradient id="p" x1="357.74" y1="757.99" x2="943.26" y2="757.99" xlinkHref="#a" />
        <linearGradient id="q" x1="1151.2" y1="1323.45" x2="666.62" y2="1299.51" xlinkHref="#c" />
      </defs>
      <title>hcDoctorPatient</title>
      <g className="a">
        <path className="b" d="M1617.73,1111.73a178.19,178.19,0,0,1-24.43,74.34l-1.35,2.23c-21,.39-52.57.75-93,1.08-126.53,1-339.19,1.7-580.19,1.7-183.28,0-350.17-.39-475.25-1-58.06-.3-107.12-.65-144.61-1a100,100,0,0,0-19.2-.57c.08-1,.15-2,.2-2.91,4.72-75.33-38.53-144.44-70-213.31-31.88-69.76-50.06-159.17-.35-217.58,42.3-49.69,115-55.48,177.73-73.56C485,652.92,567.77,588.75,646.43,524.28s157.82-131.82,252.7-168.44,210.69-36.89,290.14,26.6c73.53,58.75,100.69,156.73,123.34,248.09s49,189.36,121.7,249.15c40.73,33.49,92.88,51.84,130.62,88.68S1622.55,1059.3,1617.73,1111.73Z" transform="translate(-178.74 -330.94)" />
        <path className="c" d="M1621.26,1187.17c0,.39-10.25.76-29.31,1.13-21,.39-52.57.75-93,1.08-126.53,1-339.19,1.7-580.19,1.7-183.28,0-350.17-.39-475.25-1-58.06-.3-107.12-.65-144.61-1-52.71-.56-82.6-1.18-82.6-1.85s22.76-1.13,63.6-1.63c37.15-.46,89.26-.86,152.78-1.2,126.18-.67,297.46-1.08,486.08-1.08,245.32,0,461.26.7,586.9,1.76,38.06.32,67.83.68,87.62,1C1611.5,1186.42,1621.26,1186.8,1621.26,1187.17Z" transform="translate(-178.74 -330.94)" />
        <path className="d" d="M230.62,188.62H1249.47a29.25,29.25,0,0,1,29.25,29.25V856.23a0,0,0,0,1,0,0H201.37a0,0,0,0,1,0,0V217.87A29.25,29.25,0,0,1,230.62,188.62Z" />
        <rect className="e" x="235.51" y="227.63" width="1009.08" height="589.59" rx="18" />
        <polygon className="f" points="1421.46 1097.52 58.63 1097.52 201.36 856.23 1278.73 856.23 1421.46 1097.52" />
        <polygon className="g" points="1295.26 1017.32 183.76 1017.32 255.76 891.02 1224.33 891.02 1295.26 1017.32" />
        <polygon className="h" points="1406.96 1138.12 73.13 1138.12 58.63 1097.52 1421.46 1097.52 1406.96 1138.12" />
        <circle className="e" cx="918.79" cy="540.2" r="10.15" transform="translate(59.64 1029.65) rotate(-80.78)" />
        <rect className="i" x="284.63" y="269.11" width="245" height="283.68" />
        <path className="j" d="M765.65,605.16v44.31H1122V605.16Zm49.53,28.36H804.47v10.73H792V633.52H781.31V621.09H792V610.38h12.43v10.71h10.71Z" transform="translate(-178.74 -330.94)" />
        <rect className="k" x="586.91" y="350.34" width="356.36" height="21.69" />
        <rect className="l" x="586.91" y="386.49" width="356.36" height="21.69" />
        <rect className="m" x="586.91" y="422.64" width="356.36" height="21.69" />
        <rect className="n" x="586.91" y="458.8" width="356.36" height="21.69" />
        <rect className="o" x="586.91" y="494.95" width="356.36" height="21.69" />
        <rect className="p" x="586.91" y="531.1" width="356.36" height="21.69" />
        <rect className="q" x="284.63" y="604.71" width="54.02" height="41.22" />
        <rect className="r" x="357.74" y="604.71" width="585.52" height="41.22" />
        <rect className="q" x="284.63" y="671.04" width="54.02" height="41.22" />
        <rect className="s" x="357.74" y="671.04" width="585.52" height="41.22" />
        <rect className="q" x="284.63" y="737.38" width="54.02" height="41.22" />
        <rect className="t" x="357.74" y="737.38" width="585.52" height="41.22" />
        <path className="q" d="M643.35,751.54h21.79a82.9,82.9,0,0,1-8.33,11.92c-22.58,26.92-63,45.68-75.29,78.6-9.62-24.59-35-38.46-54.27-56.5-10.31-9.67-19.23-21.12-24.86-34h38.77l6-24,9.62,58.89L571,751.54h33.44L607.81,735l13.62,33.19,12.69-48.47Z" transform="translate(-178.74 -330.94)" />
        <path className="q" d="M667.55,747.08H646.69L633.9,702.9l-13.41,51.23-14.11-34.42-5.59,27.37H568l-9.38,23-10.56-64.68-10.38,41.72H500.58q-.93-2.57-1.7-5.2c-4.87-16.88-3.45-36,6.07-50.76s27.71-24.2,45-21.19c15.4,2.69,28.58,16.24,30.19,31.52,1.49-21.46,21.6-41,43.33-42.05,23-1.12,44.35,17.32,50.18,39.64C677.85,715.06,675,732.17,667.55,747.08Z" transform="translate(-178.74 -330.94)" />
        <rect className="u" x="1024.38" y="274.22" width="188.99" height="504.38" />
        <path className="v" d="M829.88,1020.19c-1.64-.14-3-3.13-4.1-8.4-.54-2.64-1-5.84-1.55-9.55s-.89-7.9-1.36-12.54S822,980,821.6,974.59s-.85-11.2-1.23-17.27-.82-12.42-1.26-19-.86-13.4-1.22-20.37-.79-13.78-1.15-20.36c-.15-3.28-.31-6.51-.46-9.68s-.24-6.26-.36-9.29c-.26-6.05-.35-11.82-.45-17.22s0-10.46.1-15.1.36-8.85.74-12.59a84.69,84.69,0,0,1,1.48-9.67c1.23-5.39,3.06-8.56,5.41-8.91s5,2.17,7.67,7.09a62.47,62.47,0,0,1,4,9.11c1.29,3.6,2.61,7.73,3.76,12.34s2.32,9.69,3.28,15.17,1.85,11.37,2.61,17.57c.38,3.11.67,6.3,1,9.56.14,1.63.28,3.28.42,4.94s.21,3.35.32,5.05c.44,6.79.62,13.83.72,21s-.07,14.26-.39,21.06c-.08,1.7-.15,3.39-.23,5.05s-.21,3.32-.32,5c-.23,3.26-.44,6.46-.74,9.57-.6,6.23-1.22,12.14-2,17.65s-1.6,10.64-2.48,15.3-1.83,8.86-2.76,12.53a87.93,87.93,0,0,1-2.83,9.38C833.29,1017.58,831.51,1020.32,829.88,1020.19Z" transform="translate(-178.74 -330.94)" />
        <path className="w" d="M709.24,1274.69a69,69,0,0,0-10.67,39.12,232.4,232.4,0,0,0,41.62.45c1.74-6.44-1-13.09-3.34-19.34s-4.8-12.9-3.41-19.43C725.62,1275,717.06,1274.44,709.24,1274.69Z" transform="translate(-178.74 -330.94)" />
        <path className="w" d="M794.67,1268a94.26,94.26,0,0,1-2,43.18,175.2,175.2,0,0,0,59.46,2.4c2.47-3,1.43-8-1-11.08s-6.14-4.69-9.84-5.83-7.56-1.87-11-3.59a22,22,0,0,1-11.71-22C811.63,1267.45,802.33,1266.76,794.67,1268Z" transform="translate(-178.74 -330.94)" />
        <path className="x" d="M826.75,1271a8.4,8.4,0,0,1-7.66,7.15q-10.41.79-21.07,1.25a8.53,8.53,0,0,1-3.76-.71,8.41,8.41,0,0,1-5-8.49c7.74-81.8,4-157.11-2.87-216.78-2.28-19.81-4.9-37.88-7.58-53.9q-1.91,28.5-4.16,53.74c-10.36,117.78-25.25,184.39-35.83,219.39a8.4,8.4,0,0,1-8.59,5.95c-6.88-.46-13.69-1-20.39-1.71a8.39,8.39,0,0,1-7.46-9.4c11-87.86,15.87-157.92,17.43-212.1q.49-16.92.59-31.8c.22-31.77-.73-56.66-2-75.24q-.35-5-.7-9.43c0-.34-.06-.68-.08-1-.16-1.84-.31-3.6-.46-5.29-.73-7.9-1.47-14-2.07-18.44l64.09,1.36,41.71.89c.3,1.7.6,3.4.88,5.09q.91,5.37,1.76,10.7,2.76,17.29,4.92,34.17h0q1,7.68,1.84,15.27,2.29,20.13,3.81,39.5,1.44,18.12,2.26,35.49C841,1151.57,833,1228.17,826.75,1271Z" transform="translate(-178.74 -330.94)" />
        <path className="w" d="M794.26,1278.68a8.41,8.41,0,0,1-5-8.49c7.74-81.79,4-157.1-2.87-216.77-2.28-19.81-4.9-37.88-7.58-53.91L797,995S817.2,1161.85,794.26,1278.68Z" transform="translate(-178.74 -330.94)" />
        <path className="w" d="M830.34,981.65a242,242,0,0,0-111.89-33.31q-.35-5-.7-9.43c0-.34-.06-.68-.08-1-.16-1.84-.31-3.6-.46-5.29-.73-7.9-1.47-14-2.07-18.44l64.09,1.36,41.71.89c.3,1.7.6,3.4.88,5.09q.91,5.37,1.76,10.7,2.76,17.29,4.92,34.17h0Q829.47,974.06,830.34,981.65Z" transform="translate(-178.74 -330.94)" />
        <path className="y" d="M779.89,771.36s-16.93-4.5-38.88,1.1v10.82h38.88Z" transform="translate(-178.74 -330.94)" />
        <path className="z" d="M828.68,918.64q.27,8.57.52,17.91c-1.62.5-3.26,1-4.89,1.45q-5.34,1.53-10.75,2.79-3.55.83-7.14,1.52a220.52,220.52,0,0,1-95.08-2.11q-.45-12.29-.76-23.22-.24-8.25-.41-15.77c0-1.08-.06-2.15-.08-3.2,0-.12,0-.23,0-.34q-.21-10.33-.33-19.32-.07-5-.1-9.55a123.58,123.58,0,0,1-14.61.67h0a92.52,92.52,0,0,1-24.1-3.34c2.68-82.28,63-83.08,63-83.08,1.61-.35,3.23-.67,4.85-1h.09c2.4-.45,4.8-.85,7.19-1.23l-.13.23,1.24-.29a98.7,98.7,0,0,1,18.31-2.29,96.28,96.28,0,0,1,23.14,2.06c.32.05.63.12,1,.19l1.35.31c.81.18,1.6.39,2.39.6,12.11,0,23.62,6.42,32.14,15.1,8.84,9,6.38,30.66,12.39,41.76-5.44,8.87-3.94,6.52-12.1,13.12.33,5,.65,10.4,1,16.26,0,.91.1,1.83.15,2.77.33,6.6.67,13.77,1,21.53q.15,3.48.28,7.14Q828.38,908.46,828.68,918.64Z" transform="translate(-178.74 -330.94)" />
        <path className="aa" d="M781.63,785.11a87.91,87.91,0,0,0-43.16,1.75c.66-2,1.28-8.18,1.87-10.25A170.33,170.33,0,0,0,745.7,749c.21-1.83.38-3.67.52-5.5.3-3.86.47-7.72.48-11.55,11.45,1.19,21.41,6.19,30.89,12.72a73.2,73.2,0,0,0-.61,14.57,74.47,74.47,0,0,0,1.13,9.27c.29,1.54.63,3.07,1,4.58C779.81,775.76,780.65,782.52,781.63,785.11Z" transform="translate(-178.74 -330.94)" />
        <path className="v" d="M779.13,773.07a53.17,53.17,0,0,1-26.72-14.32A28.61,28.61,0,0,1,745.7,749c.21-1.83.38-3.67.52-5.5.3-3.86.47-7.72.48-11.55,11.45,1.19,21.41,6.19,30.89,12.72a73.2,73.2,0,0,0-.61,14.57,74.47,74.47,0,0,0,1.13,9.27C778.4,770,778.74,771.56,779.13,773.07Z" transform="translate(-178.74 -330.94)" />
        <path className="w" d="M786.35,711.27a21.21,21.21,0,0,1-6.84,18.78c-1.55-5.4-3.07-10.83-4.62-16.23-.85-3-1.61-6.53.33-9s5.63-2.43,8.21-.9,4.2,4.26,5.43,7Z" transform="translate(-178.74 -330.94)" />
        <path className="aa" d="M784.15,709.61a61.49,61.49,0,0,0,.61,28c1.57,6.05,4.06,12.5,1.79,18.33-2.66,6.86-11.45,9.87-18.52,7.84s-12.45-7.92-16-14.36a51.19,51.19,0,0,1-2.1-44.55C760.75,698.32,775,700.78,784.15,709.61Z" transform="translate(-178.74 -330.94)" />
        <path className="x" d="M745.69,730.93c2.61-.14,4.42-2.61,5.61-4.93a42.32,42.32,0,0,0,4.53-22.41c6.59,4.25,13.43,8.37,21.19,9.5s16.71-1.91,20-9c2.43-5.29,1.15-11.84-2.45-16.41s-9.2-7.29-14.95-8.22a31.43,31.43,0,0,0-30.72,13.77,25.87,25.87,0,0,0-8,1.69,9.3,9.3,0,0,0-6,5.47c-1.13,3.4.5,7,2.1,10.24C740.32,717.33,742.33,724.19,745.69,730.93Z" transform="translate(-178.74 -330.94)" />
        <path className="aa" d="M748,729.61a4.55,4.55,0,0,0-5.06-3.7,5.93,5.93,0,0,0-4.72,4.57,8.67,8.67,0,0,0,1.4,6.65,7.25,7.25,0,0,0,4.31,3.27,4.23,4.23,0,0,0,4.7-2.06C749.83,735.62,748.26,732.58,748,729.61Z" transform="translate(-178.74 -330.94)" />
        <path className="ab" d="M779.89,771.36a33.54,33.54,0,0,1-19.16,10.53A33.57,33.57,0,0,1,741,772.46l-9.81,9.59c4.18,10.77,21.65,19.32,21.65,19.32l7.93-17.86,8.93,17.38s17-9.53,20.52-20.51Z" transform="translate(-178.74 -330.94)" />
        <path className="ac" d="M781.92,744.19a21.07,21.07,0,0,1-8.59,1.58.91.91,0,0,0-.87,1.24,5.55,5.55,0,0,0,3.22,3.3,6.51,6.51,0,0,0,6.08-.86c1.36-1,1.48-2.73,1.41-4.46A.91.91,0,0,0,781.92,744.19Z" transform="translate(-178.74 -330.94)" />
        <path className="aa" d="M873.89,914.16c-.2-1.62-3.57-2.87-9.41-3.93-2.92-.57-6.44-.92-10.52-1.6q-3.06-.46-6.5-.91c-2.31-.32-4.7-.52-7.24-1l-7.91-1.16c-1.37-.2-2.77-.39-4.19-.58l-4.33-.74-9-1.47L805.2,901c-3.27-.54-6.58-1.29-10-2l-5.1-1-5.2-1.18c-1.74-.38-3.49-.79-5.26-1.17s-3.52-.86-5.31-1.28L769,893.11l-5.41-1.4c-1.57-.43-3.17-.78-4.71-1.21l-4.61-1.25c-1.54-.4-3-.81-4.53-1.25l-4.46-1.24-4.35-1.26c-1.44-.41-2.84-.85-4.23-1.26l-2.07-.63-.1,0c-.62-1-1.22-1.92-1.82-2.84-1-1.89-2.06-3.65-3.06-5.36s-1.86-3.44-2.74-5.09-1.61-3.07-2.37-4.55a1.65,1.65,0,0,1-.14-.26c-.3-.62-.58-1.2-.89-1.79-23.13,5.59-39.15,4.69-47,3.56.32,1.82.75,3.69,1.28,5.59a35,35,0,0,0,1.14,3.56c.42,1.19.88,2.41,1.37,3.63a38.25,38.25,0,0,0,1.65,3.65c.59,1.23,1.22,2.46,1.88,3.7,1.33,2.46,3,4.86,4.6,7.33s3.67,4.75,5.64,7.12,4.36,4.51,6.67,6.74,5,4.15,7.58,6.17l2.68,2.87,4.42,1.62c1.13.42,1.88.67,2.74,1s1.67.54,2.48.8l2.11.65c.12,0,.25.07.36.12.82.22,1.64.45,2.47.7,1.65.45,3.32.91,5,1.31s3.35.83,5.05,1.21l5.13,1.08c1.72.36,3.45.68,5.2,1l5.28.87c1.77.29,3.56.5,5.35.74l6.15.76,6.09.53c2,.16,4,.36,6,.47l5.91.27,5.79.2h5.69c3.75,0,7.41,0,11-.24s7.07-.36,10.43-.59l9.79-1,4.62-.54,4.46-.69c2.9-.46,5.69-.93,8.33-1.4s5.15-1.06,7.5-1.59,4.58-1.09,6.65-1.62a88.77,88.77,0,0,0,10.49-3.35C871.09,917.66,874.09,915.79,873.89,914.16Z" transform="translate(-178.74 -330.94)" />
        <path className="v" d="M722.72,921.65l-2.11-.65c-.81-.26-1.62-.51-2.48-.8s-1.61-.55-2.74-1L711,917.61l-2.68-2.87c-2.6-2-5.16-4.07-7.58-6.17s-4.57-4.44-6.67-6.74-3.92-4.71-5.64-7.12-3.27-4.87-4.6-7.33c-.66-1.24-1.29-2.47-1.88-3.7a38.25,38.25,0,0,1-1.65-3.65c-.49-1.22-.95-2.44-1.37-3.63a35,35,0,0,1-1.14-3.56c-.53-1.9-1-3.77-1.28-5.59,7.86,1.13,23.88,2,47-3.56.31.59.59,1.17.89,1.79a1.65,1.65,0,0,0,.14.26c-8.64,3.06-15.15,11.54-16.64,20.73-1.72,10.53,2.39,21.45,9.3,29.6A51.21,51.21,0,0,0,722.72,921.65Z" transform="translate(-178.74 -330.94)" />
        <path className="aa" d="M844.42,912.11a36.05,36.05,0,0,1,50.77-7.18c-1.67,11.42-11.4,21.52-22.68,24S850.64,921.83,844.42,912.11Z" transform="translate(-178.74 -330.94)" />
        <path className="ad" d="M1146.82,1117.5c-.56,11.81-1.42,22.76-2.62,32.56l-.7.11c-11.4-1.5-26.75-3-44.88-3.71-11.16-.4-23.39-.47-36.4,0-14,.52-29,1.67-44.56,3.74h0l-3.53-.51c-.27-10.13-.4-20.5-.42-31,0,0,0,0,0,0l47.09-89.9,20.27-38.7,19,36.86Z" transform="translate(-178.74 -330.94)" />
        <polygon className="ae" points="917.78 434.07 900 446.26 885.23 433.68 917.78 434.07" />
        <path className="w" d="M1141.92,1315h-45.6l-.3-.38c-3-3.9-3.69-10-2.82-15.39a46.07,46.07,0,0,1,3.16-10.38,9.4,9.4,0,0,1,6.51-5.65c7-1.61,14.19-3.52,21.15-2.82a9,9,0,0,1,5.84,3.09,86.94,86.94,0,0,1,10.09,13.66,47.49,47.49,0,0,1,3.67,8A10.24,10.24,0,0,1,1141.92,1315Z" transform="translate(-178.74 -330.94)" />
        <path className="w" d="M1019.9,1315h45.6l.3-.38c3-3.9,3.69-10,2.82-15.39a46,46,0,0,0-3.15-10.38,9.44,9.44,0,0,0-6.51-5.65c-7-1.61-14.19-3.52-21.16-2.82a9,9,0,0,0-5.83,3.09,86.37,86.37,0,0,0-10.1,13.66,48.29,48.29,0,0,0-3.67,8A10.24,10.24,0,0,0,1019.9,1315Z" transform="translate(-178.74 -330.94)" />
        <path className="x" d="M1138.51,1290.2a336.86,336.86,0,0,1-48.36,4.79l-9.09-312.2-.15,1.45-.15-1.45L1071.68,1295a340,340,0,0,1-48.36-4.63l12.39-304.72,1.21-28,44-8.23,44,8.23,1.22,28.17L1132,1130.3l2,48.8Z" transform="translate(-178.74 -330.94)" />
        <path className="ae" d="M1126.68,783V957.64H1043.5V779c.65-.18,1.31-.35,2-.5l1.78-.41c1.93-.45,3.88-1,5.83-1.5.56-.15,1.1-.32,1.66-.48,9.41-2.75,18.66-6.5,25.68-11.13,7,4.63,16.26,8.38,25.66,11.13l1.68.48q3.83,1.07,7.61,1.91l.75.18.74.19.68.19,1,.29.86.28c.26.09.52.17.77.27l.47.17,1.31.53c.27.1.53.22.8.34.43.2.86.39,1.28.6s.91.46,1.35.7l.67.37C1126.24,782.77,1126.46,782.89,1126.68,783Z" transform="translate(-178.74 -330.94)" />
        <polygon className="x" points="900 446.26 892.35 456.54 898.06 468.04 886.15 577.13 901.68 593.5 917.13 576.94 901.68 467.66 907.17 456.31 900 446.26" />
        <path className="w" d="M1134,1179.1a34.27,34.27,0,0,0-4.7-7.76c-7.41-9.07-19.31-12.92-30.53-16.32,7.39-11.3,20.37-20,33.25-24.72Z" transform="translate(-178.74 -330.94)" />
        <polygon className="w" points="902.32 651.85 890.23 648.32 886.15 963.84 892.94 964.08 902.32 651.85" />
        <rect className="w" x="869.02" y="609.76" width="65.25" height="20.46" />
        <path className="af" d="M1146.82,1117.5c-.56,11.81-1.42,22.76-2.62,32.56l-.7.11q-22,3.09-44.89,4,0-3.76,0-7.73c.07-33,.65-75.26,1.46-119.54.42-22.89.9-46.33,1.41-69.28.34-15.24.68-30.27,1-44.78q.24-9.79.46-19.26c1.35-54.86,2.61-99.92,3.11-117.45l1.67.48c2.55.71,5.1,1.34,7.61,1.92l.76.17.73.2c.23.05.46.12.68.18l1,.29.86.28c.26.08.52.17.77.27l.47.17,1.3.52c.28.11.54.23.81.35l1.28.6,1.36.7.66.36.66.39S1152.53,996,1146.82,1117.5Z" transform="translate(-178.74 -330.94)" />
        <path className="af" d="M1062.23,1154.18q-21.13-.84-41.56-3.56l-3-.41h0c-1.18-.17-2.36-.33-3.53-.51-.27-10.13-.41-20.5-.42-31h0c-.15-81.78,6.62-173.67,12.89-240.83.44-4.75.88-9.39,1.32-13.89,4.78-49.42,8.93-82.36,8.93-82.36l1.28-.6c.26-.12.53-.24.8-.34l1.31-.53.46-.17.78-.27.86-.29,1-.28c.22-.06.45-.13.68-.18s.49-.14.74-.19a6.8,6.8,0,0,1,.75-.18q3.78-.85,7.61-1.93l1.68-.47c.68,24,2.78,99.73,4.59,181.49.51,23.57,1,47.64,1.44,71.12.8,43.62,1.36,85.19,1.43,117.71C1062.23,1149.1,1062.23,1151.68,1062.23,1154.18Z" transform="translate(-178.74 -330.94)" />
        <path className="ad" d="M1106.08,776.15s5.87-.94,15.11,2.23L1113,793.69l9.91,12.13s-3.49,50-20.23,99.93Z" transform="translate(-178.74 -330.94)" />
        <path className="ad" d="M1054.65,776.15s-5.87-.94-15.11,2.23l8.18,15.31-9.91,12.13s3.49,50,20.23,99.93Z" transform="translate(-178.74 -330.94)" />
        <path className="e" d="M1049.4,775.45a33.66,33.66,0,0,0-9.84,1.89l-1.28.44.6,1.33c1.93,4.25,4.33,9.34,7,14.86l-1.72,2.25c-2.68,3.52-5.2,6.91-7.5,10l-.37.51.07.57c1.62,12.88,3.18,22,3.86,21.88s.29-9-.82-21.59c2.3-2.93,4.76-6.11,7.31-9.46l2.31-3,.61-.81-.45-.89c-2.67-5.31-5.19-10.17-7.4-14.26a50,50,0,0,1,7.77-2.29c2.25-.49,3.56-.72,3.56-1S1051.79,775.39,1049.4,775.45Z" transform="translate(-178.74 -330.94)" />
        <rect className="e" x="1095.97" y="819.99" width="24.04" height="15.78" transform="translate(-125.04 -398.33) rotate(3.57)" />
        <rect className="w" x="1102.61" y="818.02" width="11.54" height="3.6" transform="translate(-125.54 -398.37) rotate(3.57)" />
        <path className="aa" d="M1096,775.87a87.49,87.49,0,0,1-33.77.07c0-.17.09-.35.13-.52q1.39-6,2.5-12.07c.07-.34.13-.67.19-1,.91-5.19,1.67-10.45,2.24-15.71a154.23,154.23,0,0,1,26.38-13.17c.38,7.85.74,15.74,1.17,23.63.32,5.95.67,11.9,1.09,17.83C1095.94,775.24,1096,775.56,1096,775.87Z" transform="translate(-178.74 -330.94)" />
        <path className="v" d="M1095.92,774.92a104.6,104.6,0,0,0-31.07-11.57c.07-.34.12-.67.19-1,.92-5.2,1.67-10.45,2.24-15.71a154.29,154.29,0,0,1,26.38-13.18c.37,7.86.74,15.75,1.17,23.64C1095.15,763,1095.5,769,1095.92,774.92Z" transform="translate(-178.74 -330.94)" />
        <path className="w" d="M1058.78,706.62a20.75,20.75,0,0,0,1.42,20.47,38.92,38.92,0,0,0,7.67-15.63c.36-1.5.58-3.23-.37-4.45s-2.6-1.44-4.09-1.37S1060.2,707.08,1058.78,706.62Z" transform="translate(-178.74 -330.94)" />
        <path className="aa" d="M1097,725.42c0,8.16-1.68,16.5-6.1,23.35s-11.86,12-20,12.43a19.69,19.69,0,0,1-8.56-1.55h0a17.72,17.72,0,0,1-10.37-10.93c-2.25-7.51,1.17-15.47,4.85-22.39s7.8-14.28,7-22.08l0,0c9-6.42,20.79-8.92,30.14-3C1095.45,709.2,1097,717.27,1097,725.42Z" transform="translate(-178.74 -330.94)" />
        <path className="aa" d="M1094.67,727.62a13.64,13.64,0,0,1,3.57-2.42,5.62,5.62,0,0,1,4.22-.19,5.94,5.94,0,0,1,3.25,4.35,9.07,9.07,0,0,1-13.87,9.49A50.8,50.8,0,0,0,1094.67,727.62Z" transform="translate(-178.74 -330.94)" />
        <path className="ac" d="M1060.44,740.44A20.92,20.92,0,0,0,1069,742a.91.91,0,0,1,.87,1.24,5.6,5.6,0,0,1-3.23,3.3,6.52,6.52,0,0,1-6.08-.87c-1.36-1-1.48-2.73-1.4-4.47A.91.91,0,0,1,1060.44,740.44Z" transform="translate(-178.74 -330.94)" />
        <path className="x" d="M1096,730.83q2.74-13.79,5.5-27.57c.6-3,1.19-6.13.4-9.08-1.16-4.27-5-7.28-9-9.23a37.17,37.17,0,0,0-33.9.87,21.52,21.52,0,0,0-7.86,6.89,12,12,0,0,0-1.63,10.09c1.72,5.19,7.45,8,12.84,8.9a36.47,36.47,0,0,0,23.69-4C1085,716,1089.31,725.94,1096,730.83Z" transform="translate(-178.74 -330.94)" />
        <polygon className="ag" points="917.78 434.07 899.7 446.26 908.74 458.42 927.41 446.26 917.78 434.07" />
        <polygon className="ag" points="885.23 433.68 900 446.26 890.96 458.42 875.77 445.28 885.23 433.68" />
        <path className="aa" d="M1137.81,990.91l-6.68,10.74c-.79,1.27-1.57,3-.67,4.15,1.33,1.76,4.32.15,6.29,1.14,1.56.78,1.88,2.89,1.54,4.6s-1.14,3.34-1.07,5.08c.1,2.86,2.65,5.22,5.46,5.8a12.28,12.28,0,0,0,8.23-1.71,21.6,21.6,0,0,0,9.11-27.94C1152.88,992.36,1144.94,991.32,1137.81,990.91Z" transform="translate(-178.74 -330.94)" />
        <path className="af" d="M1163.62,994.83h-32.54L1126.79,783s21.69,2.7,34.4,111.17h0A412.07,412.07,0,0,1,1163.62,994.83Z" transform="translate(-178.74 -330.94)" />
        <path className="af" d="M1038.12,781l-60,87.41-91.74,27.06s-3.72,16.1,4.77,29l101.29-22.11,43.89-44Z" transform="translate(-178.74 -330.94)" />
        <path className="w" d="M1056.71,846.52a7.24,7.24,0,1,1-12.22-6.25,95.4,95.4,0,0,1-6.32-35.15c.1-9.41,1.74-17,5-23.18,4.15-7.91,11.17-13.44,18.82-14.85l-7.45,9.13h0a25.38,25.38,0,0,0-6.68,8.17c-2.84,5.43-4.27,12.22-4.37,20.78a90,90,0,0,0,5.92,33,8.38,8.38,0,0,1,1.22.06A7.27,7.27,0,0,1,1056.71,846.52Z" transform="translate(-178.74 -330.94)" />
        <path className="w" d="M1106.94,891.66c-.27,4.65-6.69,5.31-9.3,1.68a8.13,8.13,0,0,1-1.25-6,6.61,6.61,0,0,1,1.48-3.47c2.72-3.08,5.59-.8,7.36,2.21a43.22,43.22,0,0,0,13.09-37.78c-.79-4.87-2.42-8.44-4.85-10.6-3.71-3.29-10.1-3.54-14.89-.6-4.44,2.76-7.65,7.91-8.81,14.14a38.74,38.74,0,0,0-.24,11.2,4.51,4.51,0,0,1,3.56,1.9,5.18,5.18,0,0,1,.32,5.06,6.27,6.27,0,0,1-4,3.24,5.39,5.39,0,0,1-3.46-.13c-2.59-1.07-2.49-3.93-1.29-6.31a47.12,47.12,0,0,1-.12-15.93c1.43-7.69,5.54-14.14,11.24-17.67A19.2,19.2,0,0,1,1109,830a114.66,114.66,0,0,0,5.71-20.37c1.44-9,.66-17.25-2.24-23.87a27.42,27.42,0,0,0-7.4-9.95l-7.75-9.81c8.46,2,16.08,8.71,20,17.63,3.32,7.55,4.24,16.83,2.64,26.84A118.58,118.58,0,0,1,1114,831.7a15.52,15.52,0,0,1,3,2c3.41,3,5.55,7.52,6.55,13.73A48.62,48.62,0,0,1,1106.94,891.66Z" transform="translate(-178.74 -330.94)" />
        <circle className="x" cx="1049.49" cy="845.8" r="3.47" transform="translate(-297.82 1186.62) rotate(-68.76)" />
        <path className="ah" d="M1142.75,1307c-6.34-.85-14.09-1.75-23.09-2.67s-19.25-1.88-30.58-2.82-23.74-1.86-37.07-2.71q-10-.66-20.64-1.22t-21.89-1.07c-7.49-.33-15.17-.64-23-.89s-15.85-.51-24-.66c-16.3-.38-33.18-.54-50.47-.57l-25.61.16c-8.42.09-16.71.26-24.86.42-16.31.38-32,.9-47,1.57-7.49.31-14.8.69-21.89,1.07s-14,.79-20.65,1.22-13.09.86-19.28,1.32-12.12.91-17.78,1.39c-11.33.93-21.58,1.88-30.58,2.8s-16.76,1.82-23.09,2.67c-12.67,1.7-19.68,3.16-19.68,4.62s7,2.92,19.68,4.62c6.33.85,14.09,1.74,23.09,2.66s19.25,1.88,30.58,2.81c5.66.47,11.6.93,17.78,1.38s12.62.91,19.28,1.32,13.55.85,20.65,1.22,14.4.76,21.89,1.08c15,.67,30.7,1.19,47,1.56,8.15.17,16.44.34,24.86.43l25.61.15c17.29,0,34.17-.18,50.47-.56,8.15-.16,16.16-.42,24-.67s15.52-.55,23-.89,14.79-.67,21.89-1.07,14-.78,20.64-1.22c13.33-.84,25.74-1.77,37.07-2.71s21.58-1.89,30.58-2.81,16.75-1.83,23.09-2.67c12.67-1.71,19.68-3.17,19.68-4.63S1155.42,1308.73,1142.75,1307Z" transform="translate(-178.74 -330.94)" />
      </g>
    </svg>
  );
}
