import { BadgeType } from 'services/enums';
import * as BadgeIcons from 'shared/fullColorIcons';
import * as constants from 'services/constants';

export const getBadgeIcon = badge => {
  const achieved = badge?.achieved;
  const badgeType = badge?.badge?.badgeType?.badgesType;
  const badgeIconPath = badge?.badgeIconUrl;

  switch(badgeType){
    case BadgeType.TaskFirst:
      return achieved ? BadgeIcons.TaskFirst : BadgeIcons.TaskFirstG;
    case BadgeType.Task10:
      return achieved ? BadgeIcons.Task10 : BadgeIcons.Task10G;
    case BadgeType.Task50:
      return achieved ? BadgeIcons.Task50 : BadgeIcons.Task50G;
    case BadgeType.Task100:
      return achieved ? BadgeIcons.Task100 : BadgeIcons.Task100G;
    case BadgeType.Task500:
      return achieved ? BadgeIcons.Task500 : BadgeIcons.Task500G;
    case BadgeType.Task1000:
      return achieved ? BadgeIcons.Task1000 : BadgeIcons.Task1000G;
    case BadgeType.ProfileCompleted:
      return achieved ? BadgeIcons.profile : BadgeIcons.profileG;
    case BadgeType.DeviceConnected:
      return achieved ? BadgeIcons.device : BadgeIcons.deviceG;
    case BadgeType.ChallengeCompleted:
      return achieved ? BadgeIcons.FallBadge : BadgeIcons.FallBadgeG;
    case BadgeType.ProgramChallenge:
      return achieved && badgeIconPath;
    default:
      return BadgeIcons.defaultBadge ;
  }
};

export const getGreyBadgeIcon = (badgeIconPath) => {
  let greyBadgeArray = badgeIconPath?.split('.');
  greyBadgeArray[2] = greyBadgeArray[2] + constants.GreyBadge;
  return greyBadgeArray.join('.');
};
