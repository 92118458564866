import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextField } from 'shared/form-fields';

class TimeHourField extends React.Component {
  render() {

    return (
      <Field
        {...this.props}
        component={TextField}
        type="time" />
    );
  }
}

TimeHourField.propTypes = {
  validate: PropTypes.arrayOf(PropTypes.shape)
};

export default TimeHourField;
