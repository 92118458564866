import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { deleteInvite } from 'profiles/sharing/sharing-actions';
import local from 'services/localization/local';
import { AvatarCard, Button, ConfirmModal, Icon } from 'shared';

export default function OutgoingInviteListItem({ invite }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const onYes = () => {
    dispatch(deleteInvite(invite.id));
    closeModal();
  };

  const formattedDate = moment(invite.createdAt).format(local.simpleDateFormat);
  const inviteSentOn = local.formatString(local.sharing.inviteSentOn, formattedDate);

  const action = (
    <div className="button-container">
      {showModal && (
        <ConfirmModal
          isOpen
          modalTitle={local.sharing.inviteCancelModalTitle}
          deleteYes={local.sharing.inviteCancelModalDeleteYes}
          deleteNo={local.sharing.inviteCancelModalDeleteNo}
          modalPromptText={local.formatString(local.sharing.inviteCancelModalText, invite.name)}
          handleYes={onYes}
          handleNo={closeModal} />
      )}
      <Button
        id={`delete-button-${invite.id}`}
        color='transparent'
        data-testid='delete'
        onClick={openModal}>
        <Icon symbol='clear' />
        <span>{local.sharing.delete}</span>
      </Button>
    </div>
  );

  return (
    <AvatarCard
      heading={invite.name}
      subheading={inviteSentOn}
      actionButtons={action} />
  );
}

OutgoingInviteListItem.propTypes = {
  invite: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.string,
    name: PropTypes.string
  })
};

