import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import local from 'services/localization/local';
import { Page, NoDataPlaceholder,Button } from 'shared';
import { isNotificationLoading, isNotificationLoadError, getNotifications } from 'notifications/notification-selectors';
import { loadNotifications, markAllNotificationsRead, deleteAllNotification } from 'notifications/notification-actions';
import NotificationListItem from './NotificationListItem';
import './notifications.scss';


class NotificationPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleDeleteAll = this.handleDeleteAll.bind(this);
  }
  componentDidMount() {
    const { notifications, actions } = this.props;
    if (notifications.length < 1) {
      actions.loadNotifications();
    }
    setTimeout(actions.markAllNotificationsRead, 2000);

  }

  handleDeleteAll() {
    this.props.actions.deleteAllNotification();
  }

  render() {
    const { notifications, loading, error } = this.props;

    return (
      <Page
        id="notifications-page"
        centerElement={local.notifications.label}
        loading={loading}
        error={error}>
        {notifications.length === 0 && <NoDataPlaceholder>{local.notifications.noDataText}</NoDataPlaceholder>}
        {notifications.length > 0 && (
          <div>
            <div id="clear-all-link">
              <Button className="btn-link" id="notification-btn" onClick={this.handleDeleteAll} data-testid="notifications-clearAllLink" >{local.notifications.clearAll}</Button>
            </div>
            <ul className="scrollview-list" data-testid="notifications-list">
              {notifications.map(item => <NotificationListItem key={item.id} notification={item} />)}
            </ul>
          </div>
        )}
      </Page>
    );
  }
}

NotificationPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  notifications: PropTypes.arrayOf(PropTypes.shape).isRequired,
  actions: PropTypes.shape({
    loadNotifications: PropTypes.func.isRequired,
    markAllNotificationsRead: PropTypes.func.isRequired,
    deleteAllNotification:PropTypes.func.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    loading: isNotificationLoading(state),
    error: isNotificationLoadError(state),
    notifications: getNotifications(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { loadNotifications, markAllNotificationsRead, deleteAllNotification };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPage);
