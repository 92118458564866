import React,{ useEffect } from 'react';
import local from 'services/localization/local';
import { Card,Icon,LinkWrapper } from 'shared';
import { routePaths } from 'services/routing';
import { loadMedicationSuccess } from 'health/medication/actions';
import { useSelector, useDispatch } from 'react-redux';
import { getMedications } from 'health/medication/selectors';

export default function MedicationDashboardV1() {
  const dispatch = useDispatch();

  const medications = useSelector(getMedications);
  useEffect(() => {
    dispatch(loadMedicationSuccess(medications));
  },[]);

  return (
    <div className="health-navigation">
      <ul>
        <div className='addData-card'>
          <Card>
            <LinkWrapper
              to={routePaths.healthMedicationAdd}
              id='addHealthIndex' backpath={{to: routePaths.root}} >
              <Icon symbol="addCircle" /> {local.medication_v1Dashboard.setMedicationTitle}
            </LinkWrapper>
          </Card>
        </div>
      </ul>
    </div>
  );
}
