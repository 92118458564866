import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'shared/form-fields';
import { Button } from 'shared';
import local from 'services/localization/local';
import DateTextFieldWrapper from 'shared/form-fields/DateTextFieldWrapper';
import * as validators from 'services/fields/field-validators';


export function ConfirmIdentityForm({ handleSubmit, isProcessing }) {
  const content = local.healthData.connect.identityForm;
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-row">
        <div id='profile-label'>
          <p className='profile-name'> {content.profileLabel}
          </p>
        </div>
      </div>
      <div className="form-row">
        <Field
          readOnly={isProcessing}
          label={content.firstNameLabel}
          name="firstName"
          component={TextField}
          validate={[validators.required]} />
      </div>
      <div className="form-row">
        <Field
          readOnly={isProcessing}
          label={content.lastNameLabel}
          name="lastName"
          component={TextField}
          validate={[validators.required]} />
      </div>
      <div className="form-row">
        <DateTextFieldWrapper
          readOnly={isProcessing}
          label={local.formBirthdateLabel}
          validate={[validators.required, validators.birthdate]}
          name="dateOfBirth" />
      </div>
      <div className="form-row">
        <Field
          readOnly={isProcessing}
          label={content.zipCodeLabel}
          name="zipCode"
          component={TextField}
          validate={[validators.required, validators.number, validators.minLength5, validators.maxLength5]} />
      </div>
      <br />
      <br />
      <div className="form-row text-center">
        <small>{local.healthData.connect.identityVerification.warning}</small>
      </div>
      <br />
      <div className="form-row">
        <Button
          id='verify-identity'
          className="btn btn-primary"
          submit
          color="blue"
          disabled={isProcessing}>
          {content.submitButton}
        </Button>
      </div>
    </form>
  );
}

ConfirmIdentityForm.displayName = 'ConfirmIdentity Form';

ConfirmIdentityForm.propTypes = {
  isProcessing: PropTypes.bool,
  handleSubmit: PropTypes.func
};

export default reduxForm({
  form: 'confirmIdentity',
  enableReinitialize: true
})(ConfirmIdentityForm);
