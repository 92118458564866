import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ProfileRedirect } from 'shared';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { getUrlMedication } from 'health/medication/selectors';
import LogMedicationDose from './LogMedicationDose';

export class AddMedicationDose extends React.Component {
  render() {
    const { medication, saveSuccess } = this.props;

    if (!medication || saveSuccess) {
      return <ProfileRedirect to={routePaths.healthMedication} />;
    }

    const centerElement = local.formatString(local.medicationDoseLogHeader, medication.name);

    return (
      <LogMedicationDose medication={medication} centerElement={centerElement} />
    );
  }
}

AddMedicationDose.propTypes = {
  // redux
  medication: PropTypes.shape(),
  saveSuccess: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    medication: getUrlMedication(state, ownProps),
    saveSuccess: state.medicationDose.saved
  };
}

export default withRouter(connect(mapStateToProps)(AddMedicationDose));
