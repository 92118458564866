import { TIMELINE_FILTER_UPDATE, TIMELINE_FILTER_CLEAR } from './action-names';

export function updateTimelineFilter(filteredTypes) {
  return {
    type: TIMELINE_FILTER_UPDATE,
    payload: filteredTypes
  };
}

export function clearTimelineFilter() {
  return {
    type: TIMELINE_FILTER_CLEAR
  };
}
