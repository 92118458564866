import React from 'react';
import PropTypes from 'prop-types';
import { ObservationType } from 'services/enums';
import local from 'services/localization/local';

export default function HealthGoalCard(props){
  const { observationType, activeGoal }= props;
  const content = local.observation.bloodPressure;

  const renderSteps = () => {
    return ( <div className='value'><p className='value'>{activeGoal == 0 ? <label>{local.setGoal}</label> :<label> {activeGoal} {local.timeline.summary.stepDays}</label>} </p></div>);
  };

  const renderBloodPressure = () => {
    return  (activeGoal ? <React.Fragment>
      <div><span className='BP-legend systolic-legend '></span>{content.systolicFormLabel}{content.colon} <span className='bold'>{activeGoal.systolicMin}</span> {content.goalPageToLabel} <span className='bold'>{activeGoal.systolicMax} {local.units.millimetersOfMercury}</span></div>
      <div><span className='BP-legend diastolic-legend'></span>{content.diastolicFormLabel}{content.colon} <span className='bold'>{activeGoal.diastolicMin}</span> {content.goalPageToLabel} <span className='bold'>{activeGoal.diastolicMax} {local.units.millimetersOfMercury}</span></div>
                          </React.Fragment> : <div>{local.setGoal}</div>);
  };

  const renderBloodGlucose = () => {
    return  (activeGoal ?
      <div className='value'>
        <span className='bold'>{activeGoal?.fromValue} {local.units.mgdL} </span>to<span className='bold'> {activeGoal?.toValue} {local.units.mgdL}</span>
      </div>
      : <div>{local.setGoal}</div>
    );};

  function healthDataCard()
  {
    switch(observationType){
      case ObservationType.Steps :
        return renderSteps();
      case ObservationType.Systolic :
        return renderBloodPressure();
      case ObservationType.BloodGlucose :
        return renderBloodGlucose();
    }
  }
  return(
    <div>
      {observationType == ObservationType.Steps? <div className='label'> {local.target}</div>:
        <div className='label'> {local.targetRange}</div>}
      { healthDataCard() }
    </div>

  );
}

HealthGoalCard.propTypes = {
  observationType:PropTypes.number.isRequired
};

