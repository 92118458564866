import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'shared';
import ShareLinkWrapper from './ShareLinkWrapper';
import ShareContent from './ShareContent';

export default function ShareButton({ url, title, text, dialogTitle }) {

  return (
    <ShareLinkWrapper
      url={url}
      title={title}
      text={text}
      dialogTitle={dialogTitle}
      element={Button}
      color="transparent"
      className="share-button">
      <ShareContent dialogTitle={dialogTitle} />
    </ShareLinkWrapper>
  );
}

ShareButton.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  dialogTitle: PropTypes.string.isRequired
};
