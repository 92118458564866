import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { getFilterTypes } from './filter-types';
import { getFilter } from 'timeline/timeline-selectors';
import { Button } from 'shared';
import * as actions from 'timeline/actions';
import local from 'services/localization/local';
import './timeline-filter.scss';

export default function TimelineDataTypeSelector({ closeModal }) {
  const currentFilter = useSelector(getFilter);
  const [selectedChoices, setSelectedChoices] = useState(currentFilter);
  const dispatch = useDispatch();
  const filterTypes = getFilterTypes();

  const resetForm = () => {
    setSelectedChoices([]);
    dispatch(actions.clearTimelineFilter());
  };

  const handleChange = (e) => {
    if (e.target.checked) {
      setSelectedChoices([...selectedChoices, e.target.value]);
    } else {
      setSelectedChoices((selectedChoices.filter(x => x !== e.target.value)));
    }
  };

  const applyFilters = () => {
    dispatch(actions.updateTimelineFilter(selectedChoices));
    closeModal();
  };

  return (
    <div id='data-type-selector'>
      <div className="filter-items">
        {filterTypes.map(filterType => (
          <div
            className="form-check form-checkbox-secondary"
            key={filterType.value}>
            <input
              id={filterType.value}
              name='dataTypes'
              type='checkbox'
              className='form-check-input'
              value={filterType.value}
              checked={selectedChoices.includes(filterType.value)}
              onChange={handleChange} />
            <label className='form-check-label' htmlFor={filterType.value}> {filterType.label}</label>
          </div>
        ))}
      </div>
      <div className='buttons'>
        <Button data-testid='apply-filter' id='apply-filter' className='apply-button' onClick={applyFilters}>{local.timeline.applyFilters}</Button>
        <Button data-testid='clear-filter' id='clear-filter' className='filter-button' onClick={resetForm}>{local.timeline.clearFilters}</Button>
      </div>
    </div>
  );
}

TimelineDataTypeSelector.propTypes = {
  closeModal: PropTypes.func.isRequired
};

