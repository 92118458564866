import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as selectors from 'programs/programs-selectors';
import ProgramListItemAvailable from './ProgramListItemAvailable';
import ProgramListItemEnrolled from './ProgramListItemEnrolled';
import ProgramListItemPreview from './ProgramListItemPreview';

class ProgramListItemWrapper extends React.Component {
  render() {
    const { programInfo, programInfo: { preview }, enrollment, showFeaturePreviews, showCloseButton } = this.props;
    
    if (preview) {
      if (!showFeaturePreviews) return null;
      return <ProgramListItemPreview programInfo={programInfo} />;
    }

    if (enrollment) {
      return <ProgramListItemEnrolled showCloseButton={showCloseButton} programInfo={programInfo} enrollment={enrollment} />;
    }

    return <ProgramListItemAvailable programInfo={programInfo} />;
  }
}

ProgramListItemWrapper.propTypes = {
  programInfo: PropTypes.shape({
    preview: PropTypes.bool
  }).isRequired,
  enrollment: PropTypes.shape({}),
  showFeaturePreviews: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    enrollment: selectors.getActiveEnrollmentForProgram(state, ownProps.programInfo.programId),
    showFeaturePreviews: state.identity.self.showFeaturePreviews
  };
}

export default withRouter(connect(mapStateToProps)(ProgramListItemWrapper));
