import React from 'react';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import ProfilePage from 'profiles/page/ProfilePage';
import VitalsDataContainer from 'health/vitals/VitalsDataContainer';
import VitalsChart from 'health/vitals/VitalsChart';
import { ObservationType } from 'services/enums';
import { formatFeetInches, roundToDecimals } from 'services/value-formatter';
import { routePaths } from 'services/routing';
import { NoDataPlaceholder } from 'shared';
const formatter = value => roundToDecimals(value, 1);

class BodyMeasurements extends React.Component {
  render() {
    const { bmis, currentBmi, bmiCategory } = this.props;

    let bmiTitle = currentBmi ? (
      <div className="bmi-title">
        <small>{local.bmiTitle}</small>
      </div>
    ) : null;

    let bmiHero = currentBmi ? (
      <div className="bmi">
        <div className="row">
          <div>
            <small>{local.bmiDetailsBmi}</small>
            <h2>{formatter(currentBmi.value)}</h2>
          </div>
          <div>
            <small>{local.observation.height.title}</small>
            <p>{formatFeetInches(currentBmi.height)}</p>
          </div>
          <div>
            <small>{local.observation.weight.title}</small> <br />
            <p>{formatter(currentBmi.weight)} {local.units.pounds}</p>
          </div>
        </div>
        <small>{bmiCategory}</small>
      </div>
    ) : null;

    const body = currentBmi ? (
      <React.Fragment>
        {bmiTitle}
        <VitalsChart values={bmis} formatter={formatter} />
        {bmiHero}
      </React.Fragment>
    ) : (<NoDataPlaceholder>{local.bmiNoDataText}</NoDataPlaceholder>);

    return (
      <ProfilePage
        id="body-measurements"
        backPath={{to:this.props.location?.state?.backpath ?? routePaths.health}}
        centerElement={local.bmiHeader}>
        <VitalsDataContainer observationType={ObservationType.Height}>
          <VitalsDataContainer observationType={ObservationType.Weight}>
            {body}
          </VitalsDataContainer>
        </VitalsDataContainer>
      </ProfilePage>
    );
  }
}

BodyMeasurements.propTypes = {
  bmis: PropTypes.arrayOf(PropTypes.shape),
  currentBmi: PropTypes.shape(),
  bmiCategory: PropTypes.string,
  location: PropTypes.shape()
};

BodyMeasurements.defaultProps = {
  bmis: []
};

export default BodyMeasurements;
