import React from 'react';
import { useSelector } from 'react-redux';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { getProfile } from 'profiles/list/selectors';
import { Page, Avatar } from 'shared';
import ShareProfileForm from './ShareProfileForm';
import './share-profile-page.scss';

export default function ShareProfilePage() {
  const profile = useSelector(getProfile);

  return (
    <Page
      id="share-profile"
      backPath={{ to: routePaths.sharingAdmin }}
      centerElement={local.sharing.title}
      readOnlyProfile>
      <div>
        <div id="avatar-container">
          <Avatar profile={profile} />
        </div>
        <p id="selected-share-name">
          <strong>{profile.name}</strong>
        </p>
        <div>{local.sharing.description}</div>
        <ShareProfileForm />
        <div className='disclaimer'>
          <small>{local.sharing.legalText}</small>
        </div>
      </div>
    </Page>
  );
}
