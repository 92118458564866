/* eslint-disable react/no-multi-comp */
import React from 'react';
import local from 'services/localization/local';
import { ObservationType, Unit } from 'services/enums';
import { routePaths } from 'services/routing';
import { configIds } from 'health/observations/observation-config-ids';
import { baseObservationConfig } from 'health/observations/observation-config-helpers.jsx';
import { GenericObservationDeviceLinkNoData } from 'health/observations/generics';
import { BloodKetone  } from 'shared/fullColorIcons';

const typeDefinition = {
  type: ObservationType.BloodKetone,
  typeUnitMap: {
    [ObservationType.BloodKetone]: Unit.MilligramsPerDeciliter
  },
  unitLabelMap: {
    [Unit.MilligramsPerDeciliter]: local.units.mgdL
  }
};

export default {
  ...baseObservationConfig,
  ...typeDefinition,
  id: configIds.bloodKetone,
  backPath: { to: routePaths.health },
  centerElement: local.observation.bloodKetone.title,
  logcenterElement: local.observation.bloodKetone.logTitle,
  noDataText: local.observation.bloodKetone.noDataText,
  iconSymbol: BloodKetone,
  // eslint-disable-next-line react/prop-types
  renderNoDataComponent: (props) => props && <GenericObservationDeviceLinkNoData IconSymbol={props.config.iconSymbol} message={props.config.noDataText} />
  // eslint-disable-next-line react/prop-types
};
