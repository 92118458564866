import React from 'react';
import PropTypes from 'prop-types';
import Redux from 'shared/react-redux-hooks';
import moment from 'moment';
import { getProfileId } from 'app/selectors';
import { ObservationType, Unit } from 'services/enums';
import { observationReset } from 'health/actions';
import LogBloodPressureForm from './LogBloodPressureForm';
import { getGroupedObservationSavingStatus } from 'health/observations/observation-selectors';
import * as healthActions from 'health/action-creators';

function LogBloodPressure({ handleBack, pathwayContext }) {
  const dispatch = Redux.useDispatch();
  const profileId = Redux.useSelector(getProfileId);
  const saving = Redux.useSelector((state) => getGroupedObservationSavingStatus(state, [ObservationType.Systolic, ObservationType.Diastolic]));

  React.useEffect(() => {
    return () => {
      dispatch(observationReset(ObservationType.Systolic));
      dispatch(observationReset(ObservationType.Diastolic));
    };
  }, [profileId, dispatch]);


  const handleSubmit = React.useCallback(
    ({ systolicValue, diastolicValue, notes, timestamp }) => {
      const systolic = {
        value: systolicValue,
        observationType: ObservationType.Systolic,
        unit: Unit.MillimetersOfMercury
      };

      const diastolic = {
        value: diastolicValue,
        observationType: ObservationType.Diastolic,
        unit: Unit.MillimetersOfMercury
      };

      dispatch(healthActions.saveGroupedObservations({
        profileId,
        notes,
        timestamp,
        observations: [systolic, diastolic]
      }));
    },
    [profileId]);

  const currentTimestamp = moment().format(moment.HTML5_FMT.DATETIME_LOCAL);
  const initialValues = { timestamp: currentTimestamp };

  return (
    <LogBloodPressureForm
      pathwayContext={pathwayContext}
      profileId={profileId}
      onSubmit={handleSubmit}
      handleBack={handleBack}
      saving={saving}
      initialValues={initialValues} />
  );
}

LogBloodPressure.propTypes = {
  handleBack: PropTypes.func.isRequired,
  pathwayContext: PropTypes.shape()
};

export default LogBloodPressure;

