import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DataLoadingStatus } from 'shared';
import withContext from 'app/withContext';
import { getProgramListLoading, getProgramListHasError, getProgramListLoaded } from './programs-selectors';

class ProgramDataLoadingStatus extends React.Component {
  render() {
    const { loading, error, loaded, children } = this.props;

    return (
      <DataLoadingStatus loading={loading} error={error} loaded={loaded}>
        {children}
      </DataLoadingStatus>
    );
  }
}

ProgramDataLoadingStatus.propTypes = {
  // redux
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

function mapStateToProps(state) {
  return {
    loading: getProgramListLoading(state),
    error: getProgramListHasError(state),
    loaded: getProgramListLoaded(state)
  };
}

export default withContext(connect(mapStateToProps)(ProgramDataLoadingStatus));
