import { healthIndexRoot as apiRoot } from 'app/config';
import { httpGet,buildUrl, parseSuccess } from './api';

export const getHealthScore = (profileId, dateTime, state) => {
  var url = buildUrl(apiRoot, '/api/HealthIndex/profile-healthindex', { profileId, dateTime });
  return httpGet(url, state)
    .then(parseSuccess);
};

export const getHealthScoreTrendData = (profileId, state) => {
  var url = buildUrl(apiRoot, '/api/HealthIndex/profile-healthIndex-trend', { profileId });

  return httpGet(url, state)
    .then(parseSuccess);
};
