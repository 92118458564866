import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { buildPath } from 'services/routing';
import { logClick } from 'services/appinsights-service';

export class LinkWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.logEvent = this.logEvent.bind(this);
  }

  logEvent() {
    const { id, logName } = this.props;
    logClick(logName || id);
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { to, params, logName, children, backpath, ...rest } = this.props;
    const path = buildPath(to, params);
    const toObj = backpath ? { pathname: to, search: params ? new URLSearchParams(params).toString() : '', state: {backpath}} : path;
    return (
      <Link to={toObj} {...rest} onClick={this.logEvent}>
        {children}
      </Link>
    );
  }
}

LinkWrapper.propTypes = {
  to: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  logName: PropTypes.string,
  children: PropTypes.node,
  params: PropTypes.shape(),
  backpath: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])
};

export default LinkWrapper;
