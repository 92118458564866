import moment from 'moment';
import { ErrorCode, ObservationType } from 'services/enums';
import local from 'services/localization/local';

export const ErrorCodeMessageDict = {
  [ErrorCode.General]: local.error,
  [ErrorCode.InviteAlreadyExists]: local.errorCodeMessage.inviteAlreadyExists,
  [ErrorCode.InviteSelf]: local.errorCodeMessage.inviteSelf,
  [ErrorCode.InviteUsed]: local.errorCodeMessage.inviteUsed,
  [ErrorCode.InviteExistingRelationship]: local.errorCodeMessage.inviteExistingRelationship,
  [ErrorCode.InviteNotFound]: local.error,
  [ErrorCode.RegisterPasswordError]: local.errorPasswordError,
  [ErrorCode.RegisterPasswordStrengthError]: local.errorPasswordStrengthError,
  [ErrorCode.RegisterUserExistsError]: local.errorUserExistsError,
  [ErrorCode.RegisterUserInvalidEmailFormat]: local.errorInvalidEmailFormat,
  [ErrorCode.IdMeAccountExists]: 'There is already an account associated with your Id.me email address.  Please login with your email and password that linked to Id.me. Account'

};

export const externalUrls = {
  hcWebsite: 'https://calciumhealth.com',
  hcPrivacyPolicy: 'https://calciumhealth.com/privacy-policy/',
  hcAbout: 'https://calciumhealth.com/about/'
};

export const localStoragePaths = {
  preferences: 'preferences',
  redirect: 'redirect',
  redirectUrl: 'redirectUrl',
  selectedProfile: 'selectedProfile',
  emailAddress: 'emailAddress',
  branchLinkData: 'branchLinkData',
  chat911TextClosed: 'chat911TextClosed',
  isIdMeAuth: 'isIdMeAuth'
};

export const socialShareLinks = {
  pathwayGeneral: 'https://applink.calciumhealth.com/drYTO2XsOlb',
  mainMenuShare: 'https://applink.calciumhealth.com/appshare'
};

export const AnonymousId = 'anonymous';
export const LilChartDataPointCount = 12;

export const StartDateOfHealthSummary = -6;

export const GreyBadge = 'G';

export const AppleHealthType = 'apple_health';
export const GoogleFitType = 'google_fit_sdk';
export const AppleHealthParam = 'appleHealth';
export const FromGoogleFitConnect = 'fromGoogleFitConnect';
export const DeviceData = 'devicedata';
export const ConnectDevice = 'connectdevice';
export const ObservationConnect = 'observationconnect';

export const OpenApp = 'openapp';
export const Close = 'close';
export const Tid = 'tid';
export const USD = 'USD';
export const Checkout = 'checkout';
export const CoachName = 'Calcium AI Coach';

export const Bmi = 'Bmi';

export const Group_A = 'New';
export const Group_B = 'Old';
export const Group_C = 'Excluded';

export const AbTestName = 'DASHBOARD';

export const daysForLastWeek = -6;

//the invoice has been finalized and sent to the recipient
export const Issued_Invoice = 'Issued';
//the invoice has been balaced / completely paid.
export const Balanced_Invoice = 'Balanced';
export const CancelledAppointment = 'Cancelled';

export const MacroFields = ['carbs', 'fat', 'protein'];

export const googleFitLogo = 'https://static.myhc.app/images/GoogleFit.png';

export const libreLogo = '//static.myhc.app/images/LibreLogo.png';
export const libreName = 'freeStyleLibre';
export const terraProvider = 'Terra';
export const terraLibreDisplayName = 'FreeStyle Libre';
export const DateFormat = 'MM-DD-YYYY';
export const PathwayDateFormat = 'M/D/YY';
export const InviteColor = '--invite-color';
export const InviteBtnColor = '--invite-btn-color';
export const InviteFontColor = '--invite-font-color';
export const ImageTypes = {
  png: 'png',
  jpg: 'jpg',
  svg: 'svg'
};

export const ObservationTypesForConnection = [ObservationType.BodyArms, ObservationType.BodyArms, ObservationType.BloodGlucose, ObservationType.BloodKetone, ObservationType.BloodOxygen, ObservationType.Systolic, ObservationType.Diastolic, ObservationType.BodyFat, ObservationType.BodyChest, ObservationType.Height, ObservationType.InstantaneousHeartRate, ObservationType.Insulin, ObservationType.Nutrition, ObservationType.PeakFlow, ObservationType.Sleep, ObservationType.Steps, ObservationType.BodyTemperature, ObservationType.BodyThighs, ObservationType.BodyWaist, ObservationType.Weight, ObservationType.Exercise, ObservationType.HeartRateVariability];
export const ManuallyTrackedObservations = [ObservationType.BodyArms, ObservationType.BodyChest, ObservationType.BodyHips, ObservationType.BodyThighs, ObservationType.BodyWaist, ObservationType.PeakFlow];
export const AppleHealthLogo = 'https://static.myhc.app/images/apple-health.png';
export const ObservationTypes = 'observationType';
export const Source = 'source';
export const GoalCategoryId = 'goalCategoryId';
export const Manual = 'manual';
export const Deactivate = 'deactivate';
export const GoogleFitDisplayName = 'Google Fit';
export const AppleHealthDisplayName = 'Apple Health';

export const MaxGoalNameLength = 75;
export const MaxDescriptionLength = 500;
export const MinDescriptionLength = 138;
export const MobileSubstringDescriptionLength = 38;
export const SubstringDescriptionLength = 126;
export const messageType = 'chat';
export const system = 'system';

export const PathwayCharts = {
  steps : 'Steps',
  bloodGlucose :'Blood Glucose',
  bloodPressure : 'Blood Pressure'
};
export const ProgramDescriptionLength = 128;

export const MIN_STEP_SIZE = 1000;
export const MIN_GOAL = 1000;
export const MAX_GOAL = 50000;
export const GOAL_MAX_TICKS = 8;
export const MIN_STEPS_GOAL_TARGET = 10000;
export const MAX_NUTRITION_PERCENT = 100;
export const defaultNutritionGoal = {
  cal: 2000,
  carbs: 50,
  protein: 20,
  fat: 30
};

export const GoalBasedObservationTypes = [ObservationType.Steps, ObservationType.Nutrition, ObservationType.Systolic, ObservationType.Diastolic, ObservationType.BloodGlucose];

export const stepTicks = [1000, 10000, 20000, 30000, 40000, 50000];

export const stepsStartDateTime = moment().add(-30, 'minutes').format(moment.HTML5_FMT.DATETIME_LOCAL);

export const ManufacturerNamesToBeIgnored = ['apple health', 'apple health aggregate'];

export const iPhone = 'iPhone';
