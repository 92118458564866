import React from 'react';
import PropTypes from 'prop-types';
import Redux from 'shared/react-redux-hooks';
import { getProfileId } from 'app/selectors';
import { ObservationType } from 'services/enums';
import { getObservationTypeSavingStatus } from 'health/observations/observation-selectors';
import LogImmunizationForm from 'health/observations/immunization/log/LogImmunizationForm';

export function LogImmunization({ handleBack, pathwayContext}) {
  const profileId = Redux.useSelector(getProfileId);
  const saving = Redux.useSelector((state) => getObservationTypeSavingStatus(state, ObservationType.Immunization));

  return (
    <LogImmunizationForm
      pathwayContext={pathwayContext}
      profileId={profileId}
      handleBack={handleBack}
      saving={saving} />
  );
}

LogImmunization.propTypes = {
  handleBack: PropTypes.func.isRequired,
  pathwayContext: PropTypes.shape()
};

export default LogImmunization;
