import React from 'react';
import SubLabelSection from 'shared/form-fields/SubLabelSection';
import './form-fields.scss';

class ErrorField extends React.Component {
  constructor(props) {
    super(props);
    this.getHasError = this.getHasError.bind(this);
  }

  getHasError() {
    const { meta: { error, touched } } = this.props;
    return error && touched;
  }

  render() {
    const { meta: { error } } = this.props;
    const hasError = this.getHasError();

    return (
      <div className="formField">
        <input
          className={hasError ? 'inputError' : ''}
          type="hidden" />
        <SubLabelSection showError={hasError} error={error} />
      </div>
    );
  }
}

ErrorField.displayName = 'ErrorField';

export default ErrorField;
