import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { identityRefreshMinutes } from 'app/config';
import { loadSelfIdentity } from 'identity/action-creators';

export class IdentityRefresher extends React.Component {
  constructor(props) {
    super(props);
    this.checkRefreshIdentity = this.checkRefreshIdentity.bind(this);
    this.shouldRefreshIdentity = this.shouldRefreshIdentity.bind(this);
    this.timeToRefresh = moment().add(identityRefreshMinutes, 'minutes');
  }

  componentDidMount() {
    window.addEventListener('click', this.checkRefreshIdentity, true);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.checkRefreshIdentity, true);
  }

  checkRefreshIdentity() {
    if (this.shouldRefreshIdentity()) {
      this.props.actions.loadSelfIdentity();
      this.timeToRefresh = moment().add(identityRefreshMinutes, 'minutes');
    }
  }

  shouldRefreshIdentity() {
    return this.timeToRefresh < moment();
  }

  render() {
    return null;
  }
}

IdentityRefresher.propTypes = {
  actions: PropTypes.shape({
    loadSelfIdentity: PropTypes.func.isRequired
  }).isRequired
};

function mapDispatchToProps(dispatch) {
  const actions = { loadSelfIdentity };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(null, mapDispatchToProps)(IdentityRefresher);
