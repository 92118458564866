import compileReducers from 'services/compile-reducer';
import * as persistentDataReducer from 'app/request/persistent-data-reducer';
import * as requestQueueReducer from 'app/request/request-queue-reducer';
import { actionNames } from './activity-actions';
import { types } from './activity-types';

export const initialState = {
  [types.ACTIVITY_REQUESTS]: requestQueueReducer.initialState,
  [types.ACTIVITIES]: persistentDataReducer.initialState
};

const reducers = {
  ...persistentDataReducer.reducers(types.ACTIVITIES),
  ...requestQueueReducer.reducers(types.ACTIVITY_REQUESTS)
};

reducers[actionNames.ACTIVITY_SAVE] = (state, payload) => {
  const { activity } = payload;

  const currentData = state[types.ACTIVITIES][activity.profileId] ? state[types.ACTIVITIES][activity.profileId].data : [];

  return persistentDataReducer.updateDataStatus(state, types.ACTIVITIES, activity.profileId, {
    data: [...currentData, activity]
  });
};

reducers[actionNames.ACTIVITY_DELETE] = (state, payload) => {
  const { activityId, profileId } = payload;

  const updatedActivities = state[types.ACTIVITIES][profileId].data.map(x => {
    if (x.id === activityId) {
      return { ...x, deleted: true };
    }
    return x;
  });

  return persistentDataReducer.updateDataStatus(state, types.ACTIVITIES, profileId, {
    data: updatedActivities
  });
};

export default compileReducers(reducers, initialState);
