import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import local from 'services/localization/local';
import styleVars from 'styles/_variables.scss';
import { getTrackSteps } from 'health/health-selectors';
import { loadTrackSteps } from 'health/action-creators';
import { bindActionCreators } from 'redux';
import { ObservationType } from 'services/enums';
import withContext from 'app/withContext';
import 'chartjs-plugin-annotation';
import moment from 'moment';

class StepsBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.getTrackSteps =this.getTrackSteps.bind(this);
  }

  componentDidMount()
  {
    this.getTrackSteps();
  }

  getTrackSteps() {
    const { actions, profileId, observationType } = this.props;
    actions.loadTrackSteps(profileId, observationType);
  }

  getData() {
    const { data, targetData } = this.props;
    if( data && targetData){
      const output = data.reduce((result, x) => {
        result.labels.push(moment(x.date).format('MM/D'));
        result.data.push(x.totalSteps);
        const color=x.totalSteps >= targetData?.latestData?.value ?styleVars.colorBlue: styleVars.colorDimGray;
        result.backgroundColor.push(color);
        return result;
      }, { labels: [], data: [], backgroundColor: [] });
      const datasets = [{
        data: output.data,
        barPercentage: 0.3,
        categoryPercentage: 1.5,
        backgroundColor: output.backgroundColor
      }];
      if(targetData.data && targetData.data.length>=2){
        const goals = [];
        for (const item of data) {
          const goal = targetData.data.find(d=>moment(d.startDate).format(moment.HTML5_FMT.DATE) == moment(item.date).format(moment.HTML5_FMT.DATE));
          var newGoal;
          if(goal)
            newGoal = goal.value;
          goals.push(newGoal);
        }
      }
      return {
        labels: output.labels,
        datasets
      };
    }
  }
  getOptions() {
    const { data, targetData } = this.props;
    if(data && targetData)
    {
      const targetGoal= targetData?.latestData?.value == undefined ? 0 : targetData?.latestData?.value;
      const newData = [targetGoal,...data.map(d=>d.totalSteps)];
      const maxYValue = Math.max(...newData) + 1000;
      return {
        responsive: true,
        tooltips: {
          enabled: true,
          displayColors: false,
          callbacks: {
            label: function (tooltipItem) {
              return [
                local.formatString(local.observation.steps.barChartFormat, data[tooltipItem.index].totalSteps)
              ];
            }
          }
        },
        hover: {
          mode: null
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            display: true,
            gridLines: {
              display: false
            },
            ticks: {
              display: true,
              autoSkip: true,
              maxTicksLimit: 6
            }
          }],
          yAxes: [{
            display: true,
            gridLines: {
              display: false
            },
            ticks: {
              suggestedMin:1000,
              display: true,
              suggestedMax: maxYValue,
              maxTicksLimit: 6
            }
          }]
        },
        annotation: {
          annotations: [
            {
              type: 'line',
              mode: 'horizontal',
              scaleID: 'y-axis-0',
              value:targetData?.latestData?.value,
              borderColor: '#242424',
              borderWidth: 2,
              borderDash:[5,10]
            }
          ]
        }
      };
    }
  }
  render() {
    const data = this.getData();
    const options = this.getOptions();
    if(data) {
      return (
        <Bar
          data={data}
          options={options}
          width={100}
          height={60}
          className="chart-data" />
      );
    }
    return null;
  }
}
StepsBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape),
  profileId: PropTypes.string.isRequired,
  observationType:PropTypes.number,
  actions: PropTypes.shape({
    loadTrackSteps: PropTypes.func.isRequired
  }).isRequired,
  targetData: PropTypes.shape()
};

function mapStateToProps(state, props) {
  return {
    observationType: ObservationType.Steps,
    profileId:props.profileId,
    targetData: getTrackSteps(state, props.profileId, props.observationType)
  };
}
function mapDispatchToProps(dispatch) {
  const actions = { loadTrackSteps };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default withContext(connect(mapStateToProps,mapDispatchToProps)(StepsBarChart));
