import React from 'react';
import local from 'services/localization/local';
import UpdateProfileContainer from './UpdateProfileContainer';

function AddNewOtherProfileWrapper(props) {
  return (
    <UpdateProfileContainer isNewAdd centerElement={local.createOtherProfileTitle} {...props} />
  );
}

export default AddNewOtherProfileWrapper;
