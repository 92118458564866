import React from 'react';
import PropTypes from 'prop-types';
import SubLabelSection from 'shared/form-fields/SubLabelSection';

import './form-fields.scss';

class CheckboxField extends React.Component {
  constructor(props) {
    super(props);
    this.getValue = this.getValue.bind(this);
    this.getHasError = this.getHasError.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  getValue() {
    const { input: { value } } = this.props;
    return value ? value : false;
  }

  getHasError() {
    const { meta: { error, touched } } = this.props;
    return error && touched;
  }

  handleOnChange(event) {
    const { input: { onChange, onBlur }, meta: { touched } } = this.props;
    onChange(event);
    if (!touched) {
      onBlur(event);
    }
  }

  render() {
    const { label, subLabel, input, meta: { error }, ...rest } = this.props;
    const hasError = this.getHasError();
    const value = this.getValue();
    const id = `${input.name}-input`;

    return (
      <div className="formField radio">
        <input
          className={hasError ? 'inputError' : ''}
          id={id}
          {...rest}
          {...input}
          type="checkbox"
          value={value}
          onChange={this.handleOnChange}
          placeholder={label} />
        {label && <label htmlFor={id}><figure /><span>{label}</span></label>}
        <SubLabelSection showError={hasError} error={error} subLabel={subLabel} />
      </div>

    );
  }
}

CheckboxField.displayName = 'CheckboxField';
CheckboxField.propTypes = {
  input: PropTypes.shape(),
  meta: PropTypes.shape(),
  label: PropTypes.node,
  subLabel: PropTypes.string
};

export default CheckboxField;
