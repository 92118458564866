import { isFunction } from 'services/helpers';
import { actionNames } from './persistent-data-actions';

export const initialState = {};
export const defaultStatus = {
  data: null,
  loaded: false,
  loading: false,
  hasError: false,
  error: null
};

export const reducers = (type) => ({
  [actionNames(type).REQUEST_INITIATED]: requested(type),
  [actionNames(type).REQUEST_SUCCESS]: success(type),
  [actionNames(type).REQUEST_ERROR]: failed(type)
});

export const invalidRequest = (state, type, uniqueId, requestId) => {
  return (
    !uniqueId ||
    !requestId ||
    requestId !== state[type][uniqueId].requestId
  );
};

export const updateState = (state, updateObjectOrFunction) => {
  if (typeof updateObjectOrFunction === 'object') {
    return {
      ...state,
      ...updateObjectOrFunction
    };
  } else if (isFunction(updateObjectOrFunction)) {
    return {
      ...state,
      ...updateObjectOrFunction(state)
    };
  } else {
    return state;
  }
};

export const updateData = (state, type, uniqueId, updateObjectOrFunction) => {
  const data = updateState(state[type][uniqueId].data, updateObjectOrFunction);

  return {
    ...state,
    [type]: {
      ...state[type],
      [uniqueId]: {
        ...state[type][uniqueId],
        data
      }
    }
  };
};

export const updateDataStatus = (state, type, uniqueId, updateObjectOrFunction) => {
  const dataStatus = updateState(state[type][uniqueId], updateObjectOrFunction);

  return {
    ...state,
    [type]: {
      ...state[type],
      [uniqueId]: dataStatus
    }
  };
};

export const requested = (type) => (state, payload) => {
  const { uniqueId, requestId } = payload;
  if (!uniqueId || !requestId) return state;

  return updateDataStatus(state, type, payload.uniqueId, {
    requestId,
    loading: true,
    hasError: false,
    error: null
  });
};

export const success = (type) => (state, payload) => {
  const { uniqueId, requestId, data } = payload;
  if (invalidRequest(state, type, uniqueId, requestId)) return state;

  return updateDataStatus(state, type, payload.uniqueId, {
    data,
    loaded: true,
    loading: false,
    hasError: false,
    error: null
  });
};

export const failed = (type) => (state, payload) => {
  const { uniqueId, requestId, error } = payload;
  if (invalidRequest(state, type, uniqueId, requestId)) return state;

  return updateDataStatus(state, type, payload.uniqueId, {
    loaded: false,
    loading: false,
    hasError: true,
    error
  });
};

