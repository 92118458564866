import React , {useEffect, useRef} from 'react';
import { renderToString } from 'react-dom/server';
import './carePlan.scss';
import PropTypes from 'prop-types';
export function CarePlanRerenderHTML({content}) {

  const replaceContent = content.replace(/&amp;/g,'&');

  //decoder utility function
  const htmlDecode = (input) =>{
    var doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  };

  // decode string
  const decodedStr = (htmlDecode(replaceContent));
  // check for regex edge case patterns
  const reg = /<td\s*>*>\s*<br\s*\/>\s*<\/td>/g;
  const processedStr = decodedStr.replace(new RegExp(reg,'g'), '<td></td>');

  const outPutElementDom = useRef();

  // parse html content
  let parser = new DOMParser();
  const doc = parser.parseFromString(processedStr, 'text/html');
  // extract headers and data from table and keep it as separate array
  const processData = (table) => {
    const rows = table.querySelectorAll('tr');
    return Array.from(rows).map((row) => {
      return Array.from(row.querySelectorAll('td')).map((cell) => {
        return cell.textContent;
      });

    });
  };
  const processHeader = (table) =>{
    const rows = table.querySelectorAll('tr');
    if(rows[0]){
      return Array.from(rows[0].querySelectorAll('th')).map((cell) => {
        return cell.textContent;
      });
    }
  };


  const constructTable = (iteration) =>{
    // identify table
    let table = doc.querySelectorAll('table');

    let data = processData(Array.from(table)[iteration]);
    let headers = processHeader(Array.from(table)[iteration]);
    return  data && data.length > 0   ? (
      <React.Fragment>
        {
          data.map((row)=>{
            return row.map((item,i,ar)=>{

              if(item && item.trim()){
                return(
                  <React.Fragment>
                    <tr>
                      <td>
                        {(headers[i])}
                      </td>
                      <td>{item}</td>
                    </tr>
                    {
                      ar.length-1 === i  && <tr className='tr-break'></tr>
                    }
                  </React.Fragment>
                );
              }

              if(i === ar.length-1){
                return <tr className='tr-break'></tr>;
              }
            });

          })

        }
      </React.Fragment>
    ) : null;
  };

  useEffect(()=>{
    if(outPutElementDom){
      let oldTableContent = doc.getElementsByTagName('table');
      if(oldTableContent && oldTableContent.length > 0){
        for(let i = 0;i<oldTableContent.length; i++){
          let rerenderTable = renderToString(constructTable(i));
          oldTableContent[i].innerHTML = rerenderTable;
          outPutElementDom.current.innerHTML = doc.documentElement.innerHTML;
        }

      }else{
        outPutElementDom.current.innerHTML = doc.documentElement.innerHTML;
      }

    }
  },[]);

  return(
    <div className='render-html' ref={outPutElementDom}></div>
  );
}

CarePlanRerenderHTML.propTypes = {
  content : PropTypes.string
};
