import React from 'react';
import PropTypes from 'prop-types';
import { ModalWrapper, Button, Icon} from 'shared';
import local from 'services/localization/local';
import './devices.scss';

export default function DisconnectConfirmModal({ modalTitle, modalPromptText, handleYes, handleNo, isOpen, logo}) {
  return (
    <ModalWrapper className='card-modal-wrapper disconnect-device-modal' isOpen={isOpen} onRequestClose={handleNo} >
      <div className='card-modal disconnect-device'>
        <div className="content">
          <div>
            <div className="devices-illustrator">
              <img height="100" width="100" src={logo}></img>
            </div>
            <div className="devices-modal-header">{modalTitle}</div>
            <div className="devices-modal-content">{modalPromptText} </div>
          </div>
          <div className="sticky-button-section action">
            <Button id="reject-button" className='modal-button btn btn-secondary' color="blue" onClick={handleNo}>{local.cancel}</Button>
            <Button id="accept-button" className='modal-button btn btn-primary' color="blue" onClick={handleYes}>{local.devices.disconnect}</Button>
          </div>
        </div>
        <Button id='close-badge-modal' className='card-modal-close' onClick={handleNo} data-testid='public-health-badge-modal-close'><Icon symbol='times' /></Button>
      </div>
    </ModalWrapper>

  );
}

DisconnectConfirmModal.propTypes = {
  modalTitle: PropTypes.string,
  modalPromptText: PropTypes.string,
  handleYes: PropTypes.func.isRequired,
  handleNo: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  logo:PropTypes.string.isRequired
};
