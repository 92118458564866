import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import { getIdentityId } from 'identity/selectors';
import { getSelfProfileId } from './selectors';
import { revokeAccessAsUser } from 'profiles/sharing/sharing-actions';
import { routePaths } from 'services/routing';
import { Avatar, AvatarCard, Button, Icon, LinkWrapper } from 'shared';
import RevokeAccessModal from 'profiles/sharing/revoke-access-modal/RevokeAccessModal';

export default function ProfileListItem({ profile }) {
  const { id, name, theme, isOwned } = profile;

  const selfProfileId = useSelector(getSelfProfileId);
  const identityId = useSelector(getIdentityId);
  const dispatch = useDispatch();

  const selfProfile = id === selfProfileId;

  const [showModal, setShowModal] = useState(false);

  const triggerModal = (e) => {
    e.preventDefault();
    setShowModal(!showModal);
  };

  const handleRevoke = (e) => {
    dispatch(revokeAccessAsUser(identityId, id));
    triggerModal(e);
  };

  const revokeButton = !isOwned && (
    <div className='button-container' data-testid='profile-access-revoke-button'>
      <Button
        id={`revoke-button-${id}`}
        color='transparent'
        onClick={triggerModal}
        title={local.sharing.revoke}>
        <Icon symbol='clear' />
        <span>{local.sharing.revoke}</span>
      </Button>
    </div>);

  const revokeModal = !isOwned && showModal && (
    <div data-testid='profile-access-revoke-modal'>
      <RevokeAccessModal
        handleYes={handleRevoke}
        handleNo={triggerModal}
        name={name}
        modalTitle={local.sharing.removeProfileModalTitle}
        modalText={local.sharing.removeProfileModalText}
        deleteYes={local.sharing.deleteYes}
        deleteNo={local.sharing.deleteNo} />
    </div>
  );

  let subheading = null;
  if (selfProfile) {
    subheading = local.linkSelfProfile;
  } else if (!isOwned) {
    subheading = local.sharing.sharedWithYou;
  }

  return (
    <React.Fragment>
      {revokeModal}
      <LinkWrapper
        id={`health-link-${id}`}
        logName="profile-health-link"
        className="log"
        title={`${name} ${local.healthDataLink}`}
        to={`${routePaths.root}?profileid=${id}`}>
        <div data-testid='profile-avatar'>
          <AvatarCard
            avatar={<Avatar profile={{ name, theme }} />}
            heading={name}
            subheading={subheading}
            actionButtons={revokeButton} />
        </div>
      </LinkWrapper>
    </React.Fragment>
  );
}

ProfileListItem.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    theme: PropTypes.number,
    isOwned: PropTypes.bool
  })
};
