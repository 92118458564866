import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getBmis, getCurrentBmi, getBmiCategory } from 'health/selectors-bmi';
import BodyMeasurements from './BodyMeasurements';

class BodyMeasurementsContainer extends Component {
  render() {
    return <BodyMeasurements {...this.props} />;
  }
}

function mapStateToProps(state) {
  const bmis = getBmis(state);
  const currentBmi = getCurrentBmi(bmis);
  const bmiCategory = getBmiCategory(currentBmi);

  const props = {
    bmis,
    currentBmi,
    bmiCategory
  };

  return props;
}

export default connect(mapStateToProps)(BodyMeasurementsContainer);
