import { CompletionScreenUpon } from 'services/enums';
import moment from 'moment';

export default function ShowCompleteScreen(enrollment, details, completionScreenData)
{
  const now = moment();
  const pathWayEndDate = details && details.endDateTime ? moment(details.endDateTime) : moment();
  const showSuccess = (enrollment && enrollment.complete) || (details && details.readyToComplete);
  if(completionScreenData && completionScreenData.completionScreenOn) {
    let canShowCompletionScreen = completionScreenData && showSuccess && completionScreenData.completionScreenOn.toString() === CompletionScreenUpon.TaskCompletion;
    if(canShowCompletionScreen === false && completionScreenData && details.endDateTime) {
      canShowCompletionScreen = completionScreenData.completionScreenOn.toString() === CompletionScreenUpon.PathwayCompletion && now > pathWayEndDate;
    }
    return canShowCompletionScreen;
  }
  else {
    return showSuccess;
  }
}
