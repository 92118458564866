/* eslint-disable react/jsx-newline */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { LinkWrapper, Loading, Icon } from 'shared';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import moment from 'moment';
import './Appointment.scss';

export default function DashboardAppointment(props){
  const { doctorName, consultationType, appointmentStartDate, provider} = props.data;

  if(props.loading){
    return(
      <section id="program-section">
        <div className='program-dashboard-tile'>
          <Loading></Loading>
        </div>
      </section>
    );
  }
  return(
    <section id="program-section">
      <header>
        <span>{local.appointment.myAppointment}</span>

        <LinkWrapper id="all-programs" to={routePaths.appointment}>
          {local.dashboard.allResults}
        </LinkWrapper>
      </header>

      <div className='program-dashboard-tile'>
        <div className='loaded'>
          <LinkWrapper
            id='appointment-dashboard'
            className="program-tile-details-link home-appointment-card"
            logName='program-tile-details'
            to={routePaths.appointmentDetails}
            backpath={{appointment: props.data, fromPath:routePaths.root}}>
            <div className='drName'>{doctorName}</div>

            <small className='provider-name'>{local.appointment.provider} : {provider}</small>

            <div className='appointment-type'><small>{consultationType}</small></div>

            <div className='appointment-schedule'>
              <div className='appointment-day'><Icon symbol="calenderEvent" /><span>{moment(appointmentStartDate).format('ddd')}, {moment(appointmentStartDate).format('MMM')} {moment(appointmentStartDate).format('Do')}</span></div>

              <div className='appointment-time'><Icon symbol="clock" /><span>{moment(appointmentStartDate).format('hh:mm A')}</span></div>
            </div>
          </LinkWrapper>
        </div>
      </div>
    </section>
  );
}
