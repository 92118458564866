import React from 'react';
import { useSelector } from 'react-redux';
import { getProfileById } from 'profiles/list/selectors';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import { Avatar, Icon, LinkWrapper } from 'shared';
import { getLocalizedRole } from 'services/organization-service';
import { routePaths } from 'services/routing';

export default function OrganizationInvitesListItem({ membershipId, organizationId, organizationName, role, rootOrganizationName, profileId, isGroupInvite = true }) {
  const profile = useSelector(state => getProfileById(state, profileId));
  const localizedRole = getLocalizedRole(role);
  const joinHeader = profileId ? local.formatString(local.organizations.joinHeaderForProfile, localizedRole, profile.name) : local.formatString(local.organizations.joinHeader, localizedRole);
  const joinText = rootOrganizationName
    ? local.formatString(local.organizations.joinChildOrganization, <strong>{organizationName}</strong>, <strong>{rootOrganizationName}</strong>)
    : local.formatString(local.organizations.joinRootOrganization, <strong>{organizationName}</strong>);
  const pathwayInviteHeader = local.formatString(local.organizations.programInviteHeaderForProfile, <strong>{organizationName}</strong>);

  return (
    <LinkWrapper
      id={`organization-invite-details-${membershipId}`}
      className="invite"
      logName="organization-invite-details-link"
      to={routePaths.organizationsInviteDetails}
      params={{ organizationId, membershipId }}>
      <Avatar profile={{ name: organizationName, theme: 0 }} />
      {isGroupInvite ?
        <div data-testid="organization-invite-list-item" className="invite-content">
          <span>
            <strong>{joinHeader}</strong>
          </span>
          <br />
          <span>
            {joinText}
          </span>
        </div>
        :
        <div data-testid="organization-invite-list-item" className="invite-content padding-top-10">
          <span>
            {pathwayInviteHeader}
          </span>
        </div>}
      <Icon className="invite-action" symbol="chevronright" />
      <div className="icon-badge"><Icon symbol="group" /></div>
    </LinkWrapper>
  );
}

OrganizationInvitesListItem.propTypes = {
  membershipId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  organizationName: PropTypes.string.isRequired,
  role: PropTypes.number.isRequired,
  rootOrganizationName: PropTypes.string,
  profileId: PropTypes.string
};
