import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { logClick } from 'services/appinsights-service';

class MailToLink extends Component {
  constructor(props) {
    super(props);
    this.logEvent = this.logEvent.bind(this);
  }

  logEvent() {
    const { id } = this.props;
    logClick(`mailto-${id}`);
  }

  render() {
    const { id, mailto, title, children, ...rest } = this.props;

    return (
      <a
        {...rest}
        title={title}
        onClick={this.logEvent}
        id={id}
        href={mailto}>
        {children}
      </a>
    );
  }
}

MailToLink.propTypes = {
  id: PropTypes.string.isRequired,
  mailto: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node
};

export default MailToLink;
