import React from 'react';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import { Button } from 'shared';
import './HealthCardReading.scss';
import  HealthGoalCard  from 'health/observations/HealthCardReadings/HealthGoalCard';

export default function HealthCardReading(props) {
  const { minVal, maxVal, units, observationType, activeGoal, onClick, buttonId }= props;
  const buttonText = activeGoal? local.setTarget : local.addTarget;
  return (
    <div className='healthCard'>
      <div className='reading-card'>
        <div className='mb-2'>
          <div className='label'> {local.minReading}</div>
          <div className='value'>{minVal == 0 ? '--' : minVal} {units}</div>
        </div>
        <div>
          <div className='label'> {local.maxReading}</div>
          <div className='value'>{maxVal == 0 ? '--' : maxVal} {units}</div>
        </div>
      </div>
      <div className='reading-card'>
        <HealthGoalCard
          observationType={observationType}
          activeGoal={activeGoal} />
        <div className='mt-3 text-center'>
          <Button onClick={onClick} id={buttonId} className="btn btn-primary small-btn" > {buttonText}</Button>
        </div>

      </div>
    </div>
  );
}

HealthCardReading.propTypes = {
  minVal: PropTypes.number,
  maxVal: PropTypes.number,
  observationType:PropTypes.number.isRequired,
  units:PropTypes.string.isRequired,
  onClick : PropTypes.func,
  buttonId:PropTypes.string.isRequired
};
