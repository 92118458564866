import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Avatar, AvatarCard, ConfirmModal, Button, Icon } from 'shared';
import { getProfileById } from 'profiles/list/selectors';
import { getLocalizedRole } from 'services/organization-service';
import { removeFromOrg } from 'organizations/organizations-actions';
import local from 'services/localization/local';

export default function OrganizationMembersListItem(member) {
  const { role, membershipId, profileId, profileName, email, organizationName, organizationId } = member;
  const localizedrole = getLocalizedRole(role);
  const profile = useSelector(state => getProfileById(state, profileId));
  const memberProfile = {
    id: profileId,
    name: profileName || email,
    ...profile
  };
  const avatar = <Avatar profile={memberProfile} />;

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const leaveOrgPromptText = local.formatString(local.organizations.leavePrompt, organizationName);

  const triggerModal = useCallback(() => setShowModal(!showModal), [showModal]);
  const handleLeaveOrg = useCallback(() => {
    dispatch(removeFromOrg(membershipId, organizationId, organizationName, profileId));
    triggerModal();
  }, [showModal]);

  const leaveOrgButton = (
    <Button
      data-testid='leave-org'
      id={`leave-button-${organizationId}`}
      color='transparent'
      onClick={triggerModal}>
      <Icon symbol='clear' />
      <span>{local.organizations.leaveOrgButton}</span>
    </Button>);

  const modalSection = (
    <div className='button-container'>
      {showModal &&
        <ConfirmModal
          modalTitle={local.organizations.leaveOrgHeader}
          modalPromptText={leaveOrgPromptText}
          deleteYes={local.organizations.deleteYes}
          deleteNo={local.organizations.deleteNo}
          handleYes={handleLeaveOrg}
          handleNo={triggerModal}
          isOpen />}
      {leaveOrgButton}
    </div>);

  return (
    <AvatarCard
      avatar={avatar}
      actionButtons={modalSection}
      heading={memberProfile.name}
      subheading={localizedrole} />
  );
}

OrganizationMembersListItem.propTypes = {
  member: PropTypes.shape({
    profileId: PropTypes.string,
    profileName: PropTypes.string,
    email: PropTypes.string,
    membershipId: PropTypes.string,
    organizationId: PropTypes.string,
    organizationName: PropTypes.string,
    role: PropTypes.number
  })
};
