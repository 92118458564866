import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import local from 'services/localization/local';

export default function DatePeriod({ value }) {
  if (!value) return null;

  const { start, startIncludesTime, end, endIncludesTime } = value;

  const buildDate = (date, includesTime) => {
    if (date) {
      return includesTime ?
        moment(date).format(local.simpleDateTimeFormat) :
        moment(date).format(local.simpleDateFormat);
    } else {
      return null;
    }
  };

  return (
    <small className='date-period'>
      {buildDate(start, startIncludesTime)}
      {(start && end) && (<span> - </span>)}
      {buildDate(end, endIncludesTime)}
    </small>
  );
}

DatePeriod.propTypes = {
  value: PropTypes.shape({
    start: PropTypes.string,
    startIncludesTime: PropTypes.bool,
    end: PropTypes.string,
    endIncludesTime: PropTypes.bool
  })
};

