/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LabResultListItem  from './LabResultListItem';
import { useDispatch, useSelector } from 'react-redux';

import LabResultsTrend from './LabResultsTrend';
import LabResultsEncounterList from './LabResultsEncounterList';
import { loadLabTrends, resetUnreadMedicalRecordsCount } from 'health/action-creators';
import { getProfileId } from 'app/selectors';
import local from 'services/localization/local';
import { FhirResourceTypes } from 'services/enums';
import { getUnreadMedicalRecordsCount } from 'health/health-selectors';

export default function LabResultList(props) {
  const { config } = props;
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const data = useSelector(state=>state.health?.labResult?.data);
  const unreadMedicalRecordsCount = useSelector(state => getUnreadMedicalRecordsCount(state, profileId, FhirResourceTypes.DiagnosticReport));
  const [activeTab, setActiveTab] = useState('tab1');
  const content = local.observation.labResult;

  useEffect(() => {
    dispatch(loadLabTrends(profileId));
  },[]);

  useEffect(()=>{
    if(unreadMedicalRecordsCount > 0)
      dispatch(resetUnreadMedicalRecordsCount(profileId, FhirResourceTypes.DiagnosticReport));
  },[profileId, unreadMedicalRecordsCount]);

  const showTrends = (tab)=>{
    setActiveTab(tab);
    document.getElementById('mainContentContainer').scroll(0,0);
  };

  return (
    <div className='lab-results'>
      <ol className='tab-list'>
        <li className={activeTab === 'tab1' ? 'tab-list-item tab-list-active' : 'tab-list-item'} onClick={() => showTrends('tab1')}>{content.viewEncounters}</li>
        <li className={activeTab === 'tab2' ? 'tab-list-item tab-list-active' : 'tab-list-item'} onClick={() => showTrends('tab2')}>{content.viewTrends}</li>
      </ol>
      <div className='lab-results-listItem'>
        {activeTab === 'tab1' ?
          <React.Fragment>
            {
              data && data.map(item =>
                item.attachedFileData ?
                  <div key={item.id || item.clientId}>
                    <LabResultListItem config={config} observation={item} />
                  </div>
                  : <LabResultsEncounterList key={item.remoteId} encounters={item} />
              )
            }
          </React.Fragment> : <LabResultsTrend />}
      </div>
    </div>
  );
}

LabResultList.propTypes = {
  config: PropTypes.shape().isRequired
};
