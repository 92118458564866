import React, { useEffect, useState } from 'react';
import { Button, ModalWrapper } from 'shared';
import local from 'services/localization/local';
import { useDispatch, useSelector } from 'react-redux';
import { ObservationConnectionStatus } from 'services/enums';
import { SaveObservationConnections } from './action-creators';
import { Deactivate, Manual } from 'services/constants';
import './InfoModal.scss';
import { getProfileId } from 'app/selectors';
import { getUniqueId } from 'services/helpers';

function InfoModal(props) {
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const buttonText = props.action === Deactivate ? local.observationConnect.deactivate : local.accept;
  const [goalRequestId, setGoalRequestId] = useState(null);

  useEffect(() => {
    setGoalRequestId(getUniqueId());
  },[profileId]);


  const SaveManualTracking = () => {
    let body = {
      observationType:props.observationType,
      status:  props.action === Deactivate ? ObservationConnectionStatus.Deactivated : ObservationConnectionStatus.Manual
    };
    dispatch(SaveObservationConnections(profileId, body, props?.programId, goalRequestId));
    props.onRequestClose();
  };

  const getTitle = (action) => {
    const title = action === Manual ? `${local.observationsTitle[props.observationType]} ${local.observationConnect.manualTrackingPathway}` : `${local.formatString(local.observationConnect.deactivateTitle, local.observationsTitle[props.observationType])}`;
    return title;
  };

  const getBody = (action) => {
    const body = action === Manual ? `${local.formatString(local.observationConnect.manualTrackingDescription, local.observationsTitle[props.observationType])}` : `${local.formatString(local.observationConnect.deactivateDescription, local.observationsTitle[props.observationType])}`;
    return body;
  };


  return (
    props.isOpen &&
      <ModalWrapper isOpen={props.isOpen}>
        <div id="info-modal" data-testid="info-modal" className="modal-content">
          <div className="modal-header">
            <div></div>
            <div className='modal-title'>
              {getTitle(props.action)}
            </div>
          </div>
          <div className="modal-body">
            <div className="verify-successMsg">
              <div className="error-msg-title" >
                {getBody(props.action)}
              </div>
              {props.action === Manual &&
              <div className="error-msg-title bold" >
                {local.observationConnect.manualTrackingConfirm}
              </div>}
            </div>
          </div>
          <div className='modal-footer'>
            <Button id="cancel-delete" className="btn btn-secondary small-btn" onClick={() => {props.onRequestClose();}}>{local.formCancel}</Button>
            <Button id="confirm-delete" className='btn btn-primary small-btn' color="blue" onClick={SaveManualTracking}>{buttonText}</Button>
          </div>
        </div>
      </ModalWrapper>
  );

}

export default InfoModal;
