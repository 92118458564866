import React from 'react';
import PropTypes from 'prop-types';
import Redux from 'shared/react-redux-hooks';
import { getProfileId } from 'app/selectors';
import { ObservationType } from 'services/enums';
import { getObservationTypeSavingStatus } from 'health/observations/observation-selectors';
import LogLabResultForm from 'health/observations/lab-results/log/LogLabResultForm';

export function LogLabResult({ handleBack, pathwayContext}) {
  const profileId = Redux.useSelector(getProfileId);
  const saving = Redux.useSelector((state) => getObservationTypeSavingStatus(state, ObservationType.LabResult));

  return (
    <LogLabResultForm
      pathwayContext={pathwayContext}
      profileId={profileId}
      handleBack={handleBack}
      saving={saving} />
  );
}

LogLabResult.propTypes = {
  handleBack: PropTypes.func.isRequired,
  pathwayContext: PropTypes.shape()
};

export default LogLabResult;
