import React, { useState, useEffect,useRef} from 'react';
import { Page,Icon,Button,LoadingOverlay } from 'shared';
import { updateChangedEmail,removeChangeError, resendCodeEmail } from 'preferences/changeEmail/action-creators';
import { useDispatch ,useSelector } from 'react-redux';
import { routePaths } from 'services/routing';
import local from 'services/localization/local';
import VerificationInput  from 'react-verification-input';
import './verified-change-email.scss';
import ChangeEmailSignOut from './ChangeEmailSignOut';
import { sendAgainVerificationCodeLimitSeconds,helpScoutBeaconId } from 'app/config';
import * as beaconService from 'services/helpscout-beacon-service';

export function VerifiedChangeEmail() {
  const dispatch = useDispatch();
  const email = useSelector(state => state.preferences.newEmail);
  const changeEmailError = useSelector(state => state.preferences.changeEmailError);
  const forceSignOut = useSelector(state => state.preferences.forceSignout);
  const [enabledEditing,setEnabled] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [isBeaconInitialized, setBeaconInitialized] = useState(false);
  const [isBeaconOpen, setBeaconOpen] = useState(false);
  const [showSpinner,setShowSpinner] = useState(false);
  const [verificationCode,setVerificationCode]= useState('');
  const [showResendMessage, setShowResendMessage] = useState(false);
  const textInput = useRef(null);

  useEffect(() => {
    if (seconds >0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  },[seconds]);

  useEffect(() => {
    if(changeEmailError)
    {
      setVerificationCode('');
    }
    setShowSpinner(false);
  },[changeEmailError]);

  function onBeaconClose() {
    setBeaconOpen(false);
  }

  function contactUsClick() {
    if (isBeaconOpen)
      return;

    if (!isBeaconInitialized) {
      beaconService.initialize(helpScoutBeaconId);
      beaconService.setDefaultConfiguration();
      beaconService.setIdentity(name, email);
      beaconService.prefillUserInformation(name, email);
      beaconService.setContent('','');
      beaconService.navigate('/ask/');
      beaconService.addCallbackEvent('close', onBeaconClose);
      setBeaconInitialized(true);
    }
    beaconService.open();
    setBeaconOpen(true);
  }

  const handleSubmit = (code) =>{
    if(enabledEditing)
    {
      setShowSpinner(true);
      dispatch(updateChangedEmail(email,code));
      neutralizeBack();
    }
  };

  const neutralizeBack = () => {
    window.history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, '', window.location.href);
    };
  };

  const handleResendVerificationcode = () => {
    removeError();
    setSeconds(sendAgainVerificationCodeLimitSeconds);
    setShowResendMessage(true);
    dispatch(resendCodeEmail());
    textInput.current.focus();
  };

  const removeError = () => {
    dispatch(removeChangeError());
  };

  const onChangeVerificaitonCode = (e) => {
    removeError();
    setEnabled(true);
    setShowResendMessage(false);
    setVerificationCode(e);
    if(e.length == 4)
    {
      handleSubmit(e);
    }
  };
  return (
    <Page
      id="preferences-selection-component"
      centerElement={local.accountSettings.pageTitle}
      backPath={{ to: routePaths.preferences }}>
      {showSpinner && <LoadingOverlay />}
      <div id="verifyCode">
        <Icon symbol='verificationCodeLock' />
        <h2>{local.accountSettings.verificationCode}</h2>
        <small>{local.formatString(local.accountSettings.verificationCodeMessage, email ?? '')}</small>
        <div className="codeField form-row custom-styles" data-testid="verification-inputdiv">
          {/*  https://www.npmjs.com/package/react-verification-input, https://reactjsexample.com/an-autocomplete-verification-code-input-with-react/ */}
          <VerificationInput inputProps={{ 'data-testid': 'verify-input', 'type':'tel' }} value={verificationCode} validChars='0-9' onChange={e => {onChangeVerificaitonCode(e);}} length={4} autoFocus ref={textInput} />
        </div>
        {showResendMessage && <label className="message">{local.accountSettings.resendCode}</label>}
        {changeEmailError && <label className="error attemptError">{changeEmailError}</label>}

        {!changeEmailError && forceSignOut && <ChangeEmailSignOut email={email} />}

        <div className="verifySupportLinks">
          <a className="resentCode">
            <Button
              id="resend-email"
              data-testid="resend-code"
              onClick={handleResendVerificationcode}
              color="transparent"
              disabled={seconds > 0}>
              {local.accountSettings.resendVerificationCode}
            </Button>
          </a>
          <small>Having trouble?<a className="contactHelp"><Button id="contact-us" onClick={contactUsClick} color="transparent">{local.accountSettings.contactUs}</Button></a></small>
        </div>
      </div>
    </Page>
  );
}
