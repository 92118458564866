import local from 'services/localization/local';
import { OrganizationMemberRole } from 'services/enums';

export function getLocalizedRole(role) {
  let result;
  switch(role){
    case OrganizationMemberRole.Admin:
      result = local.organizations.roles.admin;
      break;
    case OrganizationMemberRole.Member:
      result = local.organizations.roles.member;
      break;
    default:
      result = local.organizations.roles.none;
      break;
  }

  return result;
}

