/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, LinkWrapper, ModalWrapper, NavIcon } from 'shared';
import { routePaths, buildPath } from 'services/routing';
import local from 'services/localization/local';
import { getIdentityId } from 'identity/selectors';
import withContext from 'app/withContext';
import { getProfilename } from 'app/selectors';
import { getSelfProfileId } from 'profiles/list/selectors';
import { loadPendingInvitedPrograms ,updateProgramsLastDismissedOn} from 'programs/programs-actions';
import { getOrganizationInvites, getWhiteLabel } from 'organizations/organizations-selectors';
import { getActiveUsersProgramInvites, getSortedInvitedPrograms } from 'programs/programs-selectors';
import './full-screen-modal.scss';
import { ProgramTabName, ProgramTabStoreName, ProgramVisibility, InvitedProgramId, WhiteLabelProperties } from 'services/enums';
import { setLocalStorage } from 'services/helpers.js';
import { ImageTypes, InviteBtnColor, InviteColor, InviteFontColor } from 'services/constants';
import symbol from 'shared/icon/symbol-paths';


export function FullScreenModal() {
  const dispatch = useDispatch();
  const content = local.fullScreenModel;
  const profileName = useSelector(getProfilename);
  const profileId = useSelector(getSelfProfileId);
  const permissions = useSelector(state => getActiveUsersProgramInvites(state));
  const { data } = useSelector(getOrganizationInvites);
  const orgName = data && data.length > 0 ? data[0]?.organizationName : permissions && permissions.length > 0 ? permissions[0]?.organizationName : null;
  const identifier = data && data.length > 0 ? data[0]?.organizationMembershipId : permissions && permissions.length > 0 ? permissions[0]?.memberId : null;
  const organizationId = data && data.length > 0 ? data[0]?.organizationId : permissions && permissions.length > 0 ? permissions[0]?.organizationId : null;
  const [isSplashShown, setSplashShown] = useState(false);
  const invitedPrograms = useSelector(state => getSortedInvitedPrograms(state));
  const invitedProgramsList = invitedPrograms && invitedPrograms.length > 0 ? invitedPrograms.filter(x => x.inviteStatus === 1 && x.programVisibilityStatus != ProgramVisibility.Public) : null;
  const identityId = useSelector(getIdentityId);
  const {whiteLabel} = useSelector(state => getWhiteLabel(state));
  const inviteBgColor = whiteLabel?.find(x=>x.key == WhiteLabelProperties.InviteBgColorCode)?.value;
  const inviteBtnColor = whiteLabel?.find(x=>x.key == WhiteLabelProperties.InviteButtonColorCode)?.value;
  const logoUrl = whiteLabel?.find(x=>x.key == WhiteLabelProperties.LogoUrl)?.value;
  const inviteFontColor = whiteLabel?.find(x=>x.key == WhiteLabelProperties.InviteFontColorCode)?.value;
  const THREE_SECONDS = 3000;
  useEffect(() => {
    inviteBgColor && document.documentElement.style.setProperty(InviteColor, inviteBgColor);
    inviteBtnColor && document.documentElement.style.setProperty(InviteBtnColor, inviteBtnColor);
    inviteFontColor && document.documentElement.style.setProperty(InviteFontColor, inviteFontColor);
    setTimeout(() => {
      setSplashShown(true);
    }, THREE_SECONDS);
    dispatch(loadPendingInvitedPrograms(profileId));
    setLocalStorage(ProgramTabStoreName, ProgramTabName.Invited);
    setLocalStorage(InvitedProgramId, null);
  }, [inviteBgColor, inviteBtnColor, inviteFontColor]);

  function CloseModal ()
  {
    dispatch(updateProgramsLastDismissedOn(identityId));
    setSplashShown(false);
    setLocalStorage(ProgramTabStoreName, ProgramTabName.Active);
  }

  if (data && data.length < 1 && permissions && permissions.length < 1) {
    return null;
  }

  if (invitedProgramsList && invitedProgramsList.length <= 0) {
    return null;
  }

  if (organizationId == null || organizationId == undefined || identifier == null || identifier == undefined) {
    return null;
  }

  const params = {
    organizationId: organizationId,
    membershipId: identifier,
    invitedProgramsList: invitedProgramsList,
    hasProperties: (data.properties && data.properties.length > 0) || null
  };
  function renderLogo(){
    if(logoUrl && (logoUrl.endsWith(ImageTypes.png) || logoUrl.endsWith(ImageTypes.jpg))){
      return(<img className='branding-logo' src={logoUrl}></img>);
    }
    else if(logoUrl && logoUrl.endsWith(logoUrl.endsWith(ImageTypes.svg))){
      return ( <NavIcon Icon={logoUrl}></NavIcon>);
    }
    else{
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <g><circle cx="15.5" cy="9.5" r="1.5" /><circle cx="8.5" cy="9.5" r="1.5" /><path d={symbol.arc} />
            <path d={symbol.smiley} />
          </g>
        </svg>);
    }
  }
  if(invitedProgramsList && invitedProgramsList.length > 0 && whiteLabel && whiteLabel.length > 0){
    return (
      <ModalWrapper className='card-modal-wrapper pathway-invite-modal' isOpen={isSplashShown && invitedProgramsList && invitedProgramsList.length > 0} onRequestClose={CloseModal}>
        <div className="content">
          <div className='face-emotion-icon'>
            {renderLogo()}
          </div>
          <div className="congrats-header header-branding"> {profileName}! </div>
          <div data-testid="full-screen-modal">
            <div className="congrats-content content-size"> {content.invite} <span className="invite-orgName">{orgName}</span>. </div>
            <div className="sticky-button-section action">
              <LinkWrapper to={invitedProgramsList.length > 1 ? routePaths.programsList : buildPath(routePaths.programsInvite, { programId: invitedProgramsList[0]?.programId })} params={params} className="button white" id="fullscreen-modal" >{content.pathway}</LinkWrapper>
              <Button id='close-badge-modal' className='transparent-outline' onClick={CloseModal}>{local.takeMeLater}</Button>
            </div>
          </div>
        </div>
      </ModalWrapper>
    );
  }
  return (
    invitedProgramsList && invitedProgramsList.length > 0 &&
    <ModalWrapper className='card-modal-wrapper pathway-invite-modal' isOpen={isSplashShown && invitedProgramsList ? invitedProgramsList.length > 0 : null} onRequestClose={CloseModal}>
      <div className="content">
        <div className="congrats-header default"> {profileName}! </div>
        <div className='face-emotion-icon'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g><circle cx="15.5" cy="9.5" r="1.5" /><circle cx="8.5" cy="9.5" r="1.5" /><path d="M12,18c2.28,0,4.22-1.66,5-4H7C7.78,16.34,9.72,18,12,18z" />
              <path d="M11.99,2C6.47,2,2,6.48,2,12c0,5.52,4.47,10,9.99,10C17.52,22,22,17.52,22,12C22,6.48,17.52,2,11.99,2z M12,20 c-4.42,0-8-3.58-8-8c0-4.42,3.58-8,8-8s8,3.58,8,8C20,16.42,16.42,20,12,20z" />
            </g>
          </svg>
        </div>
        <div data-testid="full-screen-modal">
          <div className="congrats-content default"> {content.invite} <span className="invite-orgName">{orgName}</span>. </div>
          <div className="sticky-button-section action">
            <LinkWrapper to={invitedProgramsList.length > 1 ? routePaths.programsList : buildPath(routePaths.programsInvite, { programId: invitedProgramsList[0]?.programId })} params={params} className="button white default-black" id="fullscreen-modal" >{content.pathway}</LinkWrapper>
            <Button id='close-badge-modal' className='transparent-outline default' onClick={CloseModal}>{local.takeMeLater}</Button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

export default withContext(FullScreenModal);
