import { types } from 'health/health-types';
import {getData} from 'app/request/persistent-data-selectors';
import moment from 'moment';
import { ObservationType } from 'services/enums';
import { getObservationData } from 'health/observations/observation-selectors';
import { DateFormat } from 'services/constants';
import { compareDatesAscending } from 'services/date-service';

export const getActiveGlucoseGoal = (state, profileId) => {
  const goals = getData(state.health, types.GLUCOSE_GOALS, profileId);
  if(goals && goals.length > 0){
    const activeGoal = goals.find(g => g.isActive);

    if(activeGoal){
      const result = {};
      result.fromValue = activeGoal.fromValue;
      result.toValue = activeGoal.toValue;
      return result;
    }
  }
  return null;
};

export const getBloodGlucose = (state, profileId) => {
  const allBloodGlucose = getObservationData(state, ObservationType.BloodGlucose)
    .filter(x => x.profileId === profileId);
  return allBloodGlucose;
};

export const getBloodGlucoseVitalChartData = (state, profileId, selectedEnd,fromPathway,bloodGlucosePathwayData) => {
  const startDayMoment = moment(selectedEnd).subtract(1, 'days');

  const filteredBloodGlucose = fromPathway ? bloodGlucosePathwayData && bloodGlucosePathwayData.length >0 && bloodGlucosePathwayData.filter(x => moment(x.recordedAt).format(DateFormat) == moment(selectedEnd).format(DateFormat)) : getBloodGlucose(state, profileId).filter(x => moment(x.recordedAt).format(DateFormat) == moment(selectedEnd).format(DateFormat));
  let sortedResults =  filteredBloodGlucose.sort((a, b) => compareDatesAscending(a.recordedAt, b.recordedAt));
  const averageCalc = filteredBloodGlucose.length >=1 && filteredBloodGlucose.reduce((a,b) => a+(b.value) , 0)/filteredBloodGlucose.length;
  const average = Math.round(averageCalc);
  let count  = 0;
  const date = startDayMoment.format(moment.HTML5_FMT.DATE);

  const bloodGlucoseDataByDate = sortedResults.map((bloodGlucose)=>{
    const bloodGlucoseRecordedAtMoment = moment(bloodGlucose.recordedAt).format(moment.HTML5_FMT.DATE);
    if (bloodGlucoseRecordedAtMoment === date) {
      count++;
    }

    return {
      date:bloodGlucoseRecordedAtMoment,
      totalBloodGlucose:bloodGlucose.value,
      count:count,
      time:moment(bloodGlucose.recordedAt).format('h:mm A'),
      average:average
    };
  });
  return bloodGlucoseDataByDate;
};

export const getAveragePerWeekBloodGlucose = (state,profileId,fromPathway,bloodGlucosePathwayData) => {
  function getLastWeeksDate() {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
  }
  const bloodGlucoseData =  fromPathway ? bloodGlucosePathwayData && bloodGlucosePathwayData.length > 0 && bloodGlucosePathwayData :getBloodGlucose(state,profileId);
  const LastWeekDate = moment(getLastWeeksDate()).format(moment.HTML5_FMT.DATE);
  let lastWeekData = bloodGlucoseData && bloodGlucoseData.length > 0 ? bloodGlucoseData.filter(item => moment(item.recordedAt).format(moment.HTML5_FMT.DATE) > LastWeekDate) : '';

  const bloodGlucoseAverage = lastWeekData && lastWeekData.length > 0 ? lastWeekData.reduce((total, bloodGlucose) => total + bloodGlucose.value, 0) / lastWeekData.length : 0;

  if(bloodGlucoseAverage) {
    return `${ Math.round(bloodGlucoseAverage)}`;
  }
  return  0;
};

export const getMinMaxBloodGlucoseValue = (state,profileId,fromPathway,bloodGlucosePathwayData) =>{
  const result = {
    inRangePercent: 0,
    min: 0,
    max: 0
  };
  const bloodGlucoseData = fromPathway ? bloodGlucosePathwayData && bloodGlucosePathwayData.length > 0 && bloodGlucosePathwayData : getBloodGlucose(state,profileId);
  const activeGoal = getActiveGlucoseGoal(state,profileId);
  const bloodGlucoseValues = bloodGlucoseData.map(item => Math.round(item.value));

  if(!bloodGlucoseData || bloodGlucoseData.length <= 0 ||bloodGlucoseValues.length <=0)
    return result;

  result.min = Math.round(Math.min(...bloodGlucoseValues));
  result.max =  Math.round(Math.max(...bloodGlucoseValues));

  if(activeGoal){
    const inRangeValues = bloodGlucoseValues?.filter(item => item >= activeGoal.fromValue && item <= activeGoal.toValue);
    result.inRangePercent = Math.round((inRangeValues.length/bloodGlucoseValues.length)*100);
  }

  return result;
};

export const getMinMaxHighFrequencyData = (state,profileId,selectedEnd,fromPathway,bloodGlucosePathwayData) =>{
  const result = {
    inRangePercent: 0,
    min: 0,
    max: 0
  };

  const bloodGlucoseData = fromPathway ? bloodGlucosePathwayData && bloodGlucosePathwayData.length > 0 : getBloodGlucose(state,profileId);
  const activeGoal = getActiveGlucoseGoal(state,profileId);
  const bloodGlucoseDataByDate =fromPathway ? bloodGlucosePathwayData && bloodGlucosePathwayData.length > 0 && bloodGlucosePathwayData.filter(x => moment(x.recordedAt).format(DateFormat) == moment(selectedEnd).format(DateFormat)) : bloodGlucoseData.length > 0 ? bloodGlucoseData.filter(x => moment(x.recordedAt).format(DateFormat) == moment(selectedEnd).format(DateFormat)) : '';

  if(!bloodGlucoseData || bloodGlucoseData.length <= 0 ||!bloodGlucoseDataByDate || bloodGlucoseDataByDate.length <= 0)
    return result;

  const bloodGlucoseValues = bloodGlucoseDataByDate.map(item => Math.round(item.value));
  result.min = Math.round(Math.min(...bloodGlucoseValues));
  result.max =  Math.round(Math.max(...bloodGlucoseValues));

  if(activeGoal){
    const inRangeValues = bloodGlucoseValues?.filter(item => item >= activeGoal.fromValue && item <= activeGoal.toValue);
    result.inRangePercent = Math.round((inRangeValues.length/bloodGlucoseValues.length)*100);
  }

  return result;
};

