import React from 'react';
import PropTypes from 'prop-types';
import { Page, Button } from 'shared';
import { routePaths } from 'services/routing';
import withNavigation from 'app/withNavigation';
import MobileBackButtonController from 'mobile/MobileBackButtonController';
import local from 'services/localization/local';
import './page-404.scss';

class Page404 extends React.Component {
  constructor(props) {
    super(props);
    this.onGoHome = this.onGoHome.bind(this);
  }

  onGoHome() {
    this.props.navigate({ to: routePaths.root });
  }

  render() {
    return (
      <Page
        id="page-404"
        topChevronColor='yellow'
        titleTabText={local.page404.titleTab}>
        <h3>{local.page404.heading}</h3>
        <p>
          <span>{local.page404.subhead}</span>
          <br />
          <br />
          <small>{local.page404.error}</small>
        </p>
        <Button
          id="go-home-button"
          className="btn btn-primary"
          onClick={this.onGoHome}
          color="blue">
          {local.page404.goBackButton}
        </Button>
        <MobileBackButtonController onPress={this.onGoHome} />
      </Page>
    );
  }
}

Page404.propTypes = {
  navigate: PropTypes.func.isRequired
};

export default withNavigation(Page404);
