import moment from 'moment';
import local from 'services/localization/local';
import * as healthDataApi from 'services/api/healthdata-api';
import * as requestQueueActions from 'app/request/request-queue-actions';
import * as persistentDataActions from 'app/request/persistent-data-actions';
import { types } from './journal-types';

export const actionNames = {
  JOURNALENTRY_SAVE: 'JOURNALENTRY_SAVE',
  JOURNALENTRY_DELETE: 'JOURNALENTRY_DELETE'
};

export const actions = {
  saveJournalEntry: (journalEntry) => ({
    type: actionNames.JOURNALENTRY_SAVE,
    payload: {
      journalEntry
    }
  }),
  deleteJournalEntry: (profileId, journalEntryId) => ({
    type: actionNames.JOURNALENTRY_DELETE,
    payload: {
      journalEntryId,
      profileId
    }
  })
};

export function saveJournalEntry({ profileId, title, content, recordedAt }, requestId) {
  const saveRequest = {
    name,
    recordedAt: moment(recordedAt).format(),
    title: title || null,
    content
  };

  return (dispatch) => {
    const action = state => healthDataApi.postJournal(profileId, state, saveRequest)
      .then(journalEntry => dispatch(actions.saveJournalEntry(journalEntry)));

    dispatch(requestQueueActions.queueRequestWithToast(action, types.JOURNALENTRY_REQUESTS, requestId, {}, { profileId }));
  };
}

export function loadJournalEntries({ profileId, dateRangeStart, dateRangeEnd }) {
  const action = state => healthDataApi.getJournal(profileId, dateRangeStart, dateRangeEnd, state)
    .then(result => result.results);

  return persistentDataActions.request(action, types.JOURNALENTRIES, profileId);
}

export function deleteJournalEntry({ profileId, journalEntryId }) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch(actions.deleteJournalEntry(profileId, journalEntryId));
    };

    const action = state => healthDataApi.deleteJournal(profileId, state, journalEntryId)
      .then(onSuccess);

    dispatch(requestQueueActions.queueRequestWithToast(action, types.JOURNALENTRY_REQUESTS, null, {
      processingMessage: local.deleting,
      successMessage: local.deleted
    }, { profileId }));
  };
}
