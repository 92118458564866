import React from 'react';
import local from 'services/localization/local';
import { Icon } from 'shared';
import { routePaths } from 'services/routing';
import DashboardTile from 'health/lil-charts/DashboardTile';

export default function TimelineLilChart() {
  return (
    <DashboardTile
      id='dashboard-tile-timeline'
      path={{ to: routePaths.healthTimeline }}>
      <div className="dashboard-tile-centered">
        <Icon symbol="eventnote" size="2em" />
        <small className="dashboard-tile-centered-text">{local.timeline.title}</small>
      </div>
    </DashboardTile>
  );
}
