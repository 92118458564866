import React from 'react';
import local from 'services/localization/local';
import { ObservationType, Unit } from 'services/enums';
import { routePaths } from 'services/routing';
import { configIds } from 'health/observations/observation-config-ids';
import { baseObservationConfig } from 'health/observations/observation-config-helpers.jsx';
import { GenericObservationDeviceLinkNoData } from 'health/observations/generics';
import { BloodOxygen } from 'shared/fullColorIcons';
import { roundToDecimals } from 'services/value-formatter';
import { loadObservationEhrData } from 'health/action-creators';

const typeDefinition = {
  type: ObservationType.BloodOxygen,
  typeUnitMap: {
    [ObservationType.BloodOxygen]: Unit.Percent
  },
  unitLabelMap: {
    [Unit.Percent]: local.units.percent
  }
};

export default {
  ...baseObservationConfig,
  ...typeDefinition,
  id: configIds.bloodOxygen,
  backPath: { to: routePaths.health },
  centerElement: local.observation.bloodOxygen.title,
  noDataText: local.observation.bloodOxygen.noDataText,
  iconSymbol: BloodOxygen,
  loadData: (props) => [props.config.type].flat().forEach(x => props.dispatch(loadObservationEhrData({ observationType: x, profileId: props.profileId }))),
  valueFormatter: (props) => `${roundToDecimals(props.observation.value, 2)}${props.config.unitLabelMap[props.observation.unit]}`,
  renderNoDataComponent: (props) => props && <GenericObservationDeviceLinkNoData message={props.config.noDataText} />
};
