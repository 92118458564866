/* eslint-disable react/jsx-no-leaked-render */
import React, { useEffect, useState } from 'react';
import Redux from 'shared/react-redux-hooks';
import { Page, NavIcon, BackAction } from 'shared';
import local from 'services/localization/local';
import { BloodPressure } from 'shared/fullColorIcons';
import BloodPressureGoalForm from './BloodPressureGoalForm';
import { buildPath, routePaths } from 'services/routing';
import { configIds } from '../observation-config-ids';
import * as healthActions from 'health/action-creators';
import { ObservationType } from 'services/enums';
import { getGroupedObservationSavingStatus } from 'health/observations/observation-selectors';
import {  useSelector} from 'react-redux';
import { getProfileId } from 'app/selectors';
import { getRequestProcessing } from 'app/request/request-selectors';
import { getUniqueId } from 'services/helpers';
import {  getActiveGoal, getBloodPressureAverage } from './blood-pressure-selectors';
import { useHistory, withRouter } from 'react-router-dom';
import withNavigation from 'app/withNavigation';

function BloodPressureGoal(props){
  const dispatch = Redux.useDispatch();
  const history = useHistory();
  const content = local.observation.bloodPressure;
  const profileId = useSelector(getProfileId);
  const [submitRequestId, setSubmitRequestId] = useState();

  const isRequestProcessing = useSelector(state => getRequestProcessing(state, submitRequestId));
  const saving = Redux.useSelector(
    (state) => getGroupedObservationSavingStatus(state, [ObservationType.Systolic, ObservationType.Diastolic])
  );
  const latestData = useSelector(state => getActiveGoal(state, profileId));
  const average = useSelector(state =>getBloodPressureAverage(state,{profileId}));
  const [path, setPath] = useState({});
  const fromPathway = history.location?.state?.fromPathway;
  const routeParams = history.location?.state?.routeParams;

  useEffect(()=>{
    if(fromPathway && routeParams){
      setPath({ to: routePaths.programsDetails, params: { profileId : routeParams.profileId, programId:routeParams.programId ,programEnrollmentId:routeParams.programEnrollmentId },state:{goalObservationType : ObservationType.Systolic, isFromCreateGoal: props.location?.state?.isFromCreateGoal} });
    }else{
      setPath({ to: buildPath(routePaths.healthObservation, { configId: configIds.bloodGlucose }), params: { profileId },state:{goalObservationType : ObservationType.Systolic} });
    }
  },[fromPathway]);


  const observationType = [ObservationType.Systolic,ObservationType.Diastolic];
  useEffect(() => {
    dispatch(healthActions.loadSystolicGoals(profileId));
    dispatch(healthActions.loadDiastolicGoals(profileId));
    [observationType].flat().forEach(type => dispatch(healthActions.loadObservationData({ profileId, observationType: type })));
    setSubmitRequestId(getUniqueId());
  }, [profileId]);
  const bloodPressureGoalSaved = useSelector(state=> state?.request?.bloodPressureGoalSaved);
  function handleBack() {
    props.navigate(path);
  }
  if(bloodPressureGoalSaved){
    handleBack();
  }

  const getInitialValues = () => {
    return {
      systolicMin: latestData?.systolicMin,
      systolicMax: latestData?.systolicMax,
      diastolicMin: latestData?.diastolicMin,
      diastolicMax: latestData?.diastolicMax
    };
  };

  function handleSubmit(input){
    const newValue = {
      systolicMin: input.systolicMin,
      systolicMax: input.systolicMax,
      diastolicMin: input.diastolicMin,
      diastolicMax: input.diastolicMax
    };
    dispatch(healthActions.saveBloodPressureGoal(profileId, newValue, submitRequestId));
  }

  return (
    <Page
      id='step-target-component'
      backAction={<BackAction handleBack={handleBack} />}
      centerElement={content.goalPageTitle}>
      <div className="summary-metricIcon">
        <NavIcon Icon={BloodPressure}></NavIcon>
      </div>
      <div className="suggested_pathway" >
        <div className="stepGoal-Content">{content.goalPageContent}</div>
      </div>
      <BloodPressureGoalForm
        initialValues={getInitialValues()}
        saving={saving}
        isProcessing={isRequestProcessing}
        onSubmit={handleSubmit}
        handleBack={handleBack}
        average={average.Average} />
    </Page>
  );
}
export default withNavigation(withRouter(BloodPressureGoal));
