import compileReducers from 'services/compile-reducer';
import * as actions from './action-names';

export const initialState = {
  toastQueue: []
};

const reducers = {};

reducers[actions.TOASTER_ADD] = (state, payload) => ({
  ...state,
  toastQueue: [...state.toastQueue, payload]
});

reducers[actions.TOASTER_UPDATE] = (state, payload) => ({
  ...state,
  toastQueue: state.toastQueue.map(toast => toast.id == payload.id ? {
    ...toast,
    ...payload
  } : toast)
});

reducers[actions.TOASTER_REMOVE] = (state, payload) => {
  return {
    ...state,
    toastQueue: state.toastQueue.filter(toast => toast.id !== payload.id)
  };
};

export default compileReducers(reducers, initialState);
