import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { routePaths } from 'services/routing';
import { ProfileLink } from 'shared';
import ProgramListItem from './ProgramListItem';

class ProgramListItemComplete extends React.Component {
  render() {
    const { enrollment } = this.props;
    const logName = `program-completed-${enrollment.program.name.toLowerCase().replace(/\s/g, '-')}`;

    const params = {
      programId: enrollment.program.programId,
      programEnrollmentId: enrollment.id
    };

    const action = (
      <ProfileLink id={`program-complete-${params.programId}`} logName={logName} className='indicator-link' to={routePaths.programsDetails} params={params}>
        <small className="completed-date">{moment(enrollment.completeDateTime).fromNow()}</small>
      </ProfileLink>
    );

    return (
      <ProgramListItem
        id={`program-complete-${enrollment.id}`}
        logName={logName}
        to={routePaths.programsDetails}
        params={params}
        programInfo={enrollment.program}
        action={action} />
    );
  }
}
ProgramListItemComplete.propTypes = {
  enrollment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    completeDateTime: PropTypes.string.isRequired,
    programInfoId: PropTypes.string.isRequired,
    program: PropTypes.shape()
  }).isRequired
};

export default ProgramListItemComplete;
