import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button, MailToLink } from 'shared';
import local from 'services/localization/local';
import { buildFeedbackEmailLink } from 'services/url-service';
import { submitFeedback } from 'services/appinsights-service';

class Vote extends React.Component {
  constructor(props) {
    super(props);

    this.thumbsUp = this.thumbsUp.bind(this);
    this.thumbsDown = this.thumbsDown.bind(this);

    this.state = {
      success: false
    };
  }

  thumbsUp() {
    const { metricName } = this.props;

    this.setState({ success: true });
    submitFeedback(metricName, 'thumbsup');
  }

  thumbsDown() {
    const { metricName } = this.props;

    this.setState({ success: true });
    submitFeedback(metricName, 'thumbsdown');
  }

  render() {
    const { success } = this.state;
    const { name } = this.props;

    const ratingStyle = {
      margin: '5px',
      padding: '20px',
      fontSize: '0px',
      lineHeight: '0px',
      borderRadius: '50%'
    };

    const mailTo = buildFeedbackEmailLink(local.formatString(local.interestEmailSubject, name));

    return success ?
      (
        <div>
          <p>
            {local.interestMessageSubmittedCta}
          </p>
          <MailToLink id="feedback-email" className="button teal" mailto={mailTo}>{local.interestCta}</MailToLink>
        </div>
      ) : (
        <div style={{ width: '100%' }}>
          <p>
            <small>{local.interestMessageCta}</small>
          </p>
          <Button
            id='placeholder-form-submit-down'
            submit
            color="blue"
            onClick={this.thumbsDown}
            style={ratingStyle}>
            <Icon symbol='thumbsdown' />
          </Button>
          <Button
            id='placeholder-form-submit-up'
            submit
            color="blue"
            onClick={this.thumbsUp}
            style={ratingStyle}>
            <Icon symbol='thumbsup' />
          </Button>
        </div>
      );
  }
}

Vote.propTypes = {
  name: PropTypes.string.isRequired,
  metricName: PropTypes.string.isRequired
};

export default Vote;
