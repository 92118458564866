import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withContext from 'app/withContext';
import DataRow from 'shared/data-row/DataRow';
import { CircleDotFigure } from 'shared';
import { intensityDescriptions } from 'activity/helpers';
import { buildDurationDisplayText, momentCalendarDateTimeFormat } from 'services/date-service';
import { deleteActivity } from 'activity/activity-actions';

class ActivityListItem extends React.Component {
  constructor(props) {
    super(props);
    this.onDelete = this.onDelete.bind(this);
    this.onClick = this.onClick.bind(this);

    this.state = {
      isSelected: false
    };
  }

  onDelete() {
    const { actions, activity, profileId } = this.props;
    actions.deleteActivity({ profileId, activityId: activity.id });
  }

  onClick() {
    if (this.state.isSelected) {
      this.setState({ isSelected: false });
    } else {
      this.setState({ isSelected: true });
    }
  }

  render() {
    const { activity } = this.props;
    const timestamp = `${moment(activity.startDateTime).calendar(null, momentCalendarDateTimeFormat)}`;
    const intensity = intensityDescriptions[activity.intensity] != undefined ? intensityDescriptions[activity.intensity].label : '';
    const duration = buildDurationDisplayText(activity.startDateTime, activity.endDateTime);
    const subhead = `${duration} - ${intensity}`;
    const color = activity.isThirdParty ? 'blue' : 'grayLight';
    return (
      <li className="scrollview-list-item">
        <DataRow
          id={activity.id || ''}
          visual={<CircleDotFigure color={color} />}
          head={activity.name}
          subhead={subhead}
          notes={activity.notes}
          source={activity.source}
          deviceDisplayName={activity.deviceDisplayName}
          healthDataSource={activity.healthDataSource}
          handleRowClick={this.onClick}
          handleDelete={this.onDelete}
          selected={this.state.isSelected}
          timestamp={timestamp} />
      </li>
    );
  }
}

ActivityListItem.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    intensity: PropTypes.number.isRequired,
    startDateTime: PropTypes.string.isRequired,
    endDateTime: PropTypes.string.isRequired,
    source:PropTypes.string.isRequired,
    deviceDisplayName:PropTypes.string.isRequired,
    healthDataSource:PropTypes.number.isRequired
  }).isRequired,
  // store
  profileId: PropTypes.string.isRequired,
  // actions
  actions: PropTypes.shape({
    deleteActivity: PropTypes.func.isRequired
  })
};

function mapDispatchToProps(dispatch) {
  const actions = { deleteActivity };
  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

export default withContext(connect(null, mapDispatchToProps)(ActivityListItem));
