import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadMedications } from 'health/medication/action-creators';
import { loadMedicationDosesForVisualization } from 'health/medication/dose/action-creators';
import withContext from 'app/withContext';
import { ProfileDataLoader } from 'shared';

class MedicationDataLoader extends React.Component {
  constructor(props) {
    super(props);
    this.loadCurrentProfileMedications = this.loadCurrentProfileMedications.bind(this);
  }

  loadCurrentProfileMedications() {
    const { actions, profileId } = this.props;
    actions.loadMedications(profileId);
    actions.loadMedicationDosesForVisualization(profileId);
  }

  render() {
    return (
      <ProfileDataLoader loadData={this.loadCurrentProfileMedications}>
        {this.props.children}
      </ProfileDataLoader>
    );
  }
}

MedicationDataLoader.propTypes = {
  // redux
  actions: PropTypes.shape({
    loadMedications: PropTypes.func.isRequired,
    loadMedicationDosesForVisualization: PropTypes.func.isRequired
  }).isRequired,
  profileId: PropTypes.string.isRequired,
  children: PropTypes.node
};

MedicationDataLoader.defaultProps = {
  children: null
};

function mapDispatchToProps(dispatch) {
  const actions = {
    loadMedications,
    loadMedicationDosesForVisualization
  };

  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

export default withContext(connect(null, mapDispatchToProps)(MedicationDataLoader));
