import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'shared/form-fields';
import * as validators from 'services/fields/field-validators';
import local from 'services/localization/local';
import { Button } from 'shared';

export function UserNameForm({ submitting, saving, handleSubmit }) {
  const isSubmitting = submitting || saving;
  return (
    <form onSubmit={handleSubmit} data-testid="userName">
      <div className="form-row">
        <Field
          readOnly={isSubmitting}
          label={local.Registration.firstName}
          autoFocus
          name="firstName"
          data-testid="userfirstName"
          component={TextField}
          validate={[validators.required,validators.maxLength250]} />
      </div>
      <div className="form-row">
        <Field
          readOnly={isSubmitting}
          label={local.Registration.lastName}
          name="lastName"
          data-testid="userlastName"
          component={TextField}
          validate={[validators.required,validators.maxLength250]} />
      </div>
      <div className="form-row">
        <Button
          id="userName-form-submit"
          className="btn btn-primary"
          submit
          color="blue"
          data-testid="userNextbutton">
          {local.Registration.next}
        </Button>
      </div>
    </form>
  );
}

UserNameForm.displayName = 'UserName Form';
UserNameForm.propTypes = {
  saving: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool
};

export default reduxForm({
  form: 'userName',
  enableReinitialize: true
})(UserNameForm);
