import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withContext from 'app/withContext';
import { routePaths } from 'services/routing';
import local from 'services/localization/local';
import { DataLoadingStatus, ProfileDataLoader, Button, CircleDotFigure, Card } from 'shared';
import { isIphone, openMobileRoute, closeRouteInApp } from 'mobile/helpers';
import ProfilePage from 'profiles/page/ProfilePage';
import * as selectors from 'devices/devices-selectors';
import { loadValidicProfile, loadConnectedDevices, loadTerraDevices, connectTerraDevice, loadActiveDevices } from 'devices/devices-actions';
import './devices.scss';
import ConnectedDevices from './ConnectedDevices';
import withNavigate from 'app/withNavigation';
import { Close, OpenApp,AppleHealthType } from 'services/constants';

class DevicesOverview extends React.Component {
  constructor(props) {
    super(props);
    this.loadValidicProfile = this.loadValidicProfile.bind(this);
    this.openValidicUrl = this.openValidicUrl.bind(this);
    this.updateAppleHealth = this.updateAppleHealth.bind(this);
    this.updateTerraDeviceConnection = this.updateTerraDeviceConnection.bind(this);
  }

  componentDidMount() {
    const { location, showHealthKit, terraRedirectUrlParams, profileId, navigate} = this.props;
    if (showHealthKit) {
      this.updateAppleHealth();
      const isCloseParamReceived = new URLSearchParams(location.search).get(Close);

      if (isCloseParamReceived !== null && isCloseParamReceived !== undefined) {
        closeRouteInApp();
        openMobileRoute(OpenApp);
      }
    }
    if(terraRedirectUrlParams.fromTerra === 'true' && terraRedirectUrlParams.profile_Id === profileId){
      this.updateTerraDeviceConnection();
      if(terraRedirectUrlParams.isProfileConnect && terraRedirectUrlParams.isProfileConnect === 'true' && terraRedirectUrlParams.observationType)
      {
        const goalCategoryId = terraRedirectUrlParams.goalCategoryId === undefined ? '' : terraRedirectUrlParams.goalCategoryId;
        navigate({ to: `${routePaths.observationConnect}?observationType=${terraRedirectUrlParams.observationType}&source=${terraRedirectUrlParams.source}&goalCategoryId=${goalCategoryId}` });
      }

    }
  }

  updateTerraDeviceConnection()
  {
    const { actions, profileId} = this.props;
    const {user_id} = this.props.terraRedirectUrlParams;
    actions.connectTerraDevice(profileId, user_id);
  }

  updateAppleHealth()
  {
    const { appleDeviceUpdateUrl, devicesLoaded, devices} = this.props;
    let appleDeviceStatus = null;
    if(devicesLoaded)
    {
      const appleDevice = devices && devices !== undefined  && devices.find(device => device.type === AppleHealthType);
      appleDeviceStatus = appleDevice ? appleDevice.validicDeviceStatusSynced === false? null : appleDevice.connected : null;
      openMobileRoute(`${appleDeviceUpdateUrl}&appleHealthConnected=${appleDeviceStatus}`);
    }
  }

  openValidicUrl() {
    const { showHealthKit, navigate } = this.props;
    let navigateProps = { to: routePaths.connectDevice };
    if (showHealthKit) {
      navigateProps = { to: routePaths.connectDevice };
    }
    navigate(navigateProps);
  }

  loadValidicProfile() {
    const { actions, profileId } = this.props;
    actions.loadValidicProfile(profileId);
    actions.loadTerraDevices(profileId);
    actions.loadActiveDevices(profileId);
  }

  render() {
    const { loading, error, location } = this.props;
    return (
      <ProfilePage
        id='devices'
        centerElement={local.devices.overviewTitle}
        backPath={{ to: location?.state?.backpath ?? routePaths.root }}>
        <ProfileDataLoader loadData={this.loadValidicProfile} />
        <DataLoadingStatus loading={loading} error={error} errorMessage={local.devices.errorMessage}>
          <ConnectedDevices />
          <h5 className="connected-devices-title">{local.devices.syncTitle}</h5>
          <Card className="devices-card">
            <div>
              {local.devices.explanation}
              <br />
              {local.devices.deviceNames}
            </div>
            <div className='device-card-sub-content'>
              {local.devices.seeAllDevices}
            </div>

            <div>
              <Button
                id='open-validic-connect-page'
                className='btn btn-primary'
                color='blue'
                onClick={this.openValidicUrl}>
                {local.devices.connectButton}
              </Button>
            </div>
            <br />
            <div className="indicator-description">
              <CircleDotFigure />
              <small>
                {local.devices.dotDescription}
              </small>
            </div>
          </Card>
        </DataLoadingStatus>
      </ProfilePage>
    );
  }
}

DevicesOverview.propTypes = {
  // redux
  showHealthKit: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  profileId: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    loadValidicProfile: PropTypes.func.isRequired,
    loadConnectedDevices: PropTypes.func.isRequired,
    loadTerraDevices: PropTypes.func.isRequired,
    connectTerraDevice:PropTypes.func.isRequired,
    loadActiveDevices:PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape(),
  navigate: PropTypes.func,
  devicesLoaded :  PropTypes.bool,
  devices: PropTypes.arrayOf(PropTypes.shape()),
  appleDeviceUpdateUrl : PropTypes.string
};


const mapStateToProps = (state, ownProps) => ({
  showHealthKit: isIphone,
  loading: selectors.isValidicProfileLoading(state, ownProps.profileId),
  error: selectors.isValidicProfileError(state, ownProps.profileId),
  url: selectors.getValidicUrl(state, ownProps.profileId),
  devices: selectors.getDevices(state, ownProps.profileId),
  devicesLoaded: selectors.getDevicesLoaded(state, ownProps.profileId),
  appleDeviceUpdateUrl :selectors.getValidicUpdateAppleHealthStatusUrl(state, ownProps.profileId),
  terraRedirectUrlParams : selectors.terraRedirectUrlParams(state,ownProps.profileId)
});

function mapDispatchToProps(dispatch) {
  const actions = { loadValidicProfile, loadConnectedDevices, loadTerraDevices, connectTerraDevice, loadActiveDevices };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default withNavigate(withContext(connect(mapStateToProps, mapDispatchToProps)(DevicesOverview)));
