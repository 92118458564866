import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import { Icon, DatePeriod } from 'shared';
import './carePlan.scss';
import { CarePlanRerenderHTML } from './CarePlanRerenderHTML';
export default function CarePlanListItem({ carePlan }) {
  const {
    period,
    title,
    providerName,
    description
  } = carePlan;
  const content = local.healthData.carePlan;
  const [showResults, setShowResults] = useState(false);
  const handleClick = useCallback(() => {
    setShowResults(!showResults);
  }, [showResults]);

  return (
    <li className="card-list-item">
      <div className='carePlan-text'>
        <DatePeriod value={period} />
        {title ? <p className='carePlan-title'>{content.treatmentPlan}: {title}</p> : null}
        {providerName ? <p className='provider-name'>{content.provider}: {providerName}</p> : null}
        {description ? (description.length > 90) ?
          <div>
            <div className={showResults? 'carePlan-description expand' : 'carePlan-description hide-text'}>
              <CarePlanRerenderHTML content={description} />

            </div>
            <div className='panel-header collapsable-list-view'>

              <small className='item-action'><a className={showResults ? 'collapse-icon' : 'expand-icon'} onClick={handleClick}>{showResults ? content.collapseAction : content.expandAction} <Icon className="task-section-open-close-indicator" symbol="arrowdown" /></a></small>
            </div>
          </div>  :
          <div>
            <CarePlanRerenderHTML content={description} />

          </div> : null}
      </div>
    </li>

  );
}

CarePlanListItem.propTypes = {
  carePlan: PropTypes.shape({
    title: PropTypes.string,
    period: PropTypes.oneOfType([PropTypes.object]),
    providerName: PropTypes.string,
    description: PropTypes.string
  })
};
