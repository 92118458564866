import compileReducers from 'services/compile-reducer';
import * as persistentDataReducer from 'app/request/persistent-data-reducer';
import {INVOICE} from './action-names';

export const initialState = {
  [INVOICE]: persistentDataReducer.initialState
};

const reducers = { ...persistentDataReducer.reducers(INVOICE) };

export default compileReducers(reducers, initialState);
