import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { useSelector,useDispatch } from 'react-redux';
import ProgramSuccessPrompt from 'programs/shared/ProgramSuccessPrompt';
import ShowCompleteScreen from 'programs/shared/ProgramCompletionScreen';
import * as selectors from 'programs/programs-selectors';
import {noImmunizationPathwayId} from 'app/config';
import { ProgramChartsContainer } from './ProgramChartsContainer';
import { ObservationType,Unit } from 'services/enums';
import { ProgramDetailsHeaderContainer } from './ProgramDetailsHeaderContainer';
import { getProfileId } from 'app/selectors';
import {  loadDashboardObservationEhrData, loadProgramObservation,loadAllHighFrequencyData } from 'health/action-creators';
import { getHealthObservationData } from 'programs/programs-selectors';

export default function ProgramDetails({ programEnrollmentId,details }) {
  const pathwayStartDate = details.startDateTime;
  const pathwayEndDate = details.endDateTime;
  const profileId = useSelector(getProfileId);
  const enrollment = useSelector(state => selectors.getProgramEnrollmentById(state, programEnrollmentId));
  const programDetails = useSelector(state => selectors.getProgramDetails(state, programEnrollmentId));
  const completionScreenData = (enrollment && enrollment.program && enrollment.program.completionScreenData) ? JSON.parse(enrollment.program.completionScreenData) : null;

  const routeParams = {
    profileId : profileId,
    programId : programDetails.programId,
    programEnrollmentId: programEnrollmentId
  };


  if (!enrollment.program) {
    return null;
  }

  let showCompletionScreen = ShowCompleteScreen(enrollment, programDetails, completionScreenData);

  const observationTypes = [...new Set(details?.tasks?.reduce((acc, task) => {
    if (task.observationType === ObservationType.Steps || task.observationType === ObservationType.Nutrition || task.observationType === ObservationType.Systolic || task.observationType === ObservationType.Diastolic || task.observationType === ObservationType.BloodGlucose) {
      acc.push(task.observationType);
    }
    return acc;
  }, []))];

  const hasChartObservation = [ObservationType.Steps, ObservationType.Nutrition,ObservationType.Systolic,ObservationType.Diastolic,ObservationType.BloodGlucose].some((c) => observationTypes.includes(c));
  const isBloodGlucosePresent = observationTypes && observationTypes.length >0 && observationTypes.includes(ObservationType.BloodGlucose);
  const dispatch = useDispatch();
  const ObservationTypeUnits = [
    {
      ObservationType: ObservationType.Steps,
      Unit: Unit.Count
    },
    {
      ObservationType: ObservationType.Nutrition,
      Unit: Unit.Calories
    },
    {
      ObservationType: ObservationType.Systolic,
      Unit: Unit.MillimetersOfMercury
    },
    {
      ObservationType: ObservationType.Diastolic,
      Unit: Unit.MillimetersOfMercury
    },
    {
      ObservationType: ObservationType.BloodGlucose,
      Unit: Unit.MilligramsPerDeciliter
    }
  ];

  let observations =  useSelector(state => getHealthObservationData(state,profileId,pathwayStartDate,observationTypes));
  const pathwayObservationLength = observations && Object.values(observations).reduce((count, value) => {
    if (Array.isArray(value) && value.length >= 1) {
      return count + 1;
    } else {
      return count;
    }

  }, 0);
  const pathwayObservationLengthArray = pathwayObservationLength >= 2 ? true : false;
  useEffect(() => {
    dispatch(loadDashboardObservationEhrData({profileId:profileId, observationType: ObservationTypeUnits[0].ObservationType,  observationTypeUnits: ObservationTypeUnits}));
    dispatch(loadProgramObservation({profileId:profileId, observationType: ObservationTypeUnits[0].ObservationType,  observationTypeUnits: ObservationTypeUnits}));
    isBloodGlucosePresent && dispatch(loadAllHighFrequencyData(profileId));
  }, [profileId,isBloodGlucosePresent]);
  return (
    <figure className={`program-visual ${enrollment.program.programId == noImmunizationPathwayId ?'hidden':''}`} >
      {showCompletionScreen
        ? <ProgramSuccessPrompt enrollmentId={programEnrollmentId} />
        : (
      // eslint-disable-next-line react/jsx-no-useless-fragment

          <React.Fragment>
            <ProgramDetailsHeaderContainer program={enrollment.program} programName={enrollment.program.name} details={details} styleName={hasChartObservation && observationTypes.length > 1 && pathwayObservationLengthArray  ? 'mt-5 program-info' : 'program-info'} />
            {hasChartObservation ?
              <ProgramChartsContainer observationTypes={observationTypes} program={enrollment.program} programName={enrollment.program.name} pathwayStartDate={pathwayStartDate} details={details} pathwayEndDate={pathwayEndDate} routeParams={routeParams} observations={observations} />
              : ''}
          </React.Fragment>
        )}
    </figure>
  );
}

ProgramDetails.propTypes = {
  programEnrollmentId: PropTypes.string.isRequired
};
