import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { routePaths } from 'services/routing';
import { loadOrganizationDetails, loadOrganizationAdmins } from 'organizations/organizations-actions';
import { getOrganizationDetails, getOrganizationAdminsType } from 'organizations/organizations-selectors';
import OrganizationMembersList from './OrganizationMembersList';
import OrganizationAdminsList from 'organizations/list/admins/OrganizationAdminsList';
import { DataLoadingStatus, ProfileRedirect, Page } from 'shared';
import './details.scss';

export default function OrganizationDetailsPage({ match: { params: { organizationId } } }) {
  const membersType = useSelector(state => getOrganizationDetails(organizationId, state));
  const adminsType = useSelector(state => getOrganizationAdminsType(state, organizationId));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadOrganizationDetails(organizationId));
    dispatch(loadOrganizationAdmins(organizationId));
  }, [organizationId]);

  if (membersType.loaded && (!membersType.data || membersType.data.length === 0)) {
    return <ProfileRedirect to={routePaths.people} />;
  }
  const status = {
    loaded: membersType.loaded && adminsType.loaded,
    loading: membersType.loading && adminsType.loading,
    error: membersType.hasError || adminsType.hasError
  };

  return (
    <Page
      id='organization-details'
      centerElement={membersType.organizationName}
      backPath={{ to: routePaths.people }}>
      <DataLoadingStatus
        loaded={status.loaded}
        loading={status.loading}
        error={status.error}>
        <OrganizationMembersList members={membersType.data} />
        <OrganizationAdminsList admins={adminsType.data} />
      </DataLoadingStatus>
    </Page>
  );
}

OrganizationDetailsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      organizationId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
