import React, {useCallback, useEffect, useState } from 'react';
import { Icon,Button } from 'shared';
import { routePaths, buildPath } from 'services/routing';
import { useSelector, useDispatch } from 'react-redux';
import { defaultDashboardCirclePreferences } from 'app/config';
import { DashboardCircleIcons } from '../../dashboard/DashboardCircleIcons';
import { getUniqueId } from 'services/helpers';
import { getProfilePreferences, createOrUpdateProfilePreferences } from 'profiles/action-creators';
import { getProfileBlueRingPreferences } from 'app/selectors';
import { getProfile, getSortedProfiles } from 'profiles/list/selectors';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { trackCustomization } from 'services/appinsights-service';
import { calculateAge } from 'services/date-service';
import './dashboardPreferences.scss';
import { useHistory } from 'react-router-dom';

export function DashboardFavSelector() {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const profiles = useSelector(getSortedProfiles);

  const blueRingPreference = useSelector(getProfileBlueRingPreferences);
  const IdentityId = useSelector(state => state.identity.self.id);
  let circlePrefences = blueRingPreference == null || blueRingPreference == '' ? defaultDashboardCirclePreferences : blueRingPreference;
  const [circlePrefencesState, setCirclePrefencesState] = useState(circlePrefences?.split(','));

  useEffect(() => {
    if (profile != undefined) {
      dispatch(getProfilePreferences(profile.id, getUniqueId()));
      circlePrefences = blueRingPreference == null || blueRingPreference == '' ? defaultDashboardCirclePreferences : blueRingPreference;
      setCirclePrefencesState(circlePrefences?.split(','));
    }
  }, [profile?.id, blueRingPreference]);

  useEffect(() => {
    if (profile != undefined) {
      circlePrefences = blueRingPreference == null || blueRingPreference == '' ? defaultDashboardCirclePreferences : blueRingPreference;

      var sortedSelectedShortcuts = (circlePrefences?.split(','))?.sort()?.join(',');
      trackCustomization({
        Action: 'Landed To Preference Page', SelectedShortcut: '', FavoriteShortcuts: circlePrefences,
        SortedShortcuts: sortedSelectedShortcuts, IdentityId: IdentityId, ProfileId: profile.id,
        Age: calculateAge(profile.dateOfBirth), Gender: profile.gender !== null ? profile.gender == 1 ? 'Male' : 'Female' : '', ProfileCount: profiles?.length
      });
    }
  }, []);

  const SortableItem = SortableElement(({ param }) => {
    var value = param.value;
    var index = param.index;
    var url=buildPath(routePaths.preferencesDashboardFav, { index });
    const history = useHistory();

    const editIcon= useCallback(() =>{
      history.push(url);
    }, []);

    return (
      <li>
        <div className="organize-fav">
          <span className="drag-icon"><Icon symbol="drag" /></span>
          <Icon className="customize-icon" key={`i_${DashboardCircleIcons[value].name}`} symbol={DashboardCircleIcons[value].icon} />
          <div className="customize-icon-title">{DashboardCircleIcons[value].text}</div>
          <Button className="small-btn btn btn-secondary" id="favicon" data-testid={`testid_${DashboardCircleIcons[value].text}`} key={`edit_${DashboardCircleIcons[value].name}`} onClick={editIcon} >
            Edit
          </Button>
        </div>
      </li>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul >
        {items != undefined && items.map((value, index) => {
          if (value != '' && value != null) {
            var obj = { value: value, index: index };
            return (
              <SortableItem key={`item-${value}`} index={index} param={obj} />
            );
          }
        })}
      </ul>
    );
  });

  function onSortEnd({ oldIndex, newIndex }) {
    var currentPreferences = circlePrefencesState;
    if (oldIndex !== newIndex) {
      var newPreferences = arrayMove(currentPreferences, oldIndex, newIndex);
      dispatch(createOrUpdateProfilePreferences(profile.id, newPreferences.join(',')));
      setCirclePrefencesState(() => newPreferences);
      dispatch(getProfilePreferences(profile.id, getUniqueId()));

      var sortedSelectedShortcuts = (circlePrefences?.split(','))?.sort()?.join(',');

      trackCustomization({
        Action: 'Shortcut Position Changed', SelectedShortcut: '', FavoriteShortcuts: circlePrefences,
        SortedShortcuts: sortedSelectedShortcuts, IdentityId: IdentityId, ProfileId: profile.id,
        Age: calculateAge(profile.dateOfBirth), Gender: profile.gender !== null ? profile.gender == 1 ? 'Male' : 'Female' : '', ProfileCount: profiles?.length
      });

    }
  }

  return (
    <div className="customize-list" data-testid='customize-shortcuts'>
      <SortableList
        items={circlePrefencesState}
        onSortEnd={onSortEnd}
        helperClass="drag-preference"
        pressDelay={120} />
    </div>
  );
}
