import React, { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Icon } from 'shared';
import { NavLink } from 'react-router-dom';
import { routePaths } from 'services/routing';
import { getUnReadChatMessageCount } from 'chat/chat-selector';
import { loadUnReadChatMessageCount } from 'chat/action-creators';
import { getProfileId } from 'app/selectors';

const identityIdSelector = state => state.identity.self && state.identity.self.id;

export default function ChatLink() {
  const identityId = useSelector(identityIdSelector, shallowEqual);
  const dispatch = useDispatch();
  const unreadCount = useSelector(getUnReadChatMessageCount, shallowEqual);
  const profileId = useSelector(getProfileId);

  useEffect(() => {
    if (identityId) {
      dispatch(loadUnReadChatMessageCount(profileId));
    }
  }, [identityId]);

  return (
    <NavLink
      id="nav-link-chat"
      to={routePaths.chat}
      className="nav-chat"
      activeClassName="active" exact>
      <Icon symbol="chatMob" />

      {unreadCount > 0 ? <figure>{unreadCount}</figure> : null}
    </NavLink>
  );
}
