import {INVOICE} from './action-names';
import * as persistentDataActions from 'app/request/persistent-data-actions';
import * as Health_Care_Profile_api from 'services/api/healthCareProfile-api';

export function loadInvoice(profileId) {

  return (dispatch, getState) => {
    const identityId = getState().identity.self.id;
    const action = state => Health_Care_Profile_api.getInvoices(state, profileId);
    return dispatch(persistentDataActions.request(action, INVOICE, identityId));
  };
}
