exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "figure.program-visual section.program-info{padding:20px;padding-bottom:0px}figure.program-visual section.program-info .program-time{color:#828282;margin-left:10px}section.program-progress{line-height:.8em;padding:15px 30px 15px 15px;border-bottom:1px solid #E0E0E0}section.program-progress .progress-bar{margin:15px 0 10px 0}section.program-progress .program-title{font-size:18px;font-family:'robotomedium';color:#242424}section.program-progress .program-compliance{font-size:16px;font-family:'robotomedium';line-height:21px}section.program-progress .program-action-details{display:flex;flex-wrap:wrap;align-content:center;align-items:center}section.program-progress .program-action-details .call-to-action-wrapper{flex-grow:1;margin-right:10px;margin-bottom:10px}section.program-progress .program-action-details a.program-call-to-action{display:inline-block;padding:5px 20px}section.program-progress .program-action-details .program-call-to-action{font-size:small;border-bottom:none;display:flex;align-items:center}section.program-progress .program-action-details .program-call-to-action svg{margin-right:10px;flex-shrink:0;flex-grow:0;color:#54C2B6}section.program-progress .program-action-details .program-call-to-action span{margin:0px}section.program-progress .program-action-details .program-call-to-action.complete{font-size:small;border-bottom:none}\n", ""]);

// Exports
exports.locals = {
	"colorTeal": "#54C2B6",
	"colorViolet": "#727CF5",
	"colorTealRedChannel": "84",
	"colorTealGreenChannel": "194",
	"colorTealBlueChannel": "182",
	"colorTealDark": "#26A995",
	"colorTeal50": "rgba(84,194,182,0.5)",
	"colorTealScreened": "#54c2b6",
	"colorWhiteOff": "#F2F2F2",
	"colorWhite": "#fff",
	"colorWhiteTrans": "rgba(255,255,255,0)",
	"colorWhite50": "rgba(255,255,255,0.5)",
	"colorWarning": "#EA3030",
	"colorWarningLight": "rgba(234,48,48,0.15)",
	"colorBlack": "#333",
	"colorGrayDark": "#4F4F4F",
	"colorGray": "#828282",
	"colorGrayLight": "#E0E0E0",
	"colorGrayLight60": "rgba(224,224,224,0.6)",
	"colorBlue": "#049FDA",
	"colorBlackDark": "#242424",
	"ColorText": "#474747",
	"colorBlueScreened": "#049fda",
	"colorMagenta": "#EA3B7D",
	"colorMagentaScreened": "#ea3b7d",
	"colorYellow": "#FED504",
	"colorYellowDark": "#D8B129",
	"colorYellowScreened": "#fed504",
	"colorUi1": "rgba(4,159,218,0.3)",
	"colorUi2": "rgba(84,194,182,0.3)",
	"colorUi3": "rgba(234,59,125,0.3)",
	"colorUi4": "rgba(254,213,4,0.3)",
	"colorUi5": "rgba(31,79,228,0.3)",
	"colorUi6": "rgba(177,208,21,0.3)",
	"colorUi7": "rgba(228,34,255,0.3)",
	"colorUi8": "rgba(255,157,39,0.3)",
	"colorUi9": "rgba(66,32,208,0.3)",
	"colorUi10": "rgba(26,255,198,0.3)",
	"colorUi11": "rgba(205,38,26,0.3)",
	"colorUi12": "rgba(247,184,50,0.3)",
	"colorUi13": "rgba(146,79,207,0.3)",
	"colorUi14": "rgba(98,205,36,0.3)",
	"colorUi15": "rgba(255,24,9,0.3)",
	"colorUi16": "rgba(207,111,79,0.3)",
	"chartLightYellow": "rgba(252,215,34,0.7)",
	"chartLightPink": "rgba(250,92,124,0.7)",
	"chartLightBlue": "rgba(57,175,209,0.7)",
	"fontStyleNormal": "\"robotoregular\",sans-serif"
};