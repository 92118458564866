import moment from 'moment';
import { ObservationType } from 'services/enums';
import { getObservationData } from 'health/observations/observation-selectors';

const getSleep = (state, profileId) => {
  const allSleepData = getObservationData(state, ObservationType.Sleep)
    .filter(x => x.profileId === profileId);

  return allSleepData;
};

export const getSleepSummary = (state, props) => {
  const allSleepData = getSleep(state, props.profileId);
  const result = [];

  allSleepData && allSleepData.length > 0 && allSleepData.forEach(sleep=>
  {
    result.push({
      ...sleep,
      recordedAt: moment(sleep.recordedAt).utc().format(moment.HTML5_FMT.DATE),
      value: sleep.value
    });
  });
  return result;
};


export const getSleepBarChartData = (state, profileId, selectedEnd) => {
  const startDayMoment = moment(selectedEnd).add(-4, 'weeks');
  const endDayMoment = moment(selectedEnd);
  const filteredSleep = getSleep(state, profileId).filter(x => moment(x.recordedAt) >= startDayMoment);
  const sleepDataByDate = [];

  while (startDayMoment <= endDayMoment) {
    const date = startDayMoment.format(moment.HTML5_FMT.DATE);

    // group sleep data by date
    const sleepData = filteredSleep.reduce((result, sleep) => {
      if (moment(sleep.recordedAt).utc().format(moment.HTML5_FMT.DATE) === date) {
        // update count of sleep observations for date
        result.count++;

        // update total sleep count for date
        result.totalSleep += sleep.value;
      }
      return result;
    }, {
      date: moment(date).format('M/D'),
      totalSleep: 0,
      count: 0
    });

    sleepDataByDate.push(sleepData);

    startDayMoment.add(1, 'day');
  }
  return sleepDataByDate;
};
