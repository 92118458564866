import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { DataLoadingStatus, ProfileDataLoader, NavAction} from 'shared';
import ProfilePage from 'profiles/page/ProfilePage';
import { getProfileId } from 'app/selectors';
import { loadMedications } from 'health/medication/action-creators';
import { loadMedicationDoses,getMedicationNotificationData,updateMedicationNotification} from 'health/medication/dose/action-creators';
import { doseFilterReset, doseFilterResetMedicationId } from 'health/medication/dose/actions';
import { getFilteredMedicationName, getGroupedMedicationOverdueAndTakeNowDoses, getLoggedMedicationDoses } from 'health/medication/dose/selectors';
import DoseSection from './DoseSection';
import DoseDateSelector from './DoseDateSelector';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './doses.scss';

class DoseList extends React.Component {
  constructor(props) {
    super(props);
    this.handleDataSetup = this.handleDataSetup.bind(this);
    this.buildDoseSections = this.buildDoseSections.bind(this);
    this.handleData=this.handleData.bind(this);
  }
  componentDidMount()
  {
    this.handleDataSetup();
  }
  componentWillUnmount() {
    this.props.actions.doseFilterReset();
  }

  handleDataSetup() {
    // eslint-disable-next-line no-shadow
    const { profileId,filterDate, actions: { loadMedicationDoses, loadMedications, doseFilterResetMedicationId,getMedicationNotificationData} } = this.props;
    loadMedications(profileId);
    loadMedicationDoses({ profileId });
    doseFilterResetMedicationId();
    getMedicationNotificationData(profileId,filterDate);
  }
  async handleData(data,action)
  {
    const medicationNotification={
      scheduleAction:action,
      scheduledTime:moment(data.category,'hh:mm A').format(),
      scheduleLogs:data.values
    };
    const { actions, profileId } = this.props;
    await actions.updateMedicationNotification({ ...medicationNotification, profileId });
    this.componentDidMount();
  }

  buildDoseSections() {
    const { medicationNotificationDoses, groupedOverdueAndTakenowMedicationDoses, loggedMedicationDoses } = this.props;
    const showUpcomingMedication = moment(this.props.filterDate).isSame(moment(), 'day');
    if (medicationNotificationDoses && medicationNotificationDoses?.medicationNotifications?.length == 0
      && (medicationNotificationDoses.futureMedicationDoseDate === null)) {
      return (
        <div>
          <div className="no-medication-div">
            <img className="no-med-img" src="../../../static/images/no_med.svg" />

            <h5 className='my-3'>{local.noMedicationScheduled}</h5>

            <Link className="button blue btn btn-primary" to={routePaths.healthMedicationAdd}>Add Medicine</Link>
          </div>
        </div>

      );
    }
    else if (medicationNotificationDoses && medicationNotificationDoses?.medicationNotifications.length === 0 && medicationNotificationDoses?.futureMedicationDoseDate !== null) {
      return (
        <div>
          <div className="no-medication-div">
            <img className="no-med-img" src="../../../static/images/no_med.svg" />

            <h5 className='my-3'>{local.noMedicationScheduled}</h5>

            {
              showUpcomingMedication &&
              <ul className="no-data scrollview-list">
                <li className="scrollview-list-item">
                  <p className="message">Upcoming Medication</p>

                  <p className="upcoming-date">{moment(medicationNotificationDoses.futureMedicationDoseDate).format('hh.mm A [on] ddd MMM DD YYYY')}</p>
                </li>
              </ul>
            }
          </div>
        </div>
      );
    }

    if(medicationNotificationDoses && medicationNotificationDoses?.medicationNotifications?.length > 0) {
      return (
        <DoseSection
          UpdateDoseData={this.handleData}
          GetDoseData={this.handleDataSetup}
          doses={groupedOverdueAndTakenowMedicationDoses}
          loggedDoses={loggedMedicationDoses} />
      );
    }
  }

  render() {
    const { loading, error, filteredMedicationName,filterDate } = this.props;
    const calendarDate = moment(filterDate).calendar( null, { lastWeek: 'MMM DD', lastDay:  '[Yesterday]', sameDay:  '[Today]', nextDay:  '[Tomorrow]',  nextWeek: 'MMM DD',
      sameElse: function () {
        return 'MMM DD';
      }
    });

    return (

      <ProfilePage
        id='medication-doses'
        subHeader={
          <React.Fragment>
            <div className="med-tab-view">
              <ul className="active-list">
                <li className="calender-tab">{local.toggleCalendar}</li>

                <li className="list-tab"><Link to={routePaths.healthMedication}>{local.toggleList}</Link></li>
              </ul>
            </div>

            <DoseDateSelector />
          </React.Fragment>
        }
        centerElement={filteredMedicationName || local.listMedicationDosesHeader}
        backPath={{ to: routePaths.health }}
        primaryAction={<NavAction
          text={local.medicationDoses} iconSymbol='add'
          to={routePaths.healthMedicationAdd} />}>
        <ProfileDataLoader loadData={this.handleDataSetup} />

        <DataLoadingStatus loading={loading} error={error}>
          <div className="day-container" data-testid="doseList">
            <div className="current-date-label">{calendarDate}</div>

            {this.buildDoseSections()}
          </div>
        </DataLoadingStatus>
      </ProfilePage>

    );
  }
}


DoseList.propTypes = {
  // store
  profileId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  filteredMedicationName: PropTypes.string,
  filterDate : PropTypes.string.isRequired,
  medicationNotificationDoses: PropTypes.oneOfType([
    PropTypes.shape({
      futureMedicationDoseDate : PropTypes.string,
      medicationNotifications :  PropTypes.oneOfType([PropTypes.array])
    })
  ]),
  groupedOverdueAndTakenowMedicationDoses :  PropTypes.oneOfType([PropTypes.array]),
  loggedMedicationDoses :  PropTypes.oneOfType([PropTypes.array]),
  // actions
  actions: PropTypes.shape({
    loadMedications: PropTypes.func.isRequired,
    loadMedicationDoses: PropTypes.func.isRequired,
    doseFilterReset: PropTypes.func.isRequired,
    getMedicationNotificationData: PropTypes.func.isRequired,
    updateMedicationNotification:PropTypes.func.isRequired
  }).isRequired
};


function mapStateToProps(state, props) {
  const profileId = getProfileId(state, props);
  return {
    profileId,
    filteredMedicationName: getFilteredMedicationName(state),
    loading: state.medication.loading || state.medicationDose.loading,
    error: state.medication.loadError || state.medicationDose.loadError,
    filterDate : state.medicationDose.filter.date,
    medicationNotificationDoses : state.medicationDose.medicationNotificationDoses,
    groupedOverdueAndTakenowMedicationDoses: getGroupedMedicationOverdueAndTakeNowDoses(state),
    loggedMedicationDoses: getLoggedMedicationDoses(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { loadMedicationDoses, loadMedications, doseFilterReset, doseFilterResetMedicationId, getMedicationNotificationData,updateMedicationNotification };
  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

export default connect(mapStateToProps, mapDispatchToProps)(DoseList);
