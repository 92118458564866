import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button, LinkWrapper } from 'shared';
import local from 'services/localization/local';
import { TextField } from 'shared/form-fields';
import * as validators from 'services/fields/field-validators';
import * as normalizers from 'services/fields/field-normalizers';
import './blood-pressure.scss';

function BloodPressureGoalForm({ submitting, pristine, saving, handleSubmit, handleBack, average, isObservationGoal, dispatchBloodPressureData, dispatchBloodPressureFormErrorFlag }) {
  const isSubmitting = submitting || saving;
  const isSubmitDisabled = isSubmitting || pristine;
  const content = local.observation.bloodPressure;

  function disptachBloodPressureDatatoObservationForm() {
    if (isObservationGoal) {
      var input = getBloodPressureGoalFormFieldValues();
      dispatchBloodPressureData(input);
      var invalidFlag = validators.required(input.systolicMin) != undefined || validators.required(input.systolicMax) != undefined || validators.required(input.diastolicMin) != undefined || validators.required(input.diastolicMax) != undefined ||
        validators.minValue0(input.systolicMin) != undefined || validators.minValue0(input.systolicMax) != undefined || validators.minValue0(input.diastolicMin) != undefined || validators.minValue0(input.diastolicMax) != undefined ||
        validators.maxValue300(input.systolicMin) != undefined || validators.maxValue300(input.systolicMax) != undefined || validators.maxValue300(input.diastolicMin) != undefined || validators.maxValue300(input.diastolicMax) != undefined ||
        validators.validateSystolicRange(input.systolicMax, { systolicMin: input.systolicMin }) != undefined || validators.validateDiastolicRange(input.diastolicMax, { diastolicMin: input.diastolicMin }) != undefined;
      dispatchBloodPressureFormErrorFlag(invalidFlag);
    }
  }

  function getBloodPressureGoalFormFieldValues() {
    let systolicMin = 0;
    let systolicMax = 0;
    let diastolicMin = 0;
    let diastolicMax = 0;
    const systolicMinElement = document.getElementsByName('systolicMin');
    const systolicMaxElement = document.getElementsByName('systolicMax');
    const diastolicMinElement = document.getElementsByName('diastolicMin');
    const diastolicMaxElement = document.getElementsByName('diastolicMax');

    if (systolicMinElement.length > 0) {
      systolicMin = systolicMinElement[0].value;
    }
    if (systolicMaxElement.length > 0) {
      systolicMax = systolicMaxElement[0].value;
    }
    if (diastolicMinElement.length > 0) {
      diastolicMin = diastolicMinElement[0].value;
    }
    if (diastolicMaxElement.length > 0) {
      diastolicMax = diastolicMaxElement[0].value;
    }
    return { systolicMin, systolicMax, diastolicMin, diastolicMax };
  }

  return (
    <form id="bloodPressureGoalForm" onSubmit={handleSubmit}>
      <div className='d-flex align-items-center'>
        <label className="form-label add-on-label" htmlFor="fromValue-input">{content.goalPageSystolicLabel} <small> {content.goalPageTopNumberLabel}</small></label>
      </div>
      <div className="form-row space-label">
        <Field
          readOnly={isSubmitting}
          label="space_label"
          name="systolicMin"
          type="tel"
          component={TextField}
          normalize={normalizers.bloodPressure}
          onChange={disptachBloodPressureDatatoObservationForm}
          onBlur={disptachBloodPressureDatatoObservationForm}
          validate={[validators.required, validators.number, validators.minValue0, validators.maxValue300]} />
        <div className='range-to'>{content.goalPageToLabel}</div>
        <Field
          readOnly={isSubmitting}
          label="space_label"
          name="systolicMax"
          type="tel"
          component={TextField}
          normalize={normalizers.bloodPressure}
          onChange={disptachBloodPressureDatatoObservationForm}
          onBlur={disptachBloodPressureDatatoObservationForm}
          validate={[validators.required, validators.number, validators.minValue0, validators.maxValue300, validators.validateSystolicRange]} />
      </div>
      <div className='d-flex align-items-center'>
        <label className="form-label add-on-label" htmlFor="fromValue-input">{content.goalPageDiastolicLabel} <small>{content.goalPageBottomNumberLabel}</small></label>
      </div>
      <div className="form-row space-label">
        <Field
          readOnly={isSubmitting}
          label="space_label"
          name="diastolicMin"
          type="tel"
          component={TextField}
          normalize={normalizers.bloodPressure}
          onChange={disptachBloodPressureDatatoObservationForm}
          onBlur={disptachBloodPressureDatatoObservationForm}
          validate={[validators.required, validators.number, validators.minValue0, validators.maxValue300, validators.validateDiastolic]} />
        <div className='range-to'>{content.goalPageToLabel}</div>
        <Field
          readOnly={isSubmitting}
          label="space_label"
          name="diastolicMax"
          type="tel"
          component={TextField}
          normalize={normalizers.bloodPressure}
          onChange={disptachBloodPressureDatatoObservationForm}
          onBlur={disptachBloodPressureDatatoObservationForm}
          validate={[validators.required, validators.number, validators.minValue0, validators.maxValue300, validators.validateDiastolic, validators.validateDiastolicRange]} />
      </div>
      {
        !isObservationGoal &&
        <div className='targetLabel'>
          {content.averagePressure}<span className='bold'>{`${average} mmHg`}</span>{content.averagePeriod}
        </div>
      }
      {
        !isObservationGoal &&
        <div className="form-row slider-row">
          <Button id="bp-form-cancel" className="btn btn-secondary" onClick={handleBack}>{local.formCancel}</Button>
          <Button
            id="slider-value-submit"
            className="btn btn-primary"
            submit
            disabled={isSubmitDisabled}
            color="blue">
            {local.formSave}
          </Button>
        </div>
      }
    </form>
  );
}

BloodPressureGoalForm.displayName = 'Blood Pressure Range';

BloodPressureGoalForm.propTypes = {
  saving: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  handleBack: PropTypes.func,
  average: PropTypes.string

};

export default reduxForm({
  form: 'bloodPressureRange',
  enableReinitialize: true
})(BloodPressureGoalForm);
