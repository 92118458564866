import { actionName } from './data-types';
import compileReducers from 'services/compile-reducer';

export const initialState = { hideErrorPopup: false };

const reducers = {};

reducers[actionName.HIDE_ERROR_POPUP] = (state, payload) => {
  return {
    ...state.data,
    hideErrorPopup: payload
  };
};
export default compileReducers(reducers, initialState);
