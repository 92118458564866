import React from 'react';
import local from 'services/localization/local';
import { ObservationType, Unit } from 'services/enums';
import { routePaths } from 'services/routing';
import { configIds } from 'health/observations/observation-config-ids';
import { baseObservationConfig } from 'health/observations/observation-config-helpers.jsx';
import { formatFeetInches } from 'services/value-formatter';
import LogHeight from 'health/observations/height/log/LogHeight';
import { Height } from 'shared/fullColorIcons';
import { loadObservationEhrData } from 'health/action-creators';

const typeDefinition = {
  type: ObservationType.Height,
  typeUnitMap: {
    [ObservationType.Height]: Unit.Inches
  },
  unitLabelMap: {
    [Unit.Inches]: local.units.inches
  }
};

export default {
  ...baseObservationConfig,
  ...typeDefinition,
  id: configIds.height,
  backPath: { to: routePaths.health },
  centerElement: local.observation.height.title,
  logcenterElement: local.observation.height.logTitle,
  noDataText: local.observation.height.noDataText,
  iconSymbol: Height,
  loadData: (props) => [props.config.type].flat().forEach(x => props.dispatch(loadObservationEhrData({ observationType: x, profileId: props.profileId }))),
  valueFormatter: (props) => formatFeetInches(props.observation.value),
  renderLogComponent: (props) => props && <LogHeight {...props} />
};
