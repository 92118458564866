import React from 'react';
import { Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { getIdentity } from 'identity/selectors';
import { routePaths } from 'services/routing';

export default function VerifiedEmailRoute(props) {
  const { location } = props;
  const identity = useSelector(getIdentity);

  if (identity.unverifiedEmail) {
    return (
      <Redirect to={{ pathname: routePaths.verifyEmail, state: { from: location }}} />
    );
  }

  return (
    <Route {...props} />
  );
}
