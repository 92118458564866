import compileReducers from 'services/compile-reducer';
import * as persistentDataReducer from 'app/request/persistent-data-reducer';
import * as requestQueueReducer from 'app/request/request-queue-reducer';
import { actionNames } from './journal-actions';
import { types } from './journal-types';

export const initialState = {
  [types.JOURNALENTRY_REQUESTS]: requestQueueReducer.initialState,
  [types.JOURNALENTRIES]: persistentDataReducer.initialState
};

const reducers = {
  ...requestQueueReducer.reducers(types.JOURNALENTRY_REQUESTS),
  ...persistentDataReducer.reducers(types.JOURNALENTRIES)
};

reducers[actionNames.JOURNALENTRY_SAVE] = (state, payload) => {
  const { journalEntry } = payload;

  const currentData = state[types.JOURNALENTRIES][journalEntry.profileId] ? state[types.JOURNALENTRIES][journalEntry.profileId].data : [];

  return persistentDataReducer.updateDataStatus(state, types.JOURNALENTRIES, journalEntry.profileId, {
    data: [...currentData, journalEntry]
  });
};

reducers[actionNames.JOURNALENTRY_DELETE] = (state, payload) => {
  const { journalEntryId, profileId } = payload;

  const updatedJournalEntries = state[types.JOURNALENTRIES][profileId].data.map(x => {
    if (x.id === journalEntryId) {
      return { ...x, deleted: true };
    }
    return x;
  });

  return persistentDataReducer.updateDataStatus(state, types.JOURNALENTRIES, profileId, {
    data: updatedJournalEntries
  });
};

export default compileReducers(reducers, initialState);
