/* eslint-disable react/jsx-max-depth */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updatePreference, getIsAdminForAnyOrganization } from 'identity/action-creators';
import local from 'services/localization/local';
import { Page, Icon, Button } from 'shared';
import { DashboardFavSelector } from './dashboardPreferences/DashboardFavSelector';
import './preferences.scss';
import Switch from 'react-switch';
import PreferenceEmailChange from './changeEmail/PreferenceChangeEmail';
import PreferenceBilling from './billing/PreferenceBilling';
import DeleteAccountModal from './deleteAccount/DeleteAccountModal';
import PreferencePasswordChange from './changePassword/PreferencePasswordChange';

class Preferences extends Component {
  constructor(props) {
    super(props);
    this.handleServerPreferenceChange = this.handleServerPreferenceChange.bind(this);
    this.showDeleteAccountModal = this.showDeleteAccountModal.bind(this);
    this.closeDeleteAccountModal = this.closeDeleteAccountModal.bind(this);

    this.state = { checked: this.props.showFeaturePreviews, showDeleteAccountPopup: false };
    this.props.actions.getIsAdminForAnyOrganization();
  }

  handleServerPreferenceChange() {
    this.props.actions.updatePreference('showFeaturePreviews', !this.state.checked);
    this.setState(prevState => ({ checked: !prevState.checked }));
  }

  showDeleteAccountModal() {
    this.setState({ showDeleteAccountPopup: true });
  }

  closeDeleteAccountModal() {
    this.setState({ showDeleteAccountPopup: false });
  }

  render() {
    const { showDeleteAccountPopup } = this.state;
    if (!this.state.showDeleteAccountPopup) {
      return (
        <Page
          id="preferences-component"
          centerElement={local.preferencesTitle}>
          <div className="settingsPage">
            {/* account settings */}
            <PreferenceEmailChange />
            <PreferenceBilling />

            {/* personalize */}
            <div className="customize-section" >
              <small className="account-title">Personalize</small>
              <div data-testid='CustomizedDashboard' className="bg-white">
                <div className="personalize-title">
                  <Icon symbol='shortCutIcon' />
                  <div className="flex-1">
                    <small>
                      <strong>
                        {local.preferencesCustomizedDashboard}
                      </strong>
                      <br />
                      {local.preferencesCustomizedDashboardDescription}
                    </small>
                  </div>
                  <Icon symbol='chevronright' />
                </div>
                <DashboardFavSelector />
              </div>
            </div>
            <PreferencePasswordChange />

            {/* preferences */}
            <div className="customize-section" data-testid="account-title-preference">
              <small className="account-title">Preferences</small>
              <ul className=" bg-white">
                <li className="scrollview-list-item">
                  <Icon symbol="preferencesIcon" className="listIcon" />
                  <small className='flex-1'>
                    <strong>{local.preferencesShowFeaturePreviews}</strong>
                    <br />
                    <span>{local.preferencesShowFeaturePreviewsDescription}</span>
                  </small>
                  <Switch
                    className="preference-switch"
                    onChange={this.handleServerPreferenceChange}
                    checked={this.state.checked}
                    uncheckedIcon={<div className='unchecked-text'>No</div>}
                    checkedIcon={<div className='checked-text'>Yes</div>}
                    offColor="#E0E0E0"
                    onColor="#049FDA"
                    offHandleColor="#4F4F4F"
                    handleDiameter={20}
                    activeBoxShadow="none" />
                </li>
              </ul>
            </div>

            <div className=' bg-white form delete-account-wrapper'>
              <Button
                id="delete-account"
                data-testid="delete-account-btn"
                className="btn btn-secondary"
                onClick={this.showDeleteAccountModal}>
                {local.deleteAccount}
              </Button>
            </div>

          </div>
        </Page>
      );
    }
    else{
      return <DeleteAccountModal isOpen={showDeleteAccountPopup} onRequestClose={this.closeDeleteAccountModal} isAdmin={this.props.IsAdminForAnyOrganization} />;
    }
  }
}

Preferences.propTypes = {
  showFeaturePreviews: PropTypes.bool.isRequired,
  IsAdminForAnyOrganization: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    updatePreference: PropTypes.func.isRequired,
    getIsAdminForAnyOrganization: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  userId: state.profile.self.id,
  showFeaturePreviews: state.identity.self.showFeaturePreviews,
  IsAdminForAnyOrganization: state.identity.IsAdminForAnyOrganization
});

function mapDispatchToProps(dispatch) {
  const actions = {
    updatePreference,
    getIsAdminForAnyOrganization
  };
  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
