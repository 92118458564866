import React from 'react';
import PropTypes from 'prop-types';
import { addBackButtonListener, removeBackButtonListener, exitMobileApp } from 'mobile/helpers';
import withNavigation from 'app/withNavigation';

export class MobileBackButtonController extends React.Component {
  constructor(props) {
    super(props);
    this.attachBackButtonListener = this.attachBackButtonListener.bind(this);
    this.removeBackButtonListener = this.removeBackButtonListener.bind(this);
    this.handleMobileBackButton = this.handleMobileBackButton.bind(this);
  }

  componentDidMount() {
    this.attachBackButtonListener();
  }

  componentWillUnmount() {
    this.removeBackButtonListener();
  }

  attachBackButtonListener() {
    addBackButtonListener(this.handleMobileBackButton);
  }

  removeBackButtonListener() {
    removeBackButtonListener();
  }

  handleMobileBackButton() {
    const { onPress, to, navigate, exitApp } = this.props;
    if (exitApp) {
      exitMobileApp();
      return;
    }

    navigate({ to, navAction: onPress });
  }

  render() {
    return null;
  }
}

MobileBackButtonController.propTypes = {
  onPress: PropTypes.func,
  to: PropTypes.string,
  exitApp: PropTypes.bool,
  // navigation
  navigate: PropTypes.func.isRequired
};

export default withNavigation(MobileBackButtonController);
