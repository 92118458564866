import React from 'react';
import PropTypes from 'prop-types';
import configs from 'health/observations/observation-configs';
import { routePaths } from 'services/routing';
import { HealthNavLink } from 'shared';
import { convertObservationTypeToHealthDataType } from 'services/enums-helpers';

export default function ObservationNavLink(props) {
  const config = configs[props.configId];

  return (
    <HealthNavLink
      icon={config.iconSymbol}
      linkContent={config.centerElement}
      routePath={routePaths.healthObservation}
      linkId={config.id}
      isPremium={props.isPremium}
      healthDataType={convertObservationTypeToHealthDataType(config.type)}
      params={{ configId: config.id }}
      fhirResourceType={props.fhirResourceType}
      observationType={config.type} />
  );
}

ObservationNavLink.propTypes = {
  configId: PropTypes.string.isRequired
};
