import React from 'react';
import { useField } from 'formik';
import SubLabelSection from 'shared/form-fields/SubLabelSection';
import 'shared/form-fields/form-fields.scss';

export default function TextField({ label, subLabel, validate, multiline, ...props }) {
  const [field, meta] = useField({ validate, ...props });
  const hasError = !!(meta.touched && meta.error);
  const id = `${props.name}-input`;

  const inputProps = {
    className: hasError ? 'inputError form-control' : 'form-control',
    id,
    ...field,
    ...props,
    placeholder: label
  };

  const inputElement = multiline ?
    <textarea rows="4" cols="50" {...inputProps} /> :
    <input type="text" {...inputProps} />;

  return (
    <div className="formField">
      <label className='form-label' htmlFor={id}>{label}</label>
      {inputElement}
      <SubLabelSection showError={hasError} error={meta.error} subLabel={subLabel} />
    </div>
  );
}
