export const configIds = {
  bloodGlucose: 'blood-glucose',
  bloodOxygen: 'blood-oxygen',
  bloodPressure: 'blood-pressure',
  bodyArms: 'body-arms',
  bodyChest: 'body-chest',
  bodyFat: 'body-fat',
  bodyHips: 'body-hips',
  bodyThighs: 'body-thighs',
  bodyWaist: 'body-waist',
  heartRate: 'heart-rate',
  height: 'height',
  insulin: 'insulin',
  immunization: 'immunization',
  labResult: 'labResult',
  nutritions : 'nutritions',
  peakFlow: 'peak-flow',
  sleep: 'sleep',
  steps: 'steps',
  temperature: 'temperature',
  weight: 'weight',
  floorsClimbed :'floors-climbed',
  bloodKetone: 'blood-ketone'
};
