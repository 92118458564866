import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withContext from 'app/withContext';
import local from 'services/localization/local';
import styleVars from 'styles/_variables.scss';
import { routePaths } from 'services/routing';
import { Icon, ProfileDataLoader } from 'shared';
import LilChart from 'health/lil-charts/LilChart';
import { loadActivities } from 'activity/activity-actions';
import { getActivitiesForChart, getWeeklyActivityValue, isActivitiesLoading, isActivitiesLoadError } from 'activity/activity-selectors';

class ActivityLilChart extends Component {
  constructor(props) {
    super(props);
    this.loadCurrentProfileActivities = this.loadCurrentProfileActivities.bind(this);
  }

  loadCurrentProfileActivities() {
    const { actions, profileId } = this.props;
    const dateRangeStart = moment().startOf('day').add(-6, 'days').toISOString();
    const dateRangeEnd = moment().endOf('day').toISOString();
    actions.loadActivities({ profileId, dateRangeStart, dateRangeEnd });
  }

  render() {
    const { activities, weeklyActivity, weeklyActivityLabel, loading, loadError } = this.props;

    return (
      <ProfileDataLoader loadData={this.loadCurrentProfileActivities}>
        <LilChart
          metricIcon={<Icon symbol="activity" className="hero" size="3em" />}
          color={styleVars.colorTeal}
          data={activities}
          noDataText={local.noActivities}
          title={local.tileActivityTitle}
          metricValue={weeklyActivity}
          metricUnits={weeklyActivityLabel}
          path={{ to: routePaths.healthActivity }}
          noDataPath={{ to: routePaths.healthActivityAdd }}
          loading={loading}
          loadError={loadError} />
      </ProfileDataLoader>
    );
  }
}
ActivityLilChart.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  weeklyActivity: PropTypes.number,
  weeklyActivityLabel: PropTypes.string,
  loading: PropTypes.bool,
  loadError: PropTypes.bool,
  profileId: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    loadActivities: PropTypes.func.isRequired
  }).isRequired
};

function mapStateToProps(state, props) {
  const activities = getActivitiesForChart(state, props.profileId);
  const weeklySummary = getWeeklyActivityValue(activities);

  return {
    activities,
    weeklyActivity: weeklySummary.value,
    weeklyActivityLabel: weeklySummary.label,
    loading: isActivitiesLoading(state, props.profileId),
    error: isActivitiesLoadError(state, props.profileId)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { loadActivities };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default withContext(connect(mapStateToProps, mapDispatchToProps)(ActivityLilChart));
