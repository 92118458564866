import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Loading, CollapsableListView } from 'shared';
import { getGroupedTasks, getFutureTaskSummary, getIsProcessingTasks, getProgramEnrollmentById } from 'programs/programs-selectors';
import ProgramTask from './ProgramTask';
import local from 'services/localization/local';
import './program-tasks.scss';
import {noImmunizationPathwayId} from 'app/config';
import ProgramProgressSection from './ProgramProgressSection';

function ProgramTaskList({ programId, programEnrollmentId, profileId, enabled, readyToComplete }) {
  const tasks = useSelector(state => getGroupedTasks(state, programEnrollmentId));
  const futureTaskSummary = getFutureTaskSummary(tasks.future);
  const isProcessingTasks = useSelector(state => getIsProcessingTasks(state, programEnrollmentId));
  const enrollment = useSelector(state => getProgramEnrollmentById(state, programEnrollmentId));

  if (isProcessingTasks) {
    return (
      <div id='program-tasks'>
        <Loading />
      </div>
    );
  }

  if (tasks.total == 0) {
    return null;
  }

  const currentLabel = readyToComplete ? local.programs.taskListTitleRemaining : local.programs.taskListTitleCurrent;

  return (
    <React.Fragment>
      {programId == noImmunizationPathwayId &&
        <div id='program-tasks'>
          <Loading />
        </div>}
      <div id='program-tasks' className={programId == noImmunizationPathwayId ?'hidden':''}>
        <CollapsableListView id='current-tasks' title={`${currentLabel} (${tasks.current.length})`} show={tasks.current.length > 0} defaultChecked>
          <ProgramProgressSection
            className={enrollment.complete ? 'completed' : ''}
            programEnrollmentId={programEnrollmentId} />

          {tasks.current.map(x => (
            <li className="scrollview-list-item" key={x.programEnrollmentTaskId}>
              <ProgramTask task={x} profileId={profileId} programId={programId} programEnrollmentId={programEnrollmentId} enabled={enabled} />
            </li>
          ))}
        </CollapsableListView>
        <CollapsableListView id='missed-tasks' title={`${local.programs.taskListTitleMissed} (${tasks.missed.length})`} show={tasks.missed.length > 0} >
          {tasks.missed.map(x => (
            <li className="scrollview-list-item" key={x.programEnrollmentTaskId}>
              <ProgramTask task={x} profileId={profileId} programId={programId} programEnrollmentId={programEnrollmentId} enabled={enabled} />
            </li>
          ))}
        </CollapsableListView>
        <CollapsableListView id='completed-tasks' title={`${local.programs.taskListTitleCompleted} (${tasks.completed.length})`} show={tasks.completed.length > 0} >
          {tasks.completed.map(x => (
            <li className="scrollview-list-item" key={x.programEnrollmentTaskId}>
              <ProgramTask task={x} profileId={profileId} programId={programId} programEnrollmentId={programEnrollmentId} enabled={enabled} />
            </li>
          ))}
        </CollapsableListView>
        <CollapsableListView id='future-tasks' title={`${local.programs.taskListTitleFuture} (${tasks.future.length})`} show={tasks.future.length > 0} >
          {futureTaskSummary.map(x => (
            <li className="scrollview-list-item" key={x.programEnrollmentTaskId}>
              <ProgramTask task={x} profileId={profileId} programId={programId} programEnrollmentId={programEnrollmentId} enabled={enabled} />
            </li>
          ))}
        </CollapsableListView>
      </div>
    </React.Fragment>
  );
}

ProgramTaskList.propTypes = {
  profileId: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
  programEnrollmentId: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  readyToComplete: PropTypes.bool
};

export default ProgramTaskList;
