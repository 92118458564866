import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { getProfileId } from 'app/selectors';
import { doseFilterUpdateDate } from 'health/medication/dose/actions';
import { dateHasDoseData } from 'health/medication/dose/selectors';
import { WeeklyDatePicker } from 'shared';
import { getMedicationNotificationData} from 'health/medication/dose/action-creators';

class DoseDateSelector extends React.Component {
  constructor(props) {
    super(props);
    this.handleDateSelected = this.handleDateSelected.bind(this);
    this.dateDataSelector = this.dateDataSelector.bind(this);
  }

  handleDateSelected(date) {
    this.props.actions.doseFilterUpdateDate(date);
    this.props.actions.getMedicationNotificationData(this.props.profileId,date);
  }

  dateDataSelector(state, date) {
    const { profileId } = this.props;
    return dateHasDoseData(state, profileId, date);
  }

  render() {
    const { date } = this.props;

    return (
      <WeeklyDatePicker value={date} onChange={this.handleDateSelected} dateDataSelector={this.dateDataSelector} />
    );
  }
}

DoseDateSelector.propTypes = {
  // store
  date: PropTypes.string,
  profileId: PropTypes.string.isRequired,
  // actions
  actions: PropTypes.shape({
    doseFilterUpdateDate: PropTypes.func.isRequired,
    getMedicationNotificationData: PropTypes.func.isRequired
  }).isRequired
};

function mapStateToProps(state, props) {
  return {
    profileId: getProfileId(state, props),
    date: state.medicationDose.filter.date
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { doseFilterUpdateDate, getMedicationNotificationData };
  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

export const DoseDateSelectorContainer = connect(mapStateToProps, mapDispatchToProps)(DoseDateSelector);
export default withRouter(DoseDateSelectorContainer);
