import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import './dropdown.scss';

function DropdownComponent({ id, activeItem, children }) {
  const [showMenu, setShowMenu] = useState(false);

  const closeMenu = () => {
    setShowMenu(false);
  };

  const openMenu = () => {
    setShowMenu(true);
  };

  const closeMenuRef = useRef(closeMenu);

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('click', closeMenuRef.current);
    } else {
      document.removeEventListener('click', closeMenuRef.current);
    }

    return () => {
      document.removeEventListener('click', closeMenuRef.current);
    };
  }, [showMenu]);

  const activeItemButton = children ?
    (
      <button type="button" className="dropdown-active-item" onClick={openMenu}>
        {activeItem}
      </button>
    ) :
    (
      <div className="dropdown-active-item no-children">
        {activeItem}
      </div>
    );

  const body = showMenu && children ? (
    <div>
      <div className="overlay" />
      <div className="dropdown-list-container">
        {children}
        <button type="button" className="button" onClick={closeMenu}>
          {local.close}
        </button>
      </div>
    </div>
  ) : null;
  const classNames = `dropdown-component ${showMenu ? 'open' : ''}`;

  return (
    <div id={id} className={classNames}>
      {activeItemButton}
      {body}
    </div>
  );
}

DropdownComponent.propTypes = {
  id: PropTypes.string,
  activeItem: PropTypes.node.isRequired,
  children: PropTypes.node
};

export default DropdownComponent;
