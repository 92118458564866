import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withContext from 'app/withContext';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { Icon, ProfileLink } from 'shared';
import { getCurrentProfileMedications } from 'health/medication/selectors';
import MedicationListItem from './MedicationListItem';
import './medications.scss';

class MedicationList extends React.Component {
  constructor(props) {
    super(props);
    this.getNoDataStateWithAdd = this.getNoDataStateWithAdd.bind(this);
  }

  getNoDataStateWithAdd() {
    return (
      <ul className="card-list">
        <li className="card-list-item no-medications">
          <ProfileLink id="add-first-medication-link" to={routePaths.healthMedicationAdd}>
            <span>
              <Icon symbol="add" size="3em" />

              <small>{local.noMedications}</small>
            </span>
          </ProfileLink>
        </li>
      </ul>
    );
  }

  render() {
    const { medications } = this.props;

    if (!medications || medications.length === 0) {
      return this.getNoDataStateWithAdd();
    }

    return (
      <ul className="card-list">
        {medications.map((x) => <MedicationListItem key={x.id || 0} medication={x} />)}
      </ul>
    );
  }
}

MedicationList.propTypes = {
  medications: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    medications: getCurrentProfileMedications(state, ownProps.profileId)
  };
}

export default withContext(connect(mapStateToProps)(MedicationList));
