import React from 'react';
import PropTypes from 'prop-types';
import { routePaths } from 'services/routing';
import ProgramEnrollButton from 'programs/enrollment/ProgramEnrollButton';
import ProgramListItem from './ProgramListItem';

class ProgramListItemAvailable extends React.Component {
  render() {
    const { programInfo } = this.props;
    const logName = `program-available-${programInfo.name.toLowerCase().replace(/\s/g, '-')}`;

    const params = {
      programId: programInfo.programId
    };

    return (
      <ProgramListItem
        id={`program-enroll-link-${programInfo.programId}`}
        logName={logName}
        to={routePaths.programsEnroll}
        params={params}
        programInfo={programInfo}
        action={(<ProgramEnrollButton programInfoId={programInfo.id} />)} />
    );
  }
}
ProgramListItemAvailable.propTypes = {
  programInfo: PropTypes.shape({
    id: PropTypes.string
  }).isRequired
};

export default ProgramListItemAvailable;
