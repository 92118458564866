import * as dataSelectors from 'app/request/persistent-data-selectors';
import { FhirResourceTypes, ObservationType, Unit } from 'services/enums';
import local from 'services/localization/local';
import { types } from './health-types';
import moment from 'moment';
import { compareDatesDescending } from 'services/date-service';

export const getVaccinationProof = (state, profileId) => {
  return dataSelectors.getType(state.health, types.VACCINATION_PROOF, profileId);
};

export const getTrackSteps = (state, profileId) => {
  const {data}  = dataSelectors.getType(state.health, types.TRACK_STEPS, profileId);
  const result={};
  if(data){
    result.latestData = data?.find(x=>x.isActive);
    result.data = data && data.map(( {value,startDate} ) =>  ({value,startDate}) );
  }
  return result;
};

export const getCurrentStepTarget = (state, profileId) => {
  const {data, loading}  = dataSelectors.getType(state.health, types.CURRENT_TARGET, profileId);
  const stepTarget = data;
  return {stepTarget,loading};
};

export const getDashBoardObservation = (state, profileId) => {
  const dashBoardData  = dataSelectors.getType(state.health, types.DASHBOARD_OBSERVATIONS, profileId);
  const dashBoardEhrData  = dataSelectors.getType(state.health, types.HEALTH_DATA_VITALS_OBSERVATIONS, profileId);
  const highFrequencyData  = dataSelectors.getType(state.health, types.HIGH_FREQUENCY_OBSERVATIONS, profileId);
  let observations =  dashBoardData?.data?.observations;

  let filteredData = [];
  dashBoardEhrData?.data?.results?.map((x) => {
    filteredData.push({
      observationType: x.observationType,
      value: x.value,
      unit: x.unit,
      recordedAt: x.recordedAt
    });
  });
  observations && observations.map((x) => {
    filteredData.push({
      observationType: x.observationType,
      value: x.value,
      unit: x.unit,
      recordedAt: x.recordedAt
    });
  });

  highFrequencyData?.data?.results?.map((x) => {
    filteredData.push({
      observationType: x.observationType,
      value: x.observationType == ObservationType.BloodGlucose && x.value && x.value > 0 ? x.value.toFixed(1):  x.value,
      unit: x.unit,
      recordedAt: x.recordedAt
    });
  });

  const result = filteredData?.filter(x=>x.observationType != ObservationType.Sleep);

  const sleepData = filteredData?.filter(x=>x.observationType == ObservationType.Sleep);
  const sleepDataDesc = [...sleepData].sort((a, b) => compareDatesDescending(a.recordedAt, b.recordedAt)).shift();
  const sleepResult = sleepData.reduce((result, x) => {
    if (x.observationType === ObservationType.Sleep) {
      result.value += x.value;
    }
    return result;
  },
  {
    observationType: ObservationType.Sleep,
    value:0,
    unit: Unit.Seconds,
    recordedAt:sleepDataDesc?.recordedAt
  });

  const groupByCategory = result?.reduce((group, observation) => {
    const { observationType } = observation;
    group[observationType] = group[observationType] ?? [];
    group[observationType].push(observation);
    return group;
  }, []);

  groupByCategory && groupByCategory?.map((x) => {
    const allData = x
      .sort((a, b) => compareDatesDescending(a.recordedAt, b.recordedAt));
    return allData;
  });

  let sortedArray = [];
  if(sleepResult && sleepResult.value !==0){
    sortedArray.push(sleepResult);
  }
  groupByCategory && groupByCategory?.map((x) => {
    if(x && x.length > 0) {
      sortedArray.push(x[0]);
    }
  });
  sortedArray && sortedArray.sort((a, b) => compareDatesDescending(a.recordedAt, b.recordedAt));

  if(sortedArray)
  {
    sortedArray = sortedArray.filter(x=> x.observationType !== ObservationType.Immunization && x.observationType !== ObservationType.LabResult);

  }

  return sortedArray;
};

export const getHealthScoreTrends = (state, profileId,startDate,endDate) => {
  const healthScoreTrendData  = dataSelectors.getType(state.health, types.HEALTH_SCORE_TRENDS, profileId);
  const chartData = healthScoreTrendData?.data;
  const healthScoreDataByDate = [];
  let resultStartDate = chartData && chartData.length > 0 && chartData[chartData.length-1] && chartData[chartData.length-1].healthScoreComputedAt ? moment(chartData[chartData.length-1].healthScoreComputedAt).startOf('day') : startDate;

  if(startDate < resultStartDate )
  {
    resultStartDate = startDate;
  }

  for ( var index=1; (resultStartDate <= endDate) ; index++ ) {
    const date = resultStartDate.format(moment.HTML5_FMT.DATE);
    let currentHealthScore = chartData && chartData.find(x=>moment(x.healthScoreComputedAt).format(moment.HTML5_FMT.DATE) === date) ;
    healthScoreDataByDate.push(
      {
        date: moment(date).format(`${local.simpleDateFormat}`),
        dailyHealthScore: currentHealthScore ? currentHealthScore.healthScore :0,
        count: index
      }
    );
    resultStartDate.add(1, 'day');
  }
  return healthScoreDataByDate;

};

export const getDashBoardBmi = (state, profileId) => {
  const dashBoardData  = dataSelectors.getType(state.health, types.DASHBOARD_OBSERVATIONS, profileId);
  if( dashBoardData && dashBoardData.data )
  {
    return dashBoardData.data.bmi;
  }
  return 0;
};


export const getDashBoardExercise = (state, profileId,fromHealthData) => {
  const dashBoardData  = dataSelectors.getType(state.health, types.DASHBOARD_OBSERVATIONS, profileId);
  if( dashBoardData && dashBoardData.data && dashBoardData.data.exercise )
  {
    if(!fromHealthData){
      return dashBoardData.data.exerciseCount;
    }else {
      return {
        name : dashBoardData.data.exercise.name,
        startDateTime : dashBoardData.data.exercise.startDateTime,
        endDateTime : dashBoardData.data.exercise.endDateTime
      };
    }

  }
  return 0;
};

export const getDashboardHealthRecords = (state, profileId) =>
  dataSelectors.getData(state.health, types.DASHBOARD_HEALTH_RECORDS, profileId);

export const getHealthDataPageRecords = (state, profileId) =>
  dataSelectors.getData(state.health, types.HEALTH_DATA_RECORDS, profileId);

export const getDashboardHealthData = (state, profileId) =>
  dataSelectors.getData(state.health, types.DASHBOARD_OBSERVATIONS, profileId);

export const getHealthRecords = (state, profileId, type) => {
  const { loaded, loading, hasError, data } = dataSelectors.getType(state.health, type, profileId);

  return { loaded, loading, hasError, data };
};

export const getLabAttachment = (state, labId, attachmentId) => {
  const data = dataSelectors.getData(state.health, types.LAB_ATTACHMENTS, labId);
  return data && data.attachments && data.attachments.length > 0 &&
    data.attachments.find(a => a.remoteId === attachmentId)?.data;
};

export const getUnreadMedicalRecordsCount = (state, profileId, fhirResourceType, observationType) => {
  const data = dataSelectors.getData(state.health, types.UNREAD_MEDICAL_RECORDS_COUNT, profileId);
  if(!data || data.length == 0)
    return null;
  if(observationType && fhirResourceType != FhirResourceTypes.DiagnosticReport){
    const observationTypeValue = Array.isArray(observationType) ? observationType[0] : observationType; // observationType will be an array for BloodPressure
    const observationData = data.find(d => d.fhirResourceType == FhirResourceTypes.Observation && d.observationType == observationTypeValue);
    return observationData && observationData.count;
  }
  else if(fhirResourceType){
    const fhirData = data.find(d => d.fhirResourceType == fhirResourceType);
    return fhirData && fhirData.count;
  }
  return null;
};
