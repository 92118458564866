import React from 'react';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import './non-authenticated-page.scss';

class NonAuthenticatedPage extends React.Component {
  componentDidMount() {
    document.title = `${this.props.titleTabText} - ${local.app}`;
  }

  componentWillUnmount() {
    document.title = local.app;
  }

  render() {
    const { id, children, topChevronColor, bottomChevronColor } = this.props;
    const topChevron = topChevronColor ? <figure className={'chevron chevron-top ' + topChevronColor} /> : null;
    const bottomChevron = bottomChevronColor ? <figure className={'chevron chevron-bottom ' + bottomChevronColor} /> : null;

    return (
      <div id="non-authenticated-page-component">
        <div id={id} className="non-auth-wrapper">
          {topChevron}
          {bottomChevron}
          <div className="non-auth-content">{children}</div>
        </div>
      </div>
    );
  }
}

NonAuthenticatedPage.displayName = 'Non Authenticated Page';
NonAuthenticatedPage.propTypes = {
  id: PropTypes.string.isRequired,
  titleTabText: PropTypes.string.isRequired,
  topChevronColor: PropTypes.string,
  bottomChevronColor: PropTypes.string,
  children: PropTypes.node
};
NonAuthenticatedPage.defaultProps = {
  children: null
};

export default NonAuthenticatedPage;
