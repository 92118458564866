import React from 'react';
import PropTypes from 'prop-types';
import styleVars from 'styles/_variables.scss';
import { Icon } from 'shared';
import DashboardTile from './DashboardTile';
import LilChartGraph from './LilChartGraph';

class LilChart extends React.Component {
  constructor(props) {
    super(props);

    this.buildBody = this.buildBody.bind(this);
    this.hasData = this.hasData.bind(this);
  }

  hasData() {
    const { data } = this.props;
    if (data && data.length > 0) {
      return true;
    }
    return false;
  }

  buildBody() {
    const { title, metricIcon, metricValue, metricUnits,
      color, noDataText, data, preview, minDataTick } = this.props;

    const valueStyles = { color: preview ? styleVars.colorGray : styleVars.colorBlack };
    const labelStyles = { color: color || styleVars.colorGray };

    if (preview) {
      return (
        <div className="lil-chart-preview">
          {metricIcon}
          <small style={labelStyles} className="chart-title">{title}</small>
          <span style={valueStyles} className="chart-metric-value">{metricValue} {metricUnits && <small className="chart-metric-units">{metricUnits}</small>}</span>
          <LilChartGraph color={color} data={data} />
        </div>
      );
    }

    if (!this.hasData()) {
      return (
        <div className="lil-chart-no-data">
          <Icon symbol="add" size="2em" />
          <small style={valueStyles} className="chart-metric-add-first">{noDataText}</small>
        </div>
      );
    }

    return (
      <div className="lil-chart-data-loaded">
        {metricIcon}
        <small style={labelStyles} className="chart-title">{title}</small>
        <span style={valueStyles} className="chart-metric-value">{metricValue} <small className="chart-metric-units">{metricUnits}</small></span>
        <LilChartGraph color={color} data={data} minDataTick={minDataTick} />
      </div>
    );
  }

  render() {
    const { title, path, noDataPath, loading, loadError } = this.props;
    const body = this.buildBody();

    const computedPath = {
      ...path,
      to: this.hasData() ? path.to : noDataPath.to
    };

    return (
      <DashboardTile
        id={`lil-chart-${title}`}
        path={computedPath}
        loading={loading}
        error={loadError}>
        {body}
      </DashboardTile>
    );
  }
}

LilChart.propTypes = {
  title: PropTypes.string.isRequired,
  preview: PropTypes.bool,
  path: PropTypes.shape().isRequired,
  noDataPath: PropTypes.shape(),
  noDataText: PropTypes.string,
  metricValue: PropTypes.number,
  metricUnits: PropTypes.string,
  metricIcon: PropTypes.shape().isRequired,
  color: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape).isRequired,
  minDataTick: PropTypes.number,
  loading: PropTypes.bool,
  loadError: PropTypes.bool
};

LilChart.defaultProps = {
  color: ''
};

export default LilChart;
