import compileReducers from 'services/compile-reducer';
import * as persistentDataReducer from 'app/request/persistent-data-reducer';
import * as requestQueueReducer from 'app/request/request-queue-reducer';
import { actionNames } from './notification-actions';
import { types } from './notification-types';

export const initialState = {
  [types.NOTIFICATIONS]: persistentDataReducer.initialState,
  [types.NOTIFICATION_REQUESTS]: requestQueueReducer.initialState
};

const reducers = {
  ...persistentDataReducer.reducers(types.NOTIFICATIONS),
  ...requestQueueReducer.reducers(types.NOTIFICATION_REQUESTS)
};

reducers[actionNames.NOTIFICATION_MARK_READ] = (state, payload) => {
  const { notificationId, identityId } = payload;

  const updateNotifications = dataStatus => ({
    data: dataStatus.data.map(x => {
      if (x.id === notificationId) {
        return { ...x, isRead: true };
      }
      return x;
    })
  });

  return persistentDataReducer.updateDataStatus(state, types.NOTIFICATIONS, identityId, updateNotifications);
};

reducers[actionNames.NOTIFICATION_SET_DELETED] = (state, payload) => {
  const { notificationId, isDeleted, identityId } = payload;

  const updateNotifications = dataStatus => ({
    data: dataStatus.data.map(x => {
      if (x.id === notificationId) {
        return { ...x, deleted: isDeleted };
      }
      return x;
    })
  });

  return persistentDataReducer.updateDataStatus(state, types.NOTIFICATIONS, identityId, updateNotifications);
};

export default compileReducers(reducers, initialState);
