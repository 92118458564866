/* eslint-disable react/prop-types */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProfileId } from 'app/selectors';
import { routePaths, buildPath } from 'services/routing';
import { Page, NavIcon, BackAction } from 'shared';
import local from 'services/localization/local';
import { Steps } from 'shared/fullColorIcons';
import { loadHealthSummary } from 'timeline/action-creators';
import { getHealthSummaryDetails } from 'timeline/timeline-selectors';
import { configIds } from 'health/observations/observation-config-ids';
import './StepTarget.scss';
import Slider from './slider';
import { saveStepTarget } from './stepTarget-actions';
import { loadCurrentStepTarget } from 'health/action-creators';
import { getCurrentStepTarget } from 'health/health-selectors';
import { ObservationType } from 'services/enums';
import withNavigation from 'app/withNavigation';
import { getRequest } from 'app/request/request-selectors';
import { types } from './stepTarget-types';
import { requested } from 'app/request/request-actions';
import { getCurrentEnrollmentTask } from 'programs/programs-selectors';
import { completeTask, removeCurrentEnrollmentTaskDetails } from 'programs/programs-actions';
import { useHistory, withRouter } from 'react-router-dom';
import * as constant from 'services/constants';

function StepTarget(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const { data } = useSelector(state => getHealthSummaryDetails(state));
  const stepAvgCountValue = (data && data.step) ? data.step.averageDailySteps : 0;
  const { stepTarget, loading } = useSelector(state => getCurrentStepTarget(state, profileId));
  const { success } = useSelector(state => getRequest(state, types.STEP_TARGET_REQUESTS));
  const enrollmentTaskInfo = useSelector(state => getCurrentEnrollmentTask(state));
  const [path, setPath] = useState({});


  const fromPathway = history.location?.state?.fromPathway;
  const routeParams = history.location?.state?.routeParams;

  useEffect(() => {
    dispatch(loadHealthSummary(profileId));
    dispatch(loadCurrentStepTarget(profileId, ObservationType.Steps));

    if (enrollmentTaskInfo && enrollmentTaskInfo.programEnrollmentTaskId) {
      var programId = enrollmentTaskInfo.programId;
      var programEnrollmentId = enrollmentTaskInfo.programEnrollmentId;
      setPath({ to: routePaths.programsDetails, params: { profileId, programId, programEnrollmentId } });
    }
    else if (fromPathway && routeParams && routeParams.programEnrollmentId) {
      setPath({ to: routePaths.programsDetails, params: { profileId: routeParams.profileId, programId: routeParams.programId, programEnrollmentId: routeParams.programEnrollmentId }, state: { goalObservationType: ObservationType.Steps } });
    }
    else {
      setPath({ to: buildPath(routePaths.healthObservation, { configId: configIds.steps }), params: { profileId }, state: { goalObservationType: ObservationType.Steps } });
    }
    return () => {
      dispatch(requested(types.STEP_TARGET_REQUESTS));
      dispatch(removeCurrentEnrollmentTaskDetails());
    };

  }, [enrollmentTaskInfo, routeParams, fromPathway]);

  function onStepsChange(newValue) {
    const startDateTime = constant.stepsStartDateTime;
    dispatch(saveStepTarget({ profileId, newValue: newValue, startDateTime }));
    if (enrollmentTaskInfo && enrollmentTaskInfo.programEnrollmentTaskId && !fromPathway) {
      dispatch(completeTask(enrollmentTaskInfo.programEnrollmentTaskId, enrollmentTaskInfo.programEnrollmentId, profileId, true));
    }
  }

  function handleBack() {
    props.navigate(path);
  }

  if (success) {
    handleBack();
  }
  return (
    <Page
      id='step-target-component'
      backAction={<BackAction handleBack={handleBack} />}
      centerElement={local.steptargetTitle} loading={loading}>
      <div className="summary-metricIcon">
        <NavIcon Icon={Steps}></NavIcon>
      </div>

      <div className="suggested_pathway" >
        <div className="stepGoal-Content">{local.stepTargetMessage}</div>
      </div>

      <Slider
        stepSize={constant.MIN_STEP_SIZE} min={constant.MIN_GOAL} max={constant.MAX_GOAL} ticks={constant.stepTicks} maxTicksLimit={constant.GOAL_MAX_TICKS} onSlide={onStepsChange}
        currentTarget={stepTarget ? stepTarget : constant.MIN_STEPS_GOAL_TARGET} handleBack={handleBack} stepAvgCountValue={stepAvgCountValue}>
      </Slider>
    </Page>
  );
}
export default withNavigation(withRouter(StepTarget));




