import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NotificationType } from 'services/enums';
import { LinkWrapper, ExternalLink } from 'shared';
import { markNotificationInteraction, deleteNotification } from 'notifications/notification-actions';
import NotificationDataRow from './NotificationDataRow';

class NotificationListItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleRowClick() {
    const { notification, actions } = this.props;
    actions.markNotificationInteraction(notification.id);
  }

  handleDelete() {
    const { notification, actions } = this.props;
    actions.deleteNotification(notification.id);
  }

  render() {
    const { notification } = this.props;
    let linkWrapper = null;

    if (notification.notificationType === NotificationType.ExternalUrl) {
      linkWrapper = (sectionItem) => (
        <ExternalLink
          href={notification.actionUrl}
          id={notification.id}
          logName='notification-external-url'
          openNewTab>
          {sectionItem}
        </ExternalLink>
      );
    }

    if (notification.notificationType === NotificationType.InternalUrl) {
      linkWrapper = (sectionItem) => (
        <LinkWrapper
          to={notification.actionUrl}
          id={notification.id}
          logName='notification-internal-url'>
          {sectionItem}
        </LinkWrapper>
      );
    }

    return (
      <li className="notification-list-item scrollview-list-item">
        <NotificationDataRow
          notification={notification}
          handleDelete={this.handleDelete}
          handleRowClick={this.handleRowClick}
          linkWrapper={linkWrapper} />
      </li>
    );
  }
}

NotificationListItem.propTypes = {
  notification: PropTypes.shape({
    notificationType: PropTypes.number,
    actionUrl: PropTypes.string,
    id: PropTypes.string
  }).isRequired,
  actions: PropTypes.shape({
    markNotificationInteraction: PropTypes.func.isRequired,
    deleteNotification: PropTypes.func.isRequired
  }).isRequired
};

function mapDispatchToProps(dispatch) {
  const actions = { markNotificationInteraction, deleteNotification };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(null, mapDispatchToProps)(NotificationListItem);
