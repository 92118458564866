import React from 'react';
export const HealthDataHelperActivityText = (duration,exerciseNameType) =>{
  const exerciseValue = duration;
  const chars = exerciseValue.split('').filter(Boolean);

  const exerciseName = <span className='exercise-name'> ({exerciseNameType}) </span>;
  const wrappedExerciseValue = chars.map((char) => {
    return /[h/m/s]/.test(char) ? (
      <span className="time-char-label">{char}</span>
    ) : (
      <span>{char}</span>
    );
  });
  wrappedExerciseValue.push(exerciseName);
  return wrappedExerciseValue;
};
