import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'shared';
import { TextField, DateTimeTextFieldWrapper } from 'shared/form-fields';
import * as validators from 'services/fields/field-validators';
import * as normalizers from 'services/fields/field-normalizers';
import local from 'services/localization/local';
import PathwayContextDetails from 'health/observations/shared/PathwayContextDetails';

export function validateDiastolic(value, {systolicValue}) {
  if (systolicValue && value
    && Number(value) >= Number(systolicValue)) {
    return local.observation.bloodPressure.diastolicValueHigherError;
  }
}

function LogBloodPressureForm({ submitting, pristine, saving, handleSubmit, handleBack, pathwayContext }) {
  const isSubmitting = submitting || saving;
  const isSubmitDisabled = isSubmitting || pristine;

  return (
    <form onSubmit={handleSubmit}>
      {pathwayContext && <PathwayContextDetails pathwayContext={pathwayContext} />}
      <div className="form-row">
        <DateTimeTextFieldWrapper
          readOnly={isSubmitting}
          label={local.formTimestampLabel}
          validate={[validators.required, validators.dateInPast, validators.pathwayContextDateValidator]}
          name="timestamp" />
      </div>
      <div className="form-row">
        <Field
          readOnly={isSubmitting}
          label={local.observation.bloodPressure.systolicFormLabel}
          subLabel={local.observation.bloodPressure.systolicFormSubLabel}
          autoFocus
          name="systolicValue"
          component={TextField}
          type="tel"
          normalize={normalizers.bloodPressure}
          validate={[validators.required, validators.number, validators.minValue0, validators.maxValue300]} />
        <Field
          readOnly={isSubmitting}
          label={local.observation.bloodPressure.diastolicFormLabel}
          subLabel={local.observation.bloodPressure.diastolicFormSubLabel}
          name="diastolicValue"
          component={TextField}
          type="tel"
          normalize={normalizers.bloodPressure}
          validate={[validators.required, validators.number, validators.minValue0, validators.maxValue300, validateDiastolic]} />
      </div>
      <div className="form-row">
        <Field
          readOnly={isSubmitting}
          label={local.formNotesLabel}
          name="notes"
          component={TextField}
          validate={[validators.maxLength250]}
          subLabel={local.formNotesPlaceholder} />
      </div>
      <br />
      <br />
      <div className="form-row">
        <Button id="blood-pressure-form-cancel" className="btn btn-secondary" onClick={handleBack}>{local.formCancel}</Button>
        <Button
          id="blood-pressure-form-submit"
          className="btn btn-primary"
          submit
          color="blue"
          disabled={isSubmitDisabled}>
          {local.formSave}
        </Button>
      </div>
    </form>
  );
}

LogBloodPressureForm.displayName = 'Log Blood Pressure Form';
LogBloodPressureForm.propTypes = {
  saving: PropTypes.bool,
  handleBack: PropTypes.func.isRequired,
  // redux form
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  pathwayContext: PropTypes.shape()
};

export default reduxForm({
  form: 'bloodPressure'
})(LogBloodPressureForm);
