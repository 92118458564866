import * as actions from './action-names';
import { ToastStyle } from 'services/enums';
import { getUniqueId } from 'services/helpers';
import local from 'services/localization/local';

export const DEFAULT_TOAST_TIMEOUT = 500;

export function addToast(id, style, text, timeout) {
  return {
    type: actions.TOASTER_ADD,
    payload: {
      id,
      style,
      text,
      timeout
    }
  };
}

export function updateToast(id, style, text, timeout) {
  return {
    type: actions.TOASTER_UPDATE,
    payload: {
      id,
      style,
      text,
      timeout
    }
  };
}

export function removeToast(id) {
  return {
    type: actions.TOASTER_REMOVE,
    payload: { id }
  };
}

// Toast Helpers
export function popToast(style, text) {
  return addToast(getUniqueId(), style, text, DEFAULT_TOAST_TIMEOUT);
}

export function popSuccessToast(text) {
  return popToast(ToastStyle.Success, text);
}

export function popErrorToast(text = local.error) {
  return popToast(ToastStyle.Error, text);
}

export function addProcessingToast(id, text = local.saving) {
  return addToast(id, ToastStyle.Processing, text);
}

export function completeToastWithSuccess(id, text = local.saved) {
  return updateToast(id, ToastStyle.Success, text, DEFAULT_TOAST_TIMEOUT);
}

export function completeToastWithError(id, text = local.error) {
  return updateToast(id, ToastStyle.Error, text, DEFAULT_TOAST_TIMEOUT);
}
