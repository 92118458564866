/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Button } from 'shared';
import { useRanger } from 'react-ranger';
import './StepTarget.scss';
import local from 'services/localization/local';
import { formatStepsValue } from 'services/helpers.js';
import { Track, Tick, TickLabel, Segment, Handle } from './sliderStyle';

export default function Slider(props) {
  const [values, setValues] = React.useState([props.currentTarget]);
  const isObservationGoal = (props.isObservationGoal != undefined)  ? props.isObservationGoal : false;
  const { getTrackProps, ticks, segments, handles } = useRanger({
    values,
    onChange: (newValue) => {
      setValues(newValue);
    },
    onDrag: setValues,
    min: props.min,
    max: props.max,
    ticks: props.ticks,
    stepSize: props.stepSize
  });
  useEffect(() => {
    setValues([props.currentTarget]);
  }, [props.currentTarget]);

  useEffect(()=>{
    if(isObservationGoal){
      props.onSlide(values[0]);
    }
  },[values]);

  return (
    <div className="react-slider">
      {!isObservationGoal && <span className="stepGoal-Content step-lable">{local.observation.steps.title}</span>}
      <div className='sliderBox-Wrapper'>
        <span className="sliderBox">{formatStepsValue(values)}</span>
      </div>
      <Track {...getTrackProps()}>
        {ticks.map(({ value, getTickProps }) => (
          <Tick {...getTickProps()}>
            <TickLabel>{formatStepsValue(value)}</TickLabel>
          </Tick>
        ))}
        {segments.map(({ getSegmentProps }, i) => (
          <Segment {...getSegmentProps()} index={i} />
        ))}
        {handles.map(({ active, getHandleProps }) => (
          <Button
            id='step-target-button'
            {...getHandleProps({
              style: {
                appearance: 'none',
                border: 'none',
                background: 'transparent',
                outline: 'none',
                position:  !isObservationGoal ? 'absolute':'none'
              }
            })}>
            <Handle active={active}></Handle>
          </Button>
        ))}
      </Track>
      {
        !isObservationGoal &&
        <div className="average-steps">
          <span className="summary-metric-name">{local.avgstepTarget}</span>
          <span className="summary-metric-value">{' ' + formatStepsValue(props.stepAvgCountValue) + ' '}</span>
          <span className="summary-metric-name">{local.stepsPerDay}</span>
        </div>
      }
      {
        !isObservationGoal &&
        <div className="form-row slider-row">
          <Button id="medication-form-cancel" className="btn btn-secondary" onClick={props.handleBack}>{local.formCancel}</Button>
          <Button
            id="slider-value-submit"
            className="btn btn-primary"
            onClick={() => props.onSlide(values[0])}
            color="blue">
            {local.formSave}
          </Button>
        </div>
      }

    </div>
  );
}
