import React from 'react';
import './chat.scss';
import local from 'services/localization/local';
import { Icon } from 'shared';

export default function ChatEmergencyTextPage(props) {
  const { onHandle911TextClick } = props;
  return (
    <div className="alert alert-danger alert-dismissible fade show" role="alert">
      <div>{local.chat911Text}</div>
      <button type="button" className="chat-close" data-dismiss="alert" aria-label="Close" onClick={onHandle911TextClick}>
        <Icon symbol="times" />
      </button>
    </div>
  );
}




