import React from 'react';
import { DatePeriod } from 'shared';
import PropTypes from 'prop-types';
import local from 'services/localization/local';

export default function ConditionListItem({ condition }) {
  const { abatement, name, onset, origin, severity } = condition;
  const content = local.healthData.conditions;

  return (
    <li className="card-list-item">
      <div className='status-row'>
        <DatePeriod value={{ start: onset, end: abatement }} />
        <small>{severity}</small>
      </div>
      <div className='condition-text'>
        <span className='condition-title'>{name}</span>
        {origin && (
          <span className='condition-description'>
            <small>{content.reportedBy}: {origin}</small>
          </span>
        )}
      </div>
    </li>
  );
}

ConditionListItem.propTypes = {
  condition: PropTypes.shape({
    name: PropTypes.string,
    onset: PropTypes.string,
    abatement: PropTypes.string,
    severity: PropTypes.string,
    origin: PropTypes.string
  })
};
