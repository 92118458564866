import  React from 'react';
import { useSelector } from 'react-redux';
import IncomingProfileInviteList from 'profiles/sharing/incoming-invites/IncomingProfileInviteList';
import { getIsEmailVerified } from 'identity/selectors';
import Auth0UserInfoRefresher from 'identity/Auth0UserInfoRefresher';
import PendingInviteMessage from 'profiles/PendingInviteMessage';
import { hasPendingInvites } from 'profiles/profiles-selectors';
import InvitesLoader from 'profiles/InvitesLoader';
import './dashboard-invites-section.scss';

export default function InvitesDashboardSection() {
  const emailVerified = useSelector(getIsEmailVerified);
  const anyInvites = useSelector(hasPendingInvites);

  return (
    <React.Fragment>
      <InvitesLoader />
      {!emailVerified && anyInvites && <Auth0UserInfoRefresher />}
      {!emailVerified && anyInvites && <PendingInviteMessage />}
      { emailVerified && anyInvites && <IncomingProfileInviteList />}
    </React.Fragment>
  );
}
