import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'dashboard/newDashboard.scss';
import { getProfileId, getProfileBlueRingPreferences,getHealthScore } from 'app/selectors';
import { LinkWrapper, Icon } from 'shared';
import { buildPath, routePaths } from 'services/routing';
import RingButton from './RingButton';
import { defaultDashboardCirclePreferences } from 'app/config';
import { DashboardCircleIcons, blueCircleClasses } from './DashboardCircleIcons';
import { loadHealthScore } from '../health/action-creators';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import AnimatedProgressProvider from './AnimatedProgressProvider';
import { easeQuadInOut } from 'd3-ease';
import local from 'services/localization/local';
import { loadMedications } from 'health/medication/action-creators';
import {loadConnectedDevices } from 'devices/devices-actions';


export default function NewDashboardCircle() {
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const blueRingPreference = useSelector(getProfileBlueRingPreferences);
  const circlePrefences = blueRingPreference == '' ? defaultDashboardCirclePreferences : blueRingPreference;
  let healthScore = useSelector(getHealthScore);

  useEffect(() => {
    dispatch(loadHealthScore(profileId));
    dispatch(loadMedications(profileId));
    dispatch(loadConnectedDevices(profileId));
  }, [profileId]);
  return (
    <div>
      <div className="ring-wrapper">
        <div className="inner-ring-wrapper">
          <div className="inner-blue-ring">
            <div className="small-blue-circle"></div>
            <LinkWrapper data-testid='health-index-ring' id="new-dashboard-circle" to={routePaths.healthIndex}>
              <div id="healthScore">
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={healthScore !== null ? healthScore : 0}
                  duration={1}
                  easingFunction={easeQuadInOut}
                  repeat={false}>
                  {(value) => {
                    const roundedValue = Math.round(value);
                    return (
                      <CircularProgressbarWithChildren
                        className="healthScore-progressSvg"
                        value={value}
                        // text={`Your HealthScore ${roundedValue}`}
                        maxValue={1000}
                        /* This is important to include, because if you're fully managing the
                    animation yourself, you'll want to disable the CSS animation. */
                        styles={buildStyles({
                          pathTransition: 'none',
                          pathColor: '#959494',
                          trailColor: '#e8ecee'
                        })}>
                        <div className="healthScore-title">{local.healthScoreLabel}</div>
                        <div className="healthScore-wrapper">
                          <div className="healthScore-count">{roundedValue === 0 ? <Icon className="magnifying-glass" symbol="infoHealthIndexIcon" /> : roundedValue}</div>
                        </div>
                      </CircularProgressbarWithChildren>
                    );
                  }}
                </AnimatedProgressProvider>
              </div>
            </LinkWrapper>
            {circlePrefences?.split(',').map((b, index) =>
              <RingButton key={`icon_${DashboardCircleIcons[b].name}`} url={DashboardCircleIcons[b].configId ? buildPath(DashboardCircleIcons[b].url, { profileId, configId: DashboardCircleIcons[b].configId }) : buildPath(DashboardCircleIcons[b].url, { profileId })} class={blueCircleClasses[index]} icon={DashboardCircleIcons[b].icon} />
            )}
          </div>
          <div className="teal-line">
            <RingButton url={buildPath(routePaths.healthSummary, { profileId })} class="teal-ring" icon="timeline" />

          </div>
          <div className="magenta-line">
            <RingButton url={buildPath(routePaths.myGoal, { profileId })} class="magenta-ring" icon="Goal" />
          </div>
          <div className="magenta-line"><div className="small-magenta-circle"></div></div>

          <div className="yellow-line"><div className="small-yellow-circle"></div></div>
        </div>

      </div>


    </div>
  );
}
