import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { routePaths } from 'services/routing';
import { getRegisterErrorMessage } from 'identity/selectors';
import { registerUser } from 'identity/action-creators';
import Register from './Register';

export class RegisterContainer extends React.Component {
  render() {
    const { isSignedIn, success, ...rest } = this.props;
    if (success) {
      return <Redirect to={routePaths.registerThankyou} />;
    }

    if (!isSignedIn) {
      return <Register {...rest} />;
    }

    return <Redirect to="/" />;
  }
}

RegisterContainer.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  registering: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isSignedIn: state.identity.isSignedIn,
  errorMessage: getRegisterErrorMessage(state),
  registering: state.identity.registering,
  success: state.identity.registerSuccess
});

const actions = { handleSubmit: registerUser };

export default connect(mapStateToProps, actions)(RegisterContainer);
