import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ArrowButton } from 'shared';

class PreviousWeekButton extends React.Component {
  constructor(props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
    this.getLastWeekValidDate = this.getLastWeekValidDate.bind(this);
  }

  onSelect() {
    const { onChange } = this.props;
    const lastWeekDate = this.getLastWeekValidDate();
    if (lastWeekDate)
      onChange(moment(lastWeekDate).format(moment.HTML5_FMT.DATE));
  }

  getLastWeekValidDate() {
    const { date, startDate } = this.props;
    const lastWeekDate = moment(date).add(-1, 'week');

    if(!startDate || lastWeekDate >= moment(startDate))
      return lastWeekDate;

    const _startDate = moment(startDate);

    const endOfWeek = moment(lastWeekDate).endOf('week');

    if(endOfWeek < _startDate)
      return false;

    while (lastWeekDate < _startDate) {
      lastWeekDate.add(1, 'day');
    }
    return lastWeekDate.format(moment.HTML5_FMT.DATE);
  }

  render() {
    return (
      <div id="go-previous-week-button" data-testid="go-previous-week-button" className="row-item week-option" onClick={this.onSelect}>
        <ArrowButton id="go-previous-week-arrow" className="go-previous" />
      </div>
    );
  }
}

PreviousWeekButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  startDate: PropTypes.string
};

export default PreviousWeekButton;
