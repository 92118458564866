import React from 'react';
import { Route, Switch } from 'react-router';
import { routePaths } from 'services/routing';
import ProgramsRedirect from 'programs/layout/ProgramsRedirect';
import HealthData from 'health/data/HealthData';
import HealthScoreDetails from 'health/health-score/HealthScoreDetails';
import BodyMeasurementsContainer from 'health/body-measurements/BodyMeasurementsContainer';
import HealthPreview from 'health/preview/HealthPreview';
import MedicationLayout from 'health/medication/layout/MedicationLayout';
import LogActivity from 'activity/log/LogActivity';
import ActivityOverview from 'activity/list/ActivityOverview';
import DevicesOverview from 'devices/overview/DevicesOverview';
import Page404 from 'app/page404/Page404';
import GenericObservationLogPage from 'health/observations/generics/GenericObservationLogPage';
import ObservationConfigRenderer from 'health/observations/ObservationConfigRenderer';
import ConditionsList from 'health/conditions/ConditionsList';
import EncountersList from 'health/encounters/EncountersList';
import ProceduresList from 'health/procedures/ProceduresList';
import ConfirmIdentityPage from 'health/connect/ConfirmIdentityPage';
import Timeline from 'timeline/Timeline';
import JournalEntryList from 'journal/list/JournalEntryList';
import CreateJournalEntryPage from 'journal/create/CreateJournalEntryPage';
import HealthSummary from 'timeline/HealthSummary';
import ImmunizationNavigation from 'health/immunizations/ImmunizationNavigation';
import LabResultNavigation from 'health/labs/LabResultNavigation';
import ConnectDevices from 'devices/overview/ConnectDevices';
import CarePlanList from 'health/care-plan/CarePlanList';
import NewHealthDataPage from 'dashboard/v1/NewHealthDataPage';
export default function HealthRoutes() {
  return (
    <Switch>
      <Route exact path={routePaths.healthLogObservation} component={GenericObservationLogPage} />

      <Route exact path={routePaths.healthBodyMeasurements} component={BodyMeasurementsContainer} />

      <Route exact path={routePaths.healthTimeline} component={Timeline} />

      <Route exact path={routePaths.healthSummary} component={HealthSummary} />

      <Route exact path={routePaths.healthJournal} component={JournalEntryList} />

      <Route exact path={routePaths.healthJournalAdd} component={CreateJournalEntryPage} />

      <Route exact path={routePaths.healthObservation} component={ObservationConfigRenderer} />

      <Route path={routePaths.healthMedication} component={MedicationLayout} />

      <Route path={routePaths.healthPrograms} component={ProgramsRedirect} />

      <Route exact path={routePaths.healthConditions} component={ConditionsList} />

      <Route exact path={routePaths.healthEncounters} component={EncountersList} />

      <Route exact path={routePaths.healthLabResults} component={LabResultNavigation} />

      <Route exact path={routePaths.healthProcedures} component={ProceduresList} />

      <Route exact path={routePaths.healthActivityAdd} component={LogActivity} />

      <Route exact path={routePaths.healthActivity} component={ActivityOverview} />

      <Route exact path={routePaths.healthScoreDetails} component={HealthScoreDetails} />

      <Route exact path={routePaths.healthDevices} component={DevicesOverview} />

      <Route exact path={routePaths.health} component={NewHealthDataPage} />

      <Route exact path={routePaths.healthPreview} component={HealthPreview} />

      <Route exact path={routePaths.healthIdentity} component={ConfirmIdentityPage} />

      <Route exact path={routePaths.healthImmunizations} component={ImmunizationNavigation} />

      <Route exact path={routePaths.connectDevice} component={ConnectDevices} />

      <Route exact path={routePaths.healthCarePlan} component={CarePlanList} />

      <Route exact path={routePaths.healthAddItem} component={HealthData} />

      <Route path={routePaths.healthAddItem} component={Page404} />
    </Switch>
  );
}
