import LocalizedStrings from 'react-localization';
import english from './local.en';
import spanish from './local.es';

const local = new LocalizedStrings({
  en: english,
  es: spanish
});

export default local;
