import compileReducers from 'services/compile-reducer';
import * as persistentDataReducer from 'app/request/persistent-data-reducer';
import { types } from './features-types';

export const initialState = {
  [types.DISABLED_FEATURES]: persistentDataReducer.initialState
};

const reducers = { ...persistentDataReducer.reducers(types.DISABLED_FEATURES) };

export default compileReducers(reducers, initialState);
