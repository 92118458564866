/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React from 'react';
import { DatePeriod, Icon } from 'shared';
import local from 'services/localization/local';
import './invoice.scss';
import { Issued_Invoice } from 'services/constants';

export default function InvoiceList({invoice}){
  const { invoiceDate, invoiceAmount, provider, invoiceNumber, url, invoiceStatus } = invoice;
  const content = local.invoice;

  function OpenUrl(invoiceUrl){
    window.open(invoiceUrl, '_blank', 'noopener, noreferrer');
  }

  return(
    <li className="card-list-item">
      <div className='status-row'>
        <DatePeriod value={invoiceDate} />
        <div className='d-flex align-items-center pay-amount-detail'>
          {invoiceStatus === Issued_Invoice ? <small>{content.pay}<span className='pay-amount'> {content.dollar}{invoiceAmount}</span></small> : <small><span className='paid-pay-amount'> {content.dollar}{invoiceAmount}</span></small>}
          <a onClick={()=>OpenUrl(url)}><Icon symbol="arrowright" className="next preview-arrow" /></a>
        </div>
      </div>
      <div className='invoice-text'>
        <span className='item-title invoice-Number'>{invoiceNumber}</span>
        {provider && (<small>{provider}</small>)}
      </div>
    </li>
  );
}
