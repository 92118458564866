import local from 'services/localization/local';
import * as healthDataApi from 'services/api/healthdata-api';
import * as persistentDataActions from 'app/request/persistent-data-actions';
import * as requestQueueActions from 'app/request/request-queue-actions';
import * as toastActions from 'app/toaster/actions';
import { types } from './notification-types';

export const actionNames = {
  NOTIFICATION_MARK_READ: 'NOTIFICATION_MARK_READ',
  NOTIFICATION_SET_DELETED: 'NOTIFICATION_DELETED'
};

export const actions = {
  markNotificationRead: (identityId, notificationId) => ({
    type: actionNames.NOTIFICATION_MARK_READ,
    payload: {
      identityId,
      notificationId
    }
  }),
  updateNotificationDeleted: (identityId, notificationId, isDeleted) => ({
    type: actionNames.NOTIFICATION_SET_DELETED,
    payload: {
      identityId,
      isDeleted,
      notificationId
    }
  })
};

export function loadNotifications() {
  const action = state => healthDataApi.getNotifications(state)
    .then(result => result.results);

  return (dispatch, getState) => dispatch(persistentDataActions.request(action, types.NOTIFICATIONS, getState().identity.self.id));
}

export function markNotificationInteraction(notificationId) {
  return (dispatch, getState) => {
    dispatch(actions.markNotificationRead(getState().identity.self.id, notificationId));

    const action = state => healthDataApi.postNotificationInteraction(state, notificationId)
      .catch(error => {
        dispatch(toastActions.popErrorToast(local.notifications.markReadError));
        throw error;
      });

    dispatch(requestQueueActions.queueRequest(action, types.NOTIFICATION_REQUESTS));
  };
}

export function markAllNotificationsRead() {
  return (dispatch, getState) => {
    healthDataApi.patchAllNotificationsRead(getState());
  };
}

export function deleteNotification(notificationId) {
  return (dispatch, getState) => {
    const successAction = actions.updateNotificationDeleted(getState().identity.self.id, notificationId, true);

    dispatch(successAction);
    const action = state => healthDataApi.deleteNotification(state, notificationId)
      .then(() => dispatch(successAction), error => {
        dispatch(toastActions.popErrorToast(local.notifications.markDeletedError));
        dispatch(actions.updateNotificationDeleted(state.identity.self.id, notificationId, false));
        throw error;
      });

    dispatch(requestQueueActions.queueRequest(action, types.NOTIFICATION_REQUESTS));
  };
}

export function deleteAllNotification() {
  const action = state => healthDataApi.deleteAllNotification(state)
    .then(result => result.results);

  return (dispatch, getState) => dispatch(persistentDataActions.request(action, types.NOTIFICATIONS, getState().identity.self.id));
}
