/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import moment from 'moment';
import { momentCalendarDayFormat } from 'services/date-service';
import local from 'services/localization/local';
import { ObservationType, Unit } from 'services/enums';
import { routePaths } from 'services/routing';
import { configIds } from 'health/observations/observation-config-ids';
import { baseObservationConfig } from 'health/observations/observation-config-helpers.jsx';
import { GenericObservationDeviceLinkNoData } from 'health/observations/generics';
import { Nutrition } from 'shared/fullColorIcons';
import { getNutritionSummary } from 'health/observations/nutrition/nutrition-selector';
import NutritionPage from './NutritionPage';

const typeDefinition = {
  type: ObservationType.Nutrition,
  typeUnitMap: {
    [ObservationType.Nutrition]: Unit.Calories
  },
  unitLabelMap: {
    [Unit.Calories]: local.units.Calories
  }
};

export default {
  ...baseObservationConfig,
  ...typeDefinition,
  id: configIds.nutritions,
  backPath: { to: routePaths.health },
  centerElement: local.observation.nutritions.title,
  iconSymbol: Nutrition,
  noDataText: local.observation.nutritions.noDataText,
  disableActions:true,
  dataSelector:getNutritionSummary,
  timestampFormatter: (props) => moment(props.observation.recordedAt).calendar(null, momentCalendarDayFormat),
  renderNoDataComponent: (props) => props && <GenericObservationDeviceLinkNoData message={props.config.noDataText} />,
  renderChart: (props) => props.observations && <NutritionPage nutritions={props.observations} />,
  renderListComponent: () => null
};
