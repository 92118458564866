/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { Button, ModalWrapper } from 'shared';
import local from 'services/localization/local';
import SignoutDeleteAccountModal from './SignoutDeleteAccountModal';
import { deleteAccount } from 'identity/action-creators';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

function DeleteAccountModal(props) {
  const [deleteAccountConfirm, setDeleteAccountConfirm] = useState(false);
  const dispatch = useDispatch();
  const profiles = useSelector(state => state.profile.list);
  const identity = useSelector(state => state.identity);
  const profileId = useSelector(state => state.profile.self.id);

  const deleteUserAccount = () => {
    const userData = {
      email: identity.email,
      authZeroId: identity.self.authZeroId,
      identityId: identity.self.id,
      deleteRequestedAt: moment(),
      profileId: profileId,
      profileDeleteLogModel: profiles

    };
    setDeleteAccountConfirm(true);
    dispatch(deleteAccount(userData));
  };

  if (!deleteAccountConfirm) {
    return (
      props.isOpen &&
      <ModalWrapper isOpen={props.isOpen}>
        <div id="delete-account-modal" data-testid="delete-account" className="modal-container">
          <div className="modal-header">
            <div></div>
            <div className='modal-title'>
              {props.isAdmin ? local.formatString(local.isAdminDeleteAccountHeader) : local.formatString(local.deleteAccountTitle)}
            </div>
            <Button id="delete-close-button" onClick={props.onRequestClose}></Button>
          </div>
          <div className="modal-body">
            <div className="verify-successMsg">
              <div className="error-msg-title" >
                {props.isAdmin ? local.formatString(local.isAdminDeleteAccountMessage) : local.formatString(local.deleteAccountMessage)}
              </div>
            </div>
          </div>
          <div className='modal-footer'>
            <Button id="cancel-delete" className="btn btn-outline-dark small-btn" onClick={props.onRequestClose}>{local.formCancel}</Button>
            {!props.isAdmin ? <Button id="confirm-delete" className='btn btn-dark small-btn' onClick={deleteUserAccount} disabled={props.isAdmin}>{local.deleteAccount}</Button> : null}
          </div>
        </div>
      </ModalWrapper>
    );
  }
  return <SignoutDeleteAccountModal />;
}

DeleteAccountModal.propTypes = {
  onRequestClose: PropTypes.func,
  isOpen: PropTypes.bool,
  isAdmin: PropTypes.bool
};

export default DeleteAccountModal;
