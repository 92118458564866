import React from 'react';
import local from 'services/localization/local';
import { ObservationType, Unit } from 'services/enums';
import { routePaths } from 'services/routing';
import { configIds } from 'health/observations/observation-config-ids';
import { baseObservationConfig } from 'health/observations/observation-config-helpers.jsx';
import LogWeight from 'health/observations/weight/log/LogWeight';
import { Weight } from 'shared/fullColorIcons';
import { loadObservationEhrData } from 'health/action-creators';

const typeDefinition = {
  type: ObservationType.Weight,
  typeUnitMap: {
    [ObservationType.Weight]: Unit.Pounds
  },
  unitLabelMap: {
    [Unit.Pounds]: local.units.pounds
  }
};

export default {
  ...baseObservationConfig,
  ...typeDefinition,
  id: configIds.weight,
  backPath: { to: routePaths.health },
  centerElement: local.observation.weight.title,
  logcenterElement: local.observation.weight.logTitle,
  noDataText: local.observation.weight.noDataText,
  iconSymbol: Weight,
  loadData: (props) => [props.config.type].flat().forEach(x => props.dispatch(loadObservationEhrData({ observationType: x, profileId: props.profileId }))),
  renderLogComponent: (props) => props && <LogWeight {...props} />
};
