import local from 'services/localization/local';
import { isDateInRange } from 'services/date-service';
import moment from 'moment';

const getMinValue = min => value => {
  if ((value || value === 0) && value < min) {
    return `${local.validationMinValue} ${min}`;
  }
  return undefined;
};

export const minValue0 = getMinValue(0);
export const minValue1 = getMinValue(1);
export const minValue1800 = getMinValue(1800);

const getMaxValue = max => value => {
  if ((value || value === 0) && value > max) {
    return `${local.validationMaxValue} ${max}`;
  }
  return undefined;
};
export const maxValue5 = getMaxValue(5);
export const maxValue10 = getMaxValue(10);
export const maxValue12 = getMaxValue(12);
export const maxValue15 = getMaxValue(15);
export const maxValue31 = getMaxValue(31);
export const maxValue100 = getMaxValue(100);
export const maxValue120 = getMaxValue(120);
export const maxValue300 = getMaxValue(300);
export const maxValue500 = getMaxValue(500);
export const maxValue1000 = getMaxValue(1000);
export const maxValue10000 = getMaxValue(10000);

const getMinLength = min => value => {
  if (value) {
    if (value.length < min) {
      return `${min} ${local.validationMinLength}`;
    }
  }
  return undefined;
};

export const minLength4 = getMinLength(4);
export const minLength5 = getMinLength(5);
export const minLength8 = getMinLength(8);

const getMaxLength = max => value => {
  if (value) {
    if (value.length > max) {
      return `${max} ${local.validationMaxLength}`;
    }
  }
  return undefined;
};

export const maxLength5 = getMaxLength(5);
export const maxLength15 = getMaxLength(15);
export const maxLength50 = getMaxLength(50);
export const maxLength250 = getMaxLength(250);
export const maxLength500 = getMaxLength(500);

export const required = value => {
  if (value || value === 0) {
    return undefined;
  }
  return local.validationRequired;
};

export const number = value => {
  if (value && (typeof (value) === 'boolean' || isNaN(Number(value)))) {
    return local.validationNumber;
  }
  return undefined;
};

export const dateTimeLocal = value => {
  if (!value) {
    return value;
  }

  const date = moment(value, moment.HTML5_FMT.DATETIME_LOCAL, true);
  if (date.isValid()) {
    return undefined;
  }

  return local.validationDate;
};

export const dateInPast = value => {
  if (!value) {
    return value;
  }

  const date = moment(value);
  if (date.isValid() && date <= moment()) {
    return undefined;
  }

  return local.validationDateInPast;
};

export const birthdate = value => {
  if (!value) {
    return value;
  }

  const date = moment(value, moment.ISO_8601, true);
  if (date.isValid() && date >= moment('1800-01-01') && date <= moment()) {
    return undefined;
  }

  return local.validationBirthdate;
};

const isValidRegex = (value, regex) => {
  if (!value) {
    return value;
  }

  return value.match(regex);
};

const emailRegex = /.+@.+/;
export const email = value => {
  if (isValidRegex(value, emailRegex)) {
    return undefined;
  }
  return local.validationInvalidEmail;
};

export const timeLessThan1Day = value => {
  if (!value) {
    return value;
  }

  if (moment.duration(value).asDays() < 1) {
    return undefined;
  }

  return local.validationTimeLessThan1Day;
};

export const pathwayContextDateValidator = (value, _, { pathwayContext }) => {
  if (!value) {
    return value;
  }

  if (!isDateInRange(value, pathwayContext?.startDate, pathwayContext?.endDate)) {
    return local.validationDateInRange;
  }
};

export const maxFileValidation = (value, uploadLimit) => {
  if (value > uploadLimit)
    return local.maxFileValidation;

  return undefined;
};

export function validateRange(value, {fromValue}) {
  if (value && fromValue
    && Number(fromValue) >= Number(value)) {
    return local.validateRange;
  }
}


export function validateDiastolic(value, {systolicMax}) {
  if (systolicMax && value
    && Number(value) >= Number(systolicMax)) {
    return local.observation.bloodPressure.diastolicValueHigherError;
  }
}

export function validateSystolicRange(value, {systolicMin}) {
  if (value && systolicMin
    && Number(systolicMin) >= Number(value)) {
    return local.observation.bloodPressure.validateRange;
  }
}

export function validateDiastolicRange(value, {diastolicMin}) {
  if (value && diastolicMin
    && Number(diastolicMin) >= Number(value)) {
    return local.observation.bloodPressure.validateRange;
  }
}

