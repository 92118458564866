import compileReducers from 'services/compile-reducer';
import * as actions from './action-names';

export const initialState = {
  filteredTypes: []
};

const reducers = {};

reducers[actions.TIMELINE_FILTER_CLEAR] = (state) => ({
  ...state,
  filteredTypes: []
});

reducers[actions.TIMELINE_FILTER_UPDATE] = (state, payload) => ({
  ...state,
  filteredTypes: payload
});

export default compileReducers(reducers, initialState);
