import React from 'react';
import pathToRegexp from 'path-to-regexp';

export const routePaths = {
  root: '/',
  rootNewDashboard:'/dashboard/new',
  navigate: '/navigate/:routeId',
  feedbackHealthSync: '/feedback/health-sync',

  // Health Data
  health: '/health',
  healthActivityAdd: '/health/exercise/add',
  healthActivity: '/health/exercise',
  healthScoreDetails: '/health/health-score',
  healthBodyMeasurements: '/health/body-measurements',
  healthConditions: '/health/conditions',
  healthConnect: '/health/connect',
  healthConnectIntro: '/health/connect/intro',
  healthEncounters: '/health/encounters',
  healthIdentity: '/health/connect/identity',
  healthImmunizations: '/health/immunizations',
  healthJournalAdd: '/health/journal/add',
  healthLabResults: '/health/labs',
  healthLogObservation: '/health/observation/:configId/log',
  healthObservation: '/health/observation/:configId',
  healthObservationImmunizations: '/health/observation/immunization',
  healthObservationLabResults: '/health/observation/labResult',
  healthObservationEhrLabResults: '/health/observation/ehrLabResult',
  healthDevices: '/health/devices',
  healthPreview: '/health/placeholder/:metricName/:name',
  healthVitalsPreview: '/health/vitals/placeholder/:metricName/:name',
  healthMedicationEdit: '/health/medication/edit/:medicationId',
  healthMedicationAddDose: '/health/medication/dose/add/:medicationId',
  healthMedicationDose: '/health/medication/dose',
  healthMedicationAdd: '/health/medication/add',
  healthMedicationTracking: '/health/medication/dose',
  electronicHealthRecord: '/electronic-health-records',
  healthInfoProvider:'/health-info/provider',
  healthInfoInsurance:'/health-info/insurance',
  healthMedication: '/health/medication',
  healthProcedures: '/health/procedures',
  healthPrograms: '/health/program',
  healthTimeline: '/health/timeline',
  healthJournal: '/health/journal',
  healthBadge: '/health/public-health-badge',
  healthSummary: '/health/health-summary',
  healthCarePlan: '/health/carePlan',
  healthAddItem : '/health/addItem',

  healthBadgeSummary: '/badge/achievement-summary',
  // Programs
  healthProgramDetails: '/pathways/details/:programId/:programEnrollmentId', // To be deleted - Would cause a breaking change with notifications
  programs: '/pathways',
  programsEnroll: '/pathways/enroll/:programId',
  programsList: '/pathways/list',
  programsCompleted: '/pathways/completed',
  programsInvite: '/pathways/invite/:programId',
  programsPreview: '/pathways/placeholder/:metricName/:programId',
  programsDetails: '/pathways/details/:programId/:programEnrollmentId',
  programsViewData: '/pathways/view/:programId/:programEnrollmentId/:observationType',
  programsRecordData: '/pathways/record/:programId/:programEnrollmentId/:observationType/:taskId?',
  healthProgramRecordData: '/pathways/record/:programId/:programEnrollmentId/:observationType/:taskId?', // To be deleted - Would cause a breaking change with notifications
  programLeaderBoard: '/pathways/leaderBoard/:programId/:programEnrollmentId',

  dashboardPreview: '/placeholder/:metricName/:name',
  notifications: '/notifications',
  people: '/people',
  peopleAdd: '/people/add',
  peopleEdit: '/people/edit',
  preferences: '/settings',
  invoice: '/invoice',
  preferencesDashboardFav:'/preferences/dashboardFav/:index' ,
  organizations: '/organizations',
  organizationsCreate: '/organizations/create',
  organizationsJoinCode: '/organizations/join/:inviteCode?',
  organizationsInviteDetails: '/organizations/:organizationId/invites/:membershipId/:hasProperties?',
  organizationsDetails: '/organizations/:organizationId/details',
  organizationsJoinProgram: '/organizations/:organizationId/pathways/:programId',
  sharing: '/sharing',
  sharingIncoming: '/sharing/incoming',
  sharingAdmin: '/sharing/admin',
  signout: '/signout',
  signinCallback: '/signin-callback',
  signinCapacitor: '/signin-capacitor',
  stepTarget: '/goal/step-target',
  registerThankyou: '/register/thankyou',
  register: '/register',
  resetPassword: '/reset-password',
  verifyEmail: '/verify-email',
  page404: '/404',
  verifiedChangeEmail:'/verifycode-and-change-email',
  changeEmail:'/change-email',
  changePassword:'/change-password',
  viewPlan:'/view-plan',
  changeEmailSignout:'/change-email/signout',
  healthIndex:'/health-index',
  connectDevice: '/health/devices/connect',
  healthInfo: '/health-info',
  coach:'/product/coach',
  hipaaIntroductionPage:'/privacy-policy-eula/HipaaIntroductionPage',
  nutritionGoal: '/goal/nutrition',
  bloodGlucoseGoal: '/goal/bloodGlucose',
  appointment: '/appointment',
  appointmentDetails: '/appointment-details',
  bloodPressureGoal: '/goal/blood-pressure',
  observationConnect:'/observation-connect',
  chat: '/chat',
  myGoal:'/goals',
  createGoal:'/goals/add',
  observationGoal: '/observation-goal/:programId/:programEnrollmentId'
};

export const ProgramsContext = React.createContext({ base: routePaths.root });

export const programsContexts = {
  default: {
    base: routePaths.root
  },
  list: {
    base: routePaths.programsList
  },
  completed: {
    base: routePaths.programsCompleted
  },
  healthIndex:{
    base:routePaths.healthIndex
  },
  leaderBoard:{
    base:routePaths.programLeaderBoard
  }
};

export const buildPath = (path, params) => {
  let url = pathToRegexp.compile(path)(params);

  if (params && params.profileId) {
    url = `${url}?profileId=${params.profileId}`;
  }

  return url;
};

