import React from 'react';
import { useDispatch } from 'react-redux';
import { ModalWrapper, Button, Icon } from 'shared';
import { leaveProgram } from 'programs/programs-actions';
import local from 'services/localization/local';
import '../details/program-action-sheet.scss';

export default function ProgramCompleteModel(props) {
  const dispatch = useDispatch();
  const unenroll = () => {
    dispatch(leaveProgram(props.programEnrollmentId, props.profileId, props.requestId));
    props.reload();
  };
  const subscriptionPathwayHeader = 'Are you sure you want to permanently remove this pathway from your Active list? You will need to restart this pathway from the Browse/History Pathways screen if you wish to access it in the future.';
  const InviteePathwayHeader = 'This is a required pathway from your provider or employer. Please consult your provider or employer before deleting this pathway.';

  return (
    <ModalWrapper isOpen={props.isConfirmOpen} onRequestClose={props.closeConfirm}>
      <div id="quit-pathway-modal" className="modal-container">
        <div className="modal-header">
          <Button id="close-quit-pathway-button" onClick={props.closeConfirm} data-testid='close-quit-pathway-button'><Icon symbol="times" /></Button>
          <Icon symbol="healthprograms" />
          <p></p>
        </div>
        <div className="modal-body">
          <div className="content">
            <h4><Icon symbol="error" size="2em" /></h4>
            <h4>{local.programs.detailsUnenrollHeader}</h4>
            <p>{props.isSubscriptionPathway ? subscriptionPathwayHeader:InviteePathwayHeader}</p>
          </div>
        </div>
        <div className="modal-footer">
          <Button id='cancel-quit-pathway-button' data-testid='cancel-quit-pathway-button' onClick={props.closeConfirm}>{local.cancel}</Button>
          <Button id='confirm-quit-pathway-button' data-testid='confirm-quit-pathway-button' onClick={unenroll}>{local.programs.detailsUnenrollButton}</Button>
        </div>
      </div>
    </ModalWrapper>
  );
}
