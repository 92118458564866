import compileReducers from 'services/compile-reducer';
import * as actions from './action-names';
import * as requestQueueReducer from 'app/request/request-queue-reducer';
import { types } from './identity-types';
//import {AccountDeletionStatus} from 'services/enums';

export const initialState = {
  signInRequired: false,
  tokenType: null,
  accessToken:null,
  signingIn: false,
  isSignedIn: false,
  signInError: false,
  unverifiedEmail: false,
  errorMessage: null,
  loginErrorMessage: null,
  loading: false,
  loadError: false,
  self: undefined,
  registering: false,
  registerSuccess: false,
  registerError: false,
  activating: false,
  activateSuccess: false,
  activateError: false,
  resettingPassword: false,
  resetPasswordSuccess: false,
  resetPasswordError: false,
  verificationSentMessage: '',
  savedUserName:false,
  accountStatus: null,
  IsAdminForAnyOrganization: false,
  accountDeleted: false,
  ABTestingSegment: '',
  [types.RESEND_VERIFICATION]: requestQueueReducer.initialState
};

const reducers = { ...requestQueueReducer.reducers(types.RESEND_VERIFICATION) };

reducers[actions.IDENTITY_SIGNINREQUIRED] = (state) => ({
  ...state,
  signInRequired: true
});

reducers[actions.IDENTITY_SIGNINREQUESTED] = () => ({
  ...initialState,
  signingIn: true
});

reducers[actions.IDENTITY_SIGNINSUCCESS] = (state, token) => {
  const newState = {
    signInRequired: false,
    isSignedIn: true,
    signingIn: false,
    signInError: false,
    errorMessage: null,
    tokenType: token.tokenType,
    accessToken: token.accessToken
  };

  if (token.idTokenPayload) {
    newState.email = token.idTokenPayload.email;
    newState.unverifiedEmail = !token.idTokenPayload.email_verified;
  }

  return {
    ...state,
    ...newState
  };
};

reducers[actions.IDENTITY_SIGNINERROR] = (state, message) => ({
  ...state,
  isSignedIn: false,
  signInRequired: true,
  signingIn: false,
  signInError: true,
  errorMessage: message,
  unverifiedEmail: false
});

reducers[actions.IDENTITY_UNVERIFIEDEMAIL] = (state) => ({
  ...state,
  unverifiedEmail: true,
  isSignedIn: false,
  signingIn: false,
  signInError: true,
  errorMessage: null
});

reducers[actions.IDENTITY_VERIFIEDEMAIL] = (state) => ({
  ...state,
  unverifiedEmail: false
});

reducers[actions.IDENTITY_UNVERIFIEDEMAILRESET] = (state) => ({
  ...state,
  unverifiedEmail: false,
  signInError: false,
  errorMessage: null
});

reducers[actions.IDENTITY_REFRESH_USERINFO_SUCCESS] = (state, userInfo) => {
  return {
    ...state,
    email: userInfo.email,
    unverifiedEmail: !userInfo.email_verified
  };
};

reducers[actions.IDENTITY_REQUESTED] = state => ({
  ...state,
  loading: true,
  loadError: false
});

reducers[actions.IDENTITY_LOADED] = (state, payload) => ({
  ...state,
  self: payload,
  loading: false,
  loadError: false
});

reducers[actions.IDENTITY_LOADERROR] = (state, payload) => ({
  ...state,
  loading: false,
  loadError: true,
  loginErrorMessage: payload
});

reducers[actions.IDENTITY_REGISTERUSER] = (state) => ({
  ...state,
  saving: true,
  saveError: false,
  saveSuccess: false
});

reducers[actions.IDENTITY_REGISTERUSER] = (state) => ({
  ...state,
  registering: true,
  registerSuccess: false,
  registerError: false
});

reducers[actions.IDENTITY_REGISTERUSER_SUCCESS] = (state) => ({
  ...state,
  registering: false,
  registerSuccess: true,
  registerError: false
});

reducers[actions.IDENTITY_REGISTERUSER_ERROR] = (state, payload) => ({
  ...state,
  registering: false,
  registerSuccess: false,
  registerError: payload
});

reducers[actions.IDENTITY_RESET_PASSWORD] = (state) => ({
  ...state,
  resettingPassword: true,
  resetPasswordSuccess: false,
  resetPasswordError: false
});

reducers[actions.IDENTITY_RESET_PASSWORD_SUCCESS] = (state) => ({
  ...state,
  resettingPassword: false,
  resetPasswordSuccess: true,
  resetPasswordError: false
});

reducers[actions.IDENTITY_RESET_PASSWORD_ERROR] = (state) => ({
  ...state,
  resettingPassword: false,
  resetPasswordSuccess: false,
  resetPasswordError: true
});

reducers[actions.IDENTITY_RESET_PASSWORD_RESET] = (state) => ({
  ...state,
  resetPasswordSuccess: false,
  resetPasswordError: false
});

reducers[actions.IDENTITY_UPDATEPREFERENCE] = (state, payload) => ({
  ...state,
  self: {
    ...state.self,
    [payload.preference]: payload.value
  }
});

reducers[actions.IDENTITY_DISPLAY_VERIFICATION_SENT] = (state, payload) => ({
  ...state,
  verificationSentMessage: payload
});

reducers[actions.IS_FORCE_REGISTRATION] = (state, payload) => ({
  ...state,
  self:{
    ...state.self,
    isForceRegistration: payload
  }
});

reducers[actions.USERNAME_SAVING] = (state) => ({
  ...state,
  savedUserName: false
});

reducers[actions.USERNAME_SAVED] = (state) => ({
  ...state,
  savedUserName: true
});

reducers[actions.UPDATE_PROFILE_DELETION_STATUS] = (state,status) => ({
  ...state,
  accountStatus: status
});
reducers[actions.IS_ADMIN_FOR_ANY_ORGANIZATION] = (state, payload) => {
  return {
    ...state,
    IsAdminForAnyOrganization: payload
  };
};

reducers[actions.ACCOUNT_DELETED] = (state) => ({
  ...state,
  accountDeleted: true
});

reducers[actions.DELETE_ACCOUNT_ERROR] = (state) => ({
  ...state,
  accountDeleted: false
});

reducers[actions.AB_TESTING_SEGMENT] = (state, payload) => ({
  ...state,
  ABTestingSegment: payload
});

export default compileReducers(reducers, initialState);
