import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import { TextField } from 'shared/form-fields';
import * as validators from 'services/fields/field-validators';
import { Button } from 'shared';
import { connect } from 'react-redux';

export class ProviderInfoForm extends React.Component {

  constructor(props) {
    super(props);
    this.primaryPhysicianName = React.createRef();
    this.providerOrganizationName = React.createRef();
    this.providerAddress = React.createRef();
    this.providerEmail = React.createRef();
    this.phoneNumber = React.createRef();
    this.notes = React.createRef();
  }

  componentDidMount() {
    let props = this.props.initialValues;
    this.props.initialize({
      id: props.id,
      primaryPhysicianName: props.primaryPhysicianName,
      providerOrganizationName: props.providerOrganizationName,
      providerEmail: props.providerEmail,
      providerAddress: props.providerAddress,
      phoneNumber: props.phoneNumber,
      notes: props.notes
    });
  }

  render() {
    const { handleSubmit, readOnly } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <Field
            readOnly={readOnly}
            label={local.healthInfo.addProvider.name}
            autoFocus
            ref={this.primaryPhysicianName}
            name="primaryPhysicianName"
            validate={validators.required}
            component={TextField} />
        </div>
        <div className="form-row">
          <Field
            readOnly={readOnly}
            label={local.healthInfo.addProvider.orgName}
            ref={this.providerOrganizationName}
            name="providerOrganizationName"
            component={TextField} />
        </div>
        <div className="form-row">
          <Field
            readOnly={readOnly}
            label={local.healthInfo.addProvider.address}
            ref={this.providerAddress}
            name="providerAddress"
            component={TextField} />
        </div>
        <div className="form-row">
          <Field
            readOnly={readOnly}
            label={local.healthInfo.addProvider.email}
            ref={this.providerEmail}
            name="providerEmail"
            component={TextField} />
        </div>
        <div className="form-row">
          <Field
            readOnly={readOnly}
            label={local.healthInfo.addProvider.phoneNumber}
            ref={this.phoneNumber}
            name="phoneNumber"
            component={TextField} />
        </div>
        <div className="form-row">
          <Field
            readOnly={readOnly}
            label={local.healthInfo.addProvider.notes}
            ref={this.notes}
            name="notes"
            component={TextField} />
        </div>
        {!readOnly ? <Button
          id="profile-form-submit"
          className="btn btn-primary"
          submit
          color="blue">
          Save
        </Button> : null}
      </form>
    );
  }
}

ProviderInfoForm.propTypes = {
  // redux
  handleSubmit: PropTypes.func,
  saving: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool
};

const form = reduxForm({
  form: 'provider-info',
  initialValues: {},
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(ProviderInfoForm);

function mapStateToProps(state) {
  return {
    saving: state.medication.saving
  };
}

export default connect(mapStateToProps, null)(form);
