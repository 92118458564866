import moment from 'moment';
import local from 'services/localization/local';
import * as healthDataApi from 'services/api/healthdata-api';
import * as requestQueueActions from 'app/request/request-queue-actions';
import * as persistentDataActions from 'app/request/persistent-data-actions';
import { types } from './activity-types';

export const actionNames = {
  ACTIVITY_SAVE: 'ACTIVITY_SAVE',
  ACTIVITY_DELETE: 'ACTIVITY_DELETE'
};

export const actions = {
  saveActivity: (activity) => ({
    type: actionNames.ACTIVITY_SAVE,
    payload: {
      activity
    }
  }),
  deleteActivity: (profileId, activityId) => ({
    type: actionNames.ACTIVITY_DELETE,
    payload: {
      activityId,
      profileId
    }
  })
};

export function saveActivity({ profileId, name, startDateTime, duration, intensity, notes, healthDataSource,activityType }, requestId) {
  const start = moment(startDateTime);
  const end = moment(startDateTime).add(moment.duration(duration));
  const saveRequest = {
    name,
    startDateTime: start.format(),
    endDateTime: end.format(),
    intensity,
    notes,
    healthDataSource,
    activityType
  };

  return (dispatch) => {
    const action = state => healthDataApi.postActivity(profileId, state, saveRequest)
      .then(activity => dispatch(actions.saveActivity(activity)));

    dispatch(requestQueueActions.queueRequestWithToast(action, types.ACTIVITY_REQUESTS, requestId, {}, { profileId }));
  };
}

export function loadActivities({ profileId, dateRangeStart, dateRangeEnd }) {
  const action = state => healthDataApi.getActivities(profileId, dateRangeStart, dateRangeEnd, state)
    .then(result => result.results);

  return persistentDataActions.request(action, types.ACTIVITIES, profileId);
}

export function deleteActivity({ profileId, activityId }) {
  return (dispatch) => {
    const onSuccess = () => {
      dispatch(actions.deleteActivity(profileId, activityId));
    };

    const action = state => healthDataApi.deleteActivity(profileId, state, activityId)
      .then(onSuccess);

    dispatch(requestQueueActions.queueRequestWithToast(action, types.ACTIVITY_REQUESTS, null, {
      processingMessage: local.deleting,
      successMessage: local.deleted
    }, { profileId }));
  };
}
