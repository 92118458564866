import { evaluateCondition } from 'services/conditional-service';
import { getConfigForObservationType } from 'health/observations/observation-configs';
import { ObservationType } from './enums';

const regex = /(?<full>\{(?<key>[^?|:}]*)(?<ternary>\?(?<trueValue>[^|:]*))?[|:]?(?<falseValue>[^}]*)?\})/g;

export const templateText = (source, valueDictionary) => {

  if (source === null) {
    return '';
  }
  const SYSTOLIC = '{Observation_Systolic}';
  const DIASTOLIC = '{Observation_Diastolic}';
  const BLOOD_PRESSURE = '{Observation_BloodPressure}';
  const SLEEP = '{Observation_Sleep}';
  const EXERCISE = '{Observation_Exercise}';
  // process new values
  var systolic,diastolic,processValues;
  systolic = valueDictionary.Observation_Systolic;
  diastolic = valueDictionary.Observation_Diastolic;

  const object = (source === SYSTOLIC || source === BLOOD_PRESSURE) ? systolic : diastolic;

  processValues = object ? {
    observationType:object.observationType,
    timestamp: object.timestamp ,
    unit: object.unit ,
    value: object.value ,
    valueDiastolic : (diastolic ? diastolic.value : '' )
  } : '';

  let result = source;

  for (const match of source.matchAll(regex)) {
    var value = ((source === BLOOD_PRESSURE || source === SYSTOLIC || source === DIASTOLIC) ? processValues : valueDictionary[match.groups.key]);
    if (value === undefined && valueDictionary && Object.keys(valueDictionary).length > 0) {
      value = evaluateCondition(match.groups.key, valueDictionary);


    } else if (value !== undefined && value.observationType !== undefined) {
      const config = source === EXERCISE ? getConfigForObservationType(19) : getConfigForObservationType(value.observationType);
        if (config !== null && value.observationType != ObservationType.HeartRateVariability) {
        value = ((source === DIASTOLIC) || (source === SYSTOLIC) || (source === SLEEP) || (source === EXERCISE) ?  config.valueFormatterCustom({ observation: value, config }) : config.valueFormatter({ observation: value, config }));
      }
    }

    if (typeof (value) === 'boolean' && match.groups.ternary === undefined) {
      value = `${value}`;
    }
    var trueValue = match.groups.trueValue === undefined ? '' : match.groups.trueValue;
    var falseValue = match.groups.falseValue === undefined ? '' : match.groups.falseValue;

    var replacement = match.groups.ternary !== undefined ?
      (value ? trueValue : falseValue) :
      (value || falseValue || '');

    result = result.replace(match.groups.full, replacement);
  }
  return result;
};
