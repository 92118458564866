import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { handleSignInCallback } from 'identity/action-creators';
import SignInLoading from './SignInLoading';

class SignInCallbackWeb extends Component {
  componentDidMount() {
    this.props.actions.handleSignInCallback();
  }

  render() {
    return <SignInLoading />;
  }
}

SignInCallbackWeb.propTypes = {
  actions: PropTypes.shape({
    handleSignInCallback: PropTypes.func.isRequired
  }).isRequired
};

function mapDispatchToProps(dispatch) {
  const actions = {
    handleSignInCallback
  };
  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}


export default connect(null, mapDispatchToProps)(SignInCallbackWeb);
