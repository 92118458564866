import styled from 'styled-components';

export const Track = styled('div')`
  display: inline-block;
  height: 8px;
  width: 90%;
  margin: 0 5%;
`;

export const Tick = styled('div')`
  :before {
    content: "";
    position: absolute;
    left: 0;
    background: #828282;
    height: 5px;
    width: 2px;
    top: 0;
    transform: translate(-50%, 0.7rem);
  }
`;

export const TickLabel = styled('div')`
  position: absolute;
  font-size: 12px;
  color: #828282;
  top: 100%;
  transform: translate(-50%, 1.2rem);
  white-space: nowrap;
`;

export const Segment = styled('div')`
  background: ${props =>
    props.index === 0
      ? '#40B2E6'
      : props.index === 1
        ? '#E0E0E0'
        : props.index === 2
          ? 'black'
          : 'black'};
  height: 100%;
`;

export const Handle = styled('div')``;
