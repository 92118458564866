import { getProfileId } from 'app/selectors';
import { loadConnectedDevices } from 'devices/devices-actions';
import { getActiveTerraDevices, getDevices, getValidicGoogleFitMobileUrl, getValidicHealthKitConnectUrl } from 'devices/devices-selectors';
import { isAndroid, isGooglefitEnabled, isIOS } from 'mobile/helpers';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppleHealthType, googleFitLogo, GoogleFitType, AppleHealthLogo, GoogleFitDisplayName, AppleHealthDisplayName } from 'services/constants';
import { ObservationConnectionStatus } from 'services/enums';
import { Button, ProfileRedirect } from 'shared';
import { getObservationConnections } from './selectors';
import local from 'services/localization/local';
import { SaveObservationConnections } from './action-creators';
import './observation-connect.scss';
import 'devices/overview/devices.scss';
import { routePaths } from 'services/routing';
import { getActiveEnrollmentForProgram } from 'programs/programs-selectors';
import { getUniqueId } from 'services/helpers';
import { getRequest } from 'app/request/request-selectors';
import { getData } from 'app/request/persistent-data-selectors';
import { types } from 'devices/devices-types';

export default function ObservationConnectedDevices(props){
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const validicUserId = props.validicUserId;
  const observationType = props.observationType;
  const healthkit = useSelector(state => getValidicHealthKitConnectUrl(state, props.profileId));
  const googleFitUrl = useSelector(state => getValidicGoogleFitMobileUrl(state, props.profileId));
  const observationConnectedDevices = useSelector(state => getObservationConnections(state));
  const devices = useSelector(state => getDevices(state, profileId, healthkit, googleFitUrl));
  const profileObservations = observationConnectedDevices?.data?.profileObservationConnections?.find(x=>x.observationType === props.observationType);
  const programObservations = observationConnectedDevices?.data?.programObservationTypes?.find(x=>x.observationType === props.observationType);
  const supportedDeviceSourceNames = programObservations?.supportedDeviceSourceNames;
  const primaryDeviceName = profileObservations?.deviceSourceName;
  const activeEnrollment = useSelector(state => getActiveEnrollmentForProgram(state, props.programId));
  const [googleFitConnected, setGoogleFitConnected] = useState(false);
  const [uniqueId, setUniqueId] = useState(null);
  const request = useSelector(state => getRequest(state, uniqueId)) ;
  const goalCategoryId = props.goalCategoryId;
  const connectedTerraDevices = useSelector(state => getData(state.devices, types.TERRA_DEVICES, profileId));
  const activeTerraDevices = useSelector(state => getActiveTerraDevices(state, profileId));

  useEffect(() => {
    async function CheckGoogleFitConnected() {
      const data = await isGooglefitEnabled(validicUserId);
      setGoogleFitConnected(data);
    }
    CheckGoogleFitConnected();
  }, [validicUserId]);

  const validicConnectedDevices = devices?.filter(x=>x.connected === true && x.type !== primaryDeviceName && x.type !== GoogleFitType && x.type !== AppleHealthType && supportedDeviceSourceNames?.includes(x.type));

  if(isAndroid && googleFitConnected){
    if(primaryDeviceName !== GoogleFitType && supportedDeviceSourceNames?.includes(GoogleFitType)){
      validicConnectedDevices.push({
        type:GoogleFitType,
        logo_url : googleFitLogo,
        connect_url : googleFitUrl,
        display_name : GoogleFitDisplayName
      });

    }
  }
  if(isIOS &&  devices?.find(x=>x.connected === true && x.type === AppleHealthType)){
    if(primaryDeviceName !== AppleHealthType && supportedDeviceSourceNames?.includes(AppleHealthType)){
      validicConnectedDevices.push({
        type:AppleHealthType,
        logo_url : AppleHealthLogo,
        connect_url : healthkit,
        display_name : AppleHealthDisplayName
      });
    }
  }

  const connectedTerraDeviceList = connectedTerraDevices?.filter(x => x.connected === true && x.deviceSourceName != primaryDeviceName && supportedDeviceSourceNames?.includes(x.deviceSourceName));

  connectedTerraDeviceList?.map((x) => {
    const terraDevice = activeTerraDevices?.find(y => y.deviceSourceName === x.deviceSourceName);
    validicConnectedDevices.push({
      type:terraDevice?.deviceSourceName,
      logo_url : terraDevice?.logoUrl,
      display_name : terraDevice?.deviceDisplayName
    });
  });

  const pathwayName = activeEnrollment?.program?.name ?? props.goalName;
  useEffect(()=>{
    setUniqueId(getUniqueId());
    dispatch(loadConnectedDevices(profileId));
  },[profileId]);

  function SaveSelectedDevice(deviceSourceName){
    const body = {
      observationType,
      deviceSourceName,
      status: ObservationConnectionStatus.DeviceConnected
    };
    dispatch(SaveObservationConnections(profileId, body, props?.programId, uniqueId));
  }
  if(request.success && !request.processing && !request.hasError){
    return <ProfileRedirect to={routePaths.observationConnect} backpath={{goalCategoryId:goalCategoryId}} />;
  }
  return(
    <div className='observationConnect-connectWrapper'>
      <div className='observationConnect-connectTitle'>{local.observationConnect.connectTitle} {local.observationsTitle[observationType]}</div>
      <div className='observationConnect-connectDesc'>{local.formatString(local.observationConnect.connectDescriptionOne, pathwayName ?? '')}</div>
      {validicConnectedDevices && validicConnectedDevices.length > 0 ?
        <ul className='connected-devices'>
          {validicConnectedDevices?.map((x) => {
            return(
              <li key={x.type}>
                <div className="device-row">
                  <div className="device">
                    <div className="device-title">
                      <img className={x?.type + '-logo-align'} src={x?.type === AppleHealthType ? AppleHealthLogo : x?.type === GoogleFitType ? googleFitLogo : isIOS ? 'http:' + x?.logo_url:x?.logo_url}></img>
                      <div className='device-subTitle'>{x?.display_name}</div>
                    </div>
                    <Button id={x?.type} className="small-btn btn btn-primary" onClick={() => {  SaveSelectedDevice(x.type); }}>{local.observationConnect.select}</Button>
                  </div>
                </div>
              </li>
            );
          })}
        </ul> : null}
    </div>
  );
}
