import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Avatar, DataRow, CircleDotFigure, Button, Icon } from 'shared';
import { getProfileById } from 'profiles/list/selectors';
import local from 'services/localization/local';

export default function NotificationDataRow(props) {
  const { notification, handleDelete, handleRowClick, linkWrapper } = props;
  const profile = useSelector(state => getProfileById(state, notification.profileId));

  return (
    <DataRow
      id={notification.id}
      head={notification.header}
      subhead={notification.message}
      handleRowClick={handleRowClick}
      handleDelete={handleDelete}
      notes={moment(notification.timestamp).fromNow()}
      dataSectionWrapper={linkWrapper}
      visual={(
        <React.Fragment>
          <Avatar profile={profile} />
          {!notification.isRead && <CircleDotFigure color='magenta' />}
        </React.Fragment>
      )}
      deleteVisual={(
        <Button id={`delete-button-${notification.id}`} color='transparent' title={local.notifications.deleteNotification}><Icon symbol='clear'></Icon></Button>
      )}
      selected={false} />
  );
}

NotificationDataRow.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string,
    header: PropTypes.string,
    message: PropTypes.string,
    isRead: PropTypes.bool,
    timestamp: PropTypes.string,
    profileId: PropTypes.string
  }).isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  linkWrapper: PropTypes.func
};
