import React, { useEffect, useState } from 'react';
import { Loading, NoDataPlaceholder, Page, Button } from 'shared';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileId } from 'app/selectors';
import * as persistentDataSelectors from 'app/request/persistent-data-selectors';
import PropTypes, { func } from 'prop-types';
import { loadCarePlan, resetUnreadMedicalRecordsCount} from 'health/action-creators';
import CarePlanListItem from './CarePlanListItem';
import { types } from 'health/health-types';
import './carePlan.scss';
import { FhirResourceTypes } from 'services/enums';
import { getUnreadMedicalRecordsCount } from 'health/health-selectors';
import EHRRequestForm from './EHRRequestForm';
import {saveEHRRequest} from './action-creator'
import { getProfileName } from 'profiles/list/selectors';
export default function CarePlanList(props){
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const profileId = useSelector(getProfileId);
  const userName = useSelector(getProfileName);
  const carePlans = useSelector(state => persistentDataSelectors.getData(state.health, types.CARE_PLAN, profileId));
  const isLoading = useSelector(state=> persistentDataSelectors.isLoading(state.health, types.CARE_PLAN, profileId));
  const unreadMedicalRecordsCount = useSelector(state => getUnreadMedicalRecordsCount(state, profileId, FhirResourceTypes.CarePlan));
  const content = local.healthData.carePlan;

  useEffect(()=>{
    dispatch(loadCarePlan(profileId));
  },[profileId]);

  useEffect(()=>{
    if(unreadMedicalRecordsCount> 0)
      dispatch(resetUnreadMedicalRecordsCount(profileId, FhirResourceTypes.CarePlan));
  },[profileId, unreadMedicalRecordsCount]);

  function requestCarePlan(){
    setShowModal(!showModal);
  }

  function handleSubmit(values){
    setShowModal(!showModal);
    let requestDataType = 0;
    let model = {
      profileId,
      userName,
      ...values,
      requestDataType
    };
    console.log(model);
    dispatch(saveEHRRequest(model));
  }

  const renderCarePlans = () => {
    if(isLoading){
      return(<Loading></Loading>);
    }
    if(!carePlans || carePlans?.length === 0){
      return(<NoDataPlaceholder>
        <div>Can’t find a care plan? Send a request to your provider to share your health record and care plan.</div>
        <div className='form-row claim-offer-btn upgrade-button'>
          <Button id='subscribe-button' className="button blue btn btn-primary" onClick={requestCarePlan}>Request Health Record</Button>
        </div>
             </NoDataPlaceholder>);
    }
    return (carePlans && carePlans.length > 0 &&
        carePlans.map((x) => <CarePlanListItem key={x.remoteId} carePlan={x}></CarePlanListItem>)
    );
  };


  return(
    <Page
      id='carePlan-list'
      backPath={{to:props.location?.state?.backpath ?? routePaths.health}}
      centerElement={content.title}>
      <ul className='card-list'>
        {renderCarePlans()}
      </ul>
      <div>
        {showModal ? <EHRRequestForm onSubmit={handleSubmit} showModal={showModal} closeModal={requestCarePlan} initialValues={{}}></EHRRequestForm> : null}
      </div>
    </Page>
  );
}

CarePlanList.propTypes = {
  location: PropTypes.shape()
};
