import { useEffect, useRef } from 'react';
import { useDispatch  } from 'react-redux';
import { refreshUserInfo } from 'identity/action-creators';

export default function Auth0UserInfoRefresher() {
  const dispatch = useDispatch();

  const windowHandler = useRef(() => {
    const isComingBackIntoView = !document.hidden;
    if (isComingBackIntoView) {
      dispatch(refreshUserInfo());
    }
  });

  useEffect(() => {
    dispatch(refreshUserInfo());
    document.addEventListener('visibilitychange', windowHandler.current);
    return () => {
      document.removeEventListener('visibilitychange', windowHandler.current);
    };
  }, []);

  return null;
}
