import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ProfilePage from 'profiles/page/ProfilePage';
import { HealthNavigation } from 'health';
import { getIsSyncing } from 'health/connect/connect-selectors';
import local from 'services/localization/local';
import { getProfileId } from 'app/selectors';
import { loadImportCountSummaries, loadUnreadMedicalRecordsCount } from 'health/action-creators';
import './health-data.scss';

export default function HealthSummary(props) {
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const { loaded, isSyncing, isConnected } = useSelector(getIsSyncing);
  const enabledData = props.location?.state?.backpath?.enabledData;

  useEffect(() => {
    dispatch(loadUnreadMedicalRecordsCount(profileId));
  }, [profileId]);

  useEffect(() => {
    if (isConnected && (loaded || !isSyncing)) {
      dispatch(loadImportCountSummaries(profileId));
    }
  }, [profileId, loaded, isSyncing, isConnected]);

  return (
    <ProfilePage
      readOnlyProfile={enabledData != undefined && enabledData != null}
      id="health-summary-page"
      centerElement={local.healthDataHeader} backPath={{to: props.location?.state?.backpath?.to ?? props.location?.state?.backpath }}>
      <HealthNavigation enabledData={enabledData} />
    </ProfilePage>
  );
}

HealthSummary.propTypes = {
  location: PropTypes.shape()
};
