import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import local from 'services/localization/local';
import { NonAuthenticatedPage, LinkWrapper } from 'shared';
import { routePaths } from 'services/routing';
import VerifyEmailButton from './VerifyEmailButton';
import { getIdentity } from 'identity/selectors';
import { isMobile } from 'mobile/helpers';
import Auth0UserInfoRefresher from 'identity/Auth0UserInfoRefresher';

function VerifyEmailPage({ location }) {
  const identity = useSelector(getIdentity);

  if (!identity.unverifiedEmail) {
    return <Redirect to={location.state.from.pathname} />;
  }

  const header = identity.verificationSentMessage ? local.verifyEmailResentHeader : local.verifyEmailHeader;
  const body = identity.verificationSentMessage || local.verifyEmailBody;
  const refreshText = isMobile ? local.verifyEmailBodyMobile : local.verifyEmailBodyBrowser;

  return (
    <NonAuthenticatedPage id="verify-email-page" topChevronColor="teal" bottomChevronColor="yellow" titleTabText={local.verifyEmailHeader}>
      <Auth0UserInfoRefresher />
      <h3>{header}</h3>
      <p>{body}</p>
      <small>{refreshText}</small>
      <br />
      <br />
      <div>
        <VerifyEmailButton />
      </div>
      <div className="secondary-links">
        <small>
          <LinkWrapper id="link-to-dashboard" to={routePaths.root}>{local.goToDashboard}</LinkWrapper>
        </small>
      </div>
    </NonAuthenticatedPage>
  );
}

export default VerifyEmailPage;
