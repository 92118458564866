export { default as BloodPressure } from './svgs/blood-pressure.svg';
export { default as Medication } from './svgs/medication.svg';
export { default as Labs } from './svgs/labs.svg';
export { default as Conditions } from './svgs/conditions.svg';
export { default as Encounters } from './svgs/encounters.svg';
export { default as BloodGlucose } from './svgs/blood-glucose.svg';
export { default as Procedures } from './svgs/procedures.svg';
export { default as Immunizations } from './svgs/immunizations.svg';
export { default as Insulin } from './svgs/insulin.svg';
export { default as Temperature } from './svgs/temperature.svg';
export { default as Weight } from './svgs/weight.svg';
export { default as HeartRate } from './svgs/heart-rate.svg';
export { default as PeakFlow } from './svgs/peak-flow.svg';
export { default as Steps } from './svgs/steps.svg';
export { default as BodyMassIndex } from './svgs/body-mass-index.svg';
export { default as BodyFat } from './svgs/body-fat.svg';
export { default as Waist } from './svgs/waist.svg';
export { default as Chest } from './svgs/chest.svg';
export { default as Arms } from './svgs/arms.svg';
export { default as Allergies } from './svgs/allergies.svg';
export { default as Activities } from './svgs/activities.svg';
export { default as Thighs } from './svgs/thighs.svg';
export { default as Hips } from './svgs/hips.svg';
export { default as Height } from './svgs/height.svg';
export { default as BloodOxygen } from './svgs/blood-oxygen.svg';
export { default as Journal } from './svgs/journal.svg';
export { default as Nutrition } from './svgs/nutrition.svg';
export { default as ReproductiveHealth } from './svgs/reproductive-health.svg';
export { default as HeartRateVariability } from './svgs/heartRateVariability.svg';
export { default as TaskFirst } from './svgs/Task01.svg';
export { default as Task10 } from './svgs/Task10.svg';
export { default as Task50 } from './svgs/Task50.svg';
export { default as Task100 } from './svgs/Task100.svg';
export { default as Task500 } from './svgs/Task500.svg';
export { default as Task1000 } from './svgs/Task1000.svg';
export { default as TaskFirstG } from './svgs/Task-G01.svg';
export { default as Task10G } from './svgs/Task-G10.svg';
export { default as Task50G } from './svgs/Task-G50.svg';
export { default as Task100G } from './svgs/Task-G100.svg';
export { default as Task500G } from './svgs/Task-G500.svg';
export { default as Task1000G } from './svgs/Task-G1000.svg';
export { default as profile } from './svgs/profileBadge.svg';
export { default as profileG } from './svgs/profileGray.svg';
export { default as device } from './svgs/deviceBadge.svg';
export { default as deviceG } from './svgs/deviceGray.svg';
export { default as likeEmpty} from './svgs/thumb-up-empty.svg';
export { default as likeFilled } from './svgs/thumb-up-filled.svg';
export { default as dislikeEmpty } from './svgs/thumb-down-empty.svg';
export { default as dislikeFilled } from './svgs/thumb-down-filled.svg';
export { default as defaultBadge } from './svgs/badge-default-icon.svg';
export { default as Sleep } from './svgs/sleep.svg';
export { default as Rank01 } from './svgs/crown.svg';
export { default as Rank02 } from './svgs/rank#2.svg';
export { default as Rank03 } from './svgs/rank#3.svg';
export { default as FallBadge } from './svgs/fall-badge.svg';
export { default as FallBadgeG } from './svgs/fall-badgeG.svg';
export { default as NovemberBadge } from './svgs/Nov-Badge.svg';
export { default as NovemberBadgeG } from './svgs/Nov-BadgeG.svg';
export { default as FloorsClimbed } from './svgs/floors-climbed.svg';
export { default as GenericObservation } from './svgs/generic-observation-icon.svg';
export { default as CarePlan } from './svgs/care-plan.svg';
export { default as BloodKetone } from './svgs/blood-ketone.svg';




