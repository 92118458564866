import React from 'react';
import HealthRoutes from './HealthRoutes';

class HealthLayout extends React.Component {
  render() {
    return (
      <div id='health-layout' className='full-page-content'>
        <HealthRoutes />
      </div>
    );
  }
}

export default HealthLayout;
