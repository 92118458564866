import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon } from 'shared';
import { getColorByGuid } from 'services/helpers';
import { getProfileId } from 'app/selectors';
import { deleteMedicationDose,markNotificationAsNotTakenOrNotSkipped } from 'health/medication/dose/action-creators';
import DataRow from 'shared/data-row/DataRow';
import local from 'services/localization/local';

class DoseListItem extends React.Component {
  constructor(props) {
    super(props);
    this.onDelete = this.onDelete.bind(this);
    this.onClick = this.onClick.bind(this);

    this.state = {
      isSelected: false
    };
  }

  onClick() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ isSelected: !this.state.isSelected});
  }

  async onDelete() {
    const { actions, dose, profileId } = this.props;
    if(dose.isManual)
      await actions.deleteMedicationDose({ profileId: profileId, medicationDoseId:dose.id });
    else
      await  actions.markNotificationAsNotTakenOrNotSkipped({ profileId: profileId, medicationNotificationId:dose.id });
    this.props.GetDoseData();
  }

  render() {
    const { dose } = this.props;
    const medicationColorStyle = { backgroundColor: getColorByGuid(dose.medicationId) };
    const visual = dose.isMedicated ? <Icon className="med-taken" symbol="selectedCircle"></Icon> : ( dose.isSkipped ? <Icon className="med-skipped" symbol="removecircle"></Icon>: <figure className="medication-image" style={medicationColorStyle}><Icon symbol="medications" size="60%" /></figure>);
    const headLabel =
      (
        <div className="med-status"><span className="medicine-name">{dose.name}</span>

          <span className="med-status-timing">{dose.isMedicated ? local.medicationDoseTaken
            : ( dose.isSkipped ? local.medicationDoseSkipped: '')}{  (dose.isMedicated || dose.isSkipped) ? <span> { dose.isMedicated? moment(dose.takenAt).format('hh:mm A'): moment(dose.scheduledAt).format('hh:mm A')} </span> : '' }
          </span>
        </div>);

    var scheduledTime=(dose.isSkipped || dose.isMedicated ? dose.isManual?'':   moment(dose.scheduledAt).format('hh:mm A'):'');
    var subHeading='';
    if(scheduledTime!='') subHeading+= `${scheduledTime} | `;
    if(dose.dosage) subHeading += `${dose.dosage} | `;
    subHeading=subHeading&&subHeading.length>=3? subHeading.substr(0,subHeading.length-3):subHeading;

    return (
      <li className="card-list-item scrollview-list-item">
        <DataRow
          id={dose.id || ''}
          visual={visual}
          head={headLabel}
          subhead={subHeading}
          handleRowClick={dose.isSkipped || dose.isMedicated ? this.onClick : null}
          handleDelete={dose.isSkipped || dose.isMedicated ? this.onDelete : null}
          selected={this.state.isSelected} />
      </li>
    );
  }
}

DoseListItem.propTypes = {
  // store
  dose: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    dosage: PropTypes.string,
    medicationId: PropTypes.string.isRequired,
    scheduledAt: PropTypes.string.isRequired,
    takenAt:PropTypes.string,
    isMedicated: PropTypes.bool,
    isSkipped: PropTypes.bool
  }).isRequired,

  // actions
  actions: PropTypes.shape({
    deleteMedicationDose: PropTypes.func.isRequired,
    markNotificationAsNotTakenOrNotSkipped:PropTypes.func.isRequired
  }),

  GetDoseData:PropTypes.func
};

function mapStateToProps(state, props) {
  return {
    profileId: getProfileId(state, props)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { deleteMedicationDose,markNotificationAsNotTakenOrNotSkipped };
  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DoseListItem));
