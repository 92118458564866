/* eslint-disable no-unused-vars */
import { getObservationIcon } from 'devices/device-helper';
import React, { useEffect } from 'react';
import { ObservationType} from 'services/enums';
import local from 'services/localization/local';
import { NavIcon } from 'shared';
import { reduxForm } from 'redux-form';
import { buildPath, routePaths } from 'services/routing';
import BloodPressureGoalForm from 'health/observations/blood-pressure/BloodPressureGoalForm';
import BloodGlucoseGoalForm from 'health/observations/blood-glucose/BloodGlucoseGoalForm';
import Slider from './slider';
import NutritionGoalForm from 'health/observations/nutrition/NutritionGoalForm';
import './observation-goal.scss';
import * as constant from 'services/constants';

function ObservationGoalCard(props) {

  const observationType = props.observationType ?? ObservationType.None;
  const backPath = buildPath(routePaths.observationGoal, { profileId: props.profileId, programId: props.programId, programEnrollmentId: props.programEnrollmentId });

  useEffect(() => {
    dispatchObservationGoalFormData();
  }, []);

  function dispatchObservationGoalFormData() {
    if (props.initialValues && props.observationTypes && props.observationTypes.length > 0) {
      props.observationTypes.map((goalType) => {
        if (goalType == ObservationType.BloodGlucose) {
          props.dispatchBloodGlucoseData(props.initialValues);
          props.dispatchBloodGlucoseFormErrorFlag(false);
        }
        else if ((goalType == ObservationType.Systolic) || (goalType == ObservationType.Diastolic)) {
          props.dispatchBloodPressureData(props.initialValues);
          props.dispatchBloodPressureFormErrorFlag(false);
        }
        else if (goalType == ObservationType.Nutrition) {
          props.disptachNutritionData(props.initialValues);
          props.dispatchNutritionFormErrorFlag(false);
        }
        else if (goalType == ObservationType.Steps) {
          props.onStepsChange(props.initialValues.steps);
        }
      });
      let differenceObservationTypes = constant.GoalBasedObservationTypes.filter(x => props.observationTypes.indexOf(x) === -1);
      if (differenceObservationTypes && differenceObservationTypes.length > 0) {
        differenceObservationTypes.map((x) => {
          if (x == ObservationType.BloodGlucose) {
            props.dispatchBloodGlucoseFormErrorFlag(false);
          }
          else if ((x == ObservationType.Systolic) || (x == ObservationType.Diastolic)) {
            props.dispatchBloodPressureFormErrorFlag(false);
          }
          else if (x == ObservationType.Nutrition) {
            props.dispatchNutritionFormErrorFlag(false);
          }
        });
      }
    }
  }

  function disptachNutritionData(input) {
    props.disptachNutritionData(input);
  }

  function dispatchBloodPressureData(input) {
    props.dispatchBloodPressureData(input);
  }

  return (
    <ul className='card-list'>
      <li className="card-list-item">
        <div className='observation-goal-cardHeader'>
          <figure className="observation-goal-image">
            <NavIcon Icon={getObservationIcon(observationType)}></NavIcon>
          </figure>
          <span className="observation-goal-title">{local.observationsTitle[observationType]}</span>
        </div>
        <div className='observation-goal-cardBody'>
          {
            (observationType == ObservationType.BloodGlucose) &&
            <div >
              {renderBloodGlucoseGoalForm()}
            </div>
          }
          {
            (observationType == ObservationType.Systolic) &&
            <div >
              {renderBloodPressureGoalForm()}
            </div>
          }
          {
            observationType == ObservationType.Nutrition &&
            <div id='Nutri-target-component'>
              {renderNutritionGoalForm()}
            </div>
          }
          {
            observationType == ObservationType.Steps &&
            <form id="step-target-component">
              <div className="flex-field">
                <div className='form-row'>
                  <div className='formField'>
                    <label>{local.observation.steps.stepsRange}</label>
                    {renderStepGoal()}
                  </div>
                </div>
              </div>
            </form>
          }
        </div>
      </li>
    </ul>
  );

  function renderBloodGlucoseGoalForm() {
    return (<BloodGlucoseGoalForm
      initialValues={props.initialValues}
      saving={false}
      isProcessing={false}
      dispatchBloodGlucoseData={props.dispatchBloodGlucoseData}
      dispatchBloodGlucoseFormErrorFlag={props.dispatchBloodGlucoseFormErrorFlag}
      handleBack={backPath} isObservationGoal />
    );
  }

  function renderBloodPressureGoalForm() {
    return (<BloodPressureGoalForm
      initialValues={props.initialValues}
      saving={false}
      isProcessing={false}
      dispatchBloodPressureData={dispatchBloodPressureData}
      dispatchBloodPressureFormErrorFlag={props.dispatchBloodPressureFormErrorFlag}
      handleBack={backPath} isObservationGoal />
    );
  }

  function renderStepGoal() {
    return (<Slider
      stepSize={constant.MIN_STEP_SIZE}
      min={constant.MIN_GOAL}
      max={constant.MAX_GOAL}
      ticks={constant.stepTicks}
      maxTicksLimit={constant.GOAL_MAX_TICKS}
      currentTarget={props.initialValues.steps}
      onSlide={props.onStepsChange} isObservationGoal />
    );
  }

  function renderNutritionGoalForm() {
    return (<NutritionGoalForm
      initialValues={props.initialValues}
      isProcessing={false}
      hasError={props.hasError}
      unitType={props.initialValues.nutritionUnit}
      backPath={backPath}
      disptachNutritionData={disptachNutritionData}
      dispatchNutritionFormErrorFlag={props.dispatchNutritionFormErrorFlag}
      isObservationGoal />
    );
  }
}
export default reduxForm({
  form: 'observationGoalCard',
  enableReinitialize: true
})(ObservationGoalCard);
