import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import local from 'services/localization/local';
import { Icon } from 'shared';
import './offline-error.scss';

class OfflineError extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isConnected } = this.props;

    if (isConnected) {
      return null;
    }

    return (
      <div id="offline-error">
        <Icon symbol="offline" size="2em" />
        <small>{local.offlineErrorMainText}</small>
      </div>
    );
  }
}

OfflineError.propTypes = {
  isConnected: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    isConnected: state.network.isConnected
  };
}

export default connect(mapStateToProps, null)(OfflineError);
