import React, { createRef, useRef, useState, useEffect } from 'react';
import Compressor from 'compressorjs';
import PropTypes from 'prop-types';
import { Icon, Button } from 'shared';
import * as validators from 'services/fields/field-validators';
import { TextField } from 'shared/form-fields';
import { Field } from 'redux-form';
import local from 'services/localization/local';
import { fileTypesAllowed, fileMaxSizeKB, fileResizeWidth, fileResizeHeight, fileUploadLimit, fileMaxTextLength } from 'app/config';
import 'shared/form-fields/file-uploader-field.scss';

function FileUploaderField({ submitting, saving, handleFileSubmitData, handleBack }) {
  const options = {
    allowedTypes: fileTypesAllowed,
    maxSizeKB: fileMaxSizeKB,
    resizeWidth: fileResizeWidth,
    resizeHeight: fileResizeHeight,
    uploadLimit: fileUploadLimit,
    maxTextLength: fileMaxTextLength
  };

  const isSubmitting = submitting || saving;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [validFiles, setValidFiles] = useState([]);
  const [textIndexedTitles, setTextIndexedTitles] = useState([]);
  const [fileMaxError, setFileMaxError] = useState('');
  const [imgTitlePreview, setImageTitlePreview] = useState('');
  const [previewIndex, setPreviewIndex] = useState(0);
  const [previewMode, setPreviewMode] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const fileInputRef = createRef();
  const modalRef = useRef();
  const modalImageRef = useRef();
  const fileAllowedTypes = options.allowedTypes.split('.').join(' ');
  let uploadFiles = [];

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setIsSubmitDisabled(false);
    }
    else {
      setIsSubmitDisabled(true);
    }
    modalRef.current.style.display = 'none';
    let filteredFiles = selectedFiles.reduce((data, current) => {
      const fileExists = data.find(item => item.name === current.name);
      if (!fileExists) {
        return data.concat([current]);
      } else {
        return data;
      }
    }, []);
    if (previewMode) {
      openImageModal(filteredFiles, previewIndex);
    }
  }, [selectedFiles, validFiles, previewMode, previewIndex]);

  const updateTextValue = (value, index) => {
    let newtextIndexedTitles = [...textIndexedTitles];
    newtextIndexedTitles[index] = value;
    setTextIndexedTitles(newtextIndexedTitles);
  };

  const preventDefault = (e) => {
    e.preventDefault();
  };

  let dragOver = (e) => {
    preventDefault(e);
  };

  let dragEnter = (e) => {
    preventDefault(e);
  };

  let dragLeave = (e) => {
    preventDefault(e);
  };

  let fileDrop = (e) => {
    preventDefault(e);
    var fileCount = selectedFiles.length + e.dataTransfer.files.length;
    fileSelection(fileCount, e.dataTransfer.files);
  };

  let filesSelected = (e) => {
    var fileCount = selectedFiles.length + e.target.files.length;
    fileSelection(fileCount, e.target.files);
  };

  const fileSelection = (fileCount, files) => {
    var value = validators.maxFileValidation(fileCount, options.uploadLimit);
    if (value)
      setFileMaxError(value.replace('{0}', options.uploadLimit));
    else {
      setFileMaxError('');
      const nonExistFiles = fileNotExists(files);
      handleFiles(nonExistFiles);
    }
  };

  const fileNotExists = (files) => {
    let filteredArray = [];
    for (let index = 0; index < files.length; index++) {
      if (!(selectedFiles.filter(e => e.name === files[index].name).length > 0)) {
        filteredArray.push(files[index]);
      }
    }
    return filteredArray;
  };

  let fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        const sizeinKB = (files[i].size / 1024).toFixed();
        if (sizeinKB >= options.maxSizeKB) {
          new Compressor(files[i], {
            checkOrientation: true,
            strict: true,
            quality: 1,
            convertSize: options.maxSizeKB,
            maxHeight: options.resizeHeight,
            maxWidth: options.resizeWidth,
            mimeType: 'image/jpeg',
            success: (compressedResult) => {
              setSelectedFiles(prevArray => [...prevArray, compressedResult]);
              setValidFiles(prevArray => [...prevArray, compressedResult]);
            }
          });
        }
        else {
          setSelectedFiles(prevArray => [...prevArray, files[i]]);
          setValidFiles(prevArray => [...prevArray, files[i]]);
        }
        setTextIndexedTitles(prevArray => [...prevArray, '']);
      }
    }
  };

  const handleSubmitData = (e) => {
    e.preventDefault();
    if (validFiles.length > 0) {
      setIsSubmitDisabled(true);
      convertJson(e.target.notes.value);
    }
  };

  const convertJson = (notesData) => {
    for (let z = 0; z < validFiles.length; z++) {
      let fileObj = {};
      fileObj.tag = textIndexedTitles[z];
      getBase64(validFiles[z], (result) => {
        let splitArray = result ? result.split(',') : '';
        var base64String = '';
        if (splitArray !='' && splitArray.length > 1) {
          base64String = splitArray[1];
        }
        fileObj.fileData = base64String;
      });
      fileObj.filename = validFiles[z].name;
      if (validFiles[z].name != undefined) {
        fileObj.fileExtension = '.' + validFiles[z].name.split('.').pop();
      }
      fileObj.contentType = validFiles[z].type;
      uploadFiles.push(fileObj);
    }
    setTimeout(() => {
      let jsonData = JSON.stringify(uploadFiles);
      handleFileSubmitData(jsonData, notesData);
    }, 1000);

  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
  };

  const validateFile = (file) => {
    const validTypes = options.allowedTypes.split('.').join('image/');
    if (file.type === '' || validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  const removeFile = (name, i) => {
    setFileMaxError('');
    const indexValidFiles = validFiles.findIndex(e => e.name === name);
    const indexSelectedFiles = selectedFiles.findIndex(e => e.name === name);
    validFiles.splice(indexValidFiles, 1);
    selectedFiles.splice(indexSelectedFiles, 1);
    textIndexedTitles.splice(i, 1);
    setValidFiles([...validFiles]);
    setSelectedFiles([...selectedFiles]);
    setTextIndexedTitles([...textIndexedTitles]);
  };

  const openImageModal = (files, index) => {
    const reader = new FileReader();
    if (modalRef.current !== undefined) {
      modalRef.current.style.display = 'block';
    }

    reader.readAsDataURL(files[index]);
    reader.onload = function (e) {
      if (modalImageRef.current !== undefined) {
        modalImageRef.current.style.backgroundImage = `url(${e.target.result})`;
      }
      setPreviewIndex(index);
      setPreviewMode(true);
      setImageTitlePreview(textIndexedTitles[index]);
    };
  };

  const closeModal = () => {
    setPreviewMode(false);
    modalRef.current.style.display = 'none';
    modalImageRef.current.style.backgroundImage = 'none';
  };

  const nextPreviewImage = () => {
    setPreviewIndex(previewIndex + 1);
  };

  const prevPreviewImage = () => {
    setPreviewIndex(previewIndex - 1);
  };
  return (
    // eslint-disable-next-line react/jsx-no-bind
    <form id="form-container" onSubmit={e => { handleSubmitData(e); }}>
      <div
        className="upload-drop-container"
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        onClick={fileInputClicked}>
        {/* Currently fileupdlaoder supports only image to upload, to fix the iOS video crashing issue.. please handle for iOS if we are allowing other files to upload */}
        <input
          ref={fileInputRef}
          className="file-upload"
          type="file"
          multiple
          accept="image/*"
          onChange={filesSelected} />
        <label className="uploadField-content">
          <div className="upload-icon"><Icon symbol="fileUpload" /></div>
          <div className="upload-text desk">Drop your file(s) here or browse</div>
          <div className="upload-text mob">Click here to select file(s)</div>
          <div className="filesAllow">( {fileAllowedTypes} - max {options.uploadLimit} file(s) allowed.)</div>
        </label>
      </div>
      <div className="max-file">{fileMaxError}</div>
      <div className="file-display-container">
        <div className="uploadImgWrapper">
          {
            validFiles.map((data, index) =>
              (
                <div className="uploadedImg" key={data.name, index}>
                  {(validFiles.length > 0) ?
                    <div name={index} className="file-remove" onClick={() => removeFile(data.name, index)}>
                      <Icon symbol="dash" />
                    </div> : null}
                  <div className="imgBox" id={'imgBox_' + index} name={index} onClick={() => openImageModal(validFiles, index)}>
                    <img className="preview" alt='' src={URL.createObjectURL(data)} />
                  </div>
                  <div className='textTitle formField'>
                    {/* <label className='form-label'>Add Title</label> */}
                    <input
                      id={'tag_' + index}
                      className="form-control img-note"
                      type="text"
                      name={index}
                      key={data.name}
                      placeholder='Add Title'
                      autoComplete='off'
                      onChange={e => updateTextValue(e.target.value, index)}
                      maxLength={options.maxTextLength}
                      value={textIndexedTitles[index]} />
                  </div>
                </div>)
            )
          }
        </div>
      </div>
      <div className="form-row">
        <Field
          autoComplete='off'
          readOnly={isSubmitting}
          label={local.formNotesLabel}
          name="notes"
          component={TextField}
          validate={[validators.maxLength250]}
          subLabel={local.formNotesPlaceholder} />
      </div>
      <br />
      <br />
      <div className="form-row">
        <Button id="immunization-form-cancel" className="btn btn-secondary" onClick={handleBack}>{local.formCancel}</Button>
        <Button
          id="immunization-form-submit"
          className="btn btn-primary"
          submit
          color="blue"
          disabled={isSubmitDisabled}>
          {local.formSave}
        </Button>
      </div>
      <div className="modal uploadImg" ref={modalRef}>
        <div className="overlay"></div>
        <span className="close" onClick={(() => closeModal())}><Icon symbol="times" /></span>
        <div className="modal-image" ref={modalImageRef}></div>
        <div className="preview-text"> {imgTitlePreview}</div>
        {previewIndex !== 0 && <Icon symbol="arrowleft" id="icnPrev" className="previous preview-arrow" onClick={(() => prevPreviewImage())} />}
        {previewIndex !== (validFiles.length - 1) && <Icon symbol="arrowright" id="icnNext" className="next preview-arrow" onClick={(() => nextPreviewImage())} />}
      </div>
    </form>
  );
}

FileUploaderField.propTypes = {
  saving: PropTypes.bool,
  handleBack: PropTypes.func.isRequired,
  handleFileSubmitData: PropTypes.func,
  submitting: PropTypes.bool
};

export default FileUploaderField;
