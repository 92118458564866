import React from 'react';
import PropTypes from 'prop-types';
import withNavigation from 'app/withNavigation';
import { Icon } from 'shared';
import './header.scss';

function NavAction({ navigate, to, params, text, iconSymbol, mobileOnly, backpath }) {
  if (!to) {
    return null;
  }

  const handleClick = () => {
    navigate({ to, params, state:{backpath} });
  };

  const actionContent = iconSymbol ? <Icon symbol={iconSymbol} /> : text;
  const mobileOnlyClass = mobileOnly ? 'mobile-only' : '';

  return (
    <button className={`nav-action ${mobileOnlyClass}`} aria-label={text} type="button" onClick={handleClick}>
      {actionContent}
    </button>
  );
}

NavAction.propTypes = {
  text: PropTypes.string.isRequired,
  iconSymbol: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  to: PropTypes.string,
  params: PropTypes.shape(),
  // navigation
  navigate: PropTypes.func.isRequired,
  mobileOnly: PropTypes.bool,
  backpath: PropTypes.string
};

export default withNavigation(NavAction);
