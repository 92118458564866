import React, { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { localStoragePaths } from 'services/constants';
import { checkSignIn,checkSignInRequired, tokenGetSuccess } from 'identity/action-creators';
import SignIn from 'identity/signin/SignIn';
import LayoutContainer from 'app/layout/LayoutContainer';
import IdentityWrapper from 'identity/IdentityWrapper';
import AuthLoader from './AuthLoader';
const env = window.env || {};

export default function AuthWrapper() {
  const dispatch = useDispatch();
  const isSignedIn = useSelector(state => state.identity.isSignedIn, shallowEqual);
  const signInRequired = useSelector(state => state.identity.signInRequired, shallowEqual);

  let redirect = null;
  if (isSignedIn) {
    redirect = localStorage.getItem(localStoragePaths.redirect);
    localStorage.removeItem(localStoragePaths.redirect);
  }

  useEffect(() => {
    var isIdMeAuth = localStorage.getItem(localStoragePaths.isIdMeAuth);
    if (!isSignedIn) {
      if(isIdMeAuth){
        var expiry = localStorage.getItem('id_token_expiry');
        var token = localStorage.getItem('id_token');
        if (expiry) {
          const utcDate = new Date(expiry + ' UTC');

          // Get the local time equivalent
          const localTimeString = utcDate.toLocaleString();

          if (new Date() < new Date(localTimeString) && token) {
            dispatch(tokenGetSuccess(token));
            //tokenvalid
            return;
          }

        }

        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if(code) {
          let url = `${env.API_ROOT_PROFILES}/idme-token?code=${code}&redirectUrl=${env.Idme_redirectionurl}`;
          let header = {
            'Content-Type': 'application/json'
          };
          return fetch(url, {
            method: 'Get',
            headers: header
          }).then(response => {
            return response.json();
          })
            .then(data => {
              if (data) {
                localStorage.setItem('id_token_expiry', data.id_token_expiry);
                localStorage.setItem('id_token', data.id_token);
                var sessionRedirect = localStorage.getItem(localStoragePaths.redirect);
                if(!sessionRedirect){
                  localStorage.setItem(localStoragePaths.redirect,"/");
                }
                dispatch(tokenGetSuccess(data.id_token));
              }
              return data;
            })
            .catch(error => {
              console.log(error);
              alert('Error occured while connecting to id.me');
              localStorage.removeItem(localStoragePaths.isIdMeAuth);
              return null;
            });
        }
        else{
          localStorage.removeItem(localStoragePaths.isIdMeAuth);
          dispatch(checkSignIn());
        }

      }else{
        dispatch(checkSignIn());
      }
    }
  }, []);

  if (signInRequired) {
    return <SignIn />;
  } else if (redirect) {
    return <Redirect to={redirect} />;
  } else if (isSignedIn) {
    return (
      <IdentityWrapper>
        <LayoutContainer />
      </IdentityWrapper>
    );
  } else {
    return <AuthLoader />;
  }
}
