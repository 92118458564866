import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withContext from 'app/withContext';
import withNavigation from 'app/withNavigation';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { ProfileRedirect, BackAction, TextAction } from 'shared';
import ProfilePage from 'profiles/page/ProfilePage';
import { saveMedication, deleteMedication} from 'health/medication/action-creators';
import { saveMedicationReset } from 'health/medication/actions';
import MedicationDataLoadingStatus from 'health/medication/MedicationDataLoadingStatus';
import LogMedicationForm from './LogMedicationForm';
import './log-medications.scss';
import moment from 'moment';

export class LogMedication extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.deleteMedication = this.deleteMedication.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.saveMedicationReset();
  }

  deleteMedication() {
    const { actions, medication, profileId } = this.props;
    actions.deleteMedication({ profileId, medicationId: medication.id });
  }

  handleSubmit(values) {
    values.startAt=new moment(values.startAt,'MMMM Do YYYY').format();
    const { actions, profileId } = this.props;
    actions.saveMedication({ ...values, profileId });
  }

  handleBack() {
    const { navigate } = this.props;
    navigate({to: this.props.location?.state?.backpath?.to ?? routePaths.healthMedication });
  }

  render() {
    const { saveSuccess, titleTabText, centerElement, medication, readOnlyProfile, showDelete } = this.props;
    if (saveSuccess) {
      let path = medication?.id?routePaths.healthMedication:routePaths.healthMedicationDose;
      return <ProfileRedirect to={path} />;
    }

    return (
      <ProfilePage
        id='log-medication'
        readOnlyProfile={readOnlyProfile}
        centerElement={centerElement}
        titleTabText={titleTabText}
        backAction={<BackAction handleBack={this.handleBack} />}>
        <MedicationDataLoadingStatus>
          <LogMedicationForm onSubmit={this.handleSubmit} handleBack={this.handleBack} initialValues={medication} />

          {showDelete && (
            <div className='remove-medication-section'>
              <div className='remove-medication-warning'>
                <small>{local.medications.deleteWarning}</small>
              </div>

              <TextAction id='delete-medication-link' onClick={this.deleteMedication}>
                {local.medications.deleteButton}
              </TextAction>
            </div>
          )}
        </MedicationDataLoadingStatus>
      </ProfilePage>
    );
  }
}

LogMedication.propTypes = {
  medication: PropTypes.shape(),
  titleTabText: PropTypes.string,
  centerElement: PropTypes.string,
  readOnlyProfile: PropTypes.bool,
  showDelete: PropTypes.bool,
  // redux
  profileId: PropTypes.string.isRequired,
  saveSuccess: PropTypes.bool.isRequired,
  // actions
  actions: PropTypes.shape({
    saveMedication: PropTypes.func.isRequired,
    saveMedicationReset: PropTypes.func.isRequired,
    deleteMedication: PropTypes.func.isRequired
  }).isRequired,
  // navigation,
  navigate: PropTypes.func.isRequired
};

LogMedication.defaultProps = {
  titleTabText: local.medicationAddTitleTab,
  centerElement: local.medicationLogHeader,
  readOnlyProfile: false,
  showDelete: false
};

function mapStateToProps(state) {
  return {
    saveSuccess: state.medication.saved
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { saveMedication, saveMedicationReset, deleteMedication };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default withNavigation(withContext(connect(mapStateToProps, mapDispatchToProps)(LogMedication)));
