import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ProfileRedirect } from 'shared';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { getUrlMedication } from 'health/medication/selectors';
import MedicationDataLoader from 'health/medication/MedicationDataLoader';
import LogMedication from './LogMedication';

export class EditMedication extends React.Component {
  render() {
    const { medication, loaded } = this.props;
    if (loaded && !medication) {
      return <ProfileRedirect to={routePaths.healthMedication} />;
    }

    return (
      <React.Fragment>
        {!loaded && <MedicationDataLoader />}
        <LogMedication medication={medication} centerElement={local.medicationEditHeader} titleTabText={local.medicationEditTitleTab} readOnlyProfile showDelete />
      </React.Fragment>
    );
  }
}

EditMedication.propTypes = {
  // redux
  medication: PropTypes.shape(),
  loaded: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    medication: getUrlMedication(state, ownProps),
    loaded: state.medication.loaded
  };
}

export default withRouter(connect(mapStateToProps)(EditMedication));
