import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import local from 'services/localization/local';
import styleVars from 'styles/_variables.scss';

class ActivityBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.getOptions = this.getOptions.bind(this);
  }

  getData() {
    const { data } = this.props;

    const backgroundColor = data.map(x => {
      // calculate the alpha value of the color based on the total duration, overall intensity,
      // and amount of activities for the date
      let alphaValue = 1 - (9 * (1 / (x.totalIntensityModifier / x.count)));
      alphaValue = alphaValue ? Math.max(alphaValue, 0.3) : 0;
      return `rgba(${styleVars.colorTealRedChannel}, ${styleVars.colorTealGreenChannel}, ${styleVars.colorTealBlueChannel}, ${alphaValue})`;
    });

    return {
      labels: data.map(x => x.date),
      datasets: [{
        backgroundColor,
        barPercentage: 0.3,
        categoryPercentage: 1.5,
        data: data.map(x => x.totalDurationInMinutes)
      }]
    };
  }

  getOptions() {
    const { data } = this.props;

    return {
      responsive: true,
      tooltips: {
        enabled: true,
        displayColors: false,
        callbacks: {
          label: function (tooltipItem) {
            const itemData = data[tooltipItem.index];
            return [
              local.formatString(local.activity.visDurationlabel, itemData.totalDurationInMinutes?.toFixed()),
              local.formatString(local.activity.visIntensityLabel, itemData.totalIntensityModifier?.toFixed())
            ];
          }
        }
      },
      hover: {
        mode: null
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          display: true,
          gridLines: {
            display: false
          },
          ticks: {
            display: true,
            autoSkip: true,
            maxTicksLimit: 4
          }
        }],
        yAxes: [{
          display: false,
          gridLines: {
            display: false
          },
          ticks: {
            suggestedMin: 0,
            display: true
          }
        }]
      }
    };
  }

  render() {
    const data = this.getData();
    const options = this.getOptions();

    return (
      <Bar
        data={data}
        options={options}
        width={100}
        height={60}
        className="chart-data" />
    );
  }
}

ActivityBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape).isRequired
};

export default ActivityBarChart;
