import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

class DateOption extends React.Component {
  constructor(props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
    this.getDateOptionClassName = this.getDateOptionClassName.bind(this);
    this.getMonthDateClassName = this.getMonthDateClassName.bind(this);
  }

  onSelect() {
    const { date, onChange, isDisabled, isSelected } = this.props;
    if (!(isDisabled || isSelected)) {
      onChange(moment(date).format(moment.HTML5_FMT.DATE));
    }
  }

  getDateOptionClassName() {
    const { isSelected, isDisabled } = this.props;
    if (isDisabled) {
      return 'date-option disabled';
    }
    if (isSelected) {
      return 'date-option selected';
    }
    return 'date-option';
  }

  getMonthDateClassName() {
    const { date, hasDataForDate } = this.props;
    let className = 'month-date';
    if (date === moment().format(moment.HTML5_FMT.DATE)) {
      className = `${className} today`;
    }
    if (hasDataForDate) {
      className = `${className} hasData`;
    }
    return className;
  }

  render() {
    const { date } = this.props;
    const dayLetter = moment(date).format('ddd')[0];
    const dateOfMonth = moment(date).format('D');

    return (
      <div className="row-item">
        <div data-testid={`select-option-${date}`} id={`select-option-${date}`} className={this.getDateOptionClassName()} onClick={this.onSelect}>
          <span className="day-letter">{dayLetter}</span>
          <span data-testid={`date-option-${date}`} className={this.getMonthDateClassName()}>{dateOfMonth}</span>
        </div>
      </div>
    );
  }
}

DateOption.propTypes = {
  onChange: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  // store
  hasDataForDate: PropTypes.bool
};

function mapStateToProps(state, props) {
  const { dateDataSelector } = props;
  return {
    hasDataForDate: dateDataSelector ? dateDataSelector(state, props.date) : false
  };
}

export default connect(mapStateToProps)(DateOption);
