import React from 'react';
import LabResultsEncounterListItem from './LabResultsEncounterListItem';

export default function LabResultsEncounterList({encounters}){

  if(!encounters)
    return null;

  return(encounters.panels?.map(encounter =>
    (<LabResultsEncounterListItem
      key={encounter.remoteId}
      labId={encounters.remoteId}
      provider={encounters.provider}
      issued={encounters.issued}
      isDocument={encounters.isDocument}
      encounter={encounter} />)));
}
