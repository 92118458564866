import React from 'react';
import { useSelector } from 'react-redux';
import { InterviewModal } from '@healthchampion/interviews';
import local from 'services/localization/local';
import { getDataState } from './interview-selectors';

function Interviewer() {
  const interviewId = useSelector(state => state.interviews.interviewId);
  const { loading, saving, error } = useSelector(state => getDataState(state, interviewId));

  const text = {
    ...local.interviews,
    errorMessage: local.error,
    processingMessage: local.saving,
    closeConfirmationContent: {
      content: local.closeConfirmationContent,
      backToTaskLabel: local.backToTaskLabel,
      closeTaskLabel:local.closeTaskLabel
    }
  };

  return (
    <InterviewModal
      reduxPath='hcInterviews'
      loading={loading}
      saving={saving}
      error={error}
      text={text} />
  );
}

export default Interviewer;
