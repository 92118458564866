import { healthCareProfileRoot as apiRoot} from 'app/config';
import { httpGet, httpPatch, httpPost, buildUrl, parseSuccess, parseError} from './api';
import moment from 'moment';

export const postHealthInfoInsurance = (medication, state) => {
  var url = buildUrl(apiRoot, '/api/MemberHealthCareInfo/save-member-health-insurance');
  return httpPost(url,medication, state)
    .then(parseSuccess);
};

export const postHealthInfoProvider = (medication, state) => {
  var url = buildUrl(apiRoot, '/api/MemberHealthCareInfo/save-member-health-providerinfo');
  return httpPost(url,medication, state)
    .then(parseSuccess);
};

export const postEHRRequest = (profileId,requestModel, state) => {
  var url = buildUrl(apiRoot, '/api/EHRRequest', {profileId});

  return httpPost(url,requestModel, state)
    .then(parseSuccess);
};

export const getHealthInfo = (profileId,state) => {
  var url = buildUrl(apiRoot,'/api/MemberHealthCareInfo/get-member-healthcare-info', {profileId});

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getAppointments = (state, profileId, takeCount) => {
  var url = buildUrl(apiRoot,'/api/Appointment', {profileId, takeCount });

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getAppointmentDetails = (state, profileId, appointmentId) => {
  var url = buildUrl(apiRoot,'/api/Appointment/details', {profileId, appointmentId });

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getCarePlan = (state, profileId) => {
  var url = buildUrl(apiRoot,'/api/CarePlan', {profileId});

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getConditions = (state, profileId) => {
  var url = buildUrl(apiRoot,'/api/Condition', {profileId});

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getEncounters = (state, profileId) => {
  var url = buildUrl(apiRoot,'/api/Encounter', {profileId});

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getProcedures = (state, profileId) => {
  var url = buildUrl(apiRoot,'/api/Procedure', {profileId});

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getObservations = (state, profileId, ObservationSearchModel) => {
  var url = buildUrl(apiRoot,'/api/VitalSign', {profileId, ObservationSearchModel });

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getDiagnosticReports = (state, profileId) => {
  var url = buildUrl(apiRoot,'/api/DiagnosticReport', {profileId });

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getImmunizations = (state, profileId) => {
  var url = buildUrl(apiRoot,'/api/Immunization', {profileId });

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getLabTrends = (state, profileId) =>{
  const utcOffsetMinutes = moment().utcOffset();
  return httpGet(buildUrl(apiRoot, '/api/DiagnosticReport/lab-trends', {profileId, utcOffsetMinutes}), state)
    .then(parseSuccess)
    .catch(parseError);
};

export const verifyProfile = (profile, requireSsn, state) => httpPatch(buildUrl(apiRoot, `/api/IdentityVerification/${profile.profileId}`, { requireSsn }), profile, state)
  .then(parseSuccess)
  .catch(parseError);

export const answerQuestions = (profileId, answeredQuestions, state) =>
  httpPost(`${apiRoot}/api/IdentityVerification/${profileId}`, { answeredQuestions }, state)
    .then(parseSuccess)
    .catch(parseError);

export const verifyProfileBySsn = (profileId, ssn4, state) =>
  httpPost(`${apiRoot}/api/IdentityVerification/${profileId}/ssn`, { ssn4 }, state)
    .then(parseSuccess)
    .catch(parseError);

export const getIdentityVerificationStatus = (profileId, email, state) =>
  httpGet(buildUrl(apiRoot, '/api/Authorization/is-authorize-OrgEHR', {profileId, email}), state)
    .then(parseSuccess)
    .catch(parseError);

export const setIdentityVerifiedFalse = (profileId, state) =>
  httpPatch(buildUrl(apiRoot, '/api/IdentityVerification/deidentify-profile', {profileId}), null, state)
    .then(parseSuccess)
    .catch(parseError);

export const getDiagnosticReportAttachments = (state, id, profileId) => {
  var url = buildUrl(apiRoot,'/api/DiagnosticReport/attachments', {id, profileId });

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getInvoices = (state, profileId) => {
  var url = buildUrl(apiRoot,'/api/Invoice', {profileId});

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getHealthDataRecords = (state, profileId) => {
  var url = buildUrl(apiRoot, '/api/ResourceMapping/summary', { profileId });

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};
