import * as dataSelectors from 'app/request/persistent-data-selectors';
import {DASHBOARD_APPOINTMENT,APPOINTMENT_LIST, APPOINTMENT_DETAILS} from './action-names';

export const getDashboardAppointment = (state) => {

  const identityId = state.identity && state.identity.self.id;
  const appointment = dataSelectors.getType(state.appointment, DASHBOARD_APPOINTMENT, identityId);

  return {
    loaded: appointment.loaded,
    loading: appointment.loading,
    hasError: appointment.hasError,
    data: appointment.data?.results[0]
  };
};

export const getAppointmentList = (state) => {

  const identityId = state.identity && state.identity.self.id;
  const appointment = dataSelectors.getType(state.appointment, APPOINTMENT_LIST, identityId);

  return {
    loaded: appointment.loaded,
    loading: appointment.loading,
    hasError: appointment.hasError,
    data: appointment.data?.results
  };
};

export const getAppointmentDetails = (state) => {

  const identityId = state.identity && state.identity.self.id;
  const appointment = dataSelectors.getType(state.appointment, APPOINTMENT_DETAILS, identityId);

  return {
    loaded: appointment.loaded,
    loading: appointment.loading,
    hasError: appointment.hasError,
    appointment: appointment.data?.results[0]
  };
};
