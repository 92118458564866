import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import local from 'services/localization/local';
import { Page, NavAction } from 'shared';
import { getProfileId } from 'app/selectors';
import { routePaths } from 'services/routing';
import { isInviteStatusesLoading, isInviteStatusesLoadError, isNothingToShow } from 'profiles/sharing/sharing-selectors';
import { loadInviteStatusesForProfile } from 'profiles/sharing/sharing-actions';
import OutgoingInvitesList from './OutgoingInvitesList';
import CurrentAccessList from './CurrentAccessList';
import ShareWithOthers from './ShareWithFamily';
import { getIsCurrentProfileOwned } from 'profiles/profiles-selectors';
import './sharing-admin.scss';

export default function SharingAdminPage() {
  const dispatch = useDispatch();
  const isOwner = useSelector(getIsCurrentProfileOwned);
  const loading = useSelector(isInviteStatusesLoading);
  const error = useSelector(isInviteStatusesLoadError);
  const nothingToShow = useSelector(isNothingToShow);
  const nothingMessage = (
    <div data-testid='nothing-container' className='nothing-message-container'>
      <h5>{local.sharing.callToAction}</h5>
      <small>{local.sharing.shareToSeeData}</small>
    </div>
  );

  const profileId = useSelector(getProfileId);

  useEffect(() => {
    dispatch(loadInviteStatusesForProfile());
  }, [profileId]);

  if (!isOwner) {
    return <Redirect to={routePaths.root} />;
  }

  return (
    <Page
      id='sharing-admin-page'
      loading={loading}
      error={error}
      backPath={{ to: routePaths.root }}
      centerElement={local.sharing.sharingTitle}>
      <OutgoingInvitesList />
      <CurrentAccessList />
      <ShareWithOthers isFamilyShare />
      <ShareWithOthers />
      {nothingToShow ? nothingMessage : null}
    </Page>
  );
}
