import React from 'react';
import PropTypes from 'prop-types';
import Redux from 'shared/react-redux-hooks';
import moment from 'moment';
import LogPeakFlowForm from './LogPeakFlowForm';
import { getProfileId } from 'app/selectors';
import { ObservationType, Unit } from 'services/enums';
import { observationReset } from 'health/actions';
import { getObservationTypeSavedStatus } from 'health/observations/observation-selectors';
import * as healthActions from 'health/action-creators';

function LogPeakFlow({ handleBack, pathwayContext }) {
  const dispatch = Redux.useDispatch();
  const profileId = Redux.useSelector(getProfileId);
  const saving = Redux.useSelector((state) => getObservationTypeSavedStatus(state, ObservationType.PeakFlow));

  React.useEffect(() => {
    return () => {
      dispatch(observationReset(ObservationType.PeakFlow));
    };
  }, [profileId, dispatch]);

  const handleSubmit = React.useCallback(
    ({ peakFlowValue, notes, timestamp }) => {
      dispatch(healthActions.saveObservation({
        profileId,
        timestamp,
        notes,
        value: peakFlowValue,
        observationType: ObservationType.PeakFlow,
        unit: Unit.LiterPerMinute
      }));
    },
    [profileId]);

  const currentTimestamp = moment().format(moment.HTML5_FMT.DATETIME_LOCAL);
  const initialValues = { timestamp: currentTimestamp };

  return (
    <LogPeakFlowForm
      pathwayContext={pathwayContext}
      profileId={profileId}
      onSubmit={handleSubmit}
      handleBack={handleBack}
      saving={saving}
      initialValues={initialValues} />
  );
}

LogPeakFlow.propTypes = {
  handleBack: PropTypes.func.isRequired,
  pathwayContext: PropTypes.shape()
};

export default LogPeakFlow;
