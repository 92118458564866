import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadObservationData } from 'health/action-creators';
import { getObservationLoading, getObservationLoadError } from 'health/observations/observation-selectors';
import { getProfileId } from 'app/selectors';
import { DataLoadingStatus } from 'shared';

class VitalsDataContainer extends React.Component {
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    const { profileId } = this.props;

    if (profileId !== prevProps.profileId) {
      this.loadData();
    }
  }

  loadData() {
    const { profileId, observationType, actions: { load } } = this.props;
    load({ observationType, profileId });
  }

  render() {
    const { loading, loadError, children } = this.props;
    return (
      <DataLoadingStatus loading={loading} error={loadError}>
        {children}
      </DataLoadingStatus>
    );
  }
}
VitalsDataContainer.propTypes = {
  observationType: PropTypes.number.isRequired,
  children: PropTypes.node,
  // redux
  profileId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  loadError: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    load: PropTypes.func
  }).isRequired
};

function mapStateToProps(state, ownProps) {
  const props = {
    profileId: getProfileId(state, ownProps),
    loading: getObservationLoading(state, ownProps),
    loadError: getObservationLoadError(state, ownProps)
  };

  return props;
}

function mapDispatchToProps(dispatch) {
  const actions = { load: loadObservationData };

  const actionMap = { actions: bindActionCreators(actions, dispatch) };

  return actionMap;
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VitalsDataContainer));
