import React from 'react';
import './progress-bar.scss';

export const getMultiplier = (numericProgress) => {
  if (isNaN(numericProgress)) {
    return 0;
  }

  if (numericProgress < 0) {
    return 0;
  }

  if (numericProgress > 1) {
    return 1;
  }

  return numericProgress;
};

function ProgressBar({ primaryProgress, secondaryProgress }) {
  const primaryProgressPrecent = `${getMultiplier(primaryProgress) * 100}%`;
  const secondaryProgressPercent = `${getMultiplier(secondaryProgress) * 100}%`;

  return (
    <div className='progress-bar-wrapper'>
      <div className="progress-bar">
        <div style={{ width: secondaryProgressPercent }} className="progress-bar-secondary"></div>
        <div style={{ width: primaryProgressPrecent }} className="progress-bar-primary"></div>
      </div>
      <div className='progress-bar-tag'></div>
      <div style={{ left: primaryProgressPrecent }} className='progress-bar-tag-primary'></div>
    </div>
  );

}

export default ProgressBar;
