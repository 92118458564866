import React from 'react';
import PropTypes from 'prop-types';
import withContext from 'app/withContext';

class ProfileDataLoader extends React.Component {
  componentDidMount() {
    this.props.loadData();
  }

  componentDidUpdate(prevProps) {
    const { uniqueId, profileId, loadData } = this.props;

    if (
      profileId !== prevProps.profileId ||
      uniqueId !== prevProps.uniqueId) {
      loadData();
    }
  }

  render() {
    return this.props.children;
  }
}

ProfileDataLoader.propTypes = {
  uniqueId: PropTypes.string,
  profileId: PropTypes.string.isRequired,
  loadData: PropTypes.func.isRequired,
  children: PropTypes.node
};

ProfileDataLoader.defaultProps = {
  children: null
};

export default withContext(ProfileDataLoader);
