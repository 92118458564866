import React from 'react';
import local from 'services/localization/local';
import OrganizationsList from 'organizations/list/OrganizationsList';
import { Page, NavAction } from 'shared';
import { routePaths } from 'services/routing';
import MobileBackButtonController from 'mobile/MobileBackButtonController';
import ProfileList from 'profiles/list/ProfileList';
import InvitesList from './InvitesList';
import ManageProfileNav from 'profiles/navigation/ManageProfileNav';

import './people-page.scss';

export default function PeoplePage() {
  return (
    <Page
      id="people-page"
      centerElement={local.listPeopleHeader}
      primaryAction={<NavAction text={local.add} iconSymbol="add" to={routePaths.peopleAdd} />}>
      <div>
        <MobileBackButtonController to={routePaths.root} />
        <InvitesList />
        <ProfileList />
        <ManageProfileNav />
        <OrganizationsList />
      </div>
    </Page>
  );
}
