import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withContext from 'app/withContext';
import { getBloodPressureChartData, getActiveGoal, getBloodPressureAverage } from 'health/observations/blood-pressure/blood-pressure-selectors';
import local from 'services/localization/local';
import './blood-pressure-visualization.scss';
import { routePaths } from 'services/routing';
import withNavigate from 'app/withNavigation';
import { bloodPressureGoalSaved } from 'app/request/request-actions';
import { bindActionCreators } from 'redux';
import BloodPressureLineChart from './BloodPressureLineChart';
import  HealthCardReading  from 'health/observations/HealthCardReadings/HealthCardReading';
import { ObservationType } from 'services/enums';

class BloodPressureVisualization extends React.Component {
  constructor(props) {
    super(props);
    this.onSetTarget = this.onSetTarget.bind(this);
  }

  componentDidMount()
  {
    const { actions } = this.props;
    actions.bloodPressureGoalSaved(false);
  }

  onSetTarget(){
    const fromPathway = this.props.fromPathway ? true : false;
    const routeParams = this.props.fromPathway &&  this.props.routeParams ? this.props.routeParams : '';
    const isFromCreateGoal = this.props.location?.state?.backpath?.isFromCreateGoal ?? false;
    this.props.navigate({ to: routePaths.bloodPressureGoal,state :{ fromPathway:fromPathway , routeParams : routeParams, isFromCreateGoal: isFromCreateGoal} });
  }


  render() {
    const { data, activeGoal,average } = this.props;
    const content = local.observation.bloodPressure;
    const buttonId = activeGoal === undefined ? 'add-BloodPressureTarget' : 'change-BloodPressureTarget';
    return (
      <div id="steps-visualization" className='mt-3'>
        <div>

          <div className='average-container'>
            {average.Average === '0/0'?   <div className='no-data-label'>{content.noData}</div> : <div className='average-value'>{`${average.Average}`} <span className='average-unit'>{content.unit}</span></div>}
            <div className='average-label'>  {content.averagePerWeek} </div>
          </div>
        </div>
        <div className="chart-container" ><BloodPressureLineChart data={data} /></div>
        <HealthCardReading
          minVal={average.MinValue}
          maxVal={average.MaxValue}
          observationType={ObservationType.Systolic}
          units={local.units.millimetersOfMercury}
          activeGoal={activeGoal}
          onClick={this.onSetTarget}
          buttonId={buttonId} />
      </div>
    );
  }
}

BloodPressureVisualization.propTypes = {
  // redux
  data: PropTypes.PropTypes.shape().isRequired,
  navigate: PropTypes.func.isRequired,
  activeGoal: PropTypes.objectOf(PropTypes.shape),
  actions: PropTypes.shape({
    bloodPressureGoalSaved: PropTypes.func.isRequired
  }).isRequired
};

function mapStateToProps(state, props) {
  return {
    data: getBloodPressureChartData(state, props),
    average:getBloodPressureAverage(state,props),
    activeGoal: getActiveGoal(state, props.profileId)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { bloodPressureGoalSaved };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default withNavigate(withContext(connect(mapStateToProps,mapDispatchToProps)(BloodPressureVisualization)));
