import React from 'react';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import { SmallError, Loading, ProfileLink, TextAction } from 'shared';

class DashboardTile extends React.Component {
  constructor(props) {
    super(props);
  }

  renderBody() {
    const { loading, error, errorMessage, children } = this.props;

    if (error) {
      return (
        <SmallError text={errorMessage} />
      );
    } else if (loading) {
      return (
        <Loading />
      );
    } else {
      return children;
    }
  }
  render() {
    const { id, action, path } = this.props;

    if (path && path.to) {
      return (
        <ProfileLink
          id={id}
          to={path.to}
          params={path.params}
          className='dashboard-tile'>
          {this.renderBody()}
        </ProfileLink>
      );
    } else if (action) {
      return (
        <TextAction
          id={id}
          className='dashboard-tile'
          onClick={action}>
          {this.renderBody()}
        </TextAction>
      );
    } else {
      return (
        <div
          id={id}
          className='dashboard-tile'>
          {this.renderBody()}
        </div>
      );
    }
  }
}

DashboardTile.propTypes = {
  id: PropTypes.string,
  action: PropTypes.func,
  path: PropTypes.shape(),
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  children: PropTypes.node
};
DashboardTile.defaultProps = {
  id: 'dashboard-tile',
  errorMessage: local.error
};

export default DashboardTile;
