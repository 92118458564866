import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { TextField, DateTimeTextFieldWrapper } from 'shared/form-fields';
import { Button } from 'shared';
import * as validators from 'services/fields/field-validators';
import local from 'services/localization/local';

class LogMedicationDoseForm extends React.Component {
  render() {
    const { submitting, saving, handleSubmit, handleBack } = this.props;
    const isSubmitting = submitting || saving;

    return (
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <DateTimeTextFieldWrapper
            readOnly={isSubmitting}
            label={local.formTimestampLabel}
            name="recordedAt"
            validate={[validators.required, validators.dateInPast]} />
        </div>

        <div className="form-row">
          <Field
            readOnly={isSubmitting}
            label={local.medicationDoseFormlDoseLabel}
            name="dosage"
            component={TextField}
            validate={[validators.maxLength250]}
            placeholder={local.medicationDoseFormlDosePlaceholder} />
        </div>

        <div className="form-row">
          <Field
            readOnly={isSubmitting}
            label={local.formNotesLabel}
            name="notes"
            component={TextField}
            validate={[validators.maxLength500]}
            placeholder={local.medicationFormNotesPlaceholder} />
        </div>
        <br />
        <br />
        <div className="form-row">
          <Button id="medication-form-cancel" className="btn btn-secondary" onClick={handleBack}>{local.formCancel}</Button>
          <Button
            id="medicaiton-form-submit"
            className="btn btn-primary"
            submit
            color="blue">
            {local.formSave}
          </Button>
        </div>
      </form>
    );
  }
}

LogMedicationDoseForm.propTypes = {
  handleBack: PropTypes.func.isRequired,
  // redux
  saving: PropTypes.bool.isRequired,
  // redux form
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool
};

const form = reduxForm({
  form: 'log-medication-dose',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(LogMedicationDoseForm);

function mapStateToProps(state) {
  return {
    saving: state.medicationDose.saving
  };
}

export default connect(mapStateToProps, null)(form);
