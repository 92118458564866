import { getUniqueId } from 'services/helpers';
import * as toastActions from 'app/toaster/actions';
import { HIPAA_CONSENT_ERROR_CODE } from 'services/api/api';
import * as healthActions from 'health/actions';

export const actionNames = (type) => ({
  REQUEST_QUEUED: `REQUEST_QUEUED_${type.toUpperCase()}`,
  REQUEST_QUEUE_SUCCESS: `REQUEST_QUEUE_SUCCESS_${type.toUpperCase()}`,
  REQUEST_QUEUE_ERROR: `REQUEST_QUEUE_ERROR_${type.toUpperCase()}`,
  REQUEST_QUEUE_REMOVE: `REQUEST_QUEUE_REMOVE_${type.toUpperCase()}`
});

export function requestInitiated(type, requestId, params) {
  return {
    type: actionNames(type).REQUEST_QUEUED,
    payload: {
      type,
      requestId,
      params
    }
  };
}

export function requestSuccess(type, requestId, result, params) {
  return {
    type: actionNames(type).REQUEST_QUEUE_SUCCESS,
    payload: {
      type,
      requestId,
      result,
      params
    }
  };
}

export function requestError(type, requestId, error, params) {
  return {
    type: actionNames(type).REQUEST_QUEUE_ERROR,
    payload: {
      type,
      requestId,
      error,
      params
    }
  };
}

export function clearRequest(type, requestId) {
  return {
    type: actionNames(type).REQUEST_QUEUE_REMOVE,
    payload: {
      type,
      requestId
    }
  };
}

// Use request-actions instead when possible, request-actions manages it's own reducer
export function queueRequest(method, type, customRequestId = null, params = {}) {
  return (dispatch, getState) => {
    const requestId = customRequestId || getUniqueId();

    dispatch(requestInitiated(type, requestId, params));

    const onSuccess = result => {
      dispatch(requestSuccess(type, requestId, result, params));
    };

    const onError = error => {
      dispatch(requestError(type, requestId, error, params));
    };

    return method(getState()).then(onSuccess).catch(onError);
  };
}

// Use request-actions instead when possible, request-actions manages it's own reducer
export function queueRequestWithToast(method, type, customRequestId = null, toastMessages = {}, params = {}) {
  return (dispatch, getState) => {
    const requestId = customRequestId || getUniqueId();

    dispatch(requestInitiated(type, requestId, params));
    dispatch(toastActions.addProcessingToast(requestId, toastMessages.processingMessage));

    const onSuccess = result => {
      dispatch(requestSuccess(type, requestId, result, params));
      dispatch(toastActions.completeToastWithSuccess(requestId, toastMessages.successMessage));
    };

    const onError = error => {
      if (error && error.status === HIPAA_CONSENT_ERROR_CODE && params.profileId) {
        const retryAction = () => queueRequestWithToast(method, type, requestId, toastMessages, params);
        dispatch(toastActions.removeToast(requestId));
        dispatch(healthActions.requireHipaaConsent(params.profileId, error.body.code, retryAction));
      } else {
        dispatch(toastActions.completeToastWithError(requestId, toastMessages.errorMessage));
      }

      dispatch(requestError(type, requestId, error, params));
    };

    return method(getState()).then(onSuccess).catch(onError);
  };
}
