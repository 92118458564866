import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withContext from 'app/withContext';
import { routePaths } from 'services/routing';
import local from 'services/localization/local';
import { NavAction, DataLoadingStatus, ProfileDataLoader } from 'shared';
import ProfilePage from 'profiles/page/ProfilePage';
import { loadActivities } from 'activity/activity-actions';
import { isActivitiesLoading, isActivitiesLoadError } from 'activity/activity-selectors';
import ActivityList from './ActivityList';
import ActivityVisualizationContainer from './ActivityVisualizationContainer';
import './activity-overview.scss';

class ActivityOverview extends React.Component {
  constructor(props) {
    super(props);
    this.loadCurrentProfileActivities = this.loadCurrentProfileActivities.bind(this);
  }

  loadCurrentProfileActivities() {
    const { actions, profileId } = this.props;
    actions.loadActivities({ profileId });
  }

  render() {
    const { loading, error, location } = this.props;
    const backPath = location?.state?.backpath ?? routePaths.health;

    return (
      <ProfilePage
        id='activities'
        centerElement={local.listActivityHeader}
        backPath={{ to: backPath }}
        primaryAction={<NavAction text={local.add} backpath={backPath} iconSymbol="add" to={routePaths.healthActivityAdd} />}>
        <ProfileDataLoader loadData={this.loadCurrentProfileActivities} />
        <ActivityVisualizationContainer hide={loading || error} />
        <DataLoadingStatus loading={loading} error={error}>
          <ActivityList backpath={backPath} />
        </DataLoadingStatus>
      </ProfilePage>
    );
  }
}

ActivityOverview.propTypes = {
  // redux
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  profileId: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    loadActivities: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape()
};

function mapStateToProps(state, props) {
  return {
    loading: isActivitiesLoading(state, props.profileId),
    error: isActivitiesLoadError(state, props.profileId)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { loadActivities };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default withContext(connect(mapStateToProps, mapDispatchToProps)(ActivityOverview));
