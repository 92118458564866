import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import local from 'services/localization/local';
import withContext from 'app/withContext';
import { Button } from 'shared';
import * as selectors from 'programs/programs-selectors';
import { enrollInProgram } from 'programs/programs-actions';
import { getUniqueId } from 'services/helpers';
import './program-enrollment.scss';

class ProgramEnrollButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleEnroll = this.handleEnroll.bind(this);

    this.state = {
      requestId: getUniqueId()
    };
  }

  handleEnroll(e) {
    e.preventDefault();

    const { requestId } = this.state;
    const { programInfoId, profileId, organizationId, actions } = this.props;
    actions.enrollInProgram({ profileId, programInfoId, organizationId }, requestId);
  }

  render() {
    const {
      isEnrolled,
      isEnrolling,
      id,
      className
    } = this.props;
    const disableEnrollButton = isEnrolled || isEnrolling;
    const buttonId = id || 'program-enroll-button';

    return (
      <Button
        id={buttonId}
        color="teal"
        onClick={this.handleEnroll}
        className={className}
        disabled={disableEnrollButton}>
        {local.programs.enrollButton}
      </Button>
    );
  }
}

ProgramEnrollButton.propTypes = {
  // redux
  profileId: PropTypes.string.isRequired,
  programInfoId: PropTypes.string.isRequired,
  organizationId: PropTypes.string,
  isEnrolled: PropTypes.bool,
  isEnrolling: PropTypes.bool.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  // actions
  actions: PropTypes.shape({
    enrollInProgram: PropTypes.func.isRequired
  }).isRequired
};

function mapStateToProps(state, { programInfoId }) {
  return {
    isEnrolled: !!selectors.getActiveEnrollmentForProgramInfoId(state, programInfoId),
    isEnrolling: selectors.isEnrolling(state, programInfoId)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { enrollInProgram };
  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

export default withContext(connect(mapStateToProps, mapDispatchToProps)(ProgramEnrollButton));
