import React,{ useEffect } from 'react';
import local from 'services/localization/local';
import { Button, Icon, NavIcon } from 'shared';
import { Medication} from 'shared/fullColorIcons';
import { useSelector, useDispatch } from 'react-redux';
import { getMedicationNotificationData,updateMedicationNotification} from 'health/medication/dose/action-creators';
import { getProfileId } from 'app/selectors';
import { getDoseStatus,getTodayMedicationOverdueAndTakeNowDoses } from 'health/medication/dose/selectors';
import moment from 'moment';
import './MedicationReminderV1.scss';

export default function MedicationReminderV1() {
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const medications = useSelector(state =>getTodayMedicationOverdueAndTakeNowDoses(state));
  const takeAction ='Take';
  useEffect(() => {
    if(profileId)
    {
      const today = moment();
      const formattedDate = today.format('MM/DD/YYYY');
      dispatch(getMedicationNotificationData(profileId,formattedDate));
    }
  },[profileId]);

  const handleData = (data) =>
  {
    let scheduleLogs = [];
    scheduleLogs.push(data);
    const medicationNotification={
      profileId:profileId,
      scheduleAction:takeAction,
      scheduledTime: data && data.scheduledAt && moment(data.scheduledAt,'hh:mm A').format(),
      scheduleLogs:scheduleLogs
    };
    dispatch(updateMedicationNotification(medicationNotification));
  };

  const renderMedicationReminders = (x) => {
    let doseStatus =  getDoseStatus(x);
    let isOverdue = doseStatus && doseStatus.length > 0 ? doseStatus[0] : false;
    let isTakenow = doseStatus && doseStatus.length >= 3 ? doseStatus[2] : false;
    return (
      <div className="scrollview-list" key={`${'card-'} ${x.id}`}>
        <div className="time-of-day">
          <div className="med-detail">
            <div className='med-visual'><NavIcon Icon={Medication} /></div>
            <div className="med-status">
              <span className="medicine-name">{x.name} </span>
              <span className={isOverdue? 'overdue-label med-status-timing' : 'med-status-timing'}>
                {isOverdue && <Icon symbol="alarm"></Icon>}{moment(x.scheduledAt).format('hh:mm A')} { x.dosage ? `| ${x.dosage}` : '' }
              </span>
            </div>
          </div>
          <div className='medication-action'>
            {(isTakenow || isOverdue) && <Button id="btnTookIt" className="btn btn-primary small-btn" onClick={() => handleData(x)} >{local.medication_v1Dashboard.tookItTitle}</Button>}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="day-container">
      { medications && medications.map(x =>
        (renderMedicationReminders(x)))}
    </div>
  );
}
