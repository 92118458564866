import * as dataSelectors from 'app/request/persistent-data-selectors';
import * as requestQueueSelectors from 'app/request/request-queue-selectors';
import { types } from './journal-types';

export const isJournalEntriesLoading = (state, profileId) => {
  return !!dataSelectors.isLoading(state.journal, types.JOURNALENTRIES, profileId);
};

export const isJournalEntriesLoadError = (state, profileId) => {
  return !!dataSelectors.hasError(state.journal, types.JOURNALENTRIES, profileId);
};

export const getJournalEntries = (state, profileId) => {
  const journalEntries = dataSelectors.getData(state.journal, types.JOURNALENTRIES, profileId) || [];
  return journalEntries.filter(x => x.profileId === profileId && !x.deleted);
};

export const getJournalRequest = (state, requestId) => {
  return requestQueueSelectors.getRequestById(state.journal, types.JOURNALENTRY_REQUESTS, requestId);
};
