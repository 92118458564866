import React, {useState} from 'react';
import { ModalWrapper, Button, Icon } from 'shared';
import local from 'services/localization/local';
import '../details/program-action-sheet.scss';
import { Redirect } from 'react-router-dom';

export default function ProgramSubsriptionLink(props) {
  const [redirect, setRedirect] = useState(false);

  function redirectToSubscription(){
    if(props.isPathway){
      window.location.href = '/view-plan';
    }
    setRedirect(true);
  }
  
  return (
    <div>
      <ModalWrapper isOpen={props.isConfirmOpenModel} onRequestClose={props.closeModel}>
        <div id="quit-pathway-modal" className="modal-container">
          <div className="modal-header">
            <Button id="close-quit-pathway-button" onClick={props.closeModel} data-testid='close-quit-pathway-button'><Icon symbol="times" /></Button>
            <p></p>
          </div>
          <div className="modal-body">
            <div className="content">
              <h4>{props.content}</h4>
            </div>
          </div>
          <div className="modal-footer">
            <Button id='cancel-quit-pathway-button' data-testid='cancel-quit-pathway-button' onClick={props.closeModel}>{local.cancel}</Button>
            <Button id='confirm-quit-pathway-button' data-testid='confirm-quit-pathway-button' onClick={redirectToSubscription}>Upgrade</Button>
          </div>
        </div>
      </ModalWrapper>
      {redirect ? <Redirect to='/view-plan' /> : null}
    </div>
  );
}
