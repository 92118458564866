import React from 'react';
import PropTypes from 'prop-types';
import SubLabelSection from 'shared/form-fields/SubLabelSection';
import './form-fields.scss';

class TextField extends React.Component {
  constructor(props) {
    super(props);
    this.getValue = this.getValue.bind(this);
    this.getHasError = this.getHasError.bind(this);
    this.getStep = this.getStep.bind(this);
  }

  getValue() {
    const { input } = this.props;
    return input.value || input.value === 0 ? input.value : '';
  }

  getHasError() {
    const { meta: { error, touched } } = this.props;
    return error && touched;
  }

  getStep() {
    const { type } = this.props;
    if (type === 'number') {
      return 'any';
    }
    return undefined;
  }

  render() {
    const { children, label, subLabel, input, meta: { error }, forceShowError, ...rest } = this.props;
    const hasError = this.getHasError();
    const value = this.getValue();
    const step = this.getStep();
    const id = `${input.name}-input`;

    return (
      <div className="formField">
        {label && <label className='form-label' htmlFor={id}>{label}</label>}
        <input
          className={hasError || forceShowError ? 'inputError form-control' : 'form-control'}
          id={id}
          {...rest}
          {...input}
          value={value}
          placeholder={label}
          step={step} />
        {children}
        <SubLabelSection showError={hasError} error={error} subLabel={subLabel} forceShowError={forceShowError} />
      </div>

    );
  }
}

TextField.displayName = 'TextField';
TextField.propTypes = {
  type: PropTypes.oneOf(['text', 'tel', 'number', 'date', 'datetime-local', 'password', 'email','time'])
};

TextField.defaultProps = {
  type: 'text'
};

export default TextField;
