import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'shared';
import { AlertSeverityStatus } from 'services/enums';

export default function PublicHealthBadgeIcon({ alertSeverityStatus }) {
  let icon;
  if (alertSeverityStatus === AlertSeverityStatus.Unknown) {
    icon = (<Icon symbol='unknown' />);
  } else if (alertSeverityStatus === AlertSeverityStatus.Verified) {
    icon = (<Icon symbol='verified' />);
  } else {
    icon = (<Icon symbol='notverified' />);
  }

  return icon;
}

PublicHealthBadgeIcon.propTypes = {
  alertSeverityStatus: PropTypes.number
};

