import React, { useState } from 'react';
import { Icon } from 'shared';

// Sample data
const data = [
  {
    source: 'MediTech Expanse',
    Date: '11-03-2024',
    Action: 'View/Close',
    childData: [
      {
        Test: 'Blood Test',
        DateTime: '11-03-2024 09:00 AM',
        Result: '9.5 k/mm3',
        PerformingLocation: 'Canton Medical center'
      },
      {
        Test: 'Hemoglobin',
        DateTime: '11-03-2024 09:00 AM',
        Result: '4.5 M/mm3',
        PerformingLocation: 'Canton Medical center'
      }
    ]
  },
  {
    source: 'MediTech Expanse',
    Date: '11-10-2024',
    Action: 'View/Close',
    childData: [
      {
        Test: 'Blood Test',
        DateTime: '11-10-2024 09:00 AM',
        Result: '9.5 k/mm3',
        PerformingLocation: 'Canton Medical center'
      },
      {
        Test: 'Hemoglobin',
        DateTime: '11-10-2024 09:00 AM',
        Result: '4.5 M/mm3',
        PerformingLocation: 'Canton Medical center'
      }
    ]
  }
];

function MedicalDataTable() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleActionClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div>
      <table border="1" className='table table-striped mt-3 mb-0'>
        <thead >
          <tr>
            <th><b>Source</b></th>
            <th><b>Date</b></th>
            <th><b>Action</b></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <tr>
                <td>{item.source}</td>
                <td>{item.Date}</td>
                <td>
                  <a className="viewall-link" onClick={() => handleActionClick(index)}>{expandedIndex === index ? 'Close' : 'View'}<Icon symbol="arrowdown" /></a>
                </td>
              </tr>
              {expandedIndex === index && (
                <tr>
                  <td colSpan="3">
                    <table border="1" className='table table-striped mt-3 mb-0'>
                      <thead>
                        <tr>
                          <th><b>Test</b></th>
                          <th><b>DateTime</b></th>
                          <th><b>Result</b></th>
                          <th><b>Performing Site</b></th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.childData.map((child, childIndex) => (
                          <tr key={childIndex}>
                            <td>{child.Test}</td>
                            <td>{child.DateTime}</td>
                            <td>{child.Result}</td>
                            <td>{child.PerformingLocation}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MedicalDataTable;
