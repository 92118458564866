import * as actions from './action-names';

export function saveMedicationDose() {
  return {
    type: actions.MEDICATION_DOSE_SAVING
  };
}

export function saveMedicationDoseSuccess(medicationDose) {
  return {
    type: actions.MEDICATION_DOSE_SAVE_SUCCESS,
    payload: medicationDose
  };
}

export function saveMedicationDoseError() {
  return {
    type: actions.MEDICATION_DOSE_SAVE_ERROR
  };
}

export function saveMedicationDoseReset() {
  return {
    type: actions.MEDICATION_DOSE_SAVE_RESET
  };
}

export function loadMedicationDoses() {
  return {
    type: actions.MEDICATION_DOSE_LOADING
  };
}

export function loadMedicationDosesSuccess(medicationDoses) {
  return {
    type: actions.MEDICATION_DOSE_LOAD_SUCCESS,
    payload: medicationDoses
  };
}

export function loadMedicationDosesError() {
  return {
    type: actions.MEDICATION_DOSE_LOAD_ERROR
  };
}

export function doseFilterUpdateMedicationId(medicationId) {
  return {
    type: actions.MEDICATION_DOSE_FILTER_UPDATE_MEDICATIONID,
    payload: medicationId
  };
}

export function doseFilterUpdateDate(date) {
  return {
    type: actions.MEDICATION_DOSE_FILTER_UPDATE_DATE,
    payload: date
  };
}

export function doseFilterResetMedicationId() {
  return {
    type: actions.MEDICATION_DOSE_FILTER_RESET_MEDICATIONID
  };
}

export function doseFilterReset() {
  return {
    type: actions.MEDICATION_DOSE_FILTER_RESET
  };
}

export function doseDelete(medicationDoseId) {
  return {
    type: actions.MEDICATION_DOSE_DELETE,
    payload: medicationDoseId
  };
}

export function doseDeleteUndo(medicationDoseId) {
  return {
    type: actions.MEDICATION_DOSE_DELETE_UNDO,
    payload: medicationDoseId
  };
}

export function loadMedicationNotification() {
  return {
    type: actions.MEDICATION_NOTIFICATION_LOADING
  };
}

export function loadMedicationNotificationSuccess(medicationNotificationDoses) {
  return {
    type: actions.MEDICATION_NOTIFICATION_LOAD_SUCCESS,
    payload: medicationNotificationDoses
  };
}

export function loadMedicationNotificationError() {
  return {
    type: actions.MEDICATION_NOTIFICATION_LOAD_ERROR
  };
}
export function updateMedicationNotification() {
  return {
    type: actions.MEDICATION_NOTIFICATION_UPDATING
  };
}
export function updateMedicationNotificationSuccess(medicationNotificationAction) {
  return {
    type: actions.MEDICATION_NOTIFICATION_UPDATE_SUCCESS,
    payload: medicationNotificationAction
  };
}
export function updateMedicationNotificationError() {
  return {
    type: actions.MEDICATION_NOTIFICATION_UPDATE_ERROR
  };
}

export function updateMedicationNotificationDose(medicationNotification) {
  return {
    type: actions.MEDICATION_NOTIFICATION_DOSE_UPDATE_SUCCESS,
    payload: {
      medicationNotification
    }
  };
}
