export const MEDICATION_DOSE_SAVING = 'MEDICATION_DOSE_SAVING';
export const MEDICATION_DOSE_SAVE_SUCCESS = 'MEDICATION_DOSE_SAVE_SUCCESS';
export const MEDICATION_DOSE_SAVE_ERROR = 'MEDICATION_DOSE_SAVE_ERROR';
export const MEDICATION_DOSE_SAVE_RESET = 'MEDICATION_DOSE_SAVE_RESET';

export const MEDICATION_DOSE_LOADING = 'MEDICATION_DOSE_LOADING';
export const MEDICATION_DOSE_LOAD_SUCCESS = 'MEDICATION_DOSE_LOAD_SUCCESS';
export const MEDICATION_DOSE_LOAD_ERROR = 'MEDICATION_DOSE_LOAD_ERROR';

export const MEDICATION_DOSE_FILTER_UPDATE_MEDICATIONID = 'MEDICATION_DOSE_FILTER_UPDATE_MEDICATIONID';
export const MEDICATION_DOSE_FILTER_UPDATE_DATE = 'MEDICATION_DOSE_FILTER_UPDATE_DATE';
export const MEDICATION_DOSE_FILTER_RESET_MEDICATIONID = 'MEDICATION_DOSE_FILTER_RESET_MEDICATIONID';
export const MEDICATION_DOSE_FILTER_RESET = 'MEDICATION_DOSE_FILTER_RESET';

export const MEDICATION_DOSE_DELETE = 'MEDICATION_DOSE_DELETE';
export const MEDICATION_DOSE_DELETE_UNDO = 'MEDICATION_DOSE_DELETE_UNDO';

export const MEDICATION_NOTIFICATION_LOADING = 'MEDICATION_NOTIFICATION_LOADING';
export const MEDICATION_NOTIFICATION_LOAD_SUCCESS = 'MEDICATION_NOTIFICATION_LOAD_SUCCESS';
export const MEDICATION_NOTIFICATION_LOAD_ERROR = 'MEDICATION_NOTIFICATION_LOAD_ERROR';

export const MEDICATION_NOTIFICATION_UPDATING = 'MEDICATION_NOTIFICATION_UPDATING';
export const MEDICATION_NOTIFICATION_UPDATE_SUCCESS = 'MEDICATION_NOTIFICATION_UPDATE_SUCCESS';
export const MEDICATION_NOTIFICATION_UPDATE_ERROR = 'MEDICATION_NOTIFICATION_UPDATE_ERROR';
export const MEDICATION_NOTIFICATION_DOSE_UPDATE_SUCCESS ='MEDICATION_NOTIFICATION_DOSE_UPDATE_SUCCESS';
