import React, { useState,useEffect } from 'react';
import { Button,Page,LoadingOverlay } from 'shared';
import { useHistory } from 'react-router-dom';
import { sendUpdateEmail } from 'preferences/changeEmail/action-creators';
import { useDispatch ,useSelector,connect } from 'react-redux';
import { routePaths } from 'services/routing';
import local from 'services/localization/local';
import { Field,reduxForm } from 'redux-form';
import { TextField } from 'shared/form-fields';
import * as validators from 'services/fields/field-validators';
import './verified-change-email.scss';

function ChangeEmail() {
  const history = useHistory();
  const dispatch = useDispatch();
  const newEmail = useSelector(state=>state.preferences.newEmail);
  const [email, setEmail] = useState(newEmail);
  const changeEmailError = useSelector(state => state.preferences.changeEmailError);
  const [handleClicked, setHandleClicked]= useState(false);
  const [showSpinner,setShowSpinner] = useState(false);

  useEffect(() => {
    if(changeEmailError==undefined &&  handleClicked)
    {
      setHandleClicked(false);
      history.push(routePaths.verifiedChangeEmail);
    }
    setShowSpinner(false);
  },[changeEmailError]);

  const handleSubmit = () =>{
    const emailRegex = /.+@.+/;
    if(email)
    {
      if(email.match(emailRegex))
      {
        setHandleClicked(true);
        setShowSpinner(true);
        dispatch(sendUpdateEmail(email));
      }
    }
  };

  return (
    <Page
      id="preferences-selection-component" data-testid="changeEmail"
      centerElement={local.accountSettings.pageTitle}
      backPath={{ to: routePaths.preferences }}>
      {showSpinner && <LoadingOverlay />}
      <br />
      <form>
        <div className="form-row">
          <Field
            data-testid='new-email'
            label={local.accountSettings.validationEmail}
            autoFocus
            name="initialEmail"
            component={TextField}
            onChange={e => setEmail(e.target.value)}
            validate={[validators.required, validators.email]} />
        </div>
        {changeEmailError &&
        <React.Fragment>
          <small className="error">{changeEmailError}</small>
          <br />
          <br />
        </React.Fragment>}

        <small className="infoText">{local.accountSettings.changeEmailMessage}</small>
        <br />
        <div className="accountUpdateBtn">
          <Button
            id="change-email"
            className="btn btn-primary"
            onClick={handleSubmit}
            color="blue">
            {local.accountSettings.verifyEmail}
          </Button>
        </div>
      </form>
    </Page>
  );
}

const form = reduxForm({
  form: 'change-email',
  enableReinitialize: true
})(ChangeEmail);

function mapStateToProps(state) {
  return {
    initialValues:{
      initialEmail: state.preferences.newEmail
    }
  };
}

export default connect(mapStateToProps, null)(form);
