import React from 'react';
import PropTypes from 'prop-types';
import './float-action-menu.scss';

export default function FloatActionMenu(props) {
  const { children } = props;

  if (!children) {
    return null;
  }

  return (
    <div className="float-action-menu">
      {children}
    </div>
  );
}

FloatActionMenu.propTypes = {
  children: PropTypes.node
};
