import React from 'react';
import PropTypes from 'prop-types';
import { Icon, ProfileLink } from 'shared';
import moment from 'moment';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { getColorByGuid } from 'services/helpers';

class MedicationListItem extends React.Component {
  constructor(props) {
    super(props);
  }

  buildMedicationNotes() {
    const { medication } = this.props;
    if (medication.notes) {
      return <span className="medication-notes">{medication.notes}</span>;
    }
  }

  buildDoseMessage() {
    const { medication } = this.props;
    if (medication.lastDoseTime) {
      return (
        <span className="medication-last-dose sub">
          <Icon symbol="history" size="1.2em" />

          <span>{local.formatString(local.medicationMostRecentDose, medication.lastDoseAmount || '', moment(medication.lastDoseTime).fromNow())}</span>
        </span>
      );
    }

    if (medication.typicalDose) {
      return (
        <span className="medication-typical-dose sub">{local.medicationFormTypicalDose}

          {medication.typicalDose}
        </span>
      );
    }

    return null;
  }

  render() {
    const { medication } = this.props;
    const showActions = medication.id;
    const params = { medicationId: medication.id };

    return (
      <li className="card-list-item medication mtop40">
        <div className="medication-info">
          <figure className="medication-image" style={{ backgroundColor: getColorByGuid(medication.id) }}>
            <Icon symbol="medications" size="60%" />
          </figure>

          <div className="medication-text">
            <span className="medication-title">{medication.name}</span>

            {this.buildDoseMessage()}
          </div>
        </div>

        {this.buildMedicationNotes()}

        <div className="medication-action">
          {showActions ?
            <ProfileLink id={`add-medication-dose-${medication.id}`} logName="add-medication-dose" to={routePaths.healthMedicationAddDose} params={params}>
              <span>{local.medicationDosageCallToAction}</span>
            </ProfileLink> : null}
        </div>

        <div className="more-actions">
          {showActions ?
            <ProfileLink id={`edit-medication-link-${medication.id}`} logName="edit-medication-link" to={routePaths.healthMedicationEdit} params={params}>
              <Icon symbol="edit" size="2em" data-testid={`edit-medication-${medication.id}`} />
            </ProfileLink> : null}
        </div>
      </li>
    );
  }
}

MedicationListItem.propTypes = {
  medication: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    typicalDose: PropTypes.string,
    notes: PropTypes.string,
    timestamp: PropTypes.string,
    lastDoseTime: PropTypes.string,
    lastDoseAmount:PropTypes.string
  }).isRequired
};

export default MedicationListItem;
