import React, { useEffect } from 'react';
import { Loading, NoDataPlaceholder, Page } from 'shared';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import './procedures.scss';
import { useDispatch, useSelector } from 'react-redux';
import ProcedureListItem from './ProcedureListItem';
import { getProfileId } from 'app/selectors';
import { types } from 'health/health-types';
import { loadProcedures, resetUnreadMedicalRecordsCount } from 'health/action-creators';
import PropTypes from 'prop-types';
import { getHealthRecords, getUnreadMedicalRecordsCount } from 'health/health-selectors';
import { FhirResourceTypes } from 'services/enums';

export default function ProceduresList(props) {
  const content = local.healthData.procedures;
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const { data, loading, hasError } = useSelector(state => getHealthRecords(state, profileId, types.PROCEDURES));
  const unreadMedicalRecordsCount = useSelector(state => getUnreadMedicalRecordsCount(state, profileId, FhirResourceTypes.Procedure));

  useEffect(()=>{
    dispatch(loadProcedures(profileId));
  },[profileId]);

  useEffect(()=>{
    if(unreadMedicalRecordsCount > 0)
      dispatch(resetUnreadMedicalRecordsCount(profileId, FhirResourceTypes.Procedure));
  },[profileId, unreadMedicalRecordsCount]);

  const renderItems = () => {
    if(loading){
      return(<Loading></Loading>);
    }
    if(!data || data?.length === 0 || hasError){
      return(<NoDataPlaceholder>{content.noRecords}</NoDataPlaceholder>);
    }
    return (data && data.length > 0 &&
      data.map((x) => <ProcedureListItem key={x.remoteId} procedure={x}></ProcedureListItem>)
    );
  };

  return (
    <Page
      id='procedures-list'
      backPath={{to:props.location?.state?.backpath ?? routePaths.health}}
      centerElement={content.title}>
      <ul className='card-list'>
        {renderItems()}
      </ul>
    </Page>
  );
}

ProceduresList.propTypes = {
  location: PropTypes.shape()
};
