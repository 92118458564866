import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { buildPath, routePaths } from 'services/routing';
import { SmallError, Loading, LoadingIcon, LinkWrapper } from 'shared';
import local from 'services/localization/local';
import { getProfileId } from 'app/selectors';
import * as selectors from 'programs/programs-selectors';
import { loadProgramDetails } from 'programs/programs-actions';
import ProgramProgressBar from 'programs/details/ProgramProgressBar';
import ProgramDashboardTileDetails from './ProgramDashboardTileDetails';
import ProgramDashboardTileAction from './ProgramDashboardTileAction';
import PublicHealthBadgeModal from 'health/public-health-badge/PublicHealthBadgeModal';
import ProgramUpgradeModal from 'programs/enrollment/ProgramUpgradeModal';

export default function ProgramDashboardTile(props) {
  const { programEnrollmentId, primary } = props;

  const dispatch = useDispatch();

  const profileId = useSelector(state => getProfileId(state));
  const { loaded, loading, hasError } = useSelector(state => selectors.getProgramDetailsStatus(state, programEnrollmentId));
  const enrollment = useSelector(state => selectors.getProgramEnrollmentById(state, programEnrollmentId));
  const progress = useSelector(state => selectors.getProgramDetails(state, programEnrollmentId));
  const nextTask = useSelector(state => selectors.getNextTask(state, programEnrollmentId));
  const showLoading = !progress;
  const [retriesSeconds, setRetriesSeconds] = useState(1);

  const doLoadProgramDetails = () => dispatch(loadProgramDetails(programEnrollmentId));
  useEffect(() => {
    doLoadProgramDetails();
  }, [programEnrollmentId]);

  useEffect(() => {
    if (!loading && loaded && progress.processingTasks) {
      setRetriesSeconds(retriesSeconds * 2);
      const timeoutId = setTimeout(doLoadProgramDetails, retriesSeconds * 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [programEnrollmentId, loading, loaded]);

  let body = null;
  if (hasError) {
    body = <SmallError text={local.error} />;
  } else if (showLoading) {
    body = <Loading />;
  } else {
    body = (
      <React.Fragment>
        <ProgramUpgradeModal programEnrollmentId={programEnrollmentId} />
        <div className="program-tile-details">
          {progress.canAlert && (<PublicHealthBadgeModal programEnrollmentId={programEnrollmentId} />)}
          <LinkWrapper
            id={`program-tile-details-${progress.programId}`}
            className="program-tile-details-link"
            logName='program-tile-details'
            to={buildPath(routePaths.programsDetails, { programId: progress.programId, programEnrollmentId })}
            backpath={{ fromDashboard: true }}
            params={{ profileId }}>
            <div className="program-title-section">
              {enrollment && enrollment.program.iconPath
                ? <img className="program-icon" src={enrollment.program.iconPath} />
                : <div className="program-icon no-image"></div>}
              <div className="program-name chart-title">
                {progress.name}
              </div>
            </div>
            <ProgramProgressBar progress={progress} />
            {(progress.processingTasks ?
              (<div className="loading-progress"><LoadingIcon /> <span>{local.loading}</span></div>) :
              (<ProgramDashboardTileDetails progress={progress} />)
            )}
          </LinkWrapper>
        </div>
        <ProgramDashboardTileAction
          progress={progress}
          nextTask={nextTask}
          profileId={profileId}
          programEnrollmentId={programEnrollmentId} />
      </React.Fragment>
    );
  }

  const primaryClass = primary ? 'primary-tile' : '';
  return (
    <div className={`program-dashboard-tile ${primaryClass}`}>
      {body}
    </div>
  );
}

ProgramDashboardTile.propTypes = {
  primary: PropTypes.bool,
  programEnrollmentId: PropTypes.string.isRequired
};
