import React from 'react';
import PropTypes from 'prop-types';
import DoseListSection from './DoseListSection';
import DoseListItem from './DoseListItem';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {updateMedicationNotification} from 'health/medication/dose/action-creators';

function DoseSection(props) {
  const { doses, loggedDoses } = props;
  loggedDoses.sort((d1, d2) => {
    let date1 = new Date(new Date(d1.takenAt ? d1.takenAt : d1.scheduledAt).setSeconds(0)).setMilliseconds(0).toString();
    let date2 = new Date(new Date(d2.takenAt ? d2.takenAt : d2.scheduledAt).setSeconds(0)).setMilliseconds(0).toString();
    const compareDate =date2.localeCompare(date1);
    const compareName = d1.name.localeCompare(d2.name);

    return compareDate ||  compareName;
  });
  return (
    <div>
      {doses.map(x => <DoseListSection UpdateDoseData={props.UpdateDoseData} key={x.category} dose={x} />)}

      <ul className="scrollview-list">
        {loggedDoses.map(x => <DoseListItem GetDoseData={props.GetDoseData} dose={x} key={x.id || 0} />)}
      </ul>
    </div>
  );
}

DoseSection.propTypes = {
  doses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loggedDoses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  UpdateDoseData:PropTypes.func,
  GetDoseData:PropTypes.func
};

function mapDispatchToProps(dispatch) {
  const actions = { updateMedicationNotification};
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(null,mapDispatchToProps)(DoseSection);
