import compileReducers from 'services/compile-reducer';
import {
  INTERVIEWS_SELECT,
  INTERVIEWS_SAVING,
  INTERVIEWS_ERROR
} from './interview-actions';

export const initialState = {
  saving: false,
  error: false
};

const reducers = {};

reducers[INTERVIEWS_SELECT] = (state, { interviewId }) => ({
  ...state,
  ...initialState,
  interviewId
});

reducers[INTERVIEWS_SAVING] = state => ({
  ...state,
  saving: true,
  error: false
});

reducers[INTERVIEWS_ERROR] = state => ({
  ...state,
  saving: false,
  error: true
});

export default compileReducers(reducers, initialState);
