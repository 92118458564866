/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { closeDrawerMenu } from 'app/layout/navigation/actions';
import { TextAction, Icon } from 'shared';
import local from 'services/localization/local';
import UserConsentModal from 'app/privacy-policy-eula/userConsent/UserConsentModal';

export function UserConsentNavLink() {
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);
  const openPrivacyPolicy = () => {
    setIsPrivacyPolicyOpen(true);
    closeDrawerMenu();
  };
  const closeModal = () => {
    setIsPrivacyPolicyOpen(false);
  };
  return(
    <div>
      <TextAction id="hipaa-text" className='nav-hipaa' onClick={openPrivacyPolicy}>
        <Icon symbol="hipaaauth" />
        <span>{local.userConsent}</span>
      </TextAction>
      <UserConsentModal isOpen={isPrivacyPolicyOpen} onRequestClose={closeModal} />
    </div>
  );

}
export default UserConsentNavLink;
