/* eslint-disable max-len */
import React from 'react';
import './svg-mark.scss';

class HcLogo extends React.Component {
  render() {
    return (
      <svg className="svg-mark" viewBox="0 0 180 180" xmlns="http://www.w3.org/2000/svg">
        <path d="m89.1 69.9h-0.1c-3-0.1-5.7-1.9-6.8-4.8l-15.3-39c-1.5-3.9 0.4-8.2 4.3-9.7s8.2 0.4 9.7 4.3l8.7 22.1 9.8-22.4c1.5-3.8 5.9-5.7 9.8-4.2s5.7 5.9 4.2 9.8c-0.1 0.1-0.1 0.3-0.2 0.4l-17.2 39c-1.2 2.7-3.9 4.5-6.9 4.5" className="teal" />
        <path d="m106.1 159.9c-3.1 0-5.9-1.9-7-4.8l-8.6-22.1-9.8 22.4c-1.5 3.9-5.8 5.8-9.7 4.4s-5.8-5.8-4.4-9.7c0.1-0.2 0.2-0.5 0.3-0.7l17.1-38.9c1.7-3.8 6.1-5.5 9.9-3.9 1.8 0.8 3.3 2.3 4 4.1l15.3 38.9c1.5 3.9-0.4 8.2-4.3 9.7-0.9 0.4-1.8 0.6-2.8 0.6" className="teal" />
        <path d="m71.3 78c-1 0-2.1-0.2-3-0.6l-38.4-16.8c-3.8-1.7-5.5-6.1-3.9-9.9 1.7-3.8 6.1-5.5 9.9-3.9l21.8 9.5-8.9-22.8c-1.5-3.9 0.4-8.2 4.3-9.7s8.2 0.4 9.7 4.3l15.5 39.6c1.5 3.9-0.4 8.2-4.3 9.7-0.9 0.4-1.8 0.6-2.7 0.6" className="magenta" />
        <path d="m124.1 152.4c-3.1 0-5.9-1.9-7-4.8l-15.4-39.6c-1.5-3.9 0.4-8.2 4.3-9.7 1.9-0.7 3.9-0.7 5.7 0.1l38.3 16.8c3.8 1.7 5.5 6.1 3.9 9.9-1.7 3.8-6.1 5.5-9.9 3.9l-21.8-9.5 8.9 22.8c1.5 3.9-0.4 8.2-4.3 9.7-0.8 0.3-1.7 0.4-2.7 0.4" className="magenta" />
        <path d="m25.5 111.6c-4.1 0-7.5-3.3-7.5-7.5 0-3.1 1.9-5.9 4.8-7l22.1-8.7-22.4-9.8c-3.7-1.8-5.3-6.2-3.6-10 1.7-3.6 5.9-5.2 9.6-3.8l39 17.1c3.8 1.7 5.5 6.1 3.8 9.9-0.8 1.8-2.3 3.3-4.2 4l-39 15.2c-0.8 0.4-1.7 0.6-2.6 0.6" className="blue" />
        <path d="m154.5 111.6c-1 0-2.1-0.2-3-0.6l-39-17c-3.8-1.7-5.5-6.1-3.8-9.9 0.8-1.8 2.3-3.2 4.1-4l38.9-15.2c3.9-1.5 8.2 0.4 9.7 4.3s-0.4 8.2-4.3 9.7l-22.1 8.6 22.4 9.8c3.8 1.7 5.5 6.1 3.9 9.9-1.1 2.6-3.8 4.4-6.8 4.4" className="blue" />
        <path d="m55.8 152.4c-4.1 0-7.5-3.4-7.5-7.5 0-1 0.2-2.1 0.6-3l9.5-21.8-22.7 8.9c-3.9 1.5-8.2-0.4-9.7-4.3s0.4-8.2 4.3-9.7l39.6-15.5c3.9-1.5 8.2 0.4 9.7 4.3 0.7 1.8 0.7 3.9-0.1 5.7l-16.8 38.3c-1.2 2.8-3.9 4.6-6.9 4.6" className="yellow" />
        <path d="m107.4 76.7c-4.1 0-7.5-3.4-7.5-7.5 0-1 0.2-2.1 0.6-3l16.8-38.3c1.7-3.8 6.1-5.5 9.9-3.9s5.5 6.1 3.9 9.9l-9.5 21.8 22.8-8.9c3.9-1.5 8.2 0.4 9.7 4.3s-0.4 8.2-4.3 9.7l-39.6 15.4c-0.9 0.3-1.8 0.5-2.8 0.5" className="yellow" />
        <path d="m78.7 71.4c0-0.3 0.1-0.6 0.1-0.8 0-0.3 0-0.5-0.1-0.8s0-0.5-0.1-0.7c-0.1-0.5-0.2-1-0.4-1.4-0.7-1.8-2.2-3.3-4-4.1l-16.7-7.2 6.8 16.9c0.9 2.3 2.9 3.9 5.2 4.5l0.4 0.1c0.4 0.1 0.9 0.1 1.3 0.1h0.1c0.4 0 0.8 0 1.2-0.1l0.4-0.1c0.3-0.1 0.6-0.2 0.9-0.3l0.4-0.1 1.2-0.6 0.1-0.1c0.3-0.2 0.6-0.5 0.9-0.7l0.3-0.3c0.2-0.2 0.4-0.5 0.6-0.8l0.2-0.3c0.2-0.4 0.5-0.7 0.6-1.2 0.2-0.5 0.3-0.9 0.4-1.4 0.1-0.1 0.1-0.4 0.2-0.6" className="magenta-dark" />
        <path d="m101.3 104.3c0 0.3-0.1 0.6-0.1 0.8 0 0.3 0 0.5 0.1 0.8s0 0.4 0.1 0.7c0.1 0.5 0.2 1 0.4 1.4 0.7 1.9 2.2 3.4 4 4.1l16.7 7.3-6.7-16.9c-0.8-1.9-2.3-3.4-4.2-4.2-0.3-0.1-0.7-0.2-1-0.3l-0.4-0.1c-0.4-0.1-0.9-0.1-1.3-0.1s-0.8 0-1.2 0.1l-0.4 0.1c-0.3 0.1-0.6 0.2-0.9 0.3l-0.4 0.1-1.2 0.6-0.1 0.1c-0.3 0.2-0.6 0.5-0.9 0.7l-0.3 0.3c-0.2 0.2-0.4 0.5-0.6 0.8l-0.2 0.3-0.6 1.2c-0.2 0.5-0.4 0.9-0.5 1.4 0 0.2 0 0.4-0.1 0.6" className="magenta-dark" />
        <path d="m70.3 84.2c-0.2-0.2-0.3-0.5-0.5-0.7s-0.4-0.3-0.6-0.5-0.3-0.3-0.5-0.4c-2.1-1.4-4.7-1.7-7-0.8l-16.9 6.7 16.7 7.2c2.2 1 4.8 0.8 6.9-0.5l0.3-0.2c0.4-0.2 0.7-0.5 1-0.8s0.6-0.6 0.8-1c0.1-0.1 0.1-0.2 0.2-0.3 0.2-0.3 0.3-0.6 0.5-0.8l0.1-0.3c0.2-0.4 0.3-0.8 0.4-1.3v-0.2c0.1-0.4 0.1-0.8 0.1-1.1v-0.4c0-0.3 0-0.7-0.1-1 0-0.1 0-0.2-0.1-0.3-0.1-0.4-0.2-0.9-0.4-1.3-0.2-0.5-0.4-0.9-0.7-1.3 0-0.4-0.2-0.5-0.2-0.7" className="blue-dark" />
        <path d="m109.6 91.5c0.2 0.2 0.3 0.5 0.5 0.7s0.4 0.4 0.6 0.5 0.3 0.3 0.5 0.4c0.4 0.3 0.8 0.5 1.3 0.7 1.8 0.8 3.9 0.8 5.7 0.1l17-6.6-16.7-7.2c-1.9-0.8-4-0.8-5.9 0-0.3 0.1-0.7 0.3-1 0.5l-0.3 0.2c-0.4 0.2-0.7 0.5-1 0.8s-0.6 0.6-0.8 0.9l-0.3 0.3c-0.2 0.3-0.3 0.6-0.5 0.8l-0.2 0.3c-0.2 0.4-0.3 0.8-0.4 1.3v0.2c-0.1 0.4-0.1 0.8-0.1 1.2v0.4c0 0.3 0 0.7 0.1 1 0 0.1 0 0.2 0.1 0.3 0.1 0.4 0.2 0.9 0.4 1.3 0.2 0.5 0.4 0.9 0.7 1.3 0 0.3 0.1 0.4 0.3 0.6" className="blue-dark" />
        <path d="m86.3 107.6c-0.2 0.2-0.5 0.3-0.7 0.5s-0.4 0.4-0.5 0.6c-0.2 0.2-0.3 0.3-0.4 0.5-1.4 2.1-1.7 4.7-0.8 7l6.7 16.9 7.2-16.7c0.8-1.9 0.8-4 0-5.9-0.1-0.3-0.3-0.7-0.5-1l-0.2-0.3c-0.3-0.4-0.5-0.7-0.8-1s-0.6-0.6-0.9-0.8l-0.3-0.2c-0.3-0.2-0.6-0.3-0.9-0.5l-0.3-0.2c-0.4-0.2-0.8-0.3-1.3-0.4h-0.2c-0.4-0.1-0.8-0.1-1.1-0.1h-0.4c-0.3 0-0.7 0-1 0.1h-0.4c-0.4 0.1-0.9 0.2-1.3 0.4-0.5 0.2-0.9 0.4-1.3 0.7-0.3 0.1-0.4 0.2-0.6 0.4" className="teal-dark" />
        <path d="m93.5 68.3c0.2-0.2 0.5-0.3 0.7-0.5s0.4-0.4 0.5-0.6c0.2-0.2 0.3-0.3 0.4-0.5 0.3-0.4 0.5-0.8 0.7-1.3 0.8-1.8 0.8-3.9 0.1-5.7l-6.6-17-7.2 16.7c-0.8 1.9-0.8 4 0 5.9 0.1 0.3 0.3 0.7 0.5 1 0.1 0.1 0.1 0.2 0.2 0.3 0.2 0.4 0.5 0.7 0.8 1s0.6 0.6 0.9 0.8l0.3 0.2c0.3 0.2 0.5 0.3 0.8 0.5l0.3 0.2c0.4 0.2 0.8 0.3 1.3 0.4h0.2c0.4 0.1 0.8 0.1 1.1 0.1h0.4c0.3 0 0.7 0 1-0.1h0.4c0.4-0.1 0.9-0.2 1.3-0.4 0.5-0.2 0.9-0.4 1.3-0.7 0.3 0 0.5-0.2 0.6-0.3" className="teal-dark" />
        <path d="m73.5 99.2c-0.3 0-0.6-0.1-0.9-0.1s-0.5 0-0.8 0.1-0.5 0-0.7 0.1c-0.5 0.1-1 0.2-1.4 0.4-1.9 0.7-3.3 2.2-4.1 4l-7.2 16.7 16.9-6.8c1.9-0.8 3.4-2.3 4.2-4.2 0.1-0.3 0.2-0.7 0.3-1l0.1-0.4c0.1-0.4 0.1-0.9 0.1-1.3s0-0.8-0.1-1.2c0-0.1-0.1-0.2-0.1-0.4l-0.3-0.9c0-0.1-0.1-0.2-0.1-0.3l-0.6-1.2-0.1-0.2c-0.2-0.3-0.5-0.6-0.7-0.9l-0.3-0.3c-0.2-0.2-0.5-0.4-0.8-0.6l-0.3-0.2c-0.4-0.2-0.8-0.4-1.1-0.6-0.5-0.2-0.9-0.3-1.4-0.4-0.2 0-0.4 0-0.6-0.1" className="yellow-dark" />
        <path d="m106.4 76.6c0.3 0 0.6 0.1 0.9 0.1s0.5 0 0.8-0.1 0.5 0 0.7-0.1c0.5-0.1 1-0.2 1.4-0.4 1.9-0.7 3.3-2.2 4.1-4l7.3-16.7-16.9 6.7c-1.9 0.7-3.4 2.3-4.2 4.2-0.1 0.3-0.2 0.7-0.3 1 0 0.1-0.1 0.2-0.1 0.4-0.1 0.4-0.1 0.9-0.1 1.3s0 0.8 0.1 1.2c0 0.1 0.1 0.2 0.1 0.4 0.1 0.3 0.1 0.6 0.3 0.9 0 0.1 0.1 0.2 0.1 0.3l0.6 1.2c0 0.1 0.1 0.1 0.1 0.1 0.2 0.3 0.5 0.6 0.7 0.9l0.3 0.3c0.2 0.2 0.5 0.4 0.8 0.6 0.1 0.1 0.2 0.1 0.3 0.2 0.4 0.2 0.7 0.5 1.1 0.6 0.5 0.2 0.9 0.3 1.4 0.5 0.2 0 0.4 0 0.6 0.1" className="yellow-dark" />
      </svg>
    );
  }
}

HcLogo.displayName = 'Calcium Logo';
HcLogo.propTypes = {};
HcLogo.defaultProps = {};

export default HcLogo;
