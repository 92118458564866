import React, { useEffect, useState } from 'react';
import { Button, Page, Icon, LinkWrapper } from 'shared';
import ObservationCard from './ObservationCard';
import local from 'services/localization/local';
import './observation-connect.scss';
import { GoalStatus, InvitedProgramId, ObservationConnectionStatus, ObservationType } from 'services/enums';
import { buildPath, routePaths } from 'services/routing';
import { ManuallyTrackedObservations, ObservationTypes, Source, Close, OpenApp, Manual, GoogleFitType, GoogleFitDisplayName, GoalCategoryId } from 'services/constants';
import { useSelector, useDispatch } from 'react-redux';
import { getProfileId } from 'app/selectors';
import { SaveObservationConnections, clearObservationConnections } from './action-creators';
import { getAllDevicesFromValidic, getObservationConnections, helper } from './selectors';
import { getLocalStorage, getUniqueId } from 'services/helpers';
import { withRouter } from 'react-router';
import withNavigate from 'app/withNavigation';
import { isIphone, closeRouteInApp, openMobileRoute, isGooglefitEnabled, isAndroid } from 'mobile/helpers';
import { loadObservationValidicProfile } from 'devices/devices-actions';
import { getActiveEnrollmentForProgram } from 'programs/programs-selectors';
import { loadProgramEnrollments } from 'programs/programs-actions';
import { getValidicUserId } from 'devices/devices-selectors';
import { loadGoalCategory, loadProfileGoals, SaveProfileGoals, checkGoalBasedEnrollment } from 'goal/goal-actions';
import AlertModal from './AlertModal';
import { getRequest } from 'app/request/request-selectors';
import { getGoalCategories, getProfileGoalsList, isGoalBasedEnrollment } from 'goal/goal-selectors';

function ProfileObservationConnect(props) {
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  let invitedProgramIdArray = getLocalStorage(InvitedProgramId) != 'null' ? [getLocalStorage(InvitedProgramId)] : null;
  let urlParams = new URLSearchParams(props.location?.search);
  let paramGoalCategoryId = urlParams?.get(GoalCategoryId);
  let goalCategoryId = null;
  if (props.location?.state?.backpath?.goalCategoryId) {
    goalCategoryId = props.location?.state?.backpath?.goalCategoryId;
  }
  else {
    goalCategoryId = (paramGoalCategoryId === 'null' || paramGoalCategoryId === '') ? null : paramGoalCategoryId;
  }
  const content = local.observationConnect;
  const [googleFitConnected, setGoogleFitConnected] = useState(false);
  const validicUserId = useSelector(state => getValidicUserId(state, profileId));

  const { totalDevices, validicConnectedDevices } = useSelector(state => getAllDevicesFromValidic(state));
  const { hasError, data } = useSelector(state => getObservationConnections(state));
  const { data: profileGoalList, loaded: profileGoalLoaded } = useSelector(state => getProfileGoalsList(state));
  const { data: goalCategories } = useSelector(state => getGoalCategories(state));

  const { connectDevicesList, filteredConnectedObservationDevices, manuallyTrackingObservationDevices,
    manuallyTracked, deactivatedObservationDevices, programObservationDevices } = helper(data, validicConnectedDevices, googleFitConnected);

  let isDisabled = connectDevicesList.every((x) => ManuallyTrackedObservations.includes(x.observationType));
  const profileGoals = profileGoalList?.find(x => x.goalCategoryId === goalCategoryId);
  const goalCategory = goalCategories?.find(x => x.id === goalCategoryId);
  const programIdList = [];
  profileGoals?.profileGoalPrograms?.map((x) => {
    x.isActive && programIdList.push(x.programId);
  });

  let programIds = null;
  programIds = invitedProgramIdArray ?? programIdList;

  const activeEnrollment = useSelector(state => getActiveEnrollmentForProgram(state, invitedProgramIdArray && invitedProgramIdArray[0]));
  const pathwayName = activeEnrollment?.program?.name ?? local.formatString(local.goals.header, goalCategory?.name ?? '');
  const [goalRequestId, setGoalRequestId] = useState(null);
  const primaryObservationType = goalCategory?.goalPrograms?.find(x => x.isPrimaryProgram)?.primaryObservationType;
  const [isPrimaryObservationDeactivated, setIsPrimaryObservationDeactivated] = useState(false);
  const request = useSelector(state => getRequest(state, goalRequestId));
  const enrollmentId = activeEnrollment?.id;
  let isGoal = null;

  //Note: We will not get enrollment information in case of goal category
  if (!goalCategoryId) {
    isGoal = useSelector(state => isGoalBasedEnrollment(state, enrollmentId));
  }

  useEffect(() => {
    deactivatedObservationDevices && deactivatedObservationDevices.length > 0 && primaryObservationType ? deactivatedObservationDevices.map((x) => {
      setIsPrimaryObservationDeactivated(x.observationType === primaryObservationType);
    }) : setIsPrimaryObservationDeactivated(false);
  }, [deactivatedObservationDevices]);

  useEffect(() => {
    if (isAndroid) {
      asyncCheckGoogleFitConnected();
    }
  }, [profileId, validicUserId]);

  useEffect(() => {
    dispatch(loadProgramEnrollments(profileId));
    dispatch(loadGoalCategory(profileId));
    dispatch(loadProfileGoals(profileId));
  }, [profileId]);

  useEffect(() => {
    setGoalRequestId(getUniqueId());
    if (profileGoalLoaded && programIds && programIds.length > 0) {
      dispatch(loadObservationValidicProfile(profileId, programIds));
    }
  }, [profileId, profileGoalLoaded]);


  useEffect(() => {
    let params = new URLSearchParams(props.location?.search);
    let paramObservartionType = params?.get(ObservationTypes);
    let paramSource = params?.get(Source);

    if (paramObservartionType) {
      const observationTypes = Object.values(ObservationType);
      const observationType = observationTypes.find(x => x === Number(paramObservartionType));
      const body = {
        observationType: observationType,
        status: ObservationConnectionStatus.DeviceConnected,
        deviceSourceName: paramSource
      };
      if (profileGoalLoaded && programIds && programIds.length > 0) {
        dispatch(SaveObservationConnections(profileId, body, programIds));
      }
    }
  }, [profileId, profileGoalLoaded]);

  useEffect(() => {

    dispatch(clearObservationConnections());

    let params = new URLSearchParams(props.location?.search);
    let paramClose = params?.get(Close);

    if (isIphone && paramClose) {
      closeRouteInApp();
      openMobileRoute(OpenApp);
    }
  }, []);

  function asyncCheckGoogleFitConnected() {
    CheckGoogleFitConnected().then((result) => {
      setGoogleFitConnected(result);
    });
  }

  function handleComplete() {
    let url;
    if (isGoal) {
      url = buildPath(routePaths.observationGoal, { profileId, programId: invitedProgramIdArray[0], programEnrollmentId: activeEnrollment?.id });
    }
    else {
      url = buildPath(routePaths.programsDetails, { programId: invitedProgramIdArray[0], programEnrollmentId: activeEnrollment?.id });
    }
    props.navigate({ to: url });
  }

  useEffect(() => {
    if (profileId && enrollmentId && !goalCategoryId) {
      dispatch(checkGoalBasedEnrollment(profileId, enrollmentId));
    }
  }, [profileId, enrollmentId]);


  async function CheckGoogleFitConnected() {
    const result = await isGooglefitEnabled(validicUserId);
    return result;
  }

  function updateProfileGoal() {
    if (profileGoals) {
      let goalCategoryProgramIds = [];
      deactivatedObservationDevices.map((x) => {
        const goalProgramId = goalCategory.goalPrograms.find(y => y.primaryObservationType === x.observationType)?.id;
        goalProgramId && goalCategoryProgramIds.push(goalProgramId);
      });
      const modifiedProfileGoal = profileGoals?.profileGoalPrograms?.filter(x => !goalCategoryProgramIds.includes(x.goalProgramId));
      profileGoals.profileGoalPrograms = modifiedProfileGoal;
      profileGoals.status = GoalStatus.Active;
      dispatch(SaveProfileGoals(profileId, profileGoals, goalRequestId));
    }
  }

  if (goalCategoryId && request.success && !request.processing && !request.hasError) {
    props.navigate({ to: routePaths.myGoal });
  }
  return (
    <Page
      id="observationConnect-component"
      loading={!data || hasError}
      backPath={goalCategoryId ? { to: routePaths.createGoal, state: { backpath: { goalName: pathwayName, goalCategoryId: goalCategoryId } } } : null}
      centerElement={pathwayName}>
      <div className='formatted-text observationConnect-note'>
        <p>{content.description}</p>
      </div>
      {connectDevicesList && connectDevicesList.length > 0 ?
        <div>
          <div className='observationConnect-statusTitle'>{content.connectDevice}</div>
          {connectDevicesList.map((x) => {
            return (<ObservationCard key={x.observationType} goalCategoryId={goalCategoryId} primaryObservationType={primaryObservationType} pathwayName={pathwayName} status={ObservationConnectionStatus.Unknown} programId={programIds} supportedDeviceSourceNames={x.supportedDeviceSourceNames} observationType={x.observationType} isManualSupported={x.supportedDeviceSourceNames?.some(y => y.toLowerCase() === Manual)}></ObservationCard>);
          })}
        </div> : null}


      {filteredConnectedObservationDevices && filteredConnectedObservationDevices.length > 0 ?
        <div>
          <div className='observationConnect-statusTitle'> <Icon symbol="yes" size="1.2em" />{content.connectedDevice}</div>
          {filteredConnectedObservationDevices.map((x) => {
            let displayName = totalDevices?.find(y => y.type === x.deviceSourceName)?.display_name;
            if (x.deviceSourceName === GoogleFitType) {
              displayName = GoogleFitDisplayName;
            }
            return (<ObservationCard key={x.observationType} goalCategoryId={goalCategoryId} pathwayName={pathwayName} programId={programIds} observationType={x.observationType} status={x.status} deviceSourceName={displayName}></ObservationCard>);
          })}
        </div> : null}


      {manuallyTrackingObservationDevices && manuallyTrackingObservationDevices.length > 0 ?
        <div>
          <div className='observationConnect-statusTitle'><Icon symbol="yes" size="1.2em" />{content.manualTracking}</div>
          {manuallyTrackingObservationDevices.map((x) => {
            return (<ObservationCard key={x.observationType} goalCategoryId={goalCategoryId} primaryObservationType={primaryObservationType} pathwayName={pathwayName} programId={programIds} observationType={x.observationType} status={x.status} deviceSourceName={x.deviceSourceName}></ObservationCard>);
          })}
        </div> : null}

      {manuallyTracked && manuallyTracked.length > 0 ?
        <div>
          <div className='observationConnect-statusTitle'><Icon symbol="yes" size="1.2em" />{content.manuallyTracked}</div>
          {manuallyTracked.map((x) => {
            return (<ObservationCard key={x.observationType} goalCategoryId={goalCategoryId} pathwayName={pathwayName} programId={programIds} supportedDeviceSourceNames={x.supportedDeviceSourceNames} observationType={x.observationType}></ObservationCard>);
          })}
        </div> : null}

      {deactivatedObservationDevices && deactivatedObservationDevices.length > 0 ?
        <div>
          <div className='observationConnect-statusTitle'>{content.deactivatedDevice}</div>
          {deactivatedObservationDevices.map((x) => {
            const isManualSupported = programObservationDevices?.find(y => y.observationType == x.observationType)?.supportedDeviceSourceNames?.some(z => z.toLowerCase() === Manual);
            return (<ObservationCard key={x.observationType} goalCategoryId={goalCategoryId} pathwayName={pathwayName} programId={programIds} isManualSupported={isManualSupported} observationType={x.observationType} status={x.status} deviceSourceName={x.deviceSourceName}></ObservationCard>);
          })}
        </div> : null}

      <div className="observationConnect-action">
        {goalCategoryId ?
          <Button id="observationConnect-btn" onClick={updateProfileGoal} color="blue" className="btn btn-primary" disabled={!isDisabled || isPrimaryObservationDeactivated}>
            {local.complete}
          </Button> : null}
        {activeEnrollment && !goalCategoryId ?
          <Button id="observationConnect-btn" color="blue" className="btn btn-primary" disabled={!isDisabled} onClick={handleComplete}>
            <LinkWrapper id='change-goal-button' to={!isGoal ? buildPath(routePaths.programsDetails, { programId: invitedProgramIdArray[0], programEnrollmentId: activeEnrollment?.id }) : buildPath(routePaths.observationGoal, { profileId, programId: invitedProgramIdArray[0], programEnrollmentId: activeEnrollment?.id })}>{isGoal ? local.next : local.complete}</LinkWrapper>
          </Button> : null}
      </div>
      {isPrimaryObservationDeactivated ? <AlertModal primaryObservationType={primaryObservationType} /> : null}
    </Page>
  );
}

export default withNavigate(withRouter(ProfileObservationConnect));
