import * as actions from './action-names';

export function mobileConnected() {
  return {
    type: actions.NETWORK_MOBILE_CONNECTED
  };
}

export function mobileDisconnected() {
  return {
    type: actions.NETWORK_MOBILE_DISCONNECTED
  };
}
