/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/jsx-indent */
import React, { Fragment, useState } from 'react';
import { ModalWrapper, Button, Icon, Loading } from 'shared';
import './LabResultPdfModal.scss';
import { useSelector } from 'react-redux';
import * as persistentDataSelectors from 'app/request/persistent-data-selectors';
import { types } from 'health/health-types';
import { getLabAttachment } from 'health/health-selectors';
import PropTypes from 'prop-types';
import { Page  } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';

export default function LabResultPdfModal(props) {
  const {labId, attachmentId, name, closeModal} = props;
  const defaultScale = 1;
  const loading = useSelector(state => persistentDataSelectors.isLoading(state.health, types.LAB_ATTACHMENTS, labId));
  const base64 = useSelector(state => getLabAttachment(state, labId, attachmentId));
  const [totalPages, setTotalPages] = useState(null);
  const [scale, setScale] = useState(defaultScale);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({numPages}) {
    setTotalPages(numPages);
  }

  function handleZoomIn(){
    setScale(scale + 1);
  }

  function handleZoomOut(){
    setScale(scale - 1);
  }

  function handleReset(){
    setScale(defaultScale);
  }

  function handleNextPage(){
    setPageNumber(pageNumber + 1);
  }

  function handlePreviousPage(){
    setPageNumber(pageNumber - 1);
  }

  return (
    <ModalWrapper {...props}>
      <div className="modal-container pdf-viewer">
        <div className="modal-header pdf-header">
          <Button id="close-button" onClick={closeModal}>
            <Icon symbol="arrowleft" />
          </Button>
          <div className='pdf-title'>
            {name}
          </div>
          {!loading &&
          <div className='zoom-control'>
            <Button id="pdf-zoom-out" onClick={handleZoomOut} disabled={scale == defaultScale}>
              <Icon symbol="zoomOut" />
            </Button>
            <Button id="pdf-zoom-reset" onClick={handleReset} disabled={scale == defaultScale}>
              <Icon symbol="refresh" />
            </Button>
            <Button id="pdf-zoom-in" onClick={handleZoomIn}>
              <Icon symbol="zoomIn" />
            </Button>
          </div>}
        </div>
        {loading ? <Loading /> :
          <Fragment>
            <div className='modal-body'>
              <div>
                <Document file={`data:application/pdf;base64,${base64}`} onLoadSuccess={onDocumentLoadSuccess} loading={<Loading />}>
                  <Page pageNumber={pageNumber} scale={scale} height={500} />
                </Document>
              </div>
            </div>
            <div className='modal-footer'>
              <div className='page-control'>
                <Button id="pdf-previous-page" onClick={handlePreviousPage} disabled={pageNumber == 1}>
                  <Icon symbol="arrowleft" />
                </Button>
                <p className='page-count'>Page {pageNumber} of {totalPages}</p>
                <Button id="pdf-next-page" onClick={handleNextPage} disabled={pageNumber == totalPages}>
                  <Icon symbol="arrowright" />
                </Button>
              </div>
            </div>
          </Fragment>}
      </div>
    </ModalWrapper>
  );
}

LabResultPdfModal.propTypes = {
  labId: PropTypes.string,
  attachmentId: PropTypes.string,
  name: PropTypes.string,
  closeModal: PropTypes.func
};
