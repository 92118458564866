export const getProfile = state =>
  state.profile.list.find(profile => profile.id === state.selectedProfile.id);

export const getProfileById = (state, id) => {
  let result = {};

  if (state.profile.self.id === id) {
    result = state.profile.self;
  } else {
    result = state.profile.list.find(profile => profile.id === id) || {};
  }

  return result;
};

export const getSelfProfileId = state => state.profile.self.id;

export const getProfileName = state =>
  (getProfileById(state, state.selectedProfile.id) || { name: '' }).name;

export const getProfileNameById = (state, id) => {
  let profile = getProfileById(state, id);

  return profile.name || '';
};

export const getSortedProfiles = state => {
  let result = [];

  result = state.profile.list.sort((a, b) => {
    if (a.id === state.profile.self.id) {
      return -1;
    } else if (b.id === state.profile.self.id) {
      return 1;
    } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }

    return 1;
  });

  return result;
};
