import * as selectors from 'app/request/request-selectors';

export function getDataState(state, interviewId) {
  const { saving, error } = state.interviews;
  const { processing, hasError } = selectors.getRequest(state, `load-${interviewId}`);

  return {
    loading: processing,
    saving: saving,
    error: error || hasError
  };
}
