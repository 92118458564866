import React from 'react';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import { Button, Avatar } from 'shared';
import { respondToInvite } from 'profiles/sharing/sharing-actions';
import { useDispatch } from 'react-redux';

export default function IncomingInviteListItem({ invite }) {
  const invitedBy = local.formatString(local.sharing.invitedBy, <strong>{invite.fromName || invite.createdByEmail}</strong>, <strong>{invite.profileName}</strong>);
  const dispatch = useDispatch();

  const acceptInvite = () => dispatch(respondToInvite({
    inviteId: invite.id,
    accepted: true
  }));

  const declineInvite = () => dispatch(respondToInvite({
    inviteId: invite.id,
    accepted: false
  }));

  const acceptButton = (<Button id={`accept-button-${invite.id}`} className="btn btn-primary" onClick={acceptInvite}><span>{local.accept}</span></Button>);
  const declineButton = (<Button id={`decline-button-${invite.id}`} className="btn btn-secondary" onClick={declineInvite}><span>{local.decline}</span></Button>);

  const avatar = <Avatar profile={{ name: invite.profileName, theme: invite.profileTheme }} />;
  const actionButtons = (
    <div className="button-container">
      {declineButton}
      {acceptButton}
    </div>
  );

  return (
    <div data-testid="incoming-invites-list-item" className="invite">
      {avatar}
      <div className="invite-content">
        {invitedBy}
        {actionButtons}
      </div>
    </div>
  );
}

IncomingInviteListItem.propTypes = {
  invite: PropTypes.shape({
    profileName: PropTypes.string,
    id: PropTypes.string,
    createdByEmail: PropTypes.string,
    fromName: PropTypes.string,
    profileTheme: PropTypes.number
  })
};



