import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import BloodGlucoseVisualization from './BloodGlucoseVisulalization';

function BloodGlucoseVisualizationContainer ({bloodGlucose,formatter,isHighFrequencyData,profileId,fromPathway,pathwayStartDate,pathwayEndDate,routeParams}) {
  const [selectedEnd,setSelectedEnd] = useState(moment().format(moment.HTML5_FMT.DATE));

  const updateSelectedStart = (startDate) =>{
    setSelectedEnd(startDate);
  };
  const selectPreviousDay = () =>{
    const previousSelectedStart = moment(selectedEnd).add(-1, 'days').format();
    updateSelectedStart(previousSelectedStart);
  };

  const selectNextDay = ()=> {
    const nextSelectedStart = moment(selectedEnd).add(1, 'days').format();
    updateSelectedStart(nextSelectedStart);
  };

  if(bloodGlucose.length === 0){
    return null;
  }
  return (
    <BloodGlucoseVisualization
      selectPreviousDay={selectPreviousDay}
      selectNextDay={selectNextDay}
      selectedEnd={selectedEnd}
      bloodGlucose={bloodGlucose}
      isHighFrequencyData={isHighFrequencyData}
      profileId={profileId}
      formatter={formatter}
      fromPathway={fromPathway}
      pathwayStartDate={pathwayStartDate}
      pathwayEndDate={pathwayEndDate}
      routeParams={routeParams} />
  );

}

BloodGlucoseVisualizationContainer.propTypes = {
  bloodGlucose: PropTypes.arrayOf(PropTypes.shape),
  formatter : PropTypes.string
};

export default BloodGlucoseVisualizationContainer;
