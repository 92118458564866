import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import local from 'services/localization/local';
import { programsContexts, routePaths, buildPath } from 'services/routing';
import { Page, ProfileLink } from 'shared';
import { setContext, loadPendingInvitedPrograms } from 'programs/programs-actions';
import * as selectors from 'programs/programs-selectors';
import { getOrganizationsForSelf } from 'organizations/organizations-selectors';
import ProgramListItemWrapper from './ProgramListItemWrapper';
import './program-list.scss';
import './ProgramTab.scss';
import { noImmunizationPathwayId , studioRoot} from 'app/config';
import ProgramTabs from './ProgramTabs';
import withContext from 'app/withContext';
import { ProgramCompletionCode, ProgramTabCode, ProgramTabName, ProgramTabStoreName, ProgramVisibility, InvitedProgramId } from 'services/enums';
import { getLocalStorage, setLocalStorage } from 'services/helpers.js';
import Form from 'react-bootstrap/Form';

export class ProgramList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePrograms: [],
      allPrograms: [],
      archivedPrograms: [],
      invitedPrograms: [],
      categories: [],
      isDataLoaded: false,
      prevProfileId: props.profileId,
      browsePrograms: [],
      currentInstance: this,
      category: '',
      showFilter: false
    };
    this.setPrograms = this.setPrograms.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.getTabName = this.getTabName.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.profileId !== state.prevProfileId) {
      state.currentInstance.componentDidMount();
      return { isDataLoaded: false, prevProfileId: props.profileId };
    }
    return null;
  }

  async componentDidMount() {
    const { profileId, actions } = this.props;
    await actions.loadPendingInvitedPrograms(profileId);
    actions.setContext(programsContexts.list);
    this.getCategories();
    this.setPrograms();
    console.log(this.props.organizations);
  }

  componentWillUnmount() {
    setLocalStorage(ProgramTabStoreName, ProgramTabName.Active);
  }

  async getCategories() {
    var org = this.props.organizations?.data;
    let orgId = 'ACE81103-36EE-4559-B4E1-1FE314749342'; //HCOrg as default
    if (org?.length > 0) {
      orgId = org[0].id;
    }
    await getCategoriesList(orgId, this.props.token).then((response) => {
      if (response?.length > 0) {
        var filter = [];
        response.map((item) => {
          filter.push({ id: item.id.toLowerCase(), name: item.name });
        });
        this.setState({ categories: filter });
      }
    });
  }

  setPrograms() {
    const { commonPrograms, invitedPrograms, uniquePrograms, declinedPrograms } = this.props;
    let programId = getLocalStorage(InvitedProgramId);
    let invitedProgram;
    if (programId != null) {
      invitedProgram = invitedPrograms.filter(x => x.inviteStatus === ProgramTabCode.Invited && x.programVisibilityStatus != ProgramVisibility.Public && x.programId != programId);
    }
    else {
      invitedProgram = invitedPrograms.filter(x => x.inviteStatus === ProgramTabCode.Invited && x.programVisibilityStatus != ProgramVisibility.Public);
    }
    var activeProgram = uniquePrograms.filter(x => x.programId != noImmunizationPathwayId && x.complete === false &&
      (x.completionCode !== ProgramCompletionCode.Upgraded &&
        x.completionCode !== ProgramCompletionCode.Complete &&
        x.completionCode !== ProgramCompletionCode.Unenrolled));
    var archivedProgram = uniquePrograms.filter(x => x.programId != noImmunizationPathwayId &&
      (x.complete === true || x.completionCode === ProgramTabCode.History));
    var invtedHistory = declinedPrograms.filter(x => x.inviteStatus === ProgramTabCode.History);

    var filteredInvitedArray = invtedHistory.filter(x => archivedProgram.every(y => y.id !== x.id));

    var historyPrograms = [...archivedProgram, ...filteredInvitedArray];

    this.setState({ activePrograms: activeProgram });
    this.setState({ archivedPrograms: historyPrograms });
    this.setState({ allPrograms: commonPrograms });
    this.setState({ browsePrograms: commonPrograms });
    this.setState({ invitedPrograms: invitedProgram });
    this.setState({ isDataLoaded: true });
  }

  renderIcon(iconPath) {
    if (iconPath) {
      return <img className='icon-image' src={iconPath} />;
    } else {
      return <figure className='icon-image'></figure>;
    }
  }

  getTabName(name) {
      this.setState({ showFilter: name === 'Browse' ? true : false });
  }

  handleSelectChange(e) {
    var value = e.target.value ? e.target.value.toLowerCase() : '';
    var programs = value ? this.state.browsePrograms.filter(x => x.category && x.category.toLowerCase().includes(value)) : this.state.browsePrograms;
    this.setState({allPrograms: programs, category : value});
  }

  render() {
    return (
      <Page
        id='program-list'
        centerElement={local.programs.listHeader}
        loading={!this.state.isDataLoaded}>
        {this.state.showFilter ? <div>
          <div style={{ width: '350px', paddingTop: '10px', paddingLeft: '20px' }}>
            <label>Category</label>
            <Form.Select value={this.state.category} onChange={this.handleSelectChange}>
              <option value=''>All</option>
              {this.state.categories.map((item) => {
                return (<option value={item.id}>{item.name}</option>);
              })}
            </Form.Select>
          </div>
          <br />
        </div> : null}
        <ul className="card-list">
          <ProgramTabs getTabName={this.getTabName}>
            <div label={ProgramTabName.Active} >
              {this.state.activePrograms && this.state.activePrograms.length > 0 ? this.state.activePrograms.map(x => <ProgramListItemWrapper key={x.id} showCloseButton={true} programInfo={x} />) : <li className="program card-list-item">{local.programs.emptyActivePathWays}<br></br>{local.programs.newPathways}</li>}
            </div>
            <div label={ProgramTabName.Invited} >
              {this.state.invitedPrograms && this.state.invitedPrograms.length > 0 ? this.state.invitedPrograms.map((data, index) => <li className="program card-list-item" key={index} ><ProfileLink id={`program-link-${data.id}`} className='program-list-item-link' to={buildPath(routePaths.programsInvite, { programId: data.programId })} params={{ programId: data.programId }} > {this.renderIcon(data.iconPath)} <div className="program-text"> <small className="program-title"> {data.name} </small> <small className="program-description">{data.durationInDays} {local.daysLabel}</small> </div> </ProfileLink></li>) : <li className="program card-list-item">{local.programs.emptyInvitedPathWays}</li>}
            </div>
            <div label={ProgramTabName.History}>
              {this.state.archivedPrograms && this.state.archivedPrograms.length > 0 ? this.state.archivedPrograms.map(x => <li className="program card-list-item" key={x.id} ><ProfileLink id={x.id} className='program-list-item-link' to='' params={{}}> {this.renderIcon(x.iconPath)} <div className="program-text"> <small className="program-title"> {x.name} </small> <small className="program-description">{x.durationInDays} {local.daysLabel}</small> </div> </ProfileLink></li>) : <li className="program card-list-item">{local.programs.emptyArchivedPathWays}</li>}
            </div>
            <div label={ProgramTabName.Browse} >
              {this.state.allPrograms && this.state.allPrograms.length > 0 && this.state.allPrograms.map(x => <ProgramListItemWrapper key={x.id} programInfo={x} />)}
            </div>
          </ProgramTabs>
        </ul>
      </Page>
    );
  }
}

ProgramList.propTypes = {
  // redux
  commonPrograms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  invitedPrograms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  uniquePrograms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  declinedPrograms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  profileId: PropTypes.string.isRequired,
  // actions
  actions: PropTypes.shape({
    setContext: PropTypes.func.isRequired,
    loadPendingInvitedPrograms: PropTypes.func.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  const { commonPrograms, invitedPrograms, uniquePrograms, declinedPrograms } = selectors.getSortedPrograms(state);
  const organizations = getOrganizationsForSelf(state);
  const token = state.identity.accessToken;
  return {
    commonPrograms, invitedPrograms, uniquePrograms, declinedPrograms, organizations, token
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { setContext, loadPendingInvitedPrograms };
  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}
export default withContext(connect(mapStateToProps, mapDispatchToProps)(ProgramList));

function getCategoriesList(organizationId = '', token = '') {
  var baseUrl = studioRoot;
  // eslint-disable-next-line
  let url = baseUrl + 'expose-api/get-categories?';
  let header = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`,
    "organizationid": organizationId,
    "iscovidorg": true
  };
  return fetch(url, {
    method: 'GET',
    headers: header,
    credentials: 'include'
  }).then(response => {
    return response.json();
  })
    .then(data => {
      return data;
    })
    .catch(error => {
      console.log(error);
      return null;
    });
}
