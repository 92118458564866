import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import { TextField } from 'shared/form-fields';
import * as validators from 'services/fields/field-validators';
import { Button, ModalWrapper, Icon } from 'shared';
import { connect } from 'react-redux';

export class EHRRequestForm extends React.Component {

  constructor(props) {
    super(props);
    this.physicianName = React.createRef();
    this.providerOrganizationName = React.createRef();
    this.providerAddress = React.createRef();
    this.city = React.createRef();
    this.state = React.createRef();
    this.providerEmail = React.createRef();
    this.phoneNumber = React.createRef();
    this.notes = React.createRef();
  }

  componentDidMount() {
    let props = this.props.initialValues;
    this.props.initialize({
      id: props.id,
      physicianName: props.physicianName,
      providerOrganizationName: props.providerOrganizationName,
      providerEmail: props.providerEmail,
      providerAddress: props.providerAddress,
      phoneNumber: props.phoneNumber,
      notes: props.notes
    });
  }

  getFormFields(handleSubmit, readOnly) {
    return (<form onSubmit={handleSubmit}>
      <div>
        Please complete the form below, and we will request your electronic health records (EHR) from your healthcare provider.
      </div>
      <br />
      <div className="form-row">
        <Field
          readOnly={readOnly}
          label='Organization Name of Healthcare Provider'
          ref={this.providerOrganizationName}
          name="providerOrganizationName"
          validate={validators.required}
          autoFocus
          component={TextField} />
      </div>
      <div className="form-row">
        <Field
          readOnly={readOnly}
          label='Provider City'
          ref={this.city}
          name="providerCity"
          validate={validators.required}
          component={TextField} />
      </div>
      <div className="form-row">
        <Field
          readOnly={readOnly}
          label='Provider State'
          ref={this.state}
          name="providerState"
          validate={validators.required}
          component={TextField} />
      </div>
      <div className="form-row">
        <Field
          readOnly={readOnly}
          label={local.healthInfo.addProvider.address}
          ref={this.providerAddress}
          name="providerAddress"
          validate={validators.required}
          component={TextField} />
      </div>
      <div className="form-row">
        <Field
          readOnly={readOnly}
          label='Full Name of Your Healthcare Provider'
          ref={this.physicianName}
          name="physicianName"
          validate={validators.required}
          component={TextField} />
      </div>
      <div className="form-row">
        <Field
          readOnly={readOnly}
          label={local.healthInfo.addProvider.email}
          ref={this.providerEmail}
          name="providerEmail"
          validate={validators.required}
          component={TextField} />
      </div>
      <div className="form-row">
        <Field
          readOnly={readOnly}
          label={local.healthInfo.addProvider.phoneNumber}
          ref={this.phoneNumber}
          name="phoneNumber"
          validate={validators.required}
          component={TextField} />
      </div>
      <div className="form-row">
        <Field
          readOnly={readOnly}
          label='Specific records you’re seeking from this provider'
          ref={this.notes}
          validate={validators.required}
          name="notes"
          component={TextField} />
      </div>
      {!readOnly ? <Button
        id="profile-form-submit"
        className="btn btn-primary"
        submit
        color="blue">
        Submit
      </Button> : null}
    </form>);
  }

  render() {
    const { handleSubmit, readOnly, showModal } = this.props;
    return (
      <ModalWrapper isOpen={showModal}>
        <div id="coach-component" data-testid='product-coach-component' className="modal-container">
          <div className="modal-header">
            <div></div>
            Health Record Request Form
            <Button className="left" id="modal-cancel-button" onClick={this.props.closeModal}>
              <Icon symbol="times" />
            </Button>
          </div>
          <div className="modal-body">
            {this.getFormFields(handleSubmit, readOnly)}
          </div>
        </div>
      </ModalWrapper>
    );
  }


}

EHRRequestForm.propTypes = {
  // redux
  handleSubmit: PropTypes.func
};

const form = reduxForm({
  form: 'ehr-request',
  initialValues: {},
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(EHRRequestForm);

function mapStateToProps(state) {
  return {
    saving: state.medication.saving
  };
}

export default connect(mapStateToProps, null)(form);
