import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withContext from 'app/withContext';
import moment from 'moment';
import { getGroupedDosesForTodayAndYesterday } from 'health/medication/dose/selectors';
import { getColorByGuid } from 'services/helpers';
import MedicationDoseVisualizationNavItem from './MedicationDoseVisualizationNavItem';
import local from 'services/localization/local';

class MedicationDoseVisualization extends React.Component {
  constructor(props) {
    super(props);

    this.selectDate = this.selectDate.bind(this);

    this.state = {
      selectedDate: moment().startOf('day').format()
    };
  }

  selectDate(date) {
    this.setState({ selectedDate: date });
  }

  render() {
    const { selectedDate } = this.state;
    const { doses } = this.props;

    if (doses.length === 1 && doses[0].values.length === 0) {
      return null;
    }
    return (
      <div id="dose-visualization">
        <nav>
          {doses.map(day => (
            <MedicationDoseVisualizationNavItem
              key={day.date}
              active={day.date === selectedDate}
              day={day}
              selectDate={this.selectDate} />
          ))}
        </nav>

        <div className="days">
          {doses.map(day => (day.values.length > 0 ?
            <div key={day.date} id={day.display.toLowerCase()} className={day.date === selectedDate ? 'active day' : 'day'}>
              {day.values.map(time => (
                <div key={time.category} className={`${time.category.toLowerCase()} time-of-day`}>
                  <strong>{time.category}</strong>

                  {time.values.map(dose => (
                    <span key={dose.id} className="dose" style={{ backgroundColor: getColorByGuid(dose.medicationId) }}>
                      <strong>{dose.medicationName.substring(0, 3)}</strong>
                      &nbsp;
                      <span>{moment(dose.recordedAt).format('h:mm A')}</span>
                    </span>
                  ))}
                </div>
              ))}
            </div> :
            <div key="no-doses" className={day.date === selectedDate ? 'active day empty' : 'day empty'}>
              <small>{local.noMedicationDosesPlaceholder}</small>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

MedicationDoseVisualization.propTypes = {
  doses: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({
      category: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        medicationId: PropTypes.string.isRequired,
        medicationName: PropTypes.string.isRequired,
        recordedAt: PropTypes.string.isRequired
      })).isRequired
    })).isRequired
  })).isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    doses: getGroupedDosesForTodayAndYesterday(state, ownProps.profileId)
  };
}

export default withContext(connect(mapStateToProps)(MedicationDoseVisualization));
