import React from 'react';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import ProfilePage from 'profiles/page/ProfilePage';
import HealthScore from './HealthScore';
import Logo from 'shared/logos/svg-mark/SvgMark.jsx';
import './health-score-details.scss';

class HealthScoreDetails extends React.Component {
  render() {
    return (
      <ProfilePage
        id="health-score-details"
        centerElement={<Logo />}
        titleTabText={local.healthChampionScore}
        backPath={{ to: routePaths.root }}>
        <HealthScore />
        <div className="content">
          <small>
            {local.healthScoreDetails}
          </small>
        </div>
      </ProfilePage>
    );
  }
}

export default HealthScoreDetails;
