import React from 'react';
import local from 'services/localization/local';
import { MailToLink } from 'shared';
import { privacyEmail } from 'app/config';

export default function UserConsent() {
  const consent = (
    <div className="consent-section">
      <p><strong>{local.hipaaConsentP1}</strong></p>
      <small>{local.hipaaConsentP2}</small>
    </div>
  );


  return (
    <div className="hipaa-auth-content userConsent" >
      {/* <FormattedText text="# User Consent" /> */}
      <h1 className='pb-2 text-center'>{local.userConsent}</h1>
      <section className="hippa-summary">
        <p className='m-0'>{local.userConsentp1}</p>
        <ul>
          <li>{local.userConsentpoint1}</li>
          <li>{local.userConsentpoint2}</li>
          <li>{local.userConsentpoint3}</li>
        </ul>
      </section>
      <div id="usercontent" >
        <p>{local.userConsentp2}</p>
        <p>{local.userConsentp3}</p>
        <p>{local.userConsentp4}</p>
        <br />
        <p>{local.userConsentp5} <MailToLink id="mailto-privacy" mailto={`mailto:${privacyEmail}`}>{privacyEmail}</MailToLink>{local.userConsentp6}</p>
        <br />
        <div className='hipaa-verified'>
          <a href="http://compliancy-group.com/hipaa-compliance-verification" target="_blank" rel="noopener noreferrer">
            <img src="https://compliancy-group.com/wp-content/uploads/2016/08/HIPAA-Compliance-Verification.png" height="100" alt="HIPAA compliance verification for Calcium" />
          </a>
        </div>
        {consent}
      </div>
    </div >
  );
}
