import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
// eslint-disable-next-line no-unused-vars
import {loadSystolicGoals, loadDiastolicGoals} from 'health/action-creators';
import { bindActionCreators } from 'redux';
import withContext from 'app/withContext';
import { connect } from 'react-redux';
import {  getActiveGoal } from 'health/observations/blood-pressure/blood-pressure-selectors';
import styleVars from 'styles/_variables.scss';

const getData = (data) =>{
  if(data) {
    return {
      labels:data.labels,
      datasets:[
        {
          label:data.datasets[0].label,
          data:data.datasets[0].data,
          backgroundColor: styleVars.colorWarning,
          borderColor: styleVars.colorWarning,
          pointHoverRadius:4,
          pointHitRadius:30,
          pointRadius: 0,
          lineTension: 0,
          borderWidth: 1,
          fill: false
        },
        {
          label:data.datasets[1].label,
          data:data.datasets[1].data,
          backgroundColor: styleVars.colorBlue,
          borderColor: styleVars.colorBlue,
          pointHoverRadius:4,
          pointHitRadius:30,
          pointRadius: 0,
          lineTension: 0,
          borderWidth: 1,
          fill: false
        }
      ]
    };
  }
};
const getAnnotations = (targetData) =>{
  if(targetData){
    const systolicMin = targetData?.systolicMin == undefined ? 0 : targetData?.systolicMin;
    const systolicMax = targetData?.systolicMax == undefined ? 0 : targetData?.systolicMax;
    const diastolicMin = targetData?.diastolicMin == undefined ? 0 : targetData?.diastolicMin;
    const diastolicMax = targetData?.diastolicMax == undefined ? 0 : targetData?.diastolicMax;

    return {
      annotations: [
        {
          type: 'box',
          yScaleID: 'y-axis-0',
          scaleID: 'y-axis-0',
          borderColor: 'transparent',
          backgroundColor: styleVars.colorGrayLight60,
          yMin:systolicMin,
          yMax:systolicMax,
          drawTime: 'beforeDatasetsDraw'

        },
        {
          type: 'box',
          yScaleID: 'y-axis-0',
          scaleID: 'y-axis-0',
          borderColor: 'transparent',
          backgroundColor: styleVars.colorGrayLight60,
          yMin:diastolicMin,
          yMax:diastolicMax,
          drawTime: 'beforeDatasetsDraw'
        },
        {
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: systolicMax,
          borderColor: styleVars.colorGray,
          borderWidth: 0.5,
          borderDash:[5,2]
        },
        {
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: systolicMin,
          borderColor: styleVars.colorGray,
          borderWidth: 0.5,
          borderDash:[5,2]
        },
        {
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: diastolicMax,
          borderColor: styleVars.colorGray,
          borderWidth: 0.5,
          borderDash:[5,2]
        },
        {
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: diastolicMin,
          borderColor: styleVars.colorGray,
          borderWidth: 0.5,
          borderDash:[5,2]
        }
      ]
    };
  }else{
    return '';
  }

};

const getOptions = (targetData) =>{
  return {
    responsive: true,
    legend: {
      display: false,
      backgroundColor:'transparent',
      borderColor:'transparent',
      position: 'bottom',
      labels: {
        boxWidth: 0,
        fontColor: 'transparent'
      }
    },
    scales: {
      xAxes: [{
        display: true,
        gridLines: {
          drawOnChartArea: false
        },
        ticks: {
          display: true,
          autoSkip: true,
          fontColor: styleVars.colorGray,
          fontSize: 10,
          callback: (value) => { return `${value}`; },
          maxTicksLimit:4,
          maxRotation: 0,
          minRotation: 0
        }
      }],
      yAxes: [{
        display: true,
        gridLines: {
          drawOnChartArea: false
        },
        ticks: {
          fontColor: styleVars.colorGray,
          fontSize: 10,
          minTickLimits: 5,
          maxTicksLimit: 8,
          precision: 0,
          stepSize: 5,
          callback: function(value) {
            return  value;
          }
        }
      }]
    },
    tooltips: {
      backgroundColor: styleVars.colorWhiteOff,
      bodyFontColor: styleVars.colorGrayDark,
      bodyFontStyle: 'normal',
      bodyFontFamily: styleVars.fontStyleNormal,
      bodySpacing: 5,
      cornerRadius: 0,
      caretSize: 0,
      titleFontColor: styleVars.colorGrayDark,
      titleFontStyle: 'bold',
      titleFontSize: 14,
      intersect: false,
      mode: 'index',
      position: 'nearest',
      itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
      callbacks: {
        label: (tooltipItem, data) => `${data.datasets[tooltipItem.datasetIndex]?.label} : ${tooltipItem.yLabel} mmHg`
      }
    },

    annotation: getAnnotations(targetData)
  };
};
function BloodPressureLineChart({data,actions,activeGoal,profileId}) {
  const getActiveGoalValue = (actions,profileId) =>{
    actions.loadSystolicGoals(profileId);
    actions.loadDiastolicGoals(profileId);
  };

  useEffect(()=>{
    getActiveGoalValue(actions,profileId);
  }, []);

  const data1 = getData(data);
  const options = getOptions(activeGoal);
  return (
    <Line data={data1} options={options} />
  );
}



BloodPressureLineChart.propTypes = {
  data: PropTypes.PropTypes.shape().isRequired,
  actions: PropTypes.shape({
    loadSystolicGoals: PropTypes.func.isRequired,
    loadDiastolicGoals: PropTypes.func.isRequired
  }).isRequired
};

function mapStateToProps(state, props) {
  return {
    profileId:props.profileId,
    activeGoal: getActiveGoal(state, props.profileId)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { loadSystolicGoals , loadDiastolicGoals };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default withContext(connect(mapStateToProps,mapDispatchToProps)(BloodPressureLineChart));
