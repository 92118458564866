import React from 'react';
//import Icon from 'shared/icon/Icon';
import SubLabelSection from 'shared/form-fields/SubLabelSection';
import './form-fields.scss';

class SelectField extends React.Component {
  render() {
    const { label, subLabel, placeholder, input, meta: { error, touched }, children, ...rest } = this.props;
    const hasError = error && touched;
    const id = `${input.name}-input`;

    return (
      <div className="formField">
        {label && <label className='form-label' htmlFor={id}>{label}</label>}
        <div className="select-wrapper">
          <select
            id={id}
            className={hasError ? 'inputError form-select' : 'form-select'}
            {...input}
            {...rest}>
            {placeholder && <option disabled value="">{placeholder}</option>}
            {children}
          </select>
          {/* <Icon symbol="chevrondown" /> */}
          <SubLabelSection showError={hasError} error={error} subLabel={subLabel} />
        </div>
      </div>
    );
  }
}

SelectField.displayName = 'SelectField';
export default SelectField;
