import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import momentDurationFormatSetup from 'moment-duration-format';
import local from 'services/localization/local';

export const compareDatesAscending = (a, b) => {
  return compareDates(a, b, false);
};

export const compareDatesDescending = (a, b) => {
  return compareDates(a, b);
};

export const compareDates = (a, b, descending = true) => {
  const dateA = moment(a);
  const dateB = moment(b);

  if (dateA < dateB) {
    return descending ? 1 : -1;
  }

  if (dateA > dateB) {
    return descending ? -1 : 1;
  }

  return 0;
};

export const momentCalendarDayFormat = {
  sameDay: `[${local.dateDisplayToday}]`,
  nextDay: `[${local.dateDisplayTomorrow}]`,
  nextWeek: local.dateDisplayDayFormat,
  lastDay: `[${local.dateDisplayYesterday}]`,
  lastWeek: local.simpleDateFormat,
  sameElse: local.simpleDateFormat
};

export const momentCalendarDateTimeFormat = {
  sameDay: `[${local.dateDisplayToday}], ${local.simpleTimeFormat}`,
  nextDay: `[${local.dateDisplayTomorrow}], ${local.simpleTimeFormat}`,
  nextWeek: `${local.simpleDateFormat}, ${local.simpleTimeFormat}`,
  lastDay: `[${local.dateDisplayYesterday}], ${local.simpleTimeFormat}`,
  lastWeek: `${local.simpleDateFormat}, ${local.simpleTimeFormat}`,
  sameElse: `${local.simpleDateFormat}, ${local.simpleTimeFormat}`
};

// Remove use of localization text for bucketing categories
export const getTimeCategory = (dateTime) => {
  const hour = dateTime.hour();

  if (hour < 12) {
    return local.morning;
  }
  else if (hour < 17) {
    return local.afternoon;
  }
  else if (hour < 21) {
    return local.evening;
  }
  else {
    return local.nighttime;
  }
};

export const buildMoment = (year, month, day) => {
  return moment(`${year}-${month}-${day}`, 'YYYY-M-D', true);
};

export const splitDate = date => {
  var result = { day: '', month: '', year: '' };

  if (date) {
    const momentDate = moment(date);

    result.day = `${momentDate.date()}`;
    result.month = `${momentDate.month() + 1}`;
    result.year = `${momentDate.year()}`;
  }

  return result;
};

export const buildDuration = ({ hours, minutes, seconds }) => {
  if (!(hours || minutes || seconds)) {
    return null;
  }

  const duration = moment.duration({
    hours,
    minutes,
    seconds
  });

  return duration.toISOString();
};

export const getDurationHoursMinutesSeconds = (timespan) => {
  if (typeof (timespan) !== 'string') {
    throw Error('Invalid Input');
  }

  // Convert timespaan string potentially containin days or years to hours, minutes, seconds only
  const times = moment.duration(timespan).format('h:m:s').split(':');

  return {
    hours: times[0],
    minutes: times[1],
    seconds: times[2]
  };
};

export const buildDurationDisplayText = (startTime, endTime) => {
  const start = moment(startTime);
  const end = moment(endTime);
  const duration = moment.duration(end.diff(start));
  let durationObject = [];
  if (duration.days() > 0) { durationObject.push(duration.days() + local.daysShortLabel); }
  if (duration.hours() > 0) { durationObject.push(duration.hours() + local.hoursShortLabel); }
  if (duration.minutes() > 0) { durationObject.push(duration.minutes() + local.minutesShortLabel); }
  if (duration.seconds() > 0) { durationObject.push(duration.seconds() + local.secondsShortLabel); }

  return `${durationObject.join(' ')}`;
};

export const getDurationFromNow = (dateTime) => {
  return moment.duration(moment().diff(moment(dateTime)));
};

export const isDateInRange = (inputDate, startDate, endDate) => {
  const input = moment(inputDate || null);
  const start = moment(startDate || null);
  const end = moment(endDate || null);

  if (!input.isValid()) {
    return false;
  }

  if (start.isValid() && end.isValid()) {
    return input >= start && input <= end;
  } else if (start.isValid() && !end.isValid()) {
    return input >= start;
  } else if (end.isValid() && !start.isValid()) {
    return input <= end;
  }

  return true;
};

export const calculateAge = (dob) => {

  if (dob == null || dob == undefined) {
    return '';
  }
  var diff_ms = Date.now() - new Date(dob).getTime();
  var age_dt = new Date(diff_ms);
  return Math.abs(age_dt.getUTCFullYear() - 1970);
};

export const formatHourToDate=(hour, withMeridiem = false)=>
{
  var t = (hour==null || hour==undefined)?'12:00 AM':hour;
  let cdt = withMeridiem ? moment(t, 'HH:mm A') : moment(t, 'HH:mm');
  cdt.toDate();
  return new moment(cdt).format();
};

