import React, { Component } from 'react';
import { LinkWrapper } from 'shared';
import { routePaths, buildPath } from 'services/routing';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadNewEmail,removeChangeError } from 'preferences/changeEmail/action-creators';
import { getIsAdminForAnyOrganization } from 'identity/action-creators';
import 'preferences/changeEmail/verified-change-email.scss';

class PreferencePasswordChange extends Component{

  constructor(props) {
    super(props);
  }

  componentDidMount(){
  }

  render(){
    return (
      <div className="customize-section" data-testid="changeEmail">
        <small className="account-title">Password Setting</small>
        <div id='edit_account_settings'>
          <div className="bg-white">
            <small data-testid='changeEmail' className="emailAccount">
              <div className="emailWrapper">
              </div>
              {this.props.connection ? null : <LinkWrapper className="button small-btn btn btn-secondary" to={buildPath(routePaths.changePassword, null)} >Update Password</LinkWrapper>}
            </small>
          </div>
        </div>
      </div>
    );
  }
}

PreferencePasswordChange.propTypes = {
};
const mapStateToProps = state => ({
  connection: state.authConnection.connection
});

function mapDispatchToProps(dispatch) {
  const actions = {
    getIsAdminForAnyOrganization,
    loadNewEmail,
    removeChangeError };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps,mapDispatchToProps)(PreferencePasswordChange);
