import React, { Component } from 'react';
import { LinkWrapper, Icon } from 'shared';
import { routePaths, buildPath } from 'services/routing';
import local from 'services/localization/local';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { loadNewEmail,removeChangeError } from 'preferences/changeEmail/action-creators';
import { getIsAdminForAnyOrganization } from 'identity/action-creators';
import './verified-change-email.scss';

class PreferenceEmailChange extends Component{

  constructor(props) {
    super(props);
  }

  componentDidMount(){
    this.props.actions.loadNewEmail();
    this.props.actions.removeChangeError();
    this.props.actions.getIsAdminForAnyOrganization();
  }

  render(){
    return (
      <div className="customize-section" data-testid="changeEmail">
        <small className="account-title">{local.accountSettings.pageTitle}</small>
        <div id='edit_account_settings'>
          <div className="bg-white">
            <small data-testid='changeEmail' className="emailAccount">
              <div className="emailWrapper">
                {this.props.newEmail && <Icon symbol='mailyettoverify' />}

                {!this.props.newEmail && <Icon symbol='mail' />}

                <label className="emailLink">{this.props.email}</label>
              </div>
              {this.props.connection ? null : <LinkWrapper className="button small-btn btn btn-secondary" to={buildPath(routePaths.changeEmail, null)} >{local.accountSettings.editEmail}</LinkWrapper>}
            </small>
            {!this.props.forceSignout && this.props.newEmail && <div className="nonVerifyEmail"><small>{local.formatString(local.accountSettings.verifyPendingEmail,this.props.newEmail)}</small> </div> }
          </div>
        </div>
      </div>
    );
  }
}

PreferenceEmailChange.propTypes = {
  actions: PropTypes.shape({
    loadNewEmail: PropTypes.func.isRequired,
    getIsAdminForAnyOrganization: PropTypes.func.isRequired,
    removeChangeError: PropTypes.func.isRequired
  }).isRequired
};
const mapStateToProps = state => ({
  email: state.identity.email,
  newEmail:state.preferences.newEmail,
  forceSignout: state.preferences.forceSignout,
  IsAdminForAnyOrganization: state.identity.IsAdminForAnyOrganization,
  connection: state.authConnection.connection
});

function mapDispatchToProps(dispatch) {
  const actions = {
    getIsAdminForAnyOrganization,
    loadNewEmail,
    removeChangeError };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps,mapDispatchToProps)(PreferenceEmailChange);
