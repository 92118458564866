import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';

function AutoRedirect({redirectInSeconds, redirectTo, children}) {
  const [doRedirect, setDoRedirect] = useState();

  useEffect(() => {
    setTimeout(() => {
      setDoRedirect(true);
    }, redirectInSeconds * 1000);
  }, []);

  if(doRedirect)
    return <Redirect to={redirectTo} />;

  return children;
}

AutoRedirect.propTypes = {
  redirectInSeconds: PropTypes.number.isRequired,
  redirectTo: PropTypes.string.isRequired,
  children: PropTypes.node
};

AutoRedirect.defaultProps = {
  children: null
};

export default AutoRedirect;
