import { ObservationType } from "services/enums";

/* eslint-disable max-len */
export default {
  accept: 'Aceptar',
  acceptingInvitation: 'Aceptando invitación...',
  connected:'Conectando ...',
  disconnected:'Desconectando ...',
  accountSettings: {
    pageTitle: 'Configuraciones de la cuenta',
    newEmail: 'Correo electrónico',
    verifyEmail: 'Actualizar',
    resendVerificationCode: 'Reenviar codigo',
    verifyPendingEmail: '{0}: verificación pendiente. Haga clic en editar para verificar.',
    editEmail: 'Editar',
    verificationCode: 'Código de verificación',
    cancel: 'Cancelar',
    changeEmailMessage: 'Se cerrará la sesión de la aplicación después de verificar su nuevo correo electrónico. Utilice el nuevo correo electrónico para iniciar sesión nuevamente.',
    verificationCodeMessage: 'Envió un código de 4 dígitos a {0} para confirmar',
    confirmMessageFirst: 'Su correo electrónico se actualizó correctamente a {0}.',
    confirmMessageSecond: 'No olvide establecer su preferencia de acceso rápido cuando inicie sesión.',
    confirmMessageThree: 'Se cerrará la sesión en 10 segundos para completar el cambio. Utilice un nuevo correo electrónico para iniciar sesión nuevamente.',
    validationEmail: 'Por favor ingrese el nuevo correo electrónico',
    validationAminCannotChangeEmail: 'Tiene acceso a la información del panel. En este momento, no permitimos el acceso de autoservicio para actualizar su correo electrónico, ya que tiene acceso a datos confidenciales.',
    contactUs: 'Contacta con nosotros',
    resendCode: 'Código enviado'
  },
  activity: {
    visTitle: 'De {0}',
    visDurationlabel: 'Duración: {0} min.',
    visIntensityLabel: 'Puntuación de intensidad: {0}'
  },
  activityTitleTab: 'Agregar Ejercicio',
  activityLogHeaderDefault: 'Agregar Ejercicio',
  activityFormNameLabel: 'Ejercicio',
  activityFormNotesPlaceholder: 'Agregar notas sobre esta ejercicio',
  activityStartDateTimeLabel: 'Hora de inicio',
  activityDurationLabel: 'Duración:',
  and: '{0} y {1}',
  observation: {
    context: {
      contextHeader: 'Estos datos están asociados con la tarea \'{0}\' de la ruta {1}.',
      dateAfter: 'Vence a partir del {0}',
      dateBefore: 'Vence antes del {0}',
      dateBetween: 'Vence entre el {0} y el {1}'
    },
    bodyArms: {
      title: 'Brazos',
      logTitle: 'Registro de brazos',
      noDataText: 'Aún no se han registrado datos sobre la brazos.',
      tileNoDataText: 'Agregue su primera brazos'
    },
    bodyChest: {
      title: 'Pecho',
      logTitle: 'Registro de pecho',
      noDataText: 'Aún no se han registrado datos sobre la pecho.',
      tileNoDataText: 'Agregue su primera pecho'
    },
    bodyFat: {
      title: 'Grasa corporal',
      noDataText: 'Conecte un dispositivo para comenzar a ver las medidas de su grasa corporal aquí.'
    },
    bodyHips: {
      title: 'Cadera',
      logTitle: 'Registro de cadera',
      noDataText: 'Aún no se han registrado datos sobre la cadera.',
      tileNoDataText: 'Agregue su primera cadera'
    },
    bodyThighs: {
      title: 'Muslos',
      logTitle: 'Registro de muslos',
      noDataText: 'Aún no se han registrado datos sobre la muslos.',
      tileNoDataText: 'Agregue su primera muslos'
    },
    bodyWaist: {
      title: 'Cintura',
      logTitle: 'Registro de cintura',
      noDataText: 'Aún no se han registrado datos sobre la cintura.',
      tileNoDataText: 'Agregue su primera cintura'
    },
    bloodOxygen: {
      title: 'Oxígeno en sangre',
      noDataText: 'Conecte un dispositivo para empezar a ver las lecturas de su porcentaje de oxígeno en sangre aquí.'
    },
    bloodGlucose: {
      title: 'Glucosa en sangre',
      goalTitle:'Objetivo de glucosa en sangre',
      noDataText: 'Comience a hacer seguimiento de su glucosa en sangre.',
      glucoseGoal:'Establecer rango objetivo de glucosa en sangre',
      glucoseRange:'Rango de glucosa en sangre (mg/dL)',
      avgPerDay: 'Promedio por día',
      avgPerWeek: 'Promedio de la semana pasada',
      averageGlucose: 'En promedio, su nivel de glucosa es',
      averagePeriod : 'por día la semana pasada',
      unit:'mg/dL',
      noData:'Sin datos',
      inRange: 'En el rango'
    },
    bloodKetone: {
      title: 'Cetona de sangre',
      noDataText: 'Comience a rastrear su cetona en sangre.',
      barChartFormat: '{0} mg/dL'
    },
    bloodPressure: {
      title: 'Presión arterial',
      noDataText: 'Conecte un dispositivo para comenzar a ver las lecturas de su presión arterial aquí.',
      systolicChartLabel: 'Sistólica',
      diastolicChartLabel: 'Diastólica',
      systolicFormLabel: 'Sistólica',
      systolicFormSubLabel: 'Número superior',
      diastolicFormLabel: 'Diastólica',
      diastolicFormSubLabel: 'Número inferior',
      diastolicValueHigherError: 'Debe ser inferior a la sistólica',
      goalPageTitle: 'Objetivo de presión arterial',
      goalPageContent: 'Establezca su rango objetivo de presión arterial',
      goalPageSystolicLabel: 'Rango sistólico (mmHg)',
      goalPageTopNumberLabel: 'Número superior',
      goalPageDiastolicLabel: 'Rango diastólico (mmHg)',
      goalPageBottomNumberLabel: 'Número inferior',
      goalPageToLabel: 'a',
      currentGoalRange: 'Rango de objetivos actual',
      colon: ':',
      validateRange: 'Debe ser mayor que del valor',
      averagePressure: 'En promedio, su nivel de presión es',
      averagePeriod : 'por día la semana pasada ',
      averagePerWeek : 'Promedio por semana',
      unit:'mmHg',
      noData:'Sin datos'
    },
    heartRate: {
      title: 'Frecuencia cardíaca',
      logTitle: 'Registro de frecuencia cardíaca',
      noDataText: 'Aún no se han registrado datos sobre la frecuencia cardíaca.',
      tileNoDataText: 'Agregue su primera frecuencia cardíaca'
    },
    heartRateVariability: {
      title: 'Variabilidad del ritmo cardíaco'
    },
    height: {
      title: 'Altura',
      logTitle: 'Registro de altura',
      noDataText: 'Aún no se han registrado datos sobre la altura.',
      tileNoDataText: 'Agregue su primera altura'
    },
    immunization: {
      title: 'Vacunas',
      tileNoDataText: 'Aún no se han registrado vacunas.'
    },
    insulin: {
      title: 'Insulina',
      noDataText: 'Conecte un dispositivo para comenzar a ver las lecturas de su insulina.'
    },
    labResult: {
      title: 'Informes de diagnóstico',
      tileNoDataText: 'Aún no se han registrado informes de diagnóstico.',
      viewEncounters: 'Ver por encuentro',
      viewTrends: 'Ver tendencias',
      component: 'Componente',
      value: 'tu valor',
      noTrends: 'No se encontraron tendencias de laboratorio.'
    },
    ehrLabResult: {
      title: 'Resultados de laboratorio de EHR',
      tileNoDataText: 'Aún no se han registrado resultados de laboratorio.'
    },
    medication:{
      medication: 'Notas de medicación'
    },
    nutritions: {
      title: 'Nutrición',
      noDataText: 'Conecte un dispositivo para comenzar a ver sus Nutrición diarias aquí.',
      tileTitle: 'Nutrición diarias',
      barChartFormat: '{0} Calorías',
      carbohydrate: 'Carbohydrate',
      protein: 'Proteína',
      fat: 'Gordo',
      calories: 'calorías',
      cal: 'calorías',
      averageCalories: 'Promedio de calorías/día',
      today: 'Este Dia',
      addGoal: 'Añadir objetivo',
      changeGoal: 'Cambiar objetivo',
      currentGoal: 'El objetivo actual es',
      carbsGoal: 'carbohidratos',
      proteinGoal: 'proteína',
      fatGoal: 'Gordo',
      calGoal: 'meta de calor',
      goal: 'meta',
      actual: 'actual',
      macrosMessage: 'Las macros deben ser iguales al 100 %',
      averageCal: 'En promedio, consumiste',
      macroGoal: 'Metas macro',
      calorieGoal: 'Meta de calorías (cal/día)',
      nutritionGoal: 'Establezca una meta de nutrición diaria.',
      caloriesGoal: 'calorías',
      week: 'semana',
      perDay: 'por día la semana pasada.',
      macroGoalPage:'macroobjetivo',
      calorieObservationGoal: 'Meta de calorías (cal/día)'
    },
    peakFlow: {
      title: 'Flujo máximo',
      noDataText: 'Conecte un dispositivo para comenzar a ver sus lecturas de flujo máximo aquí.',
      peakFlowFormLabel: 'Flujo máximo (L/minuto)'
    },
    sleep: {
      title: 'Dormir',
      noDataText: 'Conecte un dispositivo para comenzar a ver sus lecturas de sueño aquí.',
      hoursLabel: 'hr',
      minutesLabel: 'min'
    },
    steps: {
      title: 'Pasos',
      noDataText: 'Conecte un dispositivo para comenzar a ver sus pasos diarios aquí.',
      tileTitle: 'Pasos diarios',
      barChartFormat: '{0} pasos',
      averagePerDay : 'Promedio por día',
      averageData:'promedio de la semana',
      noData:'Sin datos',
      stepsRange: 'Meta de pasos por día.'
    },
    temperature: {
      title: 'Temperatura',
      logTitle: 'Registro de temperatura',
      noDataText: 'Aún no se han registrado datos sobre la temperatura.',
      tileNoDataText: 'Agregue su primera temperatura'
    },
    weight: {
      title: 'Peso',
      logTitle: 'Registro de peso',
      noDataText: 'Aún no se han registrado datos sobre el peso.'
    },
    floorsClimbed: {
      title: 'Floors Climbed',
      logTitle: 'Floors Climbed',
      noDataText: 'No Floors Climbed data has been recorded yet.'
    }
  },
  setGoal: 'Establece tu objetivo aquí.',
  checkEmail: 'Por favor, compruebe su correo electrónico. Le hemos enviado un correo electrónico a {0}.',
  customizeDashboard: 'Atajos',
  sendingConfirmationEmail: 'Enviando otro correo electrónico de confirmación.',
  daysShortLabel: 'd',
  hoursShortLabel: 'h',
  minutesShortLabel: 'm',
  secondsShortLabel: 's',
  daysLabel: 'días',
  hoursLabel: 'horas',
  minutesLabel: 'minutos',
  secondsLabel: 'segundos',
  activityIntensityLabel: 'Intensidad',
  low: 'Ligera',
  medium: 'Moderada',
  high: 'Enérgica',
  lowActivityHelper: 'Puede cantar una canción',
  mediumActivityHelper: 'Puede mantener una conversación',
  highActivityHelper: 'Puede decir unas pocas palabras',
  add: 'Añadir',
  agree: 'Acepto',
  app: 'Calcium',
  aspartof: 'como parte de',
  back: 'Volver',
  bmiCategoryNormal: '(Normal)',
  bmiCategoryObese: '(Obeso)',
  bmiCategoryOverweight: '(Sobrepeso)',
  bmiCategoryUnderweight: '(Bajo peso)',
  bmiDetailsBmi: 'IMC reciente',
  bmiTitle: 'IMC',
  bmiHeader: 'Índice de masa corporal',
  bmiNoDataText: 'Se requieren datos de altura y peso para calcular el IMC.',
  bodyMeasurementsHeader: 'Medidas corporales',
  browserInformationDisclaimer: 'La siguiente información se está recopilando para uso diagnóstico.',
  cancel: 'Cancelar',
  close: 'Cerrar',
  continue: 'Continuar',
  skip:'Saltar',
  complete:'Completo',
  next:'Continuar para establecer la meta',
  copyLink: 'Copiar enlace',
  copyright: 'Calcium LLC',
  createSelfProfileTitle: 'Cree su perfil',
  createOtherProfileTitle: 'Crear perfil',
  currentSelection: 'Selección actual',
  activityText: 'actividad',
  dashboard: {
    allResults: 'Ver todo',
    data: {
      header: 'Datos de salud'
    },
    dataConnections: {
      header: 'Orígenes de datos',
      tile: 'Dispositivos y datos'
    },
    pathways: {
      activeHeader: 'Mis rutas',
      activeHeader_v1dashboard:'Mis actividades para hoy',
      completedHeader: 'Rutas completadas',
      header: 'Explorar rutas',
      description: 'Nuestros programas personalizados de mejora de la salud.',
      nextTask: 'Siguiente tarea',
      dueToday: 'Vence hoy'
    },
    clickToSee: 'Cliquez pour voir ',
    newDashboard: 'Nouveau tableau de bord'
  },
  healthIndex: {
    healthIndexToday: 'My HealthIndex hoy',
    improveAccuracyOfHealthIndex: 'Informe su puntaje My HealtIndex cada día completando estas tareas:',
    isProfileAccurate: 'Asegúrese de que su perfil sea exacto',
    ageAndGender: 'La edad y el género son importantes',
    connectYourDevice: 'Conecte sus dispositivos de salud',
    activityTrackers: 'Monitores de actividad, básculas inteligentes, brazaletes de presión arterial, etc.​',
    smartScales: 'Básculas inteligentes (dentro, etc.)',
    bloodPressure: 'Manguitos de presión arterial​',
    addMoreInformation: 'Agrega información sobre ti',
    heightWeight: 'Altura, peso, medidas corporales',
    pastMonthTitle:'El mes pasado',
    todayTitle:'Hoy dia',
    healthIndexTooltip: 'HealthIndex'
  },
  // To Do Beta User
  healthData_v1Dashboard: {
    title : 'mis datos de salud',
    healthIndex: 'HealthIndex',
    exercise: 'ejercicio esta semana',
    litersPerMinute: 'litros/min',
    calories:'calorías',
    myRecords: 'mis registros de salud',
    addOtherData:'añadir otros datos',
    generateHealthIndex: 'Generar mi HealthIndex',
    trackSteps: 'Pasos de sincronización',
    trackExercise: 'Ejercicio de seguimiento',
    addInfo: 'Añadir información',
    addImmunization: 'Agregar información de vacunación'
  },
  medication_v1Dashboard: {
    setMedicationTitle : 'Establecer medicamentos/Recordatorios de suplementos',
    tookItTitle: 'lo tom'
  },
  dateDisplayToday: 'Hoy',
  dateDisplayTomorrow: 'Mañana',
  dateDisplayYesterday: 'Ayer',
  dateDisplayDayFormat: 'dddd',
  decline: 'Rechazar',
  deleted: 'Borrada',
  deleting: 'Borrando...',
  devices: {
    connectedDevicesTitle   :'dispositivos conectados',
    connectButton: 'Conecte un dispositivo',
    errorMessage: 'No se puede preparar para el dispositivo y las conexiones de datos',
    explanation: 'Conectarse a uno de los muchos dispositivos compatibles:',
    deviceNames:'Apple watch, Fitbit, Garmin…',
    seeAllDevices:'Ver todas las opciones del dispositivo:',
    dotDescription: 'Nota: El punto de color indica que los datos provienen de un tercero.',
    overviewTitle: 'Dispositivos y datos',
    connectError: 'Error al abrir el administrador de dispositivos. Haga clic para volver a intentarlo.',
    syncTitle: 'Sincronizar datos automáticamente',
    needADeviceQuestion: '¿Necesita un dispositivo que funcione con Calcium?',
    marketplaceLink: 'Obtenga un dispositivo compatible aquí.',
    healthKitExplanation: 'Haga clic aquí para conectar o desconectar su cuenta de Apple Health.',
    healthKitButton: 'Apple Health',
    healthKitButtonExplanationFirst:'Tenga en cuenta,',
    healthKitButtonExplanation:' que solo podrá desconectarse de Apple Health a través de su dispositivo Apple.',
    googleFitButtonExplanation:' que solo podrá desconectarse de Google Fit a través de su dispositivo Android.',
    disconnectExplanation:'¿Estás seguro de que deseas desconectar tu dispositivo? Si desconecta su dispositivo, los datos dejarán de sincronizando.',
    disconnectConfirmationTitle:'¿Desconectar el dispositivo?',
    disconnect: 'desconectar',
    connectDevices: 'Conecte dispositivo',
    connectLink: 'Conecte',
    deviceConnectExplanation: 'Ningún dispositivo detectado. Conecta un dispositivo para unirte al desafío.',
    syncIsInProgress : 'La sincronización de datos está en curso...'
  },
  privateProfile:'Perfil privado',
  privateProfileDescription:'Esta información nunca será visible públicamente y solo se compartirá con aquellos que usted designe.',
  publicProfile:'Perfil público',
  displayName:'Nombre para mostrar',
  firstName:'Primer nombre',
  done: 'Hecho',
  edit: 'Editar',
  editProfile: 'Editar perfil',
  error: 'Se ha producido un error',
  errorLoadProfile: 'Lo sentimos, no hemos podido cargar su perfil.  Compruebe su conexión a Internet e inténtelo de nuevo.',
  errorPasswordError: 'La contraseña no está permitida, puede que sea muy común',
  errorPasswordStrengthError: 'La contraseña debe tener al menos 8 caracteres',
  errorUserExistsError: 'Correo electrónico ya registrado',
  errorInvalidEmailFormat: 'Formato de correo electrónico inválido',
  errorCodeMessage: {
    inviteAlreadyExists: 'Ya existe una invitación de compartir para el usuario con este correo electrónico',
    inviteSelf: 'No puedo invitarme',
    inviteUsed: 'Esta invitación ya no es válida.',
    inviteExistingRelationship: 'El usuario con este correo electrónico ya tiene acceso a este perfil.'
  },
  feedbackEmailSubject: 'Comentarios de Calcium',
  formAvatarLabel: 'Avatar',
  formBirthdateLabel: 'Fecha de nacimiento',
  formBloodGlucoseLabel: 'Glucosa en sangre (mg/dL)',
  formBloodKetoneLabel: 'Cetona en sangre (mg/dL)',
  formBodyChestLabel: 'Pecho (pulgadas)',
  formBodyChestPlaceholder: 'Ejemplo: 30',
  formBodyHipsLabel: 'Cadera (pulgadas)',
  formBodyHipsPlaceholder: 'Ejemplo: 30',
  formBodyThighsLabel: 'Muslos (pulgadas)',
  formBodyThighsPlaceholder: 'Ejemplo: 30',
  formBodyWaistLabel: 'Cintura (pulgadas)',
  formBodyWaistPlaceholder: 'Ejemplo: 30',
  formBodyArmsLabel: 'Brazos (pulgadas)',
  formBodyArmsPlaceholder: 'Ejemplo: 30',
  formCancel: 'Cancelar',
  takeMeLater:'Lo comprobaré más tarde',
  formContinue: 'Continuar',
  formDayLabel: 'Día',
  formEmailLabel: 'Correo electrónico',
  formResetEmailLabel: 'Correo electrónico',
  formGenderLabel: 'Sexo',
  formHeartRateLabel: 'Ritmo cardíaco (bpm)',
  formHeartRatePlaceholder: 'Ejemplo: 120',
  formHeightFeetLabel: 'Pies',
  formHeightInchesLabel: 'Pulgadas',
  formImmunizationLabel: 'Tipo de inmunización',
  formLabResultLabel: 'Tipo de informe de diagnóstico',
  formLast4Ssn: 'Últimos 4 dígitos del número de seguridad social',
  medicationDoses: 'Dosis',
  noMedicationDoses: 'No se han registrado dosis de medicamentos que coincidan con su búsqueda.',
  noMedicationScheduled: 'No hay medicamentos programados para este día.',
  medications: {
    deleteButton: 'Borrar medicamentos',
    deleteWarning: 'Advertencia: Borrar este medicamento también eliminará todas sus dosis registradas'
  },
  medicationDoseLogHeaderDefault: 'Dosis del medicamento',
  medicationDoseLogHeader: 'Añadir {0} dosis',
  medicationDoseAddTitleTab: 'Añadir dosis de medicamento',
  medicationDosageCallToAction: 'Añadir dosis',
  medicationMostRecentDose: '{0} tomadas {1}',
  medicationNoTypicalDose: 'No se encontraron dosis habituales',
  medicationFormTypicalDose: 'Dosis habitual:',
  medicationLogHeader: 'Añadir medicamento',
  medicationEditHeader: 'Editar medicamento',
  medicationAddTitleTab: 'Añadir medicamento',
  medicationEditTitleTab: 'Editar medicamento',
  medicationFormNameLabel: 'Nombre del medicamento',
  medicationFormStartLabel: 'La dosis de medicación comienza a partir de',
  medicationFrequencyLabel: '¿Cuándo se toma esta dosis?',
  medicationAddTimingLabel: 'Agregar tiempo',
  medictaionTimeSlotErrorLabel: 'Se debe ingresar al menos un intervalo de tiempo',
  medictaionSameTimeSlotErrorLabel: 'Los horarios programados no se pueden repetir',
  medicationTimeSlotInfoLabel: 'Haga clic en "agregar tiempo" para establecer la hora.',
  medicationTimingLabel: 'Establecer tiempo',
  medicationTimingSetLabel: 'Colocar',
  medicationFormNotesPlaceholder: 'Añadir notas sobre este medicamento',
  medicationFormFrequencyPlaceholder: 'Agregue cualquier frecuencia sobre este medicamento',
  medicationFormTypicalDosePlaceholder: 'Añada su dosis habitual',
  medicationFormTypicalDoseLabel: 'Dosis habitual (opcional)',
  medicationDoseFormlDoseLabel: 'Dosis (opcional)',
  medicationDoseFormlDosePlaceholder: 'Dosis tomadas',
  medicationDoseFormSubmit: 'Agregar medicina',
  medicationDoseOverdue: 'Atrasado',
  medicationDoseTakenow: 'Tómalo ahora',
  medicationDoseTaken: 'Tomado',
  medicationDoseSkipped: 'Omitido',
  medicationDoseTake: 'Llevar',
  medicationDoseSkip: 'Saltar',
  morning: 'Mañana',
  afternoon: 'Tarde',
  evening: 'Atardecer',
  nighttime: 'Noche',
  formMonthLabel: 'Mes',
  formNameLabel: 'Nombre de preferencia',
  formNotesLabel: 'Notas (opcional)',
  formNotesPlaceholder: '¿Qué estaba pasando en ese momento?',
  formPasswordLabel: 'Contraseña',
  formPasswordRequirementLabel: 'Contraseña (mínimo 8 caracteres)',
  formPrivacyPolicyAgreeText: 'Al hacer clic en "{0}", usted acepta nuestras {1} y {2}.',
  formProfileHeader: 'Perfil',
  formProfileSave: 'Guardar perfil',
  formSave: 'Guardar',
  formSignInButton: 'Iniciar sesión',
  formContinueButton: 'Continuar',
  formSignInHeader: 'Iniciar sesión',
  formSignInForgotPassword: '¿Ha olvidado la contraseña?',
  formSignInResetPasswordLink: 'Restablecer',
  formSignInJoin: 'Crea una cuenta.',
  formSignInJoinText: '¿Es nuevo en Calcium?',
  formSubmit: 'Enviar',
  formTemperatureLabel: 'Temperatura (ºF)',
  formTemperaturePlaceholder: 'Temperatura',
  formTimestampLabel: 'Indicación de tiempo',
  formUpdate: 'Actualizar',
  formWeightLabel: 'Peso (lbs)',
  formWeightPlaceholder: 'Peso',
  formYearLabel: 'Año',
  genderFemale: 'Femenino',
  genderMale: 'Masculino',
  genderOther: 'Otros',
  genderUnknown: 'Desconocido',
  generalFeedbackMessage: 'Agradecemos sus comentarios y nos encantaría saber sobre su experiencia con Calcium. Háganos saber lo que le gusta y lo que podría ser mejorado.',
  generatingLink: 'Creando enlace para compartir...',
  goToDashboard: 'Ir a casa',
  healthScoreLabel: 'My HealthIndex',
  totalHealthScore: 'de 1000',
  healthDataHeader: 'Datos de salud',
  healthDataBodyMeasurementsSectionHeader: 'Medidas corporales',
  healthDataVitalsSectionHeader: 'Vitales',
  healthDataOtherSectionHeader: 'Otros',
  healthDashboardHeader: 'Casa',
  healthChampionScore: 'Puntuación Calcium',
  healthBadge: {
    badge: {
      title: 'Logros',
      milestones: 'Hitos',
      taskCompleted: 'Tareas completadas',
      explorer: 'Insignias de explorador',
      monthly: 'Desafíos'
    },
    unknown: {
      message: 'Estado desconocido',
      status: 'Por favor, rellene correctamente las tareas obligatorias.',
      calculatingStatus: 'Calculando el nuevo estado.', // 'Calculating new status.', // non-official
      errorStatus: 'Se produjo un error al recuperar el último estado' // 'An error occurred retrieving the latest status' // non-official
    },
    verified: {
      message: '¡Enhorabuena!',
      status: 'No presenta síntomas.'
    },
    unverified: {
      message: 'Advertencia de estado',
      status: 'Por favor, asegúrese de rellenar correctamente las tareas obligatorias. Si informó sobre síntomas, siga las pautas de los CDC en los próximos pasos.'
    },
    updated: 'Actualizado {0}', //'Updated {0}', // non-official
    onDay: 'En {0}', //'On {0}', // non-official
    dateAtTime: 'MM/DD/YYYY [at] h:mm a', // locale consideration
    notification: {
      congratulations: '¡Felicidades!',
      earnedABadge: 'Te has ganado una medalla.',
      greatWork: '¡Mantener el buen trabajo!',
      viewBadge: 'Ver mi insignia',
      viewAchievements: 'Ver mis logros'
    }
  },
  healthDataLink: 'Datos de salud',
  healthData: {
    connect: {
      identityForm: {
        address1Label: 'Dirección',
        address2Label: 'N.º de suite/apartamento',
        addressCityLabel: 'Ciudad',
        addressStateLabel: 'Estado/provincia',
        birthdateLabel: 'Fecha de nacimiento',
        firstNameLabel: 'Primer nombre',
        lastNameLabel: 'Apellido',
        genderLabel: 'Sexo',
        profileLabel: 'Verifica tu identidad',
        zipCodeLabel: 'Código postal actual',
        submitButton: 'Iniciar verificación',
        validationBirthdate: 'La fecha de nacimiento no es válida',
        validationDay: 'El día debe estar entre 1 y 31',
        validationMonth: 'El mes debe ser estar 1 y 12',
        validationZipCode: 'El código postal debe tener 5 dígitos',
        disclaimer: 'Al hacer clic en Iniciar verificación, certifica que tiene autorización legal para acceder a la Información de salud protegida de esta persona.'
      },
      identityVerification: {
        challengeMessage: 'Para su seguridad, necesitamos un poco más de información a fin de completar su verificación.',
        contactCta: 'Contactar con el servicio de asistencia',
        continueVerificationAction: 'Continuar verificación',
        emailSubject: 'Problemas de verificación de identidad',
        emailMessage: 'Tengo problemas para verificar mi identidad y necesito ayuda.',
        introDetails: 'Calcium se dedica a proteger su privacidad. Ayúdenos a verificar su identidad.',
        introHeader: 'Conecte su historia clínica',
        processingMessage: 'Procesando...',
        ssnHeader: 'Verificar identidad',
        ssnParagraph1: 'Para asegurarnos de que lo hemos encontrado, necesitamos que verifique los últimos cuatro dígitos de su número de seguridad social (SSN, por sus siglas en inglés).',
        ssnParagraph2: 'Utilizamos esta información solo para fines de verificación, y no la almacenaremos una vez completada.',
        startVerificationAction: 'Iniciar verificación',
        steps: '1. La verificación de identidad suele tardar entre cinco y diez minutos.\n\n3. Puede intentar este proceso **una vez**.',
        title: 'Verifique su identidad',
        toastProcessing: 'Procesando',
        toastSuccess: '¡Identidad verificada!',
        toastError: 'No se pudo verificar su identidad',
        verificationErrorHeader: 'Lo sentimos',
        verificationErrorDetails: 'No se pudo verificar su identidad. Inténtalo de nuevo.\n\nNota: Si no puede verificar su identidad en este próximo intento, su cuenta será bloqueada.',
        verifyIdentiyHeader: 'Verificar identidad',
        warning: 'Nota: es importante que ingrese la siguiente información con precisión para evitar que se bloquee el sistema. Evite las distracciones durante este proceso.',
        identityVerificationSupportTicketCreated: 'Se ha creado un ticket de soporte y alguien de nuestro equipo de soporte se comunicará con usted con los próximos pasos.',
        noRecordsFoundErrorMessage: 'Conectamos con éxito su perfil a nuestra red de registros, pero no pudimos ubicar sus registros. \n\nEsto significa que actualmente no hay registros electrónicos vinculados a su identidad.\n\nNo pierda la esperanza. Trabajamos continuamente para mejorar nuestra red.\n\nMientras tanto, nos encantaría saber más sobre sus registros para que podamos mejorar nuestra capacidad de localizarlos.',
        importRecordsFailedErrorMessage: 'Se produjo un error al cargar sus registros.\n\nNos gustaría ayudarlo a resolver este problema.',
        attemptFailedErrorMessage: 'Su identidad no pudo ser verificada en este momento.',
        verify: 'Verificar',
        verifyData: 'Para verificar su identidad para conectar sus registros de salud, complete toda la información de manera precisa y completa.',
        warningFirstAttempt: 'Si no podemos verificar su identidad en este último intento, su cuenta será bloqueada. Verifique la precisión.',
        warningSecondAttempt: 'Su cuenta ahora está bloqueada. Se ha creado un ticket de soporte y nuestro equipo se comunicará con usted para resolverlo.',
        please:'Por favor ',
        contactSupport:'soporte de contacto',
        questions:'  con cualquier pregunta adicional.',
        successNavigateMessage: 'Volverá a la página de datos de salud en 10 segundos.'
      },
      status: {
        actionConnect: 'Conectar',
        actionConnectLong: 'Conecte su historia clínica',
        complete: '¡Completado!',
        connected: 'Conectado',
        connecting: 'Conectando...',
        header: 'Historia clínica',
        lastSynced: 'Sincronizado {0}',
        noData: 'No encontramos su historia clínica ¡No se desanime! ¡Estamos expandiendo nuestra red continuamente!',
        processing: 'Procesando',
        returnToDashboard: 'Vuelve a casa',
        searchComplete: 'Búsqueda completada',
        searching: 'Ahora estamos buscando su información clínica en nuestras redes.',
        searchingDisclaimer: 'Esto podría tardar un tiempo en completarse, así que siéntase libre de continuar usando la aplicación. Le informaremos cuando hayamos terminado.',
        sorry: 'Lo sentimos',
        startedSync: 'Sincronización iniciada',
        successDetails: 'Gracias por completar el proceso de verificación de identidad. Su identificación ha sido verificada y sus registros de salud comenzarán a completarse dentro de las 24 horas.',
        syncing: 'Sincronizando...',
        syncDataAction: 'Sincronizar ahora',
        syncError: 'Algo salió mal. Por favor, vuelva más tarde.',
        viewHealthRecords: 'Conecte su historia clínica',
        connectingProfile: 'Conectando su perfil a la red de registros',
        locatingRecords: 'Localización de sus registros',
        loadingRecords: 'Cargando sus registros en Calcium'
      },
      error: {
        checkbox: {
          receivedElectronicRecords: 'He recibido registros electrónicos de mi (s) médico (s)',
          notReceivedElectronicRecords: 'Nunca he recibido registros electrónicos de mi (s) médico (s)'
        },
        messages: {
          title: {
            connectingFailed: 'Conexión fallida',
            recordsLocatingFailed: 'No se pudieron localizar los registros',
            recordsImportingFailed: 'No se pudieron importar los registros'
          },
          content: {
            connectingFailed: 'No pudimos conectar su perfil a la red de registros. Esto significa que ninguno de sus médicos ha conectado sus expedientes médicos con su identidad en nuestra red O hay otra persona cuya identidad es similar a la suya y nuestro sistema no puede diferenciar entre usted.',
            recordsLocatingFailed: 'Conectamos con éxito su perfil a nuestra red de registros, pero no pudimos localizar sus registros. Esto significa que actualmente no hay registros de salud electrónicos que podamos vincular a su identidad, O que el intento se agotó antes de localizar cualquier registro de su identidad.',
            recordsLocatingFailedDocter: 'Es posible que ninguno de sus médicos utilice registros electrónicos. Nos encantaría saber más sobre sus registros para que podamos mejorar nuestra capacidad de localizarlos.\n\nComuníquese con nosotros haciendo clic en "Obtener ayuda" en el menú para informarnos sobre sus registros.',
            recordsLocatingFailedForSurvey: 'Nos encantaría saber más sobre sus registros para que podamos mejorar nuestra capacidad de localizarlos.',
            recordsImportingFailed: 'No pudimos cargar sus registros en Calcium. Esto significa que localizamos sus registros, pero el sistema agotó el tiempo de espera O una de las conexiones de red falló durante el intento.',
            recordsImportingFailedForSurvey: 'Hmm ... Parece que algo puede estar roto.',
            recordsLocatingFailedUnkownError: 'Lo sentimos, no pudimos cargar sus registros.',
            recordsLocatingFailedUnkownErrorDescription: 'Se produjo un error al recuperar sus registros. Estamos trabajando para solucionarlo.',
            disconnectedEncountersMessage: 'Aún no se han registrado Encuentros',
            disconnectedProceduresMessage: 'Aún no se han registrado Procedimientos',
            disconnectedLabResultsMessage: 'Aún no se han registrado Resultados de Laboratorio',
            disconnectedImmunizationMessage: 'Aún no se han registrado Vacunas',
            disconnectedConditionsMessage: 'Aún no se han registrado Condiciones',
            connectedMessage: 'La función no está disponible temporalmente'
          }
        },
        buttons: {
          tellUsMore: 'Cuéntanos más',
          mayBeLater: 'Quizas mas tarde',
          nevermind: 'No importa',
          tryAgain: 'Toma de nuevo ahora'
        },
        beacon: {
          subject: 'Lo que esperaba encontrar en mi EHR de Calcium',
          body: 'Cuéntenos un poco sobre lo que estaba buscando al conectar su EHR.'
        }
      }
    },
    conditions: {
      connectMessage: 'Conecte su historia clínica para ver sus enfermedades.',
      noConditions: 'No se encontraron enfermedades',
      title: 'Enfermedades',
      linkText: 'Enfermedades',
      diagnosed: 'Diagnosticadas',
      severity: 'Gravedad',
      reportedBy: 'Informado por',
      remission: 'Remisión',
      unspecified: 'Sin especificar'
    },
    encounters: {
      connectMessage: 'Conecte su historia clínica para ver sus interacciones con los médicos y otros proveedores de atención médica.',
      title: 'Encuentros',
      diagnoses: 'Diagnósticos',
      linkText: 'Encuentros',
      location: 'Ubicación',
      noRecords: 'No se encontraron encuentros',
      provider: 'Proveedor',
      started: 'Iniciado',
      stopped: 'Interrumpido'
    },
    carePlan: {
      connectMessage: 'Conecte su historia clínica para ver sus interacciones con los médicos y otros proveedores de atención médica.',
      title: 'Plan de cuidado',
      linkText: 'Plan de cuidado',
      noRecords: 'No se encontró ningún plan de cuidados',
      treatmentPlan:'Plan de tratamiento',
      provider:'Proveedor',
      carePlanTitle:'Título',
      expandAction: 'Expandir',
      collapseAction: 'Colapsar'
    },
    immunizations: {
      connectMessage: 'Conecte su historia clínica para ver su historial de vacunas.',
      dose: 'Dosis',
      title: 'Vacunas',
      linkText: 'Vacunas',
      lotNumber: 'N.º de lote',
      manufacturer: 'Fabricante',
      noRecords: 'No se encontraron registros sobre vacunas',
      practitioner: 'Profesional médico',
      noImmunization: 'Cargar tarjeta de vacunación COVID-19',
      covidVaccination: 'Vacunación Covid-19',
      deletePrompt: '¿Estás seguro de que deseas eliminar este grupo de imágenes? Esta operación no se puede deshacer.',
      deleteImmunization: 'Eliminar estas imágenes?',
      deleteYes: 'Eliminar Imagen',
      deleteNo: 'cancelar'
    },
    labs: {
      connectMessage: 'Conecte sus registros de salud para ver sus informes de diagnóstico.',
      title: 'Informes de diagnóstico',
      linkText: 'Informes de diagnóstico',
      noRecords: 'No se encontraron informes de diagnóstico',
      expandAction: 'Expandir',
      collapseAction: 'Colapsar',
      deletePrompt: '¿Estás seguro de que deseas eliminar este grupo de imágenes? Esta operación no se puede deshacer.',
      deleteLabResult: 'Eliminar estas imágenes?',
      deleteYes: 'Eliminar Imagen',
      deleteNo: 'cancelar'
    },
    procedures: {
      connectMessage: 'Conecte su historia clínica para ver su historial de procedimientos médicos.',
      title: 'Procedimientos',
      status: 'Estado',
      linkText: 'Procedimientos',
      location: 'Ubicación',
      noRecords: 'No se encontraron procedimientos',
      provider: 'Proveedor',
      started: 'Iniciado',
      stopped: 'Interrumpido'
    },
    summary: {
      totalDays: 'días activos',
      trendsLastWeek: 'desde la semana pasada',
      trendsNoChange: 'Ningún cambio'
    }
  },
  healthScoreDetails: 'La puntuación de Calcium es una cifra calculada en base a una variedad de factores relacionados con la salud física, la salud mental, el estilo de vida y factores sociales medibles y gestionables. Puede realizar el seguimiento de su puntuación de Calcium a diario, mientras recibe comentarios personalizados y sugerencias para mejorar durante su evolución en el camino de la atención médica.',
  helpscout: {
    heathSync: {
      subject: 'Conectar registros médicos',
      message: 'Cuéntenos un poco sobre lo que estaba buscando al conectar su EHR'
    }
  },
  hipaaAuthorization: 'Autorización de HIPAA',
  userConsent:'Consentimiento del usuario',
  userConsentp1:'Aquí hay un resumen de cómo manejamos sus datos:',
  userConsentp2:'Por la presente, autorizo ​​el uso o la divulgación de la información de salud protegida que me pertenece a Calcium LLC. Esta autorización entrará en vigencia de inmediato y permanecerá vigente durante un año a partir de la fecha de la firma, a menos que se especifique una fecha diferente. El propósito/uso de esta información es proporcionar a Calcium LLC la capacidad de brindarme un mejor servicio como usuario autorizado.',
  userConsentp3:'Autorizo ​​a Calcium LLC a utilizar mi información de salud protegida con fines de marketing, incluida la presentación de productos relevantes para mí. Entiendo que si mi información se utilizaría con fines de marketing según lo define la Ley de Portabilidad y Responsabilidad de Seguros Médicos (HIPAA), Calcium LLC puede recibir una remuneración directa o indirecta de un tercero. Calcium LLC no venderá directamente información de salud personal a terceros sin consentimiento adicional.',
  userConsentp4:'Entiendo que la información utilizada o divulgada puede estar sujeta a una nueva divulgación por parte de la persona o clase de personas o centro que la recibe y entonces ya no estará protegida por las normas federales de privacidad.',
  userConsentp5:'Puedo revocar esta autorización notificando a Calcium de mi deseo de revocarla notificando a Calcium LLC por escrito o por correo electrónico a',
  userConsentp6:'. Sin embargo, entiendo que cualquier acción que ya se haya tomado con base en esta autorización no se puede revertir, y mi revocación no afectará esas acciones. La revocación por escrito es efectiva al recibirla, excepto en la medida en que la parte reveladora u otros hayan actuado confiando en esta autorización. Entiendo que el destinatario no puede usar ni divulgar legalmente la información de salud a menos que obtenga otra autorización de mí o que dicho uso o divulgación sea requerido o permitido por ley.',
  userConsentpoint1:'Sus datos de salud serán encriptados y almacenados en los servidores de Calcium.',
  userConsentpoint2:'Sus datos nunca serán compartidos sin su consentimiento específico.',
  userConsentpoint3:'Puede solicitar eliminar su cuenta y sus datos en cualquier momento.',
  hipaaConsentIntroGreeting: '¡Hola!',
  hipaaConsentIntroP1: 'Necesitamos autorización para usar la información de salud personal de {0} a fin de proporcionarle los conocimientos más relevantes y que pueda experimentar la aplicación al completo.',
  hipaaReconsentIntroP1: 'Cada año, necesitamos renovar su autorización para usar la información de salud personal de {0} a fin de proporcionarle los conocimientos más relevantes y que pueda experimentar la aplicación al completo.',
  hipaaConsentNewVersionIntroP1: 'Hemos actualizado nuestro formulario de autorización de HIPAA y necesitamos su autorización para continuar utilizando la información de salud de {0} a fin de proporcionarle los conocimientos más relevantes y que pueda experimentar la aplicación al completo.',
  hipaaConsentIntroP2: 'Sin su autorización, se perderá experimentar muchas de las funciones más innovadoras y útiles de nuestra plataforma.',
  hipaaConsentP1: 'He leído este formulario y acepto los usos y divulgaciones de la información según lo descrito.',
  hipaaConsentP2: 'Cualquier copia de esta autorización es tan válida como la original. Tiene derecho a recibir una copia de esta autorización. Al hacer clic en «Acepto», usted acepta los términos mencionados anteriormente en lugar de una firma original en papel.',
  hipaaIntroNewProfileTitle: 'Antes de continuar ..',
  hipaaIntroNewProfileContent: 'Necesitamos que firme nuestro acuerdo HIPAA para cada nuevo perfil que cree. Sabemos que los datos de salud pueden ser confidenciales y por eso nos mantenemos al más alto nivel de seguridad.',
  hipaaIntroNewUserTitle: 'Casi ahí..',
  hipaaIntroNewUserContent: 'Pero primero necesitamos que firme nuestro acuerdo HIPAA, sabemos que los datos de salud son personales y pueden ser muy sensibles. Es por eso que nos regimos por el más alto estándar de seguridad y creemos que nuestros usuarios merecen saber cómo se utilizan sus datos.',
  hipaaIntroResignTitle: 'Antes de continuar ..',
  hipaaIntroResignContent: 'Necesitamos que renuncie a nuestro acuerdo de HIPAA, al igual que lo hace cada año cuando visita al médico. Como siempre, entendemos que los datos de salud pueden ser confidenciales y es por eso que nos mantenemos al más alto nivel de seguridad.',
  hipaaIntroUpdateTitle: 'Antes de continuar ..',
  hipaaIntroUpdateContent: 'Necesitamos que renuncie a nuestro acuerdo de HIPAA, hemos realizado algunos cambios. Como siempre, entendemos que los datos de salud pueden ser confidenciales y es por eso que creemos que nuestros usuarios merecen saber cómo se utilizan sus datos.',
  immunizationForm: 'Formulario de vacunación',
  immunizationTypeCovid: 'Vacunación Covid-19',
  in: 'en {0}',
  inactiveHaveACode: '¿Tiene un código de invitación?',
  inactiveHeader: '¡Gracias por unirse!',
  inactiveParagraph: 'Manténgase atento ya que iremos agregando participantes con el tiempo. Si ya recibió un código de invitación, introdúzcalo a continuación para obtener acceso instantáneo. De lo contrario, {0} y le enviaremos un correo electrónico una vez que su acceso esté disponible.',
  inactivePreHeader: 'Introduzca un código de invitación',
  inactiveRequestCode: 'solicite un código de invitación',
  inactiveRequestCodeEmailSubject: 'Solicite un código de invitación',
  includes: 'incluye',
  interestCta: 'Envíenos un correo electrónico',
  interestEmailSubject: 'La inclusión de {0} como función haría de Calcium un mejor producto.',
  interestMessageCta: '¿Esta función sería beneficiosa para usted?',
  interestMessageSubmittedCta: '¡Gracias por sus comentarios! Nos encantaría saber más sobre cómo la utilizaría.',
  interestMessageactivity: 'Haga un seguimiento y analice el impacto de su actividad diaria. Introduzca detalles sobre el ejercicio, la atención plena, el sueño y otras actividades directamente en la aplicación y sincronice los dispositivos conectados.',
  interestMessageallergies: 'Haga un seguimiento y comprenda sus alergias conocidas. Introduzca notas directamente en la aplicación, importe su historia clínica y sus resultados de laboratorio.',
  interestMessagebloodglucose: 'Haga un seguimiento y analice sus niveles de glucosa en sangre. Introduzca las lecturas directamente en la aplicación, importe su historia clínica, sus resultados de laboratorio, y sincronice los dispositivos conectados.',
  interestMessagebp: 'Haga un seguimiento y comprenda los resultados de su presión arterial. Introduzca las lecturas directamente en la aplicación, importe su historia clínica y sincronice los dispositivos conectados.',
  interestMessagehealthprograms: 'Obtenga recomendaciones inteligentes sobre rutas relacionadas con la salud para ayudarle a alcanzar sus objetivos personalizados.',
  interestMessageimmunizations: 'Haga un seguimiento y gestione sus vacunas pasadas, actuales y futuras en función de su historia clínica o de la introducción directa de datos.',
  interestMessagemedications: 'Haga un seguimiento y gestione sus medicamentos actuales en función de su historia clínica o de la lista introducida manualmente. Vea información sobre medicamentos, interacciones y reabastecimiento desde cadenas de farmacias específicas.',
  interestMessagenutrition: 'Obtenga una imagen clara de sus hábitos alimenticios diarios, realice un seguimiento del progreso hacia sus objetivos y acceda a consejos para mejorar y mantener una dieta saludable.',
  interestMessagereproductive: 'Gestione y mantenga su salud reproductiva. Haga un seguimiento a procesos y funciones reproductivos en todas las etapas de la vida. Integre su historia clínica, aplicaciones y dispositivos de terceros.',
  interestMessagespO2: 'Haga seguimiento y analice sus niveles de oxígeno en sangre. Introduzca las lecturas directamente en la aplicación, importe su historia clínica y sincronice los dispositivos conectados.',
  interestMessageshareprofile: 'Comparta privilegios de acceso para perfiles con otros.',
  interestMessagegroup: 'Cree grupos, comparta información y apoye el progreso de otros.',
  interviews: {
    answerYes: 'Sí',
    answerNo: 'No',
    continue: 'Continuar',
    complete: 'Completar',
    unknownContentType: 'Tipo de contenido desconocido'
  },
  invitationAccepted: '¡Éxito! La invitación ha sido aceptada correctamente',
  invitationError: 'No se pudo aceptar la invitación',
  invitationErrorCta: 'Compruebe su conexión a Internet e inténtelo de nuevo.',
  invitationErrorToast: 'No se pudo aceptar la invitación',
  isAdminDeleteAccountHeader:'No se puede eliminar la cuenta de administrador',
  isAdminDeleteAccountMessage:'Nota: Usted es administrador en esta organización, elimine los privilegios de administrador antes de eliminar su cuenta.',
  join: 'Registrarme',
  journal: {
    linkJournals: 'Mi diario',
    noData: 'No hay entradas de diario que mostrar',
    title: 'Mi diario',
    createTitle: 'Crear entrada de diario',
    formTitle: 'Introduzca un título (opcional)',
    formContent: 'Contenido de la entrada (límite de 4000 caracteres)',
    deletePrompt: '¿Está seguro de que desea eliminar esta entrada? Esta operación no se puede deshacer.',
    deleteJournal: 'Crear entrada de diario',
    journalEntry: 'Entrada de diario',
    deleteYes: 'eliminar la entrada',
    deleteNo: 'cancelar'
  },
  labResultSubType: 'Resultados de la prueba Covid-19',
  labResultForm: 'Formulario de resultados de laboratorio',
  linkActivity: 'Ejercicio',
  linkAddProfile: 'Añadir a alguien',
  linkAllergies: 'Alergias',
  linkLearnMore: 'Calcium',
  linkHome: 'Inicio',
  linkMedications: 'Medicamentos',
  linkNutrition: 'Nutrición',
  linkObservations: 'Vitales',
  privacyPolicy: 'Política de privacidad',
  eula: 'Términos y condiciones',
  support: 'Apoyo',
  Registration:{
    lastName: 'Apellidos',
    firstName:'primer nombre',
    setUp:'Configuración completa',
    setupExplanation:'El calcio puede ayudarlo a mantenerse al tanto de su salud al aprender más sobre usted',
    genderExplanation:'¿Cuál es su género?',
    dobExplanation:'¿Cuál es tu fecha de nacimiento?',
    userNameRegistration:'Introduzca su nombre',
    userInfo:'Información de usuario',
    next:'Próximo'
  },
  linkProfileList: 'Personas',
  linkReproductiveHealth: 'Salud reproductiva',
  linkSelfProfile: 'Yo',
  linkSignIn: 'Iniciar sesión',
  linkSignout: 'Cerrar sesión',
  listActivityHeader: 'Ejercicio',
  listGroupsHeader: 'Grupos',
  listMedicationDosesHeader: 'Dosis del medicamento',
  listMedicationsHeader: 'Medicamentos',
  listMoreHeader: 'Más opciones',
  listPeopleHeader: 'Personas',
  listProfileHeader: 'Perfil',
  loading: 'Cargando...',
  logButton: 'Añadir',
  signInError: 'Error de inicio de sesión',
  signInErrorMessage: 'Dirección de correo electrónico o contraseña no válidas',
  marketplace: {
    label: 'Mercado',
    sponsoredAds: 'Patrocinado',
    noDataText: 'Actualmente no hay artículos en el mercado.',
    ads: {
      omronBp7900: {
        title: 'Omron - BP7900, monitor inalámbrico completo de presión arterial del brazo superior + ECG',
        header: 'Omron - BP7900',
        summary: 'Monitor inalámbrico completo de presión arterial del brazo superior + ECG'
      }
    }
  },
  maxFileValidation: 'Puede subir un máximo de {0} archivo (s)',
  menu: 'Más',
  more: 'más',
  modal: {
    yes: 'Sí',
    no: 'No'
  },
  fullScreenModel:{
    pathway:'Llévame al camino!',
    pathways:'Llévame a los senderos!',
    invite:'Has sido invitado a un camino por ',
    invites:'Usted ha sido invitado a múltiples itinerarios por.',
    hello: 'Hola',
    hi: 'Hola',
    premium: 'prima'
  },
  namePlaceholder: '(Nombre y apellido)',
  nav: {
    title: 'Más',
    dashboard: 'Casa',
    health: 'Datos de salud',
    pathways: 'Rutas',
    more: 'Más',
    people: 'Personas',
    preferences: 'Ajustes',
    invoice: 'Invoices',
    getHelp: 'Consigue ayuda',
    marketplace: 'Mercado',
    hipaaAuth: 'Autorización de HIPAA',
    privacyPolicy: 'Política de privacidad',
    termsConditions: 'Términos y condiciones',
    signOut: 'Cerrar sesión',
    chat: 'Charlar'
  },
  chat911Text : 'Si se trata de una emergencia que amenaza la vida, marque el 911 o diríjase a la sala de emergencias más cercana.',
  noActivities: 'Ejercicio reciente',
  noMedications: 'Agregue su primer medicamento',
  noMedicationsPlaceholder: 'Aún no se han registrado medicamentos.',
  noMedicationDosesPlaceholder: 'Hoy no se han registrado dosis de medicamentos.',
  nonAuthWallPage: {
    nav: {
      about: 'Acerca de',
      partners: 'Socios'
    },
    header: 'Empoderamiento de la atención sanitaria impulsada por las personas.',
    subhead: 'Piensa más saludable.',
    value1: 'Acceda a su historia clínica electrónica en tiempo real.',
    value2: 'Haga seguimiento a sus síntomas y sus actividades.',
    value3: 'Informe a su médico sobre los acontecimientos.',
    value4: 'Consolide sus datos de accesorios de salud.'
  },
  healthInfo: {
    title: 'Health Info'
  },
  notEnoughInfoForHealthScore: 'Obtenga mejores conocimientos agregando más datos.',
  notVerifiedForInvites: 'Verifique su correo electrónico para ver las invitaciones.', // 'Please confirm your email to see invites. If you cannot find the email you can resend it.'
  notifications: {
    label: 'Notificaciones',
    noDataText: 'Sin notificaciones',
    markReadError: 'Se ha producido un error al marcar la notificación como leída',
    markDeletedError: 'Se ha producido un error al eliminar la notificación',
    deleteNotification: 'Eliminar la notificación',
    clearAll:'Limpiar todo'
  },
  offlineErrorMainText: 'Usted está desconectado',
  organizations: {
    admins: 'Administradores',
    adminsDisclaimer: 'Estos son los administradores de este grupo. Si esta información parece sospechosa, rechace la invitación.',
    title: 'Grupos',
    createGroupLink: 'Crear grupo',
    createDescription: 'Cree un grupo de salud confiable y comparta noticias importantes de salud con los miembros que invite.',
    joinGroupLink: 'Unirse al grupo', // 'Join group by invite code', // non-official
    groupNameLabel: 'Nombre del grupo',
    groupDescriptionLabel: 'Descripción del grupo (opcional)',
    statuses: {
      none: 'Ninguno',
      pending: 'Pendiente',
      declined: 'Rechazado',
      active: 'Activa',
      removed: 'Eliminado'
    },
    roles: {
      none: 'Ninguno',
      admin: 'Administrador',
      member: 'Miembro'
    },
    details: {
      header: 'Detalles del grupo',
      membersListHeader: 'Afiliación'
    },
    loadError: 'Se ha producido un error al cargar sus grupos.',
    leavePrompt: '¿Está seguro de que desea salir de {0}?',
    leavePromptForAdmin: '¿Está seguro de que desea salir de {0}?\n\nSi lo hace, seguirá siendo administrador de {0}, pero sus datos de salud no se compartirán con {0}.',
    leaveOrgHeader: 'Deja este grupo?',
    deleteYes: 'Abandonar grupo',
    deleteNo: 'cancelar',
    leaveOrgButton: 'Abandonar grupo',
    organizationInviteHeader: 'Invitación de grupo',
    organizationInviteCodeHeader: 'Código de invitación',
    inviteCodeLabel: 'Código de invitación',
    inviteCodeIntro: 'Ingrese el código de invitación del grupo.',
    inviteCodeSearching: 'Buscando...',
    inviteCodeFound: '¡Encontrado!',
    inviteCodeAlreadyUsed: 'Código de invitación ya utilizado.',
    inviteCodeNotFound: 'Código de invitación no encontrado.',
    removingOrg: 'Saliendo de {0}...',
    removedOrg: 'Quedan {0}',
    notVerifiedForInvites: 'Verifique su correo electrónico para ver las invitaciones a unirse a grupos.', // 'Please verify your email to see your invites.', // non-official
    viewDetails: 'Ver detalles',
    cannotRespond: 'No puede aceptar una invitación para unirse a un grupo con un perfil compartido. Elija un perfil diferente para responder a esta invitación.',
    joinHeader: 'Unirme a {0}',
    joinHeaderForProfile: 'Unirme a {0} para {1}', // rough translation 'Group {0} Invitation For {1}', //group invite for profile
    programInviteHeaderForProfile: 'Invitacion de Pathway de {0}',
    expectationMessage: '{2} se une como grupo {0}.',
    expectationMessageAdmin: 'Has sido invitado a ser {0} de {1}. Unirme como {2}.',
    accepting: 'Aceptando...',
    accepted: 'Aceptado',
    declining: 'Rechazando...',
    declined: 'Rechazado',
    membershipAlreadyExists: '{0} ya es miembro de {1}. Elija un perfil diferente para responder a esta invitación.',
    organizationMembershipAlreadyExists: '{0} ya es miembro de {1}.',
    joinChildOrganization: 'Conviértase en {0}', // 'Join the group {0}, part of {1}.', // needs translation
    joinRootOrganization: 'Conviértase en {0}', // 'Join the group {0}.' // needs translation
    recommendedByOrg: 'Recomendado por {0}',
    learnMore: 'Más información',
    dataShared: 'Los siguientes datos se compartirán con {0}:',
    permissions: {
      adminHeader: 'Administración de grupo',
      adminMessageSingle: 'Este administrador de grupos es {0}.',
      adminMessageMultiple: 'Los administradores son {0} y {1}.',
      adminMessageExtended: 'Los administradores incluyen {0} y {1} más.',
      observationHeader: 'Datos compartidos',
      observationMessageDefault: 'Las respuestas a las preguntas se compartirán con los administradores del grupo.',
      observationMessageSingle: 'Las respuestas a las preguntas y {0} se compartirán con los administradores del grupo.',
      observationMessageMultiple: 'Las respuestas a las preguntas, {0} y {1} se compartirán con los administradores del grupo.',
      pathwayHeader: 'Inscripciones en rutas',
      pathwayMessageSingle: 'Será inscrito en la ruta {0}.',
      pathwayMessageMultiple: 'Será inscrito en las rutas {0} y {1}.',
      choosePathway: 'Elija las vías en las que desea inscribirse',
      selectAllPrograms: 'Seleccionar Todo'
    }
  },
  page: 'Página',
  page404: {
    titleTab: 'Página no encontrada',
    heading: '¡Vaya!',
    subhead: 'Al parecer no podemos encontrar la página que está buscando.',
    error: 'Código de error: 404',
    goBackButton: 'Inicio'
  },
  preferencesAdded: 'Adicional',
  preferencesCelsiusHelper: '(Celsius)',
  preferencesCelsiusLabel: '°C',
  preferencesFahrenheitHelper: '(Fahrenheit)',
  preferencesFahrenheitLabel: '°F',
  preferencesImperialHelper: '(ft, in, lb, oz)',
  preferencesImperialLabel: 'Imperial',
  preferencesMetricHelper: '(m, cm, kg, g)',
  preferencesMetricLabel: 'Métrico',
  preferencesOn: 'Sí',
  preferencesOff: 'No',
  preferencesCustomizedDashboard: 'Cambiar atajos',
  preferencesCustomizedDashboardDescription: 'Haga clic en el botón editar a continuación para cambiar los accesos directos que ve en la página de inicio',
  preferencesShowFeaturePreviews: 'Vista previa de las próximas funciones',
  preferencesShowFeaturePreviewsDescription: 'Funciones de vista previa que están disponibles en la aplicación que están en modo de vista previa. Ayúdenos a decidir qué construir próximamente votando y compartiendo su opinión.',
  preferencesTemperatureUnitHeader: 'Unidades de temperatura',
  preferencesTitle: 'Ajustes',
  preferencesUnitHeader: 'Unidades',
  preview: {
    devicesLabel: 'Conectar dispositivos portátiles',
    devicesName: 'Dispositivos portátiles',
    devicesDetails: 'Conecte dispositivos portátiles a fin de obtener una visión general clara de su progreso y acceder a informes de datos clínicos.',
    healthRecordsLabel: 'Conecte su historia clínica',
    healthRecordsName: 'Historia clínica',
    healthRecordsDetails: 'Conecte su historia clínica electrónica para ver y administrar fácilmente sus datos.'
  },
  previewGroupMembers: 'Podrá incluirse a usted, sus amigos, familiares o proveedores de atención médica',
  previewGroupName: 'HealthChampion',
  programs: {
    detailsComplete: 'Ruta completa',
    detailsCompleteButton: 'Completar ruta',
    detailsCompletedOnDate: '{0} completada',
    detailsDayProgress: 'Día {0} de {1}',
    detailsEndedOnDate: '{0} finalizada',
    detailsEndingDate: '{0} finalizando',
    detailsResources: 'Recursos',
    detailsTasksEmpty: 'No hay tareas',
    detailsTasksAvailableEmpty: 'Aún no hay tareas para completar',
    detailsTasksComplete: '{0} de {1} tareas completadas',
    detailsAvailableTaskCompleteSingle: 'La tarea actual se ha completado',
    detailsAvailableTasksComplete: 'Todas las tareas {0} se han completado',
    detailsUnenrollButton: 'Salir de la ruta',
    detailsUnenrollHeader: '¿Desea salir de esta ruta?',
    detailsUnenrollText: 'Salirse puede hacer que pierda la conexión de datos y de la evolución asociada con esta ruta.',
    enrollButton: 'Iniciar',
    enrollDuration: 'Duración: {0} días',
    enrollDurationSingular: 'Duración: {0} día',
    enrollDurationShort: '{0} Días',
    enrollDurationShortSingular: '{0} Día',
    enrollHeader: 'Iniciar',
    enrollTitleTab: 'Inscripción en la ruta',
    willBeEnrolled: 'Será inscrito en la ruta {0}',
    sharedDataHeader: 'Datos compartidos',
    willSeeData: 'Las respuestas a esta ruta serán compartidas.',
    sharedData: 'La ruta solicita datos {0}.',
    listHeader: 'Rutas',
    completedListHeader: 'Rutas completadas',
    emptyActivePathWays:'Actualmente no tiene ninguna ruta activa.',
    newPathways:'Para encontrar nuevas vías para inscribirse, seleccione Examinar.',
    emptyInvitedPathWays:'Actualmente no tienes rutas invitadas.',
    emptyArchivedPathWays:'Actualmente no tiene ninguna vía.',
    inviteOrgQuestion: '¿Quién me invitó?',
    inviteOrganization:'Fuiste invitado por',
    invitePathwayAbout:'¿De qué se trata este camino?',
    invitePathway:'has sido invitado a',
    inviteQuestion: '¿Qué debo hacer si tengo preguntas',
    questionResponses:'Pregunta respuestas',
    sharedPathwayQuestion:'¿Qué se compartirá en este camino?',
    sharedPathwayList:'Compartirás los siguientes datos de salud',
    inviteAnswer: 'Si tiene preguntas sobre el camino, comuníquese con',
    leaderBoard: {
      duration: 'Duración:',
      viewTasks: 'Ver tareas',
      myRank: 'Mi rango',
      steps: 'Pasos',
      me: '(me)',
      floors: 'Pisos',
      count: 'Contar',
      healthIndex: 'HealthIndex'
    },
    listItemEnrolled: 'Activa',
    listPlaceholderNoAvailablePrograms: 'No hay rutas disponibles actualmente',
    listPlaceholderNoCompletedPrograms: 'Aún no se ha completado ninguna ruta',
    listTabAvailable: 'Disponible',
    listTabEnrolled: 'Inscrito',
    listTabCompleted: 'Completado',
    programShareTitle: 'Ruta Calcium en curso',
    programShareText: 'Estoy siguiendo la ruta {0} en Calcium',
    programShareDialogTitle: 'Compartir con mis amigos',
    programInvite: 'Invitación de ruta',
    readyToComplete: 'Archivar mi camino',
    reEnrollText: 'Continúe su viaje; vuelva a inscribirse en esta ruta y siga los próximos pasos.',
    reEnrollLabel: 'Reinscribirme',
    reEnrollingLabel: 'Inscribiendo...',
    reEnrollSkipLabel: 'Saltar',
    reEnrollingSkipLabel: 'Dejar ...',
    reEnrollUpgradeLabel: 'Actualiza', // 'Update' // non-official
    reEnrollingUpgradeLabel: 'Actualizando ...', // 'Updating ...' // non-official
    referralListTitle: 'Rutas sugeridas',
    successPromptHeader: '¡Hola, buen trabajo!',
    successPromptCompleteHeader: '¡Buen trabajo!',
    successPromptCompleteText: 'Está listo para marcar esta ruta como completada, ¡adelante!',
    successPromptCompleteButton: 'Completar',
    successPromptShareOrReferralCTA: '¿Por qué no compartir sus logros o dar el siguiente paso e inscribirse en otra ruta?',
    successPromptShareCTA: '¿Por qué no compartir su logro?',
    successPromptShareTitle: 'Ruta de Calcium completada',
    successPromptShareText: 'He completado la ruta {0} en Calcium',
    successPromptShareDialogTitle: 'Compartir con mis amigos',
    taskListTitle: 'Tareas',
    taskListTitleCurrent: 'Tareas disponibles',
    taskListTitleRemaining: 'Tareas restantes opcionales',
    taskListTitleMissed: 'Tareas perdidas',
    taskListTitleCompleted: 'Tareas completadas',
    taskListTitleFuture: 'Tareas futuras',
    taskListNextAt: 'Próxima {0}',
    taskListDueBy: 'Vencida {0}',
    taskListCompleted: '{0} completada',
    tileLink: 'Explorar rutas',
    toastMessageComplete: 'Ruta completa',
    toastMessageCompleting: 'Completando ruta',
    toastMessageEnrolling: 'Aceptando ruta',
    toastMessagereenrolling: 'Vía de reinscripción',
    toastMessageEnrolled: 'Ruta aceptada',
    toastMessageReenrolled: 'Camino reinscrito',
    toastMessageLeaving: 'Abandonando ruta',
    toastMessageLeft: 'Ruta abandonada',
    upgradeAvailableBanner: 'Actualiza a la nueva versión de este camino.', // 'Update to the new version of this pathway.', // non-official
    upgradeContentHeader: 'Confirme la actualización de la ruta',
    upgradePromptHeader: 'ACCIÓN REQUERIDA: SU CAMINO ESTÁ VACÍO.', // 'ACTION REQUIRED: YOUR PATHWAY IS OUT OF DATE', // non-official
    upgradePromptText: 'Su versión actual está desactualizada y debe actualizarse para poder capturar los datos más precisos.', // 'Your current version is out-of-date and needs to be updated so the most accurate data can be captured.', // non-offical
    upgradePromptHighlightText: 'Esto no llevará más tiempo.',
    upgradePromptCTA: 'Actualización disponible', // 'Update Available', // non-official
    upgradeObservationHeaderContent : 'La ruta "{0}" se ha actualizado con una nueva solicitud de respuesta de datos:',
    upgradeObservationFooterContent1 : 'Seleccione "Aceptar" para continuar participando en esta ruta con los datos adicionales solicitados.',
    upgradeObservationFooterContent2 : '"Salir de la vía" para poner fin a su participación en la vía.',
    weightProgramAction: 'Registro de peso',
    weightProgramCallToAction: 'Registro de peso de hoy',
    weightProgramHistory: 'Historial de peso',
    weightProgramLinkWeightLoss: 'Pérdida de peso'
  },
  deleteAccount: 'Borrar cuenta',
  deleteAccountTitle: '¿Eliminar tu cuenta?',
  deleteAccountMessage:'Eliminar su cuenta eliminará permanentemente todos sus datos e información.',
  ok:'OK',
  signoutHeaderMessage:'Eliminación de cuenta en curso',
  signoutMessage:'Nota: Ha solicitado eliminar esta cuenta. No puede registrarse/iniciar sesión en este momento. Por favor, inténtelo de nuevo más tarde.',
  register: 'Registrarme',
  registerAlreadyHaveAccount: '¿Ya tiene una cuenta en Calcium?',
  registerConfirmEmail: 'Gracias por crear su cuenta. Por favor revise su correo electrónico y confirme su dirección de correo electrónico.',
  registerLearnMore: 'Conozca más sobre {0}.',
  registerSignup: 'Crear una cuenta en Calcium',
  registerThankYou: 'Gracias',
  reload: 'Inténtelo de nuevo',
  resendVerification: 'Reenviar correo electrónico de confirmación',
  resetPasswordTitle: 'Restablecer la contraseña',
  resetPasswordSubtitle: 'Introduzca la dirección de correo electrónico asociada a su cuenta.',
  resetPasswordSuccessTitle: 'Gracias',
  resetPasswordSuccessSubtitle: 'Compruebe su correo electrónico y haga clic en el enlace para restablecer su contraseña.',
  forceResetPasswordText1: 'En un esfuerzo por mantener sus datos seguros, use el botón a continuación para actualizar su contraseña. ',
  forceResetPasswordText2: 'Si tiene alguna pregunta, comuníquese con ',
  forceResetPasswordActionButton: 'Enviar correo electrónico de restablecimiento de contraseña',
  resetPasswordSuccessSignInText: 'Navegar a',
  saved: '¡Guardado!',
  saving: 'Guardando...',
  closeConfirmationContent: 'Si sale ahora, perderá todo el progreso con esta tarea.¿Seguro que quieres salir? Haga clic en el botón completar al final de esta tarea para enviarlo.',
  backToTaskLabel: 'Volver a la tarea',
  closeTaskLabel: 'Cerrar',
  sending: 'Enviando...',
  sent: '¡Enviado!',
  selectPlaceholder: '- Seleccionar',
  sharing: {
    title: 'Compartir perfil',
    description: 'Invite a otra persona a compartir la administración de su perfil seleccionado.',
    inviteEmail: 'Enviar invitación por correo electrónico',
    legalText: 'Al hacer clic en el botón anterior, acepta compartir la administración del perfil con otro usuario que pueda ver y modificar la información de salud de este perfil.',
    noPendingInvites: 'No hay invitaciones de perfil pendientes',
    pendingInvites: 'Invitaciones pendientes',
    invitedBy: 'A {0} le gustaría que manejaras el perfil de {1}.', //'{0} would like you to manage {1}\'s profile.', // non-official, needs update
    incomingTitle: 'Invitaciones compartidas entrantes',
    sharingTitle: 'Compartiendo',
    accepted: '¡Aceptado!',
    declined: '¡Rechazado!',
    pending: 'Pendiente',
    inviteSentOn: 'Enviado el: {0}',
    accessSince: 'Desde: {0}',
    whoHasAccess: 'Quién tiene acceso',
    shareToSeeData: 'Administre quién en su círculo puede acceder y actualizar la información sobre este perfil.',
    callToAction: 'Comience a compartir ahora',
    revoke: 'Dejar de compartir',
    delete: 'Borrar',
    revokeModalTitle: 'Revocar acceso al perfil',
    revokeModalText: '¿Está seguro de que desea revocar permanentemente el acceso a {0}?',
    revokeModalDeleteYes: 'Revocar el acceso',
    revokeModalDeleteNo: 'cancelar',
    inviteCancelModalTitle: 'Eliminar esta invitación de perfil?',
    inviteCancelModalText: '¿Está seguro de que desea eliminar la invitación a {0}?',
    inviteCancelModalDeleteYes: 'Eliminar invitación',
    inviteCancelModalDeleteNo: 'cancelar',
    sharedWithYou: 'Compartido usted',
    accepting: 'Aceptando...',
    declining: 'Rechazando...',
    revokingAccess: 'Revocando el acceso...',
    accessRevoked: '¡Acceso revocado!',
    removeProfileModalTitle: 'Quitar perfil',
    removeProfileModalText: '¿Estás seguro de que quieres eliminar el perfil {0}?',
    deleteYes: 'Eliminar perfil',
    deleteNo: 'cancelar'
  },
  signingIn: 'Iniciando sesión...',
  signingOut: 'Cerrando sesión...',
  simpleDateFormat: 'MM/DD/YY', // locale
  simpleDateTimeFormat: 'MM/DD/YY hh:mm A', // locale
  simpleTimeFormat: 'h:mm A',
  socialShare: {
    fallbackCopied: 'Copiado al portapapeles',
    desktopTitle: 'Copiar enlace',
    menuShareDialogTitle: 'Invitar amigos',
    copyAppLink: 'Copiar el enlace de la aplicación',
    menuShareText: 'Únase a mí en Calcium'
  },
  sponsored: 'Patrocinado',
  states: {
    stateAL: 'Alabama',
    stateAK: 'Alaska',
    stateAS: 'Samoa Americana',
    stateAZ: 'Arizona',
    stateAR: 'Arkansas',
    stateCA: 'California',
    stateCO: 'Colorado',
    stateCT: 'Connecticut',
    stateDE: 'Delaware',
    stateDC: 'Distrito de Columbia',
    stateFM: 'Estados Federados de Micronesia',
    stateFL: 'Florida',
    stateGA: 'Georgia',
    stateGU: 'Guam',
    stateHI: 'Hawái',
    stateID: 'Idaho',
    stateIL: 'Illinois',
    stateIN: 'Indiana',
    stateIA: 'Iowa',
    stateKS: 'Kansas',
    stateKY: 'Kentucky',
    stateLA: 'Luisiana',
    stateME: 'Maine',
    stateMH: 'Islas Marshall',
    stateMD: 'Maryland',
    stateMA: 'Massachusetts',
    stateMI: 'Michigan',
    stateMN: 'Minnesota',
    stateMS: 'Mississippi',
    stateMO: 'Missouri',
    stateMT: 'Montana',
    stateNE: 'Nebraska',
    stateNV: 'Nevada',
    stateNH: 'Nuevo Hampshire',
    stateNJ: 'Nueva Jersey',
    stateNM: 'Nuevo México',
    stateNY: 'Nueva York',
    stateNC: 'Carolina del Norte',
    stateND: 'Dakota del Norte',
    stateMP: 'Islas Marianas del Norte',
    stateOH: 'Ohio',
    stateOK: 'Oklahoma',
    stateOR: 'Oregón',
    statePW: 'Palau',
    statePA: 'Pennsylvania',
    statePR: 'Puerto Rico',
    stateRI: 'Rhode Island',
    stateSC: 'Carolina del Sur',
    stateSD: 'Dakota del Sur',
    stateTN: 'Tennessee',
    stateTX: 'Texas',
    stateUT: 'Utah',
    stateVT: 'Vermont',
    stateVI: 'Islas Vírgenes',
    stateVA: 'Virginia',
    stateWA: 'Washington',
    stateWV: 'West Virginia',
    stateWI: 'Wisconsin',
    stateWY: 'Wyoming'
  },
  bottomNumber: 'Número inferior',
  topNumber: 'número superior',
  goalRange:'Rango de objetivos actual',
  steptargetTitle: 'Objetivo de paso',
  observationGoalTitle: 'Establecer una meta',
  setTarget: 'Cambiar objetivo',
  currentTarget: 'El objetivo actual es',
  addTarget: 'Agregar objetivo',
  targetRange: 'Rango de objetivos',
  target: 'Meta',
  minReading: 'mín. Lectura',
  maxReading: 'máx. Lectura',
  stepTargetMessage: 'Establezca una meta de pasos diaria para ayudarlo a mantenerse activo.',
  setTargetMessage:'Configure su objetivo a continuación para continuar.',
  avgstepTarget: 'En promedio, caminaste',
  stepsPerDay:'pasos por día la semana pasada',
  tileActivityTitle: 'Ejercicio: 7 días',
  tileActivityUnits: 'minutos',
  tileBodyMeasurementsTitle: 'IMC',
  noBodyMeasurementsData: 'Añada su altura y peso',
  tileBodyMeasurementsUnits: '(Sobrepeso)',
  tileMedicationsLabel: 'Levotiroxina',
  tileMedicationsTitle: 'Medicamentos',
  tileMedicationsUnits: 'horas desde la última toma',
  timeline: {
    title: 'Calendario de 7 días',
    noData: {
      header: 'Vea su calendario',
      message: 'Vea todos sus datos de salud de los últimos 7 días en un solo lugar.',
      callToAction: 'Intente añadir su temperatura para comenzar.',
      buttonText: 'Añadir temperatura',
      linkText1: 'Sincronice su historia clínica',
      linkText2: 'Conecte un dispositivo',
      WeightData: 'Datos no disponibles'
    },
    filtersApplied: 'Filtros aplicados.',
    applyFilters: 'Aplicar',
    clearFilters: 'Borrar',
    summary: {
      title: 'mi resumen',
      yourStats: 'Mira lo que lograste!',
      stepDays: 'pasos/día',
      deviceNotConnected: 'Dispositivo no conectado',
      heartRate: 'Frecuencia cardíaca en reposo',
      trendWeek: 'desde la semana pasada',
      noChangeInData: 'Ningún cambio',
      fromLastWeight: 'de',
      sleepDays: 'Sueño/día',
      nutritionDays: 'Calorías / Día'
    }
  },
  tooltipAddMedication: 'Añadir medicamento',
  toggleCalendar: 'Calendario',
  toggleList: 'Lista',
  tryAgain: 'Haga clic para volver a intentarlo',
  units: {
    unknown: 'N/A',
    beatsPerMinute: 'bpm',
    degreesCelsius: 'ºC',
    degreesFahrenheit: 'ºF',
    centimeters: 'cm',
    inches: 'in',
    kilograms: 'kg',
    pounds: 'lb',
    meters: 'm',
    steps: 'pasos',
    percent: '%',
    internationalUnit: 'UI',
    litersPerMinute: 'l/min',
    gramsPerDeciliter: 'g/dl',
    mgdL: 'mg/dL',
    millimolesPerLiter: 'mmol/L',
    millimetersOfMercury: 'mmHg',
    cubicMetersPerSecond: 'm³/s',
    pascal: 'Pa',
    seconds: 's',
    Calories: 'Cal',
    floorsClimbed: 'Floors'
  },
  userAgent: 'Agente de usuario',
  validateRange: 'Debe ser mayor que del valor',
  validationBirthdate: 'La fecha de nacimiento no es válida',
  validationDate: 'La fecha no es válida',
  validationDateInPast: 'La fecha debe estar en el pasado',
  validationDateInRange: 'La fecha no está en el rango válido',
  validationInvalidEmail: 'Debe ser un correo electrónico válido',
  validationInvalidHeight: 'La altura es demasiado grande',
  validationMaxLength: 'longitud máxima de caracteres',
  validationMaxCharLength: '{0} longitud máxima de caracteres',
  validationMaxValue: 'Debe ser a lo sumo',
  validationMinLength: 'longitud máxima de caracteres',
  validationMinValue: 'Debe ser al menos de',
  validationNumber: 'Debe ser un número',
  validationRequired: 'Requerido',
  validationSsn4: 'Se requieren exactamente cuatro dígitos',
  validationTimeLessThan1Day: 'El tiempo debe ser inferior a 24 horas.',
  verifyEmailBody: 'No puede acceder a ciertas páginas hasta que se confirme su dirección de correo electrónico. Verifique su correo electrónico y haga clic en el enlace para confirmar su dirección de correo electrónico.',
  verifyEmailHeader: 'Por favor, confirme su dirección de correo electrónico',
  verifyEmailResentHeader: 'Nueva confirmación de correo electrónico enviada',
  verifyEmailBodyMobile: 'Si ha verificado su correo electrónico y aún ve este mensaje, reinicie la aplicación.', //'If you have confirmed your email and still see this message please restart the app.',
  verifyEmailBodyBrowser: 'Si ha verificado su correo electrónico y aún ve este mensaje, actualice el navegador.', //'If you have confirmed your email and still see this message please refresh the browser.',
  vitalsHeader: 'Vitales',
  welcome: 'Bienvenido',
  suggestedPathways: 'Suggested Pathways',
  showAll: 'Show all',
  resourceRating:{
    helpful: 'Helpful?',
    yourfeedback : 'Your Feedback',
    content : 'Please tell us why you thought this resource was',
    helpfulContent: 'helpful.',
    notHelpfulContent: 'not helpful.',
    cancel:'Cancel',
    submit:'Submit'
  },
  coach: {
    for: 'por',
    months: 'meses',
    save: 'Ahorrar',
    benefits: 'Comience el año nuevo con estos beneficios de Calcium AI Coach',
    assessment:'Evaluación de salud por un entrenador de salud certificado por la junta',
    personalized: 'Objetivos inteligentes personalizados y alcanzables',
    app: 'Acceda al programa en cualquier momento y en cualquier lugar a través de la aplicación Calcium',
    hipaa: 'Cumple con HIPAA',
    join: '¡Y mucho más! ¡Únase hoy por tan solo $ 10 / mes!',
    selectPlan: 'Seleccionar un plan',
    start: 'Empezar hoy'
  },
  deletionInProgress:'Estamos trabajando para eliminar su cuenta, no puede registrarse/iniciar sesión hasta que se complete la eliminación. Por favor, inténtelo de nuevo más tarde',
  invoice:{
    noData:'No se encontraron facturas',
    verifyHealthRecords:'Verifique sus registros de salud',
    identityNotVerified:'Para sincronizar sus registros de salud, necesitamos su identidad. Complete toda la información de manera completa y precisa para evitar quedarse fuera del sistema.',
    pay:'Pagar',
    dollar: '$',
    verify:'Verifica ahora'
  },
  appointment: {
    myAppointment:'Mi cita',
    provider:'Proveedor',
    events:'Próximos Eventos',
    noRecords:'No se encontraron citas',
    cancelledAppointment:'Cita cancelada',
    cancelledReason:'Su cita fue cancelada debido a',
    cancelled:'Cancelada',
    when:'Cuando',
    where:'Dónde',
    appointmentReason:'Motivo de la cita',
    description:'Descripción',
    patientInstruction:'Instrucción del paciente'
  },
  verifyEmail:{
    confirm: 'confirme su email',
    sentCode: 'Envió un código de 4 dígitos a {0} para confirmar.',
    trouble: '¿Teniendo problemas?',
    verifying: 'Verifying...',
    verified: 'Verificado',
    failed: 'Fallo en la verificación'
  },
  deviceName : {
    manual : 'Manual',
    sync : 'Sincronizar desde',
    noProviderName : 'Registrado por el proveedor',
    withProviderName : 'Grabado por',
    device:'dispositivo'
  },
  observationConnect : {
    description: 'Tienes datos de salud que necesitan ser rastreados. Conecte el dispositivo, active la entrada manual o cierre el registro de salud para continuar.',
    addDevice:'Añadir dispositivo',
    manualEntry:'Entrada manual',
    changeDevice:'Cambia el dispositivo',
    connectDevice:'Conecta los dispositivos para rastrear',
    connectedDevice:'Connected Devices',
    manualTracking:'Dispositivos conectados',
    deactivatedDevice:'Dispositivos',
    manuallyTracked:'Seguimiento manual',
    select: 'Seleccionar',
    connectTitle:'Conectar un dispositivo para monitorear',
    connectDescriptionOne:'Tienes un dispositivo previamente conectado que funciona con {0}. Puedes elegir uno u otro de nuestra lista de dispositivos compatibles.',
    manualTrackingPathway:'Seguimiento manual de rutas',
    manualTrackingDescription:'Esta ruta de seguimiento {0} admite el seguimiento manual, lo que le permite ingresar sus valores cada día.',
    manualTrackingConfirm:'¿Le gustaría activar el seguimiento de ruta manual?',
    deactivateTitle:'Desactivar {0}',
    deactivate:'Desactivar',
    deactivateDescription:'Entendemos que es posible que no tenga un dispositivo hoy, pero si obtiene uno en el futuro, puede comenzar a rastrear los datos de {0} inmediatamente',
    connectedDeviceName:'Actualmente conectada',
    connectTitleText:'Conectar {0} dispositivo',
    deviceSurvey:{
      link: 'no veo mi dispositivo',
      title: 'Encuesta de actualización de dispositivos (OPCIONAL)',
      message: 'Enumere cualquier información sobre su dispositivo, como fabricante, nombre del dispositivo, modelo, S/N, etc. y esperamos agregar su dispositivo en una actualización futura.',
      information: 'Información del dispositivo'
    }
  },
  observationTypeDescriptions: {
    [ObservationType.BloodGlucose]: 'Es importante mantener los niveles de azúcar en la sangre dentro del rango deseado tanto como sea posible para ayudar a prevenir problemas de salud. Permanecer en su rango objetivo también puede ayudar a mejorar su energía y estado de ánimo.',
    [ObservationType.BloodKetone]: 'Un nivel equilibrado de cetonas puede mejorar la salud metabólica y física. Al medir sus niveles de cetonas, puede determinar si está quemando grasa con Keto.',
    [ObservationType.BloodOxygen]: 'El nivel de oxígeno en la sangre es la cantidad de oxígeno que circula en la sangre. Cada tejido y célula del cuerpo necesita un suministro constante de oxígeno para funcionar correctamente. Por lo tanto, es importante asegurarse de que su nivel de oxígeno en la sangre no sea demasiado alto o bajo.',
    [ObservationType.Systolic]: 'Empiece a controlar su presión arterial hoy haciendo pequeños cambios en su vida diaria. Su presión arterial es una de las mejores formas de medir la salud en general.',
    [ObservationType.Diastolic]: 'Empiece a controlar su presión arterial hoy haciendo pequeños cambios en su vida diaria. Su presión arterial es una de las mejores formas de medir la salud en general.',
    [ObservationType.BodyFat]: 'Mantener su porcentaje de grasa corporal en un nivel saludable puede ayudarlo a evitar riesgos graves para la salud, regular la temperatura, equilibrar los niveles hormonales y mejorar la salud reproductiva.',
    [ObservationType.InstantaneousHeartRate]: 'Conseguir que tu ritmo cardíaco equilibre tu cuerpo mueve oxígeno y sangre a tus músculos. Medir tu frecuencia cardíaca te ayuda a mantenerte saludable.',
    [ObservationType.Insulin]: 'La insulina sana y equilibrada es esencial para la salud de todo el cuerpo. Es importante mantener los niveles de insulina tanto como sea posible para ayudar a prevenir problemas de salud.',
    [ObservationType.Nutrition]: 'Una dieta sana y equilibrada es clave para cualquier persona con diabetes. Una buena nutrición no solo controla los niveles de glucosa (azúcar en la sangre), sino que también mejora el colesterol y la presión arterial, los cuales pueden ser altos para las personas con diabetes.',
    [ObservationType.PeakFlow]: 'El flujo máximo puede detectar cambios en las vías respiratorias, a veces antes de que tenga síntomas. El seguimiento regular del flujo máximo lo ayudará a reconocer disminuciones tempranas en el flujo de aire y será utilizado por su cuidador.',
    [ObservationType.Steps]: 'Es una buena idea averiguar cuántos pasos al día camina ahora, como su propia línea de base. Luego, puede trabajar hacia la meta de 10,000 pasos con el objetivo de agregar 1,000 pasos adicionales por día cada dos semanas.',
    [ObservationType.Sleep]: 'Dormir ayuda a que tu cuerpo y tu cerebro funcionen correctamente. Realice un seguimiento y equilibre sus patrones de sueño para ayudarlo a alcanzar una mejor salud a través de un mejor sueño.',
    [ObservationType.BodyTemperature]: 'La temperatura corporal es una señal de advertencia temprana de infección. Controlar la temperatura de su cuerpo, incluso cuando está sano, puede ayudar a detectar enfermedades temprano y ayudarlo a saber si está bien ir al trabajo o a la escuela.',
    [ObservationType.Weight]: 'Un peso saludable te ayuda a sentirte mejor y mejora tu estilo de vida saludable. El seguimiento nos permite ser conscientes de lo que comemos, cuánto nos movemos y cómo fluctúa nuestro peso corporal en la báscula cada día.',
    [ObservationType.BodyArms]: 'Medida de la circunferencia del bíceps de los brazos.',
    [ObservationType.BodyChest]: 'Medida de la circunferencia del pecho.',
    [ObservationType.Height]: 'Medida de su altura.',
    [ObservationType.BodyHips]: 'Medida de la circunferencia de las caderas internas.',
    [ObservationType.BodyThighs]: 'Medida de la circunferencia de la parte superior del muslo.',
    [ObservationType.BodyWaist]: 'Medida de la circunferencia de la cintura alrededor del ombligo.',
    [ObservationType.LabResult]: 'Descripción de los informes de diagnóstico',
    [ObservationType.Immunization]: 'Descripción de vacunas',
    [ObservationType.Exercise]: 'El ejercicio regular es bueno para una vida física y emocional saludable. Puede ayudarte a prevenir muchos problemas de salud. Nunca es demasiado tarde para comenzar a desarrollar sus hábitos de ejercicio.',
    [ObservationType.HeartRateVariability]: 'La variabilidad del corazón refleja cuán adaptable puede ser su cuerpo y crea constantemente conciencia sobre el cuerpo y lo ayuda a lidiar con los factores estresantes cotidianos de una manera saludable. Las consideraciones positivas en la medición son que no hay dolor, incomodidad o miedo relacionado o inquietud para las personas cuando se toman las medidas.',
    [ObservationType.None]: 'Ninguna'
  },
  observationsTitle: {
    [ObservationType.BodyArms]: 'Brazos',
    [ObservationType.BloodGlucose]: 'glucosa en sangre',
    [ObservationType.BloodKetone]: 'cetona en sangre',
    [ObservationType.BloodOxygen]: 'Blood Oxygen',
    [ObservationType.Systolic]: 'Presión arterial',
    [ObservationType.Diastolic]: 'Presión arterial',
    [ObservationType.BodyFat]: 'Grasa corporal',
    [ObservationType.BodyChest]: 'Tórax',
    [ObservationType.Height]: 'Altura',
    [ObservationType.InstantaneousHeartRate]: 'Ritmo cardiaco',
    [ObservationType.Insulin]: 'Insulina',
    [ObservationType.Nutrition]: 'Nutrición',
    [ObservationType.PeakFlow]: 'Flujo máximo',
    [ObservationType.Sleep]: 'Dormir',
    [ObservationType.Steps]: 'Pasos',
    [ObservationType.BodyTemperature]: 'La temperatura',
    [ObservationType.Weight]: 'Peso',
    [ObservationType.BodyHips]: 'Caderas',
    [ObservationType.BodyThighs]: 'Muslos',
    [ObservationType.BodyWaist]: 'Cintura',
    [ObservationType.LabResult]: 'Informes de diagnóstico',
    [ObservationType.Immunization]: 'vacunas',
    [ObservationType.Exercise]: 'Ejercicio',
    [ObservationType.HeartRateVariability]: 'Variabilidad del ritmo cardíaco',
    [ObservationType.None]: 'Ninguna'
  },
  goals:{
    title: 'Mis metas',
    Goal:'Meta:',
    header: 'Crear {0} objetivo',
    name:'Nombre del objetivo',
    description: 'Describe qué significa el objetivo para ti (opcional)',
    continue:'Continuar a la selección de dispositivos',
    noGoal:'Actualmente no tienes ningún objetivo activo.',
    createNewGoal:'Para crear una nueva meta, haga clic en "+"',
    goalHelp:'Necesito una meta para ayudar con',
    viewAll:'Ver todo',
    collapseAction: 'Colapsar',
    alertTitle: 'Activar {0}',
    alertDescription: 'El dispositivo {0} se ha desconectado. Para continuar la observación en este camino, vuelva a conectar su dispositivo.',
    pathways: 'caminos'
  }
};
