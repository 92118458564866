import React, { useState } from 'react';
import { getSubscription, SubsriptionType } from 'preferences/billing/Subscription';
import { ProfileLink } from 'shared';
import { routePaths } from 'services/routing';
import  ProgramSubsriptionLink from 'programs/list/ProgramSubsriptionLink';
import ShareAdminRequestForm from './ShareAdminRequestForm';
import {saveEHRRequest} from 'health/care-plan/action-creator';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileId } from 'app/selectors';
import { getProfileName } from 'profiles/list/selectors';

const upgradeProviderRequestContent = 'Upgrade your subscription to share your Calcium app data with your healthcare provider.';
const upgradeContent = 'Upgrade your subscription to share your Calcium app data with family members and trusted friends.';
export default function ShareWithOthers (props) {
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const userName = useSelector(getProfileName);
  const [openSubscriptionLink, setOpenSubscriptionLink] = useState(false);
  const [showModal, setShowModal] = useState(false);

  function handleOpenSubscription(){
    setOpenSubscriptionLink(!openSubscriptionLink);
  }

  function handleOpenForm(){
    setShowModal(!showModal);
  }

  function handleSubmit(values){
    handleOpenForm(!showModal);
    let requestDataType = 1;
    let model = {
      profileId,
      userName,
      ...values,
      requestDataType
    };
    console.log(model);
    dispatch(saveEHRRequest(model));
  }

  const subscription = getSubscription();
  const { isFamilyShare } = props;
  const title = isFamilyShare ? 'Share with Family & Trusted Friends' : 'Share with Healthcare Provider';

  return (
    <div>
      <ul className="scrollview-list">
        {isFamilyShare ? (
          <li className="scrollview-list-item">
            {subscription.type === SubsriptionType.Free ? (
              <a
                id="sharing-admin-link"
                onClick={handleOpenSubscription}
                className="with-padding with-chevron">
                <div>{title}</div>
              </a>
            ) : (
              <ProfileLink
                id="sharing-admin-link"
                className="with-padding with-chevron"
                to={routePaths.sharing}>
                <div>{title}</div>
              </ProfileLink>
            )}
          </li>
        ) : (
          <li className="scrollview-list-item">
            {subscription.type === SubsriptionType.Free ? (
              <a
                id="sharing-admin-link"
                onClick={handleOpenSubscription}
                className="with-padding with-chevron">
                <div>{title}</div>
              </a>
            ) :
              (<a
                id="sharing-admin-link"
                onClick={handleOpenForm}
                className="with-padding with-chevron">
                <div>{title}</div>
               </a>)}
            {showModal ? <ShareAdminRequestForm
              onSubmit={handleSubmit}
              showModal={showModal}
              closeModal={handleOpenForm}
              initialValues={{}} /> : null}
          </li>
        )}
      </ul>
      {openSubscriptionLink ?
        <ProgramSubsriptionLink
          isConfirmOpenModel={openSubscriptionLink}
          closeModel={handleOpenSubscription}
          content={isFamilyShare ? upgradeContent : upgradeProviderRequestContent}>
        </ProgramSubsriptionLink>
        : null}
    </div>
  );
}

ShareWithOthers.propTypes = {
  //isOwned: PropTypes.bool.isRequired
};

ShareWithOthers.defaultProps = {};

