import React from 'react';
import { Route, Switch } from 'react-router';
import VerifiedEmailRoute from 'identity/verify-email/VerifiedEmailRoute';
import CreateOrganizationPage from 'organizations/create/CreateOrganizationPage';
import OrganizationInviteCodePage from 'organizations/organization-invites/code/OrganizationInviteCodePage';
import OrganizationInviteDetailsPage from 'organizations/organization-invites/details/OrganizationInviteDetailsPage';
import OrganizationDetailsPage from 'organizations/list/details/OrganizationDetailsPage';
import RecommendedProgramPage from 'organizations/recommended-program/RecommendedProgramPage';
import Page404 from 'app/page404/Page404';
import { routePaths } from 'services/routing';

export default function OrganizationRoutes() {
  return (
    <Switch>
      <VerifiedEmailRoute exact path={routePaths.organizationsCreate} component={CreateOrganizationPage} />
      <VerifiedEmailRoute exact path={routePaths.organizationsJoinCode} component={OrganizationInviteCodePage} />
      <VerifiedEmailRoute exact path={routePaths.organizationsInviteDetails} component={OrganizationInviteDetailsPage} />
      <VerifiedEmailRoute exact path={routePaths.organizationsDetails} component={OrganizationDetailsPage} />
      <VerifiedEmailRoute exact path={routePaths.organizationsJoinProgram} component={RecommendedProgramPage} />
      <Route component={Page404} />
    </Switch>
  );
}
