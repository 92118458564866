/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { compareDatesDescending } from 'services/date-service';
import { connect } from 'react-redux';
import withContext from 'app/withContext';
import { ProfileDataLoader, HealthDataImportCountTracker } from 'shared';
import ProfilePage from 'profiles/page/ProfilePage';
import { convertObservationTypeToHealthDataType } from 'services/enums-helpers';
class GenericObservationOverview extends React.Component {
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
  }

  loadData() {
    const { config } = this.props;
    config.loadData(this.props);
  }

  render() {
    const { observations, config, loading, loadError} = this.props;
    const filteredObservations = observations
      .filter(x => !x.deleted)
      .sort((a, b) => compareDatesDescending(a.recordedAt, b.recordedAt));

    const props = { ...this.props, observations: filteredObservations };
    return (
      <ProfilePage
        id={`${config.id}-overview`}
        centerElement={config.centerElement}
        loading={loading}
        error={loadError}
        primaryAction={config.renderPrimaryActionComponent(props)}
        backPath={this.props.location?.state?.backpath ? {to:this.props.location.state.backpath} : config.backPath}>
        <HealthDataImportCountTracker healthDataType={convertObservationTypeToHealthDataType(config.type)} />
        <ProfileDataLoader loadData={this.loadData} />
        {config.renderChart(props)}
        {filteredObservations.length === 0  && config.renderNoDataComponent(props)}

        {filteredObservations.length > 0 && config.renderListComponent(props)}
      </ProfilePage>
    );
  }
}

GenericObservationOverview.propTypes = {
  config: PropTypes.shape().isRequired,
  observations: PropTypes.arrayOf(PropTypes.shape).isRequired,
  loading: PropTypes.bool.isRequired,
  loadError: PropTypes.bool.isRequired,
  location: PropTypes.shape()
};

function mapStateToProps(state, props) {
  return {
    observations: props.config.dataSelector(state, props),
    loading: props.config.loadingSelector(state, props),
    loadError: props.config.loadErrorSelector(state, props),
    isHighFrequencyData:state.health.bloodGlucose.highFrequencyData
  };
}

export default withContext(connect(mapStateToProps)(GenericObservationOverview));
