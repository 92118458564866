import React from 'react';
import PropTypes from 'prop-types';
import { routePaths } from 'services/routing';
import local from 'services/localization/local';
import { ProfileLink } from 'shared';
import ProgramListItem from './ProgramListItem';
import ProgramCompleteModel from './ProgramCompleteModel';
import { Redirect } from 'react-router-dom';
class ProgramListItemEnrolled extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmation: false,
      redirect: false,
      isSubscriptionPathway: !props.enrollment.organizationId
    };
    this.closeConfirm = this.closeConfirm.bind(this);
    this.reload = this.reload.bind(this);
  }

  closeConfirm() {
    this.setState({ showConfirmation: !this.state.showConfirmation });
  }

  reload() {
    this.setState({redirect:true});
  }
  render() {
    const { programInfo, enrollment, showCloseButton } = this.props;
    const logName = `program-enrolled-${enrollment.program.name.toLowerCase().replace(/\s/g, '-')}`;

    const params = {
      programId: programInfo.programId,
      programEnrollmentId: enrollment.id
    };

    const action = (
      <ProfileLink id={`enrolled-${programInfo.programId}`} logName={logName} className='indicator-link' to={routePaths.programsDetails} params={params}>
        <span className="indicator">{local.programs.listItemEnrolled}</span>
      </ProfileLink>
    );

    return (
      <React.Fragment>
        <ProgramListItem
          id={`program-details-${programInfo.programId}`}
          logName={logName}
          to={routePaths.programsDetails}
          params={params}
          programInfo={programInfo}
          closeConfirm={this.closeConfirm}
          showCloseButton={showCloseButton}
          action={action} />
        {this.state.showConfirmation &&
          <ProgramCompleteModel
            isSubscriptionPathway={this.state.isSubscriptionPathway}
            isConfirmOpen={this.state.showConfirmation}
            closeConfirm={this.closeConfirm}
            programEnrollmentId={enrollment.id}
            profileId={enrollment.profileId}
            reload={this.reload}
            requestId={enrollment.id} />
        }
        {this.state.redirect ? (<Redirect push to="/pathways" />) : null}
      </React.Fragment>
    );
  }
}
ProgramListItemEnrolled.propTypes = {
  programInfo: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  enrollment: PropTypes.shape({
    id: PropTypes.string.isRequired
  })
};

export default ProgramListItemEnrolled;
