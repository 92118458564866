import React from 'react';
import local from 'services/localization/local';
import { ObservationType, Unit } from 'services/enums';
import { routePaths } from 'services/routing';
import { configIds } from 'health/observations/observation-config-ids';
import { baseObservationConfig } from 'health/observations/observation-config-helpers.jsx';
import { GenericObservationDeviceLinkNoData } from 'health/observations/generics';
import LogBloodGlucose from 'health/observations/blood-glucose/log/LogBloodGlucose';
import { BloodGlucose } from 'shared/fullColorIcons';
import BloodGlucoseVisualizationContainer from './visualization/BloodGlucoseVisualizationContainer';
import { roundToDecimals } from 'services/value-formatter';
import { loadHighFrequencyData } from 'health/action-creators';


const typeDefinition = {
  type: ObservationType.BloodGlucose,
  typeUnitMap: {
    [ObservationType.BloodGlucose]: Unit.MilligramsPerDeciliter
  },
  unitLabelMap: {
    [Unit.MilligramsPerDeciliter]: local.units.mgdL
  }
};

export default {
  ...baseObservationConfig,
  ...typeDefinition,
  id: configIds.bloodGlucose,
  backPath: { to: routePaths.health },
  centerElement: local.observation.bloodGlucose.title,
  logcenterElement: local.observation.bloodGlucose.logTitle,
  noDataText: local.observation.bloodGlucose.noDataText,
  iconSymbol: BloodGlucose,
  loadData: (props) =>  props.dispatch(loadHighFrequencyData({ observationType: props.config.type, profileId: props.profileId })),
  renderLogComponent: (props) => props && <LogBloodGlucose {...props} />,
  valueFormatter: (props) => `${roundToDecimals(props.observation.value, 2)} ${props.config.unitLabelMap[props.observation.unit]}`,
  renderNoDataComponent: (props) => props && <GenericObservationDeviceLinkNoData IconSymbol={props.config.iconSymbol} message={props.config.noDataText} />,
  getChartFormatter: (config, unit) => (value) => config.valueFormatter({ observation: { value, unit }, config }),
  renderChart : (props) => {
    return  (props.observations.length > 1 && <BloodGlucoseVisualizationContainer bloodGlucose={props.observations} formatter={props.config.getChartFormatter(props.config, props.observations[0].unit)} chartSettings={props.config.chartSettings} isHighFrequencyData={props.isHighFrequencyData} fromPathway={false} /> );

  }
};

