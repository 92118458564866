import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'shared/icon/Icon';
import local from 'services/localization/local';
import './error-section.scss';

class ErrorSection extends React.Component {
  render() {
    const { children, error } = this.props;
    if (!(children || error)) {
      return null;
    }

    const body = children || local.error;

    return (
      <div className="errorSection">
        <Icon symbol="error" size="1em" />
        {body}
      </div>
    );
  }
}

ErrorSection.displayName = 'ErrorSection';
ErrorSection.propTypes = {
  children: PropTypes.node,
  error: PropTypes.bool
};

export default ErrorSection;
