import React from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { getProfileId } from 'app/selectors';
import { Page, Button, ProfileRedirect } from 'shared';
import { TextField } from 'shared/form-fields/formik';
import { getUniqueId } from 'services/helpers';
import { getJournalRequest } from 'journal/journal-selectors';
import { saveJournalEntry } from 'journal/journal-actions';
import { dateInPast } from 'services/fields/field-validators';

const validation = Yup.object({
  title: Yup.string()
    .max(255, local.formatString(local.validationMaxCharLength, 255)),
  content: Yup.string()
    .required(local.validationRequired)
    .max(4000, local.formatString(local.validationMaxCharLength, 4000)),
  recordedAt: Yup.date()
    .required(local.validationRequired)
});

let requestId = getUniqueId();
const selectRequest = (state) => getJournalRequest(state, requestId);

export default function CreateJournalEntryPage() {
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const request = useSelector(selectRequest);

  if (request.success) {
    requestId = getUniqueId();
    return <ProfileRedirect push to={routePaths.healthJournal} />;
  }

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(saveJournalEntry({ profileId, ...values }, requestId));
    setSubmitting(false);
  };

  return (
    <Page
      id="create-journal-entry"
      backPath={{ to: routePaths.healthJournal }}
      centerElement={local.journal.createTitle}>
      <div>
        <Formik
          validationSchema={validation}
          initialValues={{
            title: '',
            content: '',
            recordedAt: moment().format(moment.HTML5_FMT.DATETIME_LOCAL)
          }}
          onSubmit={handleSubmit}>
          <Form>
            <div className="form-row">
              <TextField
                type="datetime-local"
                readOnly={request.processing}
                label={local.formTimestampLabel}
                validate={dateInPast}
                name="recordedAt" />
            </div>
            <div className="form-row">
              <TextField
                readOnly={request.processing}
                label={local.journal.formTitle}
                name='title' />
            </div>
            <div className="form-row">
              <TextField
                readOnly={request.processing}
                label={local.journal.formContent}
                multiline
                name='content' />
            </div>
            <br />
            <div className="form-row">
              <Button
                color='blue'
                id='save-journal-entry-button'
                className="btn btn-primary"
                disabled={request.processing}
                submit>
                {local.formSave}
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </Page>
  );
}
