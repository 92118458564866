import * as interviewActions from '@healthchampion/interviews';
import * as persistentDataActions from 'app/request/persistent-data-actions';
import { queueRequestWithToast } from 'app/request/request-queue-actions';
import { request } from 'app/request/request-actions';
import * as healthDataApi from 'services/api/healthdata-api';
import * as profilesApi from 'services/api/profiles-api';
import * as healthCareProfileApi from 'services/api/healthCareProfile-api';
import local from 'services/localization/local';
import { HealthDataSource, InterviewContentType } from 'services/enums';
import * as toastActions from 'app/toaster/actions';
import { profileDataUpdated } from 'profiles/actions';
import { types } from './connect-types';

export function verifyHipaaConsent(profileId, requestId) {
  return (dispatch) => {
    const action = state => profilesApi.getHipaaConsent(profileId, state);
    dispatch(request(action, requestId, { profileId }, { requireHipaaVerification: true }));
  };
}

export function syncHealthGorillaConditions(profileId, tempTriedCount = 1, neverReceievedElectronicRecords = false) {
  return (dispatch) => {
    const action = state => healthDataApi.syncConditions(profileId, HealthDataSource.HealthGorilla, state, neverReceievedElectronicRecords, tempTriedCount)
      .finally(() => dispatch(getSyncStatus(profileId)));

    dispatch(queueRequestWithToast(action, types.SYNC_HEALTH_RECORDS, profileId, {
      processingMessage: local.healthData.connect.status.processing,
      successMessage: local.healthData.connect.status.startedSync
    }, { profileId, healthDataSource: HealthDataSource.HealthGorilla }));
  };
}


export function updateSkipSyncTriedCount(profileId, tempTriedCount) {
  return (dispatch) => {
    const action = state => healthDataApi.updateSkipSync(profileId, state, tempTriedCount);
    dispatch(request(action, null, { profileId }, { showToasts: false }));
  };
}

export function getSyncStatus(profileId) {
  const action = state => healthDataApi.getSyncStatus(HealthDataSource.HealthGorilla, profileId, state);
  return persistentDataActions.request(action, types.SYNC_STATUS, profileId);
}

export function verifyProfileDetails(verificationProfile, profileRequestId, answersRequestId, requireSsn) {
  const action = state =>  healthCareProfileApi.verifyProfile(verificationProfile, requireSsn, state);
  return verifyProfileIdentity(action, verificationProfile.profileId, profileRequestId, answersRequestId);
}

export function verifyProfileWithSsn(profileId, ssn4, profileRequestId, answersRequestId) {
  const action = state => healthCareProfileApi.verifyProfileBySsn(profileId, ssn4, state);
  return verifyProfileIdentity(action, profileId, profileRequestId, answersRequestId);
}

export function verifyProfileIdentity(apiAction, profileId, profileRequestId, answersRequestId) {
  return (dispatch) => {
    const action = state => apiAction(state)
      .then(
        ({ success, errorMessage, needSsn4, questions, errorCode }) => {
          if (success && needSsn4) {
            // Nothing went wrong, but we need more information
            dispatch(toastActions.removeToast(profileRequestId));
            dispatch(profileDataUpdated(profileId, { needSsn4 }));
          } else if (success) {
            // Successful at locating the person, begin validation
            dispatch(loadInterview(profileId, questions, answersRequestId));
          } else {
            // Unsuccessful at locating the person
            dispatch(toastActions.removeToast(profileRequestId));
            dispatch(profileDataUpdated(profileId, {
              validationFailed: true,
              validationError: errorMessage,
              errorCode
            }));
          }
        });

    const requestOptions = {
      showToasts: true,
      requireHipaaVerification: true,
      processingMessage: local.healthData.connect.identityVerification.toastProcessing
    };

    return dispatch(request(action, profileRequestId, { profileId }, requestOptions));
  };
}

export function answerQuestions(profileId, answeredQuestions, answersRequestId) {
  return (dispatch) => {
    const action = state => healthCareProfileApi.answerQuestions(profileId, answeredQuestions, state)
      .then(() => {
        dispatch(toastActions.popSuccessToast(local.healthData.connect.identityVerification.toastSuccess));
        dispatch(updateIdentityVerifiedStatus(profileId, true));
        dispatch(interviewActions.closeInterview());
      })
      .catch((error) => {
        const { status, body } = error;
        // Challenge question required.
        if (status === 409 && body) {
          dispatch(loadInterview(profileId, body.questions, answersRequestId, true));
        } else {
          dispatch(toastActions.popErrorToast(local.healthData.connect.identityVerification.toastError));
          dispatch(interviewActions.closeInterview());
          throw error;
        }
      });

    return dispatch(request(action, answersRequestId));
  };
}

export function loadInterview(profileId, questions, answersRequestId, isChallenge = false) {
  let title = local.healthData.connect.identityVerification.title;
  let steps = questions.map(question => ({
    content: [
      {
        id: 1,
        type: InterviewContentType.Static,
        options: question.answers.length > 5 ? {} : {
          image: {
            path: 'https://hcstaticdev.blob.core.windows.net/images/QuestionMark.png'
          }
        }
      },
      {
        id: question.type,
        type: InterviewContentType.Select,
        text: question.prompt,
        options: {
          list: question.answers,
          variable: question.type,
          required: true,
          allowMultipleOptionToSelect: false,
          selectionLimit: 1,
          multipleOptionsList: question.answers
        }
      }
    ]
  }));


  if (isChallenge) {
    steps.unshift({
      content: [
        {
          id: 'challenge-intro',
          type: InterviewContentType.Static,
          options: {
            image: {
              path: 'https://hcstaticdev.blob.core.windows.net/images/security_on.png'
            }
          }
        },
        {
          id: 'challenge-message',
          type: InterviewContentType.Static,
          text: local.healthData.connect.identityVerification.challengeMessage
        }
      ]
    });
  }

  const interview = {
    title,
    processingMessage: local.healthData.connect.identityVerification.processingMessage,
    steps
  };

  const completeAction = answers => {
    return answerQuestions(profileId, questions.map(question => ({
      ...question,
      correctAnswer: answers[question.type].answer
    })), answersRequestId);
  };

  return interviewActions.startInterview(interview, completeAction);
}

export function notifyHealthDataSyncError(errorData) {
  return (dispatch) => {
    const action = state => profilesApi.notifyHealthDataSyncError(errorData, state);
    return dispatch(request(action));
  };
}

export function loadIdentityVerificationStatusData(profileId, email) {
  const action = state => healthCareProfileApi.getIdentityVerificationStatus(profileId, email, state);
  return persistentDataActions.request(action, types.IDENTITY_VERIFICATION_STATUS, profileId);
}

export function setIdentityVerifiedFalse(profileId) {
  return (dispatch) => {
    const action = state => healthCareProfileApi.setIdentityVerifiedFalse(profileId, state);
    dispatch(request(action, null, { profileId }));
  };
}

export function updateIdentityVerifiedStatus(profileId, isIdentityVerified) {
  return {
    type: types.IDENTITY_VERIFIED_STATUS,
    payload: {
      profileId,
      isIdentityVerified
    }
  };
}
