import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { GenericObservationListItem } from 'health/observations/generics';
import { configIds } from '../observation-config-ids';
import { glucoseSaved } from 'app/request/request-actions';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileId } from 'app/selectors';
import { loadGlucoseGoals, resetUnreadMedicalRecordsCount } from 'health/action-creators';
import { getUnreadMedicalRecordsCount } from 'health/health-selectors';
import { FhirResourceTypes } from 'services/enums';
import  '../blood-glucose/blood-glucose.scss';

export default function GenericObservationList(props) {
  const { config, observations } = props;
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const unreadMedicalRecordsCount = useSelector(state => getUnreadMedicalRecordsCount(state, profileId, FhirResourceTypes.Observation, config.type));
  useEffect(() => {
    if(props?.config?.id === configIds.bloodGlucose){
      dispatch(loadGlucoseGoals(profileId));
      dispatch(glucoseSaved(false));
    }
  }, []);

  useEffect(() => {
    if(config.type && unreadMedicalRecordsCount > 0)
      dispatch(resetUnreadMedicalRecordsCount(profileId, FhirResourceTypes.Observation, config.type));
  }, [profileId, unreadMedicalRecordsCount]);

  return (
    <ul className='scrollview-list'>
      {
        observations.map(x => (
          <GenericObservationListItem key={x.id || x.clientId} config={config} observation={x} />
        ))
      }
    </ul>

  );
}

GenericObservationList.propTypes = {
  config: PropTypes.shape().isRequired,
  observations: PropTypes.arrayOf(PropTypes.shape).isRequired
};
