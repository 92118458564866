import React from 'react';
import { useSelector } from 'react-redux';
import { Icon, OpenMenuAction } from 'shared';
import { routePaths } from 'services/routing';
import { NavLink } from 'react-router-dom';
import { getIdentityId } from 'identity/selectors';
import HealthBottomNavLink from 'health/navigation/HealthBottomNavLink';
import NotificationsLink from 'notifications/link/NotificationsLink.jsx';
import './bottom-navigation.scss';
import { getChatComponentProps } from 'chat/chat-selector';
import ChatLink from 'chat/link/ChatLink';

export default function BottomNavigation() {
  const identityId = useSelector(getIdentityId);
  if (!identityId) {
    return null;
  }

  const {isChatEnabledOrg} = useSelector(state => getChatComponentProps(state));

  return (
    <footer id="bottom-nav">
      <NavLink id="nav-link-logo" to={routePaths.root} className="nav-health home-bottom-nav" activeClassName="active" exact>
        <Icon symbol="home" />
      </NavLink>

      <HealthBottomNavLink />

      <NavLink id="nav-link-pathways" className="nav-pathways" to={routePaths.programs} activeClassName="active">
        <Icon symbol="pathway" />
      </NavLink>

      {isChatEnabledOrg &&
        <ChatLink />}
      <NotificationsLink />
      <OpenMenuAction />
    </footer>
  );
}
