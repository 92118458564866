import React,{ useState, useRef} from 'react';
import PropTypes from 'prop-types';
import { Page } from 'shared';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import VerificationInput  from 'react-verification-input';
import { removeChangeError } from 'preferences/changeEmail/action-creators';
import { useDispatch } from 'react-redux';

export default function ConfirmIdentitySsnPage({ submit }) {
  const content = local.healthData.connect.identityVerification;
  const dispatch = useDispatch();
  const [verificationCode,setVerificationCode]= useState('');
  const textInput = useRef(null);

  const onChangeVerificationCode = (e) => {
    dispatch(removeChangeError());
    setVerificationCode(e);
    if(e.length == 4)
      submit(e);
  };

  return (
    <Page
      id='confirm-identity-ssn-page'
      backPath={{ to: routePaths.health }}
      centerElement={content.ssnHeader}>
      <div id='verifyCode'>
        <h2>{local.formLast4Ssn}</h2>
        <div>
          <small>{content.ssnParagraph1}</small>
        </div>
        <br />
        <div className="codeField form-row custom-styles" data-testid="verification-inputdiv">
          {/*  eslint-disable-next-line react/jsx-no-bind */}
          <VerificationInput inputProps={{ 'data-testid': 'verify-input', 'type':'tel' }} value={verificationCode} validChars='0-9' onChange={e => {onChangeVerificationCode(e);}} length={4} autoFocus ref={textInput} />
        </div>
      </div>
    </Page>
  );
}

ConfirmIdentitySsnPage.propTypes = {
  submit: PropTypes.func.isRequired
};
