import { getProfileId } from 'app/selectors';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { buildPath, routePaths } from 'services/routing';
import { BackAction, NavIcon, Page } from 'shared';
import { configIds } from '../observation-config-ids';
import BloodGlucoseGoalForm from './BloodGlucoseGoalForm';
import { BloodGlucose } from 'shared/fullColorIcons';
import local from 'services/localization/local';
import { getRequestProcessing } from 'app/request/request-selectors';
import { getUniqueId } from 'services/helpers';
import { withRouter } from 'react-router';
import { loadGlucoseGoals, loadObservationData, saveGlucoseGoal } from 'health/action-creators';
import { getActiveGlucoseGoal, getAveragePerWeekBloodGlucose } from './bloodGlucose-selector';
import { useHistory } from 'react-router-dom';
import { ObservationType } from 'services/enums';
import withNavigation from 'app/withNavigation';

function BloodGlucoseGoalPage(props){
  const [submitRequestId, setSubmitRequestId] = useState();
  const profileId = useSelector(getProfileId);
  const activeGoal = useSelector(state => getActiveGlucoseGoal(state, profileId));
  const history = useHistory();
  const fromPathway = history.location?.state?.fromPathway;
  const routeParams = history.location?.state?.routeParams;

  const backPath = fromPathway && routeParams ? { to: routePaths.programsDetails, params: { profileId : routeParams.profileId, programId:routeParams.programId ,programEnrollmentId:routeParams.programEnrollmentId },state:{isFromCreateGoal: props.location?.state?.isFromCreateGoal} }: {to : buildPath(routePaths.healthObservation, {configId: configIds.bloodGlucose, profileId})};
  const content = local.observation.bloodGlucose;
  const isRequestProcessing = useSelector(state => getRequestProcessing(state, submitRequestId));
  const dispatch = useDispatch();
  const glucoseGoalSaved = useSelector(state=> state?.request?.glucoseSaved);
  const avergae = useSelector(state =>getAveragePerWeekBloodGlucose(state,profileId));

  useEffect(() => {
    dispatch(loadGlucoseGoals(profileId));
    dispatch(loadObservationData({profileId, observationType: ObservationType.BloodGlucose}));
    setSubmitRequestId(getUniqueId());
  }, [profileId]);

  function handleBack() {
    props.navigate(backPath);
  }

  if(glucoseGoalSaved){
    handleBack();
  }

  const getInitialValues = () => {
    return {
      fromValue : activeGoal?.fromValue,
      toValue : activeGoal?.toValue
    };
  };

  function onSubmit(input){
    const newValue = {
      fromValue : Number(input.fromValue),
      toValue : Number(input.toValue)
    };
    dispatch(saveGlucoseGoal(profileId, newValue, submitRequestId));
  }
  return(
    <Page
      id='step-target-component'
      backAction={<BackAction handleBack={handleBack} />}
      centerElement={content.goalTitle}
      readOnlyProfile>
      <div className="summary-metricIcon">
        <NavIcon Icon={BloodGlucose}></NavIcon>
      </div>
      <div className='glucoseGoal-Content'>{content.glucoseGoal}</div>

      <BloodGlucoseGoalForm
        initialValues={getInitialValues()}
        isProcessing={isRequestProcessing}
        onSubmit={onSubmit}
        backPath={backPath}
        average={avergae} />
    </Page>
  );
}
export default withNavigation(withRouter(BloodGlucoseGoalPage));
