import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Modal from 'react-modal';
import configureStore from 'app/stores';
import { localStoragePaths } from 'services/constants';
import App from 'app/App';
import AppInsights from 'app/AppInsights';

if (window.IGNORE_LOAD !== true) {
  const initialState = {
    selectedProfile: {
      id: localStorage.getItem(localStoragePaths.selectedProfile)
    }
  };

  const store = configureStore(initialState);
  const root = document.getElementById('app');
  Modal.setAppElement('#app');

  render(
    <Provider store={store}>
      <Router>
        <App />
        <AppInsights />
      </Router>
    </Provider>,
    root
  );
}
