import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { assignNetworkChangeHandler, removeNetworkChangeHandler, handleCurrentNetworkStatus } from 'mobile/helpers';
import { mobileConnected, mobileDisconnected } from './actions';

export class NetworkHandler extends React.Component {
  constructor(props) {
    super(props);
    this.updateNetworkStatus = this.updateNetworkStatus.bind(this);
  }

  componentDidMount() {
    assignNetworkChangeHandler(this.updateNetworkStatus);
    handleCurrentNetworkStatus(this.updateNetworkStatus);
  }

  componentWillUnmount() {
    removeNetworkChangeHandler();
  }

  updateNetworkStatus(status) {
    // eslint-disable-next-line no-shadow
    const { mobileConnected, mobileDisconnected } = this.props;
    if (status.connected) {
      mobileConnected();
    } else {
      mobileDisconnected();
    }
  }

  render() {
    return null;
  }
}

NetworkHandler.propTypes = {
  mobileConnected: PropTypes.func.isRequired,
  mobileDisconnected: PropTypes.func.isRequired
};

const actions = { mobileConnected, mobileDisconnected };

export default connect(null, actions)(NetworkHandler);
