import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import local from 'services/localization/local';
import { loadDashboardPrograms, loadPendingProgramInvitesCount, setContext } from 'programs/programs-actions';
import * as selectors from 'programs/programs-selectors';
import ProgramDashboardTileV1 from './ProgramDashboardTileV1';
import './program-dashboard-v1.scss';
import { Loading } from 'shared';
import { FullScreenModal } from 'shared/full-screen-modal/FullScreenModal';
import { getIsEmailVerified, getIdentityId } from 'identity/selectors';
import { programsContexts } from 'services/routing';
import { noImmunizationPathwayId } from 'app/config';

export default function ProgramDashboardV1() {
  const dispatch = useDispatch();
  const profileId = useSelector(state => state.selectedProfile.id);
  const { loading, loaded, data } = useSelector(selectors.getEnrollmentsStatus);
  const emailVerified = useSelector(getIsEmailVerified);
  const permissionsnotDismissed = useSelector(state => selectors.getActiveUsersProgramInvitesNotDismissed(state));
  const anyProgramPendingInvitesNotDismissed = (permissionsnotDismissed > 0);
  const identityId = useSelector(getIdentityId);

  useEffect(() => {
    dispatch(loadDashboardPrograms(profileId));
  }, [profileId]);

  useEffect(() => {
    if (emailVerified) {
      dispatch(loadPendingProgramInvitesCount(identityId));
    }
  }, [identityId]);

  useEffect(() => {
    dispatch(setContext(programsContexts.default));
  }, []);
  return (
    <section id="program-section">
      <div className='program-header'>{local.dashboard.pathways.activeHeader_v1dashboard}</div>
      {(!data || data.length <= 0) && loading && <div className='program-dashboard-tile'><Loading /></div>}
      {data && data.length > 0 && data.map((enrollment) => (
        enrollment?.program?.programId != noImmunizationPathwayId &&
        <ProgramDashboardTileV1 key={enrollment.id} profileId={profileId} programEnrollment={enrollment} />
      ))}
      {loaded && emailVerified && anyProgramPendingInvitesNotDismissed &&
        <div>
          <FullScreenModal />
        </div>}
    </section>
  );
}
