/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Routes from 'app/Routes';
import Page404 from 'app/page404/Page404';
import local from 'services/localization/local';
import { ErrorPage, LoadingPage } from 'shared';
import AddNewSelfProfileWrapper from 'profiles/update/AddNewSelfProfileWrapper';
import MainNavigation from './navigation/Navigation';
import UserNameRegistration from 'identity/username/UserNameRegistration';
import UserConsentPage from 'app/privacy-policy-eula/userConsent/UserConsentPage';
import VerifyEmailByCode from 'identity/verify-email/VerifyEmailByCode';


export class LayoutComponent extends React.Component {
  render() {
    const { loading, loadError, profileMissing, profile, IsForceRegistration, hasProfileUserName, isEmailverified} = this.props;
    let nav = null;
    let body = null;
    if (loading) {
      body = <LoadingPage />;
    } else if (loadError) {
      body = <ErrorPage id="layout-error">{local.errorLoadProfile}</ErrorPage>;
    } else if (profileMissing) {
      body = <AddNewSelfProfileWrapper />;
      nav = <MainNavigation signOutOnly />;
    } else if (!profile) {
      body = <Page404 />;
      nav = <MainNavigation />;
    } else if (!isEmailverified) {
      body = <VerifyEmailByCode />;
      nav = <MainNavigation signOutOnly />;
    }
    else if (IsForceRegistration && !hasProfileUserName) {
      body = <UserNameRegistration />;
      nav = <MainNavigation signOutOnly />;
    }
    else if (!profile.hipaaConsentResult.hasHipaaConsent) {
      body = <UserConsentPage />;
      nav = <MainNavigation signOutOnly />;
    }   else {
      body = <Routes />;
      nav = <MainNavigation />;
    }

    return (
      <div id="main-layout">
        {nav}
        {body}
      </div>
    );
  }
}

LayoutComponent.displayName = 'Layout';
LayoutComponent.propTypes = {
  profile: PropTypes.shape({}),
  loading: PropTypes.bool,
  loadError: PropTypes.bool,
  profileMissing: PropTypes.bool,
  hasProfileUserName:PropTypes.bool,
  IsForceRegistration: PropTypes.bool,
  isEmailverified: PropTypes.bool
};
LayoutComponent.defaultProps = {
  loading: true,
  loadError: false
};

export default LayoutComponent;
