import React from 'react';
import PropTypes from 'prop-types';
import { CardList } from 'shared';
import OrganizationAdminsListItem from './OrganizationAdminsListItem';
import local from 'services/localization/local';
import './admins-list.scss';

export default function OrganizationAdminsList({ admins }) {
  if (!admins || admins.length === 0) {
    return null;
  }

  return (
    <div id='admins-list-container' data-testid='admins-list' className='list-container'>
      <h5>{local.organizations.admins}</h5>
      <CardList testId='admins-list'>
        {
          admins.map(admin => {
            return (
              <OrganizationAdminsListItem
                key={admin.membershipId}
                profileName={admin.profileName}
                email={admin.email} />
            );
          })
        }
      </CardList>
    </div>
  );
}

OrganizationAdminsList.propTypes = {
  admins: PropTypes.arrayOf(PropTypes.shape({
    profileName: PropTypes.string,
    email: PropTypes.string,
    membershipId: PropTypes.string
  }))
};

