export const onlyNumbers = value => value.replace(/[^\d]/g, '');
export const onlyDecimals = value => value.replace(/[^\d.]/g, '');

const getNumMaxLength = max => (value, prevValue) => {
  if (!value) {
    return value;
  }
  const numHeartRate = onlyNumbers(value);
  if (numHeartRate.length < max + 1) {
    return numHeartRate;
  }

  return prevValue;
};

export const heartRate = getNumMaxLength(3);
export const temperature = getNumMaxLength(3);
export const bloodGlucose = getNumMaxLength(3);
export const bloodPressure = getNumMaxLength(3);
export const peakFlow = getNumMaxLength(4);
export const bloodKetone = getNumMaxLength(3);
export const calories = getNumMaxLength(5);

export const numMaxLength2 = getNumMaxLength(2);
export const numMaxLength4 = getNumMaxLength(4);

const getDecimalMaxLength = max => (value, prevValue) => {
  if (!value) {
    return value;
  }
  const numHeartRate = onlyDecimals(value);
  if (numHeartRate.length < max + 1) {
    return numHeartRate;
  }

  return prevValue;
};

export const bodyArms = getDecimalMaxLength(6);
export const bodyChest = getDecimalMaxLength(6);
export const bodyHips = getDecimalMaxLength(6);
export const bodyThighs = getDecimalMaxLength(6);
export const bodyWaist = getDecimalMaxLength(6);
export const heightInches = getDecimalMaxLength(6);
export const heightFeet = getDecimalMaxLength(6);
export const weightPounds = getDecimalMaxLength(6);
export const temperatureFahrenheit = getDecimalMaxLength(6);
export const macroGoal = getDecimalMaxLength(6);

