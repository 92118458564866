import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

function withContext(WrappedComponent) {
  class Wrapper extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  Wrapper.propTypes = {
    profileId: PropTypes.string
  };

  const mapStateToProps = (state) => ({
    profileId: state.selectedProfile.id
  });

  return withRouter(connect(mapStateToProps)(Wrapper));
}

export default withContext;
