import * as actionNames from './action-names';

export function openDrawerMenu() {
  return {
    type: actionNames.DRAWER_MENU_OPEN
  };
}

export function closeDrawerMenu() {
  return {
    type: actionNames.DRAWER_MENU_CLOSE
  };
}
