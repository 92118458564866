import withContext from 'app/withContext';
import { setIdentityVerifiedFalse } from 'health/connect/connect-actions';
import moment from 'moment';
import { saveNewOtherProfile, saveProfile, saveSelfProfile } from 'profiles/action-creators';
import { profileStatusReset } from 'profiles/actions';
import { getProfileById } from 'profiles/list/selectors';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { bindActionCreators } from 'redux';
import { SubmissionError } from 'redux-form';
import { buildMoment } from 'services/date-service';
import { birthdate } from 'services/fields/field-validators';
import { routePaths } from 'services/routing';
import { getFormName } from './selectors';
import Profile from './UpdateProfile';

class UpdateProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.getHandleSave = this.getHandleSave.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillUnmount() {
    const { profileStatusReset } = this.props.actions;
    setTimeout(profileStatusReset, 200);
  }

  getHandleSave() {
    const { createSelfProfile, isNewAdd, actions } = this.props;
    const { saveProfile, saveNewOtherProfile, saveSelfProfile } = actions;

    if (createSelfProfile) {
      return saveSelfProfile;
    } else if (isNewAdd) {
      return saveNewOtherProfile;
    } else {
      return saveProfile;
    }
  }

  onSubmit(input) {
    const { year, month, day, ...rest } = input;
    let dateOfBirth = null;
    if (year || month || day) {
      dateOfBirth = buildMoment(year, month, day);
      const birthdateError = birthdate(dateOfBirth.format());

      if (birthdateError) {
        throw new SubmissionError({
          month: ' ',
          day: ' ',
          year: ' ',
          dateError: birthdateError
        });
      }
    }

    const saveAction = this.getHandleSave();
    saveAction({ dateOfBirth, ...rest });

    const {profile, actions} = this.props;
    if(!profile )
      return;
    if(input.firstName != profile.firstName || input.lastName != profile.lastName || input.gender != profile.gender || moment(input.dateOfBirth).format(moment.HTML5_FMT.DATE) != moment(profile.dateOfBirth).format(moment.HTML5_FMT.DATE)){
      actions.setIdentityVerifiedFalse(profile.id);
    }
  }

  render() {
    const { saveSuccess, profile, name, loading, loadError, centerElement, hideCancel, location } = this.props;
    const returnUrl = location?.state?.backpath ?? this.props.returnUrl;

    if (saveSuccess) {
      return <Redirect to={returnUrl} />;
    }

    return (
      <Profile
        handleSave={this.onSubmit}
        profile={profile}
        returnUrl={returnUrl}
        name={name}
        loading={loading}
        loadError={loadError}
        centerElement={centerElement}
        hideCancel={hideCancel} />
    );
  }
}

UpdateProfileContainer.propTypes = {
  name: PropTypes.string,
  isNewAdd: PropTypes.bool,
  createSelfProfile: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  loadError: PropTypes.bool.isRequired,
  saveSuccess: PropTypes.bool,
  profile: PropTypes.shape({}),
  returnUrl: PropTypes.string.isRequired,
  centerElement: PropTypes.string,
  hideCancel: PropTypes.bool,
  actions: PropTypes.shape({
    saveProfile: PropTypes.func,
    profileStatusReset: PropTypes.func,
    saveSelfProfile: PropTypes.func,
    saveNewOtherProfile: PropTypes.func,
    setIdentityVerifiedFalse: PropTypes.func
  }).isRequired,
  location: PropTypes.oneOfType([PropTypes.object])
};

function mapStateToProps(state, ownProps) {
  let profile;
  let returnUrl = routePaths.people;

  if (!ownProps.isNewAdd) {
    profile = getProfileById(state, ownProps.profileId);
    returnUrl = routePaths.root;
  }

  return {
    profile,
    returnUrl,
    saveSuccess: state.profile.saved,
    loading: state.profile.loadingList,
    loadError: state.profile.listError,
    name: getFormName(state.form.profile) || ''
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    saveProfile,
    saveSelfProfile,
    profileStatusReset,
    saveNewOtherProfile,
    setIdentityVerifiedFalse
  };

  const actionMap = { actions: bindActionCreators(actions, dispatch) };

  return actionMap;
}

export default withContext(connect(mapStateToProps, mapDispatchToProps)(UpdateProfileContainer));
