import compileReducers from 'services/compile-reducer';
import * as requestQueueReducer from 'app/request/request-queue-reducer';
import * as persistentDataReducer from 'app/request/persistent-data-reducer';
import { types } from './sharing-types';
import { actionNames } from './sharing-actions';

export const initialState = {
  [types.INVITE_REQUESTS]: requestQueueReducer.initialState,
  [types.INCOMING_PROFILE_INVITES]: persistentDataReducer.initialState,
  [types.INCOMING_PROFILE_INVITES_COUNT]: persistentDataReducer.initialState,
  [types.INVITE_STATUSES]: persistentDataReducer.initialState
};

const reducers = {
  ...requestQueueReducer.reducers(types.INVITE_REQUESTS),
  ...persistentDataReducer.reducers(types.INCOMING_PROFILE_INVITES),
  ...persistentDataReducer.reducers(types.INCOMING_PROFILE_INVITES_COUNT),
  ...persistentDataReducer.reducers(types.INVITE_STATUSES)
};

reducers[actionNames.INVITE_DELETE] = (state, payload) => {
  const { identitySelfId, inviteId } = payload;

  const updatedInvites = state[types.INCOMING_PROFILE_INVITES][identitySelfId].data.filter(x => x.id !== inviteId);

  return persistentDataReducer.updateDataStatus(state, types.INCOMING_PROFILE_INVITES, identitySelfId, {
    data: updatedInvites
  });
};

reducers[actionNames.STATUS_DELETE] = (state, payload) => {
  const { profileId, identityId } = payload;

  const updatedStatuses = state[types.INVITE_STATUSES][profileId].data.filter(status => status.id !== identityId);

  return persistentDataReducer.updateDataStatus(state, types.INVITE_STATUSES, profileId, {
    data: updatedStatuses
  });
};

export default compileReducers(reducers, initialState);
