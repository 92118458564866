import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { routePaths } from 'services/routing';
import ProgramsRedirect from 'programs/layout/ProgramsRedirect';
import Page404 from 'app/page404/Page404';
import ProgramList from 'programs/list/ProgramList';
import CompletedProgramList from 'programs/list/CompletedProgramList';
import ProgramDetailsContainer from 'programs/details/ProgramDetailsContainer';
import ProgramEnrollment from 'programs/enrollment/ProgramEnrollment';
import ProgramInviteDetails from 'programs/list/ProgramInviteDetails';
import ProgramPreview from 'programs/preview/ProgramPreview';
import RecordProgramData from 'programs/data/RecordProgramData';
import ViewProgramData from 'programs/data/ViewProgramData';
import ProgramLeaderBoard from 'programs/details/ProgramLeaderBoard';

class ProgramsRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={routePaths.programsRecordData} component={RecordProgramData} />
        <Route exact path={routePaths.programsViewData} component={ViewProgramData} />
        <Route exact path={routePaths.programsDetails} component={ProgramDetailsContainer} />
        <Route exact path={routePaths.programsEnroll} component={ProgramEnrollment} />
        <Route exact path={routePaths.programsPreview} component={ProgramPreview} />
        <Route exact path={routePaths.programsList} component={ProgramList} />
        <Route exact path={routePaths.programsInvite} component={ProgramInviteDetails} />
        <Route exact path={routePaths.programsCompleted} component={CompletedProgramList} />
        <Route exact path={routePaths.programs} component={ProgramsRedirect} />
        <Route exact path={routePaths.programLeaderBoard} component={ProgramLeaderBoard} />
        <Route component={Page404} />
      </Switch>
    );
  }
}

export default ProgramsRoutes;
