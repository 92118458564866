import React from 'react';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import { FormattedText } from 'shared';
import ProgramEnrollButton from './ProgramEnrollButton';
import './program-tile.scss';
import moment from 'moment';
import { momentCalendarDayFormat } from 'services/date-service';

function ProgramTile({ program, showHeroImage, showEnroll, showDescription, organizationId , hideDuration,details}) {
  if (!program) return null;

  const durationMessage = local.formatString((program.durationInDays === 1) ? local.programs.enrollDurationSingular : local.programs.enrollDuration, program.durationInDays);

  const renderHero = () => {
    if (!showHeroImage) {
      return null;
    } else if (program.headerImagePath) {
      return <div className='hero-image' style={{ backgroundImage: `url(${program.headerImagePath})` }} />;
    } else {
      return <figure className='hero-image'></figure>;
    }
  };

  const renderIcon = () => {
    if (program.iconPath) {
      return <img className='icon-image' src={program.iconPath} />;
    } else {
      return <figure className='icon-image'></figure>;
    }
  };

  const renderEndDate = () =>{
    const now = moment();
    const {
      complete,
      completeDateTime,
      endDateTime,
      readyToComplete
    } = details;

    let endingMessage = null;

    if (complete) {
      endingMessage = local.formatString(local.programs.detailsCompletedOnDate, moment(completeDateTime).calendar(null, momentCalendarDayFormat));
    } else if (readyToComplete && now.isAfter(endDateTime)) {
      endingMessage = local.formatString(local.programs.detailsEndedOnDate, moment(endDateTime).calendar(null, momentCalendarDayFormat));
    } else {
      endingMessage = local.formatString(local.programs.detailsEndingDate, moment(endDateTime).calendar(null, momentCalendarDayFormat));
    }

    return (
      <small className="program-time">
        <span className="program-ending">{endingMessage}</span>
      </small>
    );
  };


  return (
    <div className="program-tile">
      {renderHero()}
      <div className="program-enrollment-info">
        {renderIcon()}
        <strong className="program-title" title={program.name}>{program.name}</strong>
        {showEnroll ? <ProgramEnrollButton programInfoId={program.id} organizationId={organizationId} /> : null}
      </div>
      {showDescription ? <FormattedText text={program.description} /> : null}
      <div className='d-flex'>
        {!hideDuration && <small>{durationMessage}</small>}
        { details ?  renderEndDate() : ''}
      </div>
    </div>
  );
}

ProgramTile.propTypes = {
  program: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    iconPath: PropTypes.string,
    headerImagePath: PropTypes.string,
    durationInDays: PropTypes.number
  }),
  showHeroImage: PropTypes.bool,
  showDescription: PropTypes.bool,
  showEnroll: PropTypes.bool,
  organizationId: PropTypes.string,
  hideDuration: PropTypes.bool
};
ProgramTile.defaultProps = {
  showHeroImage: false,
  showDescription: false,
  showEnroll: false,
  hideDuration: false
};

export default ProgramTile;

