import React, { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Icon } from 'shared';
import { NavLink } from 'react-router-dom';
import { routePaths } from 'services/routing';
import { unreadNotificationsCount } from 'notifications/notification-selectors';
import { loadNotifications } from 'notifications/notification-actions';
import { getIdentityId } from 'identity/selectors';
import { updateShowBadgeAchievedScreen } from 'badge/action-creators';

export default function NotificationsLink() {
  const dispatch = useDispatch();
  const identityId = useSelector(getIdentityId);
  const unreadCount = useSelector(unreadNotificationsCount, shallowEqual);
  const badgeSummary = useSelector(state => state.badgeSummary);
  const hasUnread = unreadCount > 0;

  useEffect(() => {
    if (identityId) {
      dispatch(loadNotifications());
      dispatch(updateShowBadgeAchievedScreen(true, badgeSummary.showBadgeAchievedScreen?.retryCount > 0 ? badgeSummary.showBadgeAchievedScreen?.retryCount : 1));
    }
  }, [identityId]);

  return (
    <NavLink
      to={routePaths.notifications}
      id="notifications-page-link"
      activeClassName="active">
      <Icon symbol="notification" />
      {identityId && hasUnread && <figure>{unreadCount}</figure>}
    </NavLink>
  );
}

