import moment from 'moment';
import { HealthDataSource } from 'services/enums';
import { healthDataApiRoot as apiRoot } from 'app/config';
import { httpGet, httpPost, httpDelete, httpPut, httpPatch, buildUrl, parseSuccess, parseError } from './api';

export const getObservation = (searchModel, state) => {
  var url = buildUrl(apiRoot, '/observations', searchModel);

  return httpGet(url, state)
    .then(parseSuccess);
};

export const postObservation = (data, profileId, state) => {
  var url = buildUrl(apiRoot, '/observations', { profileId });

  return httpPost(url, data, state)
    .catch(parseError);
};

export const postGroupedObservations = (data, profileId, state) => {
  var url = buildUrl(apiRoot, '/grouped-observations', { profileId });

  return httpPost(url, data, state)
    .catch(parseError);
};

export const deleteObservation = (observationId, profileId, state) => {
  var url = buildUrl(apiRoot, `/observations/${observationId}`, { profileId });

  return httpDelete(url, {}, state)
    .catch(parseError);
};

export const deleteFileObservation = (observationId, profileId, deleteFileObservationModal, state) => {
  var url = buildUrl(apiRoot, `/observations/${observationId}`, { profileId });

  return httpDelete(url, deleteFileObservationModal, state)
    .catch(parseError);
};

export const getBlobData = (profileId, blobUrls, state) => {
  var url = buildUrl(apiRoot, '/observations/get-files', { profileId });

  return httpPost(url, blobUrls, state)
    .catch(parseError);
};

export const getMedications = (profileId, state) => {
  var url = buildUrl(apiRoot, '/medications', { profileId });

  return httpGet(url, state)
    .then(parseSuccess);
};

export const postMedication = (medication, profileId, state) => {
  var url = buildUrl(apiRoot, '/medications', { profileId });

  return httpPost(url, medication, state)
    .catch(parseError);
};

export const putMedication = (medication, profileId, state) => {
  var url = buildUrl(apiRoot, `/medications/${medication.id}`, { profileId });

  return httpPut(url, medication, state)
    .catch(parseError);
};

export const deleteMedication = (medicationId, profileId, state) => {
  var url = buildUrl(apiRoot, `/medications/${medicationId}`, { profileId });

  return httpDelete(url, {}, state)
    .catch(parseError);
};

export const postMedicationDose = (dose, profileId, state) => {
  var url = buildUrl(apiRoot, '/medications/doses', { profileId });

  return httpPost(url, dose, state)
    .catch(parseError);
};

export const getMedicationDoses = (queryParams, state) => {
  var url = buildUrl(apiRoot, '/medications/doses', queryParams);

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const deleteMedicationDose = (medicationDoseId, profileId, state) => {
  var url = buildUrl(apiRoot, `/medications/doses/${medicationDoseId}`, { profileId });

  return httpDelete(url, {}, state)
    .catch(parseError);
};

export const getPrograms = (state) => {
  var url = buildUrl(apiRoot, '/programs', { profileId: state.selectedProfile.id });
  return httpGet(url, state)
    .then(parseSuccess);
};

export const getDashboardPrograms = (state) => {
  const utcOffsetMinutes = moment().utcOffset();
  var url = buildUrl(apiRoot, '/programs/get-all-enrollments', { profileId: state.selectedProfile.id, utcOffsetMinutes });

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};
export const getPendingInvitedPrograms = (state) => {
  var secondUrl = buildUrl(apiRoot, '/programs/invitedPrograms', { profileId: state.selectedProfile.id });
  return httpGet(secondUrl, state)
    .then(parseSuccess);
};

export const getProgram = (programId, state) => {
  var url = buildUrl(apiRoot, `/programs/${programId}`, { profileId: state.selectedProfile.id });

  return httpGet(url, state)
    .then(parseSuccess);
};

export const getProgramDetails = (programEnrollmentId, profileId, state) => {
  const utcOffsetMinutes = moment().utcOffset();
  var url = buildUrl(apiRoot, `/programs/enrollment/${programEnrollmentId}`, { profileId, utcOffsetMinutes, useProgramId: true });

  return httpGet(url, state)
    .then(parseSuccess);
};

export const getProgramPermissions = (programId, memberId, state) => {
  var url = buildUrl(apiRoot, '/programs/permissions', { programId, memberId });

  return httpGet(url, state)
    .then(parseSuccess);
};

export const getUpgradedObservations = (profileId, programId, state) => {
  var url = buildUrl(apiRoot, '/programs/get-upgraded-observations', { profileId, programId });

  return httpGet(url, state)
    .then(parseSuccess);
};

export const getActiveUsersProgramInvites = (state) => {
  var url = buildUrl(apiRoot, '/programs/active-users-permissions');

  return httpGet(url, state)
    .then(parseSuccess);
};

export const getPendingProgramInvitesCount = (state) => {
  var url = buildUrl(apiRoot, '/programs/pending-invite-notifcation-shown-count');

  return httpGet(url, state)
    .then(parseSuccess);
};

export const getProgramEnrollments = (profileId, state) => {
  var url = buildUrl(apiRoot, '/programs/enrollment', { profileId });

  return httpGet(url, state)
    .then(parseSuccess);
};

export const postProgramEnroll = (programInfoId, profileId, organizationId, state) => {
  const utcOffsetMinutes = moment().utcOffset();
  var url = buildUrl(apiRoot, '/programs/enrollment', { profileId });
  var body = { profileId, programInfoId, utcOffsetMinutes, organizationId };

  return httpPost(url, body, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const postProgramComplete = (programEnrollmentId, profileId, completionCode, state) => {
  var url = buildUrl(apiRoot, `/programs/enrollment/${programEnrollmentId}/complete`, { profileId, completionCode });

  return httpPost(url, {}, state)
    .then(parseSuccess);
};

export const postCompleteTask = (programEnrollmentTaskId, profileId, completed, state) => {
  var url = buildUrl(apiRoot, '/programs/tasks', { profileId });
  return httpPost(url, { programEnrollmentTaskId, completed }, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const postCompleteInterviewTask = (programEnrollmentTaskId, interviewId, answers, profileId, state) => {
  var url = buildUrl(apiRoot, '/programs/tasks/interview', { profileId });
  return httpPost(url, { programEnrollmentTaskId, interviewId, answers }, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const saveInterviewTaskDraftAnswers = (programEnrollmentTaskId, interviewId, draftAnswers, profileId, state) => {
  var url = buildUrl(apiRoot, '/programs/tasks/interview/draft-answers', { profileId });
  return httpPost(url, { programEnrollmentTaskId, interviewId, draftAnswers }, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getInterview = (interviewId, programEnrollmentTaskId, state) => {
  var url = buildUrl(apiRoot, `/interviews/details/${interviewId}`, { profileId: state.selectedProfile.id, programEnrollmentTaskId: programEnrollmentTaskId });
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const postInterview = (interviewId, questions, state) => {
  var url = buildUrl(apiRoot, `/interviews/${interviewId}`, { profileId: state.selectedProfile.id });
  return httpPost(url, questions, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const postActivity = (profileId, state, activityRequest) => {
  var url = buildUrl(apiRoot, '/activities', { profileId });
  return httpPost(url, activityRequest, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getActivities = (profileId, dateRangeStart, dateRangeEnd, state) => {
  var url = buildUrl(apiRoot, '/activities', { profileId, dateRangeStart, dateRangeEnd });
  return httpGet(url, state)
    .then(parseSuccess);
};

export const deleteActivity = (profileId, state, activityId) => {
  var url = buildUrl(apiRoot, `/activities/${activityId}`, { profileId });
  return httpDelete(url, null, state)
    .catch(parseError);
};

export const getValidicProfile = (profileId, state) => {
  var url = buildUrl(apiRoot, `/data-sources/${HealthDataSource.Validic}/profile`, { profileId });
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getDevices = (profileId, state) => {
  var url = buildUrl(apiRoot, '/v1/devices/get-disconnected-devices', { profileId });
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getNotifications = (state) => {
  var url = buildUrl(apiRoot, '/notifications');
  return httpGet(url, state)
    .then(parseSuccess);
};

export const postNotificationInteraction = (state, notificationId) => {
  const url = buildUrl(apiRoot, `/notifications/${notificationId}/interact`);
  return httpPost(url, null, state);
};

export const patchAllNotificationsRead = (state) => {
  const url = buildUrl(apiRoot, '/notifications/read-all');
  return httpPatch(url, null, state);
};

export const deleteNotification = (state, notificationId) => {
  const url = buildUrl(apiRoot, `/notifications/${notificationId}`);
  return httpDelete(url, null, state);
};

export const deleteAllNotification = (state) => {
  const url = buildUrl(apiRoot, '/notifications/delete-all');
  return httpDelete(url, null, state)
    .catch(parseError);
};

export const getConditions = (state, profileId) => {
  var url = buildUrl(apiRoot, '/conditions', { profileId });

  return httpGet(url, state)
    .then(parseSuccess);
};

export const syncConditions = (profileId, healthDataSource, state, neverReceievedElectronicRecords, triedCount) => {
  var url = buildUrl(apiRoot, `/data-sources/${healthDataSource}/sync`, { healthDataSource, profileId, triedCount, neverReceievedElectronicRecords });
  return httpPost(url, null, state)
    .catch(parseError);
};

export const updateSkipSync = (profileId, state, triedCount) => {
  var url = buildUrl(apiRoot, '/data-sources/sync/update', { profileId, triedCount });
  return httpPost(url, null, state)
    .catch(parseError);
};


export const getImportCountSummaries = (state, profileId) => {
  var url = buildUrl(apiRoot, '/health-records/counts', { profileId });

  return httpGet(url, state)
    .then(parseSuccess);
};

export const markImportCountSummaryAccessed = (state, profileId, types) => {
  var url = buildUrl(apiRoot, '/health-records/counts-accessed', { profileId });

  return httpPost(url, types, state);
};

export const getEncounters = (state, profileId) => {
  var url = buildUrl(apiRoot, '/encounters', { profileId });

  return httpGet(url, state)
    .then(parseSuccess);
};

export const getImmunizations = (state, profileId) => {
  var url = buildUrl(apiRoot, '/immunizations', { profileId });

  return httpGet(url, state)
    .then(parseSuccess);
};

export const getLabResults = (state, profileId) => {
  var url = buildUrl(apiRoot, '/labs', { profileId });

  return httpGet(url, state)
    .then(parseSuccess);
};

export const getProcedures = (state, profileId) => {
  var url = buildUrl(apiRoot, '/procedures', { profileId });

  return httpGet(url, state)
    .then(parseSuccess);
};

export const getSyncStatus = (healthDataSource, profileId, state) => {
  var url = buildUrl(apiRoot, `/data-sources/${healthDataSource}/sync`, { healthDataSource, profileId });

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const postJournal = (profileId, state, createRequest) => {
  var url = buildUrl(apiRoot, '/journal', { profileId });
  return httpPost(url, createRequest, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getJournal = (profileId, dateRangeStart, dateRangeEnd, state) => {
  var url = buildUrl(apiRoot, '/journal', { profileId, dateRangeStart, dateRangeEnd });
  return httpGet(url, state)
    .then(parseSuccess);
};

export const deleteJournal = (profileId, state, journalEntryId) => {
  var url = buildUrl(apiRoot, `/journal/${journalEntryId}`, { profileId });
  return httpDelete(url, null, state)
    .catch(parseError);
};

export const getEnrollmentReferrals = (profileId, state, programEnrollmentId) => {
  const url = buildUrl(apiRoot, `/programs/referrals/${programEnrollmentId}`, { profileId });
  return httpGet(url, state)
    .then(parseSuccess);
};

export const postProgramCompleteAndReEnroll = (profileId, completedEnrollmentId, optInOrganizationIds, completionCode, state) => {
  const utcOffsetMinutes = moment().utcOffset();
  const url = buildUrl(apiRoot, '/programs/re-enrollment', { profileId });
  const body = { completedEnrollmentId, optInOrganizationIds, utcOffsetMinutes, completionCode };

  return httpPost(url, body, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getOrganizationProgramsForProfile = (profileId, organizationId, state) => {
  const url = buildUrl(apiRoot, '/identity/organizations/programs', { profileId, organizationId });
  return httpGet(url, state)
    .then(parseSuccess);
};

export const getSeverityLevel = (state, enrollmentId, profileId) => {
  const url = buildUrl(apiRoot, `/programs/enrollment/${enrollmentId}/severity`, { profileId });
  return httpGet(url, state)
    .then(parseSuccess);
};

export const loadMedicationNotificationDoses = (profileId, searchDate, state) => {
  const utcOffsetMinutes = moment().utcOffset();
  var url = buildUrl(apiRoot, `/medications/${profileId}/notifications`, { searchDate, utcOffsetMinutes });

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const putMedicationNotification = (medicationNotification, profileId, state) => {
  var url = buildUrl(apiRoot, `/medications/${profileId}/notifications`);
  return httpPost(url, medicationNotification, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const putMedicationNotificationWhenDeleteDose = (medicationNotificationId, profileId, state) => {
  var url = buildUrl(apiRoot, `/notifications/reset-doses/${medicationNotificationId}`, { profileId });
  return httpPost(url, {}, state)
    .catch(parseError);
};

export const getHealthSummary = (profileId, state) => {
  const utcOffsetMinutes = moment().utcOffset();
  var url = buildUrl(apiRoot, '/getHealthSummary', { profileId, utcOffsetMinutes });
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getSuggestedPathways = (state) => {
  var url = buildUrl(apiRoot, '/suggested-programs/', { profileId: state.selectedProfile.id });
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getVaccinationProof = (state, profileId, metricName) => {
  var url = buildUrl(apiRoot, '/metric/interview/image/last-uploaded', { profileId, metricName });
  return httpGet(url, state)
    .then(parseSuccess);
};

export const getConnectedDevices = (profileId, state) => {
  var url = buildUrl(apiRoot, '/v1/connected-devices/get', { profileId });
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const disconnectDevice = (profileId, deviceType, state) => {
  var url = buildUrl(apiRoot, `/v1/disconnect-device/${deviceType}`, { profileId });
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const isGoalBasedEnrollment = (profileId, enrollmentId, state) => {
  var url = buildUrl(apiRoot, '/goal/is-goal-based-enrollment', { profileId, enrollmentId });
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getProfileObservationGoals = (profileId, enrollmentId, state) => {
  var url = buildUrl(apiRoot, '/goal/profile-observation-goals', { profileId, enrollmentId });
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getResourceRatingDetails = (state, programEnrollmentId, profileId, organizationId, programId) => {
  var url = buildUrl(apiRoot, `/programs/enrollment/${programEnrollmentId}/search-program-resource`, { profileId, organizationId, programId });
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getLeaderBoardSummary = (state, profileId, programId, searchDate) => {
  const parameters = { profileId, programId };
  if (searchDate)
    parameters.searchDate = searchDate;
  var url = buildUrl(apiRoot, '/leaderBoard', parameters);
  return httpGet(url, state)
    .then(parseSuccess);
};

export const getHasEnrolledInActiveEventProgram = (state, profileId, programId) => {
  var url = buildUrl(apiRoot, '/leaderBoard/enrolled-active-event-program', { profileId, programId });
  return httpGet(url, state)
    .then(parseSuccess);
};

export const createResourceRating = (state, resourceRatingModel, profileId) => {
  var url = buildUrl(apiRoot, '/programs/enrollment/create-program-resource', { profileId });
  return httpPost(url, resourceRatingModel, state)
    .then(parseSuccess)
    .catch(parseError);
};
export const updateResourceRating = (state, resourceRatingModel, profileId) => {
  var url = buildUrl(apiRoot, '/programs/enrollment/update-program-resource', { profileId });
  return httpPut(url, resourceRatingModel, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getBadgesIcon = (profileId, state) => {
  var url = buildUrl(apiRoot, '/badge/event-badge/get-all-icons', { profileId });
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const postStepTargetGoal = (profileId, saveRequest, state) => {
  var url = buildUrl(apiRoot, '/goal/set-target', { profileId });
  return httpPost(url, saveRequest, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getTrackSteps = (state, profileId, observationType) => {
  var url = buildUrl(apiRoot, '/goal/get-step-goals', { profileId, observationType });
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getSyncedData = (state, profileId) => {
  var url = buildUrl(apiRoot, '/connected-devices/synced-health-data', { profileId });
  return httpGet(url, state)
    .then(parseSuccess);
};

export const getCurrentStepTarget = (state, profileId, observationType) => {
  var url = buildUrl(apiRoot, '/goal/get-current-step-target', { profileId, observationType });
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const updateInviteLastDismissedOn = (state) => {
  var url = buildUrl(apiRoot, '/programs/dismiss-invite-notifications');
  return httpPut(url, {}, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getDashBoardObservation = (state, profileId, utcOffsetMinutes) => {
  var url = buildUrl(apiRoot, '/observations/dashboard', { profileId, utcOffsetMinutes });
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getDashboardHealthRecords = (state, profileId) => {
  var url = buildUrl(apiRoot, '/health/dashboard-healthrecords-summary', { profileId });

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getObservationGoals = (state, profileId, observationType) => {
  var url = buildUrl(apiRoot, '/goal/details', { profileId, observationType });
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const postGoalList = (profileId, saveRequest, state) => {
  var url = buildUrl(apiRoot, '/goal/set-targets', { profileId });
  return httpPost(url, saveRequest, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getProgramInfos = (state, profileId, programIds) => {
  var url = buildUrl(apiRoot, '/programs/get-program-info', { profileId });
  return httpPost(url, programIds, state)
    .then(parseSuccess)
    .catch(parseError);
};


export const getGoalSummary = (state, profileId, body) => {
  var url = buildUrl(apiRoot, '/goalsummary/get', { profileId });
  return httpPost(url, body, state)
    .then(parseSuccess)
    .catch(parseError);
};

