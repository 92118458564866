import * as persistentDataActions from 'app/request/persistent-data-actions';
import * as healthDataApi from 'services/api/healthdata-api';
import { types } from './devices-types';
import * as deviceManagerApi from 'services/api/deviceManager-api';
import { isMobile, openRouteInApp, openMobileRoute } from 'mobile/helpers';
import { loadObservationConnections } from 'profile-observation-connections/action-creators';


export function loadValidicProfile(profileId) {
  return (dispatch) => {
    const action = state => healthDataApi.getValidicProfile(profileId, state);
    dispatch(persistentDataActions.request(action, types.VALIDIC_PROFILE, profileId, { profileId }));
  };
}

export function loadConnectedDevices(profileId) {
  return (dispatch) => {
    const action = state => healthDataApi.getConnectedDevices(profileId, state).then((result)=>{
      return result;
    });
    dispatch(persistentDataActions.request(action, types.CONNECTED_DEVICES, profileId, { profileId }));
  };
}

export function disconnectDevice(profileId,deviceType) {
  return (dispatch) => {
    const action = state => healthDataApi.disconnectDevice(profileId,deviceType, state).then((result)=>{
      return result;
    });
    dispatch(persistentDataActions.request(action, types.CONNECTED_DEVICES, profileId, { profileId }));
  };
}

export function loadSyncedData(profileId) {
  return (dispatch) => {
    const action = state => healthDataApi.getSyncedData(state, profileId);
    dispatch(persistentDataActions.request(action, types.SYNCED_DATA, profileId, { profileId }));
  };
}

export function loadDevices(profileId) {
  return (dispatch) => {
    const action = state => healthDataApi.getDevices(profileId, state);
    dispatch(persistentDataActions.request(action, types.DISCONNECTED_DEVICES, profileId, { profileId }));
  };
}

//////////////////// Terra Action //////////////////////////

export function connectTerraDevice(profileId, providerUserId) {
  return (dispatch) => {
    const action = state => deviceManagerApi.connectTerraDevice(state, profileId, providerUserId).then(() => {
      dispatch(loadTerraDevices(profileId));
    });

    dispatch(persistentDataActions.request(action, types.TERRA_USER_INFO, profileId, { providerUserId }));
  };
}

export function loadTerraDevices(profileId) {
  return (dispatch) => {
    // eslint-disable-next-line import/namespace
    const action = state => deviceManagerApi.getTerraDevices(state, profileId);
    dispatch(persistentDataActions.request(action, types.TERRA_DEVICES, profileId));
  };
}

export function getTerraConnectionUrl(profileId, deviceSourceName, redirectUrl) {
  const data = {
    reference_id : profileId,
    auth_success_redirect_url: redirectUrl
  } ;

  return (dispatch) => {
    const action = state => deviceManagerApi.getTerraConnectionUrl(profileId, deviceSourceName, data, state).then((result) => {

      if (isMobile) {
        openRouteInApp(result);
      }
      else {
        window.location.href = result;
      }
    });

    dispatch(persistentDataActions.request(action, types.TERRA_PROFILE, profileId, {deviceSourceName}));
  };
}

export function getTerraTokenAndConnect(profileId,connect = true) {

  return (dispatch) => {
    const action = state => deviceManagerApi.getTerraToken(profileId, state).then((result) => {

      if (result) {
        let resultData = JSON.parse(result);
        let base = connect ? 'healthkitconnect':'healthkitdisconnect';
        let redirectUri = `${base}?proofileId=${profileId}&token=${resultData.token}&key=${resultData.developerKey}`;
        openMobileRoute(redirectUri);
      }
    });
    dispatch(persistentDataActions.request(action, types.TERRA_PROFILE, profileId, null));
  };
}

export function disconnectTerraDevice(profileId, providerUserId) {

  return (dispatch) => {
    const action = state => deviceManagerApi.disconnectTerraDevice(profileId, providerUserId, state).then(() => {
      dispatch(loadTerraDevices(profileId));
    });

    dispatch(persistentDataActions.request(action, types.TERRA_USER_INFO, profileId, { providerUserId }));
  };
}

export function loadObservationValidicProfile(profileId, programId) {
  return (dispatch) => {

    const getConnectedDevice = (result) =>{
      dispatch(loadConnectedDevices(profileId));
      return result;
    };

    const getDisconnectedDevice = (result) =>{
      dispatch(loadDevices(profileId));
      return result;
    };

    const getTerraDevices = (result) =>{
      dispatch(loadTerraDevices(profileId));
      return result;
    };

    const getObservationConnections = (result) =>{
      dispatch(loadObservationConnections(profileId,programId));
      return result;
    };

    const action = state => healthDataApi.getValidicProfile(profileId, state).then((result)=>getConnectedDevice(result)).then((result)=>getTerraDevices(result))
      .then((result)=>getDisconnectedDevice(result)).then((result)=>getObservationConnections(result)).then((result)=> {return result;});
    dispatch(persistentDataActions.request(action, types.VALIDIC_PROFILE, profileId, { profileId }));
  };
}

export function loadActiveDevices(profileId) {
  return (dispatch) => {
    // eslint-disable-next-line import/namespace
    const action = state => deviceManagerApi.getActiveDevices(state, profileId);
    dispatch(persistentDataActions.request(action, types.ACTIVE_DEVICES, profileId));
  };
}
