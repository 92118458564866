import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import local from 'services/localization/local';
import * as selectors from 'programs/programs-selectors';
import ProgramProgressBar from './ProgramProgressBar';
import './program-progress-section.scss';

class ProgramProgressSection extends React.Component {
  constructor(props) {
    super(props);

    this.renderPrimaryMessage = this.renderPrimaryMessage.bind(this);
  }

  renderPrimaryMessage() {
    const { progress } = this.props;
    const {
      complete,
      currentDay,
      totalDays
    } = progress;

    if (complete) {
      return local.programs.detailsComplete;
    } else {
      return (
        <div className="program-progress-days">
          <small>{local.formatString(local.programs.detailsDayProgress, currentDay, totalDays)}</small>
        </div>
      );
    }
  }


  render() {
    const { progress, loading } = this.props;

    if (!progress) return null;

    const {
      currentTasksComplete,
      tasksComplete,
      tasksTotal
    } = progress;

    let tasksFraction = local.formatString(local.programs.detailsTasksComplete, tasksComplete, tasksTotal);

    if (currentTasksComplete) {
      if (tasksTotal === 0) {
        tasksFraction = local.programs.detailsTasksAvailableEmpty;
      } else if (tasksTotal === 1) {
        tasksFraction = local.programs.detailsAvailableTaskCompleteSingle;
      } else {
        tasksFraction = local.formatString(local.programs.detailsAvailableTasksComplete, tasksTotal);
      }
    }


    return (
      <section className="program-progress">
        <div>
          <div className='program-title'>{local.programs.taskListTitleCurrent}</div>
          <ProgramProgressBar progress={progress} />
          <div className="program-action-details">
            <div className="call-to-action-wrapper">
            <div className="program-compliance "><strong>{tasksFraction}</strong> {loading && <span className='inline-spinner'></span>}</div>
              {this.renderPrimaryMessage()}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ProgramProgressSection.propTypes = {
  loading: PropTypes.bool.isRequired,
  progress: PropTypes.shape({
    complete: PropTypes.bool.isRequired,
    completeDateTime: PropTypes.string,
    programId: PropTypes.string.isRequired,
    programEnrollmentId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    startDateTime: PropTypes.string.isRequired,
    endDateTime: PropTypes.string.isRequired,
    currentTasksComplete: PropTypes.bool.isRequired,
    currentDay: PropTypes.number.isRequired,
    totalDays: PropTypes.number.isRequired,
    daysPercentComplete: PropTypes.number.isRequired,
    tasksComplete: PropTypes.number.isRequired,
    tasksTotal: PropTypes.number.isRequired,
    tasksPercentComplete: PropTypes.number.isRequired,
    readyToComplete: PropTypes.bool.isRequired
  })
};

function mapStateToProps(state, ownProps) {
  const { programEnrollmentId } = ownProps;
  return {
    progress: selectors.getProgramDetails(state, programEnrollmentId),
    loading: selectors.getProgramDetailsLoading(state, programEnrollmentId)
  };
}

export default connect(mapStateToProps)(ProgramProgressSection);
