import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'shared';
import './avatar-card.scss';

export default function AvatarCard({ heading, subheading, avatar, actionButtons }) {
  const headingStyles = subheading ? 'invited-name' : 'invited-name only-heading';
  return (
    <Card>
      <div className='invite-container'>
        <div className='invite-info'>
          {avatar}
          <div className='invite-text'>
            <span className={headingStyles}>{heading}</span>
            {subheading && (<small>{subheading}</small>)}
          </div>
        </div>
        {actionButtons}
      </div>
    </Card>
  );
}

AvatarCard.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  avatar: PropTypes.node,
  actionButtons: PropTypes.node
};
