import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withContext from 'app/withContext';
import { ProgramsContext } from 'services/routing';
import { ProfileDataLoader } from 'shared';
import { loadPrograms, loadProgramEnrollments } from 'programs/programs-actions';
import ProgramsRoutes from './ProgramsRoutes';

class ProgramsLayout extends Component {
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
  }

  loadData() {
    const { profileId, actions } = this.props;
    actions.loadPrograms(profileId);
    actions.loadProgramEnrollments(profileId);
  }

  render() {
    const { context } = this.props;

    return (
      <ProgramsContext.Provider value={context}>
        <ProfileDataLoader loadData={this.loadData}>
          <ProgramsRoutes />
        </ProfileDataLoader>
      </ProgramsContext.Provider>
    );
  }
}

ProgramsLayout.propTypes = {
  context: PropTypes.shape({}).isRequired,
  // actions
  actions: PropTypes.shape({
    loadPrograms: PropTypes.func.isRequired,
    loadProgramEnrollments: PropTypes.func.isRequired
  }).isRequired,
  profileId: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    context: state.programs.context
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { loadPrograms, loadProgramEnrollments };
  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

export default withContext(connect(mapStateToProps, mapDispatchToProps)(ProgramsLayout));
