import React from 'react';
import { Icon } from 'shared';
import { NavLink } from 'react-router-dom';
import { routePaths } from 'services/routing';

export default function HealthBottomNavLink() {
// const syncStatus = useSyncStatus();
// const { isConnected, isSyncing } = syncStatus;
  const isConnected = false;
  const isSyncing =false;


  const connected = <figure className='action'><Icon size="1em" symbol="yes" /></figure>;
  const syncing = <figure className='spin action'><Icon size="1em" symbol="loader" /></figure>;

  return (
    <NavLink id="nav-link-health" to={routePaths.health} className="nav-health health_bottom_nav" activeClassName="active" exact>
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" className="ui-icon undefined" fill="currentcolor">
        <g>
          <g>
            <path d="M20,6h-4V4c0-1.1-0.9-2-2-2h-4C8.9,2,8,2.9,8,4v2H4C2.9,6,2,6.9,2,8v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8 C22,6.9,21.1,6,20,6z M10,4h4v2h-4V4z M20,20H4V8h16V20z" />

            <polygon points="13,10 11,10 11,13 8,13 8,15 11,15 11,18 13,18 13,15 16,15 16,13 13,13" />
          </g>
        </g>
      </svg>

      {isConnected && !isSyncing && connected}

      {isConnected && isSyncing && syncing}
    </NavLink>
  );
}
