import React from 'react';
import PropTypes from 'prop-types';
import { TextField, ErrorField } from 'shared/form-fields';
import { buildDuration, getDurationHoursMinutesSeconds } from 'services/date-service';
import * as normalizers from 'services/fields/field-normalizers';

class TimeField extends React.Component {
  constructor(props) {
    super(props);
    this.getHoursInput = this.getHoursInput.bind(this);
    this.getMinutesInput = this.getMinutesInput.bind(this);
    this.getSecondsInput = this.getSecondsInput.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.updateState = this.updateState.bind(this);
    this.onHoursChange = this.onHoursChange.bind(this);
    this.onMinutesChange = this.onMinutesChange.bind(this);
    this.onSecondsChange = this.onSecondsChange.bind(this);

    this.state = this.getInitialState(props);
  }

  getInitialState(props) {
    const { meta: { initial } } = props;
    if (initial) {
      return getDurationHoursMinutesSeconds(initial);
    }

    return {
      hours: null,
      minutes: null,
      seconds: null
    };
  }

  onInputChange(event, key) {
    const time = { ...this.state };

    // Enforce input value constraints
    const value = normalizers.numMaxLength4(event.target.value, time[key]);

    // Update hours/minutes/seconds according to input key
    time[key] = value;

    this.updateState(event, time);
  }

  updateState(event, time) {
    const { input: { onChange, onBlur } } = this.props;

    // State is stored in local component to preserve the
    // hours/minutes/seconds values being inputted
    // i.e. so > 60 minutes aren't converted to 1 hour
    this.setState({ ...time });

    // State is held in redux store as an
    // iso8601 duration string and is updated
    // based on event type
    if (event.type === 'change') {
      onChange(buildDuration(time));
    }

    if (event.type === 'blur') {
      onBlur(buildDuration(time));
    }
  }

  onHoursChange(event) {
    this.onInputChange(event, 'hours');
  }

  onMinutesChange(event) {
    this.onInputChange(event, 'minutes');
  }

  onSecondsChange(event) {
    this.onInputChange(event, 'seconds');
  }

  getHoursInput() {
    const { input } = this.props;
    return {
      ...input,
      id: `${input.id}-hours`,
      value: this.state.hours,
      onChange: this.onHoursChange,
      onBlur: this.onHoursChange
    };
  }

  getMinutesInput() {
    const { input } = this.props;
    return {
      ...input,
      id: `${input.id}-minutes`,
      value: this.state.minutes,
      onChange: this.onMinutesChange,
      onBlur: this.onMinutesChange
    };
  }

  getSecondsInput() {
    const { input } = this.props;
    return {
      ...input,
      id: `${input.id}-seconds`,
      value: this.state.seconds,
      onChange: this.onSecondsChange,
      onBlur: this.onSecondsChange
    };
  }

  render() {
    const { label, meta } = this.props;
    const fieldMeta = { ...meta, error: meta.error ? ' ' : undefined };

    return (
      <div className="form-row combined-fields">
        {label && (
          <label className="form-label " htmlFor="time">{label}</label>
        )}
        <div className="fieldWrapper">
          <TextField
            input={this.getHoursInput()}
            meta={fieldMeta}
            type="tel"
            label="Hours" />
          <TextField
            input={this.getMinutesInput()}
            meta={fieldMeta}
            type="tel"
            label="Minutes" />
          <TextField
            input={this.getSecondsInput()}
            meta={fieldMeta}
            type="tel"
            label="Seconds" />
        </div>
        <ErrorField meta={meta} />
      </div>
    );
  }
}

TimeField.propTypes = {
  meta: PropTypes.shape().isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
  }).isRequired,
  label: PropTypes.string
};

export default TimeField;
