import React from 'react';
import local from 'services/localization/local';
import { LinkWrapper } from 'shared';
import { routePaths } from 'services/routing';
import { configIds } from 'health/observations/observation-config-ids';
import './timeline-no-data.scss';

export default function TimelineNoData() {

  return (
    <div id="timeline-no-data" className='timeline-no-data'>
      <figure>
        <img src="https://static.myhc.app/programs/hcMedicalData.svg" alt="health data" />
      </figure>
      <article>
        <h4>{local.timeline.noData.header}</h4>
        <p><small>{local.timeline.noData.message}</small></p>
        <p><small><strong>{local.timeline.noData.callToAction}</strong></small></p>
        <p>
          <LinkWrapper
            id='timeline-add-temperature'
            className="blue button btn btn-primary"
            to={routePaths.healthLogObservation}
            params={{ configId: configIds.temperature }}>
            {local.timeline.noData.buttonText}
          </LinkWrapper>
        </p>
        {/* <p>
          <small>
            <LinkWrapper
              id='timeline-connect-records'
              to={routePaths.healthConnect}>
              {local.timeline.noData.linkText1}
            </LinkWrapper>
          </small>
        </p> */}
        <p>
          <small>
            <LinkWrapper
              id='timeline-connect-devices'
              to={routePaths.healthDevices}>
              {local.timeline.noData.linkText2}
            </LinkWrapper>
          </small>
        </p>
      </article>
    </div >
  );
}
