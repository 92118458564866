import React from 'react';
import { useSelector } from 'react-redux';
import local from 'services/localization/local';
import { CardList } from 'shared';
import { getSortedProfiles } from './selectors';
import ProfileListItem from './ProfileListItem';

export default function ProfileList() {
  const profiles = useSelector(getSortedProfiles);

  return (
    <div className='list-container' data-testid='profile-list'>
      <h4>{local.listProfileHeader}</h4>
      <CardList>
        {profiles.map(profile => {
          return (
            <ProfileListItem
              key={profile.id}
              profile={profile} />
          );
        })}
      </CardList>
    </div>
  );
}
