import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import local from 'services/localization/local';
import { Button, ProfileRedirect } from 'shared';
import { buildPath, routePaths } from 'services/routing';
import * as selectors from 'programs/programs-selectors';
import * as requestSelectors from 'app/request/request-selectors';
import { getUniqueId, setLocalStorage } from 'services/helpers';
import { getProfileId } from 'app/selectors';
import { completeAndReEnrollInProgram, completeProgram } from 'programs/programs-actions';
import { ObservationTypesForConnection } from 'services/constants';
import { InvitedProgramId } from 'services/enums';
import { useHistory } from 'react-router-dom';

export default function ProgramReEnroller({ hide, enrollProgramId, completedEnrollmentId, closeModalRequest, upgrade }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const profileId = useSelector(state => getProfileId(state));
  const completeRequestId = useRef(getUniqueId());
  const quitRequestId = useRef(getUniqueId());

  const activeEnrollment = useSelector(state => selectors.getActiveEnrollmentForProgram(state, enrollProgramId));
  const currentEnrollment = useSelector(state => selectors.getProgramEnrollmentById(state, completedEnrollmentId));
  const isEnrolled = !!activeEnrollment;

  const permissionStatus = useSelector(state => selectors.getProgramPermissions(state, enrollProgramId));

  const handleComplete = useCallback(() => {
    dispatch(completeProgram(completedEnrollmentId, profileId, quitRequestId.current));
    closeModalRequest();
  }, [profileId, completedEnrollmentId, quitRequestId.current]);

  const handleReEnroll = useCallback(() => {
    // FUTURE: do not just pass org ID, allow the user to opt-in via a modal version of this request or something.
    const optInOrganizationIds = currentEnrollment.organizationId ? [currentEnrollment.organizationId] : [];
    dispatch(completeAndReEnrollInProgram(profileId, completedEnrollmentId, optInOrganizationIds, completeRequestId.current, upgrade));
    setLocalStorage(InvitedProgramId, activeEnrollment?.program?.programId);
    closeModalRequest();
    const toPath =  permissionStatus?.data && permissionStatus?.data.length > 0 && permissionStatus?.data[0]?.details?.some(r=> ObservationTypesForConnection.includes(r.observationType)) ? routePaths.observationConnect : null;
    toPath && history.push(toPath);
  }, [profileId, completeRequestId.current]);

  const completeRequest = useSelector(state => requestSelectors.getRequest(state, completeRequestId.current));
  const quitRequest = useSelector(state => requestSelectors.getRequest(state, quitRequestId.current));

  const isInProgress = completeRequest.processing;
  const isQuitInProgress = quitRequest.processing;
  const actionText = upgrade ? local.accept : local.programs.reEnrollLabel;
  const actionInProgressText = upgrade ? local.programs.reEnrollingUpgradeLabel : local.programs.reEnrollingLabel;
  const label = isInProgress ? actionInProgressText : actionText;
  const quitActionText = upgrade ? local.programs.detailsUnenrollButton : local.programs.reEnrollSkipLabel;
  const quitActionInprogressText = local.programs.reEnrollingSkipLabel;
  const quitLabel = isQuitInProgress ? quitActionInprogressText : quitActionText;

  if (completeRequest.success && isEnrolled) {
    const routeParams = {
      programId: activeEnrollment.program.programId,
      programEnrollmentId: activeEnrollment.id
    };
    return <ProfileRedirect to={buildPath(routePaths.programsDetails, routeParams)} />;
  }

  if (quitRequest.success) {
    return null;
  }

  if (hide) {
    return null;
  }

  return (
    <div className="action re-enroll">
      <Button
        id={`re-enroll-skip-${completedEnrollmentId}`}
        className="skip-re-enroll"
        onClick={handleComplete}
        color="transparent"
        disabled={isInProgress || isQuitInProgress}>
        {quitLabel}
      </Button>
      <Button
        id={`re-enroll-${completedEnrollmentId}`}
        className="accept-pathway"
        onClick={handleReEnroll}
        disabled={isInProgress || isQuitInProgress}>
        {label}
      </Button>
    </div>
  );
}

ProgramReEnroller.propTypes = {
  enrollProgramId: PropTypes.string.isRequired,
  completedEnrollmentId: PropTypes.string.isRequired,
  hide: PropTypes.bool
};
