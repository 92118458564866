import * as profilesApi from 'services/api/profiles-api';
import { types } from './auth-connection-types';
import { verifiedEmail } from '../identity/actions';


export function getAuthConnection(email, onCallback) {
  var domain = email && email.substring(email.lastIndexOf('@') + 1);

  return (dispatch, getState) => {

    dispatch(resetConnection());
    dispatch(loading());
    const onSuccess = response => {
      onCallback && onCallback();
      dispatch(loadAuthConnectionSuccess(response));
      dispatch(verifiedEmail());
    };

    const onError = error => {
      onCallback && onCallback();
      if (error && error.status === 404) {
        dispatch(connectionNotFound());
      }
    };

    return profilesApi.getAuthConnections(getState(), domain)
      .then(onSuccess).catch(onError);
  };
}

export function loadAuthConnectionSuccess(connection) {
  return {
    type: types.LOAD_AUTH_CONNECTIONS_SUCCESS,
    payload: connection
  };
}

export function connectionNotFound() {
  return {
    type: types.NO_CONNECTION_FOUND
  };
}

export function resetConnection() {
  return {
    type: types.RESET_CONNECTIONS
  };
}

export function loading() {
  return {
    type: types.LOADING_AUTH_CONNECTION
  };
}
