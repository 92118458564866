import React from 'react';
import { getConfigForObservationType } from 'health/observations/observation-configs';
import { ProfileLink } from 'shared';
import { routePaths } from 'services/routing';
import TimelineIconDate from './TimelineIconDate';

export default function TimelineObservation({ item }) {
  const config = getConfigForObservationType(item.data.observationType);

  return (
    <ProfileLink
      to={routePaths.healthObservation}
      params={{ configId: config.id }}
      id={`timeline-item-link-${item.data.id}`}
      logName={`timeline-observation-${config.id}`}>
      <TimelineIconDate item={item} Symbol={config.iconSymbol} />
      {item.data.name && <strong><p className="timeline-item-header">{item.data.name}</p></strong>}
      {item.data.notes && <p className="timeline-item-notes">{item.data.notes}</p>}
      <span className="timeline-item-subheader">{config.centerElement}</span>
      <br />
      <span className="timeline-item-value">{config.valueFormatter({ observation: item.data, config })}</span>
    </ProfileLink>
  );
}
