import React from 'react';
import local from 'services/localization/local';
import { ObservationType, Unit } from 'services/enums';
import { routePaths } from 'services/routing';
import { configIds } from 'health/observations/observation-config-ids';
import { baseObservationConfig } from 'health/observations/observation-config-helpers.jsx';
import LogTemperature from 'health/observations/temperature/log/LogTemperature';
import { Temperature } from 'shared/fullColorIcons';
import { roundToDecimals } from 'services/value-formatter';
import { loadObservationEhrData } from 'health/action-creators';

const typeDefinition = {
  type: ObservationType.BodyTemperature,
  typeUnitMap: {
    [ObservationType.BodyTemperature]: Unit.Fahrenheit
  },
  unitLabelMap: {
    [Unit.Fahrenheit]: local.units.degreesFahrenheit
  }
};

const chartSettings = {
  scales: {
    yAxes: [{
      ticks: {
        precision: 1
      }
    }]
  }
};

export default {
  ...baseObservationConfig,
  ...typeDefinition,
  id: configIds.temperature,
  backPath: { to: routePaths.health },
  valueFormatter: (props) => `${roundToDecimals(props.observation.value, 2)}${props.config.unitLabelMap[props.observation.unit]}`,
  centerElement: local.observation.temperature.title,
  logcenterElement: local.observation.temperature.logTitle,
  noDataText: local.observation.temperature.noDataText,
  iconSymbol: Temperature,
  loadData: (props) => [props.config.type].flat().forEach(x => props.dispatch(loadObservationEhrData({ observationType: x, profileId: props.profileId }))),
  chartSettings,
  renderLogComponent: (props) => props && <LogTemperature {...props} />
};
