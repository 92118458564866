import React from 'react';
import local from 'services/localization/local';
import LoadingPage from 'shared/loading/LoadingPage';
import { isMobile, openMobileRoute } from 'mobile/helpers';
import { signOut } from 'services/auth0-service';
const env = window.env || {};

class SignOut extends React.Component {
  componentDidMount() {
    if (isMobile) {
      openMobileRoute('signout');
    } else {
      var isIdMeAuth = localStorage.getItem('isIdMeAuth');
      if(isIdMeAuth){
        localStorage.removeItem('id_token_expiry');
        localStorage.removeItem('id_token');
        localStorage.removeItem('isIdMeAuth');
        window.location.href = env.Idme_logouturl;
      }else{
        signOut();
      }

    }
  }

  render() {
    return <LoadingPage id="signout-loading" data-testid="signout" centerElement={local.signingOut} />;
  }
}

export default SignOut;
