/* eslint-disable react/jsx-max-depth */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ModalWrapper } from 'shared';
import { TextField } from 'shared/form-fields/formik';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import local from 'services/localization/local';
import { Manual } from 'services/constants';
import { useDispatch, useSelector } from 'react-redux';
import { saveDeviceSurveyNotes } from 'profile-observation-connections/action-creators';
import InfoModal from 'profile-observation-connections/InfoModal';
import withNavigation from 'app/withNavigation';
import { routePaths } from 'services/routing';
import { getObservationConnections } from 'profile-observation-connections/selectors';
import { ObservationConnectionStatus } from 'services/enums';

const validation = Yup.object({
  deviceInformation: Yup.string()
    .required(local.validationRequired)
    .max(250, local.formatString(local.validationMaxCharLength, 250))
});

function DeviceSurvey({programId, observationType, navigate}) {
  const [isDeviceSurveyModalOpen, setIsDeviceSurveyModalOpen] = useState(false);
  const [isManualTrackingModalOpen, setIsManualTrackingModalOpen] = useState(false);
  const profileId = useSelector(state => state.profile.self.id);
  const observationConnectedDevices = useSelector(state => getObservationConnections(state));
  const profileObservation = observationConnectedDevices?.data?.profileObservationConnections?.find(x=>x.observationType === observationType);
  const programObservations = observationConnectedDevices?.data?.programObservationTypes?.find(x=>x.observationType === observationType);
  const supportedDeviceSourceNames = programObservations?.supportedDeviceSourceNames;
  const dispatch = useDispatch();
  const content = local.observationConnect.deviceSurvey;

  if(isManualTrackingModalOpen){
    return(
      <InfoModal isOpen programId={programId} action={Manual} observationType={observationType} onRequestClose={closeManualTrackingModal} />
    );
  }

  function openDeviceSurveyModal(){
    setIsDeviceSurveyModalOpen(true);
  }

  function closeDeviceSurveyModal(){
    setIsDeviceSurveyModalOpen(false);

    if(profileObservation?.status != ObservationConnectionStatus.Manual && supportedDeviceSourceNames?.some(y => y.toLowerCase() === Manual))
      setIsManualTrackingModalOpen(true);
    else
      navigate({to: routePaths.observationConnect});
  }

  function closeManualTrackingModal(){
    setIsManualTrackingModalOpen(false);
    navigate({to: routePaths.observationConnect});
  }

  const handleSubmit = ({deviceInformation}) => {
    closeDeviceSurveyModal();
    dispatch(saveDeviceSurveyNotes(profileId, observationType, deviceInformation));
  };

  return (
    <div>
      <div className='text-center'>
        <Button id='device-survey' className="btn-link mb-4" onClick={openDeviceSurveyModal}>{content.link}</Button>
      </div>

      <ModalWrapper isOpen={isDeviceSurveyModalOpen}>
        <div id="info-modal" data-testid="info-modal" className="modal-content">
          <div className="modal-header">
            <div></div>
            <div className='modal-title'>
              {content.title}
            </div>
          </div>
          <Formik
            validationSchema={validation}
            initialValues={{
              deviceInformation: ''
            }}
            onSubmit={handleSubmit}>
            <Form>
              <div className="modal-body">
                <div className="verify-successMsg">
                  <div className="error-msg-title" >
                    {content.message}
                  </div>
                  <div className="form-row">
                    <TextField
                      label={content.information}
                      multiline
                      name='deviceInformation' />
                  </div>
                  <div className="form-row">
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <Button id="cancel-delete" className="btn btn-secondary small-btn" onClick={closeDeviceSurveyModal}>{local.formCancel}</Button>
                <Button
                  id='save-journal-entry-button'
                  className="btn btn-primary small-btn"
                  submit>
                  {local.formSubmit}
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      </ModalWrapper>
    </div>
  );
}

DeviceSurvey.propTypes = {
  programId: PropTypes.string.isRequired,
  observationType: PropTypes.number.isRequired,
  navigate: PropTypes.func.isRequired
};

export default withNavigation(DeviceSurvey);
