/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import HipaaConsentModal from 'app/privacy-policy-eula/HipaaConsentModal';
import { getIsApplicationLoading, getIsApplicationLoadError, getIsForceRegistration,HasProfileDemographicName } from 'app/selectors';
import Interviewer from 'interviews/Interviewer';
import { getProfile } from 'profiles/list/selectors';
import IdentityRefresher from 'identity/identity-refresher/IdentityRefresher';
import { ProfileWatcher } from 'profiles/selected/ProfileWatcher';
import { loadAppData } from './action-creators';
import Layout from './Layout';
import { getIdentityId, getIsEmailVerified } from 'identity/selectors';
import { getLogs } from 'analytics/getLogs-selectors';
import BadgeEarned from 'badge/BadgeEarned';
import { routePaths } from 'services/routing';
import { mobileAppRoot } from 'app/config';
import { isMobile } from 'mobile/helpers';

export class LayoutContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.refreshPage = this.refreshPage.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadAppData();
    // eslint-disable-next-line react/no-direct-mutation-state
  }

  componentDidCatch(){
    this.setState({ hasError: true });
  }
  refreshPage() {
    if(isMobile)
    {
      window.location.href = `${mobileAppRoot}homepage`;
    }
    else
    {
      window.location.assign(routePaths.root);
    }
  }
  render() {
    const { location } = this.props;
    const hipaaTermsAgreed = this.props.profile && this.props.profile.hipaaConsentResult.hasHipaaConsent;
    if(!this.state.hasError){
      return (
        <React.Fragment>
          <IdentityRefresher />
          <ProfileWatcher location={location} />
          <Layout {...this.props} />
          <HipaaConsentModal />
          <Interviewer />
          {hipaaTermsAgreed !== undefined && hipaaTermsAgreed && <BadgeEarned />}
        </React.Fragment>
      );
    }
    else{
      return(
        <div className="errorComponent">
          <div>
            Something went wrong
          </div>
          <a className='button btn btn-primary mt-3' onClick={this.refreshPage}>
            Go to Home Page
          </a>

        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  const { profile } = state;

  const props = {
    loading: getIsApplicationLoading(state),
    loadError: getIsApplicationLoadError(state),
    profile: getProfile(state),
    profileMissing: profile.profileMissing,
    identityId: getIdentityId(state),
    logResult: getLogs(state),
    hasProfileUserName: HasProfileDemographicName(state),
    IsForceRegistration: getIsForceRegistration(state),
    isEmailverified: getIsEmailVerified(state)
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  const actions = {
    loadAppData
  };
  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

LayoutContainer.propTypes = {
  unverifiedEmail: PropTypes.bool,
  location: PropTypes.shape(),
  actions: PropTypes.shape({
    loadAppData: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LayoutContainer));
