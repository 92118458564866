import React, { useEffect, useRef, useState } from 'react';
import { Button, Icon, NavAction, NonAuthenticatedPage, Page } from 'shared';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import VerificationInput from 'react-verification-input';
import { useDispatch, useSelector } from 'react-redux';
import { resendVerificationEmail, verifyEmailWithCode } from 'identity/action-creators';
import { getUniqueId } from 'services/helpers';
import { getRequest } from 'app/request/request-selectors';
import { sendAgainVerificationCodeLimitSeconds, helpScoutBeaconId } from 'app/config';
import * as beaconService from 'services/helpscout-beacon-service';
import './verifyEmailByCode.scss';

function VerifyEmailByCode() {
  const email = useSelector(state => state.identity.email);
  const [verificationRequestId, setVerificationRequestId]= useState();
  const {hasError, error} = useSelector(state => getRequest(state, verificationRequestId));
  const [verificationCode, setVerificationCode]= useState('');
  const [isBeaconInitialized, setBeaconInitialized] = useState(false);
  const [isBeaconOpen, setBeaconOpen] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [showError, setShowError] = useState(hasError);
  const textInput = useRef(null);
  const dispatch = useDispatch();
  const content = local.verifyEmail;

  useEffect(() => {
    setVerificationRequestId(getUniqueId());
  }, [email]);

  useEffect(() => {
    setShowError(hasError);
    if(hasError)
      setVerificationCode('');
  }, [hasError]);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
  },[seconds]);

  const onChangeVerificationCode = (code) => {
    setVerificationCode(code);
    setShowError(false);
    if(code.length == 4){
      dispatch(verifyEmailWithCode(code, verificationRequestId));
    }
  };

  function resendCode(){
    dispatch(resendVerificationEmail());
    setVerificationCode('');
    setShowError(false);
    setSeconds(sendAgainVerificationCodeLimitSeconds);
  }

  function onBeaconClose() {
    setBeaconOpen(false);
  }

  function contactUsClick() {
    if (isBeaconOpen)
      return;

    if (!isBeaconInitialized) {
      beaconService.initialize(helpScoutBeaconId);
      beaconService.setDefaultConfiguration();
      beaconService.setIdentity(name, email);
      beaconService.prefillUserInformation(name, email);
      beaconService.setContent('','');
      beaconService.navigate('/ask/');
      beaconService.addCallbackEvent('close', onBeaconClose);
      setBeaconInitialized(true);
    }
    beaconService.open();
    setBeaconOpen(true);
  }

  return (
    <Page
      id='verify-email-by-code-page'
      centerElement={<Icon className="center" symbol="hipaaauth" />}
      primaryAction={<NavAction mobileOnly text={local.linkSignout} iconSymbol="signout" to={routePaths.signout} />}
      hideBottomNav>
      <NonAuthenticatedPage id="verify-email-by-code" titleTabText='Verify Email' topChevronColor='yellow'>
        <div className='verify-email-by-code-card' id="verifyCode">
          <Icon symbol='verificationCodeLock' />
          <h2 className='pb-3'>{content.confirm}</h2>
          <small>{local.formatString(content.sentCode, email)}</small>
          <div className="codeField form-row custom-styles">
            <VerificationInput
              value={verificationCode}
              validChars='0-9'
              onChange={e => {onChangeVerificationCode(e);}}
              length={4}
              autoFocus
              ref={textInput}
              inputProps={{type:'tel'}} />
          </div>
          {showError ? <label className='error'>{error?.body}</label> : null}
          <div className="verifySupportLinks">
            <a className="resentCode">
              <Button
                id="resend-email"
                data-testid="resend-code"
                onClick={resendCode}
                color="transparent"
                disabled={seconds > 0}>
                {local.accountSettings.resendVerificationCode}
              </Button>
            </a>
            <small>{content.trouble}<a className="contactHelp"><Button id="contact-us" onClick={contactUsClick} color="transparent">{local.accountSettings.contactUs}</Button></a></small>
          </div>
        </div>
      </NonAuthenticatedPage>
    </Page>
  );
}

export default VerifyEmailByCode;
