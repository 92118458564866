export const types = {
  VALIDIC_PROFILE: 'validicProfile',
  CONNECTED_DEVICES: 'connectedDevices',
  SYNCED_DATA: 'syncedData',
  DISCONNECTED_DEVICES: 'disconnectedDevices',
  TERRA_PROFILE: 'terraProfile',
  TERRA_DEVICES: 'terraDevices',
  TERRA_USER_INFO: 'terraUserInfo',
  ACTIVE_DEVICES: 'activeDevices'
};
