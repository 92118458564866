/* eslint-disable max-len */
import React from 'react';
import './hc-logo-inline.scss';

class HcLogoInline extends React.Component {
  render() {
    return (
      <svg id="logoInline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 571.78 102.29">
        <path className="teal" d="M74.72,59h-.11a5.34,5.34,0,0,1-4.86-3.38L58.92,27.91A5.33,5.33,0,0,1,68.85,24L75,39.73l7-15.9a5.33,5.33,0,1,1,9.76,4.28L79.6,55.78A5.33,5.33,0,0,1,74.72,59" transform="translate(-24.22 -20.64)" />
        <path className="teal" d="M86.85,122.93a5.33,5.33,0,0,1-5-3.39l-6.15-15.71-7,15.91A5.34,5.34,0,0,1,59,115.45L71.14,87.79A5.33,5.33,0,0,1,81,88l10.83,27.66a5.34,5.34,0,0,1-5,7.28" transform="translate(-24.22 -20.64)" />
        <path className="magenta" d="M62.07,64.74a5.31,5.31,0,0,1-2.13-.44L32.72,52.39A5.33,5.33,0,0,1,37,42.62l15.45,6.76L46.13,33.2a5.33,5.33,0,1,1,9.93-3.87L67,57.48a5.32,5.32,0,0,1-5,7.26" transform="translate(-24.22 -20.64)" />
        <path className="magenta" d="M99.64,117.63a5.32,5.32,0,0,1-5-3.4l-11-28.14a5.33,5.33,0,0,1,7.1-6.82L118,91.17a5.33,5.33,0,0,1-4.27,9.77L98.3,94.18l6.31,16.18a5.33,5.33,0,0,1-3,6.9,5.26,5.26,0,0,1-1.94.37" transform="translate(-24.22 -20.64)" />
        <path className="blue" d="M29.56,88.6a5.33,5.33,0,0,1-1.95-10.3l15.71-6.15-15.91-7a5.33,5.33,0,1,1,4.29-9.76L59.36,67.55a5.34,5.34,0,0,1-.19,9.85L31.5,88.23a5.34,5.34,0,0,1-1.94.37" transform="translate(-24.22 -20.64)" />
        <path className="blue" d="M121.18,88.6a5.41,5.41,0,0,1-2.14-.45L91.37,76a5.33,5.33,0,0,1,.2-9.84l27.67-10.84a5.33,5.33,0,0,1,3.89,9.93l-15.71,6.15,15.9,7a5.33,5.33,0,0,1-2.14,10.21" transform="translate(-24.22 -20.64)" />
        <path className="yellow" d="M51.09,117.63a5.34,5.34,0,0,1-4.88-7.47L53,94.71,36.79,101a5.33,5.33,0,0,1-3.87-9.93l28.14-11a5.33,5.33,0,0,1,6.82,7.1L56,114.43a5.33,5.33,0,0,1-4.89,3.2" transform="translate(-24.22 -20.64)" />
        <path className="yellow" d="M87.74,63.82a5.33,5.33,0,0,1-4.88-7.47l11.9-27.22a5.33,5.33,0,0,1,9.77,4.27L97.77,48.85,114,42.54a5.33,5.33,0,0,1,3.87,9.93l-28.14,11a5.26,5.26,0,0,1-1.94.37" transform="translate(-24.22 -20.64)" />
        <path className="magenta-dark" d="M67.34,60.09c0-.2.06-.4.07-.6a5.07,5.07,0,0,0,0-.56,3.88,3.88,0,0,0,0-.48,5.33,5.33,0,0,0-3.12-3.93L52.33,49.38l4.8,12a5.29,5.29,0,0,0,3,3,4.91,4.91,0,0,0,.74.24l.27.06a6.1,6.1,0,0,0,.94.09h0a5.12,5.12,0,0,0,.87-.08l.27-.06a5.46,5.46,0,0,0,.66-.19l.25-.1a5.16,5.16,0,0,0,.84-.44l.1-.07a5.38,5.38,0,0,0,.64-.52l.2-.2a4.51,4.51,0,0,0,.44-.54c0-.07.11-.13.15-.2a5,5,0,0,0,.45-.82,5.28,5.28,0,0,0,.32-1c0-.14,0-.29,0-.43" transform="translate(-24.22 -20.64)" />
        <path className="magenta-dark" d="M83.4,83.45c0,.2-.06.4-.07.6s0,.37,0,.56,0,.32,0,.48a5.23,5.23,0,0,0,.28,1A5.32,5.32,0,0,0,86.52,89l11.84,5.2-4.74-12a5.3,5.3,0,0,0-3-3,6.39,6.39,0,0,0-.74-.24l-.27-.06a4.92,4.92,0,0,0-.94-.1h0a5.24,5.24,0,0,0-.88.08l-.27.06a4.84,4.84,0,0,0-.65.19l-.25.09a4.87,4.87,0,0,0-.85.44l-.11.07a6.21,6.21,0,0,0-.63.52l-.2.19a5.85,5.85,0,0,0-.45.54l-.15.2a5,5,0,0,0-.45.82,5.32,5.32,0,0,0-.33,1c0,.14,0,.29,0,.43" transform="translate(-24.22 -20.64)" />
        <path className="blue-dark" d="M61.4,69.18a4.33,4.33,0,0,0-.38-.47,3.73,3.73,0,0,0-.43-.36,2.76,2.76,0,0,0-.37-.31,4.74,4.74,0,0,0-.91-.51,5.31,5.31,0,0,0-4.07-.06l-12,4.75,11.89,5.1a5.33,5.33,0,0,0,4.2,0A7.49,7.49,0,0,0,60,77l.24-.15a6,6,0,0,0,.73-.6h0a5.19,5.19,0,0,0,.56-.68l.15-.23a5.76,5.76,0,0,0,.33-.6,2.56,2.56,0,0,1,.11-.24,5.28,5.28,0,0,0,.28-.91s0-.09,0-.13a5.58,5.58,0,0,0,.09-.81V72.3a5.35,5.35,0,0,0-.07-.7,1.87,1.87,0,0,0,0-.25,4.85,4.85,0,0,0-.26-.9,5.11,5.11,0,0,0-.48-.93c-.08-.12-.18-.23-.27-.34" transform="translate(-24.22 -20.64)" />
        <path className="blue-dark" d="M89.27,74.34c.13.16.24.33.38.48a5.12,5.12,0,0,0,.43.36c.12.1.23.22.36.31a5.15,5.15,0,0,0,.92.51,5.3,5.3,0,0,0,4.07.09l12-4.7L95.61,66.23a5.29,5.29,0,0,0-4.2,0,5.58,5.58,0,0,0-.69.35l-.24.15a5.23,5.23,0,0,0-.73.59h0v0a5.3,5.3,0,0,0-.57.67l-.15.23a5.76,5.76,0,0,0-.33.6l-.11.24a6.39,6.39,0,0,0-.29.91s0,.09,0,.13a5.58,5.58,0,0,0-.09.81v.28c0,.23,0,.47.06.7,0,.08,0,.16,0,.25a4.76,4.76,0,0,0,.26.89A4.89,4.89,0,0,0,89,74c.07.12.18.23.26.34" transform="translate(-24.22 -20.64)" />
        <path className="teal-dark" d="M72.74,85.74a4.33,4.33,0,0,0-.47.38,5.12,5.12,0,0,0-.36.43,2.76,2.76,0,0,0-.31.37,4.74,4.74,0,0,0-.51.91A5.31,5.31,0,0,0,71,91.9l4.76,12L80.88,92a5.28,5.28,0,0,0,0-4.2,7.49,7.49,0,0,0-.36-.69l-.15-.24a6.07,6.07,0,0,0-.59-.73h0a0,0,0,0,0,0,0,5.19,5.19,0,0,0-.68-.56l-.23-.15a5.76,5.76,0,0,0-.6-.33L78,85a5.28,5.28,0,0,0-.91-.28l-.13,0a5.58,5.58,0,0,0-.81-.09h-.28a5.35,5.35,0,0,0-.7.07l-.25,0A4.76,4.76,0,0,0,74,85a4.89,4.89,0,0,0-.94.48c-.12.08-.22.18-.34.27" transform="translate(-24.22 -20.64)" />
        <path className="teal-dark" d="M77.9,57.87c.16-.13.33-.24.48-.38a5.12,5.12,0,0,0,.36-.43,3.47,3.47,0,0,0,.31-.36,5.9,5.9,0,0,0,.52-.92,5.35,5.35,0,0,0,.08-4.07L75,39.66,69.79,51.53a5.33,5.33,0,0,0,0,4.2,4.52,4.52,0,0,0,.36.69c0,.08.09.16.14.24a6.07,6.07,0,0,0,.59.73h0a5.3,5.3,0,0,0,.67.57l.23.15a5.76,5.76,0,0,0,.6.33l.24.11a6.39,6.39,0,0,0,.91.29l.13,0a5.58,5.58,0,0,0,.81.09h.28c.24,0,.47,0,.7-.06l.25,0a4.64,4.64,0,0,0,.9-.26,5.11,5.11,0,0,0,.93-.48c.12-.07.23-.18.34-.26" transform="translate(-24.22 -20.64)" />
        <path className="yellow-dark" d="M63.64,79.82c-.2,0-.4-.06-.6-.06s-.37,0-.56,0-.32,0-.48,0a4.55,4.55,0,0,0-1,.29A5.31,5.31,0,0,0,58.07,83L52.93,94.84l12-4.8a5.3,5.3,0,0,0,3-3,4.91,4.91,0,0,0,.24-.74L68.2,86a5,5,0,0,0,.09-.94h0v0a5.24,5.24,0,0,0-.08-.88l-.06-.26a5.46,5.46,0,0,0-.19-.66L67.87,83a5.67,5.67,0,0,0-.44-.85.39.39,0,0,1-.08-.1,4.59,4.59,0,0,0-.52-.63l-.19-.2c-.18-.16-.36-.31-.55-.45l-.2-.15a5.9,5.9,0,0,0-.82-.45,5.82,5.82,0,0,0-1-.32l-.43-.05" transform="translate(-24.22 -20.64)" />
        <path className="yellow-dark" d="M87,63.76a4.09,4.09,0,0,0,.6.07,5.09,5.09,0,0,0,.56,0,3.73,3.73,0,0,0,.48,0,4.64,4.64,0,0,0,1-.29,5.29,5.29,0,0,0,2.94-2.81L97.78,48.8l-12,4.74a5.4,5.4,0,0,0-3.21,3.71,2.8,2.8,0,0,0-.06.28,5.9,5.9,0,0,0-.11.93v0a5.12,5.12,0,0,0,.08.87l.06.27a5.46,5.46,0,0,0,.19.66l.09.25a6.2,6.2,0,0,0,.44.85l.08.1a4.48,4.48,0,0,0,.51.64l.19.19a5.73,5.73,0,0,0,.54.46c.07,0,.13.1.2.15a6.53,6.53,0,0,0,.82.45,5.28,5.28,0,0,0,1,.32c.14,0,.29,0,.43,0" transform="translate(-24.22 -20.64)" />
        <path className="text" d="M224.62,48.83A26.82,26.82,0,0,0,188.33,59a27.32,27.32,0,0,0-3.52,13.77,27.64,27.64,0,0,0,3.52,13.82,26.36,26.36,0,0,0,23.37,13.5,25.5,25.5,0,0,0,12.86-3.31,26.71,26.71,0,0,0,9.45-9l9.5,5.66A37.92,37.92,0,0,1,230,106.1a36.88,36.88,0,0,1-18.25,4.69,38.11,38.11,0,0,1-33-57.1,37.8,37.8,0,0,1,33-18.89A36.89,36.89,0,0,1,230,39.5a37.5,37.5,0,0,1,13.56,12.7L234,57.75A25.52,25.52,0,0,0,224.62,48.83Z" transform="translate(-24.22 -20.64)" />
        <path className="text" d="M286.57,57.27A19.1,19.1,0,0,1,294.42,65a23.44,23.44,0,0,1,2.77,11.53v32.55H286.52v-7.36a19.08,19.08,0,0,1-7,6.08,21,21,0,0,1-9.93,2.35,20.12,20.12,0,0,1-8.81-1.92,15.25,15.25,0,0,1-6.35-5.6,15.7,15.7,0,0,1-2.34-8.6A14.07,14.07,0,0,1,257,82.72a27.28,27.28,0,0,1,12.71-5.66l16.65-3.2A12.94,12.94,0,0,0,282.94,67a9.48,9.48,0,0,0-7.2-2.72,14.78,14.78,0,0,0-7.58,2.13,15.13,15.13,0,0,0-5.55,5.45L254,65.75a25.31,25.31,0,0,1,9.18-8.11,24.58,24.58,0,0,1,11.74-3.1A24.24,24.24,0,0,1,286.57,57.27Zm-4.32,39.38A12.13,12.13,0,0,0,286.52,87V83.15l-14.84,3a18.28,18.28,0,0,0-6.45,2.77,5.55,5.55,0,0,0-2.51,4.7,5.68,5.68,0,0,0,2.67,4.85,11.39,11.39,0,0,0,6.72,1.87A15.05,15.05,0,0,0,282.25,96.65Z" transform="translate(-24.22 -20.64)" />
        <path className="text" d="M324.09,34.37v74.71H313.41V34.37Z" transform="translate(-24.22 -20.64)" />
        <path className="text" d="M387.06,97.45a29,29,0,0,1-10.14,9.29,27.27,27.27,0,0,1-13.34,3.41,25.85,25.85,0,0,1-13.72-3.73,26.44,26.44,0,0,1-9.6-10.09,30.12,30.12,0,0,1,0-28.07,26.44,26.44,0,0,1,9.6-10.09,25.85,25.85,0,0,1,13.72-3.73,27.27,27.27,0,0,1,13.34,3.41,29,29,0,0,1,10.14,9.29l-9,5.23a17.63,17.63,0,0,0-6.3-5.71,16.74,16.74,0,0,0-8.11-2.08,15.27,15.27,0,0,0-8.32,2.35,16.07,16.07,0,0,0-5.82,6.4,20.36,20.36,0,0,0,0,17.93,16,16,0,0,0,5.82,6.4,15.27,15.27,0,0,0,8.32,2.35,16.74,16.74,0,0,0,8.11-2.08,17.63,17.63,0,0,0,6.3-5.71Z" transform="translate(-24.22 -20.64)" />
        <path className="text" d="M400.13,44.62a6.84,6.84,0,0,1,0-9.82,6.87,6.87,0,0,1,5-2,6.76,6.76,0,0,1,4.86,2,6.84,6.84,0,0,1,0,9.82,6.76,6.76,0,0,1-4.86,2A6.87,6.87,0,0,1,400.13,44.62Zm10.3,11.1v53.36H399.76V55.72Z" transform="translate(-24.22 -20.64)" />
        <path className="text" d="M475.86,55.72v53.36H465.18V101a19.29,19.29,0,0,1-7.09,6.73,20.07,20.07,0,0,1-10,2.56,18.72,18.72,0,0,1-10.46-3,20.24,20.24,0,0,1-7.21-8.38,27.93,27.93,0,0,1-2.61-12.33V55.72H438.5v28.6q0,7.58,3.52,11.8a11.28,11.28,0,0,0,9.07,4.21A12.29,12.29,0,0,0,458.57,98a15.13,15.13,0,0,0,4.9-6.13,19.37,19.37,0,0,0,1.71-8.06V55.72Z" transform="translate(-24.22 -20.64)" />
        <path className="text" d="M561.45,57.59a20.29,20.29,0,0,1,7,8.32A27.21,27.21,0,0,1,571,77.71v31.37H560.28V80.27q0-7.15-3.2-11.48a10.6,10.6,0,0,0-17.08,0q-3.21,4.33-3.2,11.48v28.81H526.12V80.27q0-7.15-3.2-11.48a10.59,10.59,0,0,0-17.07,0q-3.21,4.33-3.21,11.48v28.81H492V55.72h10.67v7.15a16.66,16.66,0,0,1,14.62-8.33,17.54,17.54,0,0,1,9.56,2.73,19.55,19.55,0,0,1,6.88,7.52,24.82,24.82,0,0,1,7.47-7.31,18.61,18.61,0,0,1,10.25-2.94A17.45,17.45,0,0,1,561.45,57.59Z" transform="translate(-24.22 -20.64)" />
        <path className="text" d="M588.77,104.19h-2v5.38h-.44v-5.38h-2v-.43h4.38Z" transform="translate(-24.22 -20.64)" />
        <path className="text" d="M596,109.57h-.43v-5.15l-2.35,5.15h-.49l-2.34-5.17v5.17H590v-5.81h.61l2.42,5.29,2.41-5.29H596Z" transform="translate(-24.22 -20.64)" />
      </svg>
    );
  }
}

HcLogoInline.displayName = 'HcLogoInline';
HcLogoInline.propTypes = {};
HcLogoInline.defaultProps = {};

export default HcLogoInline;
