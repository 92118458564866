import * as dataSelectors from 'app/request/persistent-data-selectors';
import { types } from 'profiles/sharing/sharing-types';

export const getBadgeSummaryDetails = (state) => {
  const identityId = state.identity && state.identity.self.id;
  const { loaded, loading, hasError, data } = dataSelectors.getType(state.badgeSummary, types.PROFILE_BADGES, identityId);

  return { loaded, loading, hasError, data };
};

export const getBadgeSummaryDetailsForNotification = (state) => {
  const identityId = state.identity && state.identity.self.id;
  const { loaded, loading, hasError, data } = dataSelectors.getType(state.badgeSummary, types.BADGES_FOR_NOTIFICATION, identityId);
  const toNotifyBadge = data?.find(b => b.achieved && !b.isNotified);
  return { loaded, loading, hasError, data, toNotifyBadge };
};

export const getBadgeIcons = (state) => {
  const identityId = state.identity && state.identity.self.id;
  const { loaded, loading, hasError, data } = dataSelectors.getType(state.badgeSummary, types.BADGES_ICONS, identityId);
  const badgeIconsPath = data;
  return { loaded, loading, hasError, badgeIconsPath };
};
