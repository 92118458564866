import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProfileId } from 'app/selectors';
import { getObservationData, getObservationsLoading, getObservationsLoadError } from 'health/observations/observation-selectors';
import { loadObservationData } from 'health/action-creators';

export default function useObservationData(observationType, searchLimit) {
  const dispatch = useDispatch();
  const profileId = useSelector(state => getProfileId(state));
  const observations = useSelector(state => [observationType].flat().map(type => getObservationData(state, type).flat()));
  const loading = useSelector(state => getObservationsLoading(state, observationType));
  const error = useSelector(state => getObservationsLoadError(state, observationType));

  const hasObservations = observations && observations.length > 1;
  const showLoading = loading && !hasObservations;

  useEffect(() => {
    [observationType].flat().forEach(type => dispatch(loadObservationData({ profileId, observationType: type, searchLimit })));
  }, [profileId]);

  return [showLoading, error];
}
