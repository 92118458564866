import React, { useEffect } from 'react';
import { Icon, LinkWrapper, Page, Card, NavIcon } from 'shared';
import { buildPath, routePaths } from 'services/routing';
import local from 'services/localization/local';
import { getProfileId } from 'app/selectors';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import './timeline-summary.scss';
import { loadHealthSummary } from './action-creators';
import { getHealthSummaryDetails } from './timeline-selectors';
import configs from 'health/observations/observation-configs';
import { configIds } from 'health/observations/observation-config-ids';
import * as constants from 'services/constants';
import { Activities, Sleep, Nutrition} from 'shared/fullColorIcons';
import ChartOverLay from './ChartOverLay';

export default function HealthSummary() {
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);

  const today = moment().format('MMM D');
  const startDate = moment().startOf('day').add(constants.StartDateOfHealthSummary, 'days').format('MMM D');

  const weightIconConfig = configs[configIds.weight];
  const heartRateIconConfig = configs[configIds.heartRate];
  const stepsIconConfig = configs[configIds.steps];
  const { data, loading, hasError } = useSelector(state=> getHealthSummaryDetails(state));
  const stepAvgCountValue = (data && data.step) ? data.step.averageDailySteps : 0;
  const stepTrend = (data && data.step) ? data.step.lastWeekTrend : 0;
  const weightSummary = (data && data.weight) ? data.weight : null;
  const restingHeartRate = (data && data.heartRate) ? data.heartRate.averageRestingHeartRate : 0;
  const trendHeartRate = (data && data.heartRate) ? data.heartRate.lastWeekTrend : 0;
  const sleepAvgCount = (data && data.sleep) ? data.sleep.averageDailySleep : 0;
  const sleepTrend = (data && data.sleep) ? data.sleep.lastWeekTrend : 0;
  const activeDays = (data) ? data.exercise?.activeDays : 0;
  const lastWeekTrends = (data)? data.exercise?.lastWeekTrend:0;
  const nutritionAvgcalories = (data && data.nutrition) ? data.nutrition.averageDailyNutrition : 0;
  const nutritionTrend = (data && data.nutrition) ? data.nutrition.lastWeekTrend : 0;

  const backPath = buildPath(routePaths.healthSummary, { profileId });
  const stepsNavigationProps = stepAvgCountValue > 0 ? {to: buildPath(routePaths.healthObservation, {configId: configIds.steps}), params: {profileId}} : {to: routePaths.healthDevices};
  const sleepNavigationProps = sleepAvgCount > 0 ? {to: buildPath(routePaths.healthObservation, {configId: configIds.sleep}), params: {profileId}} : {to: routePaths.healthDevices};
  const exerciseNavigationProps = activeDays > 0 ? {to: routePaths.healthActivity, params: {profileId}} : {to: routePaths.healthActivityAdd};
  const heartRateNavigationProps = restingHeartRate > 0 ? {to: buildPath(routePaths.healthObservation, {configId: configIds.heartRate}), params: {profileId}} : {to: routePaths.healthDevices};
  const weightNavigationProps = weightSummary && weightSummary.currentWeight ? {to: buildPath(routePaths.healthObservation, {configId: configIds.weight}), params: {profileId}} : {to: buildPath(routePaths.healthLogObservation, {configId: configIds.weight})};
  const nutritionNavigationProps = nutritionAvgcalories > 0 ? {to: buildPath(routePaths.healthObservation, {configId: configIds.nutritions}), params: {profileId}} : {to: routePaths.healthDevices};
  const currentUser = useSelector(state => state.identity.email);
  const showChartOverlay = (currentUser === 'ken.mathew+ehr1@calciumhealth.com') ? true : false;
  useEffect(() => {
    dispatch(loadHealthSummary(profileId));
  }, [profileId]);

  const timeLineLink = () => {
    return (
      !showChartOverlay && <LinkWrapper
        id="timeline-page-link"
        to={routePaths.healthTimeline} params={{profileId}}
        backpath={backPath}>
        <Icon symbol="timeline" />
      </LinkWrapper>
    );
  };

  return (
    <Page
      id="timeline-summary-page"
      loading={loading}
      error={hasError}
      backPath={{ to: routePaths.root }}
      centerElement={local.timeline.summary.title}
      primaryAction={timeLineLink()}>

      { showChartOverlay && <ChartOverLay></ChartOverLay> }

      { !showChartOverlay &&  <div className="summary-title">
        <div className="summary-weekRange">{startDate}&nbsp; - &nbsp;{today}</div>
        <span>{local.timeline.summary.yourStats}</span>
      </div> }

      { !showChartOverlay && <div className="timeline-summary-cards">
        <LinkWrapper id='steps-link' backpath={backPath} {...stepsNavigationProps}>
          <Card>
            <div className="summary-metricIcon">
              <NavIcon Icon={stepsIconConfig.iconSymbol}></NavIcon>
            </div>
            {(stepAvgCountValue && stepAvgCountValue > 0) ? (
              <React.Fragment>
                <div className="summary-metricContent">
                  <span className="summary-metric-value">{ FormatStepsValue(stepAvgCountValue.toFixed()) }</span>
                  <span className="summary-metric-name">{ local.timeline.summary.stepDays }</span>
                </div>
                {stepTrend !== stepAvgCountValue && stepTrend > 0 &&
                  <div className="summary-metric-footer"><span> <Icon symbol="arrowCircleUp" />+{ FormatStepsValue(stepTrend.toFixed()) } {local.timeline.summary.trendWeek } </span></div>}

                {stepTrend !== stepAvgCountValue && stepTrend < 0 &&
                  <div className="summary-metric-footer"><span><Icon symbol="arrowCircleDown" />{ FormatStepsValue(stepTrend.toFixed()) } {local.timeline.summary.trendWeek } </span> </div>}

                {stepTrend !== stepAvgCountValue && stepTrend === 0 &&
                  <div className="summary-metric-footer"><span><Icon symbol="removeCircleOutline" />{local.healthData.summary.trendsNoChange}</span></div> }
              </React.Fragment>
            ) :
              (
                <React.Fragment>
                  <div className="summary-metricContent">
                    <span className="summary-metric-value">--</span>
                    <span className="summary-metric-name">{ local.timeline.summary.stepDays }</span>
                  </div>
                  <div className="summary-metric-footer error-msg">
                    <span><Icon symbol="plugConnect" className="error" />{ local.timeline.summary.deviceNotConnected }</span>
                  </div>
                </React.Fragment>
              )}
          </Card>
        </LinkWrapper>

        <LinkWrapper id='steps-link' backpath={backPath} {...exerciseNavigationProps}>
          <Card>
            <div className="summary-metricIcon">
              <NavIcon Icon={Activities}></NavIcon>
            </div>
            {(activeDays > 0) ?
              (
                <React.Fragment>
                  <div className="summary-metricContent">
                    <span className="summary-metric-value">{activeDays}</span>
                    <span className="summary-metric-name">{local.healthData.summary.totalDays} </span>
                  </div>
                  {(activeDays !== lastWeekTrends && lastWeekTrends < 0) &&
                  <div className="summary-metric-footer"><span><Icon symbol="arrowCircleDown" />{lastWeekTrends} {local.healthData.summary.trendsLastWeek} </span></div>}
                  {(activeDays !== lastWeekTrends && lastWeekTrends > 0) &&
                  <div className="summary-metric-footer"><span><Icon symbol="arrowCircleUp" />{'+' + lastWeekTrends} {local.healthData.summary.trendsLastWeek}</span></div>}
                  {(activeDays !== lastWeekTrends && lastWeekTrends === 0) &&
                  <div className="summary-metric-footer"><span><Icon symbol="removeCircleOutline" />{local.healthData.summary.trendsNoChange}</span></div>}
                </React.Fragment>
              ) :
              (
                <React.Fragment>
                  <div className="summary-metricContent">
                    <span className="summary-metric-value">--</span>
                    <span className="summary-metric-name">{local.healthData.summary.totalDays} </span>
                  </div>
                  <div className="summary-metric-footer error-msg">
                    <span> <Icon symbol="plugConnect" className="error" />{ local.timeline.summary.deviceNotConnected }</span>
                  </div>
                </React.Fragment>
              )}
          </Card>
        </LinkWrapper>

        <LinkWrapper id='steps-link' backpath={backPath} {...heartRateNavigationProps}>
          <Card>
            <div className="summary-metricIcon">
              <NavIcon Icon={heartRateIconConfig.iconSymbol}></NavIcon>
            </div>
            {(restingHeartRate && restingHeartRate > 0) ? (
              <React.Fragment>
                <div className="summary-metricContent">
                  <div>
                    <span className="summary-metric-value">{ restingHeartRate }</span>
                    <span className="summary-metric-unit"> {local.units.beatsPerMinute}</span>
                  </div>
                  <div className="summary-metric-name">
                    <span className="summary-metric-name">{local.timeline.summary.heartRate}</span>
                  </div>
                </div>
                {restingHeartRate !== trendHeartRate && trendHeartRate > 0 &&
                <div className="summary-metric-footer">
                  <span><Icon symbol="arrowCircleUp" />+{trendHeartRate} {local.timeline.summary.trendWeek }</span>
                </div>}

                {restingHeartRate !== trendHeartRate && trendHeartRate < 0 &&
                <div className="summary-metric-footer">
                  <span><Icon symbol="arrowCircleDown" />{trendHeartRate} {local.timeline.summary.trendWeek }</span>
                </div>}

                {restingHeartRate !== trendHeartRate && trendHeartRate === 0 &&
                <div className="summary-metric-footer">
                  <span><Icon symbol="removeCircleOutline" />{local.timeline.summary.noChangeInData}</span>
                </div>}
              </React.Fragment>
            ) :(
              <React.Fragment>
                <div className="summary-metricContent">
                  <span className="summary-metric-value">--</span>
                  <span className="summary-metric-name">{local.timeline.summary.heartRate}</span>
                </div>
                <div className="summary-metric-footer error-msg">
                  <span> <Icon symbol="plugConnect" className="error" />{ local.timeline.summary.deviceNotConnected }</span>
                </div>
              </React.Fragment>
            )}
          </Card>
        </LinkWrapper>

        <LinkWrapper id='steps-link' backpath={backPath} {...weightNavigationProps}>
          <Card>
            <div className="summary-metricIcon">
              <NavIcon Icon={weightIconConfig.iconSymbol}></NavIcon>
            </div>
            {weightSummary &&
            <React.Fragment>
              <div className="summary-metricContent">
                <div>
                  <span className="summary-metric-value">{weightSummary.currentWeight.toFixed(1)}</span>
                  <span className="summary-metric-unit"> {local.units.pounds}</span>
                </div>
                <div className="summary-metric-name">
                  <span>{local.observation.weight.title} </span>
                  <span>|</span>
                  <span> {moment(weightSummary.lastUpdatedAt).format('MMM D')}</span>
                </div>
              </div>

              {weightSummary.lastWeekTrend !== weightSummary.currentWeight && weightSummary.lastWeekTrend > 0 &&
              <div className="summary-metric-footer">
                <span><Icon symbol="arrowCircleUp" />+{weightSummary.lastWeekTrend.toFixed(1)} {local.timeline.summary.fromLastWeight} {moment(weightSummary.lastWeekUpdatedAt).format('MMM D')}</span>
              </div>}

              {weightSummary.lastWeekTrend !== weightSummary.currentWeight && weightSummary.lastWeekTrend < 0 &&
              <div className="summary-metric-footer">
                <span><Icon symbol="arrowCircleDown" />{weightSummary.lastWeekTrend.toFixed(1)} {local.timeline.summary.fromLastWeight} {moment(weightSummary.lastWeekUpdatedAt).format('MMM D')}</span>
              </div>}

              {weightSummary.lastWeekTrend !== weightSummary.currentWeight && weightSummary.lastWeekTrend === 0 &&
              <div className="summary-metric-footer">
                <span> <Icon symbol="removeCircleOutline" />{local.timeline.summary.noChangeInData}</span>
              </div>}

            </React.Fragment>}
            {!weightSummary &&
            <React.Fragment>
              <div className="summary-metricContent">
                <span className="summary-metric-value">--</span>
                <span className="summary-metric-name">{local.observation.weight.title}</span>
              </div>
              <div className="summary-metric-footer error-msg">
                <span> <Icon symbol="plugConnect" className="error" />{local.timeline.summary.deviceNotConnected}</span>
              </div>
            </React.Fragment>}
          </Card>
        </LinkWrapper>
        <LinkWrapper id='steps-link' backpath={backPath} {...sleepNavigationProps}>
          <Card>
            <div className="summary-metricIcon">
              <NavIcon Icon={Sleep}></NavIcon>
            </div>
            {(sleepAvgCount && sleepAvgCount > 0) ? (
              <React.Fragment>
                <div className="summary-metricContent">
                  <div>
                    {buildDurationDisplayText(moment.duration(sleepAvgCount, 'seconds'))}
                  </div>
                  <div className="summary-metric-name">
                    <span className="summary-metric-name">{local.timeline.summary.sleepDays}</span>
                  </div>
                </div>
                {sleepAvgCount !== sleepTrend && sleepTrend > 0 &&
                <div className="summary-metric-footer">
                  <span><Icon symbol="arrowCircleUp" />+{buildTrendDisplayText(moment.duration(sleepTrend, 'seconds'))} {local.timeline.summary.trendWeek }</span>
                </div>}

                {sleepAvgCount !== sleepTrend && sleepTrend < 0 &&
                <div className="summary-metric-footer">
                  <span><Icon symbol="arrowCircleDown" />-{buildTrendDisplayText(moment.duration(Math.abs(sleepTrend), 'seconds'))} {local.timeline.summary.trendWeek }</span>
                </div>}

                {sleepAvgCount !== sleepTrend && sleepTrend === 0 &&
                <div className="summary-metric-footer">
                  <span><Icon symbol="removeCircleOutline" />{local.timeline.summary.noChangeInData}</span>
                </div>}
              </React.Fragment>
            ) :(
              <React.Fragment>
                <div className="summary-metricContent">
                  <span className="summary-metric-value">--</span>
                  <span className="summary-metric-name">{local.timeline.summary.sleepDays}</span>
                </div>
                <div className="summary-metric-footer error-msg">
                  <span> <Icon symbol="plugConnect" className="error" />{ local.timeline.summary.deviceNotConnected }</span>
                </div>
              </React.Fragment>
            )}
          </Card>
        </LinkWrapper>
        <LinkWrapper id='steps-link' backpath={backPath} {...nutritionNavigationProps}>
          <Card>
            <div className="summary-metricIcon">
              <NavIcon Icon={Nutrition}></NavIcon>
            </div>
            {(nutritionAvgcalories && nutritionAvgcalories > 0) ? (
              <React.Fragment>
                <div className="summary-metricContent">
                  <div>
                    <span className="summary-metric-value">{ formatNumber(nutritionAvgcalories) }</span>
                    <span className="summary-metric-unit"> {local.units.Calories}</span>
                  </div>
                  <div className="summary-metric-name">
                    <span className="summary-metric-name">{local.timeline.summary.nutritionDays}</span>
                  </div>
                </div>
                {nutritionAvgcalories !== nutritionTrend && nutritionTrend > 0 &&
                <div className="summary-metric-footer">
                  <span><Icon symbol="arrowCircleUp" />+{formatNumber(nutritionTrend)} {local.timeline.summary.trendWeek }</span>
                </div>}
                {nutritionAvgcalories !== nutritionTrend && nutritionTrend < 0 &&
                <div className="summary-metric-footer">
                  <span><Icon symbol="arrowCircleDown" />{formatNumber(nutritionTrend)} {local.timeline.summary.trendWeek }</span>
                </div>}
                {nutritionAvgcalories !== nutritionTrend && nutritionTrend === 0 &&
                <div className="summary-metric-footer">
                  <span><Icon symbol="removeCircleOutline" />{local.timeline.summary.noChangeInData}</span>
                </div>}
              </React.Fragment>
            ) :(
              <React.Fragment>
                <div className="summary-metricContent">
                  <span className="summary-metric-value">--</span>
                  <span className="summary-metric-name">{local.timeline.summary.nutritionDays}</span>
                </div>
                <div className="summary-metric-footer error-msg">
                  <span> <Icon symbol="plugConnect" className="error" />{ local.timeline.summary.deviceNotConnected }</span>
                </div>
              </React.Fragment>
            )}
          </Card>
        </LinkWrapper>
      </div>}
    </Page>
  );

  function FormatStepsValue( steps ) {
    var formattedSteps = steps.toString();
    if (formattedSteps.length >= 5) {
      formattedSteps = formattedSteps.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    return formattedSteps;
  }
}

export const buildDurationDisplayText = (duration) => {
  let durationObject = [];
  let ClassName='summary-metric-value';
  let ClassUnitName='summary-metric-unit';

  var days=<span className={ClassName}>{duration.days()}</span>;
  var hours=<span className={ClassName}>{duration.hours()}</span>;
  var minutes=<span className={ClassName}>{duration.minutes()}</span>;
  var daysShortLabel=<span className={ClassUnitName}>{local.daysShortLabel}</span>;
  var hoursLabel=<span className={ClassUnitName}>{local.observation.sleep.hoursLabel}</span>;
  var minutesLabel=<span className={ClassUnitName}>{local.observation.sleep.minutesLabel}</span>;

  if (duration.days() > 0) { durationObject.push(days,daysShortLabel,' '); }
  if (duration.hours() > 0) { durationObject.push(hours,hoursLabel,' '); }
  if (duration.minutes() > 0) { durationObject.push(minutes,minutesLabel,' '); }
  return [durationObject] ;
};

export const buildTrendDisplayText = (duration) => {
  let durationObject = [];
  if (duration.days() > 0) { durationObject.push(duration.days()  + local.daysShortLabel); }
  if (duration.hours() > 0) { durationObject.push(duration.hours()  + local.observation.sleep.hoursLabel); }
  if (duration.minutes() > 0) { durationObject.push(duration.minutes() + local.observation.sleep.minutesLabel); }
  return `${durationObject.join(' ')}`;
};

export const formatNumber = value => {
  let formetedNumber=(Number(value)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  let splitArray=formetedNumber.split('.');
  if(splitArray.length>1){
    formetedNumber=splitArray[0];
  }
  return(formetedNumber);
};
