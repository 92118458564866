export const getCurrentProfileMedications = (state, profileId) => {
  return state.medication.medications.filter(x => x.profileId === profileId);
};

export const getUrlMedication = (state, props) => {
  const medicationId = props && props.match && props.match.params && props.match.params.medicationId;
  return state.medication.medications.find(x => x.id === medicationId);
};

export const getMedications = (state) => {
  return state.medication?.medications ?? [];
};
