import React from 'react';
import PropTypes from 'prop-types';
import { DatePeriod } from 'shared';
import local from 'services/localization/local';

export default function ImmunizationListItemHG({ data }) {
  const { name, date, dose, lotNumber, practitioner, manufacturer, status } = data;
  const content = local.healthData.immunizations;

  return (
    <li className="card-list-item">
      <div className='status-row'>
        <DatePeriod value={date} />
        <small>{status}</small>
      </div>
      <span className='item-title'>{name}</span>
      {practitioner && (<small>{content.practitioner}: {practitioner}</small>)}
      <div>
        {dose && (<div>{content.dose}: {dose}</div>)}
        {lotNumber && (<div>{content.lotNumber}: {lotNumber}</div>)}
        {manufacturer && (<div>{content.manufacturer}: {manufacturer}</div>)}
      </div>
    </li>
  );
}

ImmunizationListItemHG.propTypes = {
  data: PropTypes.shape()
};
