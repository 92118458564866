import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { initializeAI } from 'services/appinsights-service';

function AppInsights({ history }) {
  useEffect(() => {
    initializeAI({ history });
  }, []);

  return null;
}

export default withRouter(AppInsights);
