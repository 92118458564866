import { isMobile } from 'mobile/helpers';
import React, { useCallback, useState } from 'react';
import { MobileSubstringDescriptionLength, ProgramDescriptionLength } from 'services/constants';
import local from 'services/localization/local';
import { FormattedText, Icon } from 'shared';
import {trimDescription} from './goal-selectors';

export default function GoalPathwaysList(props){
  const program = props.program;
  const [showResults, setShowResults] = useState(false);

  const handleClick = useCallback(() => {
    setShowResults(!showResults);
  }, [showResults]);

  return(
    <li key={'program-' + program.id} className="card-list-item">
      <div id={'program-select-link-' + program.id} className="program-tile" onClick={!program.isPrimaryProgram ?() => props.selectProgram(program.id, !program.isSelected) : null}>
        <div className="select-icons">
          <input onChange={() => {}} className={program.isSelected ? 'checked form-check-input' : 'unChecked form-check-input'} disabled={program.isPrimaryProgram} readOnly={program.isSelected} checked={program.isSelected} type="checkbox" id="selectOne" />
        </div>
        <div>
          <div className="program-enrollment-info">
            <div>
              <img className="icon-image" src={program.iconPath || 'https://static.myhc.app/programs/hc_thumb.png'}></img>
            </div>
            <strong className="program-header">{program.title}</strong>
          </div>
          <small> <FormattedText text={showResults ? program.description : trimDescription(program.description)}></FormattedText></small>
        </div>
      </div>
      {(program.description.length > ProgramDescriptionLength) || (isMobile && program.description.length > MobileSubstringDescriptionLength) ?
        <div className='collapse-expand'>
          <small id={'expand-' + program.id} className='item-action'><a className={showResults ? 'collapse-icon' : 'expand-icon'} onClick={handleClick}>{showResults ? local.goals.collapseAction : local.goals.viewAll} <Icon className="task-section-open-close-indicator" symbol="arrowdown" /></a></small>
        </div> : null}
    </li>
  );
}
