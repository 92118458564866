import React, { Component } from 'react';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import { Error, Loading, LoadingOverlay } from 'shared';

// When wrapping a simple persistent-data-action use PersistentDataStatus instead
// When wrapping a simple request-queue-action use RequestQueueStatus instead
// When wrapping a simple request-action use RequestStatus instead
export class DataLoadingStatus extends Component {
  constructor(props) {
    super(props);
    this.renderDefault = this.renderDefault.bind(this);
    this.renderWithOverlay = this.renderWithOverlay.bind(this);
  }

  renderDefault() {
    const { loaded, loading, error, errorMessage, children } = this.props;

    if (loading) {
      return <Loading />;
    }

    if (error) {
      return <Error>{errorMessage}</Error>;
    }

    if (loaded) {
      return children;
    }

    return null;
  }

  renderWithOverlay() {
    const { loading, error, children } = this.props;

    if (error) {
      return <Error>{local.error}</Error>;
    }

    return (
      <React.Fragment>
        {loading && <LoadingOverlay />}
        {children}
      </React.Fragment>
    );
  }


  render() {
    const { overlay } = this.props;

    if (overlay) {
      return this.renderWithOverlay();
    }

    return this.renderDefault();
  }
}

DataLoadingStatus.propTypes = {
  loaded: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  children: PropTypes.node.isRequired,
  overlay: PropTypes.bool
};
DataLoadingStatus.defaultProps = {
  loaded: true, //This property hasn't been implemented everywhere, but we can begin to use it.
  errorMessage: local.error
};

export default DataLoadingStatus;
