import React from 'react';
import { useSelector } from 'react-redux';
import local from 'services/localization/local';
import styleVars from 'styles/_variables.scss';
import { routePaths } from 'services/routing';
import { ObservationType } from 'services/enums';
import { LilChartDataPointCount } from 'services/constants';
import { roundToDecimals } from 'services/value-formatter';
import LilChart from 'health/lil-charts/LilChart';
import { getLilChartObservationData, getLilChartCurrentObservation } from 'health/observations/observation-selectors';
import { Icon } from 'shared';
import { configIds } from 'health/observations/observation-config-ids';
import useObservationData from 'health/observations/hooks/useObservationData';

export default function TemperatureLilChart() {
  const [loading, loadError] = useObservationData(ObservationType.BodyTemperature, LilChartDataPointCount);
  const temperatures = useSelector(state => getLilChartObservationData(state, ObservationType.BodyTemperature));
  const currentTemperature = useSelector(() => getLilChartCurrentObservation(temperatures));

  const metricValue = currentTemperature != null ? roundToDecimals(currentTemperature, 1) : null;

  return (
    <LilChart
      metricIcon={<Icon symbol="temperature" className="hero" size="3em" />}
      data={temperatures}
      minDataTick={90}
      noDataPath={{ to: routePaths.healthLogObservation, params: { configId: configIds.temperature } }}
      noDataText={local.observation.temperature.tileNoDataText}
      color={styleVars.colorTeal}
      title={local.observation.temperature.title}
      metricUnits={local.units.degreesFahrenheit}
      metricValue={metricValue}
      path={{ to: routePaths.healthObservation, params: { configId: configIds.temperature } }}
      loading={loading}
      loadError={loadError} />
  );
}
