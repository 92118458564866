import React from 'react';

export default function VerifiedArt() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1009.5 839.6" style={{ enableBackground: 'new 0 0 1009.5 839.6' }}>
      <path className="st3" d="M586.6 111.4c-64.7-2.2-126.4-23.1-185.2-46S284.2 17.2 221 5.1C180.4-2.7 133.9-3.8 101.2 18 69.7 39 59.5 75.1 54 108.7c-4.1 25.3-6.5 51.8 4.7 75.5 7.8 16.4 21.7 30.2 31.4 46 33.5 54.7 9.8 122.2-26.4 175.6-17 25.1-36.8 49-49.9 75.7C.7 508.2-5.4 538.7 6.1 566c11.4 27 38.5 47.2 67.9 61.5 59.7 29 130 37.2 198.6 41.9 151.8 10.4 304.5 5.9 456.7 1.4 56.3-1.7 112.9-3.4 168.3-12.1 30.8-4.8 62.6-12.5 84.9-31 28.4-23.5 35.4-63.4 16.4-92.9-31.9-49.5-120-61.8-142.3-114.9-12.3-29.2.3-61.8 18.2-88.9 38.2-58.2 102.3-109.2 105.7-175.7 2.3-45.7-28.5-91.4-76.1-113-49.9-22.6-119.2-19.8-156 17.7-38 38.5-104.7 53.4-161.8 51.4z" />
      <ellipse className="st3" cx="483.5" cy="781" rx="304" ry="41.1" />
      <path className="st8" d="M645.1 289.8h4.3c1.3 0 2.3 1 2.3 2.3v68.4c0 1.3-1 2.3-2.3 2.3h-4.3c-1.3 0-2.3-1-2.3-2.3v-68.4c0-1.3 1-2.3 2.3-2.3zM350.7 232.6h.4c1.3 0 2.3 1 2.3 2.3v19.5c0 1.3-1 2.3-2.3 2.3h-.4c-1.3 0-2.3-1-2.3-2.3v-19.5c0-1.3 1-2.3 2.3-2.3zM350.5 276.6h1c1.3 0 2.3 1 2.3 2.3v37.3c0 1.3-1 2.3-2.3 2.3h-1c-1.3 0-2.3-1-2.3-2.3v-37.3c0-1.2 1-2.3 2.3-2.3zM350.6 333.1h.7c1.3 0 2.3 1 2.3 2.3V373c0 1.3-1 2.3-2.3 2.3h-.7c-1.3 0-2.3-1-2.3-2.3v-37.6c0-1.3 1-2.3 2.3-2.3z" />
      <path className="st13" d="M390.2 153.2h218.4c21.5 0 39 17.5 39 39v524.4c0 21.5-17.5 39-39 39H390.2c-21.5 0-39-17.5-39-39V192.2c0-21.6 17.4-39 39-39z" />
      <path className="st10" d="M605.8 168.8h-34v4.1c0 10.7-8.7 19.4-19.4 19.4H444.3c-10.7 0-19.4-8.7-19.4-19.4v-4.1H393c-13 0-23.5 10.5-23.5 23.5v524c0 13 10.5 23.5 23.5 23.5h212.7c13 0 23.5-10.5 23.5-23.5V192.4c.1-13-10.4-23.6-23.4-23.6z" />
      <path className="st4" d="M474.7 171.2h33.6c2.1 0 3.9 1.7 3.9 3.9v.7c0 2.1-1.7 3.9-3.9 3.9h-33.6c-2.1 0-3.9-1.7-3.9-3.9v-.8c0-2.1 1.7-3.8 3.9-3.8z" />
      <circle className="st4" cx="523.2" cy="175.4" r="4.8" />
      <path className="st2" d="M750.2 126.8s-84.2-5.1-74.3 53.9c0 0-2 10.4 7.5 15.2 0 0 .2-4.4 8.6-2.9 3 .5 6.1.6 9.2.4 4.1-.3 8-1.9 11.2-4.6 0 0 23.7-9.8 32.9-48.5 0 0 6.8-8.4 6.5-10.6l-14.2 6.1s4.9 10.2 1 18.8c0 0-.5-18.4-3.2-18-.5.1-7.4 3.6-7.4 3.6s8.3 17.9 2 30.8c0 0 2.4-22-4.7-29.5l-10 5.8s9.8 18.4 3.1 33.5c0 0 1.7-23.1-5.2-32.1l-9.1 7.1s9.2 18.2 3.6 30.6c0 0-.7-26.9-5.5-28.9 0 0-7.9 7-9.1 9.9 0 0 6.3 13.2 2.4 20.2 0 0-2.4-17.9-4.3-18 0 0-7.9 11.9-8.7 20 0 0 .3-12.1 6.8-21.1 0 0-7.6 1.3-12.1 6.3 0 0 1.2-8.4 14-9.1 0 0 6.5-9 8.3-9.5 0 0-12.7-1.1-20.5 2.4 0 0 6.8-7.9 22.8-4.3l9-7.3s-16.8-2.3-23.9.2c0 0 8.2-7 26.3-1.9l9.7-5.8s-14.3-3.1-22.8-2c0 0 9-4.9 25.7.4l7-3.1s-10.5-2.1-13.5-2.4-3.3-1.4-3.3-1.4c6.6-1.1 13.5-.3 19.7 2.2 0 0 14.8-5.5 14.5-6.4zM663.8 100.5s-38-2.3-33.6 24.4c0 0-.9 4.7 3.4 6.8 0 0 .1-2 3.9-1.3 1.4.2 2.8.3 4.1.2 1.9-.1 3.6-.9 5-2.1 0 0 10.7-4.4 14.9-21.9 0 0 3.1-3.8 3-4.8l-6.4 2.7s2.2 4.6.5 8.5c0 0-.2-8.3-1.4-8.1-.2 0-3.3 1.6-3.3 1.6s3.8 8.1.9 13.9c0 0 1.1-9.9-2.1-13.4l-4.5 2.7s4.4 8.3 1.4 15.1c0 0 .8-10.4-2.4-14.5l-4.1 3.2s4.1 8.2 1.6 13.9c0 0-.3-12.1-2.5-13.1 0 0-3.6 3.2-4.1 4.5 0 0 2.8 6 1.1 9.1 0 0-1.1-8.1-2-8.1 0 0-3.6 5.4-3.9 9 .2-3.4 1.2-6.7 3.1-9.5-2.1.4-4 1.4-5.5 2.8 0 0 .6-3.8 6.3-4.1 0 0 3-4.1 3.7-4.3 0 0-5.8-.5-9.2 1.1 0 0 3.1-3.6 10.3-1.9l4-3.3s-7.6-1-10.8.1c0 0 3.7-3.2 11.9-.9l4.4-2.6s-6.5-1.4-10.3-.9c0 0 4.1-2.2 11.6.2l3.2-1.4s-4.7-.9-6.1-1.1-1.5-.5-1.5-.5c3-.5 6.1-.2 8.9 1-.1 0 6.7-2.6 6.5-3z" />
      <ellipse className="st3" cx="864.2" cy="773.3" rx="26.9" ry="4.5" />
      <ellipse className="st3" cx="91.1" cy="810.7" rx="26.9" ry="4.5" />
      <ellipse className="st3" cx="195.3" cy="835.1" rx="26.9" ry="4.5" />
      <ellipse className="st10" cx="805.2" cy="822.1" rx="40.2" ry="6.8" />
      <path className="st2" d="M821.9 811c1.8-1.5 3.2-3.5 3.8-5.8.5-2.3-.5-5-2.7-5.9-2.5-.9-5.1.8-7.1 2.5s-4.3 3.7-6.9 3.3c2.7-2.5 4-6.2 3.2-9.8-.1-.7-.4-1.4-.9-2-1.4-1.5-3.8-.8-5.5.3-5.2 3.7-6.7 10.7-6.7 17.1-.5-2.3-.1-4.7-.1-7s-.7-5-2.6-6.2c-1.2-.7-2.6-1-4-.9-2.3-.1-4.9.2-6.5 1.9-2 2.1-1.5 5.7.3 8s4.3 3.8 6.8 5.4c1.9 1.2 3.6 2.7 4.8 4.6.1.3.3.5.4.8h14.6c3.3-1.7 6.3-3.8 9.1-6.3zM196.7 723.3s5.5 7.2-2.5 18-14.6 20-12 26.8c0 0 12.1-20.2 22-20.4s3.3-12.3-7.5-24.4z" />
      <path className="st14" d="M196.7 723.3c.5.7.9 1.5 1.1 2.3 9.6 11.3 14.8 21.8 5.5 22.1-8.6.2-18.9 15.7-21.4 19.5l.3.9s12.1-20.2 22-20.4 3.3-12.3-7.5-24.4z" />
      <path className="st12" d="M206.9 732.4c0 2.5-.3 4.6-.6 4.6s-.6-2-.6-4.6.4-1.3.7-1.3.5-1.2.5 1.3z" />
      <path className="st12" d="M210.4 735.5c-2.2 1.2-4.2 1.9-4.3 1.6s1.5-1.5 3.7-2.8 1.4-.3 1.5 0 1.3-.1-.9 1.2z" />
      <path className="st2" d="M167.6 723.3s-5.5 7.2 2.5 18 14.6 20 12 26.8c0 0-12.1-20.2-22-20.4s-3.3-12.3 7.5-24.4z" />
      <path className="st14" d="M167.6 723.3c-.5.7-.9 1.5-1.1 2.3-9.6 11.3-14.7 21.8-5.5 22.1 8.6.2 19 15.7 21.4 19.5l-.3.9s-12.1-20.2-22-20.4-3.3-12.3 7.5-24.4z" />
      <path className="st12" d="M157.4 732.4c0 2.5.3 4.6.6 4.6s.6-2 .6-4.6-.4-1.3-.7-1.3-.5-1.2-.5 1.3z" />
      <path className="st12" d="M153.9 735.5c2.2 1.2 4.1 1.9 4.3 1.6s-1.5-1.5-3.7-2.8-1.3-.3-1.5 0-1.3-.1.9 1.2z" />
      <path className="st5" d="M159.2 767.1s15.4-.5 20-3.8 23.6-7.2 24.8-2 23.1 26.3 5.8 26.4-40.3-2.7-44.9-5.5-5.7-15.1-5.7-15.1z" />
      <path className="st14" d="M210 786c-17.3.1-40.3-2.7-44.9-5.5-3.5-2.2-4.9-9.8-5.4-13.4h-.5s1 12.4 5.6 15.2 27.6 5.7 44.9 5.5c5 0 6.7-1.8 6.6-4.5-.7 1.7-2.6 2.7-6.3 2.7z" />
      <path className="st14" d="M402.1 245.7c-2.1-.6-5.1-1.6-8.7-2.9 3.8-4.9 6.5-10.5 8.1-16.5l-.3.1c.1-.4.2-.7.3-1.1-6.9 2.9-14.6-2-19.5-8.2s-8.2-13.9-13.6-19.7c-2.2-2.3-4.6-4.4-7.2-6.3-1.6-1.2-3.3-2.5-5.2-2.6-3.1-.1-5.6 3-8.6 3.9-2 .5-4.1.4-6-.2-7.8-1.8-15.3-5.1-23.1-6-4.2-.5-8.5 0-12.6 1.4-4 1.5-5.8 4.6-9 7-3.5 2.6-10.4 2.6-14.5 3-5 .6-10 .8-14.9.6-2.9-.3-5.8-.2-8.6.3-5.9 1.3-10.7 6.1-15.2 10.7-1.2 1.1-2.1 2.4-2.5 4-.1.4-.1.9 0 1.3 0 1.2.2 2.4.6 3.5 1.3 4.1 3.3 8.1 5.7 11.7 1.8 2.6 3.9 5.1 5 8.2.5 1.5.8 3.1.8 4.8-.1 1.7-.2 3.4-.5 5.1-.8 4.7-2.1 9.2-3.9 13.6-.9 2.3-2 4.6-2.2 7.2v1.5c-.3 7.8 7.5 13.5 9 21.3.1.6.2 1.3.2 2-.2 4.9-2.9 9.6-6.9 11.5.4.1.7.3 1.1.4-.3.2-.7.4-1.1.6.7.3 1.5.5 2.2.7-4.6 4-8.5 8.8-11.4 14.2-13.5 25.5-105.2 75.6-105.2 75.6s.5.3 1.4.8c-.3.2-.7.5-1.1.7-7.9 5.4-26.6 21-9.7 40.7 0 0 13.1-21 22.4-30 .4-.4.8-.8 1.2-1.1.4.6.5 1.4.4 2.2l60.3-34.4s21.4-15.2 27.4-18.7c2.1-1.2 7.3-4.5 13.6-5.9l-.7.3c2.3-.6 4.6-1 6.9-1 10.7.2 22.2 8.1 26.1 39.5 5.1 42.1 2.7 49.6 1.1 50.7-.2 0-.4-.1-.6-.1 0 0 .1.1.2.1-.2 0-.4-.1-.7-.1.2.2.6.3.9.2.2.1.5 0 .7-.1h.8c0 .4-.1.8-.1 1.2-.1 0-.5.2 0 .3-.1 2.4-.1 5 0 7.6-5.3.3-10.6.6-15.3.9l.4-2.2.2-1c4.1-.3 7.9-1.5 8.4-4.9 1-7.4-9-11.3-17.5-11.8s-59.3 3-59.3 3-7.6 10 16.6 13.1c.7.4 1.5.8 2.2 1.2 9.5 5.5 22.3 15.6 28.5 20.5l.9.7-2.8 2.2c15 3.4 29.4 61.4 37.4 87.9s25.4 25.5 39.9 21.6c13.5-3.7 15.3-43.6 15.4-48.6v-.5l24.1 30.1 1.8 2.3c.7.4 1.4.7 2.1 1 18.2 8.8 38.4-8.1 32.7-27.3-5.8-19.4-14.8-48.1-20.8-60.2-5-10.1-11.5-27-15.5-37.6.5-.2 1-.5 1.5-.7 0 0-.1-.5-.2-1.3l.2-.1s-5.8-30.5-6.1-52.2v-1.1-.7c4.4-1 7.1-1.4 7.1-1.4l.1-.9-.5.1 2.4-28.1s8.9-19.2 2.1-27.2c-2.1-2.3-3.5-5.2-4-8.3-1.1-7.4 0-19.6 15.9-29.8 24.4-15.7 33.9-28.5 33.9-28.5s.3-9.4-14.6-13.8zm-48.6 19.5v-13c4.5-.2 9.2.3 13.7 0 2.2-.2 4.5-.5 6.6-1.1l1.1.8c-6.6 5.2-13.8 9.6-21.4 13.3zM284.3 469l5.4 3.9c1.1 6.4 2.1 10.9 2.1 11.2-2-5.2-4.5-10.3-7.5-15.1z" />
      <path className="st6" d="M323.4 460.8l-1.1 5.9-.8 4.5-1.5 8.2-14.6 10.3s-1.3-1.1-3.5-2.9c-6-4.9-18.6-15-27.9-20.4-2.4-1.5-5-2.7-7.7-3.5l57.1-2.1z" />
      <path className="st14" d="M323.4 460.8l-1.1 5.9c-3.2.2-6.5 0-9.7-.5l-34.2.5c-1.6-.1-3.1-.2-4.5-.4-2.4-1.5-5-2.7-7.7-3.5l57.2-2z" />
      <path className="st8" d="M312.7 465.2s17.1 2.9 18.1-4.4-8.8-11.2-17.1-11.7-58.1 2.9-58.1 2.9-8.8 11.7 22.9 13.7l34.2-.5z" />
      <path className="st14" d="M323.4 460.8l-1.1 5.9-.8 4.5-1.5 8.2-14.6 10.3s-1.3-1.1-3.5-2.9c-6-4.9-18.6-15-27.9-20.4-2.4-1.5-5-2.7-7.7-3.5l57.1-2.1z" />
      <path className="st14" d="M323.4 460.8l-1.1 5.9c-3.2.2-6.5 0-9.7-.5l-34.2.5c-1.6-.1-3.1-.2-4.5-.4-2.4-1.5-5-2.7-7.7-3.5l57.2-2z" />
      <path className="st14" d="M312.7 465.2s17.1 2.9 18.1-4.4-8.8-11.2-17.1-11.7-58.1 2.9-58.1 2.9-8.8 11.7 22.9 13.7l34.2-.5z" />
      <path className="st8" d="M260.7 224.6H350v105.9h-89.3z" />
      <path className="st14" d="M260.7 225.1H350V331h-89.3z" />
      <path className="st6" d="M156.1 405.9c-1.5.4-3.4 1.8-5.4 3.9-9 8.9-21.9 29.8-21.9 29.8-16.5-19.6 1.8-35.1 9.5-40.5 1.9-1.3 3.2-2 3.2-2l14.6 8.8zM322.5 258s4.4 30.7 19.5 38.1-19.5 21-19.5 21l-47.3-17.6s26.8-8.8 21.5-34.6l25.8-6.9zM271.7 443l-1.1 5.8-.9 4.5-1.5 8.2-14.6 10.2s-1.3-1.1-3.5-2.9c-6-4.9-18.6-15-27.9-20.4-2.4-1.5-5-2.7-7.7-3.5l57.2-1.9z" />
      <path className="st14" d="M271.7 443l-1.1 5.8c-3.2.2-6.5 0-9.7-.5l-34.2.5c-1.6-.1-3.1-.2-4.5-.4-2.4-1.5-5-2.7-7.7-3.5l57.2-1.9z" />
      <path className="st8" d="M261 447.4s17.1 2.9 18-4.4-8.8-11.2-17.1-11.7-58.1 2.9-58.1 2.9-8.8 11.7 22.9 13.7l34.3-.5z" />
      <path className="st14" d="M269.8 453.3l-1.5 8.2-14.6 10.2s-1.3-1.1-3.5-2.9l19.4-15.9.2.4z" />
      <path className="st8" d="M366.5 565c-.7-.3-1.3-.7-2-1l-1.8-2.3-23.6-29.9v.5c-.2 5-1.9 44.6-15.1 48.3-14.1 3.9-31.2 4.9-39-21.5s-22-83.9-36.6-87.4l21.7-17.8s14.4 16.8 20.2 32.9c-.2-1-10.1-47.4-2-55.6h71.2s11 31.3 18.5 46.9c5.9 12.1 14.7 40.5 20.4 59.8 5.7 19.1-14.1 35.9-31.9 27.1z" />
      <path className="st14" d="M364.4 440.1c-11 6.5-59.3 3.9-75 2.8l-4.3-.3c-.5 0 .1-.3.1-.3l.4-4.4 11.7-29.8 62.7-36.4c-1.3 3.8-1.7 9.7-1.6 16.4.4 21.6 6 52 6 52z" />
      <path className="st1" d="M364.4 438.6c-11 6.5-59.3 3.9-75 2.8l-4.3-.3-.7-.1.8-.2 3.9-1 8.3-33.2 62.7-36.4c-1.3 3.8-1.7 9.7-1.6 16.4.3 21.6 5.9 52 5.9 52z" />
      <path className="st14" d="M358.4 386.7c-12.8 2.9-40.8 11.6-51.6 32.4-7.2 13.9-13.2 19.8-17.5 22.3l-4.3-.3c0-.1.1-.2.1-.3l3.9-1 8.3-33.2 62.7-36.4c-1.2 3.9-1.7 9.8-1.6 16.5zM141.4 397.1l14.6 8.8c-1.5.4-3.4 1.8-5.4 3.9-1.2-3.6-8.9-8.5-12.4-10.6 2-1.4 3.2-2.1 3.2-2.1zM282.5 441.7c-.3.2-.7.1-1-.1.3 0 .7.1 1 .1z" />
      <path className="st11" d="M315.6 294.2s-13.2 1.5-22.5-8.3c0 0-39 11.2-52.2 36.6s-103 75.1-103 75.1 15.6 8.8 14.1 13.2l59-34.2s21-15.1 26.8-18.5 38.1-24.4 44.9 32.7 0 50.3 0 50.3 9.8 3.4 23.9-23.9 58.1-33.7 58.1-33.7l2.4-28.8s8.7-19.1 2-27c-2-2.3-3.4-5.2-3.9-8.3-1.1-7.3 0-19.5 15.5-29.6 23.9-15.6 33.2-28.3 33.2-28.3s.5-9.3-14.1-13.7-74.2-28.3-74.2-28.3l-2 8.3 47 33.2s-23.4 19-42 18.5c.2 0 3.6 14.2-13 14.7z" />
      <path className="st14" d="M324.7 271.4c-1.2-3.9-2.1-7.9-2.7-11.9l-25.9 6.8c1.1 4.9.9 10.1-.6 14.9 10.8 2.9 22.3-.9 29.2-9.8z" />
      <circle className="st6" cx="303.2" cy="252.9" r="27.8" />
      <g className="st14">
        <path d="M329.3 279.5s2 8.2-4.4 12.4c9.9-1.7 10.3-9.4 10-12.8-1.9.3-3.7.4-5.6.4zM143.9 395.6s89.8-49.8 103-75.1c9.5-18.2 32.2-29.1 44.3-33.9-8.8 3-38.5 14.3-49.7 35.8-13.2 25.4-103 75.1-103 75.1l5.4-1.9zM346.8 275.6c15.3-4.8 29.9-16.6 29.9-16.6l-46.9-33.2 1.1-4.5-4.5-1.8-2 8.3 46.9 33.2c-7.6 5.9-15.8 10.8-24.5 14.6zM347 388.1c6-2 12.1-3.6 18.4-4.6l.1-.9c-6.3 1.3-12.5 3.1-18.5 5.5zM290.3 439.2h-.4c-.3.2-.7.1-1-.1.3.1.7.1 1 .1 1.6-.9 4-8.2-1-50.4-5.4-45-26.5-41.5-38.1-36.3 11.8-3.3 28.1-.4 32.8 38.3 5 42.2 2.6 49.5 1 50.4 1.9-.1 4-.8 5.7-2zM284.5 441.2c-.3 0-.7 0-1-.1.2.2.6.2 1 .1z" />
      </g>
      <path className="st8" d="M318 188.7c7.7.9 15 4.2 22.6 6 1.9.6 3.9.7 5.9.2 3-.9 5.4-4 8.4-3.9 1.9.1 3.5 1.3 5.1 2.6 2.5 1.9 4.8 4 7 6.2 5.2 5.7 8.5 13.4 13.3 19.5s12.3 11 19.1 8.2c-4.6 18-19.8 31.7-36.1 32.8-6.5.5-13.2-.9-19.5 1-1.6.6-3.2 1-4.9 1.2-3.6 0-6.6-3.3-8.8-6.6-5.3-7.6-9.5-16.3-15.5-23.3-2-2.3-4.3-4.5-7.1-4.9s-5.4.9-7.9 2.2c-4.1 2.1-8.3 4.3-11.5 8s-5.2 9.1-3.9 14c.7 2.7 2.3 5.1 2.4 7.8.1 4.4-3.9 7.7-4.6 12-.2 1.8-.2 3.6-.2 5.5-.3 5.9-4.2 10.8-8.2 14.5-1.6 1.4-3.3 3-3.8 5.2-.3 1.5 0 3.1-.2 4.7-.6 3.8-4.4 5.8-7.8 6.3-4 .6-8 .1-11.7-1.4 4.7-2.2 7.7-8.6 6.6-14.3-1.5-7.9-9.5-13.6-8.8-21.7.4-2.5 1.1-4.9 2.2-7.1 1.7-4.4 3-8.9 3.8-13.5.6-3.6.9-7.4-.2-10.8-1-3.1-3.1-5.6-4.9-8.2-2.4-3.6-4.3-7.4-5.6-11.5-.5-1.2-.7-2.5-.5-3.8.4-1.5 1.3-2.9 2.5-3.9 4.4-4.6 9-9.3 14.8-10.6 2.8-.5 5.6-.6 8.4-.3 4.9.2 9.8-.1 14.6-.6 4-.5 10.8-.4 14.1-3 3.2-2.4 4.9-5.5 8.8-7 3.7-1.6 7.9-2 12.1-1.5z" />
      <circle className="st10" cx="501.2" cy="461.8" r="56.1" />
      <path className="st2" d="M574.9 454.4c0 41.4-33.6 74.9-74.9 74.9s-75-33.5-75-74.9 33.6-74.9 74.9-74.9 75 33.5 75 74.9zM491.3 494l55.6-55.6c1.9-1.9 1.9-4.9 0-6.8l-6.8-6.8c-1.9-1.9-4.9-1.9-6.8 0L488 470.1l-21.2-21.2c-1.9-1.9-4.9-1.9-6.8 0l-6.8 6.9c-1.9 1.9-1.9 4.9 0 6.8l31.4 31.4c1.7 2 4.8 2 6.7 0 0 .1 0 .1 0 0z" />
    </svg>
  );
}
