/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import moment from 'moment';
import local from 'services/localization/local';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withContext from 'app/withContext';
import { getStepsBarChartData, getStepsAverage } from 'health/observations/steps/steps-selectors';
import { ArrowButton } from 'shared';
import StepsBarChart from './StepsBarChart';
import './steps-visualization.scss';
import { routePaths } from 'services/routing';
import withNavigation from 'app/withNavigation';
import {getTrackSteps} from 'health/health-selectors';
import {formatStepsValue} from 'services/helpers.js';
import  HealthCardReading  from 'health/observations/HealthCardReadings/HealthCardReading';
import { ObservationType } from 'services/enums';

class StepsVisualization extends React.Component {
  constructor(props) {
    super(props);
    this.getTitle = this.getTitle.bind(this);
    this.onSetTarget = this.onSetTarget.bind(this);
  }
  getTitle() {
    const { selectedEnd } = this.props;
    {
      return local.formatString(`${moment(selectedEnd).add(-6, 'days').format('MMM D')} - ${moment(selectedEnd).format('MMM D')}`);
    }

  }
  onSetTarget(){
    const fromPathway = this.props.fromPathway ? true : false;
    const routeParams = this.props.fromPathway ? this.props.routeParams : '';
    const isFromCreateGoal = this.props.location?.state?.backpath?.isFromCreateGoal ?? false;
    this.props.navigate({ to: routePaths.stepTarget,state :{ fromPathway:fromPathway , routeParams : routeParams, isFromCreateGoal: isFromCreateGoal} } );
  }
  render() {
    const { data, profileId, selectPreviousWeeks, selectNextWeeks, selectedEnd, stepTarget, average,fromPathway,pathwayStartDate,pathwayEndDate } = this.props;
    const previousDisabled = fromPathway ? moment(pathwayStartDate) >= moment(selectedEnd).add(-6,'days') : false;
    const nextDisabled = fromPathway ? moment(pathwayEndDate) < moment(selectedEnd).add(1,'days') : moment(selectedEnd).add(1, 'day') > moment();
    const buttonId = stepTarget?.latestData ?'change-StepsGoal': 'add-StepsGoal';
    const goalValue = stepTarget?.latestData ? formatStepsValue(stepTarget?.latestData.value) : 0;
    const content = local.observation.steps;
    return (
      <div id="steps-visualization">
        <div className="steps-vis-header">
          <ArrowButton id="previous-steps-button" onClick={selectPreviousWeeks} disabled={previousDisabled} />
          <small className="steps-vis-title">{this.getTitle()}</small>
          <ArrowButton id="next-steps-button" rightArrow onClick={selectNextWeeks} disabled={nextDisabled} />
        </div>
        <div className='average-container'>
          {average.stepsAverage == 0 ?  <div className='no-data-label'>{content.noData}</div> : <div className='average-value'>{`${average.stepsAverage}`} <span className='average-unit'>{content.title}</span></div>}
          <div className='average-label'> {content.averageData} </div>
        </div>
        <StepsBarChart data={data} profileId={profileId} />
        <HealthCardReading
          minVal={average.minValue}
          maxVal={average.maxvalue}
          observationType={ObservationType.Steps}
          units={local.units.steps}
          activeGoal={goalValue}
          onClick={this.onSetTarget}
          fromPathway={fromPathway}
          buttonId={buttonId} />
      </div>
    );
  }
}

StepsVisualization.propTypes = {
  selectedEnd: PropTypes.string.isRequired,
  selectPreviousWeeks: PropTypes.func.isRequired,
  selectNextWeeks: PropTypes.func.isRequired,
  // redux
  data: PropTypes.arrayOf(PropTypes.shape).isRequired,
  navigate: PropTypes.func.isRequired,
  profileId: PropTypes.string.isRequired,
  stepTarget: PropTypes.objectOf(PropTypes.shape)
};

function mapStateToProps(state, props) {
  return {
    data: getStepsBarChartData(state, props.profileId, props.selectedEnd,props.steps,props.fromPathway,props.pathwayStartDate,props.pathwayEndDate),
    stepTarget: getTrackSteps(state, props.profileId),
    average: getStepsAverage(state, props.profileId, props.selectedEnd,props.steps,props.fromPathway)
  };
}

export default withNavigation(withContext(connect(mapStateToProps)(StepsVisualization)));
