import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class MedicationDoseVisualizationNavItem extends React.Component {
  constructor(props) {
    super(props);

    this.selectDate = this.selectDate.bind(this);
  }

  selectDate() {
    const { day: { date }, selectDate } = this.props;
    selectDate(date);
  }

  render() {
    const { active, day } = this.props;

    return (
      <span className={active ? 'active' : ''} onClick={this.selectDate}>
        <strong>{day.display}</strong>&nbsp;<small>{moment(day.date).format('M/D')}</small>
      </span>
    );
  }
}

MedicationDoseVisualizationNavItem.propTypes = {
  active: PropTypes.bool.isRequired,
  selectDate: PropTypes.func.isRequired,
  day: PropTypes.shape({
    date: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired
  }).isRequired
};

export default MedicationDoseVisualizationNavItem;
