import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ProgramTab from './ProgramTab';
import withContext from 'app/withContext';
import { ObservationType } from 'services/enums';
import { PathwayCharts } from 'services/constants';

export function ProgramTabs(props) {
  const goalObservationType = props?.location?.state?.goalObservationType;
  const pathwayTabData = props.fromPathway ? props.children.filter(x => x?.props?.label) : null;
  const defaultTab = props.fromPathway ? pathwayTabData && pathwayTabData.length > 0 && pathwayTabData[0].props.label : null;
  const [activeTab, setActiveTab] = useState(defaultTab);
  const pathwayTabLength = pathwayTabData ? pathwayTabData.length : 0;
  useEffect(() => {
    let availableTabs = props.children && props.children.length ? props.children.filter(tabs => tabs) : null;

    if (availableTabs && availableTabs.length > 0 ) {
      if(goalObservationType){
        switch(goalObservationType){
          case ObservationType.Steps :
            setActiveTab(PathwayCharts.steps);
            break;
          case ObservationType.BloodGlucose :
            setActiveTab(PathwayCharts.bloodGlucose);
            break;
          case ObservationType.Systolic :
            setActiveTab(PathwayCharts.bloodPressure);
            break;
        }
      }
      else{
        setActiveTab(availableTabs[0]?.props?.label);
      }
    }
  },[pathwayTabLength, goalObservationType]);

  function onClickTabItem(tab) {
    if(props.getTabName){
      props.getTabName(tab);
    }
    setActiveTab(tab);
  }

  const pathwayLabelData = props.fromPathway ? (pathwayTabData && pathwayTabData.length > 1 ? pathwayTabData : '' ): props.children;
  return (
    <div className="tabs">
      <ol className={props.fromPathway ? props.className : 'tab-list'}>
        {pathwayLabelData ? pathwayLabelData.map((child) => {
          const { label } = child.props ? child.props : '';
          return (
            <ProgramTab
              activeTab={activeTab}
              key={label}
              label={label}
              onClick={onClickTabItem} />
          );
        }) : null}
      </ol>
      <div className="tab-content">
        {props.children ? props.children.map((child) => {
          if(child.props){
            if (child.props.label !== activeTab) return undefined;
            return  child.props.children;
          }

        }) : null}
      </div>
    </div>
  );
}

ProgramTabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired
};

export default withContext(ProgramTabs);
