import React, { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { localStoragePaths } from 'services/constants';
import { getProfile } from 'profiles/list/selectors';
import useProfileIdFromUrl from './useProfileIdFromUrl';
import { selectProfile } from './selected-profile-actions';
import { handleProfileDeletionStatus } from 'identity/action-creators';
import { AccountDeletionStatus } from 'services/enums';
import SignoutDeleteAccountModal from 'preferences/deleteAccount/SignoutDeleteAccountModal';

const profileIdSelector = state => state.selectedProfile.id || state.profile.self.id;
const selfProfileIdSelector = state => state.profile.self.id;
const loadingSelector = state => state.profile.loading || state.profile.loadingList;

export function ProfileWatcher({ location }) {
  const dispatch = useDispatch();
  const urlProfileId = useProfileIdFromUrl(location);
  const selfProfileId = useSelector(selfProfileIdSelector, shallowEqual);
  const profileId = useSelector(profileIdSelector, shallowEqual);
  const profile = useSelector(getProfile, shallowEqual);
  const loading = useSelector(loadingSelector, shallowEqual);
  const email = useSelector(state => state.identity.email);
  const profileDeletionStatus = useSelector(state => state.identity.accountStatus);
  useEffect(() => {
    if (urlProfileId && urlProfileId !== profileId) {
      dispatch(selectProfile(urlProfileId));
    }
  }, [profileId, urlProfileId]);

  useEffect(() => {
    if (profileId && profile) {
      localStorage.setItem(localStoragePaths.selectedProfile, profileId);
    }
  }, [profileId, profile]);

  useEffect(() => {
    if (!loading && !profile && !urlProfileId) {
      dispatch(selectProfile(selfProfileId));
    }
  }, [loading, profile, urlProfileId]);
  useEffect(() => {
    dispatch(handleProfileDeletionStatus(email));
  }, [email]);

  return (
    <section id="profile-watcher-section">
      {
        (profileDeletionStatus === AccountDeletionStatus.Inprogress) &&
        <div>
          <SignoutDeleteAccountModal />
        </div>
      }
    </section>
  );
}
