import React from 'react';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import { ConfirmModal } from 'shared';

export default function RevokeAccessModal({ handleYes, handleNo, name, modalTitle, modalText, deleteYes, deleteNo }) {
  const formattedModalText = local.formatString(modalText, name);

  return (
    <ConfirmModal
      modalTitle={modalTitle}
      modalPromptText={formattedModalText}
      handleYes={handleYes}
      handleNo={handleNo}
      deleteYes={deleteYes}
      deleteNo={deleteNo}
      isOpen />
  );
}

RevokeAccessModal.propTypes = {
  handleYes: PropTypes.func,
  handleNo: PropTypes.func,
  name: PropTypes.string,
  modalTitle: PropTypes.string,
  modalText: PropTypes.string
};
