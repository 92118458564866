import React from 'react';
import PropTypes from 'prop-types';
import withNavigate from 'app/withNavigation';
import { routePaths } from 'services/routing';
import { NoDataPlaceholder, Icon, Button } from 'shared';
import './generic-observation-device-link-no-data.scss';
import local from 'services/localization/local';

class GenericObservationDeviceLinkNoData extends React.Component {
  constructor(props) {
    super(props);
    this.navigateToDevices = this.navigateToDevices.bind(this);
  }

  navigateToDevices() {
    const { navigate } = this.props;
    navigate({ to: routePaths.healthDevices });
  }


  render() {
    const { IconSymbol, message } = this.props;

    let icon = <Icon symbol='devices' size='3em' />;
    if (IconSymbol) {
      icon = <IconSymbol height='4em' width='4em' />;
    }

    return (
      <NoDataPlaceholder>
        <div className="generic-observation-no-data-device-link">
          {icon}
          <p className="message"><small>{message}</small></p>
          <Button id="connect-devices-button" className="btn btn-primary" color="blue" onClick={this.navigateToDevices}>{local.devices.connectButton}</Button>
        </div>
      </NoDataPlaceholder>
    );
  }
}

GenericObservationDeviceLinkNoData.propTypes = {
  message: PropTypes.string.isRequired,
  IconSymbol: PropTypes.node,
  // navigate
  navigate: PropTypes.func.isRequired
};

export default withNavigate(GenericObservationDeviceLinkNoData);
