import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import local from 'services/localization/local';
import { getProfileId } from 'app/selectors';
import { createInvite } from './sharing-actions';
import { getCreateInviteErrorMessage, getCreateInviteRequestSuccess } from './sharing-selectors';
import { routePaths } from 'services/routing';
import { Button, ErrorSection } from 'shared';
import { Form, Formik } from 'formik';
import { TextField } from 'shared/form-fields/formik';
import { email } from 'services/fields/field-validators';
import { getUniqueId } from 'services/helpers';

export default function ShareProfileForm() {
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const [requestId] = useState(getUniqueId());
  const errorMessage = useSelector(state => getCreateInviteErrorMessage(state, requestId));
  const inviteSuccess = useSelector(state => getCreateInviteRequestSuccess(state, requestId));

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(createInvite({ profileId, email: values.email, requestId, setSubmitting }));
  };

  if (inviteSuccess) {
    return <Redirect to={routePaths.sharingAdmin} />;
  }

  return (
    <Formik
      validateOnChange={false}
      initialValues={{ email: '' }}
      onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <div className="form-row">
            <ErrorSection>{errorMessage}</ErrorSection>
          </div>
          <br />
          <div className="form-row">
            <TextField
              validate={email}
              type='email'
              label={local.formEmailLabel}
              name='email' />
          </div>
          <br />
          <div className="">
            <Button
              color='blue'
              id='send-share-invite-button'
              className="btn btn-primary mx-0"
              disabled={isSubmitting}
              submit>
              {local.sharing.inviteEmail}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
