import { ObservationType } from 'services/enums';

export const convertObservationTypeToHealthDataType = (obsType) => {
  if (Array.isArray(obsType)) {
    return obsType.map(ot => ot + 1000);
  }

  return obsType + 1000;
};

export const getObservationMetricKey = (observationType) => {
  const observationName = Object.keys(ObservationType).find(x => ObservationType[x] === observationType);
  if (observationName) {
    return `Observation_${observationName}`;
  }
  return null;
};

export const getObjectKeyByValue = (object, value) =>
  Object.keys(object).find(key => object[key] === value);
