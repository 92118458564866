import { actionNames } from './request-queue-actions';

export const initialState = {};
export const defaultStatus = {
  result: null,
  success: false,
  processing: false,
  hasError: false,
  error: null,
  params: null
};

// Use request-actions instead when possible, request-actions manages it's own reducer
export const reducers = (type) => ({
  [actionNames(type).REQUEST_QUEUED]: queued(type),
  [actionNames(type).REQUEST_QUEUE_SUCCESS]: success(type),
  [actionNames(type).REQUEST_QUEUE_ERROR]: failed(type),
  [actionNames(type).REQUEST_QUEUE_REMOVE]: remove(type)
});

export const updateRequest = (state, type, requestId, requestData) => {
  return {
    ...state,
    [type]: {
      ...state[type],
      [requestId]: {
        ...state[type][requestId],
        ...requestData
      }
    }
  };
};

export const queued = (type) => (state, payload) => {
  const { requestId, params } = payload;
  if (!requestId) return state;

  return updateRequest(state, type, requestId, {
    result: null,
    success: false,
    processing: true,
    hasError: false,
    error: null,
    params
  });
};

export const success = (type) => (state, payload) => {
  const { requestId, result } = payload;
  if (!requestId) return state;

  return updateRequest(state, type, requestId, {
    result,
    success: true,
    processing: false,
    hasError: false,
    error: null
  });
};

export const failed = (type) => (state, payload) => {
  const { requestId, error } = payload;
  if (!requestId) return state;

  return updateRequest(state, type, requestId, {
    result: null,
    success: false,
    processing: false,
    hasError: true,
    error
  });
};

export const remove = (type) => (state, payload) => {
  const { requestId } = payload;
  if (!requestId) return state;

  const {
    // eslint-disable-next-line no-unused-vars
    [requestId]: removedRequest,
    ...rest
  } = state[type];

  return {
    ...state,
    [type]: {
      ...rest
    }
  };
};
