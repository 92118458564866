import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import StepsVisualization from './StepsVisualization';

class StepsVisualizationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.selectPreviousWeeks = this.selectPreviousWeeks.bind(this);
    this.selectNextWeeks = this.selectNextWeeks.bind(this);
    this.updateSelectedStart = this.updateSelectedStart.bind(this);

    this.state = {
      selectedEnd: moment().format(moment.HTML5_FMT.DATE)
    };
  }

  selectPreviousWeeks() {
    const previousSelectedStart = moment(this.state.selectedEnd).add(-6, 'days').format();
    this.updateSelectedStart(previousSelectedStart);
  }

  selectNextWeeks() {
    const nextSelectedStart = moment(this.state.selectedEnd).add(6, 'days').format();
    this.updateSelectedStart(nextSelectedStart);
  }

  updateSelectedStart(startDate) {
    this.setState({ selectedEnd: startDate });
  }

  render() {
    const { steps, fromPathway,pathwayStartDate,pathwayEndDate,routeParams } = this.props;
    if (steps.length === 0) {
      return null;
    }

    return (
      <StepsVisualization
        selectPreviousWeeks={this.selectPreviousWeeks}
        selectNextWeeks={this.selectNextWeeks}
        selectedEnd={this.state.selectedEnd}
        fromPathway={fromPathway}
        pathwayStartDate={pathwayStartDate}
        pathwayEndDate={pathwayEndDate}
        steps={steps}
        routeParams={routeParams} />
    );
  }
}

StepsVisualizationContainer.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape)
};

export default StepsVisualizationContainer;
