import React from 'react';
import local from 'services/localization/local';
import { ObservationType, Unit } from 'services/enums';
import { routePaths } from 'services/routing';
import { configIds } from 'health/observations/observation-config-ids';
import { baseObservationConfig } from 'health/observations/observation-config-helpers.jsx';
import { roundToDecimals } from 'services/value-formatter';
import LogHeartRate from 'health/observations/heart-rate/log/LogHeartRate';
import { HeartRate } from 'shared/fullColorIcons';
import { loadObservationEhrData } from 'health/action-creators';

const typeDefinition = {
  type: ObservationType.InstantaneousHeartRate,
  typeUnitMap: {
    [ObservationType.InstantaneousHeartRate]: Unit.BeatsPerMinute
  },
  unitLabelMap: {
    [Unit.BeatsPerMinute]: local.units.beatsPerMinute
  }
};

export default {
  ...baseObservationConfig,
  ...typeDefinition,
  id: configIds.heartRate,
  backPath: { to: routePaths.health },
  centerElement: local.observation.heartRate.title,
  logcenterElement: local.observation.heartRate.logTitle,
  noDataText: local.observation.heartRate.noDataText,
  iconSymbol: HeartRate,
  loadData: (props) => [props.config.type].flat().forEach(x => props.dispatch(loadObservationEhrData({ observationType: x, profileId: props.profileId }))),
  valueFormatter: (props) => `${roundToDecimals(props.observation.value)} ${props.config.unitLabelMap[props.observation.unit]}`,
  renderLogComponent: (props) => props && <LogHeartRate {...props} />
};
