import * as dataSelectors from 'app/request/persistent-data-selectors';
import { types } from 'organizations/organizations-types';
import {  OrganizationStatus, OrganizationMemberStatus } from 'services/enums';
import moment from 'moment';

export const getLogs = (state) => {
  const identityId = state.identity && state.identity.self.id;
  const memberTypeInfo = dataSelectors.getType(state.organization, types.ORGANIZATION_MEMBER_TYPE, identityId);

  const result = { ...memberTypeInfo, memberType: '', ageGroup: '', gender: '', email: '' };
  return result;
};