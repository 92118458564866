import React from 'react';
import local from 'services/localization/local';
import ProviderInfoForm from './providerInfoForm';
import InsuranceInfoForm from './insuranceInfoForm';
import { connect } from 'react-redux';
import './styles.css';
import ProfilePage from 'profiles/page/ProfilePage';
import withContext from 'app/withContext';
import { saveHealthInfoInsurance, saveHealthInfoProvider, loadHealthInfo } from './action-creators';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

export class HealthInfo extends React.Component {
  constructor(props) {
    super(props);
    this.toggleTab = this.toggleTab.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      activeTab: 'provider',
      providerInfo: {},
      insuranceDetails: {},
      readOnly: false
    };
  }

  componentDidMount(){
    const { actions, profileId } = this.props;
    if(profileId){
      actions.loadHealthInfo(profileId);
    }
    setTimeout(() => {
      var isDataExists = this.props.healthCareInfo?.healthInfo?.item1 && this.props.healthCareInfo?.healthInfo?.item2;
      if(isDataExists){
        this.setState({readOnly: false, providerInfo : this.props.healthCareInfo?.healthInfo?.item2, insuranceDetails : this.props.healthCareInfo?.healthInfo?.item1[0]});
        console.log(this.state.providerInfo, this.state.insuranceDetails);
      }
    }, 2000);
  }

  toggleTab(tab) {
    this.setState({ activeTab: tab });
  }

  handleSubmit(values) {
    const { actions, profileId } = this.props;
    if(this.state.activeTab == 'provider'){
      console.log(values);
      actions.saveHealthInfoProvider({ ...values, profileId });
    }else{
      actions.saveHealthInfoInsurance({ ...values, profileId });
    }
  }
  render() {
    return (
      <ProfilePage
        id="notifications-page"
        centerElement={local.healthInfo.title}
        loading={false}
        error={null}>
        <div className='healthInfo'>
          <div className="healthInfo-nav">
            <div
              className={`account-title ${this.state.activeTab === 'provider' ? 'account-title-active' : ''}`}
              onClick={() => this.toggleTab('provider')}>
              {local.healthInfo.provider.title}
            </div>
            <div
              className={`account-title ${this.state.activeTab === 'insurance' ? 'account-title-active' : ''}`}
              onClick={() => this.toggleTab('insurance')}>
              {local.healthInfo.insurance.title}
            </div>
          </div>
          {this.state.activeTab === 'provider' ? <ProviderInfoForm onSubmit={this.handleSubmit} readOnly={this.state.readOnly} initialValues={this.state.providerInfo} /> : <InsuranceInfoForm onSubmit={this.handleSubmit} readOnly={this.state.readOnly} initialValues={this.state.insuranceDetails} />}
        </div>
      </ProfilePage>
    );
  }
}

HealthInfo.propTypes = {
  healthInfo: PropTypes.shape(),
  profileId: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    loadHealthInfo: PropTypes.func.isRequired,
    saveHealthInfoInsurance: PropTypes.func.isRequired,
    saveHealthInfoProvider: PropTypes.func.isRequired
  }).isRequired
};

HealthInfo.defaultProps = {
  titleTabText: 'Health Care Info',
  centerElement: local.medicationLogHeader,
  readOnlyProfile: false,
  showDelete: false
};

function mapStateToProps(state) {
  return {
    healthCareInfo: state.healthCareInfo
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { saveHealthInfoInsurance, saveHealthInfoProvider, loadHealthInfo };
  return { actions: bindActionCreators(actions, dispatch) };
}
export default withContext(connect(mapStateToProps, mapDispatchToProps)(HealthInfo));


// class HealthInfo extends Component {
//   render() {
//     // const { isOwned } = this.props;

//     return (
//       <Page
//         id="notifications-page"
//         centerElement={local.healthInfo.title}
//         loading={false}
//         error={null}>
//         <div>
//           <div className="customize-section" data-testid="changeEmail">
//             <div className="emailWrapper">
//               <small className="account-title">{local.healthInfo.provider.title}</small>
//               <LinkWrapper className="button small-btn btn btn-secondary" to="#" >{local.healthInfo.provider.add}</LinkWrapper>
//             </div>
//             <div id='edit_account_settings'>
//               <div className="bg-white">
//                 <small data-testid='changeEmail' className="emailAccount">
//                   <div className="emailWrapper">
//                     <Icon symbol='mail' />
//                     <label className="emailLink">Test@gmail.com</label>
//                   </div>
//                   <LinkWrapper className="button small-btn btn btn-secondary" to="#" >{local.healthInfo.provider.edit}</LinkWrapper>
//                 </small>
//               </div>
//             </div>
//             <div id='edit_account_settings'>
//               <div className="bg-white">
//                 <small data-testid='changeEmail' className="emailAccount">
//                   <div className="emailWrapper">
//                     <Icon symbol='mail' />
//                     <label className="emailLink">Kannan@gmail.com</label>
//                   </div>
//                   <LinkWrapper className="button small-btn btn btn-secondary" to="#" >{local.healthInfo.provider.edit}</LinkWrapper>
//                 </small>
//               </div>
//             </div>
//           </div>
//           <ProviderInfoForm />
//         </div>
//       </Page>
//     );
//   }
// }

// HealthInfo.propTypes = {
//   // isOwned: PropTypes.bool.isRequired
// };

// HealthInfo.defaultProps = {
// };

// function mapStateToProps(state) {
//   return {
//     profileName: getProfileName(state),
//     isOwned: getIsCurrentProfileOwned(state)
//   };
// }

// export default withContext(connect(mapStateToProps)(HealthInfo));
