import React from 'react';

function NutritionMacroDetails(props) {

  return (
    props.isPercent ?
      <div className='form-row text-center macro-info-text'>
        <small>{props.content.macroGoalPage} <span className='bold'>{props.macro.carbohydrates}%</span> {props.content.carbsGoal} | <span className='bold'>{props.macro.fats}%</span> {props.content.fatGoal} | <span className='bold'>{props.macro.proteins}%</span> {props.content.proteinGoal}</small>
      </div> :
      <div className='form-row text-center macro-info-text'>
        <small>{props.content.macroGoalPage} <span className='bold'>{props.macro.carbohydrates}g</span> {props.content.carbsGoal} | <span className='bold'>{props.macro.fats}g</span> {props.content.fatGoal} | <span className='bold'>{props.macro.proteins}g</span> {props.content.proteinGoal}</small>
      </div>
  );
}

export default NutritionMacroDetails;
