import React, { Component } from 'react';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import Page from 'shared/page/Page';
import Loading from './Loading';

class LoadingPage extends Component {
  render() {
    return <Page id="loading-page-component" centerElement={this.props.centerElement}><Loading /></Page>;
  }
}
LoadingPage.propTypes = {
  centerElement: PropTypes.string
};
LoadingPage.defaultProps = {
  centerElement: local.loading
};

export default LoadingPage;
