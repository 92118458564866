import * as dataSelectors from 'app/request/persistent-data-selectors';
import { types } from './action-names';

export const getChatComponentProps = (state) => {
  const organizationDetail = (state.identity.self && dataSelectors.getData(state.organization, types.GET_ROOT_ORGANIZATION, state.identity.self.id)) || [];
  const isChatEnabledOrg = (organizationDetail && organizationDetail.length > 0) ? true : false;
  return {organizationDetail, isChatEnabledOrg};
};

export const getChatParticipants = (state) => {
  const participantDetails = (state.identity.self && dataSelectors.getData(state.chat, types.GET_PARTICIPANT_DETAILS, state.identity.self.id)) || [];
  return participantDetails;
};

export const getUnReadChatMessageCount = (state) => {
  const unReadChatMessageCountDetails = (state.identity.self && dataSelectors.getData(state.chat, types.GET_UNREAD_COUNT_DETAILS, state.identity.self.id));
  const unReadCount = (unReadChatMessageCountDetails != undefined && unReadChatMessageCountDetails > 0) ? unReadChatMessageCountDetails : 0;
  return unReadCount;
};


