import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getIsEmailVerified , getIdentityId } from 'identity/selectors';
import { loadOrganizationInvites, loadOrganizationInvitesCount } from 'organizations/organizations-actions';
import { loadActiveUsersProgramInvites } from '../programs/programs-actions';

import { loadIncomingInvites, loadIncomingInvitesCount } from 'profiles/sharing/sharing-actions';

export default function InvitesLoader() {
  const dispatch = useDispatch();
  const isVerified = useSelector(getIsEmailVerified);
  const identityId = useSelector(getIdentityId);

  useEffect(() => {
    if (isVerified) {
      dispatch(loadOrganizationInvites());
      dispatch(loadIncomingInvites());
    } else {
      dispatch(loadOrganizationInvitesCount());
      dispatch(loadIncomingInvitesCount());
    }
  }, [isVerified]);

  useEffect(() => {
    if (isVerified) {
      dispatch(loadActiveUsersProgramInvites(identityId));
    }
  }, [identityId]);

  return null;
}
