import React, { useState } from 'react';
import { ModalWrapper, Button, Icon } from 'shared';
import './Program-Resource-Commentsmodal.scss';
import local from 'services/localization/local';
import PropTypes from 'prop-types';

export default function ProgramResourceComments(props) {
  const [comments, setComments] = useState('');
  const maxLength = 1000;
  function submit() {
    props.onSubmit(comments);
  }
  function cancel() {
    props.onClose('');
  }
  return (

    <ModalWrapper isOpen>
      <div id="resource-feedback-modal" className="modal-container">
        <div className="modal-header">
          <Button id="close-quit-pathway-button" onClick={cancel}><Icon symbol="times" /></Button>
          <div className='title'>{local.resourceRating.yourfeedback}</div>
        </div>
        <div className="modal-body">
          <div className="modal-text-title">{local.resourceRating.content} {props.resourceRating ? local.resourceRating.helpfulContent : local.resourceRating.notHelpfulContent}</div>
          <textarea
            id='CommentsTextArea'
            className='resource-text-area'
            value={comments}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={(e) => setComments(e.target.value)}
            maxLength={maxLength}>
          </textarea>
          <div className='characters-modal'>{maxLength - comments.length}/{maxLength} characters left
          </div>
        </div>
        <div className="modal-footer">
          <Button id='cancel-resource-button' onClick={cancel}>{local.resourceRating.cancel}</Button>
          <Button id='confirm-resource-button' onClick={submit}>{local.resourceRating.submit}</Button>
        </div>
      </div>
    </ModalWrapper>
  );
}

ProgramResourceComments.propTypes = {
  resourceRating: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};
