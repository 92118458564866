/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { NavIcon, Page, Card } from 'shared';
import { useSelector, useDispatch } from 'react-redux';
import local from 'services/localization/local';
import { getProfileId } from 'app/selectors';
import './BadgeSummary.scss';
import { loadBadgesSummary, getBadgesIcon } from './action-creators';
import { getBadgeSummaryDetails, getBadgeIcons } from './BadgeSummary-selectors';
import { BadgeCategoryType, BadgeType } from 'services/enums';
import { routePaths } from 'services/routing';
import moment from 'moment';
import { getBadgeIcon, getGreyBadgeIcon } from './badge-helpers';

export default function BadgeSummary() {
  const text = local.healthBadge.badge;
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const { data, loading, hasError } = useSelector(state => getBadgeSummaryDetails(state));
  const { badgeIconsPath } = useSelector(state => getBadgeIcons(state));
  const milestoneBadges = data ? data.filter(x => x.badge.badgeCategory.badgeCategoryType == BadgeCategoryType.MileStone) : null;
  const explorerBadges = data ? data.filter(x => x.badge.badgeCategory.badgeCategoryType == BadgeCategoryType.Explorer) : null;
  const challengeBadges = data ? data.filter(x => x.badge.badgeCategory.badgeCategoryType == BadgeCategoryType.Challenge) : null;

  useEffect(() => {
    dispatch(loadBadgesSummary(profileId));
    dispatch(getBadgesIcon(profileId));
  }, [profileId]);

  return (
    <Page
      id="badge-summary-page"
      loading={loading}
      error={hasError}
      backPath={{ to: routePaths.root }}
      centerElement={text.title}>

      {milestoneBadges ?
        <div className="badge-summary-cards">
          <Card>
            <div className="summary-metric-header">{text.milestones}</div>
            <div className="summary-metric-subheader">{text.taskCompleted}</div>

            <ul className="card-ul">
              {renderBadges(milestoneBadges)}
            </ul>
          </Card>
        </div> : null}
      {explorerBadges ?
        <div className="badge-summary-cards">
          <Card>
            <div className="summary-metric-header">{text.explorer}</div>
            <ul className="card-ul">
              {renderBadges(explorerBadges)}
            </ul>
          </Card>
        </div> : null}

      {challengeBadges ?
        <div className="badge-summary-cards">
          <Card>
            <div className="summary-metric-header">{text.monthly}</div>
            <ul className="card-ul">
              {renderBadges(challengeBadges)} {renderChallengeBadges(challengeBadges)}
            </ul>
          </Card>
        </div> : null}
    </Page >
  );

  function renderBadges(badges) {
    return (badges && badges.length > 0) ?
      ((badges
        .sort((b1, b2) => b2.achieved - b1.achieved || b1.badge.badgeType.sortOrder - b2.badge.badgeType.sortOrder)
        .map((item, index) => (
          item && (item.badge.badgeType.badgesType != BadgeType.ProgramChallenge) &&
          <li key={index}>
            <div className="summary-metricIcon"> {
              (item.achieved === true ) ? (
                <div className="badge-align color-badge ">
                  <NavIcon Icon={getBadgeIcon(item)}></NavIcon>
                  <span className="badge-summary-date">{ moment(item.achievedOn).format('MMM YYYY')}</span>
                </div>
              ) : (
                <div className="badge-align grey-badge">
                  <NavIcon Icon={getBadgeIcon(item)}></NavIcon>
                </div>
              )
            }
            </div>
          </li>

        ))
      ))   : null;
  }

  function renderChallengeBadges(badges){
    return (badgeIconsPath && badgeIconsPath.length > 0) ?
      (badgeIconsPath.map((item, index) => (
        item && (badges.find(x=>x.badgeIconUrl == item) != undefined ?
          <li>
            <div className="summary-metricIcon">
              <div className="badge-align color-badge ">
                <img key={index} className="program-challenge-badge" src={getBadgeIcon(badges.find(x=>x.badgeIconUrl ==item))}></img>
                <span className="badge-summary-date">{ moment(badges.find(x=>x.badgeIconUrl ==item).achievedOn).format('MMM YYYY')}</span>
              </div>
            </div>
          </li> :
          <li>
            <div className="summary-metricIcon">
              <div className="badge-align grey-badge">
                <img key={index} className="program-challenge-badge" src={getGreyBadgeIcon(item)}></img>
              </div>
            </div>

          </li> )
      ))) :  null;
  }
}
