import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from 'programs/programs-selectors';
import { loadProgram } from 'programs/programs-actions';

export default function useEnrollmentOutdated(enrollmentId) {
  const [returnValue, setReturnValue] = useState(false);
  const dispatch = useDispatch();

  const enrollmentInfo = useSelector(state => selectors.getProgramEnrollmentById(state, enrollmentId));

  var programId = enrollmentInfo ? enrollmentInfo.program.programId : null;
  const programInfo = useSelector(state => selectors.getProgramById(state, programId));
  const programStatus = useSelector(state => selectors.getProgramStatus(state, programId));

  useEffect(() => {
    if (enrollmentInfo) {
      let version = 0;
      if (programInfo && programInfo.upgradable) {
        version = programInfo.version;
      } else if (programStatus){
        if (!programStatus.loaded && !programStatus.loading) {
          dispatch(loadProgram(enrollmentInfo.program.programId));
        } else if (programStatus.loaded && programStatus.data.upgradable) {
          version = programStatus.data.version;
        }
      }
      setReturnValue(version > enrollmentInfo.program.version);
    }
  }, [enrollmentInfo, programInfo, programStatus]);
  return returnValue;
}
