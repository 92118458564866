import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { routePaths } from 'services/routing';
import local from 'services/localization/local';
import LoadingPage from 'shared/loading/LoadingPage';

export default function SignInLoading() {
  const isSignedIn = useSelector(state => state.identity.isSignedIn, shallowEqual);
  const signInError = useSelector(state => state.identity.signInError, shallowEqual);

  if (isSignedIn || signInError) {
    return <Redirect to={routePaths.root} />;
  }

  return (
    <div id="main-layout">
      <LoadingPage centerElement={local.signingIn} />
    </div>
  );
}
