import React from 'react';
import { ObservationType, PathwayChartsTabTame } from 'services/enums';
import StepsVisualizationContainer from 'health/observations/steps/visualization/StepsVisualizationContainer';
import PropTypes from 'prop-types';
import ProgramTabs from 'programs/list/ProgramTabs';
import NutritionPage from 'health/observations/nutrition/NutritionPage';
import BloodPressureVisualization from 'health/observations/blood-pressure/visualization/BloodPressureVisualization';
import BloodGlucoseVisualizationContainer from 'health/observations/blood-glucose/visualization/BloodGlucoseVisualizationContainer';

export function ProgramChartsContainer({observationTypes,pathwayStartDate,pathwayEndDate,routeParams,observations}) {
  const isStepsPresent = observationTypes && observationTypes.length >0 && observationTypes.includes(ObservationType.Steps);
  const isBloodGlucosePresent = observationTypes && observationTypes.length >0 && observationTypes.includes(ObservationType.BloodGlucose);
  const isNutritionPresent = observationTypes && observationTypes.length >0 && observationTypes.includes(ObservationType.Nutrition);
  const isBloodPressurePresent = observationTypes && observationTypes.length >0 && observationTypes.includes(ObservationType.Systolic);
  return (
    <div>
      <ProgramTabs className='tab-list-pathway' fromPathway>
        {isBloodGlucosePresent && observations.glucosePathwayData && observations.glucosePathwayData.length > 0 ?
          <div label={PathwayChartsTabTame.BloodGlucose} >
            <BloodGlucoseVisualizationContainer fromPathway bloodGlucose={observations.glucosePathwayData} isHighFrequencyData={observations.isHighFrequencyData} pathwayStartDate={pathwayStartDate} pathwayEndDate={pathwayEndDate} routeParams={routeParams} />
          </div> : '' }
        {isBloodPressurePresent &&  observations.systolicPathwayData && observations.systolicPathwayData.length > 0 ?
          <div label={PathwayChartsTabTame.BloodPressure} >
            <BloodPressureVisualization fromPathway systolicPathwayData={observations.systolicPathwayData} routeParams={routeParams} />
          </div> :''}
        {isNutritionPresent && observations.nutritionPathwayData && observations.nutritionPathwayData.length > 0 ?
          <div label={PathwayChartsTabTame.Nutrition} >
            <NutritionPage nutritions={observations.nutritionPathwayData} fromPathway pathwayStartDate={pathwayStartDate} pathwayEndDate={pathwayEndDate} />

          </div> : '' }
        { isStepsPresent && observations.stepsPathwayData && observations.stepsPathwayData.length > 0 ?
          <div label={PathwayChartsTabTame.Steps} >
            <StepsVisualizationContainer steps={observations.stepsPathwayData} fromPathway pathwayStartDate={pathwayStartDate} pathwayEndDate={pathwayEndDate} routeParams={routeParams}  />
          </div> : ''}

      </ProgramTabs>

    </div>
  );
}
ProgramChartsContainer.propTypes = {
  observationTypes: PropTypes.arrayOf(PropTypes.shape()),
  // eslint-disable-next-line react/forbid-prop-types
  observations:  PropTypes.object,
  pathwayStartDate:PropTypes.string,
  pathwayEndDate:PropTypes.string
};
