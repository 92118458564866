import React from 'react';
import { FilterToggle } from 'shared';
import TimelineDataTypeSelector from './TimelineDataTypeSelector';

export default function TimelineFilterToggle() {
  return (
    <FilterToggle
      renderBody={closeModal => (
        <TimelineDataTypeSelector closeModal={closeModal} />
      )} />
  );
}
