import React from 'react';
import PropTypes from 'prop-types';
import Redux from 'shared/react-redux-hooks';
import moment from 'moment';
import { getProfileId } from 'app/selectors';
import { ObservationType, Unit } from 'services/enums';
import { observationReset } from 'health/actions';
import { getObservationTypeSavingStatus } from 'health/observations/observation-selectors';
import * as healthActions from 'health/action-creators';
import LogBodyWaistForm from './LogBodyWaistForm';

export function LogBodyWaist({ handleBack, pathwayContext }) {
  const dispatch = Redux.useDispatch();
  const profileId = Redux.useSelector(getProfileId);
  const saving = Redux.useSelector((state) => getObservationTypeSavingStatus(state, ObservationType.BodyWaist));

  React.useEffect(() => {
    return () => {
      dispatch(observationReset(ObservationType.BodyWaist));
    };
  }, [profileId, dispatch]);


  const handleSubmit = React.useCallback(
    ({ bodyWaistValue, notes, timestamp }) => {
      dispatch(healthActions.saveObservation({
        profileId,
        value: bodyWaistValue,
        observationType: ObservationType.BodyWaist,
        unit: Unit.Inches,
        notes,
        timestamp
      }));
    },
    [profileId]);

  const currentTimestamp = moment().format(moment.HTML5_FMT.DATETIME_LOCAL);
  const initialValues = { timestamp: currentTimestamp };

  return (
    <LogBodyWaistForm
      pathwayContext={pathwayContext}
      profileId={profileId}
      onSubmit={handleSubmit}
      handleBack={handleBack}
      saving={saving}
      initialValues={initialValues} />
  );
}

LogBodyWaist.propTypes = {
  handleBack: PropTypes.func.isRequired,
  pathwayContext: PropTypes.shape()
};

export default LogBodyWaist;
