import React from 'react';
import { useSelector } from 'react-redux';
import local from 'services/localization/local';
import styleVars from 'styles/_variables.scss';
import { routePaths } from 'services/routing';
import { Icon } from 'shared';
import LilChart from 'health/lil-charts/LilChart';
import { getStepsLilChartData } from 'health/observations/steps/steps-selectors';
import { ObservationType } from 'services/enums';
import { configIds } from 'health/observations/observation-config-ids';
import useObservationData from 'health/observations/hooks/useObservationData';

export default function StepsLilChart() {
  const stepsLimit = 7; // one observation for everday in the past week
  const [loading, loadError] = useObservationData(ObservationType.Steps, stepsLimit);
  const { steps, dailySteps } = useSelector(state => getStepsLilChartData(state));
  const isTrackingSteps = dailySteps > 0;
  const path = { to: routePaths.healthObservation, params: { configId: configIds.steps } };

  if (isTrackingSteps) {
    return (
      <LilChart
        metricIcon={<Icon symbol="steps" className="hero" size="3em" />}
        color={styleVars.colorTeal}
        data={steps}
        noDataText={local.observation.steps.noDataText}
        title={local.observation.steps.tileTitle}
        metricValue={dailySteps}
        metricUnits={local.units.steps}
        path={path}
        noDataPath={path}
        loading={loading}
        loadError={loadError} />
    );
  }
  return null;
}
