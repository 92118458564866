import React from 'react';
import { isAndroid, isIOS } from 'mobile/helpers';
import { Icon } from 'shared';

export default function ShareIcon({ ...props }) {
  let symbol = 'sharesocialbrowser';
  if (isAndroid) {
    symbol='sharesocialandroid';
  } else if (isIOS) {
    symbol='sharesocialiosrounded';
  }

  return <Icon size="1.5em" className="action-icon" {...props} symbol={symbol} />;
}
