import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import local from 'services/localization/local';
import { Button, Icon, Avatar, AvatarCard } from 'shared';
import { revokeAccessAsOwner } from 'profiles/sharing/sharing-actions';
import { getIdentityId } from 'identity/selectors';
import RevokeAccessModal from 'profiles/sharing/revoke-access-modal/RevokeAccessModal';

export default function CurrentAccessListItem({ item }) {
  const formattedDate = item.createdAt ? moment(item.createdAt).format(local.simpleDateFormat) : null;
  const sinceText = local.formatString(local.sharing.accessSince, formattedDate);
  const dispatch = useDispatch();
  const identityId = useSelector(getIdentityId);
  const isOwned = item.id === identityId;

  const [showModal, setShowModal] = useState(false);
  const triggerModal = useCallback(() => setShowModal(!showModal), [showModal]);
  const handleRevoke = useCallback(() => {
    dispatch(revokeAccessAsOwner(item.id));
    triggerModal();
  }, []);

  const avatar = <Avatar profile={{ name: item.name, theme: item.profileTheme }} />;
  const revokeButton = (<Button id={`revoke-button-${item.id}`} color='transparent' data-testid='revoke' onClick={triggerModal}><Icon symbol='clear' /><span>{local.sharing.revoke}</span></Button>);
  const revokeAction = !isOwned ? (
    <div className='button-container'>
      {showModal &&
        <RevokeAccessModal
          handleYes={handleRevoke}
          handleNo={triggerModal}
          name={item.name}
          modalTitle={local.sharing.revokeModalTitle}
          modalText={local.sharing.revokeModalText}
          deleteYes={local.sharing.revokeModalDeleteYes}
          deleteNo={local.sharing.revokeModalDeleteNo} />}
      {revokeButton}
    </div>
  ) : null;

  return (
    <AvatarCard
      avatar={avatar}
      actionButtons={revokeAction}
      heading={item.name}
      subheading={sinceText} />
  );
}

CurrentAccessListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    createdAt: PropTypes.string,
    name: PropTypes.string,
    profileTheme: PropTypes.number
  })
};
