export const types = {
  ORGANIZATIONS: 'organizations',
  ORGANIZATION_DETAILS: 'organizationDetails',
  ORGANIZATION_REQUESTS: 'organizationRequests',
  ORGANIZATION_INVITES: 'organizationInvites',
  ORGANIZATION_INVITES_COUNT: 'organizationInvitesCount',
  ORGANIZATION_ADMINS: 'organizationAdmins',
  ORGANIZATION_MEMBER_TYPE: 'organizationMemberType',
  ORGANIZATION_RECENT_PENDING_INVITE :'organizationRecentPendingInvite',
  ORGANIZATION_WHITE_LABEL : 'organizationWhiteLabel'
};
