import React from 'react';
import './loading.scss';

function LoadingOverlay() {
  return (
    <React.Fragment>
      <div className="loading-overlay" />
      <div className="loading" />
    </React.Fragment>
  );
}

export default LoadingOverlay;
