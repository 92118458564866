import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resendVerificationEmail } from 'identity/action-creators';
import { Button } from 'shared';
import local from 'services/localization/local';

export default function VerifyEmailButton() {
  const dispatch = useDispatch();
  const resend = useCallback(() => dispatch(resendVerificationEmail()), []);

  return (
    <Button onClick={resend} id="link-to-resend" className="btn btn-primary" color="blue" data-testid='verify-button'>{local.resendVerification}</Button>
  );
}
