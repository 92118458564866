/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { routePaths } from 'services/routing';
import { Loading, NoDataPlaceholder, Page } from 'shared';
import local from 'services/localization/local';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmentList } from './appointment-selector';
import AppointmentListItem from './AppointmentListItem';
import { loadAppointmentList } from './action-creators';
import { getProfileId } from 'app/selectors';

export default function AppointmentList(props){
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const { data, loading, hasError } = useSelector(state => getAppointmentList(state));

  useEffect(()=>{
    dispatch(loadAppointmentList(profileId));
  },[profileId]);

  const renderItems = () => {
    if(loading){
      return(<Loading></Loading>);
    }
    if(!data || data?.length === 0 || hasError){
      return(<NoDataPlaceholder>{local.appointment.noRecords}</NoDataPlaceholder>);
    }
    return (data && data.length > 0 &&
          data.map((x) => <AppointmentListItem key={x.id} appointment={x}></AppointmentListItem>)
    );
  };

  return(
    <Page
      id='appointment-list'
      backPath={{to:props.location?.state?.backpath ?? routePaths.root}}
      centerElement={local.appointment.events}>
      <ul className='card-list'>
        {renderItems()}
      </ul>
    </Page>
  );
}
