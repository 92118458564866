import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import local from 'services/localization/local';
import { getOrganizationTypeById } from 'organizations/organizations-selectors';
import { getProfileId } from 'app/selectors';
import { routePaths } from 'services/routing';
import { LinkWrapper } from 'shared';

export default function RecommendedProgramTile({ organizationId, program }) {
  const { name, programId } = program;
  const { data } = useSelector(state => getOrganizationTypeById(state, organizationId));
  const profileId = useSelector(state => getProfileId(state));
  const subText = data ? local.formatString(local.organizations.recommendedByOrg, data?.name) : null;

  return (
    <div className='program-dashboard-tile'>
      <div className='loaded'>
        <LinkWrapper
          id={`program-tile-details-${program.programId}`}
          className="program-tile-details-link"
          logName='program-tile-details'
          to={routePaths.organizationsJoinProgram}
          params={{ organizationId, programId, profileId }}>
          <div className='chart-title program-name'>{name}</div>
          <div className='program-tile-progress-text'>
            <div className='recommended-program-info'>
              <div className='program-tile-recommended-by'>{subText}</div>
              <div className="program-tile-recommended-action">{local.organizations.learnMore}</div>
            </div>
          </div>
        </LinkWrapper>
      </div>
    </div>
  );
}

RecommendedProgramTile.propTypes = {
  organizationId: PropTypes.string.isRequired,
  program: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    programId: PropTypes.string.isRequired
  })
};
