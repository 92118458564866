import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isMobile, openRouteInApp } from 'mobile/helpers';
import { logClick } from 'services/appinsights-service';

class ExternalLink extends Component {
  constructor(props) {
    super(props);
    this.logEvent = this.logEvent.bind(this);
  }

  logEvent() {
    const { id, logName, href } = this.props;
    logClick(`link-${logName || id}`);

    if (isMobile) {
      openRouteInApp(href);
    }
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { id, logName, href, openNewTab, title, children, rel, ...rest } = this.props;
    const linkrel = openNewTab ? rel : undefined;
    const target = openNewTab ? '_blank' : undefined;
    const linkPath = isMobile ? undefined : href;

    return (
      <a
        {...rest}
        rel={linkrel}
        target={target}
        title={title}
        onClick={this.logEvent}
        id={id}
        href={linkPath}>
        {children}
      </a>
    );
  }
}

ExternalLink.propTypes = {
  id: PropTypes.string.isRequired,
  logName: PropTypes.string,
  href: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  openNewTab: PropTypes.bool,
  rel: PropTypes.string
};

ExternalLink.defaultProps = {
  rel: 'noopener noreferrer'
};

export default ExternalLink;
