import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { momentCalendarDayFormat } from 'services/date-service';
import local from 'services/localization/local';

export default function ProgramDashboardTileDetails({ progress }) {
  const { endDateTime, currentDay, totalDays, tasksComplete, currentTasksComplete, tasksTotal, readyToComplete } = progress;

  if (!readyToComplete && !currentTasksComplete) {
    return null;
  }

  else {
    const tasksFraction = readyToComplete ? local.programs.readyToComplete :
      local.formatString(local.programs.detailsTasksComplete, tasksComplete, tasksTotal);

    const now = moment();
    let daysProgress = null;

    if (readyToComplete && now.isSameOrBefore(endDateTime)) {
      daysProgress = local.formatString(local.programs.detailsEndingDate, moment(endDateTime).calendar(null, momentCalendarDayFormat));
    } else if (readyToComplete) {
      daysProgress = local.formatString(local.programs.detailsEndedOnDate, moment(endDateTime).calendar(null, momentCalendarDayFormat));
    } else {
      daysProgress = local.formatString(local.programs.detailsDayProgress, currentDay, totalDays);
    }

    return (
      <div className='program-tile-progress-text'>
        <span className='program-tile-tasks-progress'>{tasksFraction}</span>
        <span className='program-tile-days-progress'>{daysProgress}</span>
      </div>
    );
  }
}

ProgramDashboardTileDetails.propTypes = {
  progress: PropTypes.shape({}).isRequired
};
