import React, { useEffect, useState } from 'react';
import { Page, Button } from 'shared';
import { routePaths } from 'services/routing';
import { Redirect } from 'react-router-dom';
import './ehr.scss';
import { getSubscription, SubsriptionType } from 'preferences/billing/Subscription';
import { useSelector } from 'react-redux';
import { getIsOrgMember, getSSN } from 'identity/selectors';
import IdMeVerificationErrorModal from './IdMeVerificationErrorModal';
import MedicalDataTable from './MedicalDataTable';
const env = window.env || {};

export default function EHR(props) {
  const [redirect, setRedirect] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const currentSubscription = getSubscription();
  const ssn = useSelector(getSSN);
  const isOrgMember = useSelector(getIsOrgMember);
  //const isIdMeAuth = localStorage.getItem('isIdMeAuth') === 'true';
  const isFreetire = currentSubscription.type == SubsriptionType.Free;

  function doPayment() {
    setRedirect(true);
  }

  function closeConfirm() {
    setIsConfirmOpen(!isConfirmOpen);
  }

  function verifyIAL2() {
    localStorage.setItem('IAL2Verification', true);
    if (window.location.search) {
      localStorage.setItem('IAL2VerificationRedirect', window.location.href.replace(window.location.search, ''));
    } else {
      localStorage.setItem('IAL2VerificationRedirect', window.location.href);
    }
    window.location.href = env.Idme_verificationurl;
    //'https://api.idmelabs.com/oauth/authorize?client_id=b88a4c79755cbc8509cd63c777179291&redirect_uri=https://dev-owen.myhc.app/&response_type=code&scope=openid%20http://idmanagement.gov/ns/assurance/ial/2/aal/2';
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const error = urlParams.get('error');
    if (error && error.includes('access_denied')) {
      setIsConfirmOpen(true);
    }
  }, []);
  console.log('isFreetire', isFreetire, 'ssn', ssn);
  return (
    <Page
      id='invoice-list'
      backPath={{ to: props.location?.state?.backpath ?? routePaths.root }}
      centerElement='Electronic Health Records'>
      <div id="noDataPlaceholder" className="no-data-placeholder">
        {isFreetire && !isOrgMember ?
          <div>
            <div>
              Upgrade your subscription to access your electronic health records from your hospitals and healthcare providers!
            </div>
            <div className='form-row claim-offer-btn upgrade-button'>
              <Button id='subscribe-button' className="button blue btn btn-primary" onClick={doPayment}>Upgrade</Button>
            </div>
          </div> : null}
        {
          (!isFreetire || isOrgMember) && !ssn ?
            <div>
              <div>
                To ensure that your health data and privacy are fully protected and secure, we must verify your identity to NIST-IAL2 standards, which will require contact information, a government-issued photo ID and a smart phone. We use ID.me to conduct this easy, but necessary process.
              </div>
              <div className='form-row claim-offer-btn upgrade-button' style={{ cursor: 'pointer' }}>
                <img onClick={verifyIAL2} height={45} src="/static/images/verifyidme.svg" alt="android iphone verifyidme" />
              </div>
            </div> : null
        }
        {ssn ? <div><div style={{ fontSize: 'larger', fontWeight: 'bolder', color: 'darkred' }}>Lab Results</div><MedicalDataTable /> </div> : null}
        {isConfirmOpen ? <IdMeVerificationErrorModal isConfirmOpen={isConfirmOpen} closeConfirm={closeConfirm} /> : null}
      </div>
      {redirect ? <Redirect to='/view-plan' /> : null}
    </Page>
  );
}
