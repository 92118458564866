import React, { Component } from 'react';
import { LinkWrapper, NonAuthenticatedPage } from 'shared';
import local from 'services/localization/local';

class RegistrationThankYou extends Component {
  render() {
    return (
      <NonAuthenticatedPage id="register-thank-you" topChevronColor='yellow' bottomChevronColor='teal' titleTabText={local.registerThankYou}>
        <div className='shadow'>
          {/* <h3>{local.registerThankYou}</h3> */}
          <div>{local.registerConfirmEmail}</div>
          <br />
          <LinkWrapper id="link-to-sign-in" className="button blue btn btn-primary w-100" to="/">{local.linkSignIn}</LinkWrapper>
        </div>
      </NonAuthenticatedPage>
    );

  }
}

export default RegistrationThankYou;

