import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, withRouter } from 'react-router';
import withNavigation from 'app/withNavigation';
import { routePaths, buildPath } from 'services/routing';
import { Page, ProfileRedirect } from 'shared';
import { getConfigForObservationType } from 'health/observations/observation-configs';
import { stateKeyMap } from 'health/health-reducer';
import { loadProgramDetails } from 'programs/programs-actions';
import * as selectors from 'programs/programs-selectors';
import { ObservationType } from 'services/enums';

class RecordProgramData extends React.Component {
  constructor(props) {
    super(props);
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    const { actions, programEnrollmentId, pathwayContextStatus } = this.props;

    if (!pathwayContextStatus.loaded && pathwayContextStatus.required) {
      actions.loadProgramDetails(programEnrollmentId);
    }
  }

  handleBack() {
    const { navigate, profileId, programId, programEnrollmentId } = this.props;
    navigate({ to: routePaths.programsDetails, params: { profileId, programId, programEnrollmentId } });
  }

  render() {
    const {
      complete,
      profileId,
      programId,
      programEnrollmentId,
      observationType,
      pathwayContextStatus
    } = this.props;
    if (complete) {
      return <Redirect to={buildPath(routePaths.programsDetails, { profileId, programId, programEnrollmentId })} />;
    }
    const config = getConfigForObservationType(observationType);

    if(observationType === ObservationType.Exercise.toString()){
      return <ProfileRedirect to={routePaths.healthActivityAdd} backpath={{pathwayContextStatus:pathwayContextStatus, fromPath:buildPath(routePaths.programsDetails, { programId: programId, programEnrollmentId: programEnrollmentId })}} />;
    }

    if (!config.renderLogComponent) {
      return <Redirect to={buildPath(routePaths.programsViewData, { profileId, programId, programEnrollmentId, observationType })} />;
    }

    return (
      <Page
        id='program-record-data'
        readOnlyProfile
        centerElement={config.centerElement}
        titleTabText={config.logcenterElement}
        loading={!pathwayContextStatus.loaded || pathwayContextStatus.loading}
        error={pathwayContextStatus.error}
        backPath={{ to: routePaths.programsDetails, params: { profileId, programId, programEnrollmentId } }}>
        {config.renderLogComponent({ handleBack: this.handleBack, pathwayContext: pathwayContextStatus.context })}
      </Page>
    );
  }
}
RecordProgramData.propTypes = {
  complete: PropTypes.bool.isRequired,
  profileId: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
  programEnrollmentId: PropTypes.string.isRequired,
  observationType: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
  pathwayContextStatus: PropTypes.shape(),
  actions: PropTypes.shape()
};

function mapStateToProps(state, ownProps) {
  const { programId, programEnrollmentId, observationType, taskId } = ownProps.match.params;
  const pathwayContextStatus = selectors.getPathwayTaskContextWithStatus(state, programEnrollmentId, taskId);

  return {
    complete: state.health[stateKeyMap[observationType]].saved,
    profileId: state.selectedProfile.id,
    programId,
    programEnrollmentId,
    observationType,
    pathwayContextStatus
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { loadProgramDetails };
  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

export default withNavigation(withRouter(connect(mapStateToProps, mapDispatchToProps)(RecordProgramData)));
