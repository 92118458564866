import React from 'react';
import ProfilesRoutes from './ProfilesRoutes';

class ProfileLayout extends React.Component {
  render() {
    return <ProfilesRoutes />;
  }
}

ProfileLayout.displayName = 'Profiles Layout';

export default ProfileLayout;
