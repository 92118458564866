import moment from 'moment';
import * as profilesApi from 'services/api/profiles-api';
import { OrganizationMemberStatus, OrganizationMemberRole, OrganizationFeatureType } from 'services/enums';
import * as persistentDataActions from 'app/request/persistent-data-actions';
import * as requestQueueActions from 'app/request/request-queue-actions';
import * as requestActions from 'app/request/request-actions';
import { types } from './organizations-types';
import local from 'services/localization/local';
import { loadProgramEnrollments } from 'programs/programs-actions';
import { getOrganizationWhiteLabels } from 'services/api/profileViewData-api';
import { loadChatOrganizationDetail } from 'chat/action-creators';

export const actionNames = {
  MEMBERSHIP_DELETE: 'MEMBERSHIP_DELETE',
  MEMBERSHIP_ADD: 'MEMBERSHIP_ADD'
};

export const actions = {
  removeMembership: (orgId, membershipId) => ({
    type: actionNames.MEMBERSHIP_DELETE,
    payload: {
      orgId,
      membershipId
    }
  }),
  addMembership: (newMembership) => ({
    type: actionNames.MEMBERSHIP_ADD,
    payload: newMembership
  })
};

export function createOrganization({ requestId, name, description }) {
  return (dispatch) => {
    const action = state => profilesApi.createOrganization({ name, description }, state);

    return dispatch(requestQueueActions.queueRequestWithToast(
      action,
      types.ORGANIZATION_REQUESTS,
      requestId
    ));
  };
}

export function loadActiveOrganizations() {
  return (dispatch, getState) => {
    const identityId = getState().identity.self.id;
    const action = state => profilesApi.getOrganizations([OrganizationMemberStatus.Active], [OrganizationMemberRole.Member], state)
      .then(result => {
        // Multiple orgs may be returned for a single identity if they have more than one role or multiple profiles in the organization
        const orgs = Object.values(result.results.reduce((accum, org) => {
          accum[org.id] = { id: org.id, name: org.name, isCovidOrg: org.isCovidOrganization };
          return accum;
        }, {}));
        return orgs;
      });

    return dispatch(persistentDataActions.request(action, types.ORGANIZATIONS, identityId));
  };
}

export function loadOrganizations() {
  return (dispatch, getState) => {
    const identityId = getState().identity.self.id;
    const action = state => profilesApi.getOrganizations([OrganizationMemberStatus.Active, OrganizationMemberStatus.Removed, OrganizationMemberStatus.Pending], [OrganizationMemberRole.Member], state)
      .then(result => {
        // Multiple orgs may be returned for a single identity if they have more than one role or multiple profiles in the organization
        const orgs = Object.values(result.results.reduce((accum, org) => {
          accum[org.id] = { id: org.id, name: org.name, organizationStatus: org.status, memberStatus: org.memberStatus, isIndependentMember: org.isIndependentMember, orgParentId: org?.parentOrganization?.id, orgRootId: getRootOrganizationId(org), planPrice: getPlanPrice(org) };
          return accum;
        }, {}));
        return orgs;
      });
    return dispatch(persistentDataActions.request(action, types.ORGANIZATION_MEMBER_TYPE, identityId));
  };
}

export function getPlanPrice(organization){
  const subscription = organization?.organizationFeatures?.subscriptionByProduct;
  return  Object.values(subscription)[0]?.pricingPlan;
}

export function getRootOrganizationId(organization){
  if(organization.parentOrganization && organization.parentOrganization.id != organization.id){
    const organizationId = getRootOrganizationId(organization.parentOrganization);
    if(organizationId)
      return organizationId;
  }
  else{
    return organization.id;
  }
}

export function loadOrganizationDetails(organizationId) {
  return (dispatch) => {
    const action = state => profilesApi.getOrganizationMembersByIdentity([OrganizationMemberStatus.Active], [OrganizationMemberRole.Member], organizationId, state)
      .then(response => response.results);
    return dispatch(persistentDataActions.request(action, types.ORGANIZATION_DETAILS, organizationId));
  };
}

export function removeFromOrg(organizationMembershipId, orgId, organizationName, profileId) {
  return (dispatch) => {
    const toastMessages = {
      processingMessage: local.formatString(local.organizations.removingOrg, organizationName),
      successMessage: local.formatString(local.organizations.removedOrg, organizationName)
    };

    const onSuccess = () => {
      dispatch(actions.removeMembership(orgId, organizationMembershipId));
      dispatch(loadChatOrganizationDetail(profileId, OrganizationFeatureType.Chat));
    };

    const action = state => profilesApi.leaveOrganization(organizationMembershipId, orgId, state)
      .then(onSuccess);

    return dispatch(requestQueueActions.queueRequestWithToast(action, types.ORGANIZATION_REQUESTS, organizationMembershipId, toastMessages));
  };
}

export function loadOrganizationInvites() {
  return (dispatch, getState) => {
    const identityId = getState().identity.self.id;
    const action = state => profilesApi.getOrganizationInvites(state);
    return dispatch(persistentDataActions.request(action, types.ORGANIZATION_INVITES, identityId));
  };
}

export function loadRecentPendingInvite(){
  return(dispatch,getState)=>{
    const identityId = getState().identity.self.id;
    const action = state => profilesApi.getRecentPendingInviteOrganizationMember(state);
    return dispatch(persistentDataActions.request(action, types.ORGANIZATION_RECENT_PENDING_INVITE, identityId));
  };
}

export function loadOrganizationInvitesCount() {
  return (dispatch, getState) => {
    const identityId = getState().identity.self.id;
    const action = state => profilesApi.getOrganizationInvitesCount(state);
    return dispatch(persistentDataActions.request(action, types.ORGANIZATION_INVITES_COUNT, identityId));
  };
}

export function loadOrganizationAdmins(organizationId) {
  return (dispatch) => {
    const action = state => profilesApi.getOrganizationAdmins(organizationId, state);
    return dispatch(persistentDataActions.request(action, types.ORGANIZATION_ADMINS, organizationId));
  };
}

export function respondToInvite(action, requestId, toastMessages, model) {
  return requestQueueActions.queueRequestWithToast(
    action,
    types.ORGANIZATION_REQUESTS,
    requestId,
    toastMessages,
    model
  );
}

export function acceptOrganizationInvite(requestId, organizationId, organizationMembershipId, profileId, permissions, isPathwayInvite = false) {
  return (dispatch) => {
    const utcOffsetMinutes = moment().utcOffset();
    const model = {
      organizationMembershipId,
      profileId,
      acceptedInvite: true,
      permissions,
      utcOffsetMinutes
    };

    const toastMessages = {
      processingMessage: local.organizations.accepting,
      successMessage: local.organizations.accepted
    };

    const onSuccess = newMembership => {
      if(!isPathwayInvite)
      {
        dispatch(actions.addMembership(newMembership));
      }
      else
      {
        dispatch(loadProgramEnrollments(profileId));
      }
    };

    const action = state => profilesApi.respondToOrganizationInvite(state, organizationId, model).then(onSuccess);

    return dispatch(respondToInvite(action, requestId, toastMessages, model));
  };
}

export function declineOrganizationInvite(requestId, organizationId, organizationMembershipId, profileId, permissions) {
  return (dispatch) => {
    const utcOffsetMinutes = moment().utcOffset();
    const model = {
      organizationMembershipId,
      acceptedInvite: false,
      profileId,
      permissions,
      utcOffsetMinutes
    };

    const toastMessages = {
      processingMessage: local.organizations.declining,
      successMessage: local.organizations.declined
    };

    const action = state => profilesApi.respondToOrganizationInvite(state, organizationId, model);
    return dispatch(respondToInvite(action, requestId, toastMessages, model));
  };
}

export function submitInviteCode(inviteCode, requestId) {
  return (dispatch) => {
    const checkForInviteCodeConflict = error => {
      if (error.status === 409   && error.body.organizationMemberStatus === OrganizationMemberStatus.Pending) {
        return dispatch(requestActions.requestError(requestId, error, {}));
      }
      if(error.status === 409   && error.body.organizationMemberStatus === OrganizationMemberStatus.Active)
      {
        dispatch(requestActions.requestError(requestId, error, {}));
      }
      throw error;
    };
    const action = state => profilesApi.submitOrganizationInviteCode(state, inviteCode)
      .catch(checkForInviteCodeConflict);

    return dispatch(requestActions.request(action, requestId, {}, {
      showToasts: true,
      processingMessage: local.organizations.inviteCodeSearching,
      successMessage: local.organizations.inviteCodeFound
    }));
  };
}

export function loadWhiteLabels(profileId, organizationId) {
  return (dispatch, getState) => {
    const identityId = getState().identity.self.id;
    const action = state => getOrganizationWhiteLabels(state, profileId, organizationId);
    return dispatch(persistentDataActions.request(action, types.ORGANIZATION_WHITE_LABEL, identityId));
  };
}


