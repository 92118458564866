import React from 'react';
import ProgramTile from 'programs/enrollment/ProgramTile';
import { ShareButton } from 'shared';
import { socialShareLinks } from 'services/constants';
import local from 'services/localization/local';
export function ProgramDetailsHeaderContainer({program,programName,details,styleName}) {
  return (
    <section className={styleName}>
      <ProgramTile program={program} details={details} />
      <ShareButton
        url={socialShareLinks.pathwayGeneral}
        title={local.programs.programShareTitle}
        text={local.formatString(local.programs.programShareText, programName)}
        dialogTitle={local.programs.programShareDialogTitle} />
    </section>
  );
}
