export const types = {
  CONDITIONS: 'conditions',
  ENCOUNTERS: 'encounters',
  IMMUNIZATIONS: 'immunizations',
  LAB_RESULTS: 'labResults',
  PROCEDURES: 'procedures',
  IMPORT_COUNTS: 'importCountSummaries',
  VACCINATION_PROOF: 'vaccinationProof',
  RESOURCE_CONTENT_RATING: 'resourceContentRating',
  TRACK_STEPS: 'trackSteps',
  CURRENT_TARGET: 'currentTarget',
  DASHBOARD_OBSERVATIONS: 'dashboardObservations',
  DASHBOARD_HEALTH_RECORDS: 'dashboardHealthRecords',
  HEALTH_DATA_RECORDS:'heathDataRecords',
  HEALTH_SCORE_TRENDS: 'healthScoreTrends',
  CARE_PLAN: 'carePlan',
  LAB_TRENDS: 'labTrends',
  NUTRITION_GOALS: 'nutritionGoals',
  GLUCOSE_GOALS: 'glucoseGoals',
  LAB_ATTACHMENTS: 'labAttachments',
  UNREAD_MEDICAL_RECORDS_COUNT: 'unreadMedicalRecordsCount',
  RESET_UNREAD_MEDICAL_RECORDS_COUNT: 'resetUnreadMedicalRecordsCount',
  SYSTOLIC_GOALS: 'systolicGoals',
  DIASTOLIC_GOALS: 'diastolicGoals',
  HEALTH_DATA_VITALS_OBSERVATIONS:'healthdataVitalObservations',
  HIGH_FREQUENCY_OBSERVATIONS:'highFrequencyObservations',
  PATHWAY_HEALTH_RECORDS:'pathwayHealthRecords'
};
