import * as requestQueueSelectors from 'app/request/request-queue-selectors';
import * as dataSelectors from 'app/request/persistent-data-selectors';
import { getErrorCodeText } from 'services/helpers';
import { compareDatesDescending } from 'services/date-service';
import { types } from './sharing-types';

export const getCreateInviteErrorMessage = (state, requestId) => {
  const request = requestQueueSelectors.getRequestById(state.sharing, types.INVITE_REQUESTS, requestId);
  return getErrorCodeText(request.error);
};

export const getCreateInviteRequestSuccess = (state, requestId) => {
  return !!(state.sharing[types.INVITE_REQUESTS][requestId] && state.sharing[types.INVITE_REQUESTS][requestId].success);
};

export const getIncomingInvites = state => {
  const invites = state.identity.self && dataSelectors.getData(state.sharing, types.INCOMING_PROFILE_INVITES, state.identity.self.id) || [];
  return invites.sort((a, b) => compareDatesDescending(a.createdAt, b.createdAt));
};

export const getIncomingInvitesCount = state => {
  const identityId = state.identity && state.identity.self.id;
  const count = dataSelectors.getData(state.sharing, types.INCOMING_PROFILE_INVITES_COUNT, identityId) || 0;
  return count;
};

export const isIncomingInviteLoading = state => {
  return !!(state.identity.self && dataSelectors.isLoading(state.sharing, types.INCOMING_PROFILE_INVITES, state.identity.self.id));
};

export const isIncomingInviteLoadError = state => {
  return !!(state.identity.self && dataSelectors.hasError(state.sharing, types.INCOMING_PROFILE_INVITES, state.identity.self.id));
};

export const getInviteStatuses = state => {
  const invites = state.selectedProfile && dataSelectors.getData(state.sharing, types.INVITE_STATUSES, state.selectedProfile.id) || [];
  return invites;
};

export const getOutgoingInvites = state => {
  const invites = getInviteStatuses(state);
  return invites
    .filter(x => !x.isActive)
    .sort((a, b) => compareDatesDescending(a.createdAt, b.createdAt));
};

export const getRelationshipsWithAccess = state => {
  const invites = getInviteStatuses(state);
  return invites
    .filter(x => x.isActive)
    .sort((a, b) => compareDatesDescending(a.createdAt, b.createdAt));
};

export const isInviteStatusesLoading = state => {
  return !!(state.selectedProfile && dataSelectors.isLoading(state.sharing, types.INVITE_STATUSES, state.selectedProfile.id));
};

export const isInviteStatusesLoadError = state => {
  return !!(state.selectedProfile && dataSelectors.hasError(state.sharing, types.INVITE_STATUSES, state.selectedProfile.id));
};

export const isNothingToShow = state => {
  return !isInviteStatusesLoading(state) && !isInviteStatusesLoadError(state) && getInviteStatuses(state).length === 0;
};
