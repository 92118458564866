import React from 'react';
import PropTypes from 'prop-types';
import { Icon, ConfirmModal, Loading, CircleDotFigure } from 'shared';
import { connect } from 'react-redux';
import { ObservationType, FilePreviewAction } from 'services/enums';
import local from 'services/localization/local';
import { loadBlobData } from 'health/action-creators';
import moment from 'moment';
import { momentCalendarDateTimeFormat } from 'services/date-service';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'health/observations/lab-results/listing/LabResultListItem.scss';

class LabResultListItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
    this.triggerModal = this.triggerModal.bind(this);
    this.state = {
      uploadedFile: [],
      textTitle: [],
      showPreviewModal: false,
      showDeleteModal: false,
      previewIndex: 0,
      previewFile: '',
      image: 'data:image/png;base64,',
      blobData: []
    };
  }

  componentDidMount() {
    let { observation } = this.props;
    let newBlobData = [];
    let blobUrls = [];
    if (observation != null && observation.attachedFileData != undefined && observation.attachedFileData != null && observation.attachedFileData != '') {
      this.uploadedFile = JSON.parse(observation.attachedFileData);
      this.profileId = observation.profileId;
    }
    if (this.uploadedFile != undefined) {
      for (let z = 0; z < this.uploadedFile.length; z++) {
        if (this.uploadedFile[z].BlobURL !== undefined) {
          blobUrls.push(this.uploadedFile[z].BlobURL);
        }
      }
    }
    if (blobUrls.length > 0) {
      // eslint-disable-next-line react/prop-types
      let result = this.props.dispatch(loadBlobData(this.profileId, blobUrls)).then(response => Promise.resolve(response.payload.data));
      result.then(function (value) {
        if (value !== undefined) {
          for (let i = 0; i < value.length; i++) {
            let fileData = {};
            fileData.blobUrl = value[i].blobUrl;
            fileData.blobData = value[i].blobData;
            newBlobData.push(fileData);
          }
        }
      }).then(() => {
        this.setState({ blobData: newBlobData });
      });
    }
  }

  handleDelete() {
    let deleteFileDataModel = {};
    if (Number(this.props.observation.observationType) == ObservationType.LabResult) {
      let deleteFiles = [];
      deleteFileDataModel.DeleteAll = true;
      const fileData = JSON.parse(this.props.observation.attachedFileData);
      for (let z = 0; z < fileData.length; z++) {
        let blobUrlObj = {};
        blobUrlObj.BlobUrl = fileData[z].FileName;
        deleteFiles.push(blobUrlObj);
      }
      let deleteFileData = JSON.stringify(deleteFiles);
      deleteFileDataModel.DeleteFileData = deleteFileData;
    }
    this.triggerModal();
    this.props.config.deleteData(this.props, deleteFileDataModel);
  }

  previewImage(attachedFileData, index) {
    let data = {};
    data.blobData = attachedFileData[index].blobData;
    data.Tags = attachedFileData[index].Tags;
    this.setState({
      showPreviewModal: true,
      previewFile: data.blobData,
      previewIndex: index,
      textTitle: data.Tags,
      uploadedFile: attachedFileData
    });
  }

  actionPreviewImage(previewIndex, action) {
    if (action ===  FilePreviewAction.Previous) {
      if (previewIndex > 0) {
        this.previewImage(this.state.uploadedFile, previewIndex - 1);
      }
    }
    if (action ===  FilePreviewAction.Next) {
      this.previewImage(this.state.uploadedFile, previewIndex + 1);
    }
  }

  previewImageClose() {
    this.setState({
      showPreviewModal: false
    });
  }

  triggerModal() {
    this.setState(prevState => ({ showDeleteModal: !(prevState.showDeleteModal) }));
  }

  loadLabResults(attachedFileData) {
    if (attachedFileData.length > 0) {
      let uploadedData = [];
      attachedFileData.map((item) => {
        this.state.blobData.map((data) => {
          if (item.BlobURL.includes(data.blobUrl)) {
            uploadedData.push(Object.assign([{}], item, data));
          }
        });
      });
      return uploadedData.map((fileInfo, index) => (
        // eslint-disable-next-line react/jsx-no-bind
        <div key={'main_' + index.toString()} className='labResult-preview-loop' onClick={this.previewImage.bind(this, uploadedData, index)}>
          <div className="labResult-imgcontainer" id={'img_' + index}>
            <img className="labResult-preview" id={'preview_' + index} src={this.state.image + fileInfo.blobData} alt='' />
          </div>
          <div className="labResult-text-title" id={'text_' + index}>{fileInfo.Tags}</div>
        </div>
      ));
    }
  }

  render() {
    let { observation } = this.props;
    const { showPreviewModal, previewFile, previewIndex, uploadedFile, textTitle, image } = this.state;
    if (observation != null && observation.attachedFileData != undefined && observation.attachedFileData != null && observation.attachedFileData != '') {
      this.uploadedFile = JSON.parse(observation.attachedFileData);
    }
    return (
      <div>
        <ul className='scrollview-list labResult-container'>
          {this.state.blobData.length >= 0 &&
            <li className="scrollview-list-item">
              <div className="data-row">
                <div className="item-data item-actions">
                  <div className="d-flex align-items-baseline">
                    <CircleDotFigure color='grayLight' />
                    <div className='d-flex justify-space-between w-100'>
                      <div>
                        <small className='date-period'>Updated {`${moment(observation.recordedAt).calendar(null, momentCalendarDateTimeFormat)}`}</small>
                        <div className="labResult-title">
                          {local.labResultSubType}
                        </div>
                      </div>
                      <button
                        id={`delete-button-${observation.id}`}
                        className="data-actions_labResult"
                        data-testid={`delete-button-${observation.id}`}
                        type="button" onClick={this.triggerModal}>
                        <Icon symbol="delete" />
                      </button>
                    </div>
                  </div>
                  <div className='labResult-imgBox'>
                    {this.state.blobData.length === 0 && <Loading />}
                    {this.loadLabResults(this.uploadedFile)}
                    {this.state.blobData.length > 0 && this.state.showDeleteModal ?
                      <ConfirmModal
                        modalTitle={local.healthData.labs.deleteLabResult}
                        modalPromptText={local.healthData.labs.deletePrompt}
                        deleteYes={local.healthData.labs.deleteYes}
                        deleteNo={local.healthData.labs.deleteNo}
                        handleYes={this.handleDelete}
                        handleNo={this.triggerModal}
                        isOpen />
                      : null}
                  </div>
                  <div className="labResult-notes">
                    {observation.notes &&
                      <div> Notes: {observation.notes} </div>}
                  </div>
                </div>
              </div>
            </li>}
        </ul>
        <div id='labResults-list'>
          {uploadedFile.length >= 0 &&
            <div className={`vaccination-proof-modal ${showPreviewModal ? 'show' : 'hide'}`} >
              <div className="close" onClick={this.previewImageClose.bind(this)}><Icon symbol="times" /></div>
              <div className="modal-img-wrapper"><img className="modal-image" src={image + previewFile} /></div>
              <div className="preview-text"> {textTitle}</div>
              {previewIndex > 0 && <Icon symbol="arrowleft" className="previous preview-arrow" onClick={this.actionPreviewImage.bind(this, previewIndex, FilePreviewAction.Previous)} />}
              {previewIndex !== (uploadedFile.length - 1) && <Icon symbol="arrowright" className="next preview-arrow" onClick={this.actionPreviewImage.bind(this, previewIndex, FilePreviewAction.Next)} />}
            </div>}
        </div>
      </div>
    );
  }
}

LabResultListItem.propTypes = {
  config: PropTypes.shape().isRequired,
  observation: PropTypes.shape().isRequired
};

export default connect()(LabResultListItem);
