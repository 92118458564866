import { AlertSeverityStatus, MetricAlertSeverity } from 'services/enums';

export const getAlertSeverityStatus = ({ severity, incomplete, processing }, loading, error) => {
  if (severity > MetricAlertSeverity.None) {
    return AlertSeverityStatus.NotVerified;
  } else if (severity == null || severity === MetricAlertSeverity.Unknown || processing || incomplete || loading || error) {
    return AlertSeverityStatus.Unknown;
  } else if (severity === MetricAlertSeverity.None) {
    return AlertSeverityStatus.Verified;
  } else {
    return AlertSeverityStatus.NotVerified;
  }
};
