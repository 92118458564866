import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { getProfileId, getProfileBlueRingPreferences,validBlueRingPreferences } from 'app/selectors';
import { defaultDashboardCirclePreferences } from 'app/config';

import { getUniqueId } from 'services/helpers';
import { routePaths } from 'services/routing';
import { calculateAge } from 'services/date-service';
import local from 'services/localization/local';

import { CollapsableListView, Button, Page } from 'shared';
import FavSelection from './FavSelection';
import { accordions } from '../../dashboard/DashboardCircleIcons.js';
import { createOrUpdateProfilePreferences, getProfilePreferences } from 'profiles/action-creators';
import { trackCustomization } from 'services/appinsights-service';
import { getProfile, getSortedProfiles } from 'profiles/list/selectors';
import './FavSelectionList.scss';


export function FavSelectionList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { index } = useParams();
  const profileId = useSelector(getProfileId);
  const profile = useSelector(getProfile);
  const IdentityId = useSelector(state => state.identity.self.id);
  const profiles = useSelector(state => getSortedProfiles(state), shallowEqual);

  const blueRingPreference = useSelector(getProfileBlueRingPreferences);
  let blueCirclePreferences = blueRingPreference == null || blueRingPreference == '' ? defaultDashboardCirclePreferences : blueRingPreference;
  const [selectedFavIcon, setSelectedFavIcon] = useState(blueCirclePreferences.split(',')[index]);


  const updateProfilePreference = () => {
    let newFavlist = updateFavString(blueCirclePreferences, index, selectedFavIcon, ',');
    newFavlist= validBlueRingPreferences(newFavlist) ? newFavlist:defaultDashboardCirclePreferences;
    dispatch(createOrUpdateProfilePreferences(profileId, newFavlist));
    dispatch(getProfilePreferences(profileId, getUniqueId()));

    var sortedSelectedShortcuts = (newFavlist?.split(','))?.sort()?.join(',');

    trackCustomization({
      Action: 'Shortcuts Saved', SelectedShortcut: selectedFavIcon, FavoriteShortcuts: newFavlist,
      SortedShortcuts: sortedSelectedShortcuts, IdentityId: IdentityId, ProfileId: profile.id,
      Age: calculateAge(profile.dateOfBirth), Gender: profile.gender !== null ? profile.gender == 1 ? 'Male' : 'Female' : '', ProfileCount: profiles?.length
    });
    history.push(routePaths.preferences);
  };

  useEffect(() => {
    setSelectedFavIcon(blueCirclePreferences.split(',')[index]);

    var sortedSelectedShortcuts = (blueCirclePreferences?.split(','))?.sort()?.join(',');
    trackCustomization({
      Action: 'Landed to Shortcuts Page', SelectedShortcut: selectedFavIcon, FavoriteShortcuts: blueCirclePreferences,
      SortedShortcuts: sortedSelectedShortcuts, IdentityId: IdentityId, ProfileId: profile.id,
      Age: calculateAge(profile.dateOfBirth), Gender: profile.gender !== null ? profile.gender == 1 ? 'Male' : 'Female' : '', ProfileCount: profiles?.length
    });

  }, [blueRingPreference]);

  const onClickOption = (option) => {
    setSelectedFavIcon(option ?? blueCirclePreferences.split(',')[index]);
  };

  const updateFavString = (list, indexToReplace, newIcon, separator) => {
    var values = list.split(separator);
    for (var i = 0; i < values.length; i++) {
      if (i == indexToReplace) {
        values[i] = newIcon;
        return values.join(separator);
      }
    }
    return list;
  };

  return (
    <Page
      id="preferences-selection-component"
      centerElement={local.customizeDashboard}
      backPath={{ to: routePaths.preferences }}>
      <div className="card-component customize-option">
        <div className="card-container">

          {accordions.map(accordion => {
            return (

              <CollapsableListView key={`list_${accordion.name}`} id={accordion.name} title={accordion.text} show defaultChecked>

                <FavSelection data={accordion.options} onClick={onClickOption} activeOption={selectedFavIcon} blueCirclePreferences={blueCirclePreferences} currentIndex={index} />
              </CollapsableListView>

            );
          })}

          <div className="customize-update-btn">
            <Button
              data-testid='btnUpdate'
              id="update-profile-preference"
              className="btn btn-primary"
              onClick={updateProfilePreference} >
              {local.formUpdate}
            </Button>
          </div>
        </div>
      </div>
    </Page>
  );
}
