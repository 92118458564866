import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { canShare, share } from 'mobile/helpers';
import { getUniqueId } from 'services/helpers';
import * as actions from 'app/toaster/actions';
import local from 'services/localization/local';

export default function ShareLinkWrapper(props) {
  const {
    url,
    title,
    text,
    dialogTitle,
    id,
    className,
    children,
    element = 'div',
    ...rest
  } = props;

  const linkElement = useRef(null);
  const instanceId = useRef(getUniqueId());
  const dispatch = useDispatch();

  const handleShare = () => {
    if (canShare) {
      share(title, text, url, dialogTitle);
    }
  };

  const handleCopy = () => {
    if (linkElement.current && document) {
      linkElement.current.select();
      linkElement.current.setSelectionRange(0, 9999);
      document.execCommand('copy');
      dispatch(actions.popSuccessToast(local.socialShare.fallbackCopied));
    }
  };

  const fallbackText = `${text}: ${url}`;
  const elementProps = {
    ...rest,
    id: id || `share-link-${instanceId.current}`,
    className,
    onClick: canShare ? handleShare : handleCopy
  };

  return (
    <React.Fragment>
      <input
        style={{ transform: 'scale(0)', position: 'fixed', left: '100px' }}
        ref={linkElement}
        type="text"
        defaultValue={fallbackText} />
      {React.createElement(element, elementProps, children)}
    </React.Fragment>
  );
}

ShareLinkWrapper.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  element: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.element,
    PropTypes.string
  ]),
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
