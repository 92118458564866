import React from 'react';
import { ProfileLink } from 'shared';
import { routePaths } from 'services/routing';
import TimelineIconDate from './TimelineIconDate';
import { Journal } from 'shared/fullColorIcons';

export default function TimelineJournalEntry({ item }) {
  return (
    <ProfileLink
      to={routePaths.healthJournal}
      id={`timeline-item-link-${item.data.id}`}
      logName="timeline-journal-entry">
      <TimelineIconDate item={item} Symbol={Journal} />
      {item.data.title && <strong><p className="timeline-item-header">{item.data.title}</p></strong>}
      <p className="timeline-item-notes">{item.data.content}</p>
    </ProfileLink>
  );
}
