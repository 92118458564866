import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, LinkWrapper, AvatarCard } from 'shared';
import { routePaths } from 'services/routing';
import local from 'services/localization/local';


export default function OrganizationsListItem({ organizationId, organizationName }) {
  const avatar = <Avatar profile={{ name: organizationName, theme: 0 }} />;

  return (
    <LinkWrapper
      id='organization-details-link'
      to={routePaths.organizationsDetails}
      params={{ organizationId }}>
      <AvatarCard
        avatar={avatar}
        heading={organizationName}
        subheading={local.organizations.viewDetails} />
    </LinkWrapper>
  );
}

OrganizationsListItem.propTypes = {
  organizationId: PropTypes.string,
  organizationName: PropTypes.string
};
