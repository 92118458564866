import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'shared';
import { TextField, DateTimeTextFieldWrapper } from 'shared/form-fields';
import * as validators from 'services/fields/field-validators';
import * as normalizers from 'services/fields/field-normalizers';
import PathwayContextDetails from 'health/observations/shared/PathwayContextDetails';
import local from 'services/localization/local';

export function LogBodyWaistForm({ submitting, pristine, saving, handleSubmit, handleBack, pathwayContext }) {
  const isSubmitting = submitting || saving;
  const isSubmitDisabled = isSubmitting || pristine;

  return (
    <form onSubmit={handleSubmit}>
      {pathwayContext && <PathwayContextDetails pathwayContext={pathwayContext} />}
      <div className="form-row">
        <DateTimeTextFieldWrapper
          readOnly={isSubmitting}
          label={local.formTimestampLabel}
          validate={[validators.required, validators.dateInPast, validators.pathwayContextDateValidator]}
          name="timestamp" />
      </div>
      <div className="form-row">
        <Field
          readOnly={isSubmitting}
          label={local.formBodyWaistLabel}
          autoFocus
          name="bodyWaistValue"
          component={TextField}
          type="number"
          placeholder={local.formBodyWaistPlaceholder}
          normalize={normalizers.bodyWaist}
          validate={[validators.required, validators.number, validators.minValue1, validators.maxValue100]} />
      </div>
      <div className="form-row">
        <Field
          readOnly={isSubmitting}
          label={local.formNotesLabel}
          name="notes"
          component={TextField}
          validate={[validators.maxLength250]}
          subLabel={local.formNotesPlaceholder} />
      </div>
      <br />
      <br />
      <div className="form-row">
        <Button id="body-waist-form-cancel" className="btn btn-secondary" onClick={handleBack}>{local.formCancel}</Button>
        <Button
          id="body-waist-form-submit"
          className="btn btn-primary"
          submit
          color="blue"
          disabled={isSubmitDisabled}>
          {local.formSave}
        </Button>
      </div>
    </form>
  );
}

LogBodyWaistForm.displayName = 'Log Body waist Form';
LogBodyWaistForm.propTypes = {
  saving: PropTypes.bool,
  handleBack: PropTypes.func.isRequired,
  // redux form
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  pathwayContext: PropTypes.shape()
};

export default reduxForm({
  form: 'bodyWaist'
})(LogBodyWaistForm);
