import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProfileId } from 'app/selectors';
import local from 'services/localization/local';
import { Loading, NoDataPlaceholder, Page} from 'shared';
import { routePaths } from 'services/routing';
import { types } from 'health/health-types';
import { loadConditions, resetUnreadMedicalRecordsCount } from 'health/action-creators';
import { FhirResourceTypes, healthDataType } from 'services/enums';
import useMarkHealthDataAccessed from 'health/useMarkHealthDataAccessed';
import ConditionListItem from './ConditionListItem';
import './conditions.scss';
import PropTypes from 'prop-types';
import { getHealthRecords, getUnreadMedicalRecordsCount } from 'health/health-selectors';

export default function ConditionsList(props) {
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const { data, loading, hasError } = useSelector(state => getHealthRecords(state, profileId, types.CONDITIONS));
  const unreadMedicalRecordsCount = useSelector(state => getUnreadMedicalRecordsCount(state, profileId, FhirResourceTypes.Condition));
  const content = local.healthData.conditions;

  useMarkHealthDataAccessed(healthDataType.Condition);
  useEffect(()=>{
    dispatch(loadConditions(profileId));
  },[profileId]);

  useEffect(()=>{
    if(unreadMedicalRecordsCount> 0)
      dispatch(resetUnreadMedicalRecordsCount(profileId, FhirResourceTypes.Condition));
  },[profileId, unreadMedicalRecordsCount]);

  const renderConditions = () => {
    if(loading){
      return (<Loading></Loading>);
    }
    if(!data || data?.length === 0 || hasError){
      return(<NoDataPlaceholder>{content.noConditions}</NoDataPlaceholder>);
    }
    return (data && data.length > 0 &&
      data.map((x) => <ConditionListItem key={x.id} condition={x}></ConditionListItem>)
    );
  };

  return (
    <Page
      id='conditions-list'
      backPath={{to:props.location?.state?.backpath ?? routePaths.health}}
      centerElement={content.title}>
      <ul className='card-list'>
        {renderConditions()}
      </ul>
    </Page>
  );
}

ConditionsList.propTypes = {
  location: PropTypes.shape()
};
