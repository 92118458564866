import { NOT_FOUND_HTTP_ERROR_CODE } from 'services/api/api';
import { getUniqueId } from 'services/helpers';
import * as toastActions from 'app/toaster/actions';
import * as healthActions from 'health/actions';
import { request } from 'app/request/request-actions';
import * as profilesApi from 'services/api/profiles-api';
import { selectProfile } from 'profiles/selected/selected-profile-actions';
import * as actions from './actions';
import moment from 'moment';

export function baseSaveProfile(profile, apiAction, successAction) {
  return (dispatch, getState) => {
    const requestId = getUniqueId();

    dispatch(actions.profileSaveRequested());
    dispatch(toastActions.addProcessingToast(requestId));

    const onSuccess = updatedProfile => {
      dispatch(toastActions.completeToastWithSuccess(requestId));
      dispatch(successAction(updatedProfile));
      dispatch(selectProfile(updatedProfile.id));
    };

    const onError = error => {
      dispatch(toastActions.completeToastWithError(requestId));
      dispatch(actions.profileSaveError(error));
    };

    return apiAction(profile, getState()).then(onSuccess, onError).catch(onError);
  };
}

export function saveProfile({ id, name, gender, theme, dateOfBirth, firstName, lastName }) {
  const updatedAt = moment().format();
  return baseSaveProfile({ id, name, gender, dateOfBirth, theme, updatedAt, firstName, lastName }, profilesApi.updateProfile, actions.profileSaved);
}

export function saveSelfProfile({ name, gender, theme, dateOfBirth, firstName, lastName }) {
  return baseSaveProfile({ selfProfile: true, name, gender, dateOfBirth, theme, firstName, lastName }, profilesApi.addProfile, actions.selfProfileCreated);
}

export function saveNewOtherProfile({ name, gender, dateOfBirth, theme, firstName, lastName }) {
  return baseSaveProfile({ name, gender, dateOfBirth, theme, firstName, lastName }, profilesApi.addProfile, actions.newOtherProfileSaved);
}

export function loadProfile() {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(actions.profileRequested());

    const onError = error => {
      if (error && error.status === NOT_FOUND_HTTP_ERROR_CODE) {
        dispatch(actions.profileMissing());
      } else {
        dispatch(actions.profileLoadError(error));
      }
    };

    return profilesApi.getProfile(state)
      .then(profile => dispatch(actions.profileLoaded(profile)))
      .catch(onError);
  };
}

export function loadProfiles() {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(actions.profileListRequested());

    const onError = error => {
      if (error && error.status === NOT_FOUND_HTTP_ERROR_CODE) {
        dispatch(actions.profileMissing());
      } else {
        dispatch(actions.profileListError(error));
      }
    };

    return profilesApi.getProfileList(state)
      .then(profiles => dispatch(actions.profileListLoaded(profiles)))
      .catch(onError);
  };
}

export function loadProfileById(profileId, requestId) {
  return (dispatch) => {
    const action = state => profilesApi.getProfileById(profileId, state)
      .then(profile => dispatch(actions.profileUpdated(profile)));

    return dispatch(request(action, requestId));
  };
}

export function getProfilePreferences(profileId, requestId) {
  return (dispatch) => {
    const action = state => profilesApi.getProfilePreferences(profileId, state)
      .then(profilePreferences => {
        dispatch(actions.loadProfilePreferences(profilePreferences));
      });

    return dispatch(request(action, requestId));
  };
}

export function createOrUpdateProfilePreferences(profileId, profilePrefernce, requestId) {
  return (dispatch) => {
    const action = state => profilesApi.createOrUpdateProfilePreferences(profileId, profilePrefernce, state)
      .then(() => {
        dispatch(getProfilePreferences(profileId, requestId));
      });
    return dispatch(request(action, requestId));
  };
}

export function acceptHipaaConsentPage(profileId) {
  return (dispatch, getState) => {
    const onSuccess = () => {
      dispatch(loadProfile());
      dispatch(loadProfiles());
    };

    const onError = () => {
      dispatch(toastActions.popErrorToast());
    };

    return profilesApi.hipaaConsent(profileId, getState())
      .then(onSuccess)
      .catch(onError);
  };
}

export function acceptHipaaConsent(profileId, retryAction) {
  return (dispatch, getState) => {
    const onSuccess = () => {
      dispatch(healthActions.hipaaConsentCleared());
      dispatch(retryAction());
    };

    const onError = () => {
      dispatch(healthActions.hipaaConsentCleared());
    };

    return profilesApi.hipaaConsent(profileId, getState())
      .then(onSuccess)
      .catch(onError);
  };
}
