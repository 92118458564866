import React from 'react';
import PropTypes from 'prop-types';
import { ModalWrapper, Button, FormattedText } from 'shared';
import './confirm-modal.scss';

export default function ConfirmModal({ modalTitle, modalPromptText, handleYes, handleNo, isOpen, deleteYes,  deleteNo }) {
  return (
    <ModalWrapper isOpen={isOpen}>
      <div id="confirm-modal" className="modal-container" data-testid='modal'>
        <div className="modal-header">
          <div></div>
          <div className='modal-title'>{modalTitle}</div>
        </div>
        <div className="modal-body">
          <div className='scrollable confirm-section'>
            <FormattedText text={modalPromptText} />
          </div>
        </div>
        <div className='modal-footer'>
          <Button id="reject-button" className='btn btn-outline-dark small-btn' onClick={handleNo}>{deleteNo}</Button>
          <Button id="accept-button" className='btn btn-dark small-btn' onClick={handleYes}>{deleteYes}</Button>
        </div>
      </div>
    </ModalWrapper>
  );
}

ConfirmModal.propTypes = {
  modalTitle: PropTypes.string,
  modalPromptText: PropTypes.string,
  handleYes: PropTypes.func.isRequired,
  handleNo: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};
