import React from 'react';
import { Animate } from 'react-move';

class AnimatedProgressProvider extends React.Component {
  constructor(props){
    super(props);
    this.interval = undefined;
    this.state={
      isAnimated:false
    };
  }
  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      isAnimated: !this.state.isAnimated
    });

  }

  // componentWillUnmount() {
  //   window.clearInterval(this.interval);
  // }

  render() {
    return (
      <Animate
        start={() => ({
          value: this.props.valueStart
        })}
        update={() => ({
          value: [
            this.state.isAnimated ? this.props.valueEnd : this.props.valueStart
          ],
          timing: {
            duration: this.props.duration * 1000,
            ease: this.props.easingFunction
          }
        })}>
        {({ value }) => this.props.children(value)}
      </Animate>
    );
  }
}

export default AnimatedProgressProvider;
