import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'shared';
import { TextField, DateTimeTextFieldWrapper } from 'shared/form-fields';
import * as validators from 'services/fields/field-validators';
import * as normalizers from 'services/fields/field-normalizers';
import PathwayContextDetails from 'health/observations/shared/PathwayContextDetails';
import local from 'services/localization/local';

class LogTemperatureForm extends React.Component {
  render() {
    const { submitting, pristine, saving, handleBack, handleSubmit, pathwayContext } = this.props;

    const isSubmitting = submitting || saving;
    const isSubmitDisabled = isSubmitting || pristine;

    return (
      <form onSubmit={handleSubmit}>
        {pathwayContext && <PathwayContextDetails pathwayContext={pathwayContext} />}
        <div className="form-row">
          <DateTimeTextFieldWrapper
            readOnly={isSubmitting}
            label={local.formTimestampLabel}
            name="timestamp"
            validate={[validators.required, validators.dateInPast, validators.pathwayContextDateValidator]} />
        </div>
        <div className="form-row">
          <Field
            readOnly={isSubmitting}
            label={local.formTemperatureLabel}
            autoFocus
            name="temperature"
            component={TextField}
            type="number"
            placeholder={local.formTemperaturePlaceholder}
            normalize={normalizers.temperatureFahrenheit}
            validate={[validators.required, validators.number, validators.minValue0, validators.maxValue300]} />
        </div>
        <div className="form-row">
          <Field
            readOnly={isSubmitting}
            label={local.formNotesLabel}
            name="notes"
            component={TextField}
            validate={[validators.maxLength250]}
            placeholder={local.formNotesPlaceholder} />
        </div>
        <br />
        <br />
        <div className="form-row">
          <Button id="temperature-form-cancel" className="btn btn-secondary" onClick={handleBack}>{local.formCancel}</Button>
          <Button
            id="temperature-form-submit"
            className="btn btn-primary"
            submit
            color="blue"
            disabled={isSubmitDisabled}>
            {local.formSave}
          </Button>
        </div>
      </form>
    );
  }
}

LogTemperatureForm.displayName = 'Log Temperature Form';
LogTemperatureForm.propTypes = {
  saving: PropTypes.bool,
  handleBack: PropTypes.func.isRequired,
  // redux form
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  pathwayContext: PropTypes.shape()
};

export default reduxForm({
  form: 'temperature'
})(LogTemperatureForm);
