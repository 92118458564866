import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import styleVars from 'styles/_variables.scss';
import local from 'services/localization/local';
import { ObservationDetailType } from 'services/enums';
import moment from 'moment';
import PropTypes from 'prop-types';
import './nutrition-visualization.scss';
import { useSelector } from 'react-redux';
import { getActiveNutritionGoal, getNutritionGoalData, getNutritionsBarChartData, getWeeklyAverage, getWeeklyMacroNutrients } from '../nutrition-selector';
import { daysForLastWeek } from 'services/constants';
import { ArrowButton, ProgressBar } from 'shared';
import ChartDataLabels from 'chartjs-plugin-datalabels';

function NutritionChart({profileId, today,pathwayStartDate,fromPathway,nutritions,pathwayEndDate}) {
  const dateRangeFormat = 'MMM D';
  const [endDate, setEndDate] = useState(today);
  const nutritionData = useSelector(state => getNutritionsBarChartData(state, profileId, endDate,nutritions,fromPathway,pathwayStartDate));
  const nutritionGoalData = useSelector(state => getNutritionGoalData(state, profileId, endDate));
  const weeklyAverageData = useSelector(state => getWeeklyAverage(state, profileId, endDate,nutritions,fromPathway));
  const weeklyMacroNutrients = useSelector(state => getWeeklyMacroNutrients(state, profileId, endDate,nutritions,fromPathway));
  const activeGoal = useSelector(state => getActiveNutritionGoal(state, profileId));
  const startDay =  moment(endDate).add(daysForLastWeek, 'days').format(dateRangeFormat);

  const endDay =  moment(endDate).format(dateRangeFormat);

  const content = local.observation.nutritions;
  const previousDisabled = fromPathway ? moment(pathwayStartDate) >= moment(endDate).add(-6,'days') : false;
  const nextDisabled = fromPathway ? moment(pathwayEndDate) < moment(endDate).add(1,'days') : moment(endDate).add(1, 'day') > moment();


  const chartData = {
    labels: nutritionData.map(n => moment(n.date).format('M/D')),
    datasets : [
      {
        label: content.protein,
        data: nutritionData.map(n => n.value > 0 ? n[ObservationDetailType.Protein]?.valueInCalories : 0),
        backgroundColor: styleVars.chartLightBlue,
        barThickness: 20,
        order: 1,
        datalabels: {
          display: false
        }
      },
      {
        label: content.fat,
        data: nutritionData.map(n => n.value > 0 ? n[ObservationDetailType.Fat]?.valueInCalories : 0),
        backgroundColor: styleVars.chartLightPink,
        barThickness: 20,
        order: 2,
        datalabels: {
          display: false
        }
      },
      {
        label: content.carbohydrate,
        data: nutritionData.map(n => n.value > 0 ? n[ObservationDetailType.Carbohydrate]?.valueInCalories : 0),
        backgroundColor: styleVars.chartLightYellow,
        barThickness: 20,
        order: 3,
        datalabels: {
          display: false
        }
      },
      {
        label: 'Goal',
        data: nutritionGoalData?.map(n => n.calories),
        type: 'line',
        borderColor: styleVars.colorGray,
        backgroundColor: styleVars.colorGray,
        pointRadius: 0,
        pointHoverRadius: 0,
        pointHitRadius: 0,
        lineTension: 0,
        fill: false,
        order: 0,
        borderDash: [4, 4],
        borderWidth: 1.5
      }
    ],
    totalCalories: nutritionData.map(n => n.value),
    datesForTooltip: nutritionData.map(n => moment(n.date).format(dateRangeFormat)),
    valuesInGrams: [
      nutritionData.map(n => n[ObservationDetailType.Protein]?.valueInGrams),
      nutritionData.map(n => n[ObservationDetailType.Fat]?.valueInGrams),
      nutritionData.map(n => n[ObservationDetailType.Carbohydrate]?.valueInGrams)
    ],
    valuesInPercentage: [
      nutritionData.map(n => n[ObservationDetailType.Protein]?.valueInPercentage),
      nutritionData.map(n => n[ObservationDetailType.Fat]?.valueInPercentage),
      nutritionData.map(n => n[ObservationDetailType.Carbohydrate]?.valueInPercentage)
    ]
  };

  const chartOptions = {
    responsive: true,
    legend: {
      display: false
    },
    tooltips: {
      enabled: true,
      displayColors: true,
      mode : 'index',
      backgroundColor: styleVars.colorWhiteOff,
      titleFontColor: styleVars.ColorText,
      titleFontSize: 14,
      bodySpacing: 5,
      position: 'nearest',
      titleFontFamily: 'arial',
      caretSize: 0,
      itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
      filter: (tooltipItem) => tooltipItem?.datasetIndex !=3 && Number(tooltipItem?.value) > 0,
      callbacks: {
        title: (tooltipItem, data) => tooltipItem.length > 0 && `${data.datesForTooltip[tooltipItem[0]?.index]} : ${data.totalCalories[tooltipItem[0]?.index]} ${content.cal}`,
        label: (tooltipItem, data) => ` ${data.valuesInPercentage[tooltipItem.datasetIndex][tooltipItem.index]}%  ${data.datasets[tooltipItem.datasetIndex]?.label} (${data.valuesInGrams[tooltipItem.datasetIndex][tooltipItem.index]} g)`,
        labelTextColor: () =>  styleVars.colorGrayDark
      }
    },
    scales: {
      xAxes: [{
        display: true,
        stacked: true,
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        display: false,
        stacked: true
      }]
    },
    plugins: {
      datalabels: {
        backgroundColor: styleVars.colorBlackDark,
        borderRadius: 1,
        color: styleVars.colorWhiteOff,
        formatter: () => content.calGoal,
        padding: 3,
        display: (context) => context.dataIndex == context.dataset.data.length - 1,
        align: 'start',
        offset: 1
      }
    }
  };

  const getAverage = () => {
    if(!nutritionData || nutritionData.length <= 0)
      return 0;
    const filteredData = nutritionData?.filter(n => n.value > 0);
    if(filteredData.length <= 0)
      return 0;
    const sum = filteredData.map(n => n.value).reduce((a, b) => { return a + b; });

    return Math.round(sum/filteredData.length);
  };

  function selectPreviousWeek(){
    setEndDate(moment(endDate).add(-7, 'days').format(moment.HTML5_FMT.DATE));
  }

  function selectNextWeek(){
    setEndDate(moment(endDate).add(7, 'days').format(moment.HTML5_FMT.DATE));
  }

  const getProgress = (actual, goal) => goal == 0 || actual >= goal ? 1 : (actual/goal*100)/100;

  return (nutritionData &&
    <div>
      <div className="nutrition-vis-header">
        <ArrowButton id="previous-nutrition-button" onClick={selectPreviousWeek} disabled={previousDisabled} />
        <small className="nutrition-vis-title">{startDay} - {endDay}</small>
        <ArrowButton id="next-nutrition-button" rightArrow onClick={selectNextWeek} disabled={nextDisabled} />
      </div>
      <div className='average-container'>
        <div className='average-value'>{getAverage()}</div>
        <div className='average-label'>{content.averageCalories}</div>
      </div>
      <Bar data={chartData} options={chartOptions} plugins={[ChartDataLabels]} />
      {activeGoal ? <div className='macro-weekly-trend'>
        <div className='carbs-weekly-trend macro-progressBar'>
          <div className='d-flex justify-content-between'>
            <div>
              <span className='bold'>{weeklyMacroNutrients[ObservationDetailType.Carbohydrate]} g</span> <span className='gram-label'>{`${content.carbohydrate}/${content.week}`}</span>
            </div>
            <div>
              <span className={weeklyAverageData.carbsAveragePercentage > activeGoal.carbs ? 'warning' : undefined}>{`${weeklyAverageData.carbsAveragePercentage}% ${content.actual}`}</span> <span> / {`${activeGoal.carbs.toFixed(0)}% ${content.goal}`}</span>
            </div>
          </div>
          <ProgressBar primaryProgress={getProgress(weeklyAverageData.carbsAveragePercentage, activeGoal.carbs)} />
        </div>

        <div className='fat-weekly-trend macro-progressBar'>
          <div className='d-flex justify-content-between'>
            <div>
              <span className='bold'>{weeklyMacroNutrients[ObservationDetailType.Fat]} g</span> <span className='gram-label'>{`${content.fat}/${content.week}`}</span>
            </div>
            <div>
              <span className={weeklyAverageData.fatAveragePercentage > activeGoal.fat ? 'warning' : undefined}>{`${weeklyAverageData.fatAveragePercentage}% ${content.actual}`}</span> <span> / {`${activeGoal.fat.toFixed(0)}% ${content.goal}`}</span>
            </div>
          </div>
          <ProgressBar primaryProgress={getProgress(weeklyAverageData.fatAveragePercentage, activeGoal.fat)} />
        </div>

        <div className='protein-weekly-trend macro-progressBar'>
          <div className='d-flex justify-content-between'>
            <div>
              <span className='bold'>{weeklyMacroNutrients[ObservationDetailType.Protein]} g</span> <span className='gram-label'>{`${content.protein}/${content.week}`}</span>
            </div>
            <div>
              <span className={weeklyAverageData.proteinAveragePercentage > activeGoal.protein ? 'warning' : undefined}>{`${weeklyAverageData.proteinAveragePercentage}% ${content.actual}`}</span> <span> / {`${activeGoal.protein.toFixed(0)}% ${content.goal}`}</span>
            </div>
          </div>
          <ProgressBar primaryProgress={getProgress(weeklyAverageData.proteinAveragePercentage, activeGoal.protein)} />
        </div>
      </div> : null}
    </div>
  );
}

NutritionChart.propTypes = {
  profileId: PropTypes.string.isRequired,
  today: PropTypes.string.isRequired
};

export default NutritionChart;
