import React ,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { Line as LineChart } from 'react-chartjs-2';
import moment from 'moment';
import { getMinMaxBloodGlucoseValue } from '../bloodGlucose-selector';
import {loadGlucoseGoals} from 'health/action-creators';
import { connect } from 'react-redux';
import withContext from 'app/withContext';
import { bindActionCreators } from 'redux';
import withNavigate from 'app/withNavigation';
import { getData, getOptions } from './BloodGlucoseChartHelper';
import { compareDatesAscending } from 'services/date-service';

const chartDataValue = (values) =>{
  const chartValues = values && values.length > 0 && values.map((val)=>{
    return{
      recordedAt :  moment(val.recordedAt).format('M/D/YY'),
      value: val.value
    };
  });
  const chartData = chartValues.sort((a, b) => compareDatesAscending(a.recordedAt, b.recordedAt));
  return chartData;
};

function BloodGlucoseObservationLineChart({bloodGlucose,activeGoal,minMaxValue,profileId,actions,isHighFrquencydata}) {
  const getActiveGoalValue = (actions,profileId) =>{
    actions.loadGlucoseGoals(profileId);
  };
  useEffect(()=>{
    getActiveGoalValue(actions,profileId);
  }, []);

  const chartData = chartDataValue(bloodGlucose);
  const data = getData(chartData);
  const options = getOptions(bloodGlucose,activeGoal,minMaxValue,isHighFrquencydata);
  return <LineChart id="blood-glucose-line-chart" data={data} options={options} />;
}
BloodGlucoseObservationLineChart.displayName = 'Vitals Chart';
BloodGlucoseObservationLineChart.propTypes = {
  actions: PropTypes.shape({
    loadGlucoseGoals : PropTypes.func.isRequired
  }).isRequired
};
function mapStateToProps(state, props) {
  return {
    minMaxValue:getMinMaxBloodGlucoseValue(state,props.profileId)
  };
}
function mapDispatchToProps(dispatch) {
  const actions = { loadGlucoseGoals  };
  return { actions: bindActionCreators(actions, dispatch) };
}
export default withNavigate(withContext(connect(mapStateToProps,mapDispatchToProps)(BloodGlucoseObservationLineChart)));
