import React from 'react';
import moment from 'moment';

export default function TimeSlot(props) {
  return (
    <div className="timeslot">
      <span>{ moment(props.scheduledAt).format('hh:mm A')}</span>
    </div>
  );
}
