import moment from 'moment';
import { ObservationDetailType, ObservationType } from 'services/enums';
import { getObservationData } from 'health/observations/observation-selectors';
import { daysForLastWeek } from 'services/constants';
import * as dataSelectors from 'app/request/persistent-data-selectors';
import { types } from 'health/health-types';

const getNutritions = (state, profileId) => {
  const allNutrition = getObservationData(state, ObservationType.Nutrition)
    .filter(x => x.profileId === profileId);
  return allNutrition;
};

export const getNutritionSummary = (state, props) => {
  const allNutritionData = getNutritions(state, props.profileId);
  const result = allNutritionData.reduce(function(res, nutrition) {
    const date =moment(nutrition.recordedAt).format(moment.HTML5_FMT.DATE);
    const source=nutrition.source;
    const dateMatch=res.find(x=>moment(x.recordedAt).format(moment.HTML5_FMT.DATE)===date && x.source===source);
    if (dateMatch===undefined) {
      const nutritionData = {...nutrition, recordedAt: date, value: nutrition.value };
      res.push(nutritionData);
    }
    else
      dateMatch.value += nutrition.value;
    return res;
  }, []);
  return result;
};

export const getNutritionsData = (state, profileId) => {
  const nutritions = getNutritions(state, profileId);
  const nutritionDataByDate = [];

  nutritions.forEach(nutrition => {
    const nutritionData = {
      date: moment(nutrition.recordedAt).format(moment.HTML5_FMT.DATE),
      value: nutrition.value ? Math.round(nutrition.value) : 0,
      deviceDisplayName : nutrition.deviceDisplayName,
      observationDetails : nutrition.observationDetails,
      manufacturer: nutrition.manufacturer
    };

    if(nutrition.observationDetails){
      const macroNutrients = getMacroNutrientData(nutrition.observationDetails);
      nutritionData[ObservationDetailType.Protein]= macroNutrients[ObservationDetailType.Protein];
      nutritionData[ObservationDetailType.Carbohydrate]= macroNutrients[ObservationDetailType.Carbohydrate];
      nutritionData[ObservationDetailType.Fat]= macroNutrients[ObservationDetailType.Fat];
    }

    nutritionDataByDate.push(nutritionData);
  });
  return nutritionDataByDate;
};

export const getNutritionsBarChartData = (state, profileId, selectedEnd,nutritions,fromPathway) => {
  const startDayMoment = moment(selectedEnd).add(daysForLastWeek, 'days');
  const endDayMoment = moment(selectedEnd);

  const nutritionDataByDate = [];

  const filteredNutritions = fromPathway ?  nutritions: getNutritions(state, profileId).filter(x => moment(x.recordedAt) >= startDayMoment);
  while (startDayMoment <= endDayMoment) {
    const date = startDayMoment.format(moment.HTML5_FMT.DATE);
    const nutritionData = filteredNutritions.reduce((result, nutrition) => {

      if (moment(nutrition.recordedAt).format(moment.HTML5_FMT.DATE) === date) {
        // update count of nutrition observations for date
        result.count++;

        // update total nutrition count for date
        result.value += nutrition.value;
        result?.observationDetails.forEach((observationDetail) => {
          const typeValueObj = nutrition?.observationDetails?.find((detail) => {
            return detail.observationDetailType === observationDetail?.observationDetailType;
          });
          if(typeValueObj){
            const typeValue = typeValueObj.typeValue;
            if(observationDetail){
              observationDetail.typeValue += typeValue;
            }
          }
        });

      }

      return result;
    }, {
      date: date,
      value: 0,
      count: 0,
      observationDetails: [
        {
          observationDetailType: ObservationDetailType.Protein,
          typeValue: 0,
          typeUnit: 21
        },
        {
          observationDetailType: ObservationDetailType.Fat,
          typeValue: 0,
          typeUnit: 21
        },
        {
          observationDetailType: ObservationDetailType.Carbohydrate,
          typeValue: 0,
          typeUnit: 21
        }
      ]
    });

    if(nutritionData){
      nutritionData.value = nutritionData.value ? Math.round(nutritionData.value) : 0;
      if(nutritionData.observationDetails){

        const macroNutrients = getMacroNutrientData(nutritionData.observationDetails);
        nutritionData[ObservationDetailType.Protein] = macroNutrients[ObservationDetailType.Protein];
        nutritionData[ObservationDetailType.Carbohydrate] = macroNutrients[ObservationDetailType.Carbohydrate];
        nutritionData[ObservationDetailType.Fat] = macroNutrients[ObservationDetailType.Fat];
      }
    }

    nutritionDataByDate.push(nutritionData);
    startDayMoment.add(1, 'day');
  }
  return nutritionDataByDate;
};

export const getMacroNutrientData = (observationDetails) => {
  const carbohydrate = getMacroNutrientValues(observationDetails, ObservationDetailType.Carbohydrate);
  const protein = getMacroNutrientValues(observationDetails, ObservationDetailType.Protein);
  const fat = getMacroNutrientValues(observationDetails, ObservationDetailType.Fat);
  const totalCalories = carbohydrate.valueInCalories + protein.valueInCalories + fat.valueInCalories;

  carbohydrate.valueInPercentage = carbohydrate.valueInCalories > 0 ? Math.round(carbohydrate.valueInCalories / totalCalories * 100) : 0;
  protein.valueInPercentage = protein.valueInCalories > 0 ? Math.round(protein.valueInCalories / totalCalories * 100) : 0;
  fat.valueInPercentage = fat.valueInCalories ? Math.round(fat.valueInCalories / totalCalories * 100) : 0;


  return {
    [ObservationDetailType.Carbohydrate]: carbohydrate,
    [ObservationDetailType.Protein]: protein,
    [ObservationDetailType.Fat]: fat
  };
};

export const getMacroNutrientValues = (observationDetails, observationDetailType) => {
  const result = {
    valueInGrams: 0,
    valueInCalories: 0
  };

  let valueInGrams = observationDetails.find(o => o.observationDetailType == observationDetailType)?.typeValue;

  if(!valueInGrams){
    valueInGrams = observationDetails.find(o => o.observationDetailType == observationDetailType)?.value;
  }

  if(!valueInGrams){
    return result;
  }
  else{
    result.valueInGrams = valueInGrams % 1 !== 0 ? Number(valueInGrams.toFixed(1)) : valueInGrams;
    result.valueInCalories = observationDetailType == ObservationDetailType.Fat ? valueInGrams*9 : valueInGrams*4;
  }
  return result;
};

export const getActiveNutritionGoal = (state, profileId) => {
  const goals = dataSelectors.getData(state.health, types.NUTRITION_GOALS, profileId);
  if(goals && goals.length > 0){
    const activeGoal = goals.find(g => g.isActive);
    if(activeGoal){
      const result = {};
      result.calories = activeGoal.value;
      result.UnitType = activeGoal?.displayUnit;
      result.totalGrams = activeGoal?.totalForPercentageCalc;
      if(activeGoal.goalDetails && activeGoal.goalDetails.length > 0){
        result.carbs = activeGoal.goalDetails.find(d => d.observationDetailType == ObservationDetailType.Carbohydrate)?.value;
        result.protein = activeGoal.goalDetails.find(d => d.observationDetailType == ObservationDetailType.Protein)?.value;
        result.fat = activeGoal.goalDetails.find(d => d.observationDetailType == ObservationDetailType.Fat)?.value;
      }
      return result;
    }
  }
  return null;
};

export const getWeeklyAverage = (state, profileId, endDate,nutritions,fromPathway) => {
  const result = {
    caloriesAverage: 0,
    carbsAveragePercentage: 0,
    proteinAveragePercentage: 0,
    fatAveragePercentage: 0
  };
  const nutritionData = getNutritionsBarChartData(state, profileId, endDate,nutritions,fromPathway);
  if(!nutritionData || nutritionData.length <= 0)
    return result;
  const filteredData = nutritionData?.filter(n => n.value > 0);
  if(filteredData.length <= 0)
    return result;

  const sumValue = filteredData.map(n => n.value).reduce((a, b) => { return a + b; });
  result.caloriesAverage = Math.round(sumValue/filteredData.length);

  const caloriesSum = getTotalCalories(filteredData);

  result.carbsAveragePercentage = getAveragePercentage(filteredData, ObservationDetailType.Carbohydrate, caloriesSum);
  result.proteinAveragePercentage = getAveragePercentage(filteredData, ObservationDetailType.Protein, caloriesSum);
  result.fatAveragePercentage = getAveragePercentage(filteredData, ObservationDetailType.Fat, caloriesSum);
  return result;
};

export const getWeeklyMacroNutrients = (state, profileId, endDate,nutritions,fromPathway) => {
  const result = {
    [ObservationDetailType.Carbohydrate]: 0,
    [ObservationDetailType.Protein]: 0,
    [ObservationDetailType.Fat]: 0
  };

  const nutritionData = getNutritionsBarChartData(state, profileId, endDate,nutritions,fromPathway);
  if(!nutritionData || nutritionData.length <= 0)
    return result;
  const filteredData = nutritionData?.filter(n => n.value > 0);
  if(filteredData.length <= 0)
    return result;

  result[ObservationDetailType.Carbohydrate] = getTotalGrams(filteredData, ObservationDetailType.Carbohydrate);
  result[ObservationDetailType.Protein] = getTotalGrams(filteredData, ObservationDetailType.Protein);
  result[ObservationDetailType.Fat] = getTotalGrams(filteredData, ObservationDetailType.Fat);
  return result;
};

export const getTotalCalories = (data) =>
  getCalories(data, ObservationDetailType.Carbohydrate) + getCalories(data, ObservationDetailType.Protein) + getCalories(data, ObservationDetailType.Fat);

export const getTotalGrams = (data, observationDetailType) =>
  Math.round((data.map(n => n[observationDetailType].valueInGrams).reduce((a, b) => { return a + b; })));


export const getCalories = (data, observationDetailType) =>
  data.map(n => n[observationDetailType].valueInCalories).reduce((a, b) => { return a + b; });

export const getAveragePercentage = (data, observationDetailType, caloriesSum) =>
  caloriesSum <= 0 ? 0 : Math.round(data.map(n => n[observationDetailType].valueInCalories).reduce((a, b) => { return a + b; })/caloriesSum*100);

export const getNutritionGoalData = (state, profileId, selectedEnd) => {
  const startDayMoment = moment(selectedEnd).add(daysForLastWeek, 'days');
  const endDayMoment = moment(selectedEnd);

  const goals = dataSelectors.getData(state.health, types.NUTRITION_GOALS, profileId);

  if(!goals || goals.length <= 0)
    return null;

  const goalDataByDate = [];

  while (startDayMoment <= endDayMoment) {
    const date = startDayMoment.format(moment.HTML5_FMT.DATE);
    const goal = getGoal(goals, date);

    const goalData = {
      date
    };

    if(goal){
      goalData.calories = goal.value;
      if(goal.goalDetails){
        goalData.carbs = goal.goalDetails.find(d => d.observationDetailType == ObservationDetailType.Carbohydrate)?.value;
        goalData.protein = goal.goalDetails.find(d => d.observationDetailType == ObservationDetailType.Protein)?.value;
        goalData.fat = goal.goalDetails.find(d => d.observationDetailType == ObservationDetailType.Fat)?.value;
      }
    }

    goalDataByDate.push(goalData);

    startDayMoment.add(1, 'day');
  }

  return goalDataByDate;
};

export const getGoal = (goals, date) => {
  const filteredGoals = goals.filter(g => moment(g.startDate) <= moment.utc(date));
  return filteredGoals && filteredGoals.length > 0 && filteredGoals[0];
};
