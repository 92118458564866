import React from 'react';
import PropTypes from 'prop-types';
import { DatePeriod } from 'shared';
import local from 'services/localization/local';

export default function EncounterListItem({ encounter }) {
  const {
    name,
    location,
    provider,
    diagnoses,
    period
  } = encounter;
  const content = local.healthData.encounters;

  return (
    <li className="card-list-item">
      <div className='encounter-text'>
        <DatePeriod value={period} />
        <span className='encounter-title'>{name}</span>
        {provider && (
          <span className='condition-description'>
            <small>{content.provider}: {provider}</small>
          </span>
        )}
        {location && (
          <span className='condition-description'>
            <small>{content.location}: {location}</small>
          </span>
        )}
        {diagnoses && (
          <span className='condition-description'>
            <small>{content.diagnoses}: {diagnoses}</small>
          </span>
        )}
      </div>
    </li>
  );
}

EncounterListItem.propTypes = {
  encounter: PropTypes.shape({
    name: PropTypes.string,
    period: PropTypes.oneOfType([PropTypes.object]),
    provider: PropTypes.string,
    location: PropTypes.string,
    diagnoses: PropTypes.string
  })
};
