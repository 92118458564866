import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, AvatarCard } from 'shared';

export default function OrganizationAdminsListItem({ email, profileName }) {
  const heading =  profileName || email;
  const subheading = profileName ? email : null;
  const profile = {
    name: heading,
    theme: 0
  };

  const avatar = <Avatar profile={profile} />;
  return (
    <AvatarCard
      avatar={avatar}
      heading={heading}
      subheading={subheading} />
  );
}

OrganizationAdminsListItem.propTypes = {
  email: PropTypes.string.isRequired,
  profileName: PropTypes.string
};

