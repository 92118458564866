import React from 'react';
import { useSelector } from 'react-redux';
import local from 'services/localization/local';
import styleVars from 'styles/_variables.scss';
import { routePaths } from 'services/routing';
import { ObservationType } from 'services/enums';
import { LilChartDataPointCount } from 'services/constants';
import LilChart from 'health/lil-charts/LilChart';
import { Icon } from 'shared';
import { getLilChartObservationData, getLilChartCurrentObservation } from 'health/observations/observation-selectors';
import { configIds } from 'health/observations/observation-config-ids';
import useObservationData from 'health/observations/hooks/useObservationData';

export default function HeartRateLilChart() {
  const [loading, loadError] = useObservationData(ObservationType.InstantaneousHeartRate, LilChartDataPointCount);
  const heartRates = useSelector(state => getLilChartObservationData(state, ObservationType.InstantaneousHeartRate));
  const currentHeartRate = useSelector(() => getLilChartCurrentObservation(heartRates));

  return (
    <LilChart
      metricIcon={<Icon symbol="observations" className="hero" size="3em" />}
      data={heartRates}
      noDataText={local.observation.heartRate.tileNoDataText}
      color={styleVars.colorTeal}
      title={local.observation.heartRate.title}
      metricValue={currentHeartRate}
      metricUnits={local.units.beatsPerMinute}
      path={{ to: routePaths.healthObservation, params: { configId: configIds.heartRate } }}
      noDataPath={{ to: routePaths.healthLogObservation, params: { configId: configIds.heartRate } }}
      loading={loading}
      loadError={loadError} />
  );
}
