import moment from 'moment';
import { ObservationType } from 'services/enums';
import { getObservationData } from 'health/observations/observation-selectors';

const getSteps = (state, profileId) => {
  const allSteps = getObservationData(state, ObservationType.Steps)
    .filter(x => x.profileId === profileId);

  return allSteps;
};

export const getStepsBarChartData = (state, profileId, selectedEnd,steps,fromPathway) => {
  const startDayMoment = moment(selectedEnd).add(-6, 'days');
  const endDayMoment = moment(selectedEnd);


  const filteredSteps = fromPathway? steps && steps.length > 0 && steps.filter(x => moment(x.recordedAt) >= startDayMoment) :   getSteps(state, profileId).filter(x => moment(x.recordedAt) >= startDayMoment);

  const stepsDataByDate = [];

  while (startDayMoment <= endDayMoment) {
    const date = startDayMoment.format(moment.HTML5_FMT.DATE);

    // group steps data by date
    const stepsDataForDate = filteredSteps.reduce((result, step) => {
      if (isDLSDate(step.recordedAt).format(moment.HTML5_FMT.DATE) === date) {
        // update count of step observations for date
        result.count++;

        // update total step count for date
        result.totalSteps += step.value;
        result.date = isDLSDate(step.recordedAt);
      }
      return result;
    }, {
      date: moment(date),
      totalSteps: 0,
      count: 0
    });

    stepsDataByDate.push(stepsDataForDate);

    startDayMoment.add(1, 'day');
  }
  return stepsDataByDate;
};

export const isDLSDate = (date) =>{
  const isDst=moment(date).isDST();
  if(!isDst){
    return moment(date);
  }
  else{
    const timeStamp = moment(date).clone().subtract(1,'hours').format('LLL');
    return moment(timeStamp);
  }
};

export const getStepsLilChartData = (state) => {
  const steps = getStepsBarChartData(state, state.selectedProfile.id, moment().format()).slice(-7);


  const data = steps.reduce((result, x) => {
    result.weeklySteps += x.totalSteps;

    result.steps.push({
      value: x.totalSteps,
      recordedAt: x.date
    });

    return result;
  }, { steps: [], weeklySteps: 0 });

  data.dailySteps = steps[steps.length - 1].totalSteps;

  return data;
};

export const getStepsAverage = (state, profileId, selectedEnd,steps,fromPathway) =>{
  const result = {
    stepsAverage: 0,
    minValue:0,
    maxvalue: 0
  };

  const lastWeekData =  getStepsBarChartData(state, profileId, selectedEnd);
  if(!lastWeekData || lastWeekData.length <= 0)
    return result;

  const filteredData =  fromPathway ? steps && steps.length > 0 && steps.map(item => item.value): lastWeekData?.filter(n => n.totalSteps > 0);

  if(filteredData.length <= 0)
    return result;
  const stepsList = fromPathway?  filteredData : filteredData.map(item => item.totalSteps);

  result.minValue = Math.min(...stepsList);
  result.maxvalue = Math.max(...stepsList);
  const sumValue = stepsList.reduce((a, b) => { return a + b; });
  result.stepsAverage = Math.trunc(sumValue/filteredData.length);

  return result;
};

