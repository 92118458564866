import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import configs, {getConfigIds} from 'health/observations/observation-configs';
import { buildPath, routePaths } from 'services/routing';
import { NavIcon, ProfileLink} from 'shared';
import { ObservationType } from 'services/enums';
import { roundToDecimals } from 'services/value-formatter';
import local from 'services/localization/local';
import moment from 'moment';


const getFormatedHoursAndMinutes = (duration) => {
  let durationObject = [];
  let unitClassName='healthType';
  let valueClassName='healthValue';

  var daysShortLabel=<span className={unitClassName} key="daysShortlbl" >{local.daysShortLabel}</span>;
  var hoursLabel=<span className={unitClassName} key="hoursLabel" >{local.observation.sleep.hoursLabel}</span>;
  var minutesLabel=<span className={unitClassName} key="minutesLabel" >{local.observation.sleep.minutesLabel}</span>;

  var daysValueLabel=<span className={valueClassName} key="daysValueLabel" ><span className='hr-label'>{duration.days()}</span></span>;
  var hoursValueLabel=<span className={valueClassName} key="hoursValueLabel" ><span className='hr-label'>{duration.hours()}</span></span>;
  var minutesValueLabel=<span className={valueClassName} key="minutesValueLabel" ><span className='hr-label'>{duration.minutes()}</span></span>;

  if (duration.days() > 0) { durationObject.push(daysValueLabel,daysShortLabel,' '); }
  if (duration.hours() > 0) { durationObject.push(hoursValueLabel,hoursLabel,' '); }
  if (duration.minutes() > 0) { durationObject.push(minutesValueLabel,minutesLabel,' '); }

  durationObject.push(<span className={unitClassName}>{`(${local.observation.sleep.title})`}</span>);
  return [durationObject] ;
};

const formatFeetInches = (value,observationType) => {

  let valueClassName='healthValue';
  let result = [];

  const feet = Math.floor(value / 12);
  const inches = Math.round((value % 12) * 100) / 100;

  var feetLabel=<span className={valueClassName} key="feetLabel" >{feet}<span className='feet-icon' key="feetIconLabel"></span></span>;
  var inchLabel=<span className={valueClassName} key="inchLabel" >{inches}<span className='inch-icon' key="inchIconLabel"></span></span>;

  if (feet > 0) { result.push(feetLabel); }
  if (inches > 0) { result.push(inchLabel,' '); }
  result.push(<span className='healthType'>{observationType}</span>);
  return result;
};

const getHealthDatainInches = (observationValue,observationType) =>
  <Fragment> <span className='healthValue' key={`span-${observationType}`}>{`${roundToDecimals(observationValue, 2)}`}<span className='inch-icon'></span></span> <span className='healthType'>{observationType}</span></Fragment>;

const getFormattedHealthData = (config,observation,title) =>
  <Fragment> <span className='healthValue'>{config.valueFormatter({ observation, config })}</span> <span className='healthType'>{title}</span></Fragment>;

const getHealthDataWithUnitTitle = (observationValue,observationType) =>
  <Fragment> <span className='healthValue'>{`${roundToDecimals(observationValue, 2)}`}</span> <span className='healthType'>{observationType}</span></Fragment>;


export const getFormatedValue = (observationType, observation, config) => {
  try
  {
    switch(observationType){
      case ObservationType.InstantaneousHeartRate:
        return getHealthDataWithUnitTitle(observation.value,`${local.units.beatsPerMinute} (${local.observation.heartRate.title})`);
      case ObservationType.BodyTemperature:
        return getFormattedHealthData(config,observation,`(${local.observation.temperature.title})`);
      case ObservationType.Height:
        return formatFeetInches(observation.value,local.observation.height.title);
      case ObservationType.Weight:
        return getHealthDataWithUnitTitle(observation.value,`${local.units.pounds} (${local.observation.weight.title})`);
      case ObservationType.Steps:
        return getHealthDataWithUnitTitle(observation.value,local.observation.steps.title);
      case ObservationType.BodyArms:
        return getHealthDatainInches(observation.value,local.observation.bodyArms.title);
      case ObservationType.BodyChest:
        return getHealthDatainInches(observation.value,local.observation.bodyChest.title);
      case ObservationType.BodyHips:
        return getHealthDatainInches(observation.value,local.observation.bodyHips.title);
      case ObservationType.BodyThighs:
        return getHealthDatainInches(observation.value,local.observation.bodyThighs.title);
      case ObservationType.BodyWaist:
        return getHealthDatainInches(observation.value,local.observation.bodyWaist.title);
      case ObservationType.BodyFat:
        return getFormattedHealthData(config,observation,local.observation.bodyFat.title);
      case ObservationType.BloodOxygen:
        return getFormattedHealthData(config,observation,local.observation.bloodOxygen.title);
      case ObservationType.Insulin:
        return <Fragment> <span className='healthValue'>{`${roundToDecimals(observation.value, 2)}`}</span> {config.unitLabelMap[observation.unit]} <span className='healthType'> {`(${local.observation.insulin.title})`}</span></Fragment>;
      case ObservationType.PeakFlow:
        return getHealthDataWithUnitTitle(observation.value,`${local.healthData_v1Dashboard.litersPerMinute} (${local.observation.peakFlow.title})`);
      case ObservationType.BloodGlucose:
        return getHealthDataWithUnitTitle(observation.value,`${local.units.mgdL} (${local.observation.bloodGlucose.title})`);
      case ObservationType.Systolic:
      case ObservationType.Diastolic:
        return <Fragment> <span className='healthValue'>{`${roundToDecimals(observation.value, 2)}/${roundToDecimals(observation.valueDiastolic, 2)}`}</span> <span className='healthType'>{local.observation.bloodPressure.title}</span></Fragment>;
      case ObservationType.Sleep:
        return getFormatedHoursAndMinutes(moment.duration(observation.value, local.units.seconds));
      case ObservationType.Nutrition:
        return getHealthDataWithUnitTitle(observation.value,local.healthData_v1Dashboard.calories);
      case ObservationType.LabResult:
        return config.valueFormatter({ observation, config });
      case ObservationType.FloorsClimbed:
        return getHealthDataWithUnitTitle(observation.value,local.units.floorsClimbed);
      case ObservationType.BloodKetone:
          return getHealthDataWithUnitTitle(observation.value,`${local.units.mgdL} (${local.observation.bloodKetone.title})`);
      default:
        return '';
    }
  }
  catch(ex)
  {
    return '';
  }
};

export default function DashboardNavLinkV1(props) {

  const configId = getConfigIds(props.observationType);
  const config = configs[configId];
  const observation ={ value: props.observationValue, valueDiastolic: props.valueDiastolic, unit: config.typeUnitMap[props.observationType] };
  const backPathRoute = props.fromHealthData ? routePaths.health : routePaths.root;
  return (
    <ProfileLink
      className="with-chevron with-padding"
      to={buildPath(routePaths.healthObservation, { configId: config.id })}
      id={`${config.id}-link`}
      backpath={backPathRoute} >
      <NavIcon Icon={config.iconSymbol} />
      <div>
        { getFormatedValue(props.observationType, observation, config)}
      </div>
    </ProfileLink>
  );
}

DashboardNavLinkV1.propTypes = {
  observationType: PropTypes.number.isRequired,
  observationValue: PropTypes.number.isRequired,
  valueDiastolic: PropTypes.number
};
