import branch from 'branch-sdk';
import { branchKey } from 'app/config';
import { localStoragePaths } from 'services/constants';

export const saveCurrentBranchData = (onSave) => {
  if (branchKey) {
    // Save branch data associated with user and link if branch link has been clicked and then perform action
    branch.init(branchKey, (_error, data) => {
      if (data && data['data_parsed'] && data['data_parsed']['+clicked_branch_link']) {
        localStorage.setItem(localStoragePaths.branchLinkData, data.data);

        const redirectUrl = data['data_parsed']['redirect'];
        if (redirectUrl) {
          localStorage.setItem(localStoragePaths.redirect, redirectUrl);
        }
      }

      onSave();
    });
  } else {
    // if there is no key present, do nothing and perform action
    onSave();
  }
};
