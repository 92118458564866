import React from 'react';
import local from 'services/localization/local';
import { ObservationType, Unit } from 'services/enums';
import { routePaths } from 'services/routing';
import { configIds } from 'health/observations/observation-config-ids';
import { baseObservationConfig } from 'health/observations/observation-config-helpers.jsx';
import { GenericObservationDeviceLinkNoData } from 'health/observations/generics';
import { FloorsClimbed } from 'shared/fullColorIcons';

const typeDefinition = {
  type: ObservationType.FloorsClimbed,
  typeUnitMap: {
    [ObservationType.FloorsClimbed]: Unit.Count
  },
  unitLabelMap: {
    [Unit.Count]: local.units.floorsClimbed
  }
};

export default {
  ...baseObservationConfig,
  ...typeDefinition,
  id: configIds.floorsClimbed,
  backPath: { to: routePaths.health },
  centerElement: local.observation.floorsClimbed.title,
  noDataText: local.observation.floorsClimbed.noDataText,
  iconSymbol: FloorsClimbed,
  renderNoDataComponent: (props) => props && <GenericObservationDeviceLinkNoData message={props.config.noDataText} />
};
