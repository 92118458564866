import local from 'services/localization/local';
import { configIds } from 'health/observations/observation-config-ids';

export const ACTIVITY_KEY = 'activity';
export const DOSES_KEY = 'doses';
export const JOURNAL_KEY = 'journal';

export const getFilterTypes = () => {
  const filterTypes = [
    { value: ACTIVITY_KEY, label: local.activityFormNameLabel },
    { value: DOSES_KEY, label: local.medicationDoseLogHeaderDefault },
    { value: JOURNAL_KEY, label: local.journal.journalEntry }
  ];

  Object.keys(configIds).forEach((key) => {
    filterTypes.push({ value: configIds[key], label: local.observation[key].title });
  });

  return filterTypes.sort((a, b) => {
    return (a.label < b.label) ? -1 : ((a.label > b.label) ? 1 : 0);
  });
};

