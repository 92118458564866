import React from 'react';
import local from 'services/localization/local';
import { LoadingPage } from 'shared';

export default function AuthLoader() {
  return (
    <div id="main-layout">
      <LoadingPage centerElement={local.signingIn} />
    </div>
  );
}
