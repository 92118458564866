import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import local from 'services/localization/local';
import './pathway-context-details.scss';

export default function PathwayContextDetails({ pathwayContext }) {
  const { startDate, endDate, taskName, pathwayName, pathwayIconPath } = pathwayContext || {};
  const start = moment(startDate || null);
  const end = moment(endDate || null);

  const hasHeader = !!(taskName && pathwayName);
  const hasDateRange = start.isValid() || end.isValid();

  if (!hasHeader && !hasDateRange) {
    return null;
  }

  let dateMessage;
  if (hasDateRange) {
    if (start.isValid() && end.isValid()) {
      dateMessage = local.formatString(local.observation.context.dateBetween, start.format(local.simpleDateTimeFormat), end.format(local.simpleDateTimeFormat));
    } else if (start.isValid()) {
      dateMessage = local.formatString(local.observation.context.dateAfter, start.format(local.simpleDateTimeFormat));
    } else {
      dateMessage = local.formatString(local.observation.context.dateBefore, end.format(local.simpleDateTimeFormat));
    }
  }

  return (
    <div data-testid="pathway-context-info" className="observation-context">
      <small className="pathway-info">
        <figure className="pathway-icon" style={{ backgroundImage: `url(${pathwayIconPath})` }} />
        <span className="pathway-name">{pathwayName}</span>
      </small>
      <h4 className="pathway-task">{taskName}</h4>
      {
        hasDateRange && (
          <p className="date-range">
            {dateMessage}
          </p>
        )
      }
    </div >
  );
}

PathwayContextDetails.propTypes = {
  pathwayContext: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    taskName: PropTypes.string,
    pathwayName: PropTypes.string,
    pathwayIconPath: PropTypes.string
  }).isRequired
};
