import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextField } from 'shared/form-fields';
import * as validators from 'services/fields/field-validators';

class DateTimeTextFieldWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.getValidate = this.getValidate.bind(this);
  }

  getValidate() {
    const { validate } = this.props;
    if (validate) {
      return [validators.dateTimeLocal, ...validate];
    }
    return [validators.dateTimeLocal];
  }

  render() {
    const validate = this.getValidate();

    return (
      <Field
        {...this.props}
        validate={validate}
        component={TextField}
        type="datetime-local" />
    );
  }
}

DateTimeTextFieldWrapper.propTypes = {
  validate: PropTypes.arrayOf(PropTypes.shape)
};

export default DateTimeTextFieldWrapper;
