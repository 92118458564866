import React, { useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import local from 'services/localization/local';
import { Page, CardList } from 'shared';
import { routePaths } from 'services/routing';
import { getProfileId } from 'app/selectors';
import { loadTimelineData } from './timeline-actions';
import { clearTimelineFilter } from './actions';
import { isTimelineLoading, isTimelineLoadError, getTimelineData, getFilter } from './timeline-selectors';
import TimelineNoData from './TimelineNoData';
import TimelineListItem from './list/TimelineListItem';
import TimelineFilterToggle from './filter/TimelineFilterToggle';
import './timeline.scss';

export default function Timeline(props) {
  const dateRangeStart = moment().startOf('day').add(-7, 'days');
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId, shallowEqual);
  const loading = useSelector(isTimelineLoading);
  const error = useSelector(isTimelineLoadError);
  const timelineData = useSelector(state => getTimelineData(state, dateRangeStart), shallowEqual);
  const filters = useSelector(getFilter);

  useEffect(() => {
    dispatch(clearTimelineFilter());
    dispatch(loadTimelineData(dateRangeStart.format()));
  }, [profileId]);

  return (
    <Page
      id="timeline-page"
      loading={loading}
      error={error}
      backPath={{ to: props.location?.state?.backpath ?? routePaths.root }}
      centerElement={local.timeline.title}
      primaryAction={<TimelineFilterToggle />}>
      {filters.length > 0 && <div className='filters-in-effect'>{local.timeline.filtersApplied}</div>}
      {timelineData.length === 0 && <TimelineNoData />}
      {
        timelineData.length > 0 && (
          <CardList testId="timeline-list">
            {timelineData.map(x => <TimelineListItem key={x.data.id} item={x} />)}
          </CardList>
        )
      }
    </Page >
  );
}
