import React, { useEffect} from 'react';
import { routePaths } from 'services/routing';
import { Loading, Page} from 'shared';
import local from 'services/localization/local';
import './MyGoal.scss';
import GoalCard from './GoalCard';
import StaticGoalModal from './StaticGoalModal';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileId } from 'app/selectors';
import { clearProfileGoals, loadGoalCategory, loadProfileGoals } from './goal-actions';
import { getGoalComponentProps } from './goal-selectors';

export default function MyGoal(){
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const {goalCategory:goalCategories, profileGoals, loading, hasError, goalCategoryLoading} = useSelector(state => getGoalComponentProps(state));
  useEffect(() => {
    dispatch(loadGoalCategory(profileId));
    dispatch(loadProfileGoals(profileId));
  },[profileId]);

  useEffect(() => {
    return () =>{
      dispatch(clearProfileGoals(profileId));
    };
  },[]);

  const renderGoalCard = () =>{
    if(loading || goalCategoryLoading){
      return <Loading></Loading>;
    }
    if(!profileGoals || profileGoals?.length === 0 || hasError){
      return(
        <div>
          <div className='calciumMyGoal'></div>
          <div>
            <ul className='card-list'>
              <li className="card-list-item">
                <div className="myGoal-info no-content">
                  <span>{local.goals.noGoal}</span> <span>{local.goals.createNewGoal}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      );
    }
    return(
      <div>
        <div className='calciumMyGoal'></div>
        <div>
          {profileGoals?.map((profileGoal) => {
            const goalCategory = goalCategories?.find(x => x.id === profileGoal?.goalCategoryId);
            return (<GoalCard key={profileGoal.id} profileGoal={profileGoal} goalCategory={goalCategory}></GoalCard>);
          })}
        </div>
      </div>
    );
  };

  return(
    <Page
      id="my-goal"
      backPath={{to:routePaths.root}}
      centerElement={local.goals.title}
      primaryAction={<StaticGoalModal goalCategories={goalCategories}></StaticGoalModal>}>
      {renderGoalCard()}
    </Page>
  );
}
