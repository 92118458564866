import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { buildPath, routePaths } from 'services/routing';
import { ProfileLink, DashboardNavLinkV1, NavIcon, Card, Icon, LinkWrapper } from 'shared';
import Logo from 'shared/logos/svg-mark/SvgMark.jsx';
import { loadDashBoardObservation, loadDashboardObservationEhrData, loadHealthScore, loadAllHighFrequencyData } from 'health/action-creators';
import { getProfileId, getHealthScore } from 'app/selectors';
import { getDashBoardBmi, getDashBoardExercise, getDashBoardObservation } from 'health/health-selectors';
import local from 'services/localization/local';
import configs, { getConfigIds, getDashboardObservationTitle } from 'health/observations/observation-configs';
import { healthDataType, ObservationType, Unit } from 'services/enums';
import { BodyMassIndex, Activities } from 'shared/fullColorIcons';
import { roundToDecimals } from 'services/value-formatter';
import './health-data-v1.scss';
import { Bmi } from 'services/constants';
import { getObjectKeyByValue } from 'services/enums-helpers';
import { buildDurationDisplayText } from 'services/date-service';
import { HealthDataHelperActivityText } from './HealthDataHelper';

export default function HealthNavigationV1({ fromHealthData }) {
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  let observations = useSelector(state => getDashBoardObservation(state, profileId));
  const dashBoardBmi = useSelector(state => getDashBoardBmi(state, profileId));
  const dashBoardExercise = useSelector(state => getDashBoardExercise(state, profileId, fromHealthData));
  let healthScore = useSelector(getHealthScore);
  const formatter = value => roundToDecimals(value, 1);
  const observationSteps = observations && observations !== undefined && observations.filter(x => x.observationType === ObservationType.Steps);
  let valueDiastolic = null;
  let unitDiastolic = null;
  const backPathRoute = fromHealthData ? routePaths.health : routePaths.root;
  const observationStepsObj = observationSteps && observationSteps !== undefined && observationSteps.length > 0 && observationSteps[0];
  const stepsConfigId = observationStepsObj && observationStepsObj !== undefined && getConfigIds(observationStepsObj.observationType);
  const stepsConfig = stepsConfigId && stepsConfigId !== undefined && configs[stepsConfigId];
  const excludedObservations = [ObservationType.Immunization, ObservationType.LabResult, ObservationType.Steps, ObservationType.Diastolic, ObservationType.FloorsClimbed];
  const ObservationTypeUnits = [
    {
      ObservationType: ObservationType.BloodOxygen,
      Unit: Unit.Percent
    },
    {
      ObservationType: ObservationType.Systolic,
      Unit: Unit.MillimetersOfMercury
    },
    {
      ObservationType: ObservationType.Diastolic,
      Unit: Unit.MillimetersOfMercury
    },
    {
      ObservationType: ObservationType.InstantaneousHeartRate,
      Unit: Unit.BeatsPerMinute
    },
    {
      ObservationType: ObservationType.Weight,
      Unit: Unit.Pounds
    },
    {
      ObservationType: ObservationType.Height,
      Unit: Unit.Inches
    },
    {
      ObservationType: ObservationType.BodyTemperature,
      Unit: Unit.Fahrenheit
    }
  ];

  useEffect(() => {
    dispatch(loadDashboardObservationEhrData({ profileId: profileId, observationType: ObservationTypeUnits[0].ObservationType, observationTypeUnits: ObservationTypeUnits }));
    dispatch(loadAllHighFrequencyData(profileId));
    dispatch(loadDashBoardObservation(profileId));
    dispatch(loadHealthScore(profileId));
  }, [profileId]);

  const renderNewHealthData = () => {
    let sortedObservations = [];
    const duration = buildDurationDisplayText(dashBoardExercise.startDateTime, dashBoardExercise.endDateTime);
    if (observations && observations !== undefined) {
      let observationDiastolic = observations.filter(x => x.observationType === ObservationType.Diastolic);
      if (observationDiastolic && observationDiastolic !== undefined) {
        if (observationDiastolic.length > 0 && observationDiastolic[0]) {
          valueDiastolic = observationDiastolic[0].value;
          unitDiastolic = Unit.MillimetersOfMercury;
          observations = observations.filter(x => x.observationType !== ObservationType.Diastolic);
        }
      }

      observations.map(x => {
        sortedObservations.push({ ...x, title: getDashboardObservationTitle(x.observationType) });
      });

    }

    if (dashBoardBmi && dashBoardBmi > 0) {
      sortedObservations.push({
        title: local.bmiTitle,
        value: formatter(dashBoardBmi),
        icon: BodyMassIndex,
        route: routePaths.healthBodyMeasurements
      });
    }

    if (dashBoardExercise && !fromHealthData) {
      sortedObservations.push({
        title: local.healthData_v1Dashboard.exercise,
        value: `${formatter(dashBoardExercise)}x`,
        icon: Activities,
        route: routePaths.healthActivity

      });
    }

    // For health data Page need to show exercise in 5h2m3s format
    if (dashBoardExercise && fromHealthData) {
      sortedObservations.push({
        value: HealthDataHelperActivityText(duration, dashBoardExercise.name),
        icon: Activities,
        route: routePaths.healthActivity

      });
    }

    return (sortedObservations && sortedObservations !== undefined && sortedObservations.length > 0 && sortedObservations.map((x) => (
      x && x.observationType ?
        <Card key={`${'card-'} ${x.observationType}`}>
          <DashboardNavLinkV1 observationType={x.observationType} configId={getConfigIds(x.observationType)} observationValue={x.value} observationUnit={x.unit} valueDiastolic={valueDiastolic} unitDiastolic={unitDiastolic} fromHealthData={fromHealthData} />
        </Card> :
        <Card key={`${'card-'} ${x.title}`}>
          <ProfileLink
            className="with-chevron with-padding"
            to={x.route}
            id={x.title} backpath={backPathRoute}>
            <NavIcon Icon={x.icon}></NavIcon>
            <span >
              <span className='healthValue'>{x.value}</span> <span>{x.title}</span>
            </span>
          </ProfileLink>
        </Card>

    )));

  };

  const hasNoData = () =>
    !observations && (!dashBoardBmi || dashBoardBmi <= 0) && (!dashBoardExercise || dashBoardExercise <= 0);

  const showAddData = () => {

    let hasMissingData = false;

    if (hasNoData())
      return true;

    if (observations) {
      for (const key in ObservationType) {
        if (!excludedObservations.find(o => o === ObservationType[key]) && ObservationType[key] != ObservationType.None
          && !observations.find(o => o.observationType === ObservationType[key])) {
          hasMissingData = true;
        }
      }
    }
    else {
      hasMissingData = true;
    }

    if (!hasMissingData && (!dashBoardBmi || dashBoardBmi <= 0 || !dashBoardExercise || dashBoardExercise <= 0
      || !observationStepsObj || observationStepsObj.value <= 0))
      hasMissingData = true;

    return hasMissingData;
  };

  const getMissingData = () => {
    const missingData = [];
    for (const key in ObservationType) {
      if (!excludedObservations.find(o => o === ObservationType[key]) && ObservationType[key] != ObservationType.None
        && (!observations || !observations.find(o => o.observationType === ObservationType[key])))
        missingData.push(key);
    }

    if (!dashBoardExercise || dashBoardExercise <= 0)

      missingData.push(getObjectKeyByValue(healthDataType, healthDataType.Activity));

    if (!dashBoardBmi || dashBoardBmi <= 0)
      missingData.push(Bmi);

    if (!observationStepsObj || observationStepsObj.value <= 0)
      missingData.push(getObjectKeyByValue(ObservationType, ObservationType.Steps));
    return missingData;
  };

  const addOtherData = showAddData() && getMissingData() && getMissingData().length > 0;

  return (
    <div className="health-navigation">
      {addOtherData ?
        <div className='heathNav-content mb-4 mt-3'>
          <div>Use the Calcium features below to...</div>
          <br />
          <ol>
            <li>1. Manually record health data</li>
            <li>2. Integrate with popular devices and apps to automatically collect your health data</li>
            <li>3. Access your electronic health records</li>
            <li>4. Manually add personal health record</li>
          </ol>
        </div> : null}
      <div className='healthNav-header mt-3'>{local.healthData_v1Dashboard.title}</div>
      <ul >
        {healthScore && !fromHealthData ?
          <Card>
            <ProfileLink
              className="with-chevron with-padding"
              to={routePaths.healthIndex}
              id='hIndex' >
              <div className='hc-logo-width'> <Logo /> </div>
              <span>
                <span className='healthValue'>{healthScore ? `${roundToDecimals(healthScore, 0)}` : <Icon className="magnifying-glass" symbol="infoHealthIndexIcon" />}</span> <span>{local.healthData_v1Dashboard.healthIndex}</span>
              </span>
            </ProfileLink>
          </Card> :
          (
            !fromHealthData ?
              <div className='addData-card'>
                <Card>
                  <LinkWrapper
                    to={routePaths.healthIndex}
                    id='addHealthIndex'>
                    <Icon symbol="addCircle" /> {local.healthData_v1Dashboard.generateHealthIndex}
                  </LinkWrapper>
                </Card>
              </div>
              : ''
          )}

        {observationStepsObj != undefined && stepsConfig != undefined && observationStepsObj.value > 0 ? '' :
          <div className='addData-card'>
          <Card>
              <ProfileLink
              className="addData-card"
              to={buildPath(routePaths.healthObservation, { configId: getConfigIds(ObservationType.Steps) })}
              id='track-steps-link'
              backpath={backPathRoute} >
              <Icon symbol="addCircle" /> {local.healthData_v1Dashboard.trackSteps}
            </ProfileLink>
            </Card>
        </div>}

        {renderNewHealthData()}

        {showAddData() && getMissingData() && getMissingData().length > 0 &&
          <div className='addData-card'>
            <Card>
              <LinkWrapper
                to={buildPath(routePaths.healthAddItem)}
                id='addHealthData' backpath={{ to: backPathRoute, enabledData: getMissingData() }}>
                <Icon symbol="addCircle" /> {local.healthData_v1Dashboard.addOtherData}
              </LinkWrapper>
            </Card>
          </div>}
      </ul>

    </div>
  );
}
