/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { routePaths } from 'services/routing';
import { Button, Icon, NavAction, Page } from 'shared';
import { ErrorCode } from 'services/enums';
import local from 'services/localization/local';
import { acceptHipaaConsentPage } from 'profiles/action-creators';
import { getProfile, getSelfProfileId } from 'profiles/list/selectors';
import HipaaAuthorization from './HipaaAuthorization';
import './hipaa.scss';

function HipaaConsentPage() {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile, shallowEqual);
  const selfProfileId = useSelector(getSelfProfileId, shallowEqual);
  const profileName = profile && profile.name || '';


  const onAccept = () => {
    dispatch(acceptHipaaConsentPage(profile.id));
  };

  let name = <i>{profileName}</i>;
  let contextMessage = null;
  let showIntro = false;
  switch (profile.hipaaConsentResult.errorCode) {
    case ErrorCode.HipaaConsentExpired:
      showIntro = true;
      contextMessage = local.formatString(local.hipaaReconsentIntroP1, name);
      break;
    case ErrorCode.HipaaConsentVersionOutdated:
      showIntro = true;
      contextMessage = local.formatString(local.hipaaConsentNewVersionIntroP1, name);
      break;
    default:
      break;
  }

  const intro = (
    <div className="intro">
      <small><strong>{local.hipaaConsentIntroGreeting}</strong></small>
      <br />
      <br />
      <small><strong>{contextMessage}</strong></small>
      <br />
      <br />
      <small>{local.hipaaConsentIntroP2}</small>
    </div>
  );

  const consent = (
    <div className="consent-section">
      <p><strong>{local.hipaaConsentP1}</strong></p>
      <small>{local.hipaaConsentP2}</small>
    </div>
  );

  const stickyOverlay = (
    <div className="accept-wrapper">
      <div className="sticky-section text-center">
        <Button id="hipaa-accept-button" color="transparent" onClick={onAccept}>{local.agree}</Button>
      </div>
    </div>
  );

  const backPath = profile.id !== self ? { to: routePaths.root, params: { profileId: selfProfileId } } : null;

  return (
    <Page
      id='hipaa-consent-page'
      centerElement={<Icon className="center" symbol="hipaaauth" />}
      primaryAction={<NavAction mobileOnly text={local.linkSignout} iconSymbol="signout" to={routePaths.signout} />}
      titleTabText={local.hipaaAuthorization}
      backPath={backPath}
      hideBottomNav>
      <div className="hipaa-consent-container">
        <div className="scrollable">
          {showIntro && intro}
          <HipaaAuthorization />
          {consent}
        </div>
        {stickyOverlay}
      </div>
    </Page>
  );
}

export default HipaaConsentPage;
