import moment from 'moment';
import * as requestQueueSelectors from 'app/request/request-queue-selectors';
import * as dataSelectors from 'app/request/persistent-data-selectors';
import { roundToDecimals } from 'services/value-formatter';
import { compareDatesAscending } from 'services/date-service';
import local from 'services/localization/local';
import { types } from './activity-types';

export const getActivityRequest = (state, requestId) => {
  return requestQueueSelectors.getRequestById(state.activity, types.ACTIVITY_REQUESTS, requestId);
};

export const isActivitiesLoading = (state, profileId) => {
  return !!dataSelectors.isLoading(state.activity, types.ACTIVITIES, profileId);
};

export const isActivitiesLoadError = (state, profileId) => {
  return !!dataSelectors.hasError(state.activity, types.ACTIVITIES, profileId);
};

export const getActivities = (state, profileId) => {
  const activities = dataSelectors.getData(state.activity, types.ACTIVITIES, profileId) || [];
  return activities.filter(x => x.profileId === profileId && !x.deleted);
};

export const getActivitiesForChart = (state, profileId) => {
  const allActivities = getActivities(state, profileId);
  const weekActivities = allActivities
    .filter(x => moment(x.startDateTime) >= moment().startOf('day').add(-6, 'days'));

  if (allActivities.length > 0 && weekActivities.length === 0) {
    return [{ value: 0, recordedAt: moment().startOf('day').format() }];
  }

  return weekActivities
    .sort((a, b) => compareDatesAscending(a.startDateTime, b.startDateTime))
    .reduce((prev, x) => {
      const date = moment(x.startDateTime).startOf('day').format();
      if (prev.length == 0 || prev[prev.length - 1].recordedAt != date) {
        return [
          ...prev,
          {
            value: moment.duration(x.duration),
            recordedAt: date
          }
        ];
      } else {
        prev[prev.length - 1].value += moment.duration(x.duration);
        return prev;
      }
    }, [])
    .map(x => ({
      value: moment.duration(x.value).asMinutes(),
      recordedAt: x.recordedAt
    }));
};

export const getWeeklyActivityValue = (activities) => {
  const minutes = activities
    .reduce((prev, x) => prev + x.value, 0);

  const duration = moment.duration(minutes, 'minutes');
  if (duration.asHours() >= 1.1) {
    return {
      value: roundToDecimals(duration.asHours(), 1),
      label: local.hoursLabel
    };
  } else {
    return {
      value: roundToDecimals(duration.asMinutes(), 1),
      label: local.minutesLabel
    };
  }
};

export const getActivityBarChartData = (state, profileId, selectedEnd) => {
  const startDayMoment = moment(selectedEnd).add(-4, 'weeks');
  const endDayMoment = moment(selectedEnd);

  const activities = getActivities(state, profileId).filter(x => moment(x.startDateTime) >= startDayMoment);

  const activityDataByDate = [];

  while (startDayMoment <= endDayMoment) {
    const date = startDayMoment.format(moment.HTML5_FMT.DATE);

    // group activity data by date
    const activityDataForDate = activities.reduce((result, activity) => {
      if (moment(activity.startDateTime).format(moment.HTML5_FMT.DATE) === date) {
        // update count of activities for date
        result.count++;

        // update total duration for date
        const durationInMinutes = moment.duration(activity.duration).asMinutes();
        result.totalDurationInMinutes += durationInMinutes;

        // update the activityIntensityModifier for date
        const activityIntensityModifier = activity.intensity * durationInMinutes;
        result.totalIntensityModifier += activityIntensityModifier;
      }
      return result;
    }, {
      date: moment(date).format('M/D'),
      totalDurationInMinutes: 0,
      count: 0,
      totalIntensityModifier: 0.0
    });

    activityDataByDate.push(activityDataForDate);

    startDayMoment.add(1, 'day');
  }

  return activityDataByDate;
};
