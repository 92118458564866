import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getProfileId } from 'app/selectors';
import LinkWrapper from './LinkWrapper';

export class ProfileLink extends React.Component {
  render() {
    // eslint-disable-next-line react/prop-types, no-unused-vars
    const { profileId, params, history, location, match, staticContext, dispatch, ...rest } = this.props;

    return (
      <LinkWrapper params={{ profileId, ...params }} {...rest} />
    );
  }
}

ProfileLink.propTypes = {
  params: PropTypes.shape(),
  // store
  profileId: PropTypes.string
};

function mapStateToProps(state, props) {
  return {
    profileId: getProfileId(state, props)
  };
}

export default withRouter(connect(mapStateToProps)(ProfileLink));
