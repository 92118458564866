import React from 'react';
import { useField } from 'formik';
import SubLabelSection from 'shared/form-fields/SubLabelSection';
import 'shared/form-fields/form-fields.scss';

export default function SelectField({ label, subLabel, ...props }) {
  const [field, meta] = useField(props);
  const hasError = !!(meta.touched && meta.error);
  return (
    <div className="formField">
      <div className="select-wrapper">
        <select
          className={hasError ? 'inputError' : ''}
          {...field}
          {...props} />
        <label htmlFor={props.id || props.name}>{label}</label>
        <SubLabelSection showError={hasError} error={meta.error} subLabel={subLabel} />
      </div>
    </div>
  );
}
