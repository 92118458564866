/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, Icon, LinkWrapper, NavIcon } from 'shared';
import local from 'services/localization/local';
import { buildPath, routePaths } from 'services/routing';
import './observation-connect.scss';
import { getObservationIcon } from 'devices/device-helper';
import { ObservationConnectionStatus, ObservationType } from 'services/enums';
import { Deactivate, Manual, ManuallyTrackedObservations } from 'services/constants';
import InfoModal from './InfoModal';

export default function ObservationCard(props){
  const programId = props.programId;
  const content = local.observationConnect;
  const observationType = props.observationType ?? ObservationType.None;
  const isManualEntrySupported = props.isManualSupported;
  const status = props.status;
  const deviceSourceName = props.deviceSourceName;
  const supportedDeviceSourceNames = props.supportedDeviceSourceNames;
  const [showManualEntryPopup, setShowManualEntryPopup] = useState(false);
  const [actionName, setActionName] = useState('');
  const goalName = props.pathwayName;
  const goalCategoryId = props.goalCategoryId;

  function showManualEntryModal(action) {
    setShowManualEntryPopup(true);
    setActionName(action);
  }

  function closeManualEntryModal() {
    setShowManualEntryPopup(false);
  }

  if(showManualEntryPopup){
    return(
      <InfoModal isOpen={showManualEntryPopup} programId={programId} action={actionName} observationType={observationType} onRequestClose={closeManualEntryModal} />
    );
  }
  const renderCloseIcon = () =>{
    if(props.primaryObservationType === observationType){
      return null;
    }
    else if(status === ObservationConnectionStatus.Unknown && !ManuallyTrackedObservations.includes(observationType)){
      return (
        <div className="observationConnect-close">
          <button type='button' onClick={() => showManualEntryModal(Deactivate)}><Icon symbol="cross" size="2.3em" data-testid='remove' /></button>
        </div>
      );
    }
  };

  return(
    <ul className='card-list'>
      <li className="card-list-item">
        <div className="observationConnect-info">
          <div className='observationConnect-cardHeader'>
            <figure className="observationConnect-image">
              <NavIcon Icon={getObservationIcon(observationType)}></NavIcon>
            </figure>
            <span className="observationConnect-title">{local.observationsTitle[observationType]}</span>
            {renderCloseIcon()}
          </div>
          <div className="observationConnect-cardBody">
            <span>{local.observationTypeDescriptions[observationType]}</span>
            <div className='bold'>{status === ObservationConnectionStatus.DeviceConnected ? <div>{deviceSourceName} {content.connectedDeviceName}</div> : null}</div>
          </div>

        </div>
        <div className="observationConnect-cardFooter">

          {isManualEntrySupported ?
            <Button id="observationConnect-btn" onClick={() => showManualEntryModal(Manual)} className="btn btn-secondary small-btn">
              {content.manualEntry}
            </Button>
            : null}

          {status !== ObservationConnectionStatus.DeviceConnected && !ManuallyTrackedObservations.includes(observationType) ?
            <LinkWrapper id='observationConnect-btn' className="btn btn-primary small-btn" to={routePaths.connectDevice} backpath={{goalCategoryId: goalCategoryId, goalName: goalName ,addDevice: true, programId:programId, supportedDeviceSourceNames: supportedDeviceSourceNames, observationType: observationType, fromPath:buildPath(routePaths.observationConnect)}}>{content.addDevice}</LinkWrapper>
            : null}

          {status === ObservationConnectionStatus.DeviceConnected ?
            <LinkWrapper id='observationConnect-btn' className="btn btn-secondary small-btn" to={routePaths.connectDevice} backpath={{goalCategoryId: goalCategoryId, goalName: goalName, programId:programId, deviceSourceName: deviceSourceName, observationType: observationType, fromPath:buildPath(routePaths.observationConnect)}}>{content.changeDevice}</LinkWrapper>
            : null}
        </div>
      </li>
    </ul>
  );
}
