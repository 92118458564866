import { getConfigForObservationType } from 'health/observations/observation-configs';
import { ObservationType } from 'services/enums';

export const getUnitForObservationType = (observationType) => {
  if(!observationType || observationType === ObservationType.HeartRateVariability || observationType === ObservationType.Exercise ){
    return 0;
  }
  const config = getConfigForObservationType(observationType);
  return config.typeUnitMap[observationType];
};
