import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ActivityVisualization from './ActivityVisualization';

class ActivityVisualizationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.selectPreviousFourWeeks = this.selectPreviousFourWeeks.bind(this);
    this.selectNextFourWeeks = this.selectNextFourWeeks.bind(this);
    this.updateSelectedStart = this.updateSelectedStart.bind(this);

    this.state = {
      selectedEnd: moment().format(moment.HTML5_FMT.DATE)
    };
  }

  selectPreviousFourWeeks() {
    const previousSelectedStart = moment(this.state.selectedEnd).add(-4, 'weeks').format();
    this.updateSelectedStart(previousSelectedStart);
  }

  selectNextFourWeeks() {
    const nextSelectedStart = moment(this.state.selectedEnd).add(4, 'weeks').format();
    this.updateSelectedStart(nextSelectedStart);
  }

  updateSelectedStart(startDate) {
    this.setState({ selectedEnd: startDate });
  }

  render() {
    const { hide } = this.props;
    if (hide) {
      return null;
    }

    return (
      <ActivityVisualization
        selectPreviousFourWeeks={this.selectPreviousFourWeeks}
        selectNextFourWeeks={this.selectNextFourWeeks}
        selectedEnd={this.state.selectedEnd} />
    );
  }
}

ActivityVisualizationContainer.propTypes = {
  hide: PropTypes.bool
};

export default ActivityVisualizationContainer;
