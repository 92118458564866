import compileReducers from 'services/compile-reducer';
import { PROFILE_SELECT } from './selected-profile-actions';
import * as profileActions from 'profiles/action-names';

const initialState = {
  id: null
};

const reducers = {};

reducers[PROFILE_SELECT] = (state, profileId) => ({
  ...state,
  id: profileId
});

reducers[profileActions.PROFILE_SELFCREATED] = (state, profile) => ({
  ...state,
  id: profile.id
});

reducers[profileActions.PROFILE_LOADED] = (state, profile) => ({
  ...state,
  id: state.id || profile.id
});

export default compileReducers(reducers, initialState);
