import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Page404 from 'app/page404/Page404';
import local from 'services/localization/local';
import { buildPath, routePaths, ProgramsContext } from 'services/routing';
import { Page, ProfileRedirect, PersistentDataStatus } from 'shared';
import { types } from 'programs/programs-types';
import { loadProgram, loadProgramPermissions } from 'programs/programs-actions';
import * as selectors from 'programs/programs-selectors';
import ProgramTile from './ProgramTile';
import { noImmunizationPathwayId } from 'app/config';
import { ObservationTypesForConnection } from 'services/constants';
import { setLocalStorage } from 'services/helpers';
import {  InvitedProgramId } from 'services/enums';

export default function ProgramEnrollment(props) {
  const dispatch = useDispatch();
  const { match: { params: { programId } } } = props;
  const context = useContext(ProgramsContext);
  const programStatus = useSelector(state => selectors.getProgramStatus(state, programId));
  const activeEnrollment = useSelector(state => selectors.getActiveEnrollmentForProgram(state, programId));
  const permissionStatus = useSelector(state => selectors.getProgramPermissions(state, programId));

  useEffect(() => {
    dispatch(loadProgram(programId));
    dispatch(loadProgramPermissions(programId));
  }, [programId]);

  if (activeEnrollment) {
    const { id: programEnrollmentId } = activeEnrollment;
    setLocalStorage(InvitedProgramId, programId);
    const wasProfileSwitched = props.location?.state?.backpath?.wasProfileSwitched;
    const toPath =  permissionStatus && permissionStatus?.data  && permissionStatus?.data.length > 0 && permissionStatus?.data[0]?.details?.some(r=> ObservationTypesForConnection.includes(r.observationType)) ? routePaths.observationConnect : buildPath(routePaths.programsDetails, { programId, programEnrollmentId });
    return <ProfileRedirect backpath={{ wasProfileSwitched}} to={toPath} />;
  }

  if (programStatus.loaded && !programStatus.data) {
    return <Page404 />;
  }

  return (
    programId != noImmunizationPathwayId &&
    <Page
      id='program-enrollment'
      centerElement={local.programs.enrollHeader}
      titleTabText={local.programs.enrollTitleTab}
      backPath={{ to: props.location?.state?.backpath?.isFromCreateGoal ? routePaths.myGoal : context.base }}>
      <PersistentDataStatus reducerName='programs' type={types.PROGRAM} uniqueId={programId}>
        <ul className="card-list">
          <li className="card-list-item">
            <ProgramTile program={programStatus.data} showHeroImage showEnroll showDescription />
          </li>
        </ul>
      </PersistentDataStatus>
    </Page>
  );
}

ProgramEnrollment.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape()
};
