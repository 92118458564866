import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  loadDashBoardHealthRecords, loadHealthDataPageRecords, loadImmunizationData, loadLabReportData} from 'health/action-creators';
import { Card, LinkWrapper, NavIcon, ProfileLink, Icon} from 'shared';
import { getProfileId } from 'app/selectors';
import { getDashboardHealthData, getHealthDataPageRecords, getUnreadMedicalRecordsCount} from 'health/health-selectors';
import { Conditions, Encounters, Immunizations, Labs, Journal, Procedures, CarePlan, Medication } from 'shared/fullColorIcons';
import { ObservationType, healthDataType, FhirResourceTypes } from 'services/enums';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { getObjectKeyByValue } from 'services/enums-helpers';
import { getIdentityVerificationStatus, getIdentityVerifiedStatus } from 'health/connect/connect-selectors';
import '../../health/data/health-data-v1.scss';

export default function DashboardHealthRecordsV1({fromHealthData}){
  const profileId = useSelector(getProfileId);
  const healthData =  useSelector(state => getDashboardHealthData(state,profileId));
  const healthDataPageRecords = useSelector(state => getHealthDataPageRecords(state,profileId));
  const labs = useSelector(state=>state.health?.labResult?.data);
  const immunizations = useSelector(state=>state.health?.immunization?.data);
  const healthDataContent = local.healthData;
  const dispatch = useDispatch();
  const unreadMedicalRecordsCountConditions = useSelector(state => getUnreadMedicalRecordsCount(state, profileId, FhirResourceTypes.Condition));
  const unreadMedicalRecordsCountProcedures = useSelector(state => getUnreadMedicalRecordsCount(state, profileId, FhirResourceTypes.Procedure));
  const unreadMedicalRecordsCountEncounters = useSelector(state => getUnreadMedicalRecordsCount(state, profileId, FhirResourceTypes.Encounter));
  const unreadMedicalRecordsCountCarePlans = useSelector(state => getUnreadMedicalRecordsCount(state, profileId, FhirResourceTypes.CarePlan));
  const unreadMedicalRecordsCountDiagnosticReport = useSelector(state => getUnreadMedicalRecordsCount(state, profileId, FhirResourceTypes.DiagnosticReport));
  const unreadMedicalRecordsCountImmunization = useSelector(state => getUnreadMedicalRecordsCount(state, profileId, FhirResourceTypes.Immunization));
  const identityVerificationStatus = useSelector(state => getIdentityVerificationStatus(state, profileId));
  const verified = useSelector(state => getIdentityVerifiedStatus(state, profileId));
  const backPathRoute = fromHealthData ? routePaths.health : routePaths.root;

  useEffect(() => {
    dispatch(loadDashBoardHealthRecords(profileId));
    dispatch(loadHealthDataPageRecords(profileId));
    dispatch(loadLabReportData({profileId: profileId, observationType: ObservationType.LabResult}));
    dispatch(loadImmunizationData({profileId: profileId, observationType: ObservationType.Immunization}));
  }, [profileId]);

  const hasObservation = (observationType) =>
    healthData?.observations?.find(observation => observation.observationType === observationType);
  const hasMedications = healthData?.hasMedications;
  const hasJournals = healthData?.hasJournals;

  const renderCard = (icon, content, route,skipVerification, unreadMedicalRecordsCount) => {
    const linkParams =  !verified?.isIdentityVerified && identityVerificationStatus?.isIdentityVerificationRequired  && !skipVerification?
      { to: routePaths.healthIdentity, backpath: routePaths.root } : {to: route };
    return (

      <Card>
        <ProfileLink className="with-chevron with-padding" id={`${content}-link`} {...linkParams} >
          <NavIcon Icon={icon} />
          <span className='d-flex justify-content-between'>
            <strong>{content}</strong>
          </span>
          { unreadMedicalRecordsCount && unreadMedicalRecordsCount > 0 ? (
            <figure>{unreadMedicalRecordsCount}</figure>
          ) : null}
        </ProfileLink>
      </Card>
    )

    ;
  };

  const hasMissingRecord = () =>
    !healthDataPageRecords || !healthDataPageRecords.hasConditions || !healthDataPageRecords.hasEncounters
      || !healthDataPageRecords.hasProcedures || !healthDataPageRecords.hasCarePlans
      || !healthData ||  !healthData?.hasMedications || !healthData?.hasJournals
      || !hasObservation(ObservationType.Immunization) || !hasObservation(ObservationType.LabResult);

  const getMissingRecords = () => {
    const missingRecords = [];
    const condition = getObjectKeyByValue(healthDataType, healthDataType.Condition);
    const encounter = getObjectKeyByValue(healthDataType, healthDataType.Encounter);
    const journal = getObjectKeyByValue(healthDataType, healthDataType.JournalEntry);
    const procedure = getObjectKeyByValue(healthDataType, healthDataType.Procedure);
    const immunization = getObjectKeyByValue(healthDataType, healthDataType.ObservationTypeImmunization);
    const labResults = getObjectKeyByValue(healthDataType, healthDataType.ObservationLabResults);
    const carePlan = getObjectKeyByValue(healthDataType, healthDataType.CarePlan);
    const medication = getObjectKeyByValue(healthDataType, healthDataType.Medication);
    if(healthDataPageRecords){
      if(!(healthDataPageRecords.hasConditions || (identityVerificationStatus?.isIdentityVerificationRequired && unreadMedicalRecordsCountConditions && unreadMedicalRecordsCountConditions > 0 )))
      missingRecords.push(condition);
      if(!(healthDataPageRecords.hasEncounters || (identityVerificationStatus?.isIdentityVerificationRequired && unreadMedicalRecordsCountEncounters && unreadMedicalRecordsCountEncounters > 0)))
        missingRecords.push(encounter);
      if(!(healthDataPageRecords.hasProcedures || (identityVerificationStatus?.isIdentityVerificationRequired && unreadMedicalRecordsCountProcedures && unreadMedicalRecordsCountProcedures > 0)))
        missingRecords.push(procedure);
      if(!(healthDataPageRecords.hasCarePlans || (identityVerificationStatus?.isIdentityVerificationRequired && unreadMedicalRecordsCountCarePlans && unreadMedicalRecordsCountCarePlans > 0)))
        missingRecords.push(carePlan);
    }
    else{
      missingRecords.push(condition);
      missingRecords.push(encounter);
      missingRecords.push(procedure);
      missingRecords.push(carePlan);
    }

    if(healthData){
      if( !(hasObservation(ObservationType.Immunization)  && (immunizations && immunizations.length <= 0) || ((immunizations && immunizations.length > 0) || identityVerificationStatus?.isIdentityVerificationRequired && unreadMedicalRecordsCountImmunization && unreadMedicalRecordsCountImmunization > 0)))
        missingRecords.push(immunization);
      if( !(hasObservation(ObservationType.LabResult) && (labs && labs.length <= 0) || ((labs && labs.length > 0) || identityVerificationStatus?.isIdentityVerificationRequired && unreadMedicalRecordsCountDiagnosticReport && unreadMedicalRecordsCountDiagnosticReport > 0)))
        missingRecords.push(labResults);
      if(!hasMedications)
        missingRecords.push(medication);
      if(!hasJournals)
        missingRecords.push(journal);
    }
    else{
      missingRecords.push(immunization);
      missingRecords.push(labResults);
      missingRecords.push(medication);
      missingRecords.push(journal);
    }

    return missingRecords;
  };
  return(
    <div id="myRecord">
      <div className='healthNav-header'>{local.healthData_v1Dashboard.myRecords}</div>
      <ul>
        {healthDataPageRecords ?
          <Fragment>
            {(healthDataPageRecords.hasCarePlans || unreadMedicalRecordsCountCarePlans && unreadMedicalRecordsCountCarePlans > 0) ? renderCard(CarePlan, healthDataContent.carePlan.title, routePaths.healthCarePlan, unreadMedicalRecordsCountCarePlans && unreadMedicalRecordsCountCarePlans > 0 ? false:true, unreadMedicalRecordsCountCarePlans) : null}
            {(healthDataPageRecords.hasConditions)|| unreadMedicalRecordsCountConditions && unreadMedicalRecordsCountConditions > 0 ? renderCard(Conditions, healthDataContent.conditions.title, routePaths.healthConditions, unreadMedicalRecordsCountConditions && unreadMedicalRecordsCountConditions > 0 ? false:true, unreadMedicalRecordsCountConditions) : null}
          </Fragment> : null}
        {healthData ?
          <Fragment>
            {(hasObservation(ObservationType.LabResult) || (labs && labs.length > 0) || unreadMedicalRecordsCountDiagnosticReport && unreadMedicalRecordsCountDiagnosticReport > 0) ? renderCard(Labs, healthDataContent.labs.title, routePaths.healthObservationLabResults,unreadMedicalRecordsCountDiagnosticReport && unreadMedicalRecordsCountDiagnosticReport > 0 ? false:true, unreadMedicalRecordsCountDiagnosticReport) : null}
          </Fragment> : null}
        {healthDataPageRecords ?
          <Fragment>
            {(healthDataPageRecords.hasEncounters || unreadMedicalRecordsCountEncounters && unreadMedicalRecordsCountEncounters > 0) ? renderCard(Encounters, healthDataContent.encounters.title, routePaths.healthEncounters, unreadMedicalRecordsCountEncounters && unreadMedicalRecordsCountEncounters > 0 ? false:true, unreadMedicalRecordsCountEncounters) : null}
          </Fragment> : null}
        {healthData ?
          <Fragment>
            {(hasObservation(ObservationType.Immunization) || (immunizations && immunizations.length > 0) || unreadMedicalRecordsCountImmunization && unreadMedicalRecordsCountImmunization > 0) ? renderCard(Immunizations, healthDataContent.immunizations.title, routePaths.healthObservationImmunizations,unreadMedicalRecordsCountImmunization && unreadMedicalRecordsCountImmunization > 0 ? false:true, unreadMedicalRecordsCountImmunization) : null}
            { hasMedications ? renderCard(Medication, local.linkMedications, routePaths.healthMedicationDose,true) : null}
            {(hasJournals) ? renderCard(Journal, local.journal.title, routePaths.healthJournal,true) : null}
          </Fragment> : null}
        {healthDataPageRecords ?
          <Fragment>
            {(healthDataPageRecords.hasProcedures || unreadMedicalRecordsCountProcedures && unreadMedicalRecordsCountProcedures > 0) ? renderCard(Procedures, healthDataContent.procedures.title, routePaths.healthProcedures,unreadMedicalRecordsCountProcedures && unreadMedicalRecordsCountProcedures > 0 ? false:true,unreadMedicalRecordsCountProcedures) : null}
          </Fragment> : null}
      </ul>
      {hasMissingRecord() && getMissingRecords() && getMissingRecords().length > 0 &&
      <div className='addData-card'>
        <Card>
          <LinkWrapper id='myrecord-add-data--link' to={routePaths.healthAddItem} backpath={{to:backPathRoute, enabledData:getMissingRecords()}}>
            <Icon symbol="addCircle" />
            {local.healthData_v1Dashboard.addInfo}
          </LinkWrapper>
        </Card>
      </div>}
    </div>
  );
}
