import * as actionNames from './action-names';

export function healthScoreLoadRequested() {
  return {
    type: actionNames.HEALTH_SCORE_REQUESTED
  };
}

export function healthScoreLoaded(score) {
  return {
    type: actionNames.HEALTH_SCORE_LOADED,
    payload: score
  };
}

export function healthScoreNotEnoughInfo() {
  return {
    type: actionNames.HEALTH_SCORE_NOTENOUGHINFO
  };
}

export function healthScoreLoadError() {
  return {
    type: actionNames.HEALTH_SCORE_LOADERROR
  };
}

export function observationLoadRequested(observationType) {
  return {
    type: actionNames.HEALTH_OBSERVATION_REQUESTED,
    payload: {
      observationType
    }
  };
}

export function observationLoaded(observationType, data) {
  return {
    type: actionNames.HEALTH_OBSERVATION_LOADED,
    payload: {
      observationType,
      data
    }
  };
}
export function highFrequencyDataLoaded(observationType, data,highFrequencyData) {
  return {
    type: actionNames.HIGH_FRQUENCY_DATA_OBSERVATION_LOADED,
    payload: {
      observationType,
      data,
      highFrequencyData:highFrequencyData
    }
  };
}

export function observationLoadError(observationType, error) {
  return {
    type: actionNames.HEALTH_OBSERVATION_LOADERROR,
    payload: {
      observationType,
      error
    }
  };
}

export function observationFileLoadRequested(observationType) {
  return {
    type: actionNames.HEALTH_OBSERVATION_FILE_REQUESTED,
    payload: {
      observationType
    }
  };
}

export function observationFileLoaded(data) {
  return {
    type: actionNames.HEALTH_OBSERVATION_FILE_LOADED,
    payload: {
      data
    }
  };
}

export function observationFileLoadError(error) {
  return {
    type: actionNames.HEALTH_OBSERVATION_FILE_LOADERROR,
    payload: {
      error
    }
  };
}



export function observationReset(observationType) {
  return {
    type: actionNames.HEALTH_OBSERVATION_RESETFORM,
    payload: {
      observationType
    }
  };
}

export function observationSaveRequested(observationType) {
  return {
    type: actionNames.HEALTH_OBSERVATION_SAVEREQUESTED,
    payload: {
      observationType
    }
  };
}

export function observationSaved(data) {
  return {
    type: actionNames.HEALTH_OBSERVATION_SAVED,
    payload: {
      data
    }
  };
}

export function observationSaveError(observationType, message) {
  return {
    type: actionNames.HEALTH_OBSERVATION_SAVEERROR,
    payload: {
      observationType,
      message
    }
  };
}
export function observationDelete(observationType, observationId) {
  return {
    type: actionNames.HEALTH_OBSERVATION_DELETE,
    payload: {
      observationType,
      observationId
    }
  };
}
export function deleteFileObservation(observationType, observationId, deleteFileObservationModal) {
  return {
    type: actionNames.HEALTH_OBSERVATION_DELETE,
    payload: {
      observationType,
      observationId,
      deleteFileObservationModal
    }
  };
}
export function observationDeleteUndo(observationType, observationId) {
  return {
    type: actionNames.HEALTH_OBSERVATION_DELETE_UNDO,
    payload: {
      observationType,
      observationId
    }
  };
}

export function observationHistorySelect(observationType, observationId) {
  return {
    type: actionNames.HEALTH_OBSERVATION_HISTORY_ITEM_SELECT,
    payload: {
      observationType,
      observationId
    }
  };
}

export function observationHistoryDeselect(observationType, observationId) {
  return {
    type: actionNames.HEALTH_OBSERVATION_HISTORY_ITEM_DESELECT,
    payload: {
      observationType,
      observationId
    }
  };
}

export function requireHipaaConsent(profileId, errorCode, retryAction) {
  return {
    type: actionNames.HEALTH_REQUIRE_HIPAA_CONSENT,
    payload: {
      profileId,
      errorCode,
      retryAction
    }
  };
}

export function hipaaConsentCleared() {
  return {
    type: actionNames.HEALTH_HIPAA_CONSENT_CLEARED
  };
}
export function redirectFromImmunizationsEnable() {
  return {
    type: actionNames.REDIRECT_FROM_IMMUNIZATION_ENABLE
  };
}
export function redirectFromImmunizationsDisable() {
  return {
    type: actionNames.REDIRECT_FROM_IMMUNIZATION_DISABLE
  };
}
