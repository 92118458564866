import React from 'react';
import local from 'services/localization/local';
import { ObservationType, Unit } from 'services/enums';
import { routePaths } from 'services/routing';
import { configIds } from 'health/observations/observation-config-ids';
import { baseObservationConfig } from 'health/observations/observation-config-helpers.jsx';
import { LogBodyChest } from 'health/observations/body-chest/log/LogBodyChest';
import { Chest } from 'shared/fullColorIcons';

const typeDefinition = {
  type: ObservationType.BodyChest,
  typeUnitMap: {
    [ObservationType.BodyChest]: Unit.Inches
  },
  unitLabelMap: {
    [Unit.Inches]: local.units.inches
  }
};

export default {
  ...baseObservationConfig,
  ...typeDefinition,
  id: configIds.bodyChest,
  backPath: { to: routePaths.health },
  centerElement: local.observation.bodyChest.title,
  logcenterElement: local.observation.bodyChest.logTitle,
  noDataText: local.observation.bodyChest.noDataText,
  iconSymbol: Chest,
  renderLogComponent: (props) => props && <LogBodyChest {...props} />
};
