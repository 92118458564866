/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { NonAuthenticatedPage,Page,Icon,NavAction } from 'shared';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { getProfile, getSelfProfileId } from 'profiles/list/selectors';
import * as actions from 'identity/action-creators';
import UserNameForm from './UserNameForm';
import UserConsentPage from 'app/privacy-policy-eula/userConsent/UserConsentPage';
import './username.scss';
import { getIdentityId } from 'identity/selectors';
import { loadRecentPendingInvite } from 'organizations/organizations-actions';
import{ getRecentPendingInviteOrganizationMember } from 'organizations/organizations-selectors';

export function UserNameRegistration()  {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile, shallowEqual);
  const selfProfileId = useSelector(getSelfProfileId, shallowEqual);
  const backPath = profile.id !== self ? { to: routePaths.root, params: { profileId: selfProfileId } } : null;
  const isForceRegister = true;
  const userNameSaved = useSelector(state=>state.identity.savedUserName);
  const identityId = useSelector(getIdentityId);
  const names = useSelector(state=> getRecentPendingInviteOrganizationMember(state));

  useEffect(()=>{
    dispatch(loadRecentPendingInvite());
  },[identityId]);

  const handleSubmit =
  ({ firstName, lastName }) => {
    let userFirstName = firstName;
    let userLastName = lastName;
    const data = {userFirstName, userLastName, isForceRegister};
    dispatch(actions.updateUserName(
      selfProfileId,
      data
    ));
  };

  let body = null;
  const initialValues = names ? {
    firstName: names?.firstName,
    lastName: names?.lastName
  } : undefined;

  body = (
    <UserNameForm
      initialValues={initialValues}
      onSubmit={handleSubmit} />);

  if(!userNameSaved){
    return (
      <Page
        id='userName-registeration-page'
        centerElement={<Icon className="center" symbol="hipaaauth" />}
        primaryAction={<NavAction mobileOnly text={local.linkSignout} iconSymbol="signout" to={routePaths.signout} />}
        titleTabText={local.Registration.userInfo}
        backPath={backPath}
        hideBottomNav>
        <NonAuthenticatedPage id="username-registration" topChevronColor='yellow' titleTabText={local.userNameRegistration}>
          <div className='userName-card'>
            <h1 className='pb-3'>{local.Registration.userNameRegistration}</h1>
            {body}
          </div>
        </NonAuthenticatedPage>
      </Page>
    );
  }
  else{
    return (<UserConsentPage />);
  }
}

export default UserNameRegistration;
