import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'shared';
import { TextField } from 'shared/form-fields/formik';
import local from 'services/localization/local';

export default function OrganizationInviteDetailsPage({ inviteCode, submit, processing }) {
  const validation = Yup.object({
    inviteCode: Yup.string()
      .required(local.validationRequired)
  });

  const onSubmit = useCallback((values) => {
    submit(values.inviteCode);
  }, []);

  return (
    <Formik initialValues={{ inviteCode }} validationSchema={validation} onSubmit={onSubmit}>
      {() => (
        <Form>
          <TextField
            label={local.organizations.inviteCodeLabel}
            name='inviteCode'
            type='text'
            placeholder={local.organizations.inviteCodeLabel} />
          <br />
          <div className="">
            <Button
              color='blue'
              id='submit-org-invite-code'
              className="btn btn-primary"
              disabled={processing}
              submit>
              {local.formSubmit}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

OrganizationInviteDetailsPage.propTypes = {
  submit: PropTypes.func.isRequired,
  inviteCode: PropTypes.string,
  processing: PropTypes.bool.isRequired
};

