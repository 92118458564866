export const HEALTHINFO_REQUESTED = 'HEALTHINFO_REQUESTED';
export const HEALTHINFO_LOAD_SUCCESS = 'HEALTHINFO_LOAD_SUCCESS';
export const HEALTHINFO_LOAD_ERROR = 'HEALTHINFO_LOAD_ERROR';
export const HEALTHINFO_SAVING = 'HEALTHINFO_SAVING';
export const HEALTHINFO_SAVE_SUCCESS = 'HEALTHINFO_SAVE_SUCCESS';
export const HEALTHINFO_SAVE_ERROR = 'HEALTHINFO_SAVE_ERROR';
export const HEALTHINFO_SAVE_RESET = 'HEALTHINFO_SAVE_RESET';
export const HEALTHINFO_DELETE = 'HEALTHINFO_DELETE';
//Medication Scheduler
export const HEALTHINFO_SCHEDULE_REQUESTED = 'HEALTHINFO_SCHEDULE_REQUESTED';
export const HEALTHINFO_SCHEDULE_LOAD_SUCCESS = 'HEALTHINFO_SCHEDULE_LOAD_SUCCESS';
export const HEALTHINFO_SCHEDULE_LOAD_ERROR = 'HEALTHINFO_SCHEDULE_LOAD_ERROR';

