/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import { Page, BackAction, Loading } from 'shared';
import ProfileForm from './ProfileForm';
import moment from 'moment';

class UpdateProfile extends React.Component {
  constructor(props) {
    super(props);
    this.buildBackAction = this.buildBackAction.bind(this);
  }

  buildBackAction() {
    const { hideCancel, returnUrl } = this.props;
    if (hideCancel) {
      return undefined;
    }

    return <BackAction to={returnUrl} />;
  }

  render() {
    const { loading, loadError, profile, handleSave, centerElement, name, returnUrl, hideCancel } = this.props;
    const backAction = this.buildBackAction();

    let body = null;
    if (loading) {
      body = <Loading />;
    } else if (loadError) {
      body = <div className="error">{local.error}</div>;
    } else {
      const initialValues = profile ? {
        id: profile.id,
        name: profile.name,
        dateOfBirth: profile.dateOfBirth ? moment(profile.dateOfBirth).format(moment.HTML5_FMT.DATE) : '',
        gender: profile.gender,
        theme: profile.theme,
        firstName: profile.firstName,
        lastName: profile.lastName
      } : undefined;

      body = (
        <ProfileForm
          name={name}
          returnUrl={returnUrl}
          hideCancel={hideCancel}
          initialValues={initialValues}
          onSubmit={handleSave} />);
    }

    return (
      <Page
        id="hcprofile-component"
        centerElement={centerElement}
        backAction={backAction}>
        {body}
      </Page>
    );
  }
}

UpdateProfile.displayName = 'Update Profile';
UpdateProfile.propTypes = {
  name: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  loadError: PropTypes.bool.isRequired,
  hideCancel: PropTypes.bool,
  profile: PropTypes.shape({}),
  returnUrl: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  centerElement: PropTypes.string
};
UpdateProfile.defaultProps = {
  centerElement: local.formProfileHeader
};

export default UpdateProfile;
