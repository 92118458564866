import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'shared/avatar/Avatar';

class AvatarSelectField extends React.Component {
  render() {
    const { displayName, themes, input, label } = this.props;

    return (
      <div className="formField">
        {label && <label className='form-label mb-0' htmlFor={input.name}>{label}</label>}
        <div className="avatars">
          {
            themes.map(theme => {
              const profile = {
                name: displayName,
                theme
              };

              return (
                <div id={`theme-${theme}-input`} key={theme} onClick={() => input.onChange(theme)} className={input.value == theme ? 'selected' : ''}>
                  <Avatar profile={profile} />
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }
}

AvatarSelectField.displayName = 'Avatar Select Field';
AvatarSelectField.propTypes = {
  themes: PropTypes.arrayOf(PropTypes.number),
  displayName: PropTypes.string,
  input: PropTypes.shape(),
  label: PropTypes.string
};

AvatarSelectField.defaultProps = {
  themes: [0, 1, 2, 3, 4]
};

export default AvatarSelectField;
