import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { appInsightsKey } from 'app/config';

class AppInsightsService {
  constructor() {
    this.reactPlugin = new ReactPlugin();
  }

  initialize(reactPluginConfig) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsKey,
        extensions: [this.reactPlugin],
        extensionConfig: {
          [this.reactPlugin.identifier]: reactPluginConfig
        }
      }
    });
    this.appInsights.loadAppInsights();
  }
}

const ai = new AppInsightsService();

export function initializeAI(reactPluginConfig) {
  ai.initialize(reactPluginConfig);
}

export function submitFeedback(metric, rating) {
  ai.appInsights.trackEvent({ name: 'feedback' }, { metric, rating });
}

export function logClick(id) {
  ai.appInsights.trackEvent({ name: `click-${id}` });
}

export function logException(exception, severity = 3) {
  ai.appInsights.trackException({ exception: new Error(exception), severityLevel: severity });
}

export function trackCustomization(option) {
  ai.appInsights.trackEvent({ name: 'Shortcuts Customization' }, { option });
}
