import React from 'react';
import PropTypes from 'prop-types';
import { routePaths } from 'services/routing';
import { ProfileRedirect } from 'shared';

export default function ObservationRedirect({configId, backpath}) {
  return (
    <ProfileRedirect to={routePaths.healthObservation} params={{ configId }} backpath={backpath} />
  );
}

ObservationRedirect.propTypes = {
  configId: PropTypes.string.isRequired,
  backpath: PropTypes.string
};
