import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { observationHistorySelect, observationHistoryDeselect } from 'health/actions';
import DataRow from 'shared/data-row/DataRow';
import { getIsHistoryItemSelected } from 'health/observations/observation-selectors';
import moment from 'moment';
import { momentCalendarDayFormat, momentCalendarDateTimeFormat } from 'services/date-service';

export class VitalsDataRowContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleDeselect = this.handleDeselect.bind(this);
    this.getOnRowClick = this.getOnRowClick.bind(this);
    this.getTimeStamp = this.getTimeStamp.bind(this);
  }

  componentWillUnmount() {
    this.handleDeselect();
  }

  handleSelect() {
    const { observationHistorySelect, id, observationType } = this.props;
    observationHistorySelect(observationType, id);
  }

  handleDeselect() {
    const { observationHistoryDeselect, id, observationType, selected } = this.props;
    if (selected) {
      observationHistoryDeselect(observationType, id);
    }
  }

  getOnRowClick() {
    const { selected, disableActions } = this.props;
    if (disableActions) {
      return undefined;
    }

    if (selected) {
      return this.handleDeselect;
    }

    return this.handleSelect;
  }

  getTimeStamp(timestamp,recordedAt){
    if(recordedAt) {
      const isDst = moment(recordedAt).isDST();
      if(!isDst){
        return timestamp || moment(recordedAt).calendar(null, momentCalendarDateTimeFormat) ;
      }
      else{
        const ts = moment(recordedAt).clone().subtract(1,'hours').format('LLL');
        return moment(ts).calendar(null, momentCalendarDayFormat);
      }
    }
  }

  render() {
    const { value, recordedAt, timestamp, notes, selected, id, visual, handleDelete,source,healthDataSource,deviceDisplayName, manufacturer } = this.props;

    return (
      <DataRow
        id={id || ''}
        visual={visual}
        head={value}
        timestamp={this.getTimeStamp(timestamp,recordedAt)}
        notes={notes}
        healthDataSource={healthDataSource}
        source={source}
        deviceDisplayName={deviceDisplayName}
        handleDelete={handleDelete}
        selected={selected}
        handleRowClick={this.getOnRowClick()}
        manufacturer={manufacturer} />
    );
  }
}


VitalsDataRowContainer.propTypes = {
  id: PropTypes.string, // TODO: Consider 'clientId' for offline mode or deletion of data just posted to the server
  observationType: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string,PropTypes.array]).isRequired,
  recordedAt: PropTypes.string.isRequired,
  notes: PropTypes.string,
  healthDataSource: PropTypes.number,
  source: PropTypes.string,
  deviceDisplayName: PropTypes.string,
  timestamp: PropTypes.string,
  disableActions: PropTypes.bool,
  visual: PropTypes.node,
  handleDelete: PropTypes.func,

  // store
  selected: PropTypes.bool.isRequired,

  // dispatch
  observationHistorySelect: PropTypes.func.isRequired,
  observationHistoryDeselect: PropTypes.func.isRequired
};

const container = connect((state, ownProps) => ({
  selected: getIsHistoryItemSelected(state, ownProps)
}), { observationHistorySelect, observationHistoryDeselect }
);

export default container(VitalsDataRowContainer);
