import ProfilePage from 'profiles/page/ProfilePage';
import React from 'react';
import ProgramDashboardV1 from './ProgramDashboardV1';
import MedicationDashboardV1 from './MedicationDashboardV1';
import HealthNavigationV1 from 'health/data/HealthNavigationV1';
import { Icon, LinkWrapper, Logo } from 'shared';
import { routePaths } from 'services/routing';
import MobileBackButtonController from 'mobile/MobileBackButtonController';
import BadgeNotificationSection from 'dashboard/BadgeNotificationSection';
import InvitesDashboardSection from 'dashboard/InvitesDashboardSection';
import local from 'services/localization/local';
import DashboardHealthRecordsV1 from './DashboardHealthRecordsV1';
import MedicationReminderV1 from './MedicationReminderV1';

export default function DashboardV1() {
  return (
    <ProfilePage
      id='dashboard-page-v1'
      centerElement={
        <LinkWrapper to={routePaths.healthIndex} id="hc-logo">
          <div className='hc-logo-width'> <Logo /> </div>
        </LinkWrapper>
      }
      primaryAction={
        <LinkWrapper to={routePaths.healthBadgeSummary} data-testid='achievements-badge' id="star-badge" className="badgeColor">
          <Icon symbol="badgeStar"></Icon>
        </LinkWrapper>
      } titleTabText={local.healthDashboardHeader}>
      <MobileBackButtonController exitApp />

      <div className="invite-list">
        <section id="dashboard-invites-section">
          <BadgeNotificationSection />
          <InvitesDashboardSection />
        </section>
      </div>
      <ProgramDashboardV1 />
      <MedicationReminderV1 />
      <MedicationDashboardV1 />
      <HealthNavigationV1 fromHealthData={false} />
      <DashboardHealthRecordsV1 fromHealthData={false} />
    </ProfilePage>
  );
}
