import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import local from 'services/localization/local';
import withNavigation from 'app/withNavigation';
import withContext from 'app/withContext';
import { routePaths } from 'services/routing';
import { BackAction } from 'shared';
import ProfilePage from 'profiles/page/ProfilePage';
import { saveMedicationDose } from 'health/medication/dose/action-creators';
import { saveMedicationDoseReset } from 'health/medication/dose/actions';
import LogMedicationDoseForm from './LogMedicationDoseForm';
import MedicationNotesSection from './MedicationNotesSection';
import './medication-dose.scss';

export class LogMedicationDose extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  componentWillUnmount() {
    this.props.actions.saveMedicationDoseReset();
  }

  handleSubmit(values) {
    const { actions: { saveMedicationDose }, profileId, medication } = this.props;
    saveMedicationDose({ ...values, profileId, medicationId: medication.id });
  }

  handleBack() {
    const { navigate } = this.props;
    navigate({ to: routePaths.healthMedication });
  }

  render() {
    const { medication, centerElement } = this.props;

    const initialValues = {
      recordedAt: moment().format(moment.HTML5_FMT.DATETIME_LOCAL),
      dosage: medication.typicalDose
    };

    return (
      <ProfilePage
        id='log-medication-dose'
        centerElement={centerElement}
        readOnlyProfile
        titleTabText={local.medicationDoseAddTitleTab}
        backAction={<BackAction handleBack={this.handleBack} />}>
        <MedicationNotesSection medication={medication} />

        <LogMedicationDoseForm
          onSubmit={this.handleSubmit}
          handleBack={this.handleBack}
          initialValues={initialValues} />
      </ProfilePage>
    );
  }
}

LogMedicationDose.propTypes = {
  medication: PropTypes.shape(),
  centerElement: PropTypes.string,
  // redux
  profileId: PropTypes.string.isRequired,
  // actions
  actions: PropTypes.shape({
    saveMedicationDose: PropTypes.func.isRequired,
    saveMedicationDoseReset: PropTypes.func.isRequired
  }).isRequired,
  // navigation
  navigate: PropTypes.func.isRequired
};

LogMedicationDose.defaultProps = {
  centerElement: local.medicationDoseLogHeaderDefault
};

function mapDispatchToProps(dispatch) {
  const actions = { saveMedicationDose, saveMedicationDoseReset };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default withNavigation(withContext(connect(null, mapDispatchToProps)(LogMedicationDose)));
