import compileReducers from 'services/compile-reducer';
import * as persistentDataReducer from 'app/request/persistent-data-reducer';
import {DASHBOARD_APPOINTMENT,APPOINTMENT_LIST, APPOINTMENT_DETAILS} from './action-names';

export const initialState = {
  [DASHBOARD_APPOINTMENT]: persistentDataReducer.initialState,
  [APPOINTMENT_LIST]: persistentDataReducer.initialState,
  [APPOINTMENT_DETAILS]: persistentDataReducer.initialState
};

const reducers = {
  ...persistentDataReducer.reducers(DASHBOARD_APPOINTMENT),
  ...persistentDataReducer.reducers(APPOINTMENT_LIST),
  ...persistentDataReducer.reducers(APPOINTMENT_DETAILS)
};

export default compileReducers(reducers, initialState);
