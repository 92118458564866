import { stateKeyMap } from 'health/health-reducer';
import { compareDatesDescending } from 'services/date-service';

export const getObservationLoading = (state, props) => {
  const stateName = stateKeyMap[props.observationType];
  return state.health[stateName].loading;
};

export const getObservationLoadError = (state, props) => {
  const stateName = stateKeyMap[props.observationType];
  return state.health[stateName].loadError;
};

export const getObservationsLoading = (state, observationTypes) => {
  const types = [observationTypes].flat();
  for (let type of types) {
    if (getObservationLoading(state, { observationType: type })) {
      return true;
    }
  }
  return false;
};

export const getObservationsLoadError = (state, observationTypes) => {
  const types = [observationTypes].flat();
  for (let type of types) {
    if (getObservationLoadError(state, { observationType: type })) {
      return true;
    }
  }
  return false;
};

export const getObservationData = (state, observationType) => {
  const stateName = stateKeyMap[observationType];
  return state.health[stateName].data
    .filter(x => !x.deleted)
    .sort((a, b) => compareDatesDescending(a.recordedAt, b.recordedAt));
};

export const getPureObservationData = (state, observationType) => {
  const stateName = stateKeyMap[observationType];
  return state.health[stateName].data;
};

export const getLilChartObservationData = (state, observationType) => {
  return getObservationData(state, observationType).reverse();
};

export const getLilChartCurrentObservation = (observations) => {
  if (observations && observations.length > 0) {
    return observations[observations.length - 1].value;
  }
  return null;
};

export const getIsHistoryItemSelected = (state, props) => {
  const stateName = stateKeyMap[props.observationType];
  return state.health[stateName].selectedId === props.id;
};

export const getObservationTypeSavedStatus = (state, observationType) => {
  if (Array.isArray(observationType)) {
    return observationType.every((type) => state.health[stateKeyMap[type]].saved);
  } else {
    return state.health[stateKeyMap[observationType]].saved;
  }
};

export const getGroupedObservationSavingStatus = (state, observationTypes) => {
  if (Array.isArray(observationTypes)) {
    return observationTypes.some((type) => state.health[stateKeyMap[type]].saving);
  }

  return false;
};

export const getObservationTypeSavingStatus = (state, observationType) => {
  if (Array.isArray(observationType)) {
    return observationType.every((type) => state.health[stateKeyMap[type]].saving);
  } else {
    return state.health[stateKeyMap[observationType]].saving;
  }
};
