import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getType } from 'app/request/persistent-data-selectors';
import { DataLoadingStatus } from 'shared';

export function PersistentDataStatus({ reducerName, type, uniqueId, children }) {
  const { loading, loaded, hasError } = useSelector(state => getType(state[reducerName], type, uniqueId));

  return (
    <DataLoadingStatus loading={loading && !loaded} loaded={loaded} error={hasError}>
      {children}
    </DataLoadingStatus>
  );
}

PersistentDataStatus.propTypes = {
  reducerName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  uniqueId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default PersistentDataStatus;
