import React, { useEffect } from 'react';
import { LinkWrapper, Loading, NoDataPlaceholder, Page } from 'shared';
import { routePaths } from 'services/routing';
import local from 'services/localization/local';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileId } from 'app/selectors';
import { loadInvoice } from './action-creators';
import { getInvoiceDetails } from './invoice-selector';
import PropTypes from 'prop-types';
import { getIdentityVerificationStatus, getIdentityVerifiedStatus } from 'health/connect/connect-selectors';
import InvoiceList  from './InvoiceList';
import './invoice.scss';
import { Balanced_Invoice, Issued_Invoice } from 'services/constants';
import { loadIdentityVerificationStatusData } from 'health/connect/connect-actions';


export default function Invoice(props){
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const email = useSelector(state => state.identity.email);
  const { data, loading, hasError } = useSelector(state => getInvoiceDetails(state));
  const unPaidInvoice = data?.filter(x=>x.invoiceStatus === Issued_Invoice);
  const paidInvoice = data?.filter(x=>x.invoiceStatus === Balanced_Invoice);
  const identityVerificationStatus = useSelector(state => getIdentityVerificationStatus(state, profileId));
  const verified = useSelector(state => getIdentityVerifiedStatus(state, profileId));
  const content = local.invoice;

  useEffect(() => {
    dispatch(loadIdentityVerificationStatusData(profileId, email));
  }, [profileId, email]);


  useEffect(()=>{
    dispatch(loadInvoice(profileId));
  },[profileId]);

  const renderUnPaidInvoice = () => {
    if(loading){
      return(<Loading></Loading>);
    }
    if(verified?.isIdentityVerified == false || identityVerificationStatus?.isIdentityVerificationRequired == true){
      return(
        <div id="noDataPlaceholder" className="no-data-invoice">
          <div className='verify-health-record'>{content.verifyHealthRecords}</div>
          <div><small>{content.identityNotVerified}</small></div>
          <LinkWrapper
            id='verify-identity'
            className={`verify-btn button btn btn-primary ${identityVerificationStatus?.identityVerificationAttemptsCount >= 2 ? 'disabled' : ''}`}
            to={routePaths.healthIdentity}>{content.verify}
          </LinkWrapper>
        </div>
      );
    }
    if(!data || data?.length === 0 || hasError){
      return(<NoDataPlaceholder>{content.noData}</NoDataPlaceholder>);
    }
    if(unPaidInvoice && unPaidInvoice.length > 0){
      return(
      // eslint-disable-next-line react/jsx-key
        unPaidInvoice.map((x) => <InvoiceList invoice={x}></InvoiceList>)
      );
    }
  };

  const renderPaidInvoice = () => {
    if(paidInvoice && paidInvoice.length > 0){
      return(
      // eslint-disable-next-line react/jsx-key
        paidInvoice.map((x) => <InvoiceList invoice={x}></InvoiceList>)
      );
    }
  };
  return(
    <Page
      id='invoice-list'
      backPath={{to:props.location?.state?.backpath ?? routePaths.root}}
      centerElement={local.nav.invoice}>
      <ul className='card-list'>
        {renderUnPaidInvoice()}
      </ul>
      {paidInvoice && paidInvoice.length > 0 && !hasError &&
       (verified?.isIdentityVerified === true || identityVerificationStatus?.isIdentityVerificationRequired === false) &&
       <ul className='card-list'>
         <div className='paid-title'>Payment History</div>
         {renderPaidInvoice()}
       </ul>}
    </Page>
  );
}

Invoice.propTypes = {
  location: PropTypes.shape()
};
