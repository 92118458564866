import { buildUrl, httpGet, httpPost, parseError, parseSuccess } from './api';
import { deleteProfileRoot as apiRoot} from 'app/config';

export const deleteUserAccount = (userData, state) => {
  const url = `${apiRoot}/DeleteProfile/${userData.profileId}`;
  return httpPost(url, userData, state)
    .catch(parseError);
};

export const getAccountStatus = (email, state) => {
  const url = buildUrl(apiRoot, '/DeleteProfile/delete-profile-status', { email });
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};
