import { getQueryStringParams } from 'services/url-service';

const useProfileIdFromUrl = (location) => {
  const query = getQueryStringParams(location);
  const { profileid, profileId } = query;

  return profileid || profileId;
};

export default useProfileIdFromUrl;
