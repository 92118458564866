import React from 'react';
import { TimelineType } from 'timeline/timeline-helpers';
import { Card } from 'shared';
import {
  TimelineObservation,
  TimelineActivity,
  TimelineDose,
  TimelineJournalEntry
} from 'timeline/list';

export default function TimelineListItem({ item }) {
  let body;

  switch(item.type) {
    case TimelineType.observation:
      body = <TimelineObservation item={item} />;
      break;
    case TimelineType.activity:
      body = <TimelineActivity item={item} />;
      break;
    case TimelineType.dose:
      body = <TimelineDose item={item} />;
      break;
    case TimelineType.journalEntry:
      body = <TimelineJournalEntry item={item} />;
      break;
    default:
      body = null;
  }

  return (
    <Card>
      <div className="timeline-list-item">
        {body}
      </div>
    </Card>
  );
}
