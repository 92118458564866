import React from 'react';
import PropTypes from 'prop-types';
import './small-error.scss';

class ErrorComponent extends React.Component {
  render() {
    return (
      <div className="small-error-component">
        <div className="errorText">
          {this.props.text}
        </div>
      </div>
    );
  }
}

ErrorComponent.propTypes = {
  text: PropTypes.string
};

export default ErrorComponent;
