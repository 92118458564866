import compileReducers from 'services/compile-reducer';
import * as persistentDataReducer from 'app/request/persistent-data-reducer';
import {OBSERVATION_CONNECTIONS, CLEAR_CONNECTIONS} from './action-names';

export const initialState = {
  [OBSERVATION_CONNECTIONS]: persistentDataReducer.initialState
};

const reducers = {
  ...persistentDataReducer.reducers(OBSERVATION_CONNECTIONS)
};

reducers[CLEAR_CONNECTIONS] = (state) => {
  return {
    ...state,
    OBSERVATION_CONNECTIONS: {}
  };
};

export default compileReducers(reducers, initialState);
