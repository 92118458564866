import React from 'react';
import { getProfileId } from 'app/selectors';
import { useSelector } from 'react-redux';
import './LabResultsEncounter.scss';
import * as persistentDataSelectors from 'app/request/persistent-data-selectors';
import { types } from 'health/health-types';
import { Line } from 'react-chartjs-2';
import styleVars from 'styles/_variables.scss';
import local from 'services/localization/local';
import { NoDataPlaceholder } from 'shared';

export default function LabResultsTrend(){
  const profileId = useSelector(getProfileId);
  const labTrends = useSelector(state => persistentDataSelectors.getData(state.health, types.LAB_TRENDS, profileId));

  if(!labTrends || labTrends.length <= 0)
    return <NoDataPlaceholder>{local.observation.labResult.noTrends}</NoDataPlaceholder>;

  const getData = (trend) => {
    if(trend){
      return {
        labels: trend.axisValues.map(a => a.xAxis),
        datasets: [{
          data: trend.axisValues.map(a => Number(a.yAxis)),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor:'rgb(53, 162, 235)',
          pointRadius: 0,
          pointHoverRadius: 4,
          pointHitRadius: 30,
          lineTension: 0,
          fill: false
        }],
        metricName: trend.metricName,
        unit: trend.unit
      };
    }
    else
    {
      return {};
    }
  };

  const options = {
    responsive: true,
    legend: {
      display: false
    },
    tooltips: {
      enabled: true,
      displayColors: true,
      backgroundColor: styleVars.colorWhiteOff,
      titleFontColor: styleVars.ColorText,
      callbacks: {
        label: (tooltipItem, data) => [`${data.metricName}: ${tooltipItem.yLabel} ${data.unit}`],
        labelTextColor: () => styleVars.ColorText
      }
    },
    scales: {
      xAxes: [{
        display: true,
        gridLines: {
          drawOnChartArea: false
        },
        ticks: {
          autoSkip: true
        }
      }],
      yAxes: [{
        display: true,
        gridLines: {
          drawOnChartArea: false
        },
        ticks: {
          display: true,
          maxTicksLimit: 4
        }
      }]
    }
  };

  const tooltipLine = {
    id: 'tooltipLine',
    beforeDraw: chart => {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const activePoint = chart.controller.tooltip._active[0];
        const ctx = chart.ctx;
        const x = activePoint.tooltipPosition().x;
        const topY = chart.scales['y-axis-0'].top;
        const bottomY = chart.scales['y-axis-0'].bottom;

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.setLineDash([3,3]);
        ctx.lineWidth = 2;
        ctx.strokeStyle = '$text-color-light';
        ctx.stroke();
        ctx.restore();
      }
    }
  };

  return(
    <div className='demo-sample card-deck'>
      {labTrends && labTrends.map((trend, index) =>
        (
          // eslint-disable-next-line react/no-array-index-key
          <ul key={index} className='scrollview-list labResult-container'>
            <li className='scrollview-list-item'>
              <div className='data-row'>
                <div className='item-data item-actions pb-0'>
                  <div className='labResult-title'>{trend.metricName} ({trend.unit})</div>
                  <div className='labResult-imgcontainer'>
                    <Line data={getData(trend)} options={options} plugins={[tooltipLine]} />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        )
      )}
    </div>
  );
}
