/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { ModalWrapper, Button, Icon, LinkWrapper } from 'shared';
import local from 'services/localization/local';
import '../programs/details/program-action-sheet.scss';
import './MyGoal.scss';
import { routePaths } from 'services/routing';

export default function StaticGoalModal(props) {
  const goalCategories = props.goalCategories;
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const openSheet = () => setIsSheetOpen(true);
  const closeSheet = () => setIsSheetOpen(false);

  return (
    <React.Fragment>
      <Button id="action-modal-toggle-button" type="button" onClick={openSheet} color="transparent" data-testid='toggle'>
        <Icon symbol="add" />
      </Button>

      <ModalWrapper isOpen={isSheetOpen} closeTimeoutMS={200} onRequestClose={closeSheet}>
        <div className="modal-container action-sheet myGoal-actionSheet">
          <div className="modal-header">
            <div></div>
            <div>{local.goals.goalHelp}</div>
            <div></div>
          </div>
          <div className="modal-body">
            {goalCategories?.map((goalCategory) => {
              return (<LinkWrapper key={goalCategory.id} id="modal-close-button" to={routePaths.createGoal} backpath={{goalCategoryId : goalCategory.id, goalName:goalCategory?.name}}>{goalCategory?.name}</LinkWrapper>);
            })}
          </div>
        </div>
      </ModalWrapper>
    </React.Fragment >
  );
}
