import { abTestingRoot as apiRoot } from 'app/config';
import { httpGet,buildUrl, parseSuccess, parseError } from './api';

export const getABSegment = (state) => {
  var url = buildUrl(apiRoot,'/api/AbTesting/get-segment');

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};
