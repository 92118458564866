import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { routePaths } from 'services/routing';
import { getConfigForObservationType } from 'health/observations/observation-configs';
import { overrideObservationConfig } from 'health/observations/observation-config-helpers';

class ViewProgramData extends React.Component {
  render() {
    const { programId, programEnrollmentId, observationType } = this.props.match.params;

    const config = overrideObservationConfig(
      getConfigForObservationType(observationType),
      {
        backPath: { to: routePaths.programsDetails, params: { programId, programEnrollmentId } },
        logRoute: null
      }
    );

    return config.renderPageComponent({ config });
  }
}
ViewProgramData.propTypes = {
  // router
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string.isRequired,
      programEnrollmentId: PropTypes.string.isRequired,
      observationType: PropTypes.string.isRequired
    })
  })
};

export default withRouter(ViewProgramData);
