import moment from 'moment';
import * as toastActions from 'app/toaster/actions';
import * as persistentDataActions from 'app/request/persistent-data-actions';
import local from 'services/localization/local';
import { NOT_FOUND_HTTP_ERROR_CODE, HIPAA_CONSENT_ERROR_CODE } from 'services/api/api';
import { getUniqueId } from 'services/helpers';
import { getUnitForObservationType } from 'services/observation-service';
import * as api from 'services/api/healthdata-api';
import * as HI_api from 'services/api/healthindex-api';
import * as actions from './actions';
import { types } from './health-types';
import * as Health_Care_Profile_api from 'services/api/healthCareProfile-api';
import { compareDatesDescending } from 'services/date-service';
import { ObservationDetailType, ObservationType, RecurringType, Unit , GoalType } from 'services/enums';
import * as requestActions from 'app/request/request-actions';
import {getHighFrequencyData}  from 'services/api/hfdata-api';
import * as profileViewDataApi from 'services/api/profileViewData-api';

export function saveObservation(input) {
  const { profileId, timestamp, notes, value, observationType, unit, observationSubType, attachedFileData } = input;
  const data = {
    clientId: getUniqueId(),
    profileId,
    recordedAt: moment(timestamp).format(),
    notes,
    value,
    observationType,
    unit,
    observationSubType,
    attachedFileData
  };

  return (dispatch, getState) => {
    const state = getState();
    const requestId = getUniqueId();

    dispatch(actions.observationSaveRequested(observationType));
    dispatch(toastActions.addProcessingToast(requestId));

    const onSuccess = () => {
      dispatch(toastActions.completeToastWithSuccess(requestId));
      dispatch(actions.observationSaved(data));
    };

    const onError = error => {
      if (error && error.status === HIPAA_CONSENT_ERROR_CODE) {
        const retryAction = () => saveObservation(input);
        dispatch(toastActions.removeToast(requestId));
        dispatch(actions.requireHipaaConsent(profileId, error.body.code, retryAction));
      } else {
        dispatch(toastActions.completeToastWithError(requestId));
      }

      dispatch(actions.observationSaveError(observationType, error));
    };

    return api.postObservation(data, profileId, state)
      .then(onSuccess, onError).catch(onError);
  };
}

export function saveGroupedObservations(input) {
  const { timestamp, observations, profileId, notes } = input;
  const recordedAt = moment(timestamp).format();
  const mappedObservations = observations.map((observation) => ({
    clientId: getUniqueId(),
    profileId: profileId,
    value: observation.value,
    observationType: observation.observationType,
    unit: observation.unit,
    recordedAt,
    notes
  }));

  const data = {
    recordedAt,
    notes,
    observations: mappedObservations
  };

  return (dispatch, getState) => {
    const state = getState();
    const requestId = getUniqueId();

    mappedObservations.forEach(
      (observation) => dispatch(actions.observationSaveRequested(observation.observationType))
    );
    dispatch(toastActions.addProcessingToast(requestId));

    const onSuccess = () => {
      dispatch(toastActions.completeToastWithSuccess(requestId));
      mappedObservations.forEach(
        (observation) => dispatch(actions.observationSaved(observation))
      );
    };

    const onError = error => {
      if (error && error.status === HIPAA_CONSENT_ERROR_CODE) {
        const retryAction = () => saveGroupedObservations(input);
        dispatch(toastActions.removeToast(requestId));
        dispatch(actions.requireHipaaConsent(profileId, error.body.code, retryAction));
      } else {
        dispatch(toastActions.completeToastWithError(requestId));
      }

      mappedObservations.forEach(
        (observation) => dispatch(actions.observationSaveError(observation.observationType, error))
      );
    };

    return api.postGroupedObservations(data, profileId, state)
      .then(onSuccess, onError).catch(onError);
  };
}

export function deleteObservation(input) {
  const { observationType, observationId, profileId = {} } = input;
  return (dispatch, getState) => {
    dispatch(actions.observationDelete(observationType, observationId));

    const onSuccess = () => {
      dispatch(toastActions.popSuccessToast(local.deleted));
    };

    const onError = error => {
      if (error && error.status === HIPAA_CONSENT_ERROR_CODE) {
        const retryAction = () => deleteObservation(input);
        dispatch(actions.requireHipaaConsent(profileId, error.body.code, retryAction));
      } else {
        dispatch(toastActions.popErrorToast());
      }

      dispatch(actions.observationDeleteUndo(observationType, observationId));
    };
    return api.deleteObservation(observationId, profileId, getState())
      .then(onSuccess, onError).catch(onError);
  };
}

export function deleteFileObservation(input) {
  const { observationType, observationId, profileId, deleteFileObservationModal = {} } = input;
  return (dispatch, getState) => {
    dispatch(actions.deleteFileObservation(observationType, observationId, deleteFileObservationModal));

    const onSuccess = () => {
      dispatch(toastActions.popSuccessToast(local.deleted));
      observationType === ObservationType.LabResult ?  dispatch(loadLabReportData({profileId, observationType}))
        : dispatch(loadImmunizationData({profileId, observationType}));
    };

    const onError = error => {
      if (error && error.status === HIPAA_CONSENT_ERROR_CODE) {
        const retryAction = () => deleteFileObservation(input);
        dispatch(actions.requireHipaaConsent(profileId, error.body.code, retryAction));
      } else {
        dispatch(toastActions.popErrorToast());
      }
    };
    return api.deleteFileObservation(observationId, profileId, deleteFileObservationModal, getState())
      .then(onSuccess, onError).catch(onError);
  };
}

export function loadMultiObservationData({ profileId, observationTypeUnits, dateRangeStart }) {
  return (dispatch, getState) => {
    observationTypeUnits.forEach(x => {
      dispatch(actions.observationLoadRequested(x.observationType));
    });

    const onSuccess = response => {
      observationTypeUnits.forEach(x => {
        var results = response.results.filter(y => y.observationType === x.observationType);
        dispatch(actions.observationLoaded(x.observationType, results));
      });
    };

    const onError = error => {
      observationTypeUnits.forEach(x => {
        dispatch(actions.observationLoadError(x.observationType, error));
      });
    };

    return api.getObservation({ profileId, observationTypeUnits, dateRangeStart }, getState())
      .then(onSuccess).catch(onError);
  };
}

export function loadObservationEhrData({ profileId, observationType, searchLimit }) {
  return (dispatch, getState) => {
    dispatch(actions.observationLoadRequested(observationType));

    const unit = getUnitForObservationType(observationType);
    Promise.all([api.getObservation({ profileId, observationType, unit, searchLimit }, getState()),
      Health_Care_Profile_api.getObservations(getState(), profileId, { observationType, unit })])
      .then(results => {
        let finalResult = [...results[0]?.results, ...results[1]?.results];
        dispatch(actions.observationLoaded(observationType, finalResult));
      }).catch(() => {
        dispatch(loadObservationData({profileId,observationType,searchLimit}));
      });
  };
}

export function loadDashboardObservationEhrData({ profileId, observationType, observationTypeUnits }) {

  const action = state => Health_Care_Profile_api.getObservations(state, profileId, { observationType, observationTypeUnits });

  return persistentDataActions.request(action, types.HEALTH_DATA_VITALS_OBSERVATIONS, profileId);
}

export function loadLabReportData({ profileId, observationType, searchLimit }) {
  return (dispatch, getState) => {
    dispatch(actions.observationLoadRequested(observationType));

    const unit = getUnitForObservationType(observationType);

    Promise.all([api.getObservation({ profileId, observationType, unit, searchLimit }, getState()),
      Health_Care_Profile_api.getDiagnosticReports(getState(), profileId)])
      .then(results => {
        let finalResult = [...results[0]?.results, ...results[1]?.results];
        let sortedResults = finalResult.sort((a, b) => compareDatesDescending(a.recordedAt, b.recordedAt));
        dispatch(actions.observationLoaded(observationType, sortedResults));

      }).catch(() => {
        dispatch(loadObservationData({profileId,observationType,searchLimit}));
      });
  };
}

export function loadAllHighFrequencyData(profileId) {

  const ObservationTypeUnits = [
    {
      ObservationType: ObservationType.BloodGlucose,
      Unit: Unit.MilligramsPerDeciliter
    }
  ];

  const action = state => getHighFrequencyData({profileId, ObservationTypeUnits}, state, profileId);

  return persistentDataActions.request(action, types.HIGH_FREQUENCY_OBSERVATIONS, profileId);
}


export function loadHighFrequencyData({ profileId, observationType, searchLimit }) {
  return (dispatch, getState) => {
    dispatch(actions.observationLoadRequested(observationType));
    const unit = getUnitForObservationType(observationType);

    Promise.all([
      api.getObservation({ profileId, observationType, unit, searchLimit }, getState()),
      getHighFrequencyData({profileId, observationType, unit, searchLimit},getState(), profileId)])
      .then(results => {
        const hasHighFrequencyData = results && results.length > 1 && results[1]?.results?.length > 0 ? true : false;
        let finalResult = [...results[0]?.results, ...results[1]?.results];
        let sortedResults = finalResult.sort((a, b) => compareDatesDescending(a.recordedAt, b.recordedAt));

        dispatch(actions.observationLoaded(observationType, sortedResults));
        dispatch(actions.highFrequencyDataLoaded(observationType, sortedResults,hasHighFrequencyData));

      }).catch(() => {
        dispatch(loadObservationData({profileId,observationType,searchLimit}));
      });
  };
}

export function loadImmunizationData({ profileId, observationType, searchLimit }) {
  return (dispatch, getState) => {
    dispatch(actions.observationLoadRequested(observationType));

    const unit = getUnitForObservationType(observationType);

    Promise.all([api.getObservation({ profileId, observationType, unit, searchLimit }, getState()),
      Health_Care_Profile_api.getImmunizations(getState(), profileId)])
      .then(results => {
        let finalResult = [...results[0]?.results, ...results[1]?.results];
        let sortedResults = finalResult.sort((a, b) => compareDatesDescending(a.recordedAt, b.recordedAt));

        dispatch(actions.observationLoaded(observationType, sortedResults));

      }).catch(() => {
        dispatch(loadObservationData({profileId,observationType,searchLimit}));
      });
  };
}

export function loadObservationData({ profileId, observationType, searchLimit }) {
  return (dispatch, getState) => {
    dispatch(actions.observationLoadRequested(observationType));

    const unit = getUnitForObservationType(observationType);
    const groupBySource = true;
    return api.getObservation({ profileId, observationType, unit, searchLimit,groupBySource }, getState())
      .then(response => dispatch(actions.observationLoaded(observationType, response.results)))
      .catch((error)=>dispatch(actions.observationLoadError(observationType, error)));
  };
}

export function loadHealthScore(profileId) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(actions.healthScoreLoadRequested());

    const onSuccess = result => {
      dispatch(actions.healthScoreLoaded(result.healthScore));
    };

    const onError = (error) => {
      if (error && error.status === NOT_FOUND_HTTP_ERROR_CODE) {
        dispatch(actions.healthScoreNotEnoughInfo());
      } else {
        dispatch(actions.healthScoreLoadError());
      }
    };

    const currentDate = moment().format('MM/DD/YYYY');
    return HI_api.getHealthScore(profileId, currentDate, state)
      .then(onSuccess, onError).catch(onError);
  };
}

export function loadHealthScoreTrendData(profileId) {
  return (dispatch) => {
    const action = state => HI_api.getHealthScoreTrendData(profileId,state);
    dispatch(persistentDataActions.request(action, types.HEALTH_SCORE_TRENDS, profileId, { profileId }));
  };
}

export function loadConditions(profileId) {
  const action = state => Health_Care_Profile_api.getConditions(state, profileId)
    .then(result => result.results);

  return persistentDataActions.request(action, types.CONDITIONS, profileId);
}

export function loadEncounters(profileId) {
  const action = state => Health_Care_Profile_api.getEncounters(state, profileId)
    .then(result => result.results);

  return persistentDataActions.request(action, types.ENCOUNTERS, profileId);
}

export function loadProcedures(profileId) {
  const action = state => Health_Care_Profile_api.getProcedures(state, profileId)
    .then(result => result.results);

  return persistentDataActions.request(action, types.PROCEDURES, profileId);
}

export function loadImportCountSummaries(profileId) {
  const action = state => api.getImportCountSummaries(state, profileId)
    .then(result => result.reduce((aggregate, item) => {
      aggregate[item.recordType] = item;
      return aggregate;
    }, {}));

  return persistentDataActions.request(action, types.IMPORT_COUNTS, profileId);
}

export function accessImportCountSummary(profileId, dataType) {
  return (dispatch, getState) => {
    const reloadAction = loadImportCountSummaries(profileId);

    const dataTypeArray = Array.isArray(dataType) ? dataType : [dataType];
    return api.markImportCountSummaryAccessed(getState(), profileId, dataTypeArray)
      .then(() => dispatch(reloadAction));
  };
}

export function loadVaccinationProof(profileId) {
  return (dispatch) => {
    const action = state => api.getVaccinationProof(state, profileId, types.VACCINATION_PROOF)
      .then(result => result);

    return dispatch(persistentDataActions.request(action, types.VACCINATION_PROOF, profileId));
  };
}

export function loadBlobData(profileId, blobUrls) {
  return (dispatch, getState) => {
    return api.getBlobData(profileId, blobUrls, getState())
      .then(response => dispatch(actions.observationFileLoaded(response.json())))
      .catch(error => dispatch(actions.observationFileLoadError(error)));
  };
}

export function loadTrackSteps(profileId,observationType) {
  return (dispatch) => {
    const action = state => api.getTrackSteps(state, profileId, observationType)
      .then(result => result);

    return dispatch(persistentDataActions.request(action, types.TRACK_STEPS, profileId));
  };
}

export function loadCurrentStepTarget(profileId,observationType) {
  return (dispatch) => {
    const action = state => api.getCurrentStepTarget(state, profileId, observationType)
      .then(result => result);

    return dispatch(persistentDataActions.request(action, types.CURRENT_TARGET, profileId));
  };
}

export function loadDashBoardObservation(profileId) {
  return (dispatch) => {
    const utcOffsetMinutes = moment().utcOffset();
    const action = state => api.getDashBoardObservation(state, profileId,utcOffsetMinutes)
      .then(result => result);
    return dispatch(persistentDataActions.request(action, types.DASHBOARD_OBSERVATIONS, profileId));
  };
}

export function loadDashBoardHealthRecords(profileId) {
  const action = state => api.getDashboardHealthRecords(state, profileId);

  return persistentDataActions.request(action, types.DASHBOARD_HEALTH_RECORDS, profileId);
}
export function loadHealthDataPageRecords(profileId) {
  const action = state => Health_Care_Profile_api.getHealthDataRecords(state, profileId);

  return persistentDataActions.request(action, types.HEALTH_DATA_RECORDS, profileId);
}

export function loadCarePlan(profileId) {
  const action = state => Health_Care_Profile_api.getCarePlan(state, profileId)
    .then(result => result);

  return persistentDataActions.request(action, types.CARE_PLAN, profileId);
}

export function loadLabTrends(profileId) {
  const action = state => Health_Care_Profile_api.getLabTrends(state, profileId)
    .then(result => result);

  return persistentDataActions.request(action, types.LAB_TRENDS, profileId);
}

export function loadNutritionGoals(profileId) {
  const action = state => api.getObservationGoals(state, profileId, ObservationType.Nutrition)
    .then(result => result);

  return persistentDataActions.request(action, types.NUTRITION_GOALS, profileId);
}

export function loadGlucoseGoals(profileId) {
  const action = state => api.getObservationGoals(state, profileId, ObservationType.BloodGlucose)
    .then(result => result);

  return persistentDataActions.request(action, types.GLUCOSE_GOALS, profileId);
}

export function saveNutritionGoal(profileId, newValue, requestId) {
  const data = {
    profileId: profileId,
    observationType: ObservationType.Nutrition,
    value: newValue.calories,
    unit: Unit.Calories,
    startDate: moment().format(),
    frequency: RecurringType.Daily,
    isActive: true,
    displayUnit: newValue.displayUnit,
    totalForPercentageCalc:newValue.totalGrams,
    goalDetails: [
      {
        observationDetailType: ObservationDetailType.Carbohydrate,
        value: newValue.carbs ?? 0,
        Unit: Unit.Percent
      },
      {
        observationDetailType: ObservationDetailType.Protein,
        value: newValue.protein ?? 0,
        Unit: Unit.Percent
      },
      {
        observationDetailType: ObservationDetailType.Fat,
        value: newValue.fat ?? 0,
        Unit: Unit.Percent
      }
    ]
  };

  return (dispatch) => {
    dispatch(requestActions.nutritionSaved(false));
    const action = state => api.postStepTargetGoal(profileId, data, state).then(() => {
      dispatch(requestActions.nutritionSaved(true));
    });

    const requestOptions = {
      showToasts: true,
      processingMessage: local.saving,
      successMessage: local.saved,
      errorMessage: local.error,
      requireHipaaVerification: true
    };

    dispatch(requestActions.request(action, requestId, {}, requestOptions));
  };
}

export function saveGlucoseGoal(profileId, newValue, requestId) {
  const data = {
    profileId: profileId,
    observationType: ObservationType.BloodGlucose,
    fromValue: newValue.fromValue,
    toValue: newValue.toValue,
    unit: Unit.MilligramsPerDeciliter,
    startDate: moment().format(),
    goalType: GoalType.RangeValue,
    frequency: RecurringType.Daily,
    isActive: true
  };
  return (dispatch) => {
    dispatch(requestActions.glucoseSaved(false));
    const action = state => api.postStepTargetGoal(profileId, data, state).then(() => {
      dispatch(requestActions.glucoseSaved(true));
    });

    const requestOptions = {
      showToasts: true,
      processingMessage: local.saving,
      successMessage: local.saved,
      errorMessage: local.error,
      requireHipaaVerification: true
    };

    dispatch(requestActions.request(action, requestId, {}, requestOptions));
  };
}

export function loadDiagnosticReportAttachments(id, profileId) {
  const action = state => Health_Care_Profile_api.getDiagnosticReportAttachments(state, id, profileId)
    .then(result => result);

  return persistentDataActions.request(action, types.LAB_ATTACHMENTS, id);
}

export function saveBloodPressureGoal(profileId, newValue, submitRequestId) {
  const data = [{
    profileId: profileId,
    observationType: ObservationType.Systolic,
    unit: Unit.MillimetersOfMercury,
    startDate: moment().format(),
    frequency: RecurringType.Daily,
    isActive: true,
    goalType: GoalType.RangeValue,
    fromValue: newValue.systolicMin,
    toValue: newValue.systolicMax
  },
  {
    profileId: profileId,
    observationType: ObservationType.Diastolic,
    unit: Unit.MillimetersOfMercury,
    startDate: moment().format(),
    frequency: RecurringType.Daily,
    isActive: true,
    goalType: GoalType.RangeValue,
    fromValue: newValue.diastolicMin,
    toValue: newValue.diastolicMax
  }
  ];

  return (dispatch) => {
    dispatch(requestActions.bloodPressureGoalSaved(false));
    const action = state => api.postGoalList(profileId, data, state).then(() => {
      dispatch(requestActions.bloodPressureGoalSaved(true));
    });

    const requestOptions = {
      showToasts: true,
      processingMessage: local.saving,
      successMessage: local.saved,
      errorMessage: local.error,
      requireHipaaVerification: true
    };

    dispatch(requestActions.request(action, submitRequestId, {}, requestOptions));
  };
}

export function loadSystolicGoals(profileId) {
  const action = state => api.getObservationGoals(state, profileId, ObservationType.Systolic)
    .then(result => result);

  return persistentDataActions.request(action, types.SYSTOLIC_GOALS, profileId);
}

export function loadDiastolicGoals(profileId) {
  const action = state => api.getObservationGoals(state, profileId, ObservationType.Diastolic)
    .then(result => result);

  return persistentDataActions.request(action, types.DIASTOLIC_GOALS, profileId);
}

export function loadUnreadMedicalRecordsCount(profileId) {
  const action = state => profileViewDataApi.getUnreadMedicalRecordsCount(state, profileId)
    .then(result => result);

  return persistentDataActions.request(action, types.UNREAD_MEDICAL_RECORDS_COUNT, profileId);
}

export function resetUnreadMedicalRecordsCount(profileId, fhirResourceType, observationType) {
  const action = state => profileViewDataApi.resetUnreadMedicalRecordsCount(state, profileId, fhirResourceType, observationType);
  return persistentDataActions.request(action, types.RESET_UNREAD_MEDICAL_RECORDS_COUNT, profileId);
}
export function loadProgramObservation({ profileId, observationType, observationTypeUnits }) {
  const action = state => api.getObservation( {profileId,observationType ,observationTypeUnits},state );

  return persistentDataActions.request(action, types.PATHWAY_HEALTH_RECORDS, profileId);
}
