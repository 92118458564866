import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withContext from 'app/withContext';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { Icon, ProfileLink, Card } from 'shared';
import { getActivities } from 'activity/activity-selectors';
import ActivityListItem from './ActivityListItem';

class ActivityList extends React.Component {
  constructor(props) {
    super(props);
    this.getNoDataStateWithAdd = this.getNoDataStateWithAdd.bind(this);
  }

  getNoDataStateWithAdd() {
    return (
      <Card>
        <ProfileLink id="add-first-activity-link" to={routePaths.healthActivityAdd} backpath={this.props.backpath}>
          <span>
            <Icon symbol="add" size="3em" />
            <small>{local.noActivities}</small>
          </span>
        </ProfileLink>
      </Card>
    );
  }

  render() {
    const { activities } = this.props;

    if (!activities || activities.length === 0) {
      return this.getNoDataStateWithAdd();
    }

    return (
      <ul className="scrollview-list">
        {activities.map((x) => <ActivityListItem key={x.id || 0} activity={x} />)}
      </ul>
    );
  }
}

ActivityList.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  backpath: PropTypes.string
};

function mapStateToProps(state, props) {
  return {
    activities: getActivities(state, props.profileId)
  };
}

export default withContext(connect(mapStateToProps)(ActivityList));
