import * as actionNames from './action-names';

export function signInRequired() {
  return {
    type: actionNames.IDENTITY_SIGNINREQUIRED
  };
}

export function signInRequested() {
  return {
    type: actionNames.IDENTITY_SIGNINREQUESTED
  };
}

export function signInSuccess(token) {
  return {
    type: actionNames.IDENTITY_SIGNINSUCCESS,
    payload: token
  };
}

export function refresUserInfoSuccess(userInfo) {
  return {
    type: actionNames.IDENTITY_REFRESH_USERINFO_SUCCESS,
    payload: userInfo
  };
}

export function signInError(errorMessage) {
  return {
    type: actionNames.IDENTITY_SIGNINERROR,
    payload: errorMessage
  };
}

export function unverifiedEmail() {
  return {
    type: actionNames.IDENTITY_UNVERIFIEDEMAIL
  };
}

export function verifiedEmail() {
  return {
    type: actionNames.IDENTITY_VERIFIEDEMAIL
  };
}

export function unverifiedEmailReset() {
  return {
    type: actionNames.IDENTITY_UNVERIFIEDEMAILRESET
  };
}

export function identityRequested() {
  return {
    type: actionNames.IDENTITY_REQUESTED
  };
}

export function identityLoaded(identity) {
  return {
    type: actionNames.IDENTITY_LOADED,
    payload: identity
  };
}

export function identityLoadError(error) {
  return {
    type: actionNames.IDENTITY_LOADERROR
    ,
    payload: error
  };
}

export function registeringUser() {
  return {
    type: actionNames.IDENTITY_REGISTERUSER
  };
}

export function registerUserSuccess() {
  return {
    type: actionNames.IDENTITY_REGISTERUSER_SUCCESS
  };
}

export function registerUserError(error) {
  return {
    type: actionNames.IDENTITY_REGISTERUSER_ERROR,
    payload: error
  };
}

export function resettingPassword() {
  return {
    type: actionNames.IDENTITY_RESET_PASSWORD
  };
}

export function resetPasswordSuccess() {
  return {
    type: actionNames.IDENTITY_RESET_PASSWORD_SUCCESS
  };
}

export function resetPasswordError() {
  return {
    type: actionNames.IDENTITY_RESET_PASSWORD_ERROR
  };
}

export function resetPasswordReset() {
  return {
    type: actionNames.IDENTITY_RESET_PASSWORD_RESET
  };
}

export function updatePreference(preference, value) {
  return {
    type: actionNames.IDENTITY_UPDATEPREFERENCE,
    payload: { preference, value }
  };
}

export function displayCheckEmail(message) {
  return {
    type: actionNames.IDENTITY_DISPLAY_VERIFICATION_SENT,
    payload: message
  };
}

export function userNameSaving() {
  return {
    type: actionNames.USERNAME_SAVING
  };
}

export function userNameSaved() {
  return {
    type: actionNames.USERNAME_SAVED
  };
}

export function updateForceRegistration(isForceRegistration) {
  return {
    type: actionNames.IS_FORCE_REGISTRATION,
    payload: isForceRegistration
  };
}

export function updateProfileDeletionStatus(status) {
  return {
    type: actionNames.UPDATE_PROFILE_DELETION_STATUS,
    payload: status
  };
}

export function getIsAdminForAnyOrganizationResult(isAdmin) {
  return {
    type: actionNames.IS_ADMIN_FOR_ANY_ORGANIZATION,
    payload: isAdmin
  };
}


export function deleteAccountCompleted() {
  return {
    type: actionNames.ACCOUNT_DELETED
  };
}

export function deleteAccountError() {
  return {
    type: actionNames.DELETE_ACCOUNT_ERROR
  };
}

export function ABTestingSegment(segment) {
  return {
    type: actionNames.AB_TESTING_SEGMENT,
    payload: segment
  };
}

export function ABTestingSegmentError(error) {
  return {
    type: actionNames.AB_TESTING_SEGMENT_ERROR,
    payload: error
  };
}
