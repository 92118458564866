/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import local from 'services/localization/local';
import { loadObservationData, deleteObservation} from 'health/action-creators';
import { getPureObservationData, getObservationsLoading, getObservationsLoadError } from 'health/observations/observation-selectors';
import VitalsChart from 'health/vitals/VitalsChart';
import { NavAction, NoDataPlaceholder } from 'shared';
import { roundToDecimals } from 'services/value-formatter';
import { GenericObservationOverview, GenericObservationList } from './generics';
import { routePaths } from 'services/routing';

export const baseObservationConfig = {
  logButtonText: local.logButton,
  logButtonSymbol: 'add',
  logRoute: routePaths.healthLogObservation,
  disableActions: false,
  deleteData: (props) =>props.dispatch(deleteObservation({ observationType: props.observation.observationType, observationId: props.observation.id, profileId: props.observation.profileId})),
  loadData: (props) => [props.config.type].flat().forEach(x => props.dispatch(loadObservationData({ observationType: x, profileId: props.profileId }))),
  dataSelector: (state, props) => getPureObservationData(state, props.config.type),
  loadingSelector: (state, props) => getObservationsLoading(state, props.config.type),
  loadErrorSelector: (state, props) => getObservationsLoadError(state, props.config.type),
  timestampFormatter: () => undefined,
  valueFormatter: (props) => `${roundToDecimals(props.observation.value, 2)} ${props.config.unitLabelMap[props.observation.unit]}`,
  getChartFormatter: (config, unit) => (value) => config.valueFormatter({ observation: { value, unit }, config }),
  renderPageComponent: (props) => props.config && <GenericObservationOverview {...props} />,
  renderListComponent: (props) => props.observations && <GenericObservationList observations={props.observations} config={props.config} />,
  renderNoDataComponent: (props) => props.config.noDataText && <NoDataPlaceholder>{props.config.noDataText}</NoDataPlaceholder>,
  renderPrimaryActionComponent: (props) => props.config.renderLogComponent && <NavAction backpath={props.location?.state?.backpath} text={props.config.logButtonText} iconSymbol={props.config.logButtonSymbol} to={props.config.logRoute} params={{ configId: props.config.id }} />,
  renderChart: (props) => props.observations.length > 1 && <VitalsChart values={props.observations} formatter={props.config.getChartFormatter(props.config, props.observations[0].unit)} chartSettings={props.config.chartSettings} />
};

export const validateObservationConfig = (config) => {
  const requiredPropKeys = [
    'id',
    'type',
    'typeUnitMap',
    'unitLabelMap',
    'backPath',
    'disableActions',
    'centerElement',
    'noDataText',
    'iconSymbol',
    'deleteData',
    'loadData',
    'dataSelector',
    'loadingSelector',
    'loadErrorSelector',
    'timestampFormatter',
    'valueFormatter',
    'getChartFormatter',
    'renderPageComponent',
    'renderListComponent',
    'renderNoDataComponent',
    'renderPrimaryActionComponent'
  ];

  const missingKeys = requiredPropKeys.filter(key => !config[key] && typeof (config[key]) !== 'boolean');

  if (missingKeys.length > 0) {
    throw Error(`invalid config construction, missing ${missingKeys}`);
  }

  return config;
};

export const overrideObservationConfig = (config, overrideParams) => {
  const overridenConfig = {
    ...config,
    ...overrideParams
  };

  return validateObservationConfig(overridenConfig);
};
