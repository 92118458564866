import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Page404 from 'app/page404/Page404';
import { localStoragePaths } from 'services/constants';
import { getQueryStringParams } from 'services/url-service';
import { routePaths, buildPath } from 'services/routing';

export default function NavigateRoute({ match, location }) {
  const { routeId } = match.params;
  const query = getQueryStringParams(location);
  const isSignedIn = useSelector(state => state.identity.isSignedIn);

  if (!routePaths[routeId] || routePaths[routeId] === routePaths.navigate) {
    return <Page404 />;
  }

  const url = buildPath(routePaths[routeId], query);

  if (query.redirectUrl) {
    localStorage.setItem(localStoragePaths.redirectUrl, query.redirectUrl);
  }

  useEffect(() => {
    if (!isSignedIn) {
      localStorage.setItem(localStoragePaths.redirect, url);
    }
  }, [url]);

  return (
    <Redirect to={url} />
  );
}
NavigateRoute.propTypes = {
  location: PropTypes.shape().isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      routeId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
