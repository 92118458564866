import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProfileId } from 'app/selectors';
import { loadJournalEntries } from '../journal-actions';
import { getJournalEntries, isJournalEntriesLoadError, isJournalEntriesLoading } from 'journal/journal-selectors';
import JournalEntryListItem from './JournalEntryListItem';
import { Page, NoDataPlaceholder, CardList, NavAction } from 'shared';
import { routePaths } from 'services/routing';
import local from 'services/localization/local';
import './journal-entry-list.scss';
import PropTypes from 'prop-types';

export default function JournalEntryList(props) {
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);

  const loading = useSelector(isJournalEntriesLoading);
  const error = useSelector(isJournalEntriesLoadError);
  const entries = useSelector(state => getJournalEntries(state, profileId));
  const doLoadEntries = () => dispatch(loadJournalEntries({ profileId }));

  useEffect(() => {
    doLoadEntries();
  }, [profileId]);

  return (
    <Page
      id="journal-list-page"
      loading={loading}
      error={error}
      primaryAction={<NavAction text={local.add} iconSymbol="add" to={routePaths.healthJournalAdd} />}
      backPath={{to:props.location?.state?.backpath ?? routePaths.health}}
      centerElement={local.journal.title}>
      {entries.length === 0 && <NoDataPlaceholder>{local.journal.noData}</NoDataPlaceholder>}
      {entries.length > 0 && (
        <CardList id="journal-list" testId="journal-list">
          {entries.map(x => <JournalEntryListItem key={x.id} item={x} />)}
        </CardList>
      )}
    </Page>

  );
}

JournalEntryList.propTypes = {
  location: PropTypes.shape()
};
