import moment from 'moment';
import local from 'services/localization/local';
import { ObservationType, Unit } from 'services/enums';
import { roundToDecimals } from 'services/value-formatter';
import { getObservationData } from './observations/observation-selectors';

const compareObservationDates = (a, b) => {
  const aMoment = moment(a.recordedAt);
  const bMoment = moment(b.recordedAt);

  return aMoment > bMoment ? 1 : (aMoment == bMoment ? 0 : -1);
};

const getMostRecentObservationByDate = (observations, date) => {
  return observations.find(x => moment(x.recordedAt) < moment(date)) || observations[observations.length - 1];
};

export const getBmis = (state) => {
  const heights = getObservationData(state, ObservationType.Height)
    .filter(x => x.value !== 0) // Ignore 0 for height to avoid division by 0
    .sort(compareObservationDates)
    .reverse();

  const weight = getObservationData(state, ObservationType.Weight)
    .sort(compareObservationDates);

  if (heights.length < 1 || weight.length < 1 || heights[0].unit !== Unit.Inches || weight[0].unit !== Unit.Pounds) {
    return [];
  }

  const bmis = weight.map((w,index) => {

    let currentHeight = getMostRecentObservationByDate(heights, w.recordedAt);
    if( index == weight.length-1 && heights.length > 0)
    {
      currentHeight = heights[0];
    }

    return {
      id: w.id,
      recordedAt: w.recordedAt,
      value: (w.value * 703) / Math.pow(currentHeight.value, 2),
      height: currentHeight.value,
      weight: w.value
    };
  });

  return bmis;
};

export const getCurrentBmi = (bmis) => {
  if (bmis && bmis.length > 0) {
    return bmis[bmis.length - 1];
  }
  return undefined;
};

export const getCurrentBmiValueFormatted = (currentBmi) => {
  if (!(currentBmi && (currentBmi.value || currentBmi.value === 0))) {
    return undefined;
  }

  return roundToDecimals(currentBmi.value, 1);
};

export const getBmiCategory = (currentBmi) => {
  if (!(currentBmi && (currentBmi.value || currentBmi.value === 0))) {
    return '';
  }

  const bmiValue = currentBmi.value;
  let bmiCategory = '';

  if (bmiValue < 18.5) {
    bmiCategory = local.bmiCategoryUnderweight;
  } else if (bmiValue < 25) {
    bmiCategory = local.bmiCategoryNormal;
  } else if (bmiValue < 30) {
    bmiCategory = local.bmiCategoryOverweight;
  } else {
    bmiCategory = local.bmiCategoryObese;
  }

  return bmiCategory;
};
