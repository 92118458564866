import { acsAdapterRoot as apiRoot } from 'app/config';
import { httpGet, httpPost, buildUrl, parseSuccess, parseError, httpPut } from './api';

export const getParticipantDetails = (state, rootOrgId, memberName) => {

  var participantModel = {};

  participantModel.RootOrganizationId = rootOrgId;
  participantModel.EmailId = '';
  participantModel.ThreadId = '';
  participantModel.MemberName = memberName;
  var url = buildUrl(apiRoot, '/api/Participant/search');

  return httpPost(url, participantModel, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getUnReadChatMessageCount = (state, profileId) => {
  var url = buildUrl(apiRoot,'/api/Participant/member-unread-count', {profileId});

  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const resetUnReadChatMessageCount = (state, profileId, acsThreadId) => {
  var url = buildUrl(apiRoot,'/api/Participant/reset-member-unread', {profileId});

  return httpPut(url, acsThreadId, state)
    .then(parseSuccess)
    .catch(parseError);
};



