import { getBadgeSummaryDetailsForNotification } from 'badge/BadgeSummary-selectors';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { Icon, NavIcon } from 'shared';
import './BadgeNotificationSection.scss';
import { setBadgeNotified } from 'badge/action-creators';
import { getProfileId } from 'app/selectors';
import { getBadgeIcon } from 'badge/badge-helpers';
import { BadgeType } from 'services/enums';

export default function BadgeNotificationSection(){
  const content = local.healthBadge.notification;
  const profileId = useSelector(getProfileId);
  const dispatch = useDispatch();
  const [showNotificationSection, setshowNotificationSection] = useState(true);
  const { toNotifyBadge } = useSelector(getBadgeSummaryDetailsForNotification);
  const myRef = useRef(null);

  useEffect(() => {
    if(toNotifyBadge && myRef && myRef.current){
      myRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [toNotifyBadge]);

  if(!toNotifyBadge || !showNotificationSection)
    return null;

  function closeNotificationSection(){
    if(toNotifyBadge)
      dispatch(setBadgeNotified(profileId, toNotifyBadge.id));
    setshowNotificationSection(false);
  }

  return (
    <div id='badge-notification-section'>
      <div className='notification-area' ref={myRef}>
        <div className='badge-icon'>
          {toNotifyBadge?.badge?.badgeType?.badgesType != BadgeType.ProgramChallenge ?
            <NavIcon Icon={getBadgeIcon(toNotifyBadge)}></NavIcon>:
            <img className='program-challenge-badge' src={getBadgeIcon(toNotifyBadge)}></img>}
        </div>
        <div className="badge-notification-content">
          <div className="badge-notification-header">{content.congratulations}</div>
          <div className="badge-notification-subheader">{content.earnedABadge} {content.greatWork}</div>
          <div className="badge-notification-action" onClick={closeNotificationSection}>
            <Link to={routePaths.healthBadgeSummary} className="button">{content.viewAchievements}</Link>
          </div>
        </div>
        <Icon symbol='times' onClick={closeNotificationSection} />
      </div>
    </div>
  );
}
