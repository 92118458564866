import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import local from 'services/localization/local';
import PathwayContextDetails from 'health/observations/shared/PathwayContextDetails';
import * as healthActions from 'health/action-creators';
import Redux from 'shared/react-redux-hooks';
import moment from 'moment';
import { getProfileId } from 'app/selectors';
import { ObservationType, Unit, ObservationLabResultSubType } from 'services/enums';
import { observationReset } from 'health/actions';
import { SelectField } from 'shared/form-fields';
import FileUploaderField from 'shared/form-fields/FileUploaderField';
import 'health/observations/lab-results/log/LogLabResultForm.scss';

function LogLabResultForm({ submitting, saving, handleBack, pathwayContext }) {
  const dispatch = Redux.useDispatch();
  const profileId = Redux.useSelector(getProfileId);

  const isSubmitting = submitting || saving;

  useEffect(() => {
    return () => {
      dispatch(observationReset(ObservationType.LabResult));
    };
  }, [profileId]);

  const handleFileSubmitData = (jsonData, notesData) => {
    handleSubmit(jsonData, notesData);
  };

  const handleSubmit = React.useCallback(
    (attachedFileData, notes) => {
      dispatch(healthActions.saveObservation({
        profileId,
        value: 0,
        observationType: ObservationType.LabResult,
        unit: Unit.Unknown,
        notes,
        attachedFileData,
        observationSubType: ObservationLabResultSubType.Covid19TestProof,
        timestamp: moment().format(moment.HTML5_FMT.DATETIME_LOCAL)
      }));
    },
    [profileId]);

  return (
    <div>
      <div className="labResults-Obs-top">
        {pathwayContext && <PathwayContextDetails pathwayContext={pathwayContext} />}
        <div className="form-row">
          <Field
            readOnly={isSubmitting}
            label={local.formLabResultLabel}
            name="observationType"
            component={SelectField}>
            <option value="1">{local.labResultSubType}</option>
          </Field>
        </div>
      </div>
      <FileUploaderField submitting={submitting} saving={saving} handleBack={handleBack} handleFileSubmitData={handleFileSubmitData} />
    </div>
  );
}

LogLabResultForm.displayName = local.labResultForm;
LogLabResultForm.propTypes = {
  saving: PropTypes.bool,
  handleBack: PropTypes.func,
  submitting: PropTypes.bool,
  pathwayContext: PropTypes.shape()
};

export default reduxForm({
  form: 'LabResult'
})(LogLabResultForm);
