import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import './modal.scss';

// We may want to just implement our own modal here to have complete control over styling
// and functionality while enabling us to keep it simple compared to this package.
class ModalWrapper extends React.Component {
  render() {
    return (
      <Modal
        overlayClassName="Overlay"
        className="modal-wrapper"
        {...this.props} />
    );
  }
}

ModalWrapper.displayName = 'ModalWrapper';
ModalWrapper.propTypes = {
  isOpen: PropTypes.bool
};

export default ModalWrapper;
