import { request } from 'app/request/request-actions';
import * as profilesApi from 'services/api/profiles-api';
import * as actions from './actions';
import * as toastActions from 'app/toaster/actions';

export function sendUpdateEmail(emailId) {
  return (dispatch) => {
    const action = state => profilesApi.sendUpdateEmail(emailId,state)
      .then(newEmailId => {
        dispatch(actions.updateEmailAddress(newEmailId));
        dispatch(actions.changeEmailError(undefined));
      })
      .catch(message =>{
        dispatch(actions.changeEmailError(message.body));
      });

    return dispatch(request(action));
  };
}

export function resendCodeEmail() {
  return (dispatch) => {
    const action = state => profilesApi.resendCodeEmail(state);
    return dispatch(request(action));
  };
}

export function updateUserPassword(password) {
  return (dispatch) => {
    const action = state => profilesApi.UpdateUserPassword(password, state)
      .then(() => {
        dispatch(toastActions.popSuccessToast('Password updated successfully. You will be redirected to login page in 5 seconds.'));
        setTimeout(() => {
          window.location.href = '/signout';
        }, 5000);
      })
      .catch(() =>{
        dispatch(toastActions.popErrorToast('Failed to update the password.'));
        setTimeout(() => {
          window.location.href = location.href;
        }, 2000);
      });

    return dispatch(request(action));
  };
}

export function updateChangedEmail(emailId,verificationCode) {
  return (dispatch) => {
    const action = state => profilesApi.updateChangedEmail(emailId,verificationCode, state)
      .then(() => {
        dispatch(actions.changeEmailError(''));
        dispatch(actions.forceSignOut(true));
      })
      .catch(message =>{
        dispatch(actions.changeEmailError(message.body));
      });

    return dispatch(request(action));
  };
}

export function removeChangeError(){
  return(dispatch) => {
    dispatch(actions.changeEmailError(''));
  };
}

export function loadNewEmail() {
  return (dispatch) => {
    const action = state => profilesApi.loadNewEmail(state)
      .then(changeEmail => {
        dispatch(actions.updateEmailAddress(changeEmail.emailToUpdate));
        dispatch(actions.lastVerificationCodeSent(changeEmail.createdAt));
      });
    return dispatch(request(action));
  };
}

