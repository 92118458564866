import { ObservationType } from 'services/enums';
import { getConfigForObservationType } from 'health/observations/observation-configs';

export const TimelineType = {
  observation: 'observation',
  activity: 'activity',
  dose: 'medicationDose',
  journalEntry: 'journalEntry'
};

export const getConfigs = () => {
  const observationTypeValues = Object.values(ObservationType).filter(x => x > 0);
  const configs = Object.values(observationTypeValues
    // Gets all configs for the observation types being used
    .map(type => getConfigForObservationType(type))
    // Eliminates duplicate configs for multi-types (blood pressure for example)
    .reduce((accum, config) => {
      accum[config.id] = config;
      return accum;
    }, {}));

  return configs;
};

