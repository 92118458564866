import React from 'react';
import moment from 'moment';
import local from 'services/localization/local';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withContext from 'app/withContext';
import { getActivityBarChartData } from 'activity/activity-selectors';
import { ArrowButton } from 'shared';
import ActivityBarChart from './ActivityBarChart';

class ActivityVisualization extends React.Component {
  constructor(props) {
    super(props);
    this.getTitle = this.getTitle.bind(this);
  }

  getTitle() {
    const { selectedEnd } = this.props;
    return local.formatString(local.activity.visTitle, `${moment(selectedEnd).add(-4, 'weeks').format('M/D')} - ${moment(selectedEnd).format('M/D')}`);
  }

  render() {
    const { activityData, selectPreviousFourWeeks, selectNextFourWeeks, selectedEnd } = this.props;
    const nextDisabled = moment(selectedEnd).add(1, 'day') > moment();

    return (
      <div className="activity-visualization">
        <div className="activity-vis-header">
          <ArrowButton id="previous-activities-button" onClick={selectPreviousFourWeeks} />
          <small className="activity-vis-title">{this.getTitle()}</small>
          <ArrowButton id="next-activities-button" rightArrow onClick={selectNextFourWeeks} disabled={nextDisabled} />
        </div>
        <ActivityBarChart data={activityData} />
      </div>
    );
  }
}

ActivityVisualization.propTypes = {
  selectedEnd: PropTypes.string.isRequired,
  selectPreviousFourWeeks: PropTypes.func.isRequired,
  selectNextFourWeeks: PropTypes.func.isRequired,
  // redux
  activityData: PropTypes.arrayOf(PropTypes.shape).isRequired
};

function mapStateToProps(state, props) {
  return {
    activityData: getActivityBarChartData(state, props.profileId, props.selectedEnd)
  };
}

export default withContext(connect(mapStateToProps)(ActivityVisualization));
