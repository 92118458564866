import React from 'react';
import PropTypes from 'prop-types';
import { routePaths } from 'services/routing';
import ProgramListItem from './ProgramListItem';

class ProgramListItemPreview extends React.Component {
  render() {
    const { programInfo } = this.props;
    const logName = programInfo.name.toLowerCase().replace(/\s/g, '-');

    const params = {
      programId: programInfo.programId,
      metricName: `program-${logName}`
    };

    return (
      <ProgramListItem
        id={`program-preview-${programInfo.programId}`}
        logName={`program-preview-${logName}`}
        to={routePaths.programsPreview}
        params={params}
        programInfo={programInfo} />
    );
  }
}
ProgramListItemPreview.propTypes = {
  programInfo: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired
  }).isRequired
};

export default ProgramListItemPreview;
