import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Error, LoadingIcon } from 'shared';
import local from 'services/localization/local';
import { formatListToString } from 'services/text-service';
import { getConfigForObservationType } from 'health/observations/observation-configs';
import { loadProgramPermissions } from 'programs/programs-actions';
import { getProgramPermissions } from 'programs/programs-selectors';
import './program-permissions.scss';
const TWO_SECONDS = 2000;

function ProgramPermissions({ programId, organizationMembershipId, hasProperties }) {
  const dispatch = useDispatch();
  const identifier = organizationMembershipId || programId;
  const { data, hasError, loading, loaded } = useSelector(state => getProgramPermissions(state, identifier));

  useEffect(() => {
    if (organizationMembershipId || programId) {
      dispatch(loadProgramPermissions(programId, organizationMembershipId));
    }
  }, [organizationMembershipId, programId]);

  useEffect(() => {
    if (organizationMembershipId || programId) {
      if (loaded && !loading && hasProperties && data.length == 0) {
        setTimeout(() => dispatch(loadProgramPermissions(programId, organizationMembershipId)), TWO_SECONDS);
      }
    }
  }, [loading]);

  if (hasError) {
    return <Error>{local.error}</Error>;
  }

  if (loading || (hasProperties && loaded && data.length == 0)) {
    return <LoadingIcon />;
  }

  if (!data || data.length == 0) {
    return null;
  }

  return (
    <div id='program-permissions'>
      <div className='shared-data-header'>
        <strong>{local.programs.sharedDataHeader}</strong>
      </div>
      <div className='permissions-container'>
        {data && data.map(x => {
          let observations = x.details.map(detail => getConfigForObservationType(detail.observationType).centerElement);

          return (
            <ul key={x.permission.value}>
              <li>
                {local.formatString(local.programs.willBeEnrolled, <strong>{x.description}</strong>)}<br />
                {local.programs.willSeeData}
                {observations.length > 0 && (
                  <span>
                    <br />
                    {local.formatString(local.programs.sharedData, <strong>{formatListToString(observations)}</strong>)}
                  </span>
                )}
              </li>
            </ul>
          );
        })}
      </div>
    </div>
  );
}

ProgramPermissions.propTypes = {
  hasProperties: PropTypes.bool,
  programId: PropTypes.string,
  organizationMembershipId: PropTypes.string
};

export default ProgramPermissions;
