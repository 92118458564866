import React, { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Icon } from 'shared';
import local from 'services/localization/local';
import { NavLink } from 'react-router-dom';
import { routePaths } from 'services/routing';
import { unreadNotificationsCount } from 'notifications/notification-selectors';
import { loadNotifications } from 'notifications/notification-actions';

const identityIdSelector = state => state.identity.self && state.identity.self.id;

export default function NotificationLinkNav() {
  const identityId = useSelector(identityIdSelector, shallowEqual);
  const dispatch = useDispatch();
  const unreadCount = useSelector(unreadNotificationsCount, shallowEqual);
  const hasUnread = unreadCount > 0;

  useEffect(() => {
    if (identityId) {
      dispatch(loadNotifications());
    }
  }, [identityId]);

  return (
    <NavLink
      to={routePaths.notifications}
      id="notifications-page-link"
      className="nav-notifications"
      activeClassName="active">
      <Icon symbol="bell" />
      <span>{local.notifications.label}</span>
      {identityId && hasUnread && <figure>{unreadCount}</figure>}
    </NavLink>
  );
}
