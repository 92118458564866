import React from 'react';
import { Route, Switch } from 'react-router';
import PeoplePage from 'profiles/PeoplePage';
import UpdateProfile from 'profiles/update/UpdateProfileContainer';
import NewProfile from 'profiles/update/AddNewOtherProfileWrapper';
import { routePaths } from 'services/routing';
import Page404 from 'app/page404/Page404';

export default function ProfilesRoutes() {
  return (
    <Switch>
      <Route exact path={routePaths.people} component={PeoplePage} />
      <Route exact path={routePaths.peopleAdd} component={NewProfile} />
      <Route exact path={routePaths.peopleEdit} component={UpdateProfile} />
      <Route path={routePaths.people} component={Page404} />
    </Switch>
  );
}
