import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openDrawerMenu } from 'app/layout/navigation/actions';
import { Icon } from 'shared';
import './open-menu-action.scss';

export default function OpenMenuAction() {
  const dispatch = useDispatch();

  const onClick = useCallback(
    () => dispatch(openDrawerMenu()),
    [dispatch]
  );

  return (
    <button id="openMenuControl" type="submit" onClick={onClick}>
      <Icon symbol="menu" />
    </button>
  );
}
