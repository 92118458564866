import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getUniqueId } from 'services/helpers';
import { getProfileId } from 'app/selectors';
import * as selectors from 'programs/programs-selectors';
import { completeAndReEnrollInProgram, completeProgram } from 'programs/programs-actions';
import { Button, FormattedText, ShareContent, ShareLinkWrapper } from 'shared';
import local from 'services/localization/local';
import ShowCompleteScreen from 'programs/shared/ProgramCompletionScreen';
import { socialShareLinks } from 'services/constants';
import './program-success-prompt.scss';
import ProgramReferrals from './ProgramReferrals';
import ProgramReEnroller from 'programs/enrollment/ProgramReEnroller';
import{noImmunizationPathwayId} from 'app/config';
import {redirectFromImmunizationsDisable} from '../../health/actions';
import { useHistory } from 'react-router';
import { buildPath, routePaths } from 'services/routing';
import { EnrollTypes } from 'services/enums';

export default function ProgramSuccessPrompt({ enrollmentId }) {
  const dispatch = useDispatch();

  const profileId = useSelector(state => getProfileId(state));
  const enrollment = useSelector(state => selectors.getProgramEnrollmentById(state, enrollmentId));
  const details = useSelector(state => selectors.getProgramDetails(state, enrollmentId));
  const completeRequestId = useRef(getUniqueId());
  const [isReEnrollProcessed, setIsReEnrollProcessed] = useState(false);
  // const redirectFromImmunization = useSelector(state => state.health.redirectFromImmunization);
  const history = useHistory();
  const completionScreenData = (enrollment && enrollment.program && enrollment.program.completionScreenData) ? JSON.parse(enrollment.program.completionScreenData) : null;
  useEffect(() => {
    if(details.programId==noImmunizationPathwayId)
    {
      dispatch(redirectFromImmunizationsDisable());
      history.push(buildPath( routePaths.healthImmunizations, { profileId }));
    }
  });

  let showCompletionScreen = ShowCompleteScreen(enrollment, details, completionScreenData);

  const handleComplete = useCallback(() => {
    dispatch(completeProgram(enrollmentId, profileId, getUniqueId()));
  }, [profileId, enrollmentId]);

  const show = details && enrollment && (enrollment.complete || details.readyToComplete || showCompletionScreen);
  if (!show) {
    return null;
  }

  const needsCompletion = !enrollment.complete && details.readyToComplete;
  const showReferrals = !needsCompletion && !showCompletionScreen;
  const showReEnroll = (needsCompletion || showCompletionScreen) && enrollment.program.continuous;

  let headerText = enrollment.complete
    ? local.programs.successPromptCompleteHeader
    : local.programs.successPromptHeader;

  const contentClasses = `content ${needsCompletion ? 'congratulations' : 'share'}`;

  let promptText = local.programs.successPromptCompleteText;
  if (showReEnroll) {
    promptText = local.programs.reEnrollText;
  } else if (showReferrals) {
    promptText = local.programs.successPromptShareOrReferralCTA;
  } else if (!needsCompletion) {
    promptText = local.programs.successPromptShareCTA;
  }

  let autoEnrollment = false, manualEnrollment = true;
  if(completionScreenData && completionScreenData.enrollType)
  {
    autoEnrollment = completionScreenData.enrollType == EnrollTypes.AutomaticEnroll;
    manualEnrollment = completionScreenData.enrollType == EnrollTypes.ManuallEnroll;
  }

  useEffect(() => {
    if(showCompletionScreen && autoEnrollment && !manualEnrollment && enrollment && !enrollment.complete && !isReEnrollProcessed)
    {
      const optInOrganizationIds = enrollment && enrollment.organizationId ? [enrollment.organizationId] : [];
      setIsReEnrollProcessed(true);
      dispatch(completeAndReEnrollInProgram(profileId, enrollmentId, optInOrganizationIds, completeRequestId.current, false, true));
      history.push(buildPath(routePaths.root));
    }
  }, [showCompletionScreen, autoEnrollment, enrollment]);

  return (
    <section id="program-success-container">
      {showCompletionScreen &&
      <header>
        {(needsCompletion || showCompletionScreen) && <img src={pathWayImage(completionScreenData)} />}
        {completionScreenData && <h3>{completionScreenData.pageTitle}</h3>}
        {!completionScreenData && <h3>{headerText}</h3>}
      </header>}

      <div className={contentClasses}>
        {showCompletionScreen && completionScreenData && <span className="content-text"><FormattedText text={completionScreenData.pageContent1} /></span>}
        {showCompletionScreen && completionScreenData && <span className="content-text"><FormattedText text={completionScreenData.pageContent2} /></span>}
        {showCompletionScreen && !completionScreenData && <span className="content-text">{promptText}</span>}
        <ShareLinkWrapper
          className="program-summary-tile"
          url={socialShareLinks.pathwayGeneral}
          title={local.programs.successPromptShareTitle}
          text={local.formatString(local.programs.successPromptShareText, enrollment.program.name)}
          dialogTitle={local.programs.successPromptShareDialogTitle}>
          <figure className='icon-image' style={enrollment.program.iconPath ? { backgroundImage: `url('${enrollment.program.iconPath}')` } : {}} />
          <strong className="program-title">{enrollment.program.name}</strong>
          <ShareContent />
        </ShareLinkWrapper>

        { manualEnrollment && <ProgramReEnroller hide={!showReEnroll} enrollProgramId={details.programId} completedEnrollmentId={enrollmentId} /> }

        {needsCompletion && !showReEnroll && (
          <div className="action">
            <Button id='programm-complete-button' onClick={handleComplete} color="teal">
              {local.programs.successPromptCompleteButton}
            </Button>
          </div>
        )}

        {showReferrals && (
          <ProgramReferrals enrollmentId={enrollmentId} disabled={!enrollment.complete} />
        )}
      </div>
    </section>
  );
}

export const pathWayImage = (completionScreenData) => {
  const defaultPathwayCompleteImage = 'pathway_ready_to_complete.svg';
  const defaultImpagePath = '/static/images/' + defaultPathwayCompleteImage;
  return (completionScreenData && !completionScreenData.blobURL.includes(defaultPathwayCompleteImage))? completionScreenData.blobURL: defaultImpagePath;
};

ProgramSuccessPrompt.propTypes = {
  enrollmentId: PropTypes.string.isRequired
};

