import React from 'react';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import SelectField from './SelectField';

function SelectStateField({ useFullName = false, ...props }) {
  const states = [
    { id: 'AL', fullName: local.states.stateAL },
    { id: 'AK', fullName: local.states.stateAK },
    { id: 'AS', fullName: local.states.stateAS },
    { id: 'AZ', fullName: local.states.stateAZ },
    { id: 'AR', fullName: local.states.stateAR },
    { id: 'CA', fullName: local.states.stateCA },
    { id: 'CO', fullName: local.states.stateCO },
    { id: 'CT', fullName: local.states.stateCT },
    { id: 'DE', fullName: local.states.stateDE },
    { id: 'DC', fullName: local.states.stateDC },
    { id: 'FM', fullName: local.states.stateFM },
    { id: 'FL', fullName: local.states.stateFL },
    { id: 'GA', fullName: local.states.stateGA },
    { id: 'GU', fullName: local.states.stateGU },
    { id: 'HI', fullName: local.states.stateHI },
    { id: 'ID', fullName: local.states.stateID },
    { id: 'IL', fullName: local.states.stateIL },
    { id: 'IN', fullName: local.states.stateIN },
    { id: 'IA', fullName: local.states.stateIA },
    { id: 'KS', fullName: local.states.stateKS },
    { id: 'KY', fullName: local.states.stateKY },
    { id: 'LA', fullName: local.states.stateLA },
    { id: 'ME', fullName: local.states.stateME },
    { id: 'MH', fullName: local.states.stateMH },
    { id: 'MD', fullName: local.states.stateMD },
    { id: 'MA', fullName: local.states.stateMA },
    { id: 'MI', fullName: local.states.stateMI },
    { id: 'MN', fullName: local.states.stateMN },
    { id: 'MS', fullName: local.states.stateMS },
    { id: 'MO', fullName: local.states.stateMO },
    { id: 'MT', fullName: local.states.stateMT },
    { id: 'NE', fullName: local.states.stateNE },
    { id: 'NV', fullName: local.states.stateNV },
    { id: 'NH', fullName: local.states.stateNH },
    { id: 'NJ', fullName: local.states.stateNJ },
    { id: 'NM', fullName: local.states.stateNM },
    { id: 'NY', fullName: local.states.stateNY },
    { id: 'NC', fullName: local.states.stateNC },
    { id: 'ND', fullName: local.states.stateND },
    { id: 'MP', fullName: local.states.stateMP },
    { id: 'OH', fullName: local.states.stateOH },
    { id: 'OK', fullName: local.states.stateOK },
    { id: 'OR', fullName: local.states.stateOR },
    { id: 'PW', fullName: local.states.statePW },
    { id: 'PA', fullName: local.states.statePA },
    { id: 'PR', fullName: local.states.statePR },
    { id: 'RI', fullName: local.states.stateRI },
    { id: 'SC', fullName: local.states.stateSC },
    { id: 'SD', fullName: local.states.stateSD },
    { id: 'TN', fullName: local.states.stateTN },
    { id: 'TX', fullName: local.states.stateTX },
    { id: 'UT', fullName: local.states.stateUT },
    { id: 'VT', fullName: local.states.stateVT },
    { id: 'VI', fullName: local.states.stateVI },
    { id: 'VA', fullName: local.states.stateVA },
    { id: 'WA', fullName: local.states.stateWA },
    { id: 'WV', fullName: local.states.stateWV },
    { id: 'WI', fullName: local.states.stateWI },
    { id: 'WY', fullName: local.states.stateWY }
  ];

  return (
    <SelectField {...props}>
      <option value='' disabled>{local.selectPlaceholder}</option>
      {states.map(state => <option key={state.id} value={state.id}>{useFullName ? state.fullName : state.id}</option>)}
    </SelectField>
  );
}
SelectStateField.propTypes = {
  useFullName: PropTypes.bool
};

export default SelectStateField;
