import { getIncomingInvites, getIncomingInvitesCount } from 'profiles/sharing/sharing-selectors';
import { getOrganizationInvites, getOrganizationInvitesCount } from 'organizations/organizations-selectors';

export const getIsNewProfile = (state) => {
  const profileSelectedId = state.selectedProfile.id;
  const listProfile = state.profile.list.find(x => x.id === profileSelectedId);
  return listProfile ? listProfile.isNewProfile : false;
};

export const getIsCurrentProfileOwned = (state) => {
  const profileId = state.selectedProfile.id;
  const selectedProfile = state.profile.list.find(x => x.id === profileId);
  const isOwned = selectedProfile && selectedProfile.isOwned;
  return isOwned;
};

export const hasPendingInvites = (state) => {
  const inviteCount = getPendingInviteCount(state);
  return inviteCount > 0;
};

export const getPendingInviteCount = (state) => {
  if (!(state.identity && state.identity.self)) {
    return 0;
  }

  if (state.identity.unverifiedEmail) {
    const profileInviteCount = getIncomingInvitesCount(state);
    const orgInviteCount = getOrganizationInvitesCount(state);
    return profileInviteCount + orgInviteCount;
  }

  const incomingInvites = getIncomingInvites(state);
  const orgInvites = getOrganizationInvites(state);

  return incomingInvites.length + orgInvites.data.length;
};
