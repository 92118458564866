import moment from 'moment';

const GoldProductName = 'Gold';
const PlatinumProductName = 'Platinum';
export const SubsriptionType = {
  Free: 0,
  Gold: 1,
  Platinum: 2
};

export const getSubscription = () => {
  var platinumSubscription = localStorage.getItem(PlatinumProductName);
  var goldSubscription = localStorage.getItem(GoldProductName);
  var subscription = {
    name : 'Free',
    type : SubsriptionType.Free
  };
  if (platinumSubscription && hasValidSubscription(platinumSubscription)) {
    var data = JSON.parse(platinumSubscription);
    subscription.type = SubsriptionType.Platinum;
    subscription.name = 'Platinum';
    subscription.expiry = data.expiresAt;
    subscription.isMonthly = data.intervalCount === 1 ? true : false;
  }
  else if (goldSubscription && hasValidSubscription(goldSubscription)) {
    var goldSubscriptionData = JSON.parse(goldSubscription);
    subscription.type = SubsriptionType.Gold;
    subscription.name = 'Gold';
    subscription.expiry = goldSubscriptionData.expiresAt;
    subscription.isMonthly = goldSubscriptionData.intervalCount === 1 ? true : false;
  }
  return subscription;
};

export const hasValidSubscription = (data) => {
  try {
    var subscription = JSON.parse(data);
    if(moment(subscription.expiresAt) > moment())
      return true;
    else
      return false;

  }
  catch {
    return false;
  }
};

export const SubscriptionList = () =>{
  var data = [
    {
      id:1,
      name: 'Monthly CalciumProduct',
      planName: 'Basic',
      subscriptionType: SubsriptionType.Free,
      isMonthly: true,
      color:'#A16C36'
    },
    {
        id:2,
        name: 'Monthly CalciumProduct',
        planName: 'Basic',
        subscriptionType: SubsriptionType.Free,
        isMonthly: false,
      color:'#A16C36'

    },
    {
      id:3,
      name: 'Monthly CalciumProduct',
      planName: 'Gold',
      months: 1,
      originalPrice: 12.99,
      offerPrice: 12.99,
      intervalCount: 1,
      subscriptionType: SubsriptionType.Gold,
      color:'#FFD700',

      isMonthly: true
    },
    {
      id:4,
      name: 'Annual CalciumProduct',
      planName: 'Gold',
      months: 12,
      originalPrice: 156,
      offerPrice: 141,
      intervalCount: 12,
      subscriptionType: SubsriptionType.Gold,
      color:'#FFD700',

      isMonthly: false
    },
    {
      id:5,
      name: 'Monthly CalciumProduct',
      planName: 'Platinum',
      months: 1,
      originalPrice: 29.99,
      offerPrice: 29.99,
      intervalCount: 1,
      subscriptionType: SubsriptionType.Platinum,
      color:'#afafaf',

      isMonthly: true
    }
    ,
    {
      id:6,
      name: 'Annual CalciumProduct',
      planName: 'Platinum',
      months: 12,
      originalPrice: 360,
      offerPrice: 320,
      intervalCount: 12,
      subscriptionType: SubsriptionType.Platinum,
      color:'#afafaf',

      isMonthly: false
    }
  ];
  //var currentSubscription = getSubscription();
  //data = data.filter(x => x.subscriptionType > currentSubscription.type);
  return data;
};
