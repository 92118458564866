import React,{createRef} from 'react';
import { connect  } from 'react-redux';
import PropTypes from 'prop-types';
import { Field,reduxForm } from 'redux-form';
import { TextField } from 'shared/form-fields';
import { Button,Icon} from 'shared';
import { isMobile,isIOS,isAndroid } from 'mobile/helpers';
import * as validators from 'services/fields/field-validators';
import local from 'services/localization/local';
import TimeSlot from './TimeSlots';
import * as dateService  from 'services/date-service';
import moment from 'moment';
import { defaultMedicationTimingPreferences } from 'app/config';
import TimePicker from 'shared/time-picker/timePicker';

class LogMedicationForm extends React.Component {

  constructor(props){
    super(props);
    this.handleRemoveTiming=this.handleRemoveTiming.bind(this);
    this.assignStateandProps=this.assignStateandProps.bind(this);
    this.getSelectedTiming=this.getSelectedTiming.bind(this);
    this.state={
      isOpen:false, schedules:[{scheduledAt:dateService.formatHourToDate(defaultMedicationTimingPreferences?.split(',')[0])},{scheduledAt:dateService.formatHourToDate(defaultMedicationTimingPreferences?.split(',')[1])}],
      showDropdown: null
    };
    this.name=React.createRef();
    this.typicalDose=React.createRef();
    this.notes=React.createRef();
    this.schedule=createRef();
    this.dropdown=createRef();
  }

  componentDidMount()
  {
    if(this.props.initialValues.id){
      let props= this.props.initialValues;
      this.sortByDate(props.schedules);
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({schedules:props.schedules});
      this.props.initialize({ id:props.id,
        name: props.name,
        typicalDose:props.typicalDose,
        notes:props.notes,
        frequency:'Daily',
        startAt: moment(props.startAt).format('MMMM Do YYYY'),
        schedules:props.schedules});}
  }
  getSelectedTiming(e)
  {
    this.setState({showDropdown:null});
    this.setTiming(isMobile ? new Date(dateService.formatHourToDate(e.target.value)):e.target.value);
  }

  handleModal(){
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.schedule.current.focus();
    this.schedule.current.click();
  }


  handleWebTimePicker()
  {
    this.setState({showDropdown:!this.state.showDropdown});
  }

  sortByDate (arr){
    const sorter = (a, b) => {return new Date(a.scheduledAt).getTime() - new Date(b.scheduledAt).getTime();
    };
    arr?.sort(sorter);
  }

  handleRemoveTiming(e){
    let schedules = [...this.state.schedules];
    schedules.splice(e,1);
    this.assignStateandProps(schedules,this.props.initialValues);
  }

  checkSchedules(schedules,time)
  {
    let isPresent=false;
    let selectedTime =new Date(dateService.formatHourToDate(time,true));
    schedules.map((data)=>{
      if((new Date(data.scheduledAt).getHours()==selectedTime.getHours()) && (new Date(data.scheduledAt).getMinutes()==selectedTime.getMinutes()))
      {isPresent=true;}
    });
    return isPresent;
  }

  setTiming(selectedTime){
    let time=selectedTime;
    if(selectedTime=='Invalid Date')
    {
      time=moment();
    }
    let schedules = [...this.state.schedules];
    if(!this.checkSchedules(schedules,time )){
      schedules.push({scheduledAt: dateService.formatHourToDate(time,true)});
    }else{this.setState({isRepeatedSchedules:true});
      setTimeout(function(){this.setState({isRepeatedSchedules:false});}.bind(this),2000);}
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({isOpen:!this.state.isOpen});
    this.assignStateandProps(schedules,this.props.initialValues);
  }

  assignStateandProps(schedules,initialValues)
  {
    this.sortByDate(schedules);
    this.setState({schedules:schedules});
    this.props.initialize({ id:initialValues.id?initialValues.id:'',
      name: this.name.current.value,
      typicalDose:this.typicalDose.current.value,
      notes:this.notes.current.value,
      frequency:'Daily',
      startAt: initialValues.id?moment(initialValues.startAt).format('MMMM Do YYYY'):moment().format('MMMM Do YYYY'),
      schedules:schedules});
  }

  render() {

    const { submitting, pristine, saving, handleSubmit,handleBack} = this.props;
    const isSubmitting = submitting || saving;
    const isSubmitDisabled = isSubmitting || pristine;

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <div className="form-row ">
            <Field
              readOnly={submitting}
              label={local.medicationFormNameLabel}
              autoFocus
              name="name"
              ref={this.name}
              component={TextField}
              validate={[validators.required]} />
          </div>

          <div className="form-row">
            <Field
              readOnly={isSubmitting}
              label={local.medicationFormTypicalDoseLabel}
              name="typicalDose"
              ref={this.typicalDose}
              component={TextField}
              validate={[validators.maxLength250]}
              placeholder={local.medicationFormTypicalDosePlaceholder} />
          </div>

          <div className="form-row">
            <Field
              readOnly={isSubmitting}
              label={local.formNotesLabel}
              name="notes"
              component={TextField}
              ref={this.notes}
              validate={[validators.maxLength500]}
              placeholder={local.medicationFormNotesPlaceholder} />
          </div>

          <div className="form-row frequencyMed">
            <Field
              readOnly
              label={local.medicationFrequencyLabel}
              name="frequency"
              component={TextField}
              validate={[validators.maxLength500]}
              placeholder={local.medicationFormNotesPlaceholder} />
          </div>

          <div className="form-row doseTiming">
            {this.state.schedules?.map((data,i) =>(
              <div className="badge-pill" key={i}>
                <TimeSlot scheduledAt={data.scheduledAt} />

                <button type="button" className="clearBtn" onClick={this.handleRemoveTiming.bind(this,i)} key={i} name={data.scheduledAt}> <Icon symbol="cross" /> </button>
              </div>)
            )}
          </div>

          <div className="form-row timeSlotError">
            {this.state.schedules?.length<=0 && <p className="timeSlotRequired">{local.medictaionTimeSlotErrorLabel}</p>}
          </div>

          <div className="form-row timeSlotError">
            {this.state.schedules?.length<=0 && <p className="timeSlotError">{local.medicationTimeSlotInfoLabel}</p>}
          </div>

          <div className="form-row timeSlotError">
            {this.state.isRepeatedSchedules && <p className="timeSlotError">{local.medictaionSameTimeSlotErrorLabel}</p>}
          </div>

          <div className="form-row addTimingDiv">
            {isMobile &&
            <div className="add-timing-mob">
              { isAndroid && <input type="time" ref={this.schedule} className="mobile-time" onChange={this.getSelectedTiming} />}

              { isIOS && <input type="time" ref={this.schedule} className="mobile-time" onBlur={this.getSelectedTiming} />}

              <span onClick={()=>this.handleModal()} className="addTimeBtn"><Icon symbol="add"></Icon>{local.medicationAddTimingLabel}</span>
            </div>}

            {this.state.showDropdown && !isMobile && <TimePicker defaultValue='12:00AM' onBlur={this.getSelectedTiming} />}

            {!isMobile && <span onClick={()=>this.handleWebTimePicker()} className="addTimeBtn"><Icon symbol="add"></Icon>{local.medicationAddTimingLabel}</span>}
          </div>

          <div className="form-row startDate">
            <Field
              readOnly
              label={local.medicationFormStartLabel}
              name="startAt"
              component={TextField}
              validate={[validators.maxLength500]}
              placeholder={local.medicationFormNotesPlaceholder} />
          </div>

          <div className="form-row">
            <Button id="medication-form-cancel" className="btn btn-secondary" onClick={handleBack}>{local.formCancel}</Button>
            <Button
              id="medicaiton-form-submit"
              className="btn btn-primary"
              submit
              color="blue"
              disabled={isSubmitDisabled || this.state.schedules?.length<=0 || this.props.initialValues.name==''}>
              { !this.props.initialValues.id && local.medicationDoseFormSubmit}

              { this.props.initialValues.id && 'Update'}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

LogMedicationForm.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  handleBack: PropTypes.func.isRequired,
  // redux
  saving: PropTypes.bool.isRequired,
  // redux form
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool
};

const form = reduxForm({
  form: 'log-medication',
  initialValues:{frequency:'Daily',startAt:moment().format('MMMM Do YYYY'), schedules:[{scheduledAt:dateService.formatHourToDate(defaultMedicationTimingPreferences?.split(',')[0])},{scheduledAt:dateService.formatHourToDate(defaultMedicationTimingPreferences?.split(',')[1])}]},
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(LogMedicationForm);

function mapStateToProps(state) {
  return {
    saving: state.medication.saving
  };
}

export default connect(mapStateToProps, null)(form);

