import compileReducers from 'services/compile-reducer';
import { actionNames } from './request-actions';

export const initialState = { nutritionSaved: false, glucoseSaved: false, bloodPressureGoalSaved: false};
export const defaultStatus = {
  success: false,
  processing: false,
  hasError: false,
  error: null,
  params: null
};
export const reducers = {};

reducers[actionNames.REQUESTED] = (state, payload) => {
  const { requestId, params } = payload;

  return {
    ...state,
    [requestId]: {
      success: false,
      processing: true,
      hasError: false,
      error: null,
      params
    }
  };
};

reducers[actionNames.REQUEST_SUCCESS] = (state, payload) => {
  const { requestId, result, params } = payload;

  return {
    ...state,
    [requestId]: {
      success: true,
      processing: false,
      hasError: false,
      error: null,
      result,
      params
    }
  };
};

reducers[actionNames.REQUEST_ERROR] = (state, payload) => {
  const { requestId, error, params } = payload;

  return {
    ...state,
    [requestId]: {
      success: false,
      processing: false,
      hasError: true,
      error,
      params
    }
  };
};

reducers[actionNames.NUTRITION_SAVED] = (state,payload) => {

  return {
    ...state,
    nutritionSaved: payload
  };
};

reducers[actionNames.GLUCOSE_SAVED] = (state,payload) => {

  return {
    ...state,
    glucoseSaved: payload
  };
};

reducers[actionNames.BLOOD_PRESSURE_GOAL_SAVED] = (state,payload) => {

  return {
    ...state,
    bloodPressureGoalSaved: payload
  };
};


export default compileReducers(reducers, initialState);
