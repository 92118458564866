import { getType } from 'app/request/persistent-data-selectors';
import { types } from './action-names';
import { types as programTypes } from 'programs/programs-types';
import { getRequest } from 'app/request/request-selectors';
import * as Yup from 'yup';
import local from 'services/localization/local';
import { isMobile } from 'mobile/helpers';
import { MaxDescriptionLength, MaxGoalNameLength, MinDescriptionLength, MobileSubstringDescriptionLength, SubstringDescriptionLength } from 'services/constants';
import * as selectors from 'app/request/persistent-data-selectors';
import { ObservationDetailType, ObservationType, Unit } from 'services/enums';
import * as constant from 'services/constants';
import { ConvertPercentToGrams } from 'services/helpers';

export const getGoalRequest = (state, requestId) => {
  return getRequest(state, requestId);
};

export const getProgramInfos = (state) => {
  const profileId = state.selectedProfile?.id;
  const { loaded, loading, hasError, data } = getType(state.programs, programTypes.PROGRAM_INFOS, profileId);
  return {
    loaded,
    loading,
    hasError,
    data
  };
};

export const getGoalCategories = (state) => {
  const profileId = state.selectedProfile?.id;
  const { loaded, loading, hasError, data } = getType(state.profileGoals, types.GOAL_CATEGORY, profileId);
  return {
    loaded,
    loading,
    hasError,
    data
  };
};

export const getProfileGoalsList = (state) => {
  const profileId = state.selectedProfile?.id;
  const { loaded, loading, hasError, data } = getType(state.profileGoals, types.GOALS, profileId);
  return {
    loaded,
    loading,
    hasError,
    data
  };
};

export const getGoalSummary = (state, goalId) => {
  const {loaded, loading, hasError, data} = getType(state.profileGoals, types.GOAL_SUMMARY, goalId);
  return{
    loaded,
    loading,
    hasError,
    data
  };
};

export const getSelectedGoalCategoryId = (state) => {
  return state?.profileGoals?.goalCategoryId;
};

export const getGoalComponentProps = (state, props, programs) => {
  const { data, loading, loaded } = getGoalCategories(state);
  const result = getProfileGoalsList(state);
  const goalCategoryId = props?.location?.state?.backpath?.goalCategoryId;
  const { data: programInfos, loading: programInfoLoading, loaded: programInfoLoaded } = getProgramInfos(state);
  let programIds = [];
  let profileGoalPrograms = [];
  const filteredPrograms = programs?.filter(x => x.isSelected);
  filteredPrograms?.map((program) => {
    profileGoalPrograms.push({
      goalProgramId: program.goalProgramId,
      isActive: program.isSelected
    });
    programIds.push(program.programId);
  });
  data?.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
  return {
    goalCategory: data,
    profileGoals: result?.data?.sort((a, b) => { return a.name.localeCompare(b.name); }),
    loaded: result?.loaded,
    loading: result?.loading,
    hasError: result?.hasError,
    goalCategoryId: goalCategoryId,
    profileGoal: result?.data?.find(x => x.goalCategoryId === goalCategoryId),
    goalCategoryData: data?.find(x => x.id === goalCategoryId),
    programInfos: programInfos,
    programInfoLoading: programInfoLoading,
    programInfoLoaded: programInfoLoaded,
    programIds: programIds,
    profileGoalPrograms: profileGoalPrograms,
    goalCategoryLoading: loading,
    goalCategoryLoaded: loaded
  };
};

export const validation = Yup.object({
  goalName: Yup.string()
    .required(local.validationRequired)
    .max(75, local.formatString(local.validationMaxCharLength, MaxGoalNameLength)),
  description: Yup.string()
    .max(500, local.formatString(local.validationMaxCharLength, MaxDescriptionLength))
    .nullable()
});

export const trimDescription = (description) => {
  if (description.length < MinDescriptionLength && !isMobile) {
    return description;
  }
  let trimmedDescription = description?.substr(0, MobileSubstringDescriptionLength);
  if (!isMobile) {
    trimmedDescription = description?.substr(0, SubstringDescriptionLength);
  }
  return trimmedDescription = trimmedDescription?.substr(0, Math.min(trimmedDescription.length)).concat('...');
};

export const setProgram = (data, goalPrograms, profileGoalPrograms, setPrograms, setSelectAllPrograms, props) => {
  const programList = [];
  data?.map((x) => {
    const goalProgram = goalPrograms?.find(y => y.programId === x.programId);
    const profileGoalProgram = profileGoalPrograms?.find(z => z.goalProgramId === goalProgram?.id && z.isActive);
    var program = {
      id: x.id,
      description: x.description,
      iconPath: x.iconPath,
      title: x.name,
      isSelected: goalProgram?.isPrimaryProgram ? true : profileGoalProgram ? true : false,
      isPrimaryProgram: goalProgram?.isPrimaryProgram,
      observation: x.description,
      goalProgramId: goalProgram?.id,
      programId: goalProgram?.programId
    };
    programList.push(program);
  });
  setPrograms(programList);
  var selectedProgramCount = programList?.every(x => x.isSelected === true);
  if (selectedProgramCount) {
    setSelectAllPrograms(true);
  }
  else {
    setSelectAllPrograms(false);
  }
  props?.updatePrograms(programList);
};

export const selectAll = (value, programs, setPrograms, setSelectAllPrograms, props) => {
  let programsCopy = programs && [...programs];

  programsCopy?.map(function (x) {
    if (x.isPrimaryProgram) {
      x.isSelected = true;
    }
    else {
      x.isSelected = value;
    }
    return x;
  });
  setPrograms(programsCopy);
  setSelectAllPrograms(value);
  props?.updatePrograms(programsCopy);
};

export const selectOneProgram = (programs, id, isSelected, setPrograms, setSelectAllPrograms, props) => {
  let programsCopy = programs && [...programs];
  programsCopy.filter(x => x.id === id)[0].isSelected = isSelected;
  setPrograms(programsCopy);
  props?.updatePrograms(programsCopy);
  var selectedProgramCount = programsCopy?.filter(x => x.isSelected === true);
  if (selectedProgramCount) {
    if (selectedProgramCount?.length === programsCopy?.length)
      setSelectAllPrograms(true);
    else
      setSelectAllPrograms(false);
  }
};

export const getGoalSummaryProps = (profileGoal, goalCategory) =>{
  const goalSummaryModel = [];
  const programIds  = [];
  profileGoal?.profileGoalPrograms?.map((x) => {
    const goalProgram = goalCategory?.goalPrograms?.find(y => y.id === x.goalProgramId);
    goalProgram && programIds.push(goalProgram?.programId);
    goalProgram && goalSummaryModel.push({
      programId: goalProgram?.programId,
      isPrimaryProgram: goalProgram?.isPrimaryProgram,
      primaryObservationType: goalProgram?.primaryObservationType
    });
  });

  return{
    goalSummaryModel,
    programIds
  };
};

export const isGoalBasedEnrollment = (state, enrollmentId) => {
  const data = selectors.getData(state.profileGoals, types.IS_GOAL_BASED_ENROLLMENT, enrollmentId);
  return data;
};

export const getProfileObservationGoals = (state, profileId) => {
  const data = selectors.getData(state.profileGoals, types.PROFILE_OBSERVATION_GOALS, profileId);
  return data;
};

export const getGoalDetails = (state) => {
  return state.profileGoals;
};

export const getNutritionGoal = (activeGoal) => {
  if (activeGoal) {
    const result = {};
    result.calories = activeGoal.value;
    result.UnitType = activeGoal?.displayUnit;
    result.totalGrams = activeGoal?.totalForPercentageCalc;
    if (activeGoal.goalDetails && activeGoal.goalDetails.length > 0) {
      result.carbs = activeGoal.goalDetails.find(d => d.observationDetailType == ObservationDetailType.Carbohydrate)?.value;
      result.protein = activeGoal.goalDetails.find(d => d.observationDetailType == ObservationDetailType.Protein)?.value;
      result.fat = activeGoal.goalDetails.find(d => d.observationDetailType == ObservationDetailType.Fat)?.value;
    }
    return {
      calorieGoal: result.calories,
      carbs: result?.UnitType === Unit.Grams ? ConvertPercentToGrams(result?.carbs, result?.totalGrams) : parseFloat(result?.carbs).toFixed(0) ?? constant.defaultNutritionGoal.carbs,
      protein: result?.UnitType === Unit.Grams ? ConvertPercentToGrams(result?.protein, result?.totalGrams) : parseFloat(result?.protein).toFixed(0) ?? constant.defaultNutritionGoal.protein,
      fat: result?.UnitType === Unit.Grams ? ConvertPercentToGrams(result?.fat, result?.totalGrams) : parseFloat(result?.fat).toFixed(0) ?? constant.defaultNutritionGoal.fat,
      nutritionUnit: result.UnitType,
      displayUnit: activeGoal?.displayUnit,
      totalGrams: activeGoal?.totalForPercentageCalc,
      formTouch: false
    };
  }
  return {
    calorieGoal: constant.defaultNutritionGoal.cal,
    carbs: constant.defaultNutritionGoal.carbs,
    protein: constant.defaultNutritionGoal.protein,
    fat: constant.defaultNutritionGoal.fat,
    displayUnit: Unit.Percent,
    formTouch: false
  };
};

export const getInitialFormDetails = (profileObservationGoals) => {
  let nutritionGoal = null;
  let systolicGoal = null;
  let diastolicGoal = null;
  let bloodGlucoseGoal = null;
  let steps = constant.MIN_STEPS_GOAL_TARGET;
  if (profileObservationGoals && profileObservationGoals.goals.length > 0) {
    profileObservationGoals.goals.map((observationGoal) => {
      if (observationGoal.observationType == ObservationType.Nutrition) {
        nutritionGoal = getNutritionGoal(observationGoal);
      }
      if (observationGoal.observationType == ObservationType.Systolic) {
        systolicGoal = getSystolicGoal(observationGoal);
      }
      if (observationGoal.observationType == ObservationType.Diastolic) {
        diastolicGoal = getDiastolicGoal(observationGoal);
      }
      if (observationGoal.observationType == ObservationType.BloodGlucose) {
        bloodGlucoseGoal = getBloodGlucoseGoal(observationGoal);
      }
      if (observationGoal.observationType == ObservationType.Steps) {
        steps = observationGoal.value;
      }
    });
  }
  if (nutritionGoal == null) {
    nutritionGoal = getNutritionGoal(null);
  }
  let initialValues = { ...nutritionGoal, ...systolicGoal, ...diastolicGoal, ...bloodGlucoseGoal, steps };
  return initialValues;
};

export const getSystolicGoal = (activeGoal) => {
  if (activeGoal) {
    return {
      systolicMin: activeGoal.fromValue,
      systolicMax: activeGoal.toValue
    };
  }
  return null;
};

export const getDiastolicGoal = (activeGoal) => {
  if (activeGoal) {
    return {
      diastolicMin: activeGoal.fromValue,
      diastolicMax: activeGoal.toValue
    };
  }
  return null;
};

export const getBloodGlucoseGoal = (activeGoal) => {
  if (activeGoal) {
    return {
      fromValue: activeGoal?.fromValue,
      toValue: activeGoal?.toValue
    };
  }
  return null;
};

export const getBloodPressureGoalFormFieldValues = () => {
  let fromValue = 0;
  let toValue = 0;
  let fromValueElement = document.getElementsByName('fromValue');
  let toValueElement = document.getElementsByName('toValue');
  if (fromValueElement.length > 0) {
    fromValue = fromValueElement[0].value;
  }
  if (toValueElement.length > 0) {
    toValue = toValueElement[0].value;
  }
  return { fromValue, toValue };
};


