/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import moment from 'moment';
import local from 'services/localization/local';
import PropTypes from 'prop-types';
import { connect,useSelector } from 'react-redux';
import withContext from 'app/withContext';
import {getActiveGlucoseGoal, getAveragePerWeekBloodGlucose, getBloodGlucoseVitalChartData, getMinMaxBloodGlucoseValue, getMinMaxHighFrequencyData} from '../bloodGlucose-selector';
import { ArrowButton } from 'shared';
import './vitals-chart-blood-glucose.scss';
import withNavigation from 'app/withNavigation';
import BloodGlucoseLineChart from './BloodGlucoseLineChart';
import BloodGlucoseObservationLineChart from './BloodGlucoseObservationLineChart';
import  HealthCardReading  from 'health/observations/HealthCardReadings/HealthCardReading';
import { routePaths } from 'services/routing';
import { ObservationType } from 'services/enums';

export const getTitle = (selectedEnd) =>{
  return local.formatString(`${moment(selectedEnd).format('MMM D')}`);
};

function BloodGlucoseVisualization({selectPreviousDay,selectNextDay,bloodGlucose,formatter,profileId,selectedEnd,data,isHighFrequencyData,bloodGlucoseAverage,activeGoal, navigate,fromPathway,pathwayStartDate,pathwayEndDate,routeParams, location}) {
  const minMaxValue = useSelector(state => getMinMaxBloodGlucoseValue(state, profileId,fromPathway,bloodGlucose));
  const hFMinMaxValue = useSelector(state => getMinMaxHighFrequencyData(state, profileId, selectedEnd,fromPathway,bloodGlucose));
  function setTarget()
  {
    const fromPathwayRoute = fromPathway ? true : false;
    const routeParamsRoute = fromPathway &&  routeParams ? routeParams : '';
    const isFromCreateGoal = location?.state?.backpath?.isFromCreateGoal ?? false;
    return navigate({to: routePaths.bloodGlucoseGoal,state:{fromPathway : fromPathwayRoute,routeParams:routeParamsRoute, isFromCreateGoal:isFromCreateGoal}});
  }
  if(isHighFrequencyData){
    const previousDisabled = fromPathway ? moment(pathwayStartDate) >= moment(selectedEnd) : false;
    const nextDisabled = fromPathway ? moment(pathwayEndDate) < moment(selectedEnd).add(1,'days') : moment(selectedEnd).add(1, 'day') > moment();
    let average = data && data.length > 0  ? data[0].average :'';
    // eslint-disable-next-line no-inner-declarations
    return (
      <div id="steps-visualization" className='blood-glucose-visualization'>
        <div className="steps-vis-header">
          <ArrowButton id="previous-steps-button" onClick={selectPreviousDay} disabled={previousDisabled} />
          <small className="steps-vis-title">{getTitle(selectedEnd)}</small>
          <ArrowButton id="next-steps-button" rightArrow onClick={selectNextDay} disabled={nextDisabled} />
        </div>
        <div className='avg-wrapper'>
          <div className='average-container'>
            {average ? <div className='average-value'>{average} <span className='average-unit'>{local.observation.bloodGlucose.unit}</span></div> : <div className='no-data-label'>{local.observation.bloodGlucose.noData} </div>}
            <div className='average-label'>  {local.observation.bloodGlucose.avgPerDay}</div>
          </div>
          {activeGoal ?
            <div className='average-container'>
              <div className='average-value'>{hFMinMaxValue.inRangePercent}%</div>
              <div className='average-label'>  {local.observation.bloodGlucose.inRange}</div>
            </div> :''}
        </div>
        <div className="chart-container" ><BloodGlucoseLineChart data={data} bloodGlucose={bloodGlucose} profileId={profileId} formatter={formatter} selectEnd={selectedEnd} activeGoal={activeGoal} isHighFrequencyData={isHighFrequencyData} /></div>
        <HealthCardReading
          minVal={hFMinMaxValue.min}
          maxVal={hFMinMaxValue.max}
          observationType={ObservationType.BloodGlucose}
          units={local.units.mgdL}
          activeGoal={activeGoal}
          onClick={setTarget}
          buttonId={activeGoal ? 'change-bloodGlucose-goal' : 'add-bloodGlucose-goal'} />
      </div>
    );
  }else {
    return (
      <div id="steps-visualization" className='mt-3 blood-glucose-visualization'>
        <div className='avg-wrapper'>
          <div className='average-container'>
            {bloodGlucoseAverage ? <div className='average-value'>{bloodGlucoseAverage} <span className='average-unit'>{local.observation.bloodGlucose.unit}</span></div> : <div className='no-data-label'>{local.observation.bloodGlucose.noData} </div>}
            <div className='average-label'>  {local.observation.bloodGlucose.avgPerWeek}</div>
          </div>
          {activeGoal?
            <div className='average-container'>
              <div className='average-value'>{minMaxValue.inRangePercent}%</div>
              <div className='average-label'>  {local.observation.bloodGlucose.inRange}</div>
            </div> :''}
        </div>
        <div className="chart-container" ><BloodGlucoseObservationLineChart bloodGlucose={bloodGlucose} formatter={formatter} activeGoal={activeGoal} profileId={profileId} isHighFrequencyData={isHighFrequencyData} /></div>
        <HealthCardReading
          minVal={minMaxValue.min}
          maxVal={minMaxValue.max}
          observationType={ObservationType.BloodGlucose}
          units={local.units.mgdL}
          activeGoal={activeGoal}
          onClick={setTarget}
          buttonId={activeGoal ? 'change-bloodGlucose-goal' : 'add-bloodGlucose-goal'} />
      </div>
    );
  }


}
BloodGlucoseVisualization.propTypes = {
  selectedEnd: PropTypes.string.isRequired,
  selectPreviousDay: PropTypes.func.isRequired,
  selectNextDay: PropTypes.func.isRequired,
  // redux
  data: PropTypes.arrayOf(PropTypes.shape).isRequired,
  profileId: PropTypes.string.isRequired
};

function mapStateToProps(state, props) {
  return {
    data: getBloodGlucoseVitalChartData(state, props.profileId, props.selectedEnd,props.fromPathway,props.bloodGlucose),
    bloodGlucoseAverage: getAveragePerWeekBloodGlucose(state, props.profileId,props.fromPathway,props.bloodGlucose),
    activeGoal: getActiveGlucoseGoal(state, props.profileId)
  };
}

export default withNavigation(withContext(connect(mapStateToProps)(BloodGlucoseVisualization)));
