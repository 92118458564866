import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'shared/icon/Icon';

function ErrorLabel(props) {
  const { show, error, forceShowError } = props;
  if (!show || error === ' ' && !forceShowError) {
    return null;
  }
  return (
    <span className="errorLabel">
      <Icon symbol="formError" size="1.3em" />
      <small>{error}</small>
    </span>
  );
}

ErrorLabel.propTypes = {
  show: PropTypes.bool,
  error: PropTypes.string,
  forceShowError: PropTypes.bool
};

export default ErrorLabel;
