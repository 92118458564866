import compileReducers from 'services/compile-reducer';
import * as requestQueueReducer from 'app/request/request-queue-reducer';
import * as actions from './action-names';
import { types } from './medication-types';

export const initialState = {
  medications: [],
  loading: false,
  loadError: false,
  loaded: false,
  saving: false,
  saved: false,
  saveError: false,
  [types.DELETE]: requestQueueReducer.initialState
};

const reducers = {};

reducers[actions.MEDICATION_REQUESTED] = (state) => ({
  ...state,
  loading: true,
  loaded: false
});

reducers[actions.MEDICATION_LOAD_SUCCESS] = (state, payload) => ({
  ...state,
  medications: payload,
  loading: false,
  loaded: true,
  loadError: false
});

reducers[actions.MEDICATION_LOAD_ERROR] = (state) => ({
  ...state,
  loading: false,
  loaded: false,
  loadError: true
});

reducers[actions.MEDICATION_SAVING] = (state) => {
  return {
    ...state,
    saving: true,
    saved: false,
    saveError: false
  };
};

reducers[actions.MEDICATION_SAVE_SUCCESS] = (state, payload) => {
  const medications = state.medications.filter(x => x.id !== payload.id);

  return {
    ...state,
    medications: [...medications, payload],
    saving: false,
    saved: true,
    saveError: false
  };
};

reducers[actions.MEDICATION_SAVE_ERROR] = (state) => {
  return {
    ...state,
    saving: false,
    saved: false,
    saveError: true
  };
};

reducers[actions.MEDICATION_SAVE_RESET] = (state) => {
  return {
    ...state,
    saved: false,
    saveError: false
  };
};

reducers[actions.MEDICATION_DELETE] = (state, payload) => {
  const medications = state.medications.filter(x => x.id !== payload.medicationId);

  return {
    ...state,
    medications
  };
};

export default compileReducers(reducers, initialState);
