import React from 'react';
import local from 'services/localization/local';
import { ObservationType, Unit } from 'services/enums';
import { routePaths } from 'services/routing';
import { configIds } from 'health/observations/observation-config-ids';
import { baseObservationConfig } from 'health/observations/observation-config-helpers.jsx';
import LogImmunization from 'health/observations/immunization/log/LogImmunization';
import { Immunizations } from 'shared/fullColorIcons';
import ImmunizationList from 'health/observations/immunization/listing/ImmunizationList';
import { deleteFileObservation, loadImmunizationData } from 'health/action-creators';

const typeDefinition = {
  type: ObservationType.Immunization,
  typeUnitMap: {
    [ObservationType.Immunization]: Unit.Unknown
  },
  unitLabelMap: {
    [Unit.Unknown]: local.units.unknown
  }
};

export default {
  ...baseObservationConfig,
  ...typeDefinition,
  id: configIds.immunization,
  backPath: { to: routePaths.health },
  centerElement: local.observation.immunization.title,
  noDataText: local.observation.immunization.tileNoDataText,
  iconSymbol: Immunizations,
  loadData: (props) => [props.config.type].flat().forEach(x => props.dispatch(loadImmunizationData({ observationType: x, profileId: props.profileId }))),
  deleteData: (props,deleteFileObservationModal) =>props.dispatch(deleteFileObservation({ observationType: props.observation.observationType, observationId: props.observation.id, profileId: props.observation.profileId, deleteFileObservationModal:deleteFileObservationModal})),
  // eslint-disable-next-line react/prop-types
  renderListComponent: (props) => props.observations && <ImmunizationList observations={props.observations} config={props.config} />,
  renderChart: (props) => props.observations > 1 && null,
  // eslint-disable-next-line react/no-multi-comp
  renderLogComponent: (props) => props && <LogImmunization {...props} />
};
