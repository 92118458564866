import styleVars from 'styles/_variables.scss';

export const getData = (data,isHighFrquencydata) =>{
  if(data){
    const dataSets = isHighFrquencydata? data.map(point => ({ y: point.totalBloodGlucose, t: point.time})) :data.map(point => ({ y: point.value, t: point.recordedAt}));
    return {
      datasets: [{
        label: 'Data',
        borderColor: styleVars.colorBlue,
        backgroundColor: styleVars.colorBlue,
        pointRadius: 0,
        pointHoverRadius: 4,
        pointHitRadius: 30,
        lineTension: 0,
        borderWidth: 1,
        fill: false,
        data: dataSets
      }],
      type:'line',
      labels:isHighFrquencydata? data.map((point)=> point.time) :data.map((point)=> point.recordedAt)
    };
  }
};
function buildAnnotations (fromValue,toValue,color) {
  return {
    type: 'box',
    xScaleID: 'x-axis-1',
    yScaleID: 'y-axis-0',
    borderColor: 'transparent',
    backgroundColor: color,
    yMin: fromValue,
    yMax: toValue,
    drawTime: 'beforeDatasetsDraw'
  };
}

const buildLineAnnotations = (value) =>{
  return {
    type: 'line',
    mode: 'horizontal',
    scaleID: 'y-axis-0',
    borderColor: styleVars.colorGray,
    borderWidth: 0.5,
    borderDash:[5,5],
    value:value
  };

};
const getAnnotations = (activeGoal, minMaxValue, data) => {
  const annotations = [];

  const goalColor = styleVars.colorGrayLight60;

  if (activeGoal && data && data.length > 0 && minMaxValue) {
    const { toValue, fromValue } = activeGoal;
    annotations.push(buildAnnotations(fromValue,toValue,goalColor));
    annotations.push(buildLineAnnotations(fromValue));
    annotations.push(buildLineAnnotations(toValue));
  }else if(activeGoal){
    const { toValue, fromValue } = activeGoal;
    annotations.push(buildAnnotations(activeGoal.fromValue,activeGoal.toValue,goalColor));
    annotations.push(buildLineAnnotations(fromValue));
    annotations.push(buildLineAnnotations(toValue));
  }else{
    annotations.push({});
  }
  return { annotations };
};
export const getOptions = (data,activeGoal,minMaxValue,isHighFrquencydata) =>{
  return {
    responsive: true,
    legend: {
      display: true,
      backgroundColor:'transparent',
      borderColor:'transparent',
      position: 'bottom',
      labels: {
        boxWidth: 0,
        fontColor: 'transparent'
      }
    },
    scales: {
      xAxes: [{
        display: true,
        gridLines: {
          drawOnChartArea: false
        },
        ticks: {
          fontColor: styleVars.colorGray,
          fontSize: 10,
          display: true,
          autoSkip: true,
          maxTicksLimit: isHighFrquencydata? 4 : 6,
          minTicksLimit: isHighFrquencydata ?4 : 5,
          reverse: false,
          maxRotation: 0,
          minRotation: 0
        }
      }],

      yAxes: [{
        display: true,
        gridLines: {
          drawOnChartArea: false
        },
        ticks: {
          fontColor: styleVars.colorGray,
          fontSize: 10,
          maxTicksLimit: 5,
          minTicksLimit: 4,
          precision: 0,
          stepSize: 2
        }
      }]

    },
    annotation:getAnnotations(activeGoal,minMaxValue,data),
    tooltips: {
      backgroundColor: styleVars.colorWhiteOff,
      bodyFontColor: styleVars.colorGrayDark,
      bodyFontStyle: 'normal',
      bodyFontFamily: styleVars.fontStyleNormal,
      callbacks: {
        label: (tooltipItem) => `${Math.round(tooltipItem.yLabel)} mg/dL`
      },
      cornerRadius: 0,
      displayColors: false,
      titleFontColor: styleVars.colorGrayDark,
      titleFontStyle: 'bold',
      titleFontSize: 14
    }
  };

};

