import compileReducers from 'services/compile-reducer';
import * as persistentDataReducer from 'app/request/persistent-data-reducer';
import { types } from './action-names';

export const initialState = {
  [types.GET_ROOT_ORGANIZATION]: persistentDataReducer.initialState,
  [types.GET_PARTICIPANT_DETAILS]: persistentDataReducer.initialState,
  [types.GET_UNREAD_COUNT_DETAILS]: persistentDataReducer.initialState,
  [types.RESET_UNREAD_COUNT_DETAILS]: persistentDataReducer.initialState
};

const reducers = {
  ...persistentDataReducer.reducers(types.GET_ROOT_ORGANIZATION),
  ...persistentDataReducer.reducers(types.GET_PARTICIPANT_DETAILS),
  ...persistentDataReducer.reducers(types.GET_UNREAD_COUNT_DETAILS),
  ...persistentDataReducer.reducers(types.RESET_UNREAD_COUNT_DETAILS)
};

export default compileReducers(reducers, initialState);
