import { getRequest } from 'app/request/request-selectors';
import { getData, getType } from 'app/request/persistent-data-selectors';
import { types } from './connect-types';

export const getVerificationProcessing = (state, profileRequestId, questionsRequestId) => {
  return (
    getRequest(state, profileRequestId).processing ||
    getRequest(state, questionsRequestId).processing
  );
};

export const getVerifyIdentityFailed = (state, profileId, answersRequestId, verifyIdentityRequestId) => {
  const questionsError = getRequest(state, answersRequestId).hasError;
  const profile = state.profile.list.find(x => x.id === profileId);
  const profileValidationFailed = profile ? profile.validationFailed : false;
  const verifyRequest = getRequest(state, verifyIdentityRequestId);
  const verifyError = verifyRequest.hasError
    && verifyRequest.error
    && verifyRequest.error.status >= 400;

  return { isFailed: questionsError || profileValidationFailed || verifyError, syncResponseCode: verifyRequest?.error?.body?.syncResponseCode };
};

export const getIsSyncing = (state) => {
  const { loaded, loading, hasError, data } = getType(state.connect, types.SYNC_STATUS, state.selectedProfile.id);
  const { isConnected, lastSyncTime, foundRecords, profileId, triedCount } = data || {};
  const { isSyncing } = false;
  const result = { loaded, loading, isConnected, isSyncing, lastSyncTime, profileId, foundRecords, hasError, triedCount };
  return result;
};

export const getIdentityVerificationStatus = (state, profileId) => getData(state.connect, types.IDENTITY_VERIFICATION_STATUS, profileId);

export const getIdentityVerifiedStatus = (state, profileId) => {
  const idVerifiedStatus = state.connect[types.IDENTITY_VERIFIED_STATUS];
  return idVerifiedStatus && idVerifiedStatus[profileId];
};
