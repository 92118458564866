export const HEALTH_SCORE_REQUESTED = 'HEALTH_SCORE_REQUESTED';
export const HEALTH_SCORE_LOADED = 'HEALTH_SCORE_LOADED';
export const HEALTH_SCORE_NOTENOUGHINFO = 'HEALTH_SCORE_NOTENOUGHINFO';
export const HEALTH_SCORE_LOADERROR = 'HEALTH_SCORE_LOADERROR';

export const HEALTH_OBSERVATION_REQUESTED = 'HEALTH_OBSERVATION_REQUESTED';
export const HEALTH_OBSERVATION_LOADED = 'HEALTH_OBSERVATION_LOADED';
export const HEALTH_OBSERVATION_LOADERROR = 'HEALTH_OBSERVATION_LOADERROR';

export const HEALTH_OBSERVATION_FILE_REQUESTED = 'HEALTH_OBSERVATION_FILE_REQUESTED';
export const HEALTH_OBSERVATION_FILE_LOADED = 'HEALTH_OBSERVATION_FILE_LOADED';
export const HEALTH_OBSERVATION_FILE_LOADERROR = 'HEALTH_OBSERVATION_FILE_LOADERROR';

export const HEALTH_OBSERVATION_RESETFORM = 'HEALTH_OBSERVATION_RESETFORM';
export const HEALTH_OBSERVATION_SAVEREQUESTED = 'HEALTH_OBSERVATION_SAVEREQUESTED';
export const HEALTH_OBSERVATION_SAVED = 'HEALTH_OBSERVATION_SAVED';
export const HEALTH_OBSERVATION_SAVEERROR = 'HEALTH_OBSERVATION_SAVEERROR';

export const HEALTH_OBSERVATION_DELETE = 'HEALTH_OBSERVATION_DELETE';
export const HEALTH_OBSERVATION_DELETE_UNDO = 'HEALTH_OBSERVATION_DELETE_UNDO';

export const HEALTH_OBSERVATION_HISTORY_ITEM_SELECT = 'HEALTH_OBSERVATION_HISTORY_ITEM_SELECT';
export const HEALTH_OBSERVATION_HISTORY_ITEM_DESELECT = 'HEALTH_OBSERVATION_HISTORY_ITEM_DESELECT';

export const HEALTH_REQUIRE_HIPAA_CONSENT = 'HEALTH_REQUIRE_HIPAA_CONSENT';
export const HEALTH_HIPAA_CONSENT_CLEARED = 'HEALTH_HIPAA_CONSENT_CLEARED';

export const REDIRECT_FROM_IMMUNIZATION_ENABLE = 'REDIRECT_FROM_IMMUNIZATION_ENABLE';
export const REDIRECT_FROM_IMMUNIZATION_DISABLE = 'REDIRECT_FROM_IMMUNIZATION_DISABLE';

export const HIGH_FRQUENCY_DATA_OBSERVATION_LOADED = 'HIGH_FRQUENCY_DATA_OBSERVATION_LOADED';


