import * as profilesApi from 'services/api/profiles-api';
import * as requestActions from 'app/request/request-actions';
import { openRouteInApp, isMobile } from 'mobile/helpers';
import { types } from './products-types';
import { paymentGatewayRoot } from 'app/config';
import { getAccessToken } from 'identity/selectors';
import { Checkout, Tid, CoachName } from 'services/constants';
import { KeyType } from 'services/enums';
import * as persistentDataActions from 'app/request/persistent-data-actions';

export function initiatePayment(data) {
  return (dispatch) => {
    const action = state => profilesApi.createPayment(data, state)
      .then(payment => {
        if(isMobile)
          openRouteInApp(`${paymentGatewayRoot}/mobile/${Checkout}?${Tid}=${payment.id}&token=${getAccessToken(state)}`);
        else
          window.location.href = `${paymentGatewayRoot}/${Checkout}?${Tid}=${payment.id}`;
      });
    dispatch(requestActions.request(action, types.COACH_PAYMENT));
  };
}

export function createInvite(transactionId) {
  return (dispatch) => {
    const action = state => profilesApi.saveProgramInvite(transactionId,state);
    dispatch(requestActions.request(action, types.SAVE_PROGRAM_INVITE));
  };
}

export function loadGoldPayment(profileId) {
  localStorage.removeItem(types.GOLD);
  const action = state => profilesApi.getPaymentByKey(KeyType.ProfileId, profileId, types.GOLD, state);
  return persistentDataActions.request(action, types.GOLD, profileId);
}

export function loadPlatinumPayment(profileId) {
  localStorage.removeItem(types.PLATINUM);
  const action = state => profilesApi.getPaymentByKey(KeyType.ProfileId, profileId, types.PLATINUM, state);
  return persistentDataActions.request(action, types.PLATINUM, profileId);
}
