import React from 'react';
import { useSelector } from 'react-redux';
import { getIsEmailVerified } from 'identity/selectors';
import OrganizationInvitesList from 'organizations/organization-invites/OrganizationInvitesList';
import IncomingProfileInviteList from 'profiles/sharing/incoming-invites/IncomingProfileInviteList';
import { hasPendingInvites } from 'profiles/profiles-selectors';
import { NotVerifiedMessage } from 'shared';
import Auth0UserInfoRefresher from 'identity/Auth0UserInfoRefresher';
import InvitesLoader from 'profiles/InvitesLoader';
import local from 'services/localization/local';
import { getActiveUsersProgramInvites } from '../programs/programs-selectors';


export default function InvitesList() {
  const isVerified = useSelector(getIsEmailVerified);
  const hasInvites = useSelector(hasPendingInvites);
  const permissions = useSelector(state => getActiveUsersProgramInvites(state));
  const anyPathwayOrGroupInvites = hasInvites || (permissions && permissions.length > 0);

  return (
    <React.Fragment>
      <InvitesLoader />
      {!isVerified && hasInvites && <Auth0UserInfoRefresher />}
      {!isVerified && hasInvites && <NotVerifiedMessage message={local.notVerifiedForInvites} />}
      {(isVerified && anyPathwayOrGroupInvites) && (
        <React.Fragment>
          <h4>{local.sharing.pendingInvites}</h4>
          <div data-testid="invites-container" className="invites">
            <OrganizationInvitesList />
            <IncomingProfileInviteList />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
