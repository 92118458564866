import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DataLoadingStatus } from 'shared';

class MedicationDataLoadingStatus extends React.Component {
  render() {
    const { loading, error, loaded, children } = this.props;

    return (
      <DataLoadingStatus loading={loading} error={error} loaded={loaded}>
        {children}
      </DataLoadingStatus>
    );
  }
}

MedicationDataLoadingStatus.propTypes = {
  // redux
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

function mapStateToProps(state) {
  return {
    loading: state.medication.loading || state.medicationDose.loading,
    error: state.medication.loadError || state.medicationDose.loadError,
    loaded: state.medication.loaded && state.medication.loaded
  };
}

export default connect(mapStateToProps)(MedicationDataLoadingStatus);
