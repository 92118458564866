import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { Icon, Page } from 'shared';
import Vote from './Vote';
import { getProfileId } from 'app/selectors';

class PreviewPage extends React.Component {
  render() {
    const { backPath, profileId } = this.props;
    const { name, metricName } = this.props.match.params;

    const enabledProfileIDs = ['64c5a106-aa73-4abf-b64a-945367f85716', '90c58242-09c0-4c6c-b1c5-b46ab6b1635c', '91b55ce9-f128-4171-a36b-feb846ea60e5', '5c5ca7df-d959-44c0-ac90-31d1b4a53c90', '10e3c2f0-00b3-42ea-955f-fd6b934dd661'];


    return (
      <Page
        id="placeholder-component"
        centerElement={name}
        className='nested-page-content'
        backPath={{ to: backPath }}>
        <div style={{
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
          textAlign: 'center',
          minHeight: '100%'
        }}>
          <div style={{ marginBottom: '30px' }}>
            <Icon symbol={metricName} fill="blue" size="48px" />
          </div>
          <p>
            {local[`interestMessage${metricName}`] || local.preview[`${metricName}Details`]}
          </p>
          <hr />
          <Vote name={name} metricName={metricName} />
          {metricName && metricName.toLowerCase() == 'reproductive' && enabledProfileIDs.find(element => element == profileId) !== undefined ?
            <NavLink id="more-preferences" className='nav-preferences' to={routePaths.coach}>
              <Icon symbol="preferences" />
              <span>Calcium AI Coach</span>
            </NavLink>
            : null}
        </div>
      </Page>
    );
  }
}
PreviewPage.propTypes = {
  backPath: PropTypes.string,
  // router
  match: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string.isRequired,
      metricName: PropTypes.string.isRequired
    })
  })
};

PreviewPage.defaultProps = {
  backPath: routePaths.root
};

function mapStateToProps(state, props) {
  return {
    profileId: getProfileId(state, props)
  };
}

export default withRouter(connect(mapStateToProps)(PreviewPage));
