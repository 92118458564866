import React from 'react';
import { Route, Switch } from 'react-router';
import { routePaths } from 'services/routing';
import Page404 from 'app/page404/Page404';
import LogMedication from 'health/medication/log/LogMedication';
import EditMedication from 'health/medication/log/EditMedication';
import AddMedicationDose from 'health/medication/dose/log/AddMedicationDose';
import DoseList from 'health/medication/dose/list/DoseList';
import MedicationOverview from 'health/medication/list/MedicationOverview';

export default function MedicationRoutes() {
  return (
    <Switch>
      <Route exact path={routePaths.healthMedicationAddDose} component={AddMedicationDose} />
      <Route exact path={routePaths.healthMedicationDose} component={DoseList} />
      <Route exact path={routePaths.healthMedicationEdit} component={EditMedication} />
      <Route exact path={routePaths.healthMedicationAdd} component={LogMedication} />
      <Route exact path={routePaths.healthMedication} component={MedicationOverview} />
      <Route path={routePaths.healthMedication} component={Page404} />
    </Switch>
  );
}
