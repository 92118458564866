import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CSSTransitionGroup } from 'react-transition-group';
import { removeToast } from './actions';
import Toast from './Toast';
import './toaster.scss';

// Responsible for display of the array of toasts and closing toasts manually or through timeout
class Toaster extends Component {
  constructor(props) {
    super(props);

    this.removeToastById = this.removeToastById.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { toast } = this.props;

    if (toast && toast.timeout) {
      if (prevProps.toast && prevProps.toast.timeout && prevProps.toast.id == toast.id) {
        return; // This toast timeout has already been started
      }

      setTimeout(this.removeToastById(toast.id), toast.timeout);
    }
  }

  removeToastById(toastId) {
    return () => this.props.actions.removeToast(toastId);
  }

  render() {
    const { toast } = this.props;

    var toastComponent = null;
    var clickAction = null;
    if (toast) {
      clickAction = this.removeToastById(toast.id);
      toastComponent = <Toast toast={toast} />;
    }

    return (
      <div id="toaster" onClick={clickAction}>
        <CSSTransitionGroup
          transitionName="toaster"
          transitionEnterTimeout={2000}
          transitionLeaveTimeout={2000}>
          {toastComponent}
        </CSSTransitionGroup>
      </div>
    );
  }
}

Toaster.propTypes = {
  toast: PropTypes.shape(),
  actions: PropTypes.shape({
    removeToast: PropTypes.func.isRequired
  })
};

function mapStateToProps(state) {
  return {
    toast: state.toaster.toastQueue[0]
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    removeToast
  };

  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Toaster);
