import compileReducers from 'services/compile-reducer';
import * as persistentDataReducer from 'app/request/persistent-data-reducer';
import { types } from './action-names';
import * as constant from 'services/constants';

export const initialState = {
  [types.IS_GOAL_BASED_ENROLLMENT]: persistentDataReducer.initialState,
  [types.PROFILE_OBSERVATION_GOALS]: persistentDataReducer.initialState,
  [types.SET_STEP_GOAL]: constant.MIN_STEPS_GOAL_TARGET,
  [types.SET_NUTRITION_GOAL]: {},
  [types.SET_BLOOD_PRESSURE_GOAL]: {},
  [types.SET_BLOOD_GLUCOSE_GOAL]: {},
  [types.SET_BLOOD_PRESSURE_ERROR_FLAG]: true,
  [types.SET_NUTRITION_ERROR_FLAG]: true,
  [types.SET_BLOOD_GLUCOSE_ERROR_FLAG]: true,
  [types.GOALS]: persistentDataReducer.initialState,
  [types.GOAL_CATEGORY]: persistentDataReducer.initialState,
  [types.GOAL_SUMMARY]: persistentDataReducer.initialState,
  [types.PROFILE_GOALS]: {}
};

const reducers = {
  ...persistentDataReducer.reducers(types.GOALS),
  ...persistentDataReducer.reducers(types.GOAL_CATEGORY),
  ...persistentDataReducer.reducers(types.IS_GOAL_BASED_ENROLLMENT),
  ...persistentDataReducer.reducers(types.PROFILE_OBSERVATION_GOALS),
  ...persistentDataReducer.reducers(types.GOAL_SUMMARY)
};

reducers[types.SET_STEP_GOAL] = (state, payload) => ({
  ...state,
  [types.SET_STEP_GOAL]: payload.stepGoal
});

reducers[types.SET_NUTRITION_GOAL] = (state, payload) => ({
  ...state,
  [types.SET_NUTRITION_GOAL]: payload.nutritionGoal
});

reducers[types.SET_BLOOD_PRESSURE_GOAL] = (state, payload) => ({
  ...state,
  [types.SET_BLOOD_PRESSURE_GOAL]: payload.bloodPressureGoal
});

reducers[types.SET_BLOOD_GLUCOSE_GOAL] = (state, payload) => ({
  ...state,
  [types.SET_BLOOD_GLUCOSE_GOAL]: payload.bloodGlucoseGoal
});

reducers[types.SET_BLOOD_PRESSURE_ERROR_FLAG] = (state, payload) => ({
  ...state,
  [types.SET_BLOOD_PRESSURE_ERROR_FLAG]: payload.errorFlag
});

reducers[types.SET_NUTRITION_ERROR_FLAG] = (state, payload) => ({
  ...state,
  [types.SET_NUTRITION_ERROR_FLAG]: payload.errorFlag
});

reducers[types.SET_BLOOD_GLUCOSE_ERROR_FLAG] = (state, payload) => ({
  ...state,
  [types.SET_BLOOD_GLUCOSE_ERROR_FLAG]: payload.errorFlag
});

reducers[types.SELECTED_GOAL_ID] = (state, goalCategoryId) => ({
  ...state,
  goalCategoryId: goalCategoryId
});

reducers[types.CLEAR_GOALS] = (state, profileId) => {
  return {
    ...state,
    [types.GOALS]: {
      [profileId]: {}
    }
  };
};

reducers[types.CLEAR_PROFILE_GOALS] = (state, profileId) => {
  return {
    ...state,
    [types.PROFILE_OBSERVATION_GOALS]: {
      [profileId]: {}
    }
  };
};


export default compileReducers(reducers, initialState);
