import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import local from 'services/localization/local';
import * as selectors from 'programs/programs-selectors';
import ProgramResource from './ProgramResource';

export default function ProgramResources({ programEnrollmentId, profileId, organizationId }) {
  const resources = useSelector(state => selectors.getProgramResources(state, programEnrollmentId), shallowEqual);
  const resourcesRatings = useSelector(state => selectors.getProgramEnrollmentResourcesWithRating(state, programEnrollmentId));
  const resourcesRatingStatus = useSelector(state => selectors.getProgramEnrollmentResourcesRatingStatus(state, programEnrollmentId));
  var rating = {
    programEnrollmentId: programEnrollmentId,
    programResourceId: '',
    id: '',
    isHelpful: null,
    comments: ''
  };
  if (resources.length === 0) {
    return null;
  }
  return (
    <div id="resources">
      <small className="section-title"><strong>{local.programs.detailsResources}</strong></small>
      {resourcesRatingStatus.loaded &&
        <ul className="scrollview-list">
          {resources.map(resource => (
            <li className="scrollview-list-item" key={resource.programResourceId}>
              <ProgramResource resource={resource} programEnrollmentId={programEnrollmentId} organizationId={organizationId} profileId={profileId} isCovidOrganization={resourcesRatings?.isCovidOrganization} resourceRating={resourcesRatings?.resourceRatingList?.find(x => x.programResourceId == resource.programResourceId) ?? rating} />
            </li>
          ))}
        </ul>}
    </div>
  );
}
ProgramResources.propTypes = {
  programEnrollmentId: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired
};

