import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withContext from 'app/withContext';
import local from 'services/localization/local';
import { routePaths, programsContexts } from 'services/routing';
import { Page } from 'shared';
import { setContext } from 'programs/programs-actions';
import * as selectors from 'programs/programs-selectors';
import ProgramDataLoadingStatus from 'programs/ProgramDataLoadingStatus';
import ProgramListItemComplete from './ProgramListItemComplete';
import './program-list.scss';

class ProgramList extends React.Component {
  componentDidMount() {
    const { actions } = this.props;
    actions.setContext(programsContexts.completed);
  }

  renderEmptyCompletedEnrollmentList() {
    return (
      <ul className="card-list">
        <li className="no-programs card-list-item">
          <small>{local.programs.listPlaceholderNoCompletedPrograms}</small>
        </li>
      </ul>
    );
  }

  renderCompletedEnrollmentList() {
    const { completed } = this.props;

    if (!completed || completed.length === 0) {
      return this.renderEmptyCompletedEnrollmentList();
    }

    return (
      <ul className="card-list">
        {completed.map(x => <ProgramListItemComplete key={x.id || 0} enrollment={x} to={routePaths.programsDetails} />)}
      </ul>
    );
  }

  render() {
    return (
      <Page
        id='program-list'
        centerElement={local.programs.completedListHeader}
        backPath={{ to: routePaths.root }}>
        <ProgramDataLoadingStatus>
          {this.renderCompletedEnrollmentList()}
        </ProgramDataLoadingStatus>
      </Page>
    );
  }
}

ProgramList.propTypes = {
  // redux
  completed: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  // actions
  actions: PropTypes.shape({
    setContext: PropTypes.func.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    completed: selectors.getCompletedEnrollments(state)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { setContext };
  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

export default withContext(connect(mapStateToProps, mapDispatchToProps)(ProgramList));
