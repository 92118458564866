
import React, {useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import local from 'services/localization/local';
import { getProfileId } from 'app/selectors';
import { loadGoalProgramInfos } from './goal-actions';
import GoalPathwaysList from './GoalPathwaysList';
import { selectAll, selectOneProgram, setProgram } from './goal-selectors';

export default function GoalPathways(props){
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  var [programs, setPrograms] = useState(null);
  var [selectAllPrograms, setSelectAllPrograms] = useState(false);
  const programIds = [];
  const goalPrograms = props.goalCategory?.goalPrograms;
  const profileGoalPrograms = props.profileGoal?.profileGoalPrograms;
  goalPrograms?.map((x) => {
    programIds.push(x.programId);
  });
  const text = local.organizations.permissions;
  const data = props.programInfos;
  const loaded = props.loaded;

  useEffect(() => {
    dispatch(loadGoalProgramInfos(profileId,programIds));
  }, [profileId]);

  useEffect(() => {
    if (loaded && data && data.length > 0) {
      setProgram(data, goalPrograms, profileGoalPrograms, setPrograms, setSelectAllPrograms, props);
    }
  }, [data,loaded]);

  const selectAllProgram = (value) => {
    selectAll(value, programs, setPrograms, setSelectAllPrograms, props);
  };

  const selectProgram = (id, isSelected) => {
    selectOneProgram(programs, id, isSelected, setPrograms, setSelectAllPrograms, props);
  };

  return(
    programs && programs.length > 0 ?
      <div>
        <div className='formField mb-3'>
          <label>{text.choosePathway}</label>
        </div>
        <div className='form-check '>
          <input className='form-check-input' checked={selectAllPrograms} type="checkbox" id="selectAll" onChange={() => { selectAllProgram(!selectAllPrograms);}} />
          <label className='form-check-label' htmlFor="selectAll">{text.selectAllPrograms}</label>
        </div>
        <ul className="card-list">
          {programs?.sort((a, b) => b.isPrimaryProgram - a.isPrimaryProgram || a.title.localeCompare(b.title))
            .map((program) => (
              <GoalPathwaysList key={program.id} program={program} selectProgram={selectProgram}></GoalPathwaysList>
            ))}
        </ul>
      </div> : null
  );
}
