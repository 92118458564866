import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { loadActiveOrganizations } from 'organizations/organizations-actions';
import { loadOrganizationPrograms, loadProgramEnrollments } from 'programs/programs-actions';
import { getIdentityId } from 'identity/selectors';
import { getOrganizationTypeById } from 'organizations/organizations-selectors';
import * as selectors from 'programs/programs-selectors';
import { Page, DataLoadingStatus, ProfileRedirect } from 'shared';
import RecommendedProgram from './RecommendedProgram';
import local from 'services/localization/local';
import { buildPath, routePaths } from 'services/routing';
import './recommended-program.scss';

export default function RecommendedProgramPage({ match: { params: { organizationId, programId } } }) {
  const organizationProgram = useSelector(state => selectors.getOrganizationProgram(state, organizationId, programId));
  const enrollmentsStatus = useSelector(selectors.getEnrollmentsStatus);
  const { loading, loaded, hasError, error } = useSelector(selectors.getOrganizationProgramsStatus);
  const organization = useSelector(state => getOrganizationTypeById(state, organizationId));
  const activeEnrollment = useSelector(state => selectors.getActiveEnrollmentForProgramInfoId(state, organizationProgram?.program.id));
  const profileId = useSelector(state => state.selectedProfile.id);
  const identityId = useSelector(getIdentityId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded && !loading && !hasError) {
      dispatch(loadOrganizationPrograms(profileId));
    }

    if (!enrollmentsStatus.loaded && !enrollmentsStatus.loading && !enrollmentsStatus.hasError) {
      dispatch(loadProgramEnrollments(profileId));
    }
  }, [profileId]);

  useEffect(() => {
    if (!organization.loaded && !organization.loading && !organization.hasError) {
      dispatch(loadActiveOrganizations());
    }
  }, [identityId]);

  if ((loaded && !organizationProgram) || (organization.loaded && !organization.data)) {
    return <ProfileRedirect to={routePaths.root} />;
  }

  if (activeEnrollment) {
    const { id: programEnrollmentId } = activeEnrollment;
    return <ProfileRedirect to={buildPath(routePaths.programsDetails, { programId, programEnrollmentId })} />;
  }

  return (
    <Page
      id='recommended-program'
      centerElement={local.programs.enrollHeader}
      titleTabText={local.programs.enrollTitleTab}
      backPath={{ to: routePaths.root }}>
      <DataLoadingStatus
        loaded={loaded && organization.loaded}
        loading={loading && organization.loading}
        error={hasError && organization.hasError}
        errorMessage={error || organization.error}>
        <RecommendedProgram organizationProgram={organizationProgram} organization={organization.data} />
      </DataLoadingStatus>
    </Page>
  );
}

RecommendedProgramPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      organizationId: PropTypes.string.isRequired,
      programId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
