import * as actions from './action-names';

export function loadMedications() {
  return {
    type: actions.MEDICATION_REQUESTED
  };
}

export function loadMedicationSuccess(medications) {
  return {
    type: actions.MEDICATION_LOAD_SUCCESS,
    payload: medications
  };
}

export function loadMedicationError() {
  return {
    type: actions.MEDICATION_LOAD_ERROR
  };
}

export function saveMedication() {
  return {
    type: actions.MEDICATION_SAVING
  };
}

export function saveMedicationSuccess(medication) {
  return {
    type: actions.MEDICATION_SAVE_SUCCESS,
    payload: medication
  };
}

export function saveMedicationError() {
  return {
    type: actions.MEDICATION_SAVE_ERROR
  };
}

export function saveMedicationReset() {
  return {
    type: actions.MEDICATION_SAVE_RESET
  };
}

export function deleteMedication(profileId, medicationId) {
  return {
    type: actions.MEDICATION_DELETE,
    payload: {
      profileId,
      medicationId
    }
  };
}
//Medication Scheduler Actions
export function loadMedicationSchedule() {
  return {
    type: actions.MEDICATION_SCHEDULE_REQUESTED
  };
}

export function loadMedicationScheduleSuccess(medicationSchedule) {
  return {
    type: actions.MEDICATION_SCHEDULE_LOAD_SUCCESS,
    payload: medicationSchedule
  };
}

export function loadMedicationScheduleError() {
  return {
    type: actions.MEDICATION_SCHEDULE_LOAD_ERROR
  };
}

