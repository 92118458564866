import { defaultStatus } from 'app/request/request-queue-reducer';

export const getRequestQueue = (state, type) => {
  if (!state[type]) throw `Invalid type in selectors: ${type}`;

  return Object.keys(state[type]).map(key => state[type][key]);
};

export const getRequestById = (state, type, requestId) => {
  if (!state[type]) throw `Invalid type in selectors: ${type}`;

  return state[type][requestId] || defaultStatus;
};

export const anyProcessing = (state, type) =>
  getRequestQueue(state, type).some(x => x.processing);

export const anyError = (state, type) =>
  getRequestQueue(state, type).some(x => x.hasError);
