import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class TimePicker extends React.Component {

  constructor(props)
  {
    super(props);
  }

  isEarlierThanEndLimit(timeValue, endLimit, lastValue) {
    var timeValueIsEarlier = moment(timeValue, 'hh:mm A').diff(moment(endLimit, 'hh:mm A')) < 0;
    var timeValueIsLaterThanLastValue = lastValue === undefined ? true : moment(lastValue, 'hh:mm A').diff(moment(timeValue, 'hh:mm A')) < 0;
    return timeValueIsEarlier && timeValueIsLaterThanLastValue;
  }

  render () {
    var timeValue = this.props.beginLimit || '12:00 AM';
    var lastValue;
    var endLimit = this.props.endLimit || '11:59 PM';
    var step = this.props.step || 15;

    var options = [];

    while ( this.isEarlierThanEndLimit(timeValue, endLimit, lastValue) ) {
      lastValue = timeValue;
      timeValue = moment(timeValue, 'hh:mm A').add(step, 'minutes').format('hh:mm A');
      // eslint-disable-next-line react/jsx-filename-extension
      options.push(<option className='options' key={timeValue} value={timeValue}>{timeValue}</option>);
      //options.push(<li className="with-padding scrollview-list-item" key={timeValue} value={timeValue}>{timeValue}</li>);
    }
    return(
      <select className="selectBtn" defaultValue={this.props.defaultValue} onBlur={this.props.onBlur} name={this.props.name} size={15}>
        {options}
      </select>
      //<ul className="scrollview-list">{options}</ul>
    );
  }
}
TimePicker.propTypes = {
  onBlur: PropTypes.func,
  name:PropTypes.string
};

export default TimePicker;
