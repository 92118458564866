import React from 'react';
import {Icon,LinkWrapper} from 'shared';

export default function RingButton(props) {
  return (
    // eslint-disable-next-line react/prop-types
    <LinkWrapper id="new-dashboard" to={props.url}>
      <div className={props.class  + ' blue-ring'}>
        <div className='inner-wrapper'>
          <Icon symbol={props.icon} />
        </div>
      </div>
    </LinkWrapper>
  );
}
