import React, { useState } from 'react';
import { Button,Page,LoadingOverlay } from 'shared';
import { updateUserPassword } from 'preferences/changeEmail/action-creators';
import { useDispatch ,connect } from 'react-redux';
import { routePaths } from 'services/routing';
import local from 'services/localization/local';
import { Field,reduxForm } from 'redux-form';
import { TextField } from 'shared/form-fields';
import * as validators from 'services/fields/field-validators';
import 'preferences/changeEmail/verified-change-email.scss';
import 'identity/register/register.scss';

function ChangePassword() {
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showSpinner,setShowSpinner] = useState(false);

  const showOrHidePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = () =>{
    if(password)
    {
      setShowSpinner(true);
      dispatch(updateUserPassword(password));
    }
  };

  const icon = <i className={showPassword? 'fa fa-eye fa-eye-slash eye-slash-icon' : 'fa fa-fw fa-eye eye-icon'} onClick={showOrHidePassword}></i>;

  return (
    <Page
      id="preferences-selection-component" data-testid="changeEmail"
      centerElement="Password Setting"
      backPath={{ to: routePaths.preferences }}>
      {showSpinner ? <LoadingOverlay /> : null}
      <br />
      <form>
        <div className="form-row">
          <Field
            data-testid='new-password'
            label="Please enter the new password"
            autoFocus
            name="initialEmail"
            type={showPassword ? 'text' : 'password'}
            component={TextField}
            onChange={e => setPassword(e.target.value)}
            validate={[validators.required, validators.minLength8]}
            children={icon} />
        </div>
        <small className="infoText">You will be logged out of the app after you update the password. Please use the new password to login again.</small>
        <br />
        <div className="accountUpdateBtn">
          <Button
            id="change-email"
            className="btn btn-primary"
            onClick={handleSubmit}
            color="blue">
            {local.accountSettings.verifyEmail}
          </Button>
        </div>
      </form>
    </Page>
  );
}

const form = reduxForm({
  form: 'change-password',
  enableReinitialize: true
})(ChangePassword);

function mapStateToProps(state) {
  return {
    initialValues:{
      initialEmail: state.preferences.newEmail
    }
  };
}

export default connect(mapStateToProps, null)(form);
