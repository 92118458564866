import * as persistentDataActions from 'app/request/persistent-data-actions';
import * as requestQueueActions from 'app/request/request-queue-actions';
import * as requestActions from 'app/request/request-actions';
import * as interviewActions from 'interviews/interview-actions';
import * as toastActions from 'app/toaster/actions';
import * as healthDataApi from 'services/api/healthdata-api';
import local from 'services/localization/local';
import * as selectors from './programs-selectors';
import { types } from './programs-types';
import { ProgramCompletionCode } from 'services/enums';
import { updateShowBadgeAchievedScreen } from 'badge/action-creators';
import { loadVaccinationProof } from 'health/action-creators';
import { getUniqueId } from 'services/helpers';
import { isMobile, openRouteInApp } from 'mobile/helpers';

export const PROGRAMS_SET_CONTEXT = 'PROGRAMS_SET_CONTEXT';
export const PROGRAMS_RESET_LOAD_COUNT = 'PROGRAMS_RESET_LOAD_COUNT';
export const PROGRAMS_ADD_ENROLLMENT = 'PROGRAMS_ADD_ENROLLMENT';
export const PROGRAMS_UPDATE_ENROLLMENT = 'PROGRAMS_UPDATE_ENROLLMENT';
export const PROGRAMS_UPDATE_TASK = 'PROGRAMS_UPDATE_TASK';
export const CURRENT_ENROLLMENT_TASK_DETAILS ='CURRENT_ENROLLMENT_TASK_DETAILS';
export const COMPLETE_AND_REENROLLMENT_LOADING ='COMPLETE_AND_REENROLLMENT_LOADING';
export const COMPLETE_AND_REENROLLMENT_LOADED ='COMPLETE_AND_REENROLLMENT_LOADED';
export const PROGRAM_DETAILS_LOADED ='PROGRAM_DETAILS_LOADED';


export function setContext(context) {
  return {
    type: PROGRAMS_SET_CONTEXT,
    payload: {
      context
    }
  };
}

export function resetDetailsLoadCount(reset) {
  return {
    type: PROGRAMS_RESET_LOAD_COUNT,
    payload: {
      reset
    }
  };
}

export function addEnrollment(profileId, enrollment) {
  return {
    type: PROGRAMS_ADD_ENROLLMENT,
    payload: {
      profileId,
      enrollment
    }
  };
}

export function updateEnrollment(profileId, programEnrollmentId, enrollment) {
  return {
    type: PROGRAMS_UPDATE_ENROLLMENT,
    payload: {
      profileId,
      programEnrollmentId,
      enrollment
    }
  };
}

export function updateTask(programEnrollmentTaskId, programEnrollmentId, task) {
  return {
    type: PROGRAMS_UPDATE_TASK,
    payload: {
      programEnrollmentTaskId,
      programEnrollmentId,
      task
    }
  };
}

export function currentEnrollmentTaskDetails(programEnrollmentTaskId, programEnrollmentId,programId, task)
{
  return {
    type: CURRENT_ENROLLMENT_TASK_DETAILS,
    payload: {
      programEnrollmentTaskId,
      programEnrollmentId,
      programId,
      task
    }
  };
}

export function removeCurrentEnrollmentTaskDetails(){
  return {
    type: CURRENT_ENROLLMENT_TASK_DETAILS,
    payload: {
    }
  };
}

export function CompleteReEnrollmentLoading() {
  return {
    type: COMPLETE_AND_REENROLLMENT_LOADING
  };
}

export function CompleteReEnrollmentLoaded() {
  return {
    type: COMPLETE_AND_REENROLLMENT_LOADED
  };
}

export function loadProgram(programId) {
  const action = state => healthDataApi.getProgram(programId, state);
  return persistentDataActions.request(action, types.PROGRAM, programId);
}

export function loadPrograms(profileId) {
  const action = state => healthDataApi.getPrograms(state);
  return persistentDataActions.request(action, types.INFO, profileId);
}

export function loadPendingInvitedPrograms(profileId) {
  const action = state => healthDataApi.getPendingInvitedPrograms(state);
  return persistentDataActions.request(action, types.PENDING_INVITED_PROGRAMS, profileId);
}

export function loadProgramEnrollments(profileId) {
  const action = state => healthDataApi.getProgramEnrollments(profileId, state);
  return persistentDataActions.request(action, types.ENROLLMENTS, profileId);
}

export function loadProgramDetails(programEnrollmentId) {
  const action = state => healthDataApi.getProgramDetails(programEnrollmentId, state.selectedProfile.id, state);
  return persistentDataActions.request(action, types.DETAILS, programEnrollmentId);
}

export function loadUpgradedObservations(programId) {
  const action = state => healthDataApi.getUpgradedObservations(state.selectedProfile.id, programId, state);
  return persistentDataActions.request(action, types.GET_UPGRADED_OBSERVATIONS, programId);
}

export function loadProgramPermissions(programId, organizationMemberId) {
  const identifier = organizationMemberId || programId;
  const action = state => healthDataApi.getProgramPermissions(programId, organizationMemberId, state);
  return persistentDataActions.request(action, types.PERMISSIONS, identifier);
}

export function loadActiveUsersProgramInvites(identityId) {
  const action = state => healthDataApi.getActiveUsersProgramInvites(state);
  return persistentDataActions.request(action, types.ACTIVE_USERS_PROGRAM_INVITES, identityId);
}

export function loadPendingProgramInvitesCount(identityId) {
  const action = state => healthDataApi.getPendingProgramInvitesCount(state);
  return persistentDataActions.request(action, types.PENDING_PROGRAM_INVITES_SHOWN_COUNT, identityId);
}

export function completeAndReEnrollInProgram(profileId, completedEnrollmentId, optInOrganizationIds, requestId, upgrade, reenrollMessage=false) {
  return (dispatch) => {

    dispatch(CompleteReEnrollmentLoading());
    const completionCode = upgrade ? ProgramCompletionCode.Upgraded : ProgramCompletionCode.Complete;
    const completeAction = state => healthDataApi.postProgramCompleteAndReEnroll(profileId, completedEnrollmentId, optInOrganizationIds, completionCode, state)
      .then(({ newEnrollment }) => {
        dispatch(loadProgramDetails(newEnrollment.id));
        dispatch(updateEnrollment(profileId, completedEnrollmentId, newEnrollment));
        const timeoutId = setTimeout(() => dispatch(CompleteReEnrollmentLoaded(),15000));
        clearTimeout(timeoutId);
      })
      .catch(error => {
        const timeoutId = setTimeout(() => dispatch(CompleteReEnrollmentLoaded(),15000));
        clearTimeout(timeoutId);
        if (error.status == 422) {
          // FUTURE: Handle unprocessable state, user needs to be asked to opt-in to organization participation.
          throw error;
        } else if (error.status === 400) {
          dispatch(loadProgramDetails(completedEnrollmentId));
        }

        throw error;
      });

    dispatch(requestQueueActions.queueRequestWithToast(completeAction, types.COMPLETE_AND_REENROLLMENT, requestId, {
      processingMessage: reenrollMessage ? local.programs.toastMessagereenrolling : local.programs.toastMessageEnrolling,
      successMessage: reenrollMessage ? local.programs.toastMessageReenrolled : local.programs.toastMessageEnrolled
    }, { profileId }));
  };
}

export function enrollInProgram({ profileId, programInfoId, organizationId }, requestId) {
  return (dispatch) => {
    const action = state => healthDataApi.postProgramEnroll(programInfoId, profileId, organizationId, state)
      .then(enrollment => {
        dispatch(addEnrollment(profileId, enrollment));
        dispatch(loadProgramDetails(enrollment.id));
      });

    dispatch(requestQueueActions.queueRequestWithToast(action, types.ENROLL, requestId, {
      processingMessage: local.programs.toastMessageEnrolling,
      successMessage: local.programs.toastMessageEnrolled
    }, { profileId, programInfoId }));
  };
}
export function enrollInImmunization({ profileId, programInfoId, organizationId }, requestId) {
  return (dispatch) => {
    const action = state => healthDataApi.postProgramEnroll(programInfoId, profileId, organizationId, state)
      .then(enrollment => {
        dispatch(addEnrollment(profileId, enrollment));
        dispatch(loadProgramDetails(enrollment.id));
      });

    dispatch(requestQueueActions.queueRequest(action, types.ENROLL, requestId, { profileId, programInfoId }));
  };
}


export function leaveProgram(programEnrollmentId, profileId, requestId) {
  return completeProgram(programEnrollmentId, profileId, requestId, ProgramCompletionCode.Unenrolled, {
    processingMessage: local.programs.toastMessageLeaving,
    successMessage: local.programs.toastMessageLeft
  });
}

export function completeProgram(programEnrollmentId, profileId, requestId, completionCode = ProgramCompletionCode.Complete, toastMessages = {
  processingMessage: local.programs.toastMessageCompleting,
  successMessage: local.programs.toastMessageComplete
}) {
  return (dispatch) => {
    const action = state => healthDataApi.postProgramComplete(programEnrollmentId, profileId, completionCode, state)
      .then(enrollment => {
        dispatch(updateEnrollment(profileId, programEnrollmentId, enrollment));
        dispatch(loadProgramDetails(programEnrollmentId));
      });

      console.log(requestId);

    const requestOpts = {
      showToasts: true,
      processingMessage: toastMessages.processingMessage,
      successMessage: toastMessages.successMessage,
      requireHipaaVerification: true
    };
    dispatch(requestActions.request(action, requestId, { profileId }, requestOpts));
  };
}

export function uncompleteTask(programEnrollmentTaskId, programEnrollmentId, profileId) {
  return completeTask(programEnrollmentTaskId, programEnrollmentId, profileId, false);
}

export function completeTask(programEnrollmentTaskId, programEnrollmentId, profileId, complete = true) {
  const action = state => healthDataApi.postCompleteTask(programEnrollmentTaskId, profileId, complete, state);
  return baseCompleteTask(action, programEnrollmentTaskId, programEnrollmentId, profileId);
}

export function completeInterviewTask(programEnrollmentTaskId, programEnrollmentId, interviewId, answers, profileId, loadVaccination) {
  return (dispatch) => {
    dispatch(interviewActions.saveInterview());
    var results = Object.keys(answers).map(key => ({ id: key, ...answers[key] })); // Convert to array
    const action = state => healthDataApi.postCompleteInterviewTask(programEnrollmentTaskId, interviewId, results, profileId, state)
      .then(() => {
        dispatch(interviewActions.closeInterview(interviewId));
        dispatch(resetDetailsLoadCount(true));
        dispatch(updateShowBadgeAchievedScreen(false));
        dispatch(updateShowBadgeAchievedScreen(true));
        if (loadVaccination) {
          dispatch(loadVaccinationProof(profileId));
        }
      })
      .catch(() => {
        dispatch(interviewActions.interviewError(interviewId));
      });

    dispatch(baseCompleteTask(action, programEnrollmentTaskId, programEnrollmentId, profileId));
  };
}

export function saveInterviewTaskDraftAnswers(programEnrollmentTaskId, interviewId, answers, profileId) {
  return (dispatch, getState) => {
    var state = getState();
    healthDataApi.saveInterviewTaskDraftAnswers(programEnrollmentTaskId, interviewId, answers, profileId, state);
  };
}

export function openInAppUrl(inAppUrl) {
  if (isMobile) {
    openRouteInApp(inAppUrl);
  }
  else {
    const newWindow = window.open(inAppUrl, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null;
  }
}

export function baseCompleteTask(action, programEnrollmentTaskId, programEnrollmentId, profileId) {
  return (dispatch) => {
    const fullAction = state => action(state)
      .then(task => {
        dispatch(updateTask(programEnrollmentTaskId, programEnrollmentId, task));
        dispatch(loadProgramDetails(programEnrollmentId));
      }).catch(error => {
        dispatch(toastActions.popErrorToast());
        throw error;
      });

    dispatch(requestQueueActions.queueRequest(fullAction, types.COMPLETETASK, programEnrollmentTaskId, { programEnrollmentId, profileId }));
  };
}

export function completeTaskBase(action, programEnrollmentTaskId, programEnrollmentId, profileId) {
  return (dispatch) => {
    const fullAction = action
      .then(task => {
        dispatch(updateTask(programEnrollmentTaskId, programEnrollmentId, task));
        dispatch(loadProgramDetails(programEnrollmentId));
      }).catch(error => {
        dispatch(toastActions.popErrorToast());
        throw error;
      });

    dispatch(requestQueueActions.queueRequest(fullAction, types.COMPLETETASK, programEnrollmentTaskId, { programEnrollmentId, profileId }));
  };
}

export function checkResource(resourceId, programEnrollmentId) {
  return (dispatch, getState) => {
    const state = getState();
    const tasks = selectors.getAvailableProgramTasksByResourceId(state, programEnrollmentId, resourceId);

    tasks.forEach(task => {
      if (!task.isConditionallyCompletable) {
        dispatch(completeTask(task.programEnrollmentTaskId, programEnrollmentId, state.selectedProfile.id));
      }
    });
  };
}

export function loadReferrals(profileId, programEnrollmentId) {
  const action = state => healthDataApi.getEnrollmentReferrals(profileId, state, programEnrollmentId);
  return persistentDataActions.request(action, types.REFERRALS, programEnrollmentId);
}

export function loadOrganizationPrograms(profileId) {
  const action = state => healthDataApi.getOrganizationProgramsForProfile(profileId, undefined, state);
  return persistentDataActions.request(action, types.ORGANIZATION_PROGRAMS, profileId);
}

export function loadEnrollmentMetricSeverity(profileId, programEnrollmentId) {
  const action = state => healthDataApi.getSeverityLevel(state, programEnrollmentId, profileId);
  return persistentDataActions.request(action, types.SEVERITY, programEnrollmentId);
}

export function loadSuggestedPathways(profileId) {
  const action = state => healthDataApi.getSuggestedPathways(state);
  return persistentDataActions.request(action, types.SUGGESTED_PATHWAY, profileId);
}

export function loadLeaderBoardSummary(profileId, programId, searchDate = null) {
  const action = state => healthDataApi.getLeaderBoardSummary(state, profileId, programId, searchDate);
  return persistentDataActions.request(action, types.LEADER_BOARD_SUMMARY, profileId);
}

export function loadHasEnrolledInActiveEventProgram(profileId, programId) {
  const action = state => healthDataApi.getHasEnrolledInActiveEventProgram(state, profileId, programId);
  return persistentDataActions.request(action, types.HAS_ENROLLED_IN_ACTIVE_EVENT_PROGRAM, profileId + programId);
}
export function loadResourceRatingDetails(Id,profileId,organizationId) {
  const action = state => healthDataApi.getResourceRatingDetails(state, Id, profileId,organizationId);
  return persistentDataActions.request(action, types.RESOURCE_CONTENT_RATING, Id);
}
export function createResourceRating(resourceRatingModel,profileId) {
  const action = state => healthDataApi.createResourceRating(state, resourceRatingModel,profileId);
  return persistentDataActions.request(action, types.RESOURCE_CONTENT_RATING, resourceRatingModel.programEnrollmentId);
}
export function updateResourceRating(resourceRatingModel,profileId) {
  const action = state => healthDataApi.updateResourceRating(state, resourceRatingModel,profileId);
  return persistentDataActions.request(action, types.RESOURCE_CONTENT_RATING, resourceRatingModel.programEnrollmentId);
}
export function updateProgramsLastDismissedOn(identityId) {
  return (dispatch,getState) => {
    const onSuccess = () => {
      dispatch(loadPendingProgramInvitesCount(identityId));
    };
    return healthDataApi.updateInviteLastDismissedOn(getState()).then(onSuccess,onSuccess).catch(onSuccess);

  };
}

export function loadDashboardPrograms(profileId) {
  const requestId = getUniqueId();

  return (dispatch, getState) => {
    const state = getState();
    dispatch(persistentDataActions.requestInitiated(types.ENROLLMENTS, profileId, requestId));

    const onSuccess = result => {
      const enrollments = result.map(item => item.programEnrollment);
      if(enrollments){
        const details = result.map(item => item.programDetails);
        if(details){
          const programDetails = {};
          details.forEach(data => {
            programDetails[data.programEnrollmentId] = {
              requestId: getUniqueId(),
              loading: false,
              hasError: false,
              data,
              loaded: true
            };
          });
          dispatch(updateProgramDetails(programDetails));
        }
      }
      dispatch(persistentDataActions.requestSuccess(types.ENROLLMENTS, profileId, requestId, enrollments));
    };

    const onError = error => {
      dispatch(persistentDataActions.requestError(types.ENROLLMENTS, profileId, requestId, error));
    };

    return healthDataApi.getDashboardPrograms(state).then(onSuccess).catch(onError);
  };
}

export function updateProgramDetails(programDetails)
{
  return {
    type: PROGRAM_DETAILS_LOADED,
    payload: {
      programDetails
    }
  };
}
