import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalWrapper, Button, Icon } from 'shared';
import { ErrorCode } from 'services/enums';
import local from 'services/localization/local';
import { acceptHipaaConsent } from 'profiles/action-creators';
import { getProfileNameById } from 'profiles/list/selectors';
import { hipaaConsentCleared } from 'health/actions';
import HipaaAuthorization from './HipaaAuthorization';
import './hipaa.scss';

class HipaaConsentModal extends Component {
  constructor(props) {
    super(props);

    this.onAccept = this.onAccept.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onAccept() {
    const { requireHipaaConsentProfileId, requireHipaaConsentRetry } = this.props;

    this.props.actions.acceptHipaaConsent(requireHipaaConsentProfileId, requireHipaaConsentRetry);
  }

  onCancel() {
    this.props.actions.hipaaConsentCleared();
  }

  render() {
    const { requireHipaaConsent, requireHipaaConsentType, requireHipaaConsentProfileName } = this.props;

    let name = <i>{requireHipaaConsentProfileName}</i>;
    let contextMessage = null;
    let showIntro = false;
    switch (requireHipaaConsentType) {
      case ErrorCode.HipaaConsentExpired:
        showIntro = true;
        contextMessage = local.formatString(local.hipaaReconsentIntroP1, name);
        break;
      case ErrorCode.HipaaConsentVersionOutdated:
        showIntro = true;
        contextMessage = local.formatString(local.hipaaConsentNewVersionIntroP1, name);
        break;
      default:
        break;
    }

    const intro = (
      <div className="intro">
        <small><strong>{local.hipaaConsentIntroGreeting}</strong></small>
        <br /> <br />
        <small><strong>{contextMessage}</strong></small>
        <br /> <br />
        <small>{local.hipaaConsentIntroP2}</small>
      </div>
    );

    const consent = (
      <div className="consent-section">
        <p><strong>{local.hipaaConsentP1}</strong></p>
        <small>{local.hipaaConsentP2}</small>
      </div>
    );

    const stickyOverlay = (
      <div className="sticky-section text-center">
        <Button id="hipaa-accept-button" color="transparent" onClick={this.onAccept}>{local.agree}</Button>
      </div>
    );

    // Always display over others.
    const overlayOverride = {
      overlay: {
        zIndex: 3000
      }
    };

    return (
      <ModalWrapper isOpen={requireHipaaConsent} style={overlayOverride}>
        <div id="hipaa-authorization-modal" className="modal-container">
          <div className="modal-header">
            <Button className="left" id="hipaa-cancel-button" onClick={this.onCancel}><Icon symbol="times" /></Button>
            <Icon className="center" symbol="hipaaauth" />
            <div></div>
          </div>
          <div className="modal-body">
            <div className="scrollable">
              {showIntro && intro}
              <HipaaAuthorization />
              {consent}
            </div>
            {stickyOverlay}

          </div>
        </div>
      </ModalWrapper>
    );
  }
}

HipaaConsentModal.propTypes = {
  requireHipaaConsent: PropTypes.bool.isRequired,
  requireHipaaConsentType: PropTypes.number,
  requireHipaaConsentProfileId: PropTypes.string,
  requireHipaaConsentProfileName: PropTypes.string,
  requireHipaaConsentRetry: PropTypes.func,
  actions: PropTypes.shape({
    acceptHipaaConsent: PropTypes.func.isRequired,
    hipaaConsentCleared: PropTypes.func.isRequired
  }).isRequired
};
HipaaConsentModal.defaultProps = {
  requireHipaaConsentProfileName: ''
};

function mapStateToProps(state) {
  return {
    requireHipaaConsent: state.health.requireHipaaConsent,
    requireHipaaConsentType: state.health.requireHipaaConsentType,
    requireHipaaConsentProfileId: state.health.requireHipaaConsentProfileId,
    requireHipaaConsentRetry: state.health.requireHipaaConsentRetry,
    requireHipaaConsentProfileName: getProfileNameById(state, state.health.requireHipaaConsentProfileId)
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    acceptHipaaConsent,
    hipaaConsentCleared
  };

  const actionMap = { actions: bindActionCreators(actions, dispatch) };

  return actionMap;
}

export default connect(mapStateToProps, mapDispatchToProps)(HipaaConsentModal);
