import React from 'react';
import { buildDurationDisplayText } from 'services/date-service';
import { intensityDescriptions } from 'activity/helpers';
import { ProfileLink } from 'shared';
import { routePaths } from 'services/routing';
import TimelineIconDate from './TimelineIconDate';
import { Activities } from 'shared/fullColorIcons';

export default function TimelineActivity({ item }) {
  const durationText = buildDurationDisplayText(item.data.startDateTime, item.data.endDateTime);
  const intensityDescription = intensityDescriptions[item.data.intensity] ? intensityDescriptions[item.data.intensity].label : '';

  return (
    <ProfileLink
      to={routePaths.healthActivity}
      id={`timeline-item-link-${item.data.id}`}
      logName="timeline-activity">
      <TimelineIconDate item={item} Symbol={Activities} />
      {item.data.name && <strong><p className="timeline-item-header">{item.data.name}</p></strong>}
      {item.data.notes && <p className="timeline-item-notes">{item.data.notes}</p>}
      <div className="timeline-activity-info">
        <div className="timeline-activity-info-section">
          <span className="timeline-item-subheader">Duration</span>
          <br />
          <span className="timeline-item-value">{durationText}</span>
        </div>
        <div className="timeline-activity-info-section">
          <span className="timeline-item-subheader">Intensity</span>
          <br />
          <span className="timeline-item-value">{intensityDescription}</span>
        </div>
      </div>
    </ProfileLink>
  );
}
