import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as selectors from 'programs/programs-selectors';
import local from 'services/localization/local';
import FeaturedProgramTile from 'programs/enrollment/FeaturedProgramTile';
import './program-referrals.scss';

export default function ProgramReferrals({ enrollmentId, disabled }) {
  const loadedReferrals = useSelector(state => selectors.getEnrollmentReferrals(state, enrollmentId));
  const availableReferrals = useSelector(state => loadedReferrals.filter(referral => !selectors.getActiveEnrollmentForProgram(state, referral.referredProgramId)));
  const referrals = useRef(availableReferrals);

  // We want to prevent referrals from vanishing while this component is rendered.
  const hasReferrals = loadedReferrals && loadedReferrals.length > 0;

  if (!hasReferrals || disabled || referrals.current.length === 0) {
    return null;
  }

  return (
    <section id="program-referrals-container">
      <small><strong>{local.programs.referralListTitle}</strong></small>
      <ul className="card-list referrals">
        {referrals.current.map(x => (
          <li className="card-list-item" key={x.programInfo.id}>
            <FeaturedProgramTile program={x.programInfo} allowRedirect />
          </li>
        ))}
      </ul>
    </section>
  );
}

ProgramReferrals.propTypes = {
  enrollmentId: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};
