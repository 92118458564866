import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { NavIcon, ProfileLink} from 'shared';
import { useSelector } from 'react-redux';
import { getUnreadMedicalRecordsCount } from 'health/health-selectors';
import { getProfileId } from 'app/selectors';
import { buildPath, routePaths } from 'services/routing';
import { getIdentityVerificationStatus, getIdentityVerifiedStatus } from 'health/connect/connect-selectors';
import { FhirResourceTypes } from 'services/enums';
import ProgramSubsriptionLink from 'programs/list/ProgramSubsriptionLink';

const upgradeContent = 'Upgrade your subscription to access your electronic health records from your hospitals and healthcare providers!';

export default function HealthNavLink({ icon, linkContent, linkId, routePath, params = {}, fhirResourceType, observationType, skipVerification, isPremium}) {
  const profileId = useSelector(getProfileId);
  const [showModal, setShowModal] = useState(false);
  const unreadMedicalRecordsCount = useSelector(state => getUnreadMedicalRecordsCount(state, profileId, fhirResourceType, observationType));
  const identityVerificationStatus = useSelector(state => getIdentityVerificationStatus(state, profileId));
  const verified = useSelector(state => getIdentityVerifiedStatus(state, profileId));
  const linkParams =  !observationType &&!skipVerification && !verified?.isIdentityVerified && identityVerificationStatus?.isIdentityVerificationRequired ?
    { to: routePaths.healthIdentity, backpath: buildPath(routePath, { profileId, ...params })  } : {to: routePath, params };

  function openSubscription() {
    setShowModal(!showModal);
  }

  return (
    <div>
      {isPremium ?
        <a
          className="with-chevron with-padding"
          id={`${linkId}-link`}
          style={{color:'inherit'}}
          onClick={openSubscription}
          {...linkParams}>
          <NavIcon Icon={icon} />
          <span className='d-flex justify-content-between'>
            <strong>{linkContent}</strong>
            {(!observationType || fhirResourceType == FhirResourceTypes.DiagnosticReport)  && unreadMedicalRecordsCount && unreadMedicalRecordsCount > 0 ? (
              <figure>{unreadMedicalRecordsCount}</figure>
            ) : null}
          </span>
        </a> :
        <ProfileLink
          className="with-chevron with-padding"
          style={{color:'inherit'}}
          id={`${linkId}-link`}
          {...linkParams}>
          <NavIcon Icon={icon} />
          <span className='d-flex justify-content-between'>
            <strong>{linkContent}</strong>
            {(!observationType || fhirResourceType == FhirResourceTypes.DiagnosticReport)  && unreadMedicalRecordsCount && unreadMedicalRecordsCount > 0 ? (
              <figure>{unreadMedicalRecordsCount}</figure>
            ) : null}
          </span>
        </ProfileLink>}
      {showModal ? <ProgramSubsriptionLink isConfirmOpenModel={showModal} closeModel={openSubscription} content={upgradeContent}></ProgramSubsriptionLink> : null}
    </div>

  );
}

HealthNavLink.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  linkContent: PropTypes.node.isRequired,
  linkId: PropTypes.string.isRequired,
  routePath: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.object,
  healthDataType: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)])
};
