import React from 'react';
import local from 'services/localization/local';
import VerifyEmailLink from 'identity/verify-email/VerifyEmailLink';
import PropTypes from 'prop-types';
import './not-verified-message.scss';

export default function NotVerifiedMessage({ message }) {

  return (
    <div className='verify-email-message'>
      <h5>{local.verifyEmailHeader}</h5>
      <small>{message}</small>
      <div className='verify-email-action'><VerifyEmailLink /></div>
    </div>
  );
}

NotVerifiedMessage.propTypes = {
  message: PropTypes.string.isRequired
};
