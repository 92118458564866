import { HIPAA_CONSENT_ERROR_CODE } from 'services/api/api';
import { getUniqueId } from 'services/helpers';
import { requireHipaaConsent } from 'health/actions';
import * as toastActions from 'app/toaster/actions';
import * as api from 'services/api/healthCareProfile-api';
import * as actions from './actions';

export function loadHealthInfo(profileId) {
  return (dispatch, getState) => {
    dispatch(actions.loadMedications());

    const onSuccess = response => {
      dispatch(actions.loadMedicationSuccess(response));
    };

    const onError = () => {
      dispatch(actions.loadMedicationError());
    };
    return api.getHealthInfo(profileId, getState())
      .then(onSuccess).catch(onError);
  };
}

export function saveHealthInfoInsurance(input) {
  const { id, profileId, insurerName, primaryInsurerName, insuranceId, insurerGroupId, careType, expirationDate, phoneNumber, notes } = input;
  const medication = {
    id,
    profileId,
    insurerName,
    primaryInsurerName,
    insuranceId,
    insurerGroupId,
    careType,
    expirationDate,
    phoneNumber,
    notes
  };

  return (dispatch, getState) => {
    const state = getState();
    console.log(state);
    const requestId = getUniqueId();
    dispatch(actions.saveHealthInfoInsurance());
    dispatch(toastActions.addProcessingToast(requestId));

    const onSuccess = () => {
      dispatch(actions.saveHealthInfoInsuranceSuccess(medication));
      dispatch(toastActions.completeToastWithSuccess(requestId));
    };

    const onError = error => {
      if (error && error.status === HIPAA_CONSENT_ERROR_CODE) {
        const retryAction = () => saveHealthInfoInsurance(input);
        dispatch(toastActions.removeToast(requestId));
        dispatch(requireHipaaConsent(profileId, error.body.code, retryAction));
      } else {
        dispatch(toastActions.completeToastWithError(requestId));
      }

      dispatch(actions.saveHealthInfoInsuranceError(requestId));
    };

    if (medication.id) {
      return api.postHealthInfoInsurance(medication, state)
        .then(onSuccess).catch(onError);
    }

    return api.postHealthInfoInsurance(medication, state)
      .then(onSuccess).catch(onError);
  };
}
export function saveHealthInfoProvider(input) {
  const { id, profileId, primaryPhysicianName, providerOrganizationName, providerAddress, providerEmail, phoneNumber, notes } = input;
  const medication = {
    id,
    profileId,
    primaryPhysicianName,
    providerOrganizationName,
    providerAddress,
    providerEmail,
    phoneNumber,
    notes
  };

  return (dispatch, getState) => {
    const state = getState();
    console.log(state);
    const requestId = getUniqueId();
    dispatch(actions.saveHealthInfoInsurance());
    dispatch(toastActions.addProcessingToast(requestId));

    const onSuccess = () => {
      dispatch(actions.saveHealthInfoInsuranceSuccess(medication));
      dispatch(toastActions.completeToastWithSuccess(requestId));
    };

    const onError = error => {
      if (error && error.status === HIPAA_CONSENT_ERROR_CODE) {
        const retryAction = () => saveHealthInfoInsurance(input);
        dispatch(toastActions.removeToast(requestId));
        dispatch(requireHipaaConsent(profileId, error.body.code, retryAction));
      } else {
        dispatch(toastActions.completeToastWithError(requestId));
      }

      dispatch(actions.saveHealthInfoInsuranceError(requestId));
    };

    if (medication.id) {
      return api.postHealthInfoProvider(medication, state)
        .then(onSuccess).catch(onError);
    }

    return api.postHealthInfoProvider(medication, state)
      .then(onSuccess).catch(onError);
  };
}

