import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Page, ProfileRedirect, FormattedText } from 'shared';
import { getProfile } from 'profiles/list/selectors';
import { getUniqueId, setLocalStorage } from 'services/helpers';
import { routePaths, buildPath, programsContexts } from 'services/routing';
import { InvitedProgramId, ProgramInviteAction, ProgramTabName, ProgramTabStoreName } from 'services/enums';
import { getOrganizationInvites, getInviteResponseRequest } from 'organizations/organizations-selectors';
import { loadProgramEnrollments, loadProgramPermissions, setContext } from 'programs/programs-actions';
import { getSortedPrograms, getProgramPermissions, getActiveUsersProgramInvites, getActiveEnrollmentForProgram, getEnrollmentsStatus } from 'programs/programs-selectors';
import { acceptOrganizationInvite, declineOrganizationInvite } from 'organizations/organizations-actions';
import local from 'services/localization/local';
import PropTypes from 'prop-types';
import './program-invite.scss';
import { getConfigForObservationType } from 'health/observations/observation-configs';
import { ObservationTypesForConnection } from 'services/constants';

export default function ProgramInviteDetails({ match: { params: { programId } } }) {
  const dispatch = useDispatch();
  const [requestId] = useState(getUniqueId());
  const [isAccepted, setIsAccepted] = useState(false);
  const [buttonAction, setButtonAction] = useState('');
  var [programs, setPrograms] = useState([]);
  const currentProfile = useSelector(getProfile);
  const { invitedPrograms } = useSelector(state => getSortedPrograms(state));
  const { success } = useSelector(state => getInviteResponseRequest(state, requestId));
  const organization = useSelector(state => getOrganizationInvites(state));
  const organizationInfo = useSelector(state => getActiveUsersProgramInvites(state));
  const activeEnrollment = useSelector(state => getActiveEnrollmentForProgram(state, programId));
  const { loading, loaded } = useSelector(getEnrollmentsStatus);
  const [retriesSeconds, setRetriesSeconds] = useState(1);
  const program = invitedPrograms.filter(x => x.programId === programId);
  const programInfo = program && program.length > 0 ? program[0] : null;
  const data = organization && organization.data && organization.data.filter(x => x.organizationMembershipId == programInfo?.organizationMemberId);
  const permission = organizationInfo && organizationInfo.length > 0 && organizationInfo.filter(x => x.memberId == programInfo?.organizationMemberId);
  const organizationId = data && data.length > 0 ? data[0]?.organizationId : permission && permission.length > 0 ? permission[0]?.organizationId : null;
  const organizationMembershipId = data && data.length > 0 ? data[0]?.organizationMembershipId : permission && permission.length > 0 ? permission[0]?.memberId : null;
  const orgName = data && data.length > 0 ? data[0]?.organizationName : permission && permission.length > 0 ? permission[0]?.organizationName : null;

  const permissionStatus = useSelector(state => getProgramPermissions(state, organizationMembershipId));
  useEffect(() => {
    setLocalStorage(ProgramTabStoreName, ProgramTabName.Invited);
    if (organizationMembershipId && programId) {
      dispatch(loadProgramPermissions(programId, organizationMembershipId));
    }
  }, [organizationMembershipId, programId]);

  const getObservation = (permissionData) =>{
    const allObservationsForPermissions = permissionData.details.map(detail => getConfigForObservationType(detail.observationType)?.centerElement);
    var effectiveObservations = Object.keys(allObservationsForPermissions.reduce((accum, observation) => {
      accum[observation] = true;
      return accum;
    }, []));
    effectiveObservations.sort();
    effectiveObservations.unshift(local.programs.questionResponses);
    return effectiveObservations;
  };

  useEffect(() => {
    if(buttonAction == ProgramInviteAction.Accept && !loading && loaded && !activeEnrollment) {
      setRetriesSeconds(retriesSeconds * 2);
      const timeoutId = setTimeout(() => {
        dispatch(loadProgramEnrollments(currentProfile.id));
      }, retriesSeconds * 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [loaded, loading, buttonAction, activeEnrollment]);
  useEffect(() =>{
    if (organizationMembershipId || programId) {
      if (permissionStatus.loaded && !permissionStatus.loading && permissionStatus.data &&permissionStatus.data.length == 0) {
        dispatch(loadProgramPermissions(programId, organizationMembershipId));
      }
      if (permissionStatus && permissionStatus.data && permissionStatus.data.length > 0) {
        permissionStatus.data.map((permissionData) => {
          setPrograms(getObservation(permissionData));
        });
      }
    }
  },[permissionStatus.loaded,permissionStatus.loading,permissionStatus.data]);


  if (activeEnrollment && success && buttonAction == ProgramInviteAction.Accept) {
    dispatch(setContext(programsContexts.default));
    setLocalStorage(InvitedProgramId, programId);
    setLocalStorage(ProgramTabStoreName, ProgramTabName.Active);
    const toPath = permissionStatus && permissionStatus?.data  && permissionStatus?.data.length > 0 && permissionStatus?.data[0]?.details?.some(r=> ObservationTypesForConnection.includes(r.observationType)) ? routePaths.observationConnect : buildPath(routePaths.programsDetails, { programId, programEnrollmentId: activeEnrollment.id });
    return <ProfileRedirect to={toPath} />;
  }

  if (success && buttonAction == ProgramInviteAction.Decline) {
    setLocalStorage(ProgramTabStoreName, ProgramTabName.History);
    return <ProfileRedirect to={routePaths.programsList} />;
  }


  const getSelectedPermissions = (action) => {
    if (permissionStatus && permissionStatus.data && permissionStatus.data.length > 0) {
      setButtonAction(action);
      let result = permissionStatus.data.map(p => p.permission);
      return result;
    }
    return null;
  };

  if (program === null || program === undefined || program.length === 0 || programInfo === null) {
    return <ProfileRedirect to={routePaths.programsList} />;
  }
  const handleAccept = () => dispatch(acceptOrganizationInvite(requestId, organizationId, organizationMembershipId, currentProfile.id, getSelectedPermissions(ProgramInviteAction.Accept), true));
  const handleDecline = () => dispatch(declineOrganizationInvite(requestId, organizationId, organizationMembershipId, currentProfile.id, getSelectedPermissions(ProgramInviteAction.Decline)));

  return (
    <Page
      id='pathway-invite-details' readOnlyProfile
      centerElement={programInfo.name}
      backPath={{ to: routePaths.programsList }}
      loading={isAccepted}>
      <div className='pathway-invite-details-container' data-testid='invite-details'>
        <div className='pathway-invite-info'>
          <div className="pathway-description">
            <div className='pathway-description-title'> {local.programs.invitePathway} {programInfo.name}.</div>

            <div className='pathway-description-que'>{local.programs.inviteOrgQuestion}</div>
            <div className='pathway-description-ans'>{local.programs.inviteOrganization} <strong>{orgName}.</strong></div>

            <div className='pathway-description-que'>{local.programs.invitePathwayAbout}</div>
            <div><FormattedText text={programInfo.description} /></div>

            <div className='pathway-description-que'>{local.programs.sharedPathwayQuestion}</div>
            <div className='pathway-description-ans mb-0'>{local.programs.sharedPathwayList}</div>
            <div className='formatted-text'>
              <ul>
                {
                  programs  && programs.length > 0 ? programs.map((observation,index)=> <li key={index}>{observation}</li>) : null
                }
              </ul>
            </div>
            <div className='pathway-description-que'>{local.programs.inviteQuestion}</div>
            <div className='pathway-description-ans'>{local.programs.inviteAnswer}<strong>{orgName}.</strong></div>
          </div>

          <div className='form-row button-container' data-testid='buttons'>
            <Button id='decline-org-invite' className='btn btn-secondary' onClick={handleDecline} >{local.decline}</Button>
            <Button id='accept-org-invite' className='btn btn-primary' color='blue' onClick={() => { setIsAccepted(true);handleAccept();}} disabled={isAccepted} >{local.accept}</Button>
          </div>
        </div>
      </div>
    </Page>
  );
}

ProgramInviteDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
