import React from 'react';
import { canShare } from 'mobile/helpers';
import { ShareLinkWrapper, ShareIcon } from 'shared';
import { socialShareLinks } from 'services/constants';
import local from 'services/localization/local';

export default function NavShareLink() {
  return (
    <ShareLinkWrapper
      url={socialShareLinks.mainMenuShare}
      title={local.programs.programShareTitle}
      text={local.socialShare.menuShareText}
      dialogTitle={local.socialShare.menuShareDialogTitle}
      element="a"
      className="nav"
      id="nav-link-share">
      <ShareIcon />
      <span>{canShare ? local.socialShare.menuShareDialogTitle : local.socialShare.copyAppLink}</span>
    </ShareLinkWrapper>
  );
}
