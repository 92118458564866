import { isAndroid, isIOS, isIphone } from 'mobile/helpers';
import React from 'react';
import { AppleHealthType, googleFitLogo, GoogleFitType, AppleHealthLogo } from 'services/constants';
import { Button, Card } from 'shared';
import local from 'services/localization/local';

export default function ConnectedDevicesCard(props){
  const devices = props.devices;
  const googleFitConnectedInValidic = props.googleFitConnectedInValidic;
  const triggerModal = props.triggerModal;
  const renderSyncedData =  props.renderSyncedData;
  const googleFitConnected = props.googleFitConnected;

  return (
    devices.map(x =>
    {
      return (
        <Card key={'card-'+x.type} >
          <li >
            <div className="device-row">
              <div className="device">
                <div className="device-title">
                  <img className={x.type + '-logo-align'} src={x.type === AppleHealthType ? AppleHealthLogo : x.type === GoogleFitType ? googleFitLogo : isIOS ? 'http:' + x.logo_url:x.logo_url}></img>
                  <div className='device-subTitle'>{x.display_name}</div>
                </div>
                { (!isIphone && x.type === AppleHealthType) ?
                  <div className="apple-health-title"><strong>{local.devices.healthKitButtonExplanationFirst}</strong> {local.devices.healthKitButtonExplanation}</div>  :
                  (!isAndroid && x.type === GoogleFitType) ?
                    <div className="apple-health-title"><strong>{local.devices.healthKitButtonExplanationFirst}</strong> {local.devices.googleFitButtonExplanation} </div> :
                    <Button id={x.type} className="small-btn btn btn-secondary" onClick={()=>triggerModal(x.type,x.logo_url, x.provider, x.providerUserId)}>
                      {props.disconnectingDeviceType  == x.type ? local.disconnected : local.devices.disconnect}
                    </Button>}
              </div>
            </div>
            {isAndroid && googleFitConnected && googleFitConnectedInValidic == false && x.type === GoogleFitType? <div className="device-metric"> {local.devices.syncIsInProgress} </div> : <div className="device-metric"> {renderSyncedData(x.type)}</div>}
          </li>
        </Card>
      );
    }
    )
  );
}
