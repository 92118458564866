import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { routePaths } from 'services/routing';

class ProgramsRedirect extends Component {
  render() {
    return <Redirect to={routePaths.programsList} />;
  }
}

export default ProgramsRedirect;
