import React from 'react';
import PropTypes from 'prop-types';
import './no-data-placeholder.scss';

class NoDataPlaceholder extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div id="noDataPlaceholder" className="no-data-placeholder">{children}</div>
    );
  }
}

NoDataPlaceholder.propTypes = {
  children: PropTypes.node
};

export default NoDataPlaceholder;
