import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getRequest } from 'app/request/request-selectors';
import { DataLoadingStatus } from 'shared';
import './requested-queue-status.scss';

export function RequestStatus({ requestId, children, processingMessage }) {
  const { processing, hasError } = useSelector(state => getRequest(state, requestId));

  return (
    <div className={`requested-queue-status-container ${processing ? 'is-loading' : null}`}>
      <DataLoadingStatus loading={processing} error={hasError}>
        {children}
      </DataLoadingStatus>
      {processing && (<div className='processing-message'>{processingMessage}</div>)}
    </div>
  );
}

RequestStatus.propTypes = {
  requestId: PropTypes.string,
  children: PropTypes.node,
  processingMessage: PropTypes.string
};

export default RequestStatus;
