/* eslint-disable react/jsx-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import moment from 'moment';
import React from 'react';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { Icon, LinkWrapper } from 'shared';


export default function AppointmentListItem({appointment}){
  const { appointmentStartDate, consultationType, doctorName, provider, id } = appointment;
  return(
    <li className="card-list-item appointment-list">
      <LinkWrapper
        id='appointment-list'
        className="program-tile-details-link"
        logName='program-tile-details'
        to={routePaths.appointmentDetails}
        backpath={{appointment: appointment, fromPath:routePaths.appointment}}>
        <div className='procedure-text'>
          <div className='drName'>{doctorName}</div>

          <small className='provider-name'>{local.appointment.provider} : {provider}</small>

          <div className='appointment-type'><small>{consultationType}</small></div>

          <div className='appointment-schedule'>
            <div className='appointment-day'><Icon symbol="calenderEvent" /><span>{moment(appointmentStartDate).format('ddd')}, {moment(appointmentStartDate).format('MMM')} {moment(appointmentStartDate).format('Do')}</span></div>

            <div className='appointment-time'><Icon symbol="clock" /><span>{moment(appointmentStartDate).format('hh:mm A')}</span></div>
          </div>
        </div>
      </LinkWrapper>
    </li>
  );
}
