import { IntensityType } from 'services/enums';
import local from 'services/localization/local';

export const intensityDescriptions = {
  [IntensityType.Low]: { label: local.low, helpText: local.lowActivityHelper },
  [IntensityType.Medium]: { label: local.medium, helpText: local.mediumActivityHelper },
  [IntensityType.High]: { label: local.high, helpText: local.highActivityHelper }
};

export const intensitySliderOptions = [1, 2, 3].map(x => ({
  rangeValue: `${x}`,
  value: `${x}`,
  label: intensityDescriptions[x].label,
  helper: intensityDescriptions[x].helpText
}));
