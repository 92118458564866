import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Error, LoadingIcon, Icon } from 'shared';
import local from 'services/localization/local';
import { getConfigForObservationType } from 'health/observations/observation-configs';
import { loadOrganizationAdmins } from 'organizations/organizations-actions';
import { loadProgramPermissions } from 'programs/programs-actions';
import { getProgramPermissions } from 'programs/programs-selectors';
import { getOrganizationAdmins } from 'organizations/organizations-selectors';


export default function OrganizationPermissions({ admins, programId, organizationId, organizationMembershipId, hasProperties, updatePrograms }) {
  const dispatch = useDispatch();
  const [showAllAdmin, setShowAllAdmins] = useState(false);
  var [programs, setPrograms] = useState([]);
  var [selectAllPrograms, setSelectAllPrograms] = useState(true);
  const identifier = organizationMembershipId || programId;
  const permissions = useSelector(state => getProgramPermissions(state, identifier));
  const programList = [];
  if (!admins) {
    const orgAdmins = useSelector(state => getOrganizationAdmins(state, organizationId));
    admins = orgAdmins.data;
  }
  const text = local.organizations.permissions;
  const getObservation = (permission) => {
    let observations;
    const allObservationsForPermissions = permission.details.map(detail => getConfigForObservationType(detail.observationType).centerElement);
    // multiple permissions entries could have the same requested observation types.
    var effectiveObservations = Object.keys(allObservationsForPermissions.reduce((accum, observation) => {
      accum[observation] = true;
      return accum;
    }, {}));

    if (effectiveObservations.length == 0) {
      observations = local.formatString(text.observationMessageDefault);
    } else if (effectiveObservations.length == 1) {
      observations = local.formatString(text.observationMessageSingle, effectiveObservations[0]);
    } else {
      const lastObservation = effectiveObservations.pop();
      observations = local.formatString(text.observationMessageMultiple, effectiveObservations.join(', '), lastObservation);
    }
    return observations;
  };

  useEffect(() => {
    dispatch(loadOrganizationAdmins(organizationId));
  }, [organizationId]);

  useEffect(() => {
    if (organizationMembershipId || programId) {
      dispatch(loadProgramPermissions(programId, organizationMembershipId));
    }
  }, [organizationMembershipId, programId]);

  useEffect(() => {
    if (organizationMembershipId || programId) {
      if (permissions.loaded && !permissions.loading && hasProperties && permissions.data.length == 0) {
        dispatch(loadProgramPermissions(programId, organizationMembershipId));
      }
      if (permissions && permissions.data && permissions.data.length > 0) {
        permissions.data.map((data) => {
          var program = {
            id: data.permission['value'],
            programDetails: data,
            isSelected: true,
            observation: getObservation(data)
          };
          programList.push(program);
        });
        setPrograms(programList);
        updatePrograms(programList);
      }
    }
  }, [permissions.loading]);
  const handleClick = useCallback(() => {
    setShowAllAdmins(!showAllAdmin);
  }, [showAllAdmin]);

  if (permissions.hasError) {
    return <Error>{local.error}</Error>;
  }

  if (permissions.loading || (hasProperties && permissions.loaded && permissions.data.length == 0)) {
    return <LoadingIcon />;
  }

  const selectProgram = (id, isSelected) => {
    let programsCopy = [...programs];
    programsCopy.filter(x => x.id === id)[0].isSelected = isSelected;
    setPrograms(programsCopy);
    updatePrograms(programsCopy);
    var selectedProgramCount = programsCopy.filter(x => x.isSelected === true);
    if (selectedProgramCount) {
      if (selectedProgramCount.length === programsCopy.length)
        setSelectAllPrograms(true);
      else
        setSelectAllPrograms(false);
    }
  };

  const selectAllProgram = (value) => {
    let programsCopy = [...programs];

    programsCopy.map(function (x) {
      x.isSelected = value;
      return x;
    });
    setPrograms(programsCopy);
    setSelectAllPrograms(value);
    updatePrograms(programsCopy);
  };

  let pathwayPermissions;
  if (permissions && permissions.data && permissions.data.length > 0) {

    pathwayPermissions = (
      <div>
        <li>
          <Icon symbol="enrolledpathway" />
          <div>
            <span className="list-header">{text.pathwayHeader}</span>
          </div>
        </li>
        <small>{text.choosePathway}</small>
        <br />
        <br />
        <div className='form-check '>
          <input className='form-check-input' checked={selectAllPrograms} type="checkbox" id="selectAll" onChange={() => { selectAllProgram(!selectAllPrograms);}} />
          <label className='form-check-label' htmlFor="selectAll">{text.selectAllPrograms}</label>
        </div>
        <ul className="card-list">
          {programs && programs.length > 0 && programs.map((program, idx) => (
            idx < permissions.data.length &&
            <li key={'program-' + program.id} className="program card-list-item programs-enroll-option-list" onClick={() => selectProgram(program.id, !program.isSelected)}>
              <div id={'program-select-link-' + program.id} className="program-list-item-link program-display-flex">
                <div className="select-icons">
                  {program.isSelected ? <Icon symbol="selectedCircle" fill="blue" /> : <Icon symbol="deselectedCircle" />}
                </div>
                <div className="program-text">
                  <div className="program-title program-display-flex">
                    <div>
                      <img className="icon-image" style={{ width: '25px' }} src={program.programDetails.iconPath || 'https://static.myhc.app/programs/hc_thumb.png'}></img>
                    </div>
                    <strong className="program-header">{program.programDetails.description}</strong>
                  </div>
                  <small>{program.observation}</small>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  let adminPermissions;
  if (admins && admins.length > 0) {
    let adminList;
    let hasExtended = false;
    const adminEmails = admins.map(x => x.email).sort();
    if (adminEmails.length == 1) {
      adminList = local.formatString(text.adminMessageSingle, (<span className="nowrap-string">{adminEmails[0]}</span>));
    } else if (adminEmails.length <= 3) {
      const lastAdmin = adminEmails.pop();
      const otherAdmins = adminEmails.join(', ');
      adminList = local.formatString(text.adminMessageMultiple, (<span className='nowrap-string'>{otherAdmins}</span>), (<span className="nowrap-string">{lastAdmin}</span>));
    } else if (adminEmails.length > 3) {
      if (!showAllAdmin) {
        const abreviatedAdmins = adminEmails.splice(0, 3);
        const remainingAdmins = adminEmails.length;
        const adminsToShow = abreviatedAdmins.join(', ');
        adminList = local.formatString(text.adminMessageExtended, (<span className='nowrap-string'>{adminsToShow}</span>), remainingAdmins);
      } else {
        const lastAdmin = adminEmails.pop();
        const otherAdmins = adminEmails.join(', ');
        adminList = local.formatString(text.adminMessageMultiple, (<span className='nowrap-string'>{otherAdmins}</span>), (<span className="nowrap-string">{lastAdmin}</span>));
      }
      hasExtended = true;
    }
    if (hasExtended) {
      adminPermissions = (
        <li className="clickable" onClick={handleClick}>
          <Icon symbol="admin" />
          <div>
            <span className="list-header">{text.adminHeader}</span>
            <br />
            <small>{adminList}</small>
          </div>
          <Icon symbol="chevronright" className={showAllAdmin ? 'show-all-admins' : ''} />
        </li>);
    } else {
      adminPermissions = (
        <li>
          <Icon symbol="admin" />
          <div>
            <span className="list-header">{text.adminHeader}</span>
            <br />
            <small>{adminList}</small>
          </div>
        </li>);
    }
  }

  return (
    <ul className='organization-permissions'>
      {adminPermissions}
      {pathwayPermissions}
    </ul>
  );
}

OrganizationPermissions.propTypes = {
  hasProperties: PropTypes.bool,
  programId: PropTypes.string,
  organizationMembershipId: PropTypes.string,
  organizationId: PropTypes.string.isRequired,
  admins: PropTypes.arrayOf(PropTypes.shape())
};
