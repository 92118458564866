import compileReducers from 'services/compile-reducer';
import {
  UPDATE_EMAIL_ADDRESS,
  CHANGE_EMAIL_ERROR,
  FORCE_SIGN_OUT,
  LAST_VERIFICATION_CODE_SENT
} from './action-names';

const initialState = {
  newEmail:'',
  changeEmailError:'',
  forceSignout: false,
  lastVerificaitonCodeSent:''
};

const reducers = {};

reducers[UPDATE_EMAIL_ADDRESS] = (state,payload) => {
  return {
    ...state,
    newEmail: payload
  };
};

reducers[CHANGE_EMAIL_ERROR] = (state,payload) => {
  return {
    ...state,
    changeEmailError: payload
  };
};

reducers[FORCE_SIGN_OUT] = (state,payload) => {
  return {
    ...state,
    forceSignout: payload
  };
};

reducers[LAST_VERIFICATION_CODE_SENT] = (state,payload) => {
  return {
    ...state,
    lastVerificaitonCodeSent: payload
  };
};

export default compileReducers(reducers, initialState);
