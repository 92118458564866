import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import { buildVersion, privacyPolicyUrl, termsAndConditionsUrl } from 'app/config';
import { closeDrawerMenu } from './actions';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { Icon, Header, ExternalLink } from 'shared';
import UserConsentNavLink from 'app/privacy-policy-eula/userConsent/UserConsentNavLink';
import NotificationLinkNav from 'notifications/link/NotificationLinkNav';
import { getPendingInviteCount } from 'profiles/profiles-selectors';
import { getActiveUsersProgramInvitesCount } from 'programs/programs-selectors';
import NavShareLink from './NavShareLink';
import './navigation.scss';
import GetHelpLink from 'gethelp/GetHelpLink';
import { getCoachPayment } from 'products/products-selectors';
import { CoachName } from 'services/constants';
import { getSelfProfileId } from 'profiles/list/selectors';
import { getIsFeatureEnabled } from 'features/features-selectors';
import { getIdentityId } from 'identity/selectors';
import { Feature } from 'services/enums';
import { getChatComponentProps } from 'chat/chat-selector';
import ChatLinkNav from 'chat/link/ChatLinkNav';

class MainNavigationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.buildCloseButton = this.buildCloseButton.bind(this);
    this.buildMainNav = this.buildMainNav.bind(this);
  }


  buildCloseButton() {
    return (
      <button type="submit" onClick={this.props.actions.closeDrawerMenu}>
        <Icon symbol="times" />
      </button>
    );
  }

  buildMainNav() {

    const { signOutOnly, inviteCount, pathwayInviteCount, coachPayment, isCalciumCoachEnabled, isChatEnabledOrganization } = this.props;
    const content = local.fullScreenModel;

    if (!signOutOnly) {
      return (
        <div>
          <section className="nav-section desktop-only">
            <NavLink id="nav-link-dashboard" to={routePaths.root} className="nav-health" activeClassName="active" exact>
              <Icon symbol="home" />
              <span>{local.nav.dashboard}</span>
            </NavLink>
          </section>

          <section className="nav-section desktop-only">
            <NotificationLinkNav />
          </section>

          <section className="nav-section desktop-only">
            <NavLink id="nav-link-pathways" className="nav-pathways" to={routePaths.programs} activeClassName="active">
              <Icon symbol="healthprograms" />

              <span>{local.nav.pathways}</span>
            </NavLink>
          </section>

          <section className="nav-section desktop-only">
            <NavLink id="nav-link-health" to={routePaths.health} className="nav-health" activeClassName="active" exact>
              <Icon symbol="health" />

              <span>{local.nav.health}</span>
            </NavLink>
          </section>

          {isChatEnabledOrganization ? <section className="nav-section desktop-only">
            <ChatLinkNav />
                                       </section> : null}

          <section className="nav-section">
            <NavLink id="nav-link-connecttodevice" to={routePaths.healthDevices} className="nav-connectedtodevice" activeClassName="active">
              <Icon symbol="devices" />

              <span>{local.dashboard.dataConnections.tile}</span>
            </NavLink>
          </section>

          <section className="nav-section">
            <NavLink id="nav-link-health" to={routePaths.healthJournal} className="nav-health" activeClassName="active" exact>
              <Icon symbol="health" />

              <span>{local.nav.healthJournal}</span>
            </NavLink>
          </section>

          <section className="nav-section">
            <NavLink id="nav-link-medicationTracking" to={routePaths.healthMedicationTracking} className="nav-health" activeClassName="active" exact>
              <Icon symbol="devices" />

              <span>{local.nav.medicationTracking}</span>
            </NavLink>
          </section>

          <section className="nav-section">
            <NavLink id="nav-link-ehr" to={routePaths.electronicHealthRecord} className="nav-health" activeClassName="active" exact>
              <Icon symbol="health" />

              <span>{local.nav.electronicHealthRecord}</span>
            </NavLink>
          </section>

          {/* <section className="nav-section">
            <ExternalLink
              href={marketPlaceUrl}
              id="more-marketplace"
              logName='marketplace-external-url'
              className='nav-marketplace'
              openNewTab>
              <Icon symbol="marketplace" />

              <span>{local.nav.marketplace}</span>
            </ExternalLink>
          </section> */}

          {/* <section className="nav-section">
            <NavLink id="invoice" className='nav-preferences' to={routePaths.invoice}>
              <Icon symbol="invoice" />
              <span>{local.nav.invoice}</span>
            </NavLink>
          </section> */}

          {/* {isCalciumCoachEnabled ? <section className="nav-section">
            <NavLink id="calciumCoach" className='nav-calciumCoach' to={{ pathname: routePaths.coach, state: { from: location?.pathname } }}>
              <div className='section1'>
                <Icon symbol="coach" />
                <span>{CoachName}</span>
              </div>
              <div className='section2'>
                {!coachPayment?.data && <Icon symbol="lock"></Icon>}
                <span className='small'>{content.premium}</span>
              </div>
            </NavLink>
          </section> : null} */}

          <section className="nav-section">
            <NavLink id="nav-link-people" exact to={routePaths.people} className="nav-profile" activeClassName="active">
              <Icon symbol="group" />

              <span>{local.nav.people}</span>

              {inviteCount || pathwayInviteCount ? <figure>{inviteCount + pathwayInviteCount}</figure> : null}
            </NavLink>
          </section>
          <section className="nav-section">
            <NavLink id="nav-link-profiles" exact to={routePaths.peopleEdit} className="nav-profile" activeClassName='active'>
              <Icon symbol="editprofile" />
              <span>{local.formProfileHeader}</span>
            </NavLink>
          </section>

          <section className="nav-section">
            <NavLink id="more-preferences" className='nav-preferences' to={routePaths.preferences}>
              <Icon symbol="preferences" />
              <span>{local.nav.preferences}</span>
            </NavLink>
          </section>

          <section className="nav-section">
            <GetHelpLink />
          </section>

          <section className="nav-section">
            <NavShareLink />
          </section>

          <section className="nav-section">
            <UserConsentNavLink />
          </section>

          <section className="nav-section">
            <ExternalLink
              href={privacyPolicyUrl}
              id="privacy-policy"
              logName='privacy-policy-external-url'
              className='nav-pp'
              openNewTab>
              <Icon symbol="privacypolicy" />
              <span>{local.privacyPolicy}</span>
            </ExternalLink>
          </section>

          <section className="nav-section">
            <ExternalLink
              href={termsAndConditionsUrl}
              id="eula-text"
              logName='terms-conditions-external-url'
              className='nav-eula'
              openNewTab>
              <Icon symbol="termsandconditions" />
              <span>{local.eula}</span>
            </ExternalLink>
          </section>
        </div>
      );
    }
  }

  render() {
    const { actions, isOpen } = this.props;
    const year = new Date().getFullYear();
    const closeButton = this.buildCloseButton();
    const mainNav = this.buildMainNav();

    return (
      <aside id="tabBarContainer" className={isOpen ? 'open' : 'close'}>
        <Header centerElement={local.menu} farLeftElement={closeButton} />

        <nav id="navSectionLinks" onClick={actions.closeDrawerMenu}>
          {mainNav}

          <section className="nav-section">
            <NavLink id="more-signout" className='nav-signout' to={routePaths.signout}>
              <Icon symbol="signout" />

              <span>{local.linkSignout}</span>
            </NavLink>
          </section>
        </nav>

        <footer id="navSectionFooter">

          <small>&copy; {year} - {local.copyright}</small>

          <br />

          <small>{buildVersion}</small>
        </footer>
      </aside>
    );
  }
}


function mapStateToProps(state) {
  const profileId = getSelfProfileId(state);
  const identityId = getIdentityId(state);

  const {isChatEnabledOrg} = getChatComponentProps(state);

  const props = {
    isOpen: state.navigation.isOpen,
    inviteCount: getPendingInviteCount(state),
    pathwayInviteCount: getActiveUsersProgramInvitesCount(state),
    coachPayment: getCoachPayment(state, profileId),
    isCalciumCoachEnabled: getIsFeatureEnabled(state, identityId, Feature.CalciumCoach),
    profileId,
    isChatEnabledOrganization: isChatEnabledOrg
  };

  return props;
}


function mapDispatchToProps(dispatch) {
  const actions = {
    closeDrawerMenu
  };

  return { actions: bindActionCreators(actions, dispatch) };
}

MainNavigationComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  inviteCount: PropTypes.number.isRequired,
  signOutOnly: PropTypes.bool,
  actions: PropTypes.shape({
    closeDrawerMenu: PropTypes.func.isRequired
  }).isRequired,
  coachPayment: PropTypes.shape(),
  isCalciumCoachEnabled: PropTypes.bool,
  pathwayInviteCount: PropTypes.number,
  isChatEnabledOrganization: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(MainNavigationComponent);
