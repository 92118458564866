import * as interviewActions from '@healthchampion/interviews';
import * as requestActions from 'app/request/request-actions';
import * as toastActions from 'app/toaster/actions';
import * as healthDataApi from 'services/api/healthdata-api';

export const INTERVIEWS_SELECT = 'INTERVIEWS_SELECT';
export const INTERVIEWS_SAVING = 'INTERVIEWS_SAVING';
export const INTERVIEWS_ERROR = 'INTERVIEWS_ERROR';

export function selectInterview(interviewId) {
  return {
    type: INTERVIEWS_SELECT,
    payload: {
      interviewId
    }
  };
}

export const closeInterview = interviewActions.closeInterview;

export function saveInterview() {
  return {
    type: INTERVIEWS_SAVING,
    payload: {}
  };
}

export function interviewError(interviewId) {
  return {
    type: INTERVIEWS_ERROR,
    payload: { interviewId }
  };
}

export function loadInterview(interviewId, completeAction, programEnrollmentTaskId, autoSaveAction = undefined, openInAppUrl = undefined, isMobile) {
  return (dispatch) => {
    dispatch(selectInterview(interviewId));
    const action = state => healthDataApi.getInterview(interviewId, programEnrollmentTaskId, state)
      .then(interview => {
        dispatch(interviewActions.startInterview(interview, completeAction, interview.interviewDraftResponse, autoSaveAction, openInAppUrl, isMobile));
      })
      .catch(error => {
        dispatch(toastActions.popErrorToast());
        throw error;
      });

    return dispatch(requestActions.request(action, `load-${interviewId}`));
  };
}
