import compileReducers from 'services/compile-reducer';
import * as persistentDataReducer from 'app/request/persistent-data-reducer';
import { types } from './products-types';

export const initialState = {
  [types.COACH_PAYMENT]: persistentDataReducer.initialState,
  [types.GOLD]: persistentDataReducer.initialState,
  [types.PLATINUM]: persistentDataReducer.initialState
};

const reducers = { ...persistentDataReducer.reducers(types.COACH_PAYMENT),  ...persistentDataReducer.reducers(types.GOLD),  ...persistentDataReducer.reducers(types.PLATINUM) };

export default compileReducers(reducers, initialState);
