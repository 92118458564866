import React from 'react';
import PropTypes from 'prop-types';
import { SubmissionError } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import { getProfileId } from 'app/selectors';
import local from 'services/localization/local';
import { ObservationType, Unit } from 'services/enums';
import { feetInchesToInches } from 'services/value-formatter';
import { observationReset } from 'health/actions';
import { saveObservation } from 'health/action-creators';
import LogHeightForm from './LogHeightForm';

export class LogHeight extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    const { actions } = this.props;
    const { reset } = actions;

    // TODO: Revisit timing if we decide to route away while saving
    reset(ObservationType.Height);
  }

  handleSubmit(values) {
    const { feet, inches, ...rest } = values;
    const { profileId, actions } = this.props;

    if (feet || inches) {
      const heightInInches = feetInchesToInches(feet, inches);
      const maxInches = 120;

      if (heightInInches > maxInches) {
        throw new SubmissionError({
          feet: local.validationInvalidHeight,
          inches: ' '
        });
      }

      actions.saveObservation({
        ...rest,
        profileId,
        value: heightInInches,
        observationType: ObservationType.Height,
        unit: Unit.Inches
      });
    } else {
      throw new SubmissionError({
        feet: local.validationRequired,
        inches: ' '
      });
    }
  }

  render() {
    const { saving, profileId, handleBack, pathwayContext } = this.props;

    const currentTimestamp = moment().format(moment.HTML5_FMT.DATETIME_LOCAL);
    const initialValues = { timestamp: currentTimestamp };

    return (
      <LogHeightForm profileId={profileId} pathwayContext={pathwayContext} onSubmit={this.handleSubmit} saving={saving} initialValues={initialValues} handleBack={handleBack} />
    );
  }
}

LogHeight.propTypes = {
  profileId: PropTypes.string.isRequired,
  saving: PropTypes.bool.isRequired,
  handleBack: PropTypes.func.isRequired,
  pathwayContext: PropTypes.shape(),
  actions: PropTypes.shape({
    saveObservation: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  }).isRequired
};

function mapStateToProps(state, ownProps) {
  const props = {
    saving: state.health.height.saving,
    profileId: getProfileId(state, ownProps)
  };

  return props;
}

function mapDispatchToProps(dispatch) {
  const actions = {
    saveObservation,
    reset: observationReset
  };

  const actionMap = { actions: bindActionCreators(actions, dispatch) };

  return actionMap;
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogHeight));
