import React,{useState} from 'react';
import local from 'services/localization/local';
import { Button } from 'shared';
export function ButtonWithOneClick({children,...props}) {
  const [state,setState] = useState(false);
  const [connect,setConnect] = useState(children);
  return (
    <Button
      {...props} disabled={state} onClick={(e)=>{
        setState(!state);
        props.onClick(e);
        setConnect(local.connected);
      }}>{connect}
    </Button>
  );
}
