import React from 'react';
import { Page, Logo, Icon, LinkWrapper } from 'shared';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import Art from './art/VerificationError';
import './connect.scss';
import PropTypes from 'prop-types';
import { IdentityVerificationErrorCode as errorCode } from 'services/enums';
import AutoRedirect from './AutoRedirect';

export default function ConfirmIdentityFailed(props) {
  const content = local.healthData.connect.identityVerification;
  const errorMessage = props.syncResponseCode == errorCode.ProfileAttemptLimitExceeded || props.syncResponseCode == errorCode.IdentityAttemptLimitExceeded ?
    content.attemptFailedErrorMessage : content.verificationErrorDetails;

  return (
    <Page
      id="connect-identity-failed"
      backPath={{ to: routePaths.health }}
      centerElement={<Logo />}>
      <figure>
        <Art />
      </figure>
      <div className="formatted-text">
        <p>{errorMessage}</p>
        <br />
        { (errorMessage == content.attemptFailedErrorMessage) &&
          <p>
            {content.identityVerificationSupportTicketCreated}
          </p>}
        {(errorMessage == content.verificationErrorDetails) &&
          <LinkWrapper
            id='retry-id-verification'
            to={routePaths.health}>
            <div className="link-flex">
              <Icon symbol="refresh" />
              <span>{local.reload}</span>
            </div>
          </LinkWrapper>}
        <br />
        <AutoRedirect redirectInSeconds={10} redirectTo={routePaths.health}>
          <p>{local.healthData.connect.identityVerification.successNavigateMessage}</p>
        </AutoRedirect>
      </div>
    </Page>
  );
}

ConfirmIdentityFailed.propTypes = {
  syncResponseCode: PropTypes.number.isRequired
};
