import ProfilePage from 'profiles/page/ProfilePage';
import React , { useEffect }  from 'react';
import HealthNavigationV1 from 'health/data/HealthNavigationV1';
import MobileBackButtonController from 'mobile/MobileBackButtonController';
import local from 'services/localization/local';
import DashboardHealthRecordsV1 from './DashboardHealthRecordsV1';
import { getProfileId } from 'app/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { loadUnreadMedicalRecordsCount } from 'health/action-creators';
import VerifyIdentity from 'health/connect/VerifyIdentity';

export default function NewHealthDataPage() {
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);

  useEffect(() => {
    dispatch(loadUnreadMedicalRecordsCount(profileId));
  }, [profileId]);

  return (
    <ProfilePage
      id='dashboard-page-v1'
      centerElement={
        <div className='hc-logo-width'>{local.healthDataLink} </div>
      }>
      <MobileBackButtonController exitApp />

      <div className="invite-list">
        <VerifyIdentity />
        <section id="dashboard-invites-section">
        </section>
      </div>
      <HealthNavigationV1 fromHealthData />
      <DashboardHealthRecordsV1 fromHealthData />
    </ProfilePage>
  );
}
