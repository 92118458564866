import React from 'react';
import { ProfileLink } from 'shared';
import { routePaths } from 'services/routing';
import TimelineIconDate from './TimelineIconDate';
import { Medication } from 'shared/fullColorIcons';

export default function TimelineDose({ item }) {
  return (
    <ProfileLink
      to={routePaths.healthMedication}
      id={`timeline-item-link-${item.data.id}`}
      logName="timeline-dose">
      <TimelineIconDate item={item} Symbol={Medication} />
      {item.data.name && <strong><p className="timeline-item-header">{item.data.name}</p></strong>}
      {item.data.notes && <p className="timeline-item-notes">{item.data.notes}</p>}
      <span className="timeline-item-subheader">{item.data.medicationName}</span>
      <br />
      <span className="timeline-item-value">{item.data.dosage}</span>
    </ProfileLink>
  );
}
