import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'shared/icon/Icon';
import './data-row.scss';
import local from 'services/localization/local';
import { HealthDataSource } from 'services/enums';
import { terraLibreDisplayName } from 'services/constants';
import { GetManufacturerName } from 'services/helpers';

export class DataRow extends React.Component {
  constructor(props) {
    super(props);
    this.getClassName = this.getClassName.bind(this);
    this.getItemDataClassName = this.getItemDataClassName.bind(this);
  }

  getClassName() {
    const { selected } = this.props;
    if (selected) {
      return 'data-row selected';
    }
    return 'data-row';
  }

  getItemDataClassName() {
    const { handleRowClick, dataSectionWrapper} = this.props;
    const wrappedClass = dataSectionWrapper ? 'wrapped' : '';

    if (handleRowClick) {
      return `item-data item-actions ${wrappedClass}`;
    }

    return `item-data ${wrappedClass}`;
  }

  render() {
    const {
      head,
      subhead,
      timestamp,
      handleDelete,
      handleRowClick,
      id,
      notes,
      healthDataSource,
      source,
      deviceDisplayName,
      visual,
      deleteVisual,
      dataSectionWrapper,
      manufacturer
    } = this.props;
    const className = this.getClassName();
    const itemDataClassName = this.getItemDataClassName();
    const wrapper = dataSectionWrapper ? dataSectionWrapper : (section) => section;

    const subheadList = subhead ? subhead.split('\n') : [];
    const content = local.deviceName;
    const getDeviceName = (sourceName) =>{
      var deviceName = sourceName.split(' ')
        .map(w => w[0].toUpperCase() + w.substring(1))
        .join(' ');
      return deviceName;
    };

    let deviceSrc = (healthDataSource === HealthDataSource.Validic) && deviceDisplayName ? `${deviceDisplayName}${GetManufacturerName(manufacturer)}` : `${content.device}`;
    let providerSrc = healthDataSource === HealthDataSource.HealthGorilla && source ? `${content.withProviderName} ${source}` : null;
    let terraDevice = (healthDataSource === HealthDataSource.Terra && source ? getDeviceName(source) : terraLibreDisplayName );
    // when source and notes value are same displaying only the notes value
    providerSrc = (notes === providerSrc) && '';
    const dataSection = (

      <React.Fragment>
        <section className="item-flex-section">
          {visual ? <div className="item-flex-left">{visual}</div> : null}

          <div className="item-flex-main">
            <span className="item-data-head">
              {head}
            </span>

            {subheadList ? subheadList.map((item, index)=><span key={index} className="item-data-subhead">{item}</span>) : null}


          </div>

          <div className="item-flex-right">
            {timestamp}
          </div>
        </section>
        <section className="item-full-width-section">{
          healthDataSource === HealthDataSource.SelfReported ? `${content.manual}` : ((healthDataSource === HealthDataSource.Validic ?  `${content.sync} ${deviceSrc}` : (healthDataSource === HealthDataSource.Terra ? `${content.sync} ${terraDevice}`:(healthDataSource === HealthDataSource.HealthGorilla ? (source ? providerSrc : `${content.noProviderName}`) : ''))))
        }
        </section>
        {notes ? <section className="item-full-width-section">{notes}</section> : null}
      </React.Fragment>
    );

    return (
      <div id={`data-row-${id}`} data-testid={`data-row-${id}`} className={className}>
        <div onClick={handleRowClick} className={itemDataClassName}>
          {wrapper(dataSection)}
        </div>
        {handleDelete ?
          <div className="data-actions" onClick={handleDelete}>
            { deleteVisual ? deleteVisual : (
              <button
                id={`delete-button-${id}`}
                data-testid={`delete-button-${id}`}
                type="button">
                <Icon symbol="delete" />
              </button>
            )}
          </div> : null}
      </div>
    );
  }
}

DataRow.propTypes = {
  head: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.element,PropTypes.array]).isRequired,
  subhead: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  visual: PropTypes.element,
  deleteVisual: PropTypes.element,
  timestamp: PropTypes.string,
  notes: PropTypes.string,
  deviceDisplayName: PropTypes.string,
  healthDataSource: PropTypes.number,
  source: PropTypes.string,
  selected: PropTypes.bool,
  id: PropTypes.string.isRequired,
  handleRowClick: PropTypes.func,
  handleDelete: PropTypes.func,
  dataSectionWrapper: PropTypes.func
};

DataRow.defaultProps = {
  selected: false
};

export default DataRow;
