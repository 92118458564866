import React from 'react';
import PropTypes from 'prop-types';
import local from 'services/localization/local';

export default function MedicationNotesSection(props) {
  const { medication } = props;
  if (!(medication && medication.id && medication.notes)) {
    return null;
  }

  return (
    <section id="medication-notes">
      <small><strong>{local.observation.medication.medication}</strong></small>

      <br />

      <small>{medication.notes}</small>
    </section>
  );
}

MedicationNotesSection.propTypes = {
  medication: PropTypes.shape()
};
