import React, { useEffect , useState} from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import AuthLoader from 'app/AuthLoader';
import { ErrorPage } from 'shared';
import local from 'services/localization/local';
import { loadSelfIdentity, loadABTestingSegment } from './action-creators';
import { getIdentityId } from './selectors';
import { profilesApiRoot as apiRoot, authClientId } from 'app/config';
import { signOut } from 'services/auth0-service';
const env = window.env || {};


export default function IdentityWrapper({ children }) {
  const dispatch = useDispatch();
  const identityId = useSelector(getIdentityId, shallowEqual);
  const loadError = useSelector(state => state.identity.loadError, shallowEqual);
  const loadErrorMessage = useSelector(state => state.identity.loginErrorMessage, shallowEqual);
  const [isIAL2Verification,setIsIAL2Verification] = useState(localStorage.getItem('IAL2Verification'));
  const isIdMeLogin = localStorage.getItem('isIdMeAuth');
  const errorMessage = `There is already an account associated with your email address in ${isIdMeLogin ? 'Calcium' : 'Id.me'}. Please login with your email and password that linked to ${isIdMeLogin ? 'Calcium' : 'Id.me'} account. Click here to login`;

  function logout(){
    if(isIdMeLogin){
      localStorage.removeItem('id_token_expiry');
      localStorage.removeItem('id_token');
      localStorage.removeItem('isIdMeAuth');
      window.location.href = env.Idme_logouturl;
    }else{
      signOut();
    }
  }

  useEffect(() => {
    if (!identityId) {
      dispatch(loadSelfIdentity());
      dispatch(loadABTestingSegment());
    }
  }, [identityId]);

  useEffect(async () => {
    if (isIAL2Verification) {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const error = urlParams.get('error');
      if (code) {
        let ehrPage = localStorage.getItem('IAL2VerificationRedirect');
        await AuthorizeIdMeSignin(code, dispatch, false);
        localStorage.removeItem('IAL2Verification');
        localStorage.removeItem('IAL2VerificationRedirect');
        setIsIAL2Verification('');
        window.location.href = ehrPage;
      }
      if(error && error.includes('access_denied')){
        let redirectUri =`${localStorage.getItem('IAL2VerificationRedirect')}?error=access_denied`;
        localStorage.removeItem('IAL2Verification');
        localStorage.removeItem('IAL2VerificationRedirect');
        setIsIAL2Verification('');
        window.location.href = redirectUri;
      }
    }
  }, []);

  if (loadError && !loadErrorMessage?.includes('Login failed')) {
    return <ErrorPage>{local.errorLoadProfile}</ErrorPage>;
  }

  if (loadError && loadErrorMessage?.includes('Login failed')) {
    return (
      <ErrorPage hideReload={true}><div style={{cursor:'pointer'}} onClick={logout}>{errorMessage}</div></ErrorPage>
    );
  }

  if (!identityId || isIAL2Verification) {
    return <AuthLoader />;
  }

   return children;
}

function AuthorizeIdMeSignin(code, dispatch, dispatchToken = true) {
 // let url = `https://profiles-api-dev-owen.myhc.app/idme-token?code=${code}&redirectUrl=https://dev-owen.myhc.app/`;
  let url = `${env.API_ROOT_PROFILES}/idme-token?code=${code}&redirectUrl=${env.Idme_redirectionurl}`;
  let header = {
    'Content-Type': 'application/json'
  };
  return fetch(url, {
    method: 'Get',
    headers: header
  }).then(response => {
    return response.json();
  })
    .then(data => {
      return data;
    })
    .catch(error => {
      console.log(error);
      alert('Error occured while connecting to id.me');
      return null;
    });
}
