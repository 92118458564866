import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

class LilChartGraph extends React.Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);
    this.getOptions = this.getOptions.bind(this);
  }

  getData() {
    const { data, color } = this.props;

    const countToShow = 12;
    const filteredData = data.slice(countToShow * -1);

    for (let i = 0; i <= countToShow; i++) {
      if (!filteredData[i]) {
        filteredData[i] = { recordedAt: 0, value: 0 };
      }
    }

    return {
      labels: filteredData.map(x => x.recordedAt),
      datasets: [{
        backgroundColor: color,
        barPercentage: 0.3,
        categoryPercentage: 1.5,
        data: filteredData.map(x => x.value)
      }]
    };
  }

  getOptions() {
    const { minDataTick } = this.props;

    return {
      responsive: false,
      tooltips: {
        enabled: false
      },
      hover: {
        mode: null
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          display: false,
          gridLines: {
            display: false
          },
          ticks: {
            display: false
          }
        }],
        yAxes: [{
          display: false,
          gridLines: {
            display: false
          },
          ticks: {
            suggestedMin: minDataTick,
            display: false
          }
        }]
      }
    };
  }

  render() {
    const data = this.getData();
    const options = this.getOptions();

    return (
      <Bar
        data={data}
        options={options}
        width={108}
        height={20}
        className="chart-data" />
    );
  }
}

LilChartGraph.displayName = 'LilChartGraph';
LilChartGraph.propTypes = {
  color: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape).isRequired,
  minDataTick: PropTypes.number
};

LilChartGraph.defaultProps = {
  minDataTick: 0
};

export default LilChartGraph;
