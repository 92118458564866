import * as selectors from 'app/request/persistent-data-selectors';
import { types } from './features-types';

export const getIsFeatureEnabled = (state, identityId, feature) =>{
  const data = selectors.getData(state.features, types.DISABLED_FEATURES, identityId);
  if(data && data.features){
    const selectedFeature = data.features.find(f => f.feature === feature);
    if(selectedFeature)
      return !selectedFeature.disabled;
  }
  return true;
};
