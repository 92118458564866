export const MEDICATION_REQUESTED = 'MEDICATION_REQUESTED';
export const MEDICATION_LOAD_SUCCESS = 'MEDICATION_LOAD_SUCCESS';
export const MEDICATION_LOAD_ERROR = 'MEDICATION_LOAD_ERROR';
export const MEDICATION_SAVING = 'MEDICATION_SAVING';
export const MEDICATION_SAVE_SUCCESS = 'MEDICATION_SAVE_SUCCESS';
export const MEDICATION_SAVE_ERROR = 'MEDICATION_SAVE_ERROR';
export const MEDICATION_SAVE_RESET = 'MEDICATION_SAVE_RESET';
export const MEDICATION_DELETE = 'MEDICATION_DELETE';
//Medication Scheduler
export const MEDICATION_SCHEDULE_REQUESTED = 'MEDICATION_SCHEDULE_REQUESTED';
export const MEDICATION_SCHEDULE_LOAD_SUCCESS = 'MEDICATION_SCHEDULE_LOAD_SUCCESS';
export const MEDICATION_SCHEDULE_LOAD_ERROR = 'MEDICATION_SCHEDULE_LOAD_ERROR';

