import React from 'react';
import moment from 'moment';
import local from 'services/localization/local';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withContext from 'app/withContext';
import { getSleepBarChartData } from 'health/observations/sleep/sleep-selectors';
import { ArrowButton } from 'shared';
import SleepBarChart from './SleepBarChart';
import './sleep-visualization.scss';

class SleepVisualization extends React.Component {
  constructor(props) {
    super(props);
    this.getTitle = this.getTitle.bind(this);
  }

  getTitle() {
    const { selectedEnd } = this.props;
    return local.formatString(local.activity.visTitle, `${moment(selectedEnd).add(-4, 'weeks').format('M/D')} - ${moment(selectedEnd).format('M/D')}`);
  }

  render() {
    const { data, selectPreviousFourWeeks, selectNextFourWeeks, selectedEnd } = this.props;
    const nextDisabled = moment(selectedEnd).add(1, 'day') > moment();

    return (
      <div id="sleep-visualization">
        <div className="sleep-vis-header">
          <ArrowButton id="previous-sleep-button" onClick={selectPreviousFourWeeks} /><small className="sleep-vis-title">{this.getTitle()}</small><ArrowButton id="next-sleep-button" rightArrow onClick={selectNextFourWeeks} disabled={nextDisabled} />
        </div><SleepBarChart data={data} />
      </div>
    );
  }
}

SleepVisualization.propTypes = {
  selectedEnd: PropTypes.string.isRequired,
  selectPreviousFourWeeks: PropTypes.func.isRequired,
  selectNextFourWeeks: PropTypes.func.isRequired,
  // redux
  data: PropTypes.arrayOf(PropTypes.shape).isRequired
};

function mapStateToProps(state, props) {
  return {
    data: getSleepBarChartData(state, props.profileId, props.selectedEnd)
  };
}

export default withContext(connect(mapStateToProps)(SleepVisualization));
