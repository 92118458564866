import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import { TextField , DateTimeTextFieldWrapper} from 'shared/form-fields';
import * as validators from 'services/fields/field-validators';
import { Button } from 'shared';
import { connect } from 'react-redux';

export class InsuranceInfoForm extends React.Component {

  constructor(props) {
    super(props);
    this.insurerName = React.createRef();
    this.primaryInsurerName = React.createRef();
    this.insuranceId = React.createRef();
    this.insurerGroupId = React.createRef();
    this.careType = React.createRef();
    this.expirationDate = React.createRef();
    this.phoneNumber = React.createRef();
    this.notes = React.createRef();
  }

  componentDidMount() {
    let props = this.props.initialValues;
    this.props.initialize({
      id: props.id,
      insurerName: props.insurerName,
      primaryInsurerName: props.primaryInsurerName,
      insuranceId: props.insuranceId,
      insurerGroupId: props.insurerGroupId,
      expirationDate: props.expirationDate,
      careType: props.careType,
      phoneNumber: props.phoneNumber,
      notes: props.notes
    });
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <Field
            label={local.healthInfo.addInsurance.name}
            autoFocus
            ref={this.insurerName}
            name="insurerName"
            component={TextField}
            validate={[validators.required]} />
        </div>
        <div className="form-row">
          <Field
            label={local.healthInfo.addInsurance.personName}
            name="primaryInsurerName"
            ref={this.primaryInsurerName}
            component={TextField}
            validate={[validators.required]} />
        </div>
        <div className="form-row">
          <Field
            label={local.healthInfo.addInsurance.id}
            name="insuranceId"
            ref={this.insuranceId}
            component={TextField} />
        </div>
        <div className="form-row">
          <Field
            label={local.healthInfo.addInsurance.groupID}
            name="insurerGroupId"
            ref={this.insurerGroupId}
            component={TextField} />
        </div>
        <div className="form-row">
          <Field
            label={local.healthInfo.addInsurance.careType}
            name="careType"
            ref={this.careType}
            component={TextField} />
        </div>
        <div className="form-row">
          <DateTimeTextFieldWrapper
            label={local.healthInfo.addInsurance.expirationDate}
            name="expirationDate"
            ref={this.expirationDate} />
        </div>
        <div className="form-row">
          <Field
            label={local.healthInfo.addInsurance.insurerPhNo}
            name="phoneNumber"
            ref={this.phoneNumber}
            component={TextField} />
        </div>
        <div className="form-row">
          <Field
            label={local.healthInfo.addInsurance.notes}
            name="notes"
            ref={this.notes}
            component={TextField} />
        </div>
        <div className="form-row">
          <Button
            id="profile-form-submit"
            className="btn btn-primary"
            submit
            color="blue">
            Save
          </Button>
        </div>
      </form>
    );
  }
}
InsuranceInfoForm.propTypes = {
  // redux
  handleSubmit: PropTypes.func,
  saving: PropTypes.bool.isRequired,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool
};

const form = reduxForm({
  form: 'provider-info',
  initialValues: {},
  keepDirtyOnReinitialize: true,
  enableReinitialize: true
})(InsuranceInfoForm);

function mapStateToProps(state) {
  return {
    saving: state.medication.saving
  };
}

export default connect(mapStateToProps, null)(form);

