import moment from 'moment';
import local from 'services/localization/local';
import { getObservationData } from 'health/observations/observation-selectors';
import { ObservationType } from 'services/enums';
import styleVars from 'styles/_variables.scss';
import { getData } from 'app/request/persistent-data-selectors';
import { types } from 'health/health-types';
import { compareDatesAscending } from 'services/date-service';

export const getBloodPressureData = (state, props) => {
  const systolicData = getObservationData(state, ObservationType.Systolic).filter(x => x.profileId === props.profileId && x.groupingId);
  const diastolicData = getObservationData(state, ObservationType.Diastolic).filter(x => x.profileId === props.profileId && x.groupingId);

  const systolicDataEhr = getObservationData(state, ObservationType.Systolic).filter(x => x.profileId === props.profileId && x.id);
  const diastolicDataEhr = getObservationData(state, ObservationType.Diastolic).filter(x => x.profileId === props.profileId && x.id);

  const bloodPressureData = systolicData.reduce((accum, systolicObservation) => {
    const diastolicObservationMatch = diastolicData.find(x => x.groupingId === systolicObservation.groupingId);

    if (diastolicObservationMatch) {
      const bloodPressureObservation = {
        ...systolicObservation,
        valueDiastolic: diastolicObservationMatch.value,
        idDiastolic: diastolicObservationMatch.id
      };
      accum.push(bloodPressureObservation);
    }

    return accum;
  }, []);

  const bloodPressureDataEhr = systolicDataEhr.reduce((accum, systolicObservation) => {
    const diastolicObservationMatch = diastolicDataEhr.find(x => x.id === systolicObservation.id);

    if (diastolicObservationMatch) {
      const bloodPressureObservation = {
        ...systolicObservation,
        valueDiastolic: diastolicObservationMatch.value,
        idDiastolic: diastolicObservationMatch.id
      };
      accum.push(bloodPressureObservation);
    }

    return accum;
  }, []);
  bloodPressureData.push(...bloodPressureDataEhr);
  return bloodPressureData;

};
export const getBloodPressurePathway = (systolicPathwayData, diastolicPathwayData) => {

  const bloodPressureData = systolicPathwayData.reduce((accum, systolicObservation) => {
    const diastolicObservationMatch = diastolicPathwayData.find(x => x.groupingId === systolicObservation.groupingId);

    if (diastolicObservationMatch) {
      const bloodPressureObservation = {
        ...systolicObservation,
        valueDiastolic: diastolicObservationMatch.value,
        idDiastolic: diastolicObservationMatch.id
      };
      accum.push(bloodPressureObservation);
    }

    return accum;
  }, []);

  return bloodPressureData;

};
export const getBloodPressureChartData = (state, props) => {
  const bpData = props.fromPathway && props.systolicPathwayData && props.systolicPathwayData.length > 0   ? props.systolicPathwayData.sort((a, b) => compareDatesAscending(a.recordedAt, b.recordedAt)): getBloodPressureData(state, props).sort((a, b) => compareDatesAscending(a.recordedAt, b.recordedAt));
  const initialChartData = {
    labels: [],
    datasets: [
      {
        label: local.observation.bloodPressure.diastolicChartLabel,
        data: [],
        backgroundColor: 'transparent'
      },
      {
        label: local.observation.bloodPressure.systolicChartLabel,
        data: [],
        backgroundColor: styleVars.colorTeal50,
        borderColor: styleVars.colorTeal,
        borderSkipped: false,
        borderWidth: { top: 2, right: 0, bottom: 2, left: 0 }
      }
    ],
    observations: []
  };

  const chartData = bpData.reduce((accum, observation) => {
    const date = moment(observation.recordedAt).format('M/D/YY');
    accum.labels.push(date);
    accum.observations.push(observation);
    accum.datasets[0].data.push(observation.valueDiastolic);
    accum.datasets[1].data.push(observation.value);
    return accum;
  }, initialChartData);
  return chartData;
};

export const getBloodPressureAverage = (state,props) =>{
  const result = {
    Average: '0/0',
    MinValue: '0/0',
    MaxValue: '0/0'
  };
  function getLastWeeksDate() {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
  }

  const bpData = props.fromPathway && props.systolicPathwayData && props.systolicPathwayData.length >0 ? props.systolicPathwayData: getBloodPressureData(state, props);
  const getLastWeekDate = moment(getLastWeeksDate()).format(moment.HTML5_FMT.DATE);
  let lastWeekData = props.fromPathway ? bpData : bpData.filter(item => moment(item.recordedAt).format(moment.HTML5_FMT.DATE) > getLastWeekDate);

  if(lastWeekData && lastWeekData.length > 0 ) {
    const systolicAverage = lastWeekData.reduce((total, systolic) => total + systolic.value, 0) / lastWeekData.length;
    const diastolicAverage = lastWeekData.reduce((total, diastolic) => total + diastolic.valueDiastolic, 0) / lastWeekData.length;

    if(systolicAverage && diastolicAverage) {
      result.Average = `${ Math.round(systolicAverage)}/${ Math.round(diastolicAverage)}`;
    }
  }

  const systolicData = bpData.map(item => item.value);
  const diastolicData = bpData.map(item => item.valueDiastolic);

  if(bpData && bpData.length > 0 ){
    const systolicMinValue = Math.min(...systolicData);
    const systolicMaxvalue = Math.max(...systolicData);
    const diastolicMinValue = Math.min(...diastolicData);
    const diastolicMaxValue =  Math.max(...diastolicData);

    if(systolicMinValue && diastolicMinValue){
      result.MinValue = `${ Math.round(systolicMinValue)}/${ Math.round(diastolicMinValue)}`;
    }

    if(systolicMaxvalue && diastolicMaxValue){
      result.MaxValue = `${ Math.round(systolicMaxvalue)}/${ Math.round(diastolicMaxValue)}`;
    }
  }
  return result;
};

export const getActiveSystolicGoal = (state, profileId) => {
  const goals = getData(state.health, types.SYSTOLIC_GOALS, profileId);

  if(goals && goals.length > 0){
    const activeGoal = goals.find(g => g.isActive);
    if(activeGoal){
      const result = {};
      result.systolicMin = activeGoal?.fromValue;
      result.systolicMax = activeGoal?.toValue;
      return result;
    }
  }
  return null;
};

export const getActiveDiastolicGoal = (state, profileId) => {
  const goals = getData(state.health, types.DIASTOLIC_GOALS, profileId);

  if(goals && goals.length > 0){
    const activeGoal = goals.find(g => g.isActive);
    if(activeGoal){
      const result = {};
      result.diastolicMin = activeGoal?.fromValue;
      result.diastolicMax = activeGoal?.toValue;
      return result;
    }
  }
  return null;
};

export const getActiveGoal = (state, profileId) => {
  const systolicData  =  getActiveSystolicGoal(state, profileId);
  const diastolicData  = getActiveDiastolicGoal(state, profileId);
  if(systolicData && diastolicData){
    const latestData = {};
    latestData.systolicMin = systolicData?.systolicMin;
    latestData.systolicMax = systolicData?.systolicMax;
    latestData.diastolicMin = diastolicData?.diastolicMin;
    latestData.diastolicMax = diastolicData?.diastolicMax;
    return latestData;
  }
};

