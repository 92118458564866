import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadProgramDetails, loadReferrals, resetDetailsLoadCount, loadHasEnrolledInActiveEventProgram, loadResourceRatingDetails } from 'programs/programs-actions';
import { getUniqueId } from 'services/helpers';
import { getProfileId } from 'app/selectors';
import * as selectors from 'programs/programs-selectors';
import { routePaths } from 'services/routing';
import { ProfileRedirect } from 'shared';
import ProgramDetailsPage from './ProgramDetailsPage';
import { getHasEnrolledInActiveEventProgram } from 'programs/programs-selectors';
import PropTypes from 'prop-types';
import useEnrollmentOutdated from 'programs/shared/useEnrollmentOutdated';

const maxLoadAttempts = 3;

export default function ProgramDetailsContainer(props) {
  const dispatch = useDispatch();
  const programEnrollmentId = selectors.getProgramEnrollmentIdFromRoute(props);
  const requestId = useRef(getUniqueId());
  const retryTimeout = useRef(1000);
  const loadAttempts = useRef(0);
  const initialProfileId = useRef(null);
  const initialEnrollmentId = useRef(programEnrollmentId);
  const programId = selectors.getProgramIdFromRoute(props);
  const profileId = useSelector(state => getProfileId(state));
  const resetLoadAttempts = useSelector(state => state.programs.resetDetailsLoadCount);
  const details = useSelector(state => selectors.getProgramDetails(state, programEnrollmentId));
  const pageStatus = useSelector(state => selectors.getProgramDetailsPageStatus(state, programEnrollmentId));
  const status = useSelector(state => selectors.getProgramDetailsStatus(state, programEnrollmentId));
  const hasConditionalTask = details?.tasks?.some(x => x.isConditionallyCompletable === true);
  const enrollmentComplete = details && details.complete;
  const enrollment = useSelector(state => selectors.getProgramEnrollmentById(state, programEnrollmentId));
  const hasEnrolledInActiveEventProgram = useSelector(state => getHasEnrolledInActiveEventProgram(state, programId));
  const organizationProgramsStatus = useSelector(selectors.getOrganizationProgramsStatus);
  const organizationList = organizationProgramsStatus?.data?.filter(x => x.program.programId == programId);
  const enrollmentOutdated = useEnrollmentOutdated(programEnrollmentId);

  var organizationId;
  if (organizationList?.length > 0) {
    organizationId = organizationList[0].organizationId;
  }

  const doLoadProgramDetails = () => {
    dispatch(loadProgramDetails(programEnrollmentId));
    loadAttempts.current = loadAttempts.current + 1;
  };
  useEffect(() => {
    dispatch(loadResourceRatingDetails(programEnrollmentId, profileId, organizationId));
  }, []);

  useEffect(() => {
    doLoadProgramDetails();
    initialProfileId.current = profileId;
    dispatch(loadHasEnrolledInActiveEventProgram(profileId, programId));
  }, []);

  useEffect(() => {
    if (initialEnrollmentId.current !== programEnrollmentId) {
      initialEnrollmentId.current = programEnrollmentId;
      dispatch(resetDetailsLoadCount(true));
    }
  }, [programEnrollmentId]);

  useEffect(() => {
    if (resetLoadAttempts) {
      loadAttempts.current = 0;
      retryTimeout.current = 500;
      dispatch(resetDetailsLoadCount(false));
    }
  }, [resetLoadAttempts]);

  useEffect(() => {
    if (details && details.complete) {
      dispatch(loadReferrals(profileId, programEnrollmentId));
    }
  }, [enrollmentComplete]);

  useEffect(() => {
    if (loadAttempts.current > maxLoadAttempts && !details?.processingTasks) {
      return;
    }

    if (details && !status.loading && status.loaded && (details.processingTasks || hasConditionalTask)) {
      const timeoutId = setTimeout(doLoadProgramDetails, retryTimeout.current);
      retryTimeout.current = retryTimeout.current * 2;
      return () => clearTimeout(timeoutId);
    }
  }, [programEnrollmentId, status.loading, status.loaded, details?.processingTasks, hasConditionalTask]);

  if (initialProfileId.current && initialProfileId.current !== profileId) {
    return <ProfileRedirect to={routePaths.programsEnroll} params={{ programId }} backpath={{ wasProfileSwitched: props.location?.state?.wasProfileSwitched }} />;
  }

  const wasProfileSwitched = props.location?.state?.wasProfileSwitched || props.location?.state?.backpath?.wasProfileSwitched;

  if (wasProfileSwitched != true && props.location?.state?.backpath?.fromLeaderBoard != true
    && hasEnrolledInActiveEventProgram && hasEnrolledInActiveEventProgram.data && hasEnrolledInActiveEventProgram.data.hasEnrolled == true) {
    return <ProfileRedirect to={routePaths.programLeaderBoard} params={{ programId, programEnrollmentId }} backpath={routePaths.root} />;
  }

  return (
    <ProgramDetailsPage
      enrollment={enrollment}
      requestId={requestId.current}
      status={pageStatus}
      programEnrollmentId={programEnrollmentId}
      programId={programId}
      details={details}
      profileId={profileId}
      organizationId={organizationId}
      fromDashboard={props.location?.state?.backpath?.fromDashboard}
      enrollmentOutdated={enrollmentOutdated} />
  );
}

ProgramDetailsContainer.propTypes = {
  location: PropTypes.shape()
};
