import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { routePaths } from 'services/routing';
import { getProfileId } from 'app/selectors';
import { DataLoadingStatus, Page, ProfileRedirect } from 'shared';
import { selectProfile } from 'profiles/selected/selected-profile-actions';
import OrganizationInviteDetails from './OrganizationInviteDetails';
import * as selectors from 'organizations/organizations-selectors';
import { getActiveUsersProgramInvites } from '../../../programs/programs-selectors';
import { loadActiveUsersProgramInvites } from '../../../programs/programs-actions';
import { loadOrganizationInvites, loadOrganizationDetails } from 'organizations/organizations-actions';
import { getIdentityId } from 'identity/selectors';
import local from 'services/localization/local';

export default function OrganizationInviteDetailsPage({ match: { params: { organizationId, membershipId, hasProperties } } }) {
  const { loading, loaded, hasError } = useSelector(selectors.getOrganizationInvites);
  const selectedProfileId = useSelector(getProfileId);
  const invite = useSelector(state => selectors.getInviteByMembershipId(state, membershipId));
  const memberships = useSelector(state => selectors.getOrganizationDetails(organizationId, state));
  const isReadOnlyProfile = invite && !!invite.profileId;
  const inviteProfileId = invite ? invite.profileId : null;
  const dispatch = useDispatch();
  const identityId = useSelector(getIdentityId);

  const permissions = useSelector(state => getActiveUsersProgramInvites(state));
  const pathwayInviteDetails = permissions && permissions.filter(x => x.memberId === membershipId)[0];

  useEffect(() => {
    dispatch(loadActiveUsersProgramInvites(identityId));
  }, [identityId]);

  useEffect(() => {
    if (!loading && !loaded && !hasError) {
      dispatch(loadOrganizationInvites());
    }
  }, [membershipId]);

  useEffect(() => {
    if (inviteProfileId && inviteProfileId !== selectedProfileId) {
      dispatch(selectProfile(inviteProfileId));
    }
  }, [selectedProfileId, inviteProfileId]);

  useEffect(() => {
    dispatch(loadOrganizationDetails(organizationId));
  }, [organizationId]);

  if (!invite && loaded && !pathwayInviteDetails) {
    return <ProfileRedirect to={routePaths.people} />;
  }

  return (
    <Page
      id='organization-invite-details'
      readOnlyProfile={isReadOnlyProfile}
      centerElement={local.organizations.organizationInviteHeader}
      backPath={{ to: routePaths.people }}>
      <DataLoadingStatus
        loaded={loaded && memberships.loaded}
        loading={loading && memberships.loading}
        error={hasError || memberships.hasError}>
        <OrganizationInviteDetails invite={invite} hasProperties={!!hasProperties} pathwayInvite={pathwayInviteDetails} />
      </DataLoadingStatus>
    </Page>
  );
}

OrganizationInviteDetailsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      organizationId: PropTypes.string.isRequired,
      membershipId: PropTypes.string.isRequired,
      hasProperties: PropTypes.string
    }).isRequired
  }).isRequired
};
