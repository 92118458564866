import {
  UPDATE_EMAIL_ADDRESS,
  CHANGE_EMAIL_ERROR,
  FORCE_SIGN_OUT,
  LAST_VERIFICATION_CODE_SENT
} from './action-names';

export function updateEmailAddress(emailId)
{
  return{
    type:UPDATE_EMAIL_ADDRESS,
    payload:emailId
  };
}

export function changeEmailError(message)
{
  return {
    type: CHANGE_EMAIL_ERROR,
    payload : message
  };
}

export function forceSignOut(message)
{
  return {
    type: FORCE_SIGN_OUT,
    payload : message
  };
}

export function lastVerificationCodeSent(sentDate)
{
  return{
    type:LAST_VERIFICATION_CODE_SENT,
    payload:sentDate
  };
}


