// Client
export const ToastStyle = {
  Processing: 1,
  Info: 2,
  Success: 3,
  Error: 4
};

export const AlertSeverityStatus = {
  Unknown: 0,
  Verified: 1,
  NotVerified: 2
};

// Server
export const ErrorCode = {
  General: 1000,
  NoHipaaConsent: 1005,
  HipaaConsentExpired: 1006,
  HipaaConsentVersionOutdated: 1007,
  InviteAlreadyExists: 1101,
  InviteSelf: 1102,
  InviteUsed: 1103,
  InviteExistingRelationship: 1104,
  InviteNotFound: 1105,
  RegisterPasswordError: 1106,
  RegisterPasswordStrengthError: 1107,
  RegisterUserExistsError: 1108,
  RegisterUserInvalidEmailFormat: 1109,
  IdMeAccountExists: 1110
};

export const HealthDataSource = {
  Unknown: 0,
  SelfReported: 1,
  Validic: 2,
  HealthGorilla: 3,
  Terra:4
};

export const Unit = {
  Unknown: 0,
  BeatsPerMinute: 1,
  Celsius: 2,
  Fahrenheit: 3,
  Centimeters: 4,
  Inches: 5,
  Kilograms: 6,
  Pounds: 7,
  Meters: 8,
  Count: 9,
  Percent: 10,
  InternationalUnit: 11,
  LiterPerMinute: 12,
  GramsPerDeciliter: 13,
  MilligramsPerDeciliter: 14,
  MillimolesPerLiterGlucose: 15,
  MillimetersOfMercury: 16,
  CubicMetersPerSecond: 17,
  Pascal: 18,
  Seconds:19,
  Calories: 20,
  Grams: 21
};

export const ObservationType = {
  None: 0,
  InstantaneousHeartRate: 1,
  BodyTemperature: 2,
  Height: 3,
  Weight: 4,
  Steps: 5,
  BodyArms: 6,
  BodyChest: 7,
  BodyFat: 8,
  BodyHips: 9,
  BodyThighs: 10,
  BodyWaist: 11,
  BloodOxygen: 12,
  Insulin: 13,
  PeakFlow: 14,
  BloodGlucose: 15,
  Systolic: 16,
  Diastolic: 17,
  Immunization:18,
  Sleep:19,
  Nutrition: 20,
  LabResult: 21,
  FloorsClimbed: 22,
  BloodKetone: 23,
  HeartRateVariability: 24,
  Exercise: 25
};

export const ObservationImmunizationSubType = {
  Covid19VaccinationProof: 1
};

export const ObservationLabResultSubType = {
  Covid19TestProof: 1
};

export const FilePreviewAction = {
  Previous: 1,
  Next: 2
};

export const IntensityType = {
  None: 0,
  Low: 1,
  Medium: 2,
  High: 3
};

export const ActivityType = {
  None: 0,
  Workout: 1
};

export const NotificationType = {
  ReadOnly: 0,
  InternalUrl: 1,
  ExternalUrl: 2
};

export const TaskType = {
  Checklist: 1,
  Observation: 2,
  Resource: 3,
  Interview: 4,
  Goal: 5
};

export const InterviewContentType = {
  Static: 1,
  Boolean: 2,
  Select: 3
};

export const healthDataType = {
  ObservationTypeInstantaneousHeartRate: 1001,
  ObservationTypeBodyTemperature: 1002,
  ObservationTypeHeight: 1003,
  ObservationTypeWeight: 1004,
  ObservationTypeSteps: 1005,
  ObservationTypeBodyArms: 1006,
  ObservationTypeBodyChest: 1007,
  ObservationTypeBodyFat: 1008,
  ObservationTypeBodyHips: 1009,
  ObservationTypeBodyThighs: 1010,
  ObservationTypeBodyWaist: 1011,
  ObservationTypeBloodOxygen: 1012,
  ObservationTypeInsulin: 1013,
  ObservationTypePeakFlow: 1014,
  ObservationTypeBloodGlucose: 1015,
  ObservationTypeSystolic: 1016,
  ObservationTypeDiastolic: 1017,
  ObservationTypeImmunization:1018,
  ObservationTypeSleep:1019,
  ObservationTypeNutrition:1020,
  ObservationLabResults:1021,
  ObservationTypeFloorsClimbed:1021,
  ObservationTypeBloodKetone:1023,
  Medication: 2001,
  MedicationDose: 2002,
  Activity: 3001,
  Condition: 4001,
  Encounter: 4002,
  Procedure: 4003,
  Immunization: 4004,
  DiagnosticReport: 4005,
  CarePlan: 4006,
  JournalEntry: 5001
};

export const OrganizationMemberStatus = {
  None: 0,
  Pending: 1,
  Declined: 2,
  Active: 3,
  Removed: 4
};

export const OrganizationMemberRole = {
  None: 0,
  Admin: 1,
  Member: 2
};

export const MetricAlertSeverity = {
  Unknown: 0,
  None: 1,
  Low: 2,
  Medium: 3,
  High: 4,
  Critical: 5,
  Urgent: 6
};

export const ProgramCompletionCode = {
  Complete: 1,
  Unenrolled: 2,
  Upgraded: 3
};

export const ProgramVisibility = {
  Unknown: 0,
  Hidden: 1,
  Private: 2,
  Public: 3
};

export const ProgramTabStoreName = 'pathwayTabName';

export const InvitedProgramId = 'programId';

export const ProgramTabName = {
  Active: 'Active',
  Invited: 'Invited',
  History: 'History',
  Browse: 'Browse'
};

export const ProgramTabCode = {
  Invited: 1,
  History: 3
};

export const ProgramInviteAction = {
  Accept: 1,
  Decline: 2
};

export const ProgramTabStatus = {
  Active: 'yes',
  InActive: 'no'
};

export const ProfileRelationshipType = {
  Unknown: 0,
  Self: 1,
  Owner: 2,
  Shared: 3
};

export const IdentityVerificationErrorCode = {
  NoError: 0,
  ProfileAlreadyExists: 1100,
  ProfileAttemptLimitExceeded: 1101,
  IdentityAttemptLimitExceeded: 1102,
  ConnectionFailedPreSSN: 1103,
  MissingProfileData: 1104,
  ConnectionFailedPostSSN: 1200,
  VerificationFailed: 1300,
  NoRecordsFound: 1400,
  ImportRecordsFailed: 1500,
  NoNewRecordsFound: 1600
};

export const OrganizationStatus = {
  Pending: 0,
  Active: 1,
  Deleted: 2,
  Declined: 3,
  PaymentPending: 4
};

export const BadgeCategoryType = {
  MileStone: 1,
  Explorer: 2,
  Challenge: 3
};

export const BadgeSubCategoryType = {
  TaskCompletion: 1,
  General: 2
};

export const BadgeType = {
  TaskFirst : 1,
  Task10 : 2,
  Task50 : 3,
  Task100 : 4,
  Task500 : 5,
  Task1000 : 6,
  DeviceConnected : 7,
  ProfileCompleted : 8,
  ChallengeCompleted: 9,
  ProgramChallenge: 10
};

export const RecurringType = {
  Unknown : 0,
  Daily : 1,
  Monthly : 2
};

export const Application = {
  None : 0,
  Dash : 1,
  Covid19 : 2,
  ProviderPortal : 3,
  MemberApp : 4,
  Studio : 5
};

export const PaymentStatus = {
  None : 0,
  Initiated : 1,
  RequestedToPaymentGateway : 2,
  ReceivedAtPaymentGateway : 3,
  RedirectingToPaymentProvider : 4,
  Success : 5,
  Failure : 6
};

export const KeyType = {
  OrganizationId : 0,
  ProfileId : 1
};

export const RecursiveInterval = {
  None : 0,
  Day : 1,
  Week : 2,
  Month : 3,
  Year : 4
};

export const CompletionScreenUpon = {
  TaskCompletion : '1',
  PathwayCompletion : '2'
};

export const Feature = {
  Unknown : 0,
  CalciumCoach : 1
};

export const EnrollTypes = {
  ManuallEnroll : 1,
  DoNotEnroll : 2,
  AutomaticEnroll : 3
};

export const HealthEventType = {
  Observation : 1,
  HealthIndex : 2
};

export const AccountDeletionStatus ={
  Inprogress : 1,
  Deleted : 2
};

export const ABSegment = {
  Group_A : 1,
  Group_B : 2,
  Group_C : 3
};

export const ObservationDetailType = {
  None : 0,
  Protein : 1,
  Fat : 2,
  Carbohydrate : 3
};

export const ObservationDetailTypeValue = {
  None: 1,
  Protein: 4,
  Fat: 9,
  Carbohydrate: 4
};

export const GoalType = {
  None : 0,
  FixedMinValue: 1,
  RangeValue: 2
};

// Based on Hl7.Fhir.Model
export const FhirResourceTypes = {
  CarePlan: 13,
  Condition: 27,
  DiagnosticReport: 39,
  Encounter: 44,
  Immunization: 62,
  Observation: 96,
  Procedure: 110
};

export const WhiteLabelProperties = {
  LogoUrl : 1,
  InviteBgColorCode: 2,
  InviteButtonColorCode: 3,
  InviteFontColorCode: 4
};

export const ObservationConnectionStatus = {
  Unknown : 0,
  DeviceConnected: 1,
  Manual: 2,
  Deactivated: 3
};

export const OrganizationFeatureType = {
  EHR : 1,
  InBody : 2,
  eClinicalWorks : 3,
  FHIRIntegration : 4,
  Chat : 5
};

export const PathwayChartsTabTame = {
  BloodGlucose:'Blood Glucose',
  BloodPressure:'Blood Pressure',
  Steps:'Steps',
  Nutrition: 'Nutrition'
};

export const GoalStatus = {
  Unknown: 0,
  Draft: 1,
  Active: 2
};
