/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Button } from 'shared';
import { SelectField } from 'shared/form-fields';
import * as validators from 'services/fields/field-validators';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { Link } from 'react-router-dom';
import DateTextFieldWrapper from 'shared/form-fields/DateTextFieldWrapper';

export function UserDetailsForm({ submitting, pristine, handleSubmit, skipDetails,initialValues }) {
  const isDisabled = () =>{
    let isSubmitDisabled = submitting || pristine;
    return  (initialValues.dateOfBirth || initialValues.gender) ? false : isSubmitDisabled;
  };
  return (
    <form onSubmit={handleSubmit} className="px-0 pb-0" data-testid="profile-details">
      <div className="form-row">
        <Field
          readOnly={submitting}
          label={local.Registration.genderExplanation}
          name="gender"
          data-testid="userGender"
          component={SelectField}
          placeholder={local.selectPlaceholder}>
          <option value="1">{local.genderMale}</option>

          <option value="2">{local.genderFemale}</option>

          <option value="3">{local.genderOther}</option>

          <option value="0">{local.genderUnknown}</option>
        </Field>
      </div>

      <div className="form-row">
        <DateTextFieldWrapper
          readOnly={submitting}
          label={local.formBirthdateLabel}
          validate={[validators.birthdate]}
          data-testid="userDateOfBirth"
          name="dateOfBirth" />
      </div>

      <div className="form-row">
        <Button
          id="profile-details"
          className="btn btn-primary"
          submit
          color="blue"
          disabled={isDisabled()}>
          {local.complete}
        </Button>
      </div>

      <div onClick={skipDetails}>
        <Link to={routePaths.root} id='user-details-link'>{local.skip}</Link>
      </div>

      <div className="form-row">
      </div>
    </form>
  );
}

UserDetailsForm.displayName = 'User Details Form';
UserDetailsForm.propTypes = {
  // redux form
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  skipDetails: PropTypes.func,
  initialValues:PropTypes.object
};

export default reduxForm({
  form: 'profile-details',
  enableReinitialize: true
})(UserDetailsForm);
