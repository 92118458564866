import React from 'react';
import { ModalWrapper, Button, Icon } from 'shared';
import local from 'services/localization/local';
import './ehr.scss';


export default function IdMeVerificationErrorModal(props) {
  return (
    <ModalWrapper isOpen={props.isConfirmOpen} onRequestClose={props.closeConfirm}>
      <div id="quit-pathway-modal" className="modal-container">
        <div className="modal-header">
          <Button id="close-quit-pathway-button" onClick={props.closeConfirm} data-testid='close-quit-pathway-button'><Icon symbol="times" /></Button>
          <Icon symbol="healthprograms" />
          <p></p>
        </div>
        <div className="modal-body">
          <div className="content">
            <h4>ID.me IAL2 verification Failed</h4>
            <br />
            <p>You have opted not to complete the ID.me IAL2 verification process. As a result, access to this service or feature cannot be granted.</p>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}
