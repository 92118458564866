import React from 'react';
import PropTypes from 'prop-types';
import { LinkWrapper, ErrorSection, NonAuthenticatedPageWall } from 'shared';
import local from 'services/localization/local';
import PrivacyPolicyEulaSection from 'app/privacy-policy-eula/PrivacyPolicyEulaSection';
import RegisterForm from './RegisterForm';

class Register extends React.Component {
  render() {
    const { registering, errorMessage, handleSubmit } = this.props;
    const privacyPolicySection = <PrivacyPolicyEulaSection buttonNameText={local.join} />;
    const navAction = <LinkWrapper id='already-have-account-sign-in-nav' className="button btn btn-secondary" to="/">{local.linkSignIn}</LinkWrapper>;
    const content = local.nonAuthWallPage;
console.log(errorMessage);
    return (
      <NonAuthenticatedPageWall id="register" titleTabText={local.register} navAction={navAction}>
        <h1>{local.registerSignup}</h1>
        <div className='RegSubContent'>
          <small>{content.subhead}</small>
        </div>
        <ErrorSection>{errorMessage}</ErrorSection>
        <div className='row'>
          <div className='signin-form-split'>
            <RegisterForm onSubmit={handleSubmit} registering={registering} privacyPolicySection={null} />
            <div className="secondary-links">
              {`${local.registerAlreadyHaveAccount} `}<LinkWrapper id='already-have-account-sign-in' to="/">{local.linkSignIn}</LinkWrapper>
            </div>
          </div>
          <div className='signin-form-split' style={{border:'none'}}>
            <div style={{marginTop:'37px'}}>
              Sign up with your secure Id.me account.
            </div>
            <div style={{marginTop:'40px', cursor:'pointer'}}>
              <img onClick={this.redirectToIdMe} height={45} src="/static/images/idmesignin.svg" alt="android iphone idme signin" />
            </div>
          </div>
        </div>
        <div style={{marginTop:'37px'}}>
          {privacyPolicySection}
        </div>
      </NonAuthenticatedPageWall>
    );
  }
}

Register.displayName = 'Register';
Register.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  registering: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string
};

export default Register;
