import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, change, untouch } from 'redux-form';
import { TextField } from 'shared/form-fields';
import { Button, LinkWrapper } from 'shared';
import local from 'services/localization/local';
import * as validators from 'services/fields/field-validators';
import Switch from 'react-switch';
import { Unit, ObservationDetailTypeValue } from 'services/enums';
import { useSelector } from 'react-redux';
import { getActiveNutritionGoal } from './nutrition-selector';
import { getProfileId } from 'app/selectors';
import { ConvertGramsToPercent, ConvertActualPercentToGrams } from 'services/helpers';
import { calories, macroGoal } from 'services/fields/field-normalizers';
import { MacroFields } from 'services/constants';
import NutritionMacroDetails from './NutritionMacroDetails';


function NutritionGoalForm(props) {
  const profileId = useSelector(getProfileId);
  const { handleSubmit, isProcessing, backPath, hasError, weeklyAverageData, setMacroGoal, unitType, initialValues } = props;
  const content = local.observation.nutritions;
  const [checked, setChecked] = useState(unitType === Unit.Grams ? false : true);
  const [unit, setUnit] = useState(unitType === Unit.Grams ? Unit.Grams : Unit.Percent);
  const isObservationGoal = (props.isObservationGoal != undefined) ? props.isObservationGoal : false;
  const activeGoal = !isObservationGoal ? useSelector(state => getActiveNutritionGoal(state, profileId)) : initialValues;
  const goalPercentage = setGoalPercentage();
  const [macroPercentage, setMacroPercentage] = useState(goalPercentage);
  const [macroGram, setMacroGram] = useState(goalPercentage);

  useEffect(() => {
    if (isObservationGoal) {
      dispatchEventtoObservationGoalForm();
      dispatchEventNutritionFormErrorFlag(true);
    }
  }, [checked]);

  //Note : This function will help us to set percentage label for grams unit preference
  function setGoalPercentage() {
    if(!isObservationGoal){
      let result = {
        carbohydrates: unitType === Unit.Grams ? activeGoal?.carbs?.toFixed(0) : ConvertActualPercentToGrams(initialValues.calorieGoal, activeGoal?.carbs ?? initialValues.carbs, ObservationDetailTypeValue.Carbohydrate),
        proteins: unitType === Unit.Grams ? activeGoal?.protein?.toFixed(0) : ConvertActualPercentToGrams(initialValues.calorieGoal, activeGoal?.protein ?? initialValues.protein, ObservationDetailTypeValue.Protein),
        fats: unitType === Unit.Grams ? activeGoal?.fat?.toFixed(0) : ConvertActualPercentToGrams(initialValues.calorieGoal, activeGoal?.fat ?? initialValues.fat, ObservationDetailTypeValue.Fat)
      };
      return result;
    }
  }

  function dispatchEventtoObservationGoalForm() {
    if (isObservationGoal) {
      if (!checked) {
        ConvertToPercentage();
      }
      else {
        ConvertToGram();
      }
    }
  }

  function dispatchEventNutritionFormErrorFlag(flag) {
    props.dispatchNutritionFormErrorFlag(flag);
  }

  function handleUnitPreferenceChange(isChecked) {
    if (!isObservationGoal) {
      nutritionFormPreferenceChange(isChecked);
    }
    else {
      observationGoalFormPreferenceChange(isChecked);
    }
    setChecked(!checked);
  }

  function nutritionFormPreferenceChange(isChecked) {
    if (isChecked == false) {
      setUnit(Unit.Grams);
      setMacroGoal({
        unit: Unit.Grams
      });
      setMacroPercentage(null);
      setMacroGram(null);
    }
    else {
      setUnit(Unit.Percent);
      setMacroGoal({
        unit: Unit.Percent
      });
      setMacroPercentage(null);
      setMacroGram(null);
    }
    dispatchEventsInNutritionGoalForm();
  }

  function dispatchEventsInNutritionGoalForm() {
    for (let i = 0; i < MacroFields.length; i++) {
      props.dispatch(change('nutritionGoal', MacroFields[i], null));
      props.dispatch(untouch('nutritionGoal', MacroFields[i]));
    }
  }

  function observationGoalFormPreferenceChange(isChecked) {
    if (isChecked == false) {
      setUnit(Unit.Grams);
      setMacroPercentage(null);
      setMacroGram(null);
    }
    else {
      setUnit(Unit.Percent);
      setMacroPercentage(null);
      setMacroGram(null);
    }
    dispatchEventsInNutritionGoalForm();
  }

  function ConvertToPercentage() {
    const { carbsFormValue, fatFormValue, proteinFormValue, calorieFormValue } = getNutritionGoalFormFieldValues();

    const carbs = isNaN(Number(carbsFormValue)) === true ? 0 : Number(carbsFormValue);
    const fat = isNaN(Number(fatFormValue)) === true ? 0 : Number(fatFormValue);
    const protein = isNaN(Number(proteinFormValue)) === true ? 0 : Number(proteinFormValue);
    let totalGrams = carbs + fat + protein;

    setMacroPercentage(
      {
        carbohydrates: ConvertGramsToPercent(carbs, totalGrams).displayValue,
        proteins: ConvertGramsToPercent(protein, totalGrams).displayValue,
        fats: ConvertGramsToPercent(fat, totalGrams).displayValue
      }
    );

    let result = {
      carbs: ConvertGramsToPercent(carbs, totalGrams).actualValue,
      protein: ConvertGramsToPercent(protein, totalGrams).actualValue,
      fat: ConvertGramsToPercent(fat, totalGrams).actualValue,
      unit: Unit.Grams,
      totalGrams: totalGrams
    };
    if (!isObservationGoal) {
      setMacroGoal(result);
    }
    else {
      let displayUnit = Unit.Grams;
      dispatchNutritionFormDatatoObservationForm(calorieFormValue, displayUnit, result.carbs, result.fat, result.protein, totalGrams);
    }
  }

  function getNutritionGoalFormFieldValues() {
    let calorieFormValue = 0;
    let carbsFormValue = 0;
    let fatFormValue = 0;
    let proteinFormValue = 0;
    const calorieFormValueElement = document.getElementsByName('calorieGoal');
    const carbsFormValueElement = document.getElementsByName('carbs');
    const fatFormValueElement = document.getElementsByName('fat');
    const proteinFormValueElement = document.getElementsByName('protein');

    if (calorieFormValueElement.length > 0) {
      calorieFormValue = calorieFormValueElement[0].value;
    }
    if (carbsFormValueElement.length > 0) {
      carbsFormValue = carbsFormValueElement[0].value;
    }
    if (fatFormValueElement.length > 0) {
      fatFormValue = fatFormValueElement[0].value;
    }
    if (proteinFormValueElement.length > 0) {
      proteinFormValue = proteinFormValueElement[0].value;
    }

    return { carbsFormValue, fatFormValue, proteinFormValue, calorieFormValue };
  }

  function ConvertToGram() {
    const { carbsFormValue, fatFormValue, proteinFormValue, calorieFormValue } = getNutritionGoalFormFieldValues();

    const calorieGoal = isNaN(Number(calorieFormValue)) === true ? 0 : Number(calorieFormValue);
    const carbs = isNaN(Number(carbsFormValue)) === true ? 0 : Number(carbsFormValue);
    const fat = isNaN(Number(fatFormValue)) === true ? 0 : Number(fatFormValue);
    const protein = isNaN(Number(proteinFormValue)) === true ? 0 : Number(proteinFormValue);

    setMacroGram(
      {
        carbohydrates: ConvertActualPercentToGrams(calorieGoal, carbs, ObservationDetailTypeValue.Carbohydrate),
        proteins: ConvertActualPercentToGrams(calorieGoal, protein, ObservationDetailTypeValue.Protein),
        fats: ConvertActualPercentToGrams(calorieGoal, fat, ObservationDetailTypeValue.Fat)
      }
    );
    if (isObservationGoal) {
      let displayUnit = Unit.Percent;
      dispatchNutritionFormDatatoObservationForm(calorieFormValue, displayUnit, carbsFormValue, fatFormValue, proteinFormValue, 0);
    }
  }

  function dispatchNutritionFormDatatoObservationForm(calorieFormValue, displayUnit, carbsFormValue, fatsFormValue, proteinFormValue, totalGrams) {
    let inputs = { calorieGoal: calorieFormValue, displayUnit, carbs: carbsFormValue, fat: fatsFormValue, protein: proteinFormValue, totalGrams: totalGrams, formTouch: true };
    props.disptachNutritionData(inputs);
    var invalidFlag = validators.required(calorieFormValue) != undefined || validators.required(carbsFormValue) != undefined || validators.required(fatsFormValue) != undefined || validators.required(proteinFormValue) != undefined || validators.minValue1(calorieFormValue) != undefined || validators.maxValue10000(calorieFormValue) != undefined ||
      validators.number(calorieFormValue) != undefined || validators.number(carbsFormValue) != undefined || validators.number(fatsFormValue) != undefined || validators.number(proteinFormValue) != undefined;
    dispatchEventNutritionFormErrorFlag(invalidFlag);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='flex-field'>
        <div className="form-row">
          {unit === Unit.Percent ?
            <Field
              readOnly={isProcessing}
              label={content.calorieGoal}
              autoFocus={isObservationGoal ? false : true}
              name="calorieGoal"
              component={TextField}
              type="tel"
              normalize={calories}
              onChange={ConvertToGram}
              onBlur={ConvertToGram}
              validate={[validators.required, validators.number, validators.minValue1, validators.maxValue10000]} /> :
            <Field
              readOnly={isProcessing}
              label={content.calorieGoal}
              autoFocus={isObservationGoal ? false : true}
              name="calorieGoal"
              component={TextField}
              type="tel"
              normalize={calories}
              onChange={ConvertToPercentage}
              onBlur={ConvertToPercentage}
              validate={[validators.required, validators.number, validators.minValue1, validators.maxValue10000]} />}
        </div>
      </div>
      <div className="form-row macro-goal">
        <label className='form-label'>{content.macroGoal}</label>
        <div>
          <Switch
            className="nutri-goal-switch"
            onChange={handleUnitPreferenceChange}
            onBlur={handleUnitPreferenceChange}
            checked={checked}
            uncheckedIcon={<div className='unchecked-text'>%</div>}
            checkedIcon={<div className='checked-text'>g</div>}
            offColor="#E0E0E0"
            onColor="#E0E0E0"
            //offHandleColor="#4F4F4F"
            handleDiameter={20}
            activeBoxShadow="none" />
        </div>
      </div>

      <div className='flex-field'>
        <div className="form-row">
          {unit === Unit.Percent ?
            <Field
              readOnly={isProcessing}
              label={`${content.carbohydrate} (%)`}
              name="carbs"
              component={TextField}
              type="tel"
              forceShowError={hasError}
              onChange={ConvertToGram}
              onBlur={ConvertToGram}
              validate={[validators.number]} /> :
            <Field
              readOnly={isProcessing}
              label={`${content.carbohydrate} (g)`}
              name="carbs"
              onChange={ConvertToPercentage}
              onBlur={ConvertToPercentage}
              component={TextField}
              type="tel"
              normalize={macroGoal}
              validate={[validators.required, validators.number]} />}
        </div>
        <div className='col-space'></div>
        <div className="form-row">
          {unit === Unit.Percent ?
            <Field
              readOnly={isProcessing}
              label={`${content.fat} (%)`}
              name="fat"
              component={TextField}
              type="tel"
              forceShowError={hasError}
              onChange={ConvertToGram}
              onBlur={ConvertToGram}
              validate={[validators.number]} /> :
            <Field
              readOnly={isProcessing}
              label={`${content.fat} (g)`}
              name="fat"
              component={TextField}
              onChange={ConvertToPercentage}
              onBlur={ConvertToPercentage}
              type="tel"
              normalize={macroGoal}
              validate={[validators.required, validators.number]} />}
        </div>
        <div className='col-space'></div>
        <div className="form-row">
          {unit === Unit.Percent ?
            <Field
              readOnly={isProcessing}
              label={`${content.protein} (%)`}
              name="protein"
              component={TextField}
              type="tel"
              forceShowError={hasError}
              onChange={ConvertToGram}
              onBlur={ConvertToGram}
              validate={[validators.number]} /> :
            <Field
              readOnly={isProcessing}
              label={`${content.protein} (g)`}
              name="protein"
              component={TextField}
              onChange={ConvertToPercentage}
              onBlur={ConvertToPercentage}
              type="tel"
              normalize={macroGoal}
              validate={[validators.required, validators.number]} />}
        </div>
      </div>
      {unit === Unit.Percent &&
        <div className={`form-row text-center macro-info-text ${hasError ? 'error' : undefined}`}>
          <small>{content.macrosMessage}</small>
        </div>}
      {unit === Unit.Grams && macroPercentage ?
        <NutritionMacroDetails content={content} macro={macroPercentage} isPercent></NutritionMacroDetails> : null}
      <br />
      {unit === Unit.Percent && macroGram ?
        <NutritionMacroDetails content={content} macro={macroGram}></NutritionMacroDetails> : null}
      <br />
      {
        !isObservationGoal &&
        <div className='targetLabel'>
          <div>
            <span>{content.averageCal} </span> <span className='bold'>{weeklyAverageData.caloriesAverage} {content.cal}</span>
          </div>
          <div>
            <span className='bold'>{weeklyAverageData.carbsAveragePercentage}%</span> <span> {content.carbsGoal} | </span>
            <span className='bold'>{weeklyAverageData.fatAveragePercentage}%</span> <span> {content.fatGoal} | </span>
            <span className='bold'>{weeklyAverageData.proteinAveragePercentage}%</span> <span> {content.proteinGoal}</span>
          </div>
          <div>
            <span>{content.perDay}</span>
          </div>
        </div>
      }
      {
        !isObservationGoal &&
        <div className="form-row slider-row">
          <LinkWrapper id="nutrition-goal-form-cancel" className="button btn btn-secondary" to={backPath}>{local.formCancel}</LinkWrapper>
          <Button
            id="slider-value-submit"
            className="btn btn-primary"
            submit
            disabled={isProcessing}
            color="blue">
            {local.formSave}
          </Button>
        </div>
      }
    </form>
  );
}

NutritionGoalForm.displayName = 'Nutrition Goal';

NutritionGoalForm.propTypes = {
  isProcessing: PropTypes.bool,
  handleSubmit: PropTypes.func,
  backPath: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  weeklyAverageData: PropTypes.shape()
};

export default reduxForm({
  form: 'nutritionGoal',
  enableReinitialize: true
})(NutritionGoalForm);
