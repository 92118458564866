import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import local from 'services/localization/local';
import { NonAuthenticatedPageWall, LinkWrapper, Button } from 'shared';
import { signIn } from 'identity/action-creators';
import PrivacyPolicyEulaSection from 'app/privacy-policy-eula/PrivacyPolicyEulaSection';
import SignInForm from './SignInForm';
const env = window.env || {};

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.redirectToIdMe = this.redirectToIdMe.bind(this);
  }

  redirectToIdMe(){
    localStorage.setItem('isIdMeAuth',true);
    window.location.href = env.Idme_loginurl;
  }

  render() {
    const { signingIn, signInError, actions: { handleSignIn } } = this.props;
    const errorMessage = signInError ? local.signInErrorMessage : null;
    const privacyPolicySection = <PrivacyPolicyEulaSection buttonNameText={local.formContinueButton} />;
    const navAction = <LinkWrapper id='sign-in-register-nav' className="button btn btn-secondary" to="/register">{local.join}</LinkWrapper>;

    return (
      <NonAuthenticatedPageWall id="signin-form" titleTabText={local.linkSignIn} navAction={navAction}>
        <h1>{local.formSignInHeader}</h1>
        <div className='RegSubContent'>
          <small>{`${local.formSignInJoinText} `}
            <LinkWrapper id="sign-in-register" to="/register">{local.formSignInJoin}</LinkWrapper>
          </small>
        </div>
        <div className='row'>
          <div className='signin-form-split'>
            <SignInForm
              privacyPolicySection={privacyPolicySection}
              onSubmit={handleSignIn}
              errorMessage={errorMessage}
              signingIn={signingIn} />
            <div className="secondary-links">
              <LinkWrapper id="sign-in-forgot-password" to="/reset-password">{local.formSignInForgotPassword}</LinkWrapper>
            </div>
          </div>
          <div className='signin-form-split' style={{border:'none'}}>
            <div style={{marginTop:'37px'}}>
              Sign in with your secure Id.me account.
            </div>
            <div style={{marginTop:'40px', cursor:'pointer'}}>
              <img onClick={this.redirectToIdMe} height={45} src="/static/images/idmesignin.svg" alt="android iphone idme signin" />
            </div>
          </div>
        </div>
        <div style={{marginTop:'37px'}}>
          {privacyPolicySection}
        </div>
      </NonAuthenticatedPageWall>
    );
  }
}

SignIn.propTypes = {
  signingIn: PropTypes.bool.isRequired,
  signInError: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    handleSignIn: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  signingIn: state.identity.signingIn,
  signInError: state.identity.signInError
});

function mapDispatchToProps(dispatch) {
  const actions = { handleSignIn: signIn };

  const actionMap = { actions: bindActionCreators(actions, dispatch) };

  return actionMap;
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
