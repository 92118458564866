import * as profilesApi from 'services/api/profiles-api';
import * as healthDataApi from 'services/api/healthdata-api';

import { types } from 'profiles/sharing/sharing-types';
import * as persistentDataActions from 'app/request/persistent-data-actions';
import { request } from 'app/request/request-actions';

export const actionNames = {
  SHOW_BADGES_ACHIEVED_SCREEN: 'SHOW_BADGE_ACHIEVED_SCREEN'
};

export function loadBadgesSummary(profileId) {
  return (dispatch, getState) => {
    const identityId = getState().identity.self.id;
    const action = state => profilesApi.getBadgesSummary(profileId, state);
    return dispatch(persistentDataActions.request(action, types.PROFILE_BADGES, identityId));
  };
}

export function updateShowBadgeAchievedScreen(isShow, retryCount = 2) {
  return {
    type: actionNames.SHOW_BADGES_ACHIEVED_SCREEN,
    payload: { isShow, retryCount }
  };
}

export function setBadgeNotified(profileId, badgeId) {
  return (dispatch) => {
    const action = state => profilesApi.setBadgeNotified(profileId, badgeId, state);
    return dispatch(request(action));
  };
}

export function loadBadgesForNotification(profileId) {
  return (dispatch, getState) => {
    const identityId = getState().identity.self.id;
    const action = state => profilesApi.getBadgesSummary(profileId, state);
    dispatch(updateShowBadgeAchievedScreen(true, getState().badgeSummary.showBadgeAchievedScreen.retryCount - 1));
    return dispatch(persistentDataActions.request(action, types.BADGES_FOR_NOTIFICATION, identityId));
  };
}

export function getBadgesIcon(profileId) {
  return (dispatch, getState) => {
    const identityId = getState().identity.self.id;
    const action = state => healthDataApi.getBadgesIcon(profileId,state);
    return dispatch(persistentDataActions.request(action, types.BADGES_ICONS, identityId));
  };
}
