import React from 'react';
import { Route, Switch } from 'react-router';
import ShareProfilePage from 'profiles/sharing/ShareProfilePage';
import VerifiedEmailRoute from 'identity/verify-email/VerifiedEmailRoute';
import SharingAdminPage from 'profiles/sharing/admin/SharingAdminPage';
import Page404 from 'app/page404/Page404';
import { routePaths } from 'services/routing';

export default function Routes() {
  return (
    <Switch>
      <VerifiedEmailRoute exact path={routePaths.sharing} component={ShareProfilePage} />
      <VerifiedEmailRoute exact path={routePaths.sharingAdmin} component={SharingAdminPage} />
      <Route component={Page404} />
    </Switch>
  );
}
