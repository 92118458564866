import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { Button, ErrorSection } from 'shared';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'shared/form-fields/formik';
import { getUniqueId } from 'services/helpers';
import { getCreateOrganizationRequest } from 'organizations/organizations-selectors';
import { createOrganization } from 'organizations/organizations-actions';

export default function CreateOrganizationForm() {
  const dispatch = useDispatch();
  const requestId = useRef(getUniqueId());
  const request = useSelector(state => getCreateOrganizationRequest(state, requestId.current));

  const handleSubmit = ({ name, description }) => {
    dispatch(createOrganization({ name, description, requestId: requestId.current }));
  };

  if (request.success) {
    return <Redirect to={routePaths.people} />;
  }

  return (
    <Formik
      validateOnChange={false}
      validationSchema={validation}
      initialValues={{ name: '', description: '' }}
      onSubmit={handleSubmit}>
      <Form>
        <div className="form-row">
          <ErrorSection error={request.error} />
        </div>
        <div className="form-row">
          <TextField
            disabled={request.processing}
            label={local.organizations.groupNameLabel}
            name='name' />
        </div>
        <div className="form-row">
          <TextField
            disabled={request.processing}
            label={local.organizations.groupDescriptionLabel}
            name='description' />
        </div>
        <br />
        <div className="form-row">
          <Button
            color='teal'
            id='create-organization-button'
            disabled={request.processing}
            submit>
            {local.formSubmit}
          </Button>
        </div>
      </Form>
    </Formik>
  );
}

const validation = Yup.object({
  name: Yup.string()
    .required(local.validationRequired)
    .max(255, local.formatString(local.validationMaxCharLength, 255)),
  description: Yup.string()
    .max(500, local.formatString(local.validationMaxCharLength, 500))
});
