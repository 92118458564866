import React from 'react';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import { CardList } from 'shared';
import OrganizationMembersListItem from './OrganizationMembersListItem';

export default function OrganizationMembersList({ members }) {
  return (
    <div data-testid='org-list-container' className=' list-container'>
      <h5>{local.organizations.details.membersListHeader}</h5>
      {members && members.length > 0 && (
        <CardList testId='member-list'>
          {members.map(member => <OrganizationMembersListItem key={member.membershipId} {...member} />)}
        </CardList>
      )}
    </div>
  );
}

OrganizationMembersList.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({
    membershipId: PropTypes.string
  }))
};
