import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProfileId } from 'app/selectors';
import { getIsSyncing } from 'health/connect/connect-selectors';
import { types } from 'health/health-types';
import { accessImportCountSummary, loadImportCountSummaries } from 'health/action-creators';
import * as persistentDataSelectors from 'app/request/persistent-data-selectors';

export default function useMarkHealthDataAccessed(healthDataType) {
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const { loaded, isSyncing, isConnected } = useSelector(getIsSyncing);
  const importCounts = useSelector(state => persistentDataSelectors.getData(state.health, types.IMPORT_COUNTS, profileId));
  const importCountsLoaded = !!useSelector(state => persistentDataSelectors.isLoaded(state.health, types.IMPORT_COUNTS, profileId));

  const dataTypes = Array.isArray(healthDataType) ? healthDataType : [healthDataType];
  const hasData = !!(importCounts && dataTypes.some(t => importCounts[t] && importCounts[t].newItems));
  useEffect(() => {
    if (isConnected && (loaded || !isSyncing)) {
      if (hasData) {
        dispatch(accessImportCountSummary(profileId, healthDataType));
      } else if (!importCountsLoaded) {
        dispatch(loadImportCountSummaries(profileId));
      }
    }
  }, [profileId, isConnected, importCountsLoaded]);
}
