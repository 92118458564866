import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { TextField, DateTimeTextFieldWrapper } from 'shared/form-fields';
import { Button } from 'shared';
import * as validators from 'services/fields/field-validators';
import * as normalizers from 'services/fields/field-normalizers';
import local from 'services/localization/local';
import PathwayContextDetails from 'health/observations/shared/PathwayContextDetails';

class LogWeightForm extends React.Component {
  render() {
    const { submitting, pristine, saving, handleSubmit, handleBack, pathwayContext } = this.props;
    const isSubmitting = submitting || saving;
    const isSubmitDisabled = isSubmitting || pristine;

    return (
      <form onSubmit={handleSubmit}>
        {pathwayContext && <PathwayContextDetails pathwayContext={pathwayContext} />}
        <div className="form-row">
          <DateTimeTextFieldWrapper
            readOnly={isSubmitting}
            label={local.formTimestampLabel}
            name="timestamp"
            validate={[validators.required, validators.dateInPast, validators.pathwayContextDateValidator]} />
        </div>
        <div className="form-row">
          <Field
            readOnly={isSubmitting}
            label={local.formWeightLabel}
            autoFocus
            name="weight"
            component={TextField}
            type="number"
            placeholder={local.formWeightPlaceholder}
            normalize={normalizers.weightPounds}
            validate={[validators.required, validators.number, validators.minValue0]} />
        </div>
        <div className="form-row">
          <Field
            readOnly={isSubmitting}
            label={local.formNotesLabel}
            name="notes"
            component={TextField}
            validate={[validators.maxLength250]}
            placeholder={local.formNotesPlaceholder} />
        </div>
        <br />
        <br />
        <div className="form-row">
          <Button id="weight-form-cancel" className="btn btn-secondary" onClick={handleBack}>{local.formCancel}</Button>
          <Button
            id="weight-form-submit"
            className="btn btn-primary"
            submit
            color="blue"
            disabled={isSubmitDisabled}>
            {local.formSave}
          </Button>
        </div>
      </form>
    );
  }
}

LogWeightForm.displayName = 'Log Weight Form';
LogWeightForm.propTypes = {
  saving: PropTypes.bool,
  handleBack: PropTypes.func.isRequired,
  // redux form
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  pathwayContext: PropTypes.shape()
};

export default reduxForm({
  form: 'weight'
})(LogWeightForm);
