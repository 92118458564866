import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import { getProfileId } from 'app/selectors';
import { ObservationType, Unit } from 'services/enums';
import { observationReset } from 'health/actions';
import { saveObservation } from 'health/action-creators';
import LogWeightForm from './LogWeightForm';

class LogWeight extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    const { actions } = this.props;
    const { reset } = actions;

    // TODO: Revisit timing if we decide to route away while saving
    reset(ObservationType.Weight);
  }

  handleSubmit(values) {
    const { weight, ...rest } = values;
    const { profileId, actions } = this.props;
    actions.saveObservation({
      ...rest,
      profileId,
      value: weight,
      observationType: ObservationType.Weight,
      unit: Unit.Pounds
    });
  }

  render() {
    const { saving, profileId, handleBack, pathwayContext } = this.props;

    const currentTimestamp = moment().format(moment.HTML5_FMT.DATETIME_LOCAL);
    const initialValues = { timestamp: currentTimestamp };

    return (
      <LogWeightForm profileId={profileId} pathwayContext={pathwayContext} onSubmit={this.handleSubmit} saving={saving} initialValues={initialValues} handleBack={handleBack} />
    );
  }
}

LogWeight.propTypes = {
  profileId: PropTypes.string.isRequired,
  saving: PropTypes.bool.isRequired,
  handleBack: PropTypes.func.isRequired,
  pathwayContext: PropTypes.shape(),
  actions: PropTypes.shape({
    saveObservation: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  }).isRequired
};

function mapStateToProps(state, ownProps) {
  const props = {
    saving: state.health.weight.saving,
    profileId: getProfileId(state, ownProps)
  };

  return props;
}

function mapDispatchToProps(dispatch) {
  const actions = {
    saveObservation,
    reset: observationReset
  };

  const actionMap = { actions: bindActionCreators(actions, dispatch) };

  return actionMap;
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogWeight));
