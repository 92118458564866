/* eslint-disable react/jsx-no-bind */
import React,{useEffect} from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import {Page, Logo } from 'shared';
import local from 'services/localization/local';
import { getProfile, getSelfProfileId } from 'profiles/list/selectors';
import UserDetailsForm from './UserDetailsForm';
import * as actions from 'identity/action-creators';
import { acceptHipaaConsentPage } from 'profiles/action-creators';
import 'identity/username/username.scss';
import{ getRecentPendingInviteOrganizationMember } from 'organizations/organizations-selectors';
import { getIdentityId } from 'identity/selectors';
import { loadRecentPendingInvite } from 'organizations/organizations-actions';
import moment from 'moment';
import {ProfileRelationshipType } from 'services/enums';

function UserDetailsRegistration()  {
  const dispatch = useDispatch();
  const profile = useSelector(getProfile, shallowEqual);
  const selfProfileId = useSelector(getSelfProfileId, shallowEqual);
  const selectedProfileId = useSelector(state => state.selectedProfile.id);
  const details = useSelector(state=> getRecentPendingInviteOrganizationMember(state));
  const identityId = useSelector(getIdentityId);

  useEffect(()=>{
    if(identityId){
      dispatch(loadRecentPendingInvite());
    }
  },[identityId]);

  const handleSubmit = (input) => {
    dispatch(actions.updateUserProfiile(
      selectedProfileId,
      input
    ));
    dispatch(actions.updateForceRegistration(selfProfileId, false));
  };

  const skipDobGender = () =>{
    dispatch(actions.updateForceRegistration(selfProfileId, false));
    dispatch(acceptHipaaConsentPage(profile.id));
  };
  var genderVal = '';
  switch(details.gender){
    case 'Male' :
      genderVal = 1;
      break;
    case 'Female' :
      genderVal = 2;
      break;
    case 'Unknown' :
      genderVal = 0;
      break;
    case 'Other' :
      genderVal = 3;
      break;
    default :
      genderVal = '';
  }
  const initialValues = details && profile.profileRelationship === ProfileRelationshipType.Self  && profile  ? {
    dateOfBirth:details.dob ? moment(details.dob).format(moment.HTML5_FMT.DATE) : '',
    gender : genderVal
  }
    : {
      dateOfBirth : profile.dateOfBirth ? moment(profile.dateOfBirth).format(moment.HTML5_FMT.DATE) : '',
      gender :profile.gender ?? ''
    };
  return (

    <Page
      id='userDetails-registration-page'
      centerElement={<Logo />}
      titleTabText={local.Registration.setUp}
      hideBottomNav>
      <h1 className='px-3 pt-4'>Welcome to Calcium!</h1>

      <div className='userDetail-Wrapper'>
        <div className='userDetail-title'>{local.Registration.setUp}</div>

        <div className='userDetail-subTitle'>{local.Registration.setupExplanation}</div>

        <UserDetailsForm skipDetails={skipDobGender} onSubmit={handleSubmit} initialValues={initialValues}></UserDetailsForm>
      </div>
    </Page>
  );
}


export default UserDetailsRegistration;
