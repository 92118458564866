import React, { useEffect, useState } from 'react';
import { Button, ModalWrapper } from 'shared';
import local from 'services/localization/local';
import './InfoModal.scss';

export default function AlertModal(props){
  const [showModal, setShowModal] = useState(false);
  const primaryObservationType = props.primaryObservationType;
  function closeModal() {
    setShowModal(false);
  }

  useEffect(() => {
    setShowModal(true);
  },[]);

  return(
    <ModalWrapper isOpen={showModal}>
      <div id="info-modal" data-testid="info-modal" className="modal-content">
        <div className="modal-header">
          <div></div>
          <div className='modal-title'>
            {local.formatString(local.goals.alertTitle, local.observationsTitle[primaryObservationType])}
          </div>
        </div>
        <div className="modal-body">
          <div className="verify-successMsg">
            <div className="error-msg-title" >
              {local.formatString(local.goals.alertDescription, local.observationsTitle[primaryObservationType])}
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <Button id="cancel-delete" className="btn btn-secondary small-btn" onClick={closeModal}>{local.ok}</Button>
        </div>
      </div>
    </ModalWrapper>
  );
}
