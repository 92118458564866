import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { interviewReducer as hcInterviews } from '@healthchampion/interviews';
import connectReducer from 'health/connect/connect-reducer';
import devicesReducer from 'devices/devices-reducer';
import identityReducer from 'identity/identity-reducer';
import interviewReducer from 'interviews/interview-reducer';
import toasterReducer from 'app/toaster/toaster-reducer';
import networkReducer from 'app/network/network-reducer';
import profilesReducer from 'profiles/profiles-reducer';
import selectedProfileReducer from 'profiles/selected/selected-profile-reducer';
import programsReducer from 'programs/programs-reducer';
import healthReducer from 'health/health-reducer';
import medicationReducer from 'health/medication/medication-reducer';
import medicationDoseReducer from 'health/medication/dose/medication-dose-reducer';
import navigationReducer from './layout/navigation/navigation-reducer';
import activityReducer from 'activity/activity-reducer';
import notificationReducer from 'notifications/notification-reducer';
import requestReducer from 'app/request/request-reducer';
import sharingReducer from 'profiles/sharing/sharing-reducer';
import journalReducer from 'journal/journal-reducer';
import timelineReducer from 'timeline/timeline-reducer';
import organizationReducer from 'organizations/organizations-reducer';
import dataReducer from 'health/data/data-reducer';
import preferencesReducer from 'preferences/changeEmail/preferences-reducer';
import healthSummaryReducer from 'timeline/HealthSummary-reducer';
import BadgeSummaryReducer from 'badge/BadgeSummary-reducer';
import ProductReducer from 'products/products-reducer';
import authConnectionReducer from 'auth-connections/auth-connection-reducer';
import FeaturesReducer from 'features/features-reducer';
import InvoicesReducer from 'preferences/invoice/invoices-reducer';
import AppointmentReducer from 'appointment/appointment-reducer';
import ObservationConnectionsReducer from 'profile-observation-connections/observation-connections-reducer';
import chatReducer from 'chat/chat-reducer';
import goalReducer from 'goal/goal-reducer';
import healthCareInfoReducer from 'healthInfo/action-reducer';

export default combineReducers({
  activity: activityReducer,
  connect: connectReducer,
  devices: devicesReducer,
  form: formReducer,
  health: healthReducer,
  identity: identityReducer,
  interviews: interviewReducer,
  hcInterviews: hcInterviews,
  healthCareInfo: healthCareInfoReducer,
  journal: journalReducer,
  medication: medicationReducer,
  medicationDose: medicationDoseReducer,
  navigation: navigationReducer,
  notification: notificationReducer,
  network: networkReducer,
  organization: organizationReducer,
  profile: profilesReducer,
  programs: programsReducer,
  request: requestReducer,
  selectedProfile: selectedProfileReducer,
  sharing: sharingReducer,
  timeline: timelineReducer,
  toaster: toasterReducer,
  data: dataReducer,
  preferences:preferencesReducer,
  healthSummary: healthSummaryReducer,
  badgeSummary: BadgeSummaryReducer,
  products: ProductReducer,
  authConnection: authConnectionReducer,
  features: FeaturesReducer,
  invoice: InvoicesReducer,
  appointment:AppointmentReducer,
  observationConnections: ObservationConnectionsReducer,
  chat:chatReducer,
  profileGoals: goalReducer
});
