import React from 'react';
import PropTypes from 'prop-types';
import './header.scss';

class HeaderComponent extends React.Component {

  buildChildElement() {
    const { children } = this.props;
    if (children) {
      return <div>{children}</div>;
    }
    return null;
  }

  render() {
    const { leftElement, farLeftElement, rightElement, farRightElement, centerElement } = this.props;
    const childElement = this.buildChildElement();

    return (
      <header id="mastheadContainer" className="section-header">
        <div className="header-row-grid">
          <div className="left">
            <div className="far-left-header-item">{farLeftElement}</div>
            <div className="left-header-item">{leftElement}</div>
          </div>
          <div className="center-header-item">
            <div className="center-header-item-content">{centerElement}</div>
          </div>
          <div className="right">
            <div className="right-header-item">{rightElement}</div>
            <div className="far-right-header-item">{farRightElement}</div>
          </div>
        </div>
        {childElement}
      </header>
    );
  }
}

HeaderComponent.displayName = 'Header Component';
HeaderComponent.propTypes = {
  centerElement: PropTypes.node,
  farLeftElement: PropTypes.node,
  farRightElement: PropTypes.node,
  leftElement: PropTypes.node,
  rightElement: PropTypes.node,
  children: PropTypes.node
};

export default HeaderComponent;
