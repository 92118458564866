import React from 'react';
import { useSelector } from 'react-redux';
import local from 'services/localization/local';
import { CardList } from 'shared';
import { getOutgoingInvites } from 'profiles/sharing/sharing-selectors';
import OutgoingInviteListItem from './OutgoingInviteListItem';

export default function OutgoingInvitesList() {
  const inviteStatuses = useSelector(getOutgoingInvites);

  return (
    <div>
      {inviteStatuses.length > 0 &&
        (
          <div className='list-container'>
            <h5 className='category-header'>{local.sharing.pendingInvites}</h5>
            <CardList testId="outgoing-invites-list">
              {inviteStatuses.map(invite => (
                <OutgoingInviteListItem key={invite.id} invite={invite} />
              ))}
            </CardList>
          </div>
        )}
    </div>
  );
}
