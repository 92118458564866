import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'mobile/helpers';
import local from 'services/localization/local';
import { ShareIcon } from 'shared';
import './share-content.scss';

export default function ShareContent({ dialogTitle }) {
  let text;

  if (isMobile) {
    text = dialogTitle;
  } else {
    text = local.socialShare.desktopTitle;
  }

  return <div className="share-icon-container"><ShareIcon /><span className="action-text">{text}</span></div >;
}

ShareContent.propTypes = {
  dialogTitle: PropTypes.string
};
