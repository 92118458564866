import React from 'react';
import { Route, Switch } from 'react-router';
import ProgramsLayout from 'programs/layout/ProgramsLayout';
import DashboardPreview from 'dashboard/preview/DashboardPreview';
import Preferences from 'preferences/Preferences';
import ProfilesLayout from 'profiles/layout/ProfilesLayout';
import HealthLayout from 'health/layout/HealthLayout';
import SharingRoutes from 'profiles/sharing/SharingRoutes';
import Notifications from 'notifications/list/NotificationPage';
import AppointmentDetailsPage from 'appointment/AppointmentDetailsPage';

import OrganizationRoutes from 'organizations/OrganizationRoutes';
import Page404 from 'app/page404/Page404';
import VerifyEmailPage from 'identity/verify-email/VerifyEmailPage';
import { routePaths } from 'services/routing';
import FeedBackLink from 'feedback/FeedBackLink';
import NewDashboard from 'dashboard/NewDashboard';
import { FavSelectionList } from 'preferences/dashboardPreferences/FavSelectionList';
import { VerifiedChangeEmail } from 'preferences/changeEmail/VerifiedChangeEmail';
import  ChangeEmail  from 'preferences/changeEmail/ChangeEmail';
import ChangeEmailSignOut from 'preferences/changeEmail/ChangeEmailSignOut';
import HealthIndex from 'dashboard/HealthIndex';
import BadgeSummary from 'badge/BadgeSummary';
import StepTarget from 'goal/StepTarget';
import Coach from 'products/Coach';
import Dashboard from 'dashboard/v1/DashboardV1';
import { useSelector } from 'react-redux';
import { profilesApiRoot} from 'app/config';
import { getABTestingSegment } from 'identity/selectors';
import { Group_A, AbTestName } from 'services/constants';
import NutritionGoalPage from 'health/observations/nutrition/NutritionGoalPage';
import Invoice from 'preferences/invoice/Invoice';
import AppointmentList from 'appointment/AppointmentList';
import BloodGlucoseGoalPage from 'health/observations/blood-glucose/BloodGlucoseGoalPage';
import BloodPressureGoal from 'health/observations/blood-pressure/BloodPressureGoalPage';
import ProfileObservationConnect from 'profile-observation-connections/ProfileObservationConnect';
import ChatPage from 'chat/ChatPage';
import MyGoal from 'goal/MyGoal';
import CreateGoal from 'goal/CreateGoal';
import ObservationGoal from 'goal/ObservationGoal';
import HealthInfo from 'healthInfo';
import ViewPlan from 'preferences/billing/ViewPlan';
import EHR from 'health/electronic-health-records/EHR';
import ChangePassword from 'preferences/changePassword/ChangePassword';

export default function Routes() {
  const showBetaFeatures = useSelector(state => state.identity.self.showBetaFeatures);
  const abSegment = useSelector(state => getABTestingSegment(state, AbTestName));
  const isTestEnv = profilesApiRoot && profilesApiRoot.includes('test');
  return (
    <Switch>
      <Route exact path={routePaths.healthBadgeSummary} component={BadgeSummary} />

      {showBetaFeatures && isTestEnv ? <Route exact path={routePaths.root} component={Dashboard} /> : null}

      {abSegment == Group_A ? <Route exact path={routePaths.root} component={Dashboard} /> : <Route exact path={routePaths.root} component={NewDashboard} />}

      <Route path={routePaths.feedbackHealthSync} component={FeedBackLink} />

      <Route exact path={routePaths.verifyEmail} component={VerifyEmailPage} />

      <Route path={routePaths.dashboardPreview} component={DashboardPreview} />

      <Route path={routePaths.healthInfo} component={HealthInfo} />

      <Route exact path={routePaths.preferences} component={Preferences} />

      <Route exact path={routePaths.changePassword} component={ChangePassword} />

      <Route exact path={routePaths.invoice} component={Invoice} />

      <Route exact path={routePaths.page404} component={Page404} />

      <Route exact path={routePaths.electronicHealthRecord} component={EHR} />

      <Route path={routePaths.health} component={HealthLayout} />

      <Route path={routePaths.programs} component={ProgramsLayout} />

      <Route path={routePaths.notifications} component={Notifications} />

      <Route path={routePaths.appointmentDetails} component={AppointmentDetailsPage} />

      <Route path={routePaths.people} component={ProfilesLayout} />

      <Route path={routePaths.sharing} component={SharingRoutes} />

      <Route path={routePaths.organizations} component={OrganizationRoutes} />

      <Route path={routePaths.healthIndex} component={HealthIndex} />

      <Route path={routePaths.coach} component={Coach} />

      <Route exact path={routePaths.preferencesDashboardFav} component={FavSelectionList} />

      <Route exact path={routePaths.verifiedChangeEmail} component={VerifiedChangeEmail} />

      <Route exact path={routePaths.changeEmail} component={ChangeEmail} />

      <Route exact path={routePaths.viewPlan} component={ViewPlan} />

      <Route exact path={routePaths.stepTarget} component={StepTarget} />

      <Route exact path={routePaths.nutritionGoal} component={NutritionGoalPage} />

      <Route exact path={routePaths.bloodGlucoseGoal} component={BloodGlucoseGoalPage} />

      <Route exact path={routePaths.appointment} component={AppointmentList} />

      <Route exact path={routePaths.bloodPressureGoal} component={BloodPressureGoal} />

      <Route exact path={routePaths.observationConnect} component={ProfileObservationConnect} />

      <Route exact path={routePaths.chat} component={ChatPage} />

      <Route exact path={routePaths.myGoal} component={MyGoal} />

      <Route exact path={routePaths.createGoal} component={CreateGoal} />

      <Route exact path={routePaths.observationGoal} component={ObservationGoal} />

      <Route exact path={routePaths.ChangeEmailSignOut} component={ChangeEmailSignOut} />

      <Route component={Page404} />
    </Switch>
  );
}
