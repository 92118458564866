import React from 'react';
import { withRouter } from 'react-router';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import withNavigate from 'app/withNavigation';
import { Avatar, Dropdown, Icon } from 'shared';
import { getSortedProfiles, getProfile } from 'profiles/list/selectors';
import { selectProfile } from 'profiles/selected/selected-profile-actions';
import useProfileIdFromUrl from 'profiles/selected/useProfileIdFromUrl';
import { replaceProfileIdInUrl } from 'services/url-service';
import './profile-switcher.scss';

function ProfileSwitcher({ location, navigate, readonly, backPath }) {
  const dispatch = useDispatch();
  const selectedProfile = useSelector(state => getProfile(state), shallowEqual);
  const profiles = useSelector(state => getSortedProfiles(state), shallowEqual);
  const urlHasProfileId = !!useProfileIdFromUrl(location);

  const handleSelectProfile = (profile) => {
    const navigateProps = { to: location.pathname };
    if(backPath && backPath.to){
      const backpath = replaceProfileIdInUrl(backPath.to, profile.id);
      navigateProps.state = {backpath};
    }
    else{
      navigateProps.state = {wasProfileSwitched: true};
    }

    if (urlHasProfileId){
      navigateProps.params = { profileId: profile.id };
      navigate(navigateProps); // This assumes we're not using the search string for anything else
      return;
    }

    if(backPath && backPath.to)
      navigate(navigateProps);

    dispatch(selectProfile(profile.id));
  };

  const quickSwitchOptions = profiles.length > 1 ? (
    <ul className="scrollview-list">
      {profiles.map(profile => {
        const classNames = selectedProfile && profile.id == selectedProfile.id ? 'with-padding active scrollview-list-item' : 'with-padding scrollview-list-item';
        return (
          <li key={profile.id} onClick={() => handleSelectProfile(profile)} className={classNames}>
            <Avatar profile={profile} />
            <span>{profile.name}</span>
          </li>
        );
      })}
    </ul>
  ) : null;

  const dropdownIndicator = !readonly && quickSwitchOptions ? <Icon symbol="chevrondown" size="1em" /> : '';

  const activeContext = selectedProfile ? (
    <div id="profile-indicator">
      <Avatar profile={selectedProfile} />
      {dropdownIndicator}
    </div>
  ) : <div></div>;

  return (
    <Dropdown id="profile-switcher" activeItem={activeContext}>
      {!readonly && quickSwitchOptions}
    </Dropdown>
  );
}

ProfileSwitcher.displayName = 'Profile Selector';
ProfileSwitcher.propTypes = {
  readonly: PropTypes.bool,
  location: PropTypes.shape().isRequired,
  navigate: PropTypes.func.isRequired,
  backPath: PropTypes.shape()
};

export default withNavigate(withRouter(ProfileSwitcher));
