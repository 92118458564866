import 'gethelp/get-help.scss';
import React, { useEffect, useState } from 'react';
import { getProfileId } from 'app/selectors';
import { helpScoutBeaconId } from 'app/config';
import { Icon } from 'shared';
import local from 'services/localization/local';
import { useSelector } from 'react-redux';
import * as beaconService from 'services/helpscout-beacon-service';

export default function GetHelpLink() {
  const name = useSelector(state => state.profile.self.name);
  const email = useSelector(state => state.identity.email);
  const profileId = useSelector(getProfileId);
  const [isBeaconInitialized, setBeaconInitialized] = useState(false);
  const [isBeaconOpen, setBeaconOpen] = useState(false);

  useEffect(() => {
    if (isBeaconInitialized) {
      beaconService.logout();
      beaconService.setIdentity(name, email);
      beaconService.prefillUserInformation(name, email);
    }
  }, [name, email, profileId]);

  function handleSupportClick() {
    if (isBeaconOpen)
      return;

    if (!isBeaconInitialized) {
      beaconService.initialize(helpScoutBeaconId);
      beaconService.setDefaultConfiguration();
      beaconService.setIdentity(name, email);
      beaconService.prefillUserInformation(name, email);
      beaconService.addCallbackEvent('close', onBeaconClose);
      setBeaconInitialized(true);
    }

    beaconService.open();
    setBeaconOpen(true);
  }

  function onBeaconClose() {
    setBeaconOpen(false);
  }

  return (
    <a
      id="get-help"
      className='nav-feedback'
      title={local.nav.getHelp}
      onClick={handleSupportClick}
      data-testid='get-help-nav-link'>
      <Icon symbol="feedback" />
      <span>{local.nav.getHelp}</span>
    </a>
  );
}
