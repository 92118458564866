import React from 'react';
import { routePaths } from 'services/routing';
import local from 'services/localization/local';
import { NavAction } from 'shared';
import MedicationList from './MedicationList';
import ProfilePage from 'profiles/page/ProfilePage';
import MedicationDataLoadingStatus from 'health/medication/MedicationDataLoadingStatus';
import MedicationDataLoader from 'health/medication/MedicationDataLoader';
import MedicationDoseVisualization from './MedicationDoseVisualization';
import { Link } from 'react-router-dom';

export default function MedicationOverview() {

  return (
    <ProfilePage
      id='medications'
      centerElement={local.listMedicationsHeader}
      subHeader={
        <div className="med-tab-view">
          <ul className="active-calender">
            <li className="calender-tab"><Link to={routePaths.healthMedicationDose}>{local.toggleCalendar}</Link></li>

            <li className="list-tab">{local.toggleList}</li>
          </ul>
        </div>
      }
      backPath={{ to: routePaths.healthMedicationDose }}
      primaryAction={<NavAction
        text={local.medicationDoses} iconSymbol='add'
        to={routePaths.healthMedicationAdd} />}>

      <MedicationDataLoader />

      <MedicationDataLoadingStatus>
        <MedicationDoseVisualization />

        <MedicationList />
      </MedicationDataLoadingStatus>
    </ProfilePage>
  );
}
