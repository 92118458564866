import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import withNavigation from 'app/withNavigation';
import Page404 from 'app/page404/Page404';
import { routePaths } from 'services/routing';
import { ObservationRedirect, BackAction } from 'shared';
import ProfilePage from 'profiles/page/ProfilePage';
import configs from 'health/observations/observation-configs';
import { getObservationTypeSavedStatus } from '../observation-selectors';
import { getProfileId } from 'app/selectors';
import { replaceProfileIdInUrl } from 'services/url-service';

class GenericObservationLogPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleBack = this.handleBack.bind(this);
  }

  handleBack() {
    const { navigate } = this.props;
    const { configId } = this.props.match.params;
    const { state } = this.props.location;

    const navigateProps = { to: routePaths.healthObservation, params: { configId } };

    if(state?.backpath)
      navigateProps.to = replaceProfileIdInUrl(state.backpath, this.props.profileId);

    navigate(navigateProps);
  }

  render() {
    const { saveSuccess } = this.props;
    const { configId } = this.props.match.params;
    const { state } = this.props.location;

    if (saveSuccess) {
      const navigateProps = {configId};

      if(state?.backpath)
        navigateProps.backpath = replaceProfileIdInUrl(state.backpath, this.props.profileId);

      return <ObservationRedirect {...navigateProps} />;
    }

    const config = configs[configId];

    if (!config) {
      return <Page404 />;
    }

    if (!config.renderLogComponent) {
      return <ObservationRedirect configId={configId} />;
    }

    return (
      <ProfilePage
        id={`log-${config.id}`}
        centerElement={config.centerElement}
        titleTabText={config.logcenterElement}
        backAction={<BackAction handleBack={this.handleBack} />}>
        {config.renderLogComponent({ handleBack: this.handleBack })}
      </ProfilePage>
    );
  }
}

GenericObservationLogPage.propTypes = {
  saveSuccess: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      configId: PropTypes.string.isRequired
    })
  }),
  location: PropTypes.shape(),
  profileId: PropTypes.string.isRequired
};

function mapStateToProps(state, ownProps) {
  const { configId } = ownProps.match.params;
  const config = configs[configId];
  const saveSuccess = !!(config && getObservationTypeSavedStatus(state, config.type));
  const profileId = getProfileId(state, ownProps);

  return {
    saveSuccess,
    profileId
  };
}

export default withNavigation(withRouter(connect(mapStateToProps)(GenericObservationLogPage)));
