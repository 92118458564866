import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadHealthScore } from 'health/action-creators';
import local from 'services/localization/local';
import withContext from 'app/withContext';
import './health-score.scss';

class HealthScore extends Component {
  componentDidMount() {
    const { profileId } = this.props;
    this.props.actions.loadHealthScore(profileId);
  }

  componentDidUpdate(prevProps) {
    const { profileId } = this.props;

    if (profileId !== prevProps.profileId) {
      this.props.actions.loadHealthScore(profileId);
    }
  }

  render() {
    const { healthScore, loading, error, notEnoughInfo } = this.props;

    let body = null;
    if (loading) {
      body = <span className="health-score hs-loading">&nbsp;</span>;
    } else if (notEnoughInfo) {
      body = <span className="health-score nodata"><span>{local.notEnoughInfoForHealthScore}</span></span>;
    } else if (error) {
      body = <span className="health-score error"><span>{local.error}</span></span>;
    } else {
      body = (
        <span className="health-score score-wrapper">
          <span id="health-score">{healthScore}</span>
          {this.props.children}
        </span>);
    }

    return (
      <div className='health-score-header'>
        <h6>{local.healthScoreLabel}</h6>
        {body}
      </div>
    );
  }
}

HealthScore.propTypes = {
  profileId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  notEnoughInfo: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  healthScore: PropTypes.number,
  actions: PropTypes.shape({
    loadHealthScore: PropTypes.func.isRequired
  }).isRequired,
  children: PropTypes.node
};

const mapStateToProps = (state) => ({
  loading: state.health.healthScoreLoading,
  notEnoughInfo: state.health.healthScoreNotEnoughInfo,
  error: state.health.healthScoreError,
  healthScore: state.health.healthScore
});

function mapDispatchToProps(dispatch) {
  const actions = { loadHealthScore };

  const actionMap = { actions: bindActionCreators(actions, dispatch) };

  return actionMap;
}

export default withContext(connect(mapStateToProps, mapDispatchToProps)(HealthScore));
