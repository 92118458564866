import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { routePaths, ProgramsContext, programsContexts } from 'services/routing';
import { Page, ProfileRedirect, DataLoadingStatus, CardList, Loading, BackAction } from 'shared';
import * as selectors from 'programs/programs-selectors';
import ProgramTaskList from './ProgramTaskList';
import ProgramResources from './ProgramResources';
import ProgramDetails from './ProgramDetails';
import ProgramActionSheet from './ProgramActionSheet';
import PublicHealthBadgeModal from 'health/public-health-badge/PublicHealthBadgeModal';
import ProgramUpgradeModal from 'programs/enrollment/ProgramUpgradeModal';
import './program-details.scss';
import { bindActionCreators } from 'redux';
import { redirectFromImmunizationsDisable } from 'health/actions';
import { noImmunizationPathwayId } from 'app/config';
import withNavigation from 'app/withNavigation';
import { setContext } from 'programs/programs-actions';
import { CompletionScreenUpon } from 'services/enums';

class ProgramDetailsPage extends Component {

  constructor(props) {
    super(props);
    this.handleBack = this.handleBack.bind(this);
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.redirectFromImmunizationsDisable();
  }

  handleBack() {
    const { navigate, programId, programEnrollmentId } = this.props;
    const navProps = { to: this.context.base };
    const isFromCreateGoal = this.props.location?.state?.backpath?.isFromCreateGoal ?? this.props.location?.state?.isFromCreateGoal;

    if(isFromCreateGoal){
      return navigate({to: routePaths.myGoal});
    }

    if (this.context.base == programsContexts.leaderBoard.base) {
      navProps.params = { programId, programEnrollmentId };
      navProps.state = { backpath: routePaths.root };
    }

    navigate(navProps);
  }

  render() {
    const {
      programName,
      status,
      complete,
      completeSuccess,
      profileId,
      programId,
      programEnrollmentId,
      organizationId,
      notEnrolled,
      requestId,
      readyToComplete,
      tasksComplete,
      canAlert,
      actions,
      enrollment,
      fromDashboard,
      enrollmentOutdated,
      details
    } = this.props;

    const completionScreenData = (enrollment && enrollment.program && enrollment.program.completionScreenData) ? JSON.parse(enrollment.program.completionScreenData) : null;
    let isEnableQuitPathway = false;
    if(completionScreenData != null) {
      isEnableQuitPathway =  !complete && CompletionScreenUpon.PathwayCompletion == completionScreenData.completionScreenOn && tasksComplete ;
    }
    if (completeSuccess) {

      const navProps = { to: this.context.base };

      if (this.context.base == programsContexts.leaderBoard.base) {
        actions.setContext(programsContexts.default);
        navProps.to = routePaths.programsEnroll;
        navProps.params = { programId };
      }

      return <ProfileRedirect {...navProps} />;
    }

    if (notEnrolled) {
      const isFromCreateGoal = this.props.location?.state?.backpath?.isFromCreateGoal ? this.props.location?.state?.backpath?.isFromCreateGoal : false;
      return <ProfileRedirect to={routePaths.programsEnroll} params={{ programId }} backpath={{isFromCreateGoal:isFromCreateGoal}} />;
    }

    if (complete && !fromDashboard && enrollmentOutdated) {
      return <ProfileRedirect to={routePaths.root} />;
    }

    const actionSheet = ((!complete && !tasksComplete) || isEnableQuitPathway )?
      <ProgramActionSheet programEnrollmentId={programEnrollmentId} profileId={profileId} requestId={requestId} />
      : null;

    const badgeButton = canAlert ? (<PublicHealthBadgeModal programEnrollmentId={programEnrollmentId} />) : null;
    return (
      <Page
        id='program-details'
        centerElement={programName}
        titleTabText={programName}
        readOnlyProfile={complete}
        secondaryAction={badgeButton}
        primaryAction={actionSheet}
        backAction={<BackAction handleBack={this.handleBack} />}>
        <DataLoadingStatus {...status}>
          {!fromDashboard && <ProgramUpgradeModal programEnrollmentId={programEnrollmentId} startOpen />}
          <ProgramDetails programEnrollmentId={programEnrollmentId} requestId={requestId} details={details} />
          {programId == noImmunizationPathwayId &&
            <div id='program-tasks'>
              <Loading />
            </div>}
          <CardList id="program-progress" class={programId == noImmunizationPathwayId ? 'hidden' : ''}>
            <ProgramTaskList profileId={profileId} programId={programId} programEnrollmentId={programEnrollmentId} enabled={!complete} readyToComplete={readyToComplete} />
            <ProgramResources programEnrollmentId={programEnrollmentId} profileId={profileId} organizationId={organizationId} />
          </CardList>
        </DataLoadingStatus>
      </Page >
    );
  }
}

ProgramDetailsPage.contextType = ProgramsContext;

ProgramDetailsPage.propTypes = {
  requestId: PropTypes.string.isRequired,
  programId: PropTypes.string,
  programEnrollmentId: PropTypes.string,
  profileId: PropTypes.string.isRequired,
  organizationId: PropTypes.string,
  // redux
  programName: PropTypes.string.isRequired,
  status: PropTypes.shape({
    loaded: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired
  }).isRequired,
  enrollment: PropTypes.shape({
    program: PropTypes.shape({
      completionScreenData: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  complete: PropTypes.bool.isRequired,
  completeSuccess: PropTypes.bool.isRequired,
  notEnrolled: PropTypes.bool,
  notFound: PropTypes.bool,
  readyToComplete: PropTypes.bool.isRequired,
  tasksComplete: PropTypes.bool.isRequired,
  canAlert: PropTypes.bool.isRequired,
  // actions
  actions: PropTypes.shape({
    redirectFromImmunizationsDisable: PropTypes.func,
    setContext: PropTypes.func
  }).isRequired,
  navigate: PropTypes.func.isRequired,
  fromDashboard: PropTypes.bool,
  enrollmentOutdated: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
  const enrollment = ownProps.enrollment;
  const details = ownProps.details;

  return {
    profileId: state.selectedProfile.id,
    programName: (enrollment && enrollment.program.name) || '',
    complete: !!(enrollment && enrollment.complete),
    completeSuccess: selectors.getCompleteEnrollmentSuccess(state, ownProps.requestId),
    notEnrolled: ownProps.status.error && !enrollment,
    readyToComplete: !!(details && details.readyToComplete),
    tasksComplete: !!(details && details.allTasksComplete),
    canAlert: !!(details && details.canAlert)
  };
}
function mapDispatchToProps(dispatch) {
  const actions = { redirectFromImmunizationsDisable, setContext };
  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

export default withNavigation(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProgramDetailsPage)));
