import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resendVerificationEmail } from 'identity/action-creators';
import local from 'services/localization/local';

export default function VerifyEmailButton() {
  const dispatch = useDispatch();
  const resend = useCallback(() => dispatch(resendVerificationEmail()), []);

  return (
    <a href="#" onClick={resend} id="link-to-resend" data-testid='verify-button'>{local.resendVerification}</a>
  );
}
