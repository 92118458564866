import { validateObservationConfig } from './observation-config-helpers';
import { configIds } from 'health/observations/observation-config-ids';
import { ObservationType } from 'services/enums';
import local from 'services/localization/local';

const buildConfigDict = (r) => {
  return r.keys().reduce((accum, fileName) => {
    const fileObject = r(fileName);
    if (!fileObject.default) {
      throw Error(`missing default config in file ${fileName}`);
    }
    const config = validateObservationConfig(fileObject.default);
    if (accum[config.id]) {
      throw Error(`duplicate config id ${config.id} detected in file ${fileName}`);
    }
    accum[config.id] = config;
    return accum;
  }, {});
};

const configDict = buildConfigDict(require.context('./', true, /\.config\.(jsx|js)$/));

export default configDict;

export const observationTypeConfigDict = Object.values(configDict).reduce((accum, config) => {
  [config.type].flat().forEach(type => accum[type] = config);
  return accum;
}, {});

export const getConfigForObservationType = (observationType) => {
  const config = observationTypeConfigDict[observationType];

  if(!config){
    if(Number(observationType) === ObservationType.Exercise){
      return{
        centerElement: local.linkActivity
      };
    }
    else if(Number(observationType) === ObservationType.HeartRateVariability){
      return{
        centerElement: local.observation.heartRateVariability.title
      };
    }
    else if(!config){
      throw Error(`Missing config for observationType: ${observationType}`);
    }
  }
  return config;
};


export const getConfigIds = observationType => {
  switch(observationType){
    case ObservationType.InstantaneousHeartRate:
      return configIds.heartRate;
    case ObservationType.BodyTemperature:
      return configIds.temperature;
    case ObservationType.Height:
      return configIds.height;
    case ObservationType.Weight:
      return configIds.weight;
    case ObservationType.Steps:
      return configIds.steps;
    case ObservationType.BodyArms:
      return configIds.bodyArms;
    case ObservationType.BodyChest:
      return configIds.bodyChest;
    case ObservationType.BodyFat:
      return configIds.bodyFat;
    case ObservationType.BodyHips:
      return configIds.bodyHips;
    case ObservationType.BodyThighs:
      return configIds.bodyThighs;
    case ObservationType.BodyWaist:
      return configIds.bodyWaist;
    case ObservationType.BloodOxygen:
      return configIds.bloodOxygen;
    case ObservationType.Insulin:
      return configIds.insulin;
    case ObservationType.PeakFlow:
      return configIds.peakFlow;
    case ObservationType.BloodGlucose:
      return configIds.bloodGlucose;
    case ObservationType.Systolic:
    case ObservationType.Diastolic:
      return configIds.bloodPressure;
    case ObservationType.Immunization:
      return configIds.immunization;
    case ObservationType.Sleep:
      return configIds.sleep;
    case ObservationType.Nutrition:
      return configIds.nutritions;
    case ObservationType.LabResult:
      return configIds.labResult;
    case ObservationType.FloorsClimbed:
      return configIds.floorsClimbed;
    case ObservationType.BloodKetone:
      return configIds.bloodKetone;
    default:
      return 'TestConfig';
  }
};

export const getDashboardObservationTitle = observationType => {
  switch(observationType){
    case ObservationType.InstantaneousHeartRate:
      return local.observation.heartRate.title;
    case ObservationType.BodyTemperature:
      return local.observation.temperature.title;
    case ObservationType.Height:
      return local.observation.height.title;
    case ObservationType.Weight:
      return local.observation.weight.title;
    case ObservationType.Steps:
      return '';
    case ObservationType.BodyArms:
      return local.observation.bodyArms.title;
    case ObservationType.BodyChest:
      return local.observation.bodyChest.title;
    case ObservationType.BodyFat:
      return local.observation.bodyFat.title;
    case ObservationType.BodyHips:
      return local.observation.bodyHips.title;
    case ObservationType.BodyThighs:
      return local.observation.bodyThighs.title;
    case ObservationType.BodyWaist:
      return local.observation.bodyWaist.title;
    case ObservationType.BloodOxygen:
      return local.observation.bloodOxygen.title;
    case ObservationType.Insulin:
      return local.observation.insulin.title;
    case ObservationType.PeakFlow:
      return local.observation.peakFlow.title;
    case ObservationType.BloodGlucose:
      return local.observation.bloodGlucose.title;
    case ObservationType.Systolic:
    case ObservationType.Diastolic:
      return local.observation.bloodPressure.title;
    case ObservationType.Immunization:
      return local.observation.immunization.title;
    case ObservationType.Sleep:
      return local.observation.sleep.title;
    case ObservationType.Nutrition:
      return local.observation.nutritions.title;
    case ObservationType.LabResult:
      return configIds.labResult;
    case ObservationType.FloorsClimbed:
      return local.observation.floorsClimbed.title;
    default:
      return '';
  }
};

