const env = window.env || {};

export const authAudience = env.AUTH_AUDIENCE;
export const authClientId = env.AUTH_CLIENTID;
export const authDomain = env.AUTH_DOMAIN;
export const authRealm = env.AUTH_REALM;
export const authResponseType = env.AUTH_RESPONSE_TYPE;
export const authScope = env.AUTH_SCOPE;

export const appInsightsKey = '59c3a16a-4ca9-4559-9202-70f3acc9c061';

export const profilesApiRoot = env.API_ROOT_PROFILES;
export const healthDataApiRoot =  env.API_ROOT_HEALTH;
export const mobileAppRoot = env.MOBILE_APP_ROOT;
export const healthIndexRoot = env.HEALTH_INDEX_ROOT;
export const deleteProfileRoot = env.DELETE_PROFILE_ROOT;
export const abTestingRoot = env.AB_TESTING_ROOT;
export const healthCareProfileRoot = env.HEALTH_CARE_PROFILE_ROOT;
export const microserviceHFDataApiRoot = env.APP_API_ROOT_HFDATA;
export const profileViewDataRoot = env.PROFILE_VIEW_DATA_ROOT;
export const deviceManagerRoot = env.DEVICE_MANAGER_ROOT;
export const organizationRoot = env.ORGANIZATION_ROOT;
export const acsAdapterRoot = env.ACS_ADAPTER_ROOT;
export const goalServiceRoot = env.GOAL_SERVICE_ROOT;
export const studioRoot = env.STUDIO_API;

export const feedbackEmail = env.EMAIL_FEEDBACK || 'feedback@calciumhealth.com';
export const privacyEmail = env.EMAIL_PRIVACY || 'privacy@calciumhealth.com';
export const supportEmail = env.EMAIL_SUPPORT || 'support@calciumhealth.com';

export const syncAgainLimitMinutes = parseConfigInt(env.SYNC_AGAIN_LIMIT_MINUTES, 60);
export const identityRefreshMinutes = parseConfigInt(env.IDENTITY_REFRESH_MINUTES, 60);

export const sendAgainVerificationCodeLimitSeconds = parseConfigInt(env.SEND_AGAIN_VERIFICATION_CODE_LIMIT_SECONDS, 60);

export const buildVersion = window.WEB_APP_VERSION || '';

export const branchKey = env.BRANCH_KEY;

export const helpScoutBeaconId = env.HELPSCOUT_BEACON_ID;

export const marketPlaceUrl = env.MARKET_PLACE_URL;

export const privacyPolicyUrl = env.PRIVACY_POLICY_URL;
export const termsAndConditionsUrl = env.TERMS_AND_CONDITIONS_URL;
export const supportUrl = env.SUPPORT_URL;

export const calciumWebUrl = env.CALCIUMWEB_URL;

export const tempEHRCondition = parseConfigInt(env.TEMP_EHR_CONDITION_NUMERIC, 5);

export const defaultDashboardCirclePreferences = env.DEFAULT_DASHBOARD_CIRCLE_PREFERENCES;
export const defaultMedicationTimingPreferences = '08:00,17:00';

export const weeklyDatePickerFutureDays = 90;
export const noImmunizationPathwayId = env.NO_IMMUNIZATION_PATHWAY_ID || '';

export const paymentGatewayRoot = env.PAYMENT_GATEWAY_ROOT;


export const fileTypesAllowed = '.jpg,.jpeg,.png';
export const fileMaxSizeKB = 2048;
export const fileResizeWidth = 780;
export const fileResizeHeight = 420;
export const fileUploadLimit = 5;
export const fileMaxTextLength = 50;

function parseConfigInt(config, defaultValue) {
  var result = parseInt(config);
  return isNaN(result) ? defaultValue : result;
}
