import React from 'react';
import local from 'services/localization/local';
import { ObservationType, Unit } from 'services/enums';
import { routePaths } from 'services/routing';
import { configIds } from 'health/observations/observation-config-ids';
import { baseObservationConfig } from 'health/observations/observation-config-helpers.jsx';
import { Hips } from 'shared/fullColorIcons';
import { LogBodyHips } from './log/LogBodyHips';

const typeDefinition = {
  type: ObservationType.BodyHips,
  typeUnitMap: {
    [ObservationType.BodyHips]: Unit.Inches
  },
  unitLabelMap: {
    [Unit.Inches]: local.units.inches
  }
};

export default {
  ...baseObservationConfig,
  ...typeDefinition,
  id: configIds.bodyHips,
  backPath: { to: routePaths.health },
  centerElement: local.observation.bodyHips.title,
  logcenterElement: local.observation.bodyHips.logTitle,
  noDataText: local.observation.bodyHips.noDataText,
  iconSymbol: Hips,
  renderLogComponent: (props) => props && <LogBodyHips {...props} />
};
