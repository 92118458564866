import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import local from 'services/localization/local';
import { TextField, DateTimeTextFieldWrapper, Slider, TimeField } from 'shared/form-fields';
import * as validators from 'services/fields/field-validators';
import { Button } from 'shared';
import { intensitySliderOptions } from 'activity/helpers';
import PathwayContextDetails from 'health/observations/shared/PathwayContextDetails';

class LogActivityForm extends React.Component {
  render() {
    const { submitting, processing, handleSubmit, handleBack, pathwayContext } = this.props;
    const isSubmitting = submitting || processing;

    return (
      <form onSubmit={handleSubmit}>
        {pathwayContext ? <PathwayContextDetails pathwayContext={pathwayContext} /> : null}
        <div className="form-row">
          <DateTimeTextFieldWrapper
            readOnly={isSubmitting}
            label={local.activityStartDateTimeLabel}
            name="startDateTime"
            validate={[validators.required, validators.dateInPast]} />
        </div>
        <div className="form-row">
          <Field
            readOnly={isSubmitting}
            label={local.activityFormNameLabel}
            name="name"
            autoFocus
            component={TextField}
            validate={[validators.required, validators.maxLength250]} />
        </div>
        <div className="form-row">
          <Field
            readOnly={isSubmitting}
            label={local.activityDurationLabel}
            name="duration"
            validate={[validators.required, validators.timeLessThan1Day]}
            component={TimeField} />
        </div>
        <div className="form-row">
          <Field
            readOnly={isSubmitting}
            label={local.activityIntensityLabel}
            name="intensity"
            component={Slider}
            validate={[validators.required]}
            options={intensitySliderOptions} />
        </div>
        <div className="form-row">
          <Field
            readOnly={isSubmitting}
            label={local.formNotesLabel}
            name="notes"
            component={TextField}
            validate={[validators.maxLength500]}
            placeholder={local.activityFormNotesPlaceholder} />
        </div>
        <br />
        <br />
        <div className="form-row">
          <Button id="activity-form-cancel" className="btn btn-secondary" onClick={handleBack}>{local.formCancel}</Button>
          <Button
            id="activity-form-submit"
            className="btn btn-primary"
            submit
            color="blue">
            {local.formSave}
          </Button>
        </div>
      </form>
    );
  }
}

LogActivityForm.propTypes = {
  handleBack: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  // redux form
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool
};

const form = reduxForm({
  form: 'log-activity'
})(LogActivityForm);

export default form;
