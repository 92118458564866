import * as api from 'services/api/healthCareProfile-api';
import * as toastActions from 'app/toaster/actions';

export function saveEHRRequest(input) {
  const { profileId,userName,requestDataType, physicianName, providerOrganizationName, providerAddress,providerCity,providerState, providerEmail, phoneNumber, notes } = input;
  const requestForm = {
    physicianName,
    providerOrganizationName,
    providerAddress,
    providerEmail,
    providerCity,
    providerState,
    phoneNumber,
    notes,
    userName,
    requestDataType
  };

  var toastContent = requestDataType === 0 ? 'We’ve received your request and will be contacting this provider to obtain your electronic health records from them' :
    'We’ve received your request and will be contacting this provider to access your health data and records';

  return (dispatch,getState) => {
    const state = getState();
    api.postEHRRequest(profileId, requestForm, state)
      .then(response => {
        dispatch(toastActions.popSuccessToast(toastContent));
        return response;
      }).catch(error => {
        console.log(error);
        return null;
      });
  };


}


