import {
  PROFILE_MISSING,
  PROFILE_STATUSRESET,
  PROFILE_REQUESTED,
  PROFILE_LOADED,
  PROFILE_LOADERROR,
  PROFILE_SAVEREQUESTED,
  PROFILE_SAVED,
  PROFILE_NEWOTHERSAVED,
  PROFILE_SELFCREATED,
  PROFILE_SAVEERROR,
  PROFILE_LISTREQUESTED,
  PROFILE_LISTLOADED,
  PROFILE_LISTERROR,
  PROFILE_ONBOARDED,
  PROFILE_REMOVED,
  PROFILE_UPDATED,
  PROFILE_PREFERENCES_LOAD,
  PROFILE_DATA_UPDATED
} from './action-names';

export function profileMissing() {
  return {
    type: PROFILE_MISSING
  };
}

export function profileStatusReset() {
  return {
    type: PROFILE_STATUSRESET
  };
}

export function profileRequested() {
  return {
    type: PROFILE_REQUESTED
  };
}

export function profileLoaded(profile) {
  return {
    type: PROFILE_LOADED,
    payload: profile
  };
}

export function profileLoadError(message) {
  return {
    type: PROFILE_LOADERROR,
    payload: message
  };
}

export function profileSaveRequested() {
  return {
    type: PROFILE_SAVEREQUESTED
  };
}

export function profileSaved(profile) {
  return {
    type: PROFILE_SAVED,
    payload: profile
  };
}

export function newOtherProfileSaved(profile) {
  return {
    type: PROFILE_NEWOTHERSAVED,
    payload: profile
  };
}

export function selfProfileCreated(profile) {
  return {
    type: PROFILE_SELFCREATED,
    payload: profile
  };
}

export function profileSaveError(message) {
  return {
    type: PROFILE_SAVEERROR,
    payload: message
  };
}

export function profileListRequested() {
  return {
    type: PROFILE_LISTREQUESTED
  };
}

export function profileListLoaded(profile) {
  return {
    type: PROFILE_LISTLOADED,
    payload: profile
  };
}

export function profileListError(message) {
  return {
    type: PROFILE_LISTERROR,
    payload: message
  };
}

export function profileOnboarded(profileId) {
  return {
    type: PROFILE_ONBOARDED,
    payload: profileId
  };
}

export function removeProfileFromList(profileId) {
  return  {
    type: PROFILE_REMOVED,
    payload: profileId
  };
}

export function profileUpdated(profile) {
  return {
    type: PROFILE_UPDATED,
    payload: profile
  };
}

export function loadProfilePreferences(profilePrefrences) {
  return {
    type: PROFILE_PREFERENCES_LOAD,
    payload: profilePrefrences
  };
}

export function profileDataUpdated(profileId, profileData) {
  return {
    type: PROFILE_DATA_UPDATED,
    payload: {profileId, profileData}
  };
}
