import React from 'react';
import { useSelector } from 'react-redux';
import { getIncomingInvites } from 'profiles/sharing/sharing-selectors';
import IncomingInviteListItem from './IncomingInviteListItem';

export default function IncomingProfileInvitesList() {
  const invites = useSelector(getIncomingInvites);

  if (invites.length < 1) {
    return null;
  }

  return (
    <div className="invites">
      {invites.map(invite => <IncomingInviteListItem key={invite.id} invite={invite} />)}
    </div>
  );
}
