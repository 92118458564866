import * as actions from './action-names';

export function saveHealthInfoInsurance() {
  return {
    type: actions.HEALTHINFO_SAVING
  };
}

export function saveHealthInfoProvider() {
  return {
    type: actions.HEALTHINFO_SAVING
  };
}

export function saveHealthInfoInsuranceSuccess(healthinfo) {
  return {
    type: actions.HEALTHINFO_SAVE_SUCCESS,
    payload: healthinfo
  };
}

export function saveHealthInfoInsuranceError() {
  return {
    type: actions.HEALTHINFO_SAVE_ERROR
  };
}

export function saveMedicationReset() {
  return {
    type: actions.HEALTHINFO_SAVE_RESET
  };
}

export function loadMedications() {
  return {
    type: actions.HEALTHINFO_REQUESTED
  };
}

export function loadMedicationSuccess(payload) {
  console.log(payload);
  return {
    type: actions.HEALTHINFO_LOAD_SUCCESS,
    payload: payload
  };
}

export function loadMedicationError() {
  return {
    type: actions.HEALTHINFO_LOAD_ERROR
  };
}


