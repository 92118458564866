import React from 'react';
import PropTypes from 'prop-types';
import { DatePeriod } from 'shared';
import local from 'services/localization/local';

export default function ProcedureListItem({ procedure }) {
  const { name, description, location, provider, status, performed } = procedure;
  const content = local.healthData.procedures;

  return (
    <li className="card-list-item">
      <div className='status-row'>
        <DatePeriod value={performed} />
        <small>{status}</small>
      </div>
      <div className='procedure-text'>
        <span className='item-title'>{name}</span>
        {description && (<small>{description}</small>)}
        {provider && (<small>{content.provider}: {provider}</small>)}
        {location && (<small>{content.location}: {location}</small>)}
      </div>
    </li>
  );
}

ProcedureListItem.propTypes = {
  procedure: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    provider: PropTypes.string,
    location: PropTypes.string,
    status: PropTypes.string,
    performed: PropTypes.shape({
      start: PropTypes.string,
      startIncludesTime: PropTypes.bool,
      end: PropTypes.string,
      endIncludesTime: PropTypes.bool
    })
  })
};
