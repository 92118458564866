import { types } from 'profiles/sharing/sharing-types';
import compileReducers from 'services/compile-reducer';
import { actionNames } from './action-creators';
import * as persistentDataReducer from 'app/request/persistent-data-reducer';

export const initialState = {
  [types.PROFILE_BADGES]: persistentDataReducer.initialState,
  [types.BADGES_FOR_NOTIFICATION]: persistentDataReducer.initialState,
  [types.BADGES_ICONS]: persistentDataReducer.initialState,
  [types.SHOW_BADGE_ACHIEVED_SCREEN]: {
    [types.IS_SHOW]: false,
    [types.RETRY_COUNT]: 0
  }
};

const reducers = {
  ...persistentDataReducer.reducers(types.PROFILE_BADGES),
  ...persistentDataReducer.reducers(types.BADGES_FOR_NOTIFICATION),
  ...persistentDataReducer.reducers(types.BADGES_ICONS)
};

reducers[actionNames.SHOW_BADGES_ACHIEVED_SCREEN] = (state, payload) => {
  return {
    ...state,
    [types.SHOW_BADGE_ACHIEVED_SCREEN]: payload
  };
};

export default compileReducers(reducers, initialState);
