import React from 'react';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { Logo, Page } from 'shared';
import VerifiedArt from './art/VerifiedArt';
import AutoRedirect from './AutoRedirect';
import './connect.scss';

export default function ConnectHealthRecordsPage({redirectTo}) {
  const content = local.healthData.connect.status;
  const backpath = redirectTo ?? routePaths.health;

  return (
    <Page
      id="connect-component"
      backPath={{ to: backpath }}
      centerElement={<Logo />}>
      <div>
        <figure><VerifiedArt /></figure>

        <h4>{content.complete}</h4>

        <p>{content.successDetails}</p>

        <AutoRedirect redirectInSeconds={10} redirectTo={backpath}>
          <p>{local.healthData.connect.identityVerification.successNavigateMessage}</p>
        </AutoRedirect>
      </div>
    </Page>
  );
}
