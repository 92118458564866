import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LinkWrapper, NonAuthenticatedPageWall, ErrorSection } from 'shared';
import local from 'services/localization/local';
import { resetPasswordReset } from 'identity/actions';
import ResetPasswordForm from './ResetPasswordForm';

export class ResetPassword extends Component {
  componentWillUnmount() {
    this.props.resetPasswordReset();
  }

  render() {
    const { error, success } = this.props;
    const navAction = <LinkWrapper id='already-have-account-sign-in-nav' className="button gray btn btn-secondary" to="/">{local.linkSignIn}</LinkWrapper>;

    if (success) {
      return (
        <NonAuthenticatedPageWall id="reset-password-confirmation-page" titleTabText={local.resetPasswordSuccessTitle} navAction={navAction}>
          <h1>{local.resetPasswordSuccessTitle}</h1>
          <div className='pb-4'>{local.resetPasswordSuccessSubtitle}</div>
          <LinkWrapper to="/" id="link-to-sign-in" className="button blue btn btn-primary">{local.linkSignIn}</LinkWrapper>
        </NonAuthenticatedPageWall>
      );
    }

    return (
      <NonAuthenticatedPageWall id="reset-password-page" titleTabText={local.resetPasswordTitle} navAction={navAction}>
        <h1>{local.resetPasswordTitle}</h1>
        <ErrorSection error={error} />
        <ResetPasswordForm />
      </NonAuthenticatedPageWall>
    );
  }
}

ResetPassword.propTypes = {
  error: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  // actions
  resetPasswordReset: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  error: state.identity.resetPasswordError,
  success: state.identity.resetPasswordSuccess
});

const actions = { resetPasswordReset };

export default connect(mapStateToProps, actions)(ResetPassword);
