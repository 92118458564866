/* eslint-disable no-unused-vars */
import { isAndroid, isGooglefitEnabled, openMobileRoute } from 'mobile/helpers';
import { AppleHealthParam, GoogleFitType} from 'services/constants';
import { ObservationType } from 'services/enums';
import { routePaths } from 'services/routing';
import * as ObservationIcons from 'shared/fullColorIcons';

export const getObservationIcon = observationType => {
  switch(observationType){
    case ObservationType.InstantaneousHeartRate:
      return ObservationIcons.HeartRate;
    case ObservationType.BodyTemperature:
      return ObservationIcons.Temperature;
    case ObservationType.Height:
      return ObservationIcons.Height;
    case ObservationType.Weight:
      return ObservationIcons.Weight;
    case ObservationType.Steps:
      return ObservationIcons.Steps;
    case ObservationType.BodyArms:
      return ObservationIcons.Arms;
    case ObservationType.BodyChest:
      return ObservationIcons.Chest;
    case ObservationType.BodyFat:
      return ObservationIcons.BodyFat;
    case ObservationType.BodyHips:
      return ObservationIcons.Hips;
    case ObservationType.BodyThighs:
      return ObservationIcons.Thighs;
    case ObservationType.BodyWaist:
      return ObservationIcons.Waist;
    case ObservationType.BloodOxygen:
      return ObservationIcons.BloodOxygen;
    case ObservationType.Insulin:
      return ObservationIcons.Insulin;
    case ObservationType.PeakFlow:
      return ObservationIcons.PeakFlow;
    case ObservationType.BloodGlucose:
      return ObservationIcons.BloodGlucose;
    case ObservationType.Systolic:
    case ObservationType.Diastolic:
      return ObservationIcons.BloodPressure;
    case ObservationType.Immunization:
      return ObservationIcons.Immunizations;
    case ObservationType.Sleep:
      return ObservationIcons.Sleep;
    case ObservationType.Nutrition:
      return ObservationIcons.Nutrition;
    case ObservationType.LabResult:
      return ObservationIcons.Labs;
    case ObservationType.FloorsClimbed:
      return ObservationIcons.FloorsClimbed;
    case ObservationType.BloodKetone:
      return ObservationIcons.BloodKetone;
    case ObservationType.Exercise:
      return ObservationIcons.Activities;
    case ObservationType.HeartRateVariability:
      return ObservationIcons.HeartRateVariability;
    default:
      return ObservationIcons.GenericObservation;
  }
};

export function getDevices(props) {
  const { actions, profileId } = props;
  actions.loadDevices(profileId);
  actions.loadTerraDevices(profileId);
}

export function handleBack(props) {
  const { navigate, showHealthKit } = props;
  let navigateProps = { to: routePaths.healthDevices };
  if (showHealthKit) {
    const appleHealth = new URLSearchParams(location.search).get(AppleHealthParam);
    navigateProps = { to: routePaths.healthDevices + '?' + AppleHealthParam + '=' + appleHealth };
  }
  if(props?.location?.state?.backpath?.fromPath === routePaths.observationConnect){
    navigateProps = {to:routePaths.observationConnect, state:{backpath:{goalCategoryId:props.goalCategoryId}}};
  }
  navigate(navigateProps);
}

export function getFilteredDevices(props, googleFitConnected){
  const { devices, observationConnections } = props;
  const disconnectedDevices = [];

  if(devices && devices.length > 0){

    if(isAndroid && !googleFitConnected){
      devices && devices.length > 0 && disconnectedDevices.push(...devices);
    }
    else{
      devices && devices.length > 0 && disconnectedDevices.push(...devices?.filter(x=>x.type !== GoogleFitType));
    }
  }
  const programObservations = observationConnections?.data?.programObservationTypes?.filter(x=>x.observationType === props?.location?.state?.backpath?.observationType);
  let connectDevicesList=[];

  programObservations && programObservations.length > 0 && connectDevicesList.push(...programObservations[0]?.supportedDeviceSourceNames);

  if(connectDevicesList && connectDevicesList.length > 0){
    let filteredDevices = [];
    disconnectedDevices?.map((x) => {
      if(connectDevicesList.includes(x.type)){
        filteredDevices.push({
          connect_url: x.connect_url,
          connected: x.connected,
          connected_at: x.connected_at,
          disconnect_url: x.disconnect_url,
          display_name: x.display_name,
          last_processed_at: x.last_processed_at,
          logo_url: x.logo_url,
          type: x.type,
          provider : x.provider,
          validicDeviceStatusSynced: x.validicDeviceStatusSynced
        });
      }
    });
    return filteredDevices;
  }
  else{
    return devices;
  }

}
