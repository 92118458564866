export const IDENTITY_SIGNINREQUIRED = 'IDENTITY_SIGNINREQUIRED';
export const IDENTITY_SIGNINREQUESTED = 'IDENTITY_SIGNINREQUESTED';
export const IDENTITY_SIGNINSUCCESS = 'IDENTITY_SIGNINSUCCESS';
export const IDENTITY_SIGNINERROR = 'IDENTITY_SIGNINERROR';
export const IDENTITY_UNVERIFIEDEMAIL = 'IDENTITY_UNVERIFIFEDEMAIL';
export const IDENTITY_VERIFIEDEMAIL = 'IDENTITY_VERIFIFEDEMAIL';
export const IDENTITY_UNVERIFIEDEMAILRESET = 'IDENTITY_UNVERIFIFEDEMAILRESET';
export const IDENTITY_REFRESH_USERINFO_SUCCESS = 'IDENTITY_REFRESH_USERINFO_SUCCESS';

export const IDENTITY_REQUESTED = 'IDENTITY_REQUESTED';
export const IDENTITY_LOADED = 'IDENTITY_LOADED';
export const IDENTITY_LOADERROR = 'IDENTITY_LOADERROR';

export const IDENTITY_REGISTERUSER = 'IDENTITY_REGISTERUSER';
export const IDENTITY_REGISTERUSER_SUCCESS = 'IDENTITY_REGISTERUSER_SUCCESS';
export const IDENTITY_REGISTERUSER_ERROR = 'IDENTITY_REGISTERUSER_ERROR';

export const IDENTITY_RESET_PASSWORD = 'IDENTITY_RESET_PASSWORD';
export const IDENTITY_RESET_PASSWORD_SUCCESS = 'IDENTITY_RESET_PASSWORD_SUCCESS';
export const IDENTITY_RESET_PASSWORD_ERROR = 'IDENTITY_RESET_PASSWORD_ERROR';
export const IDENTITY_RESET_PASSWORD_RESET = 'IDENTITY_RESET_PASSWORD_RESET';

export const IDENTITY_UPDATEPREFERENCE = 'IDENTITY_UPDATEPREFERENCE';
export const USERNAME_SAVING ='USERNAME_SAVING';
export const USERNAME_SAVED ='USERNAME_SAVED';

export const IDENTITY_DISPLAY_VERIFICATION_SENT = 'IDENTITY_DISPLAY_VERIFICATION_SENT';

export const IS_FORCE_REGISTRATION = 'IS_FORCE_REGISTRATION';

export const UPDATE_PROFILE_DELETION_STATUS = 'UPDATE_PROFILE_DELETION_STATUS';

export const IS_ADMIN_FOR_ANY_ORGANIZATION = 'IS_ADMIN_FOR_ANY_ORGANIZATION';

export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';

export const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR';


export const AB_TESTING_SEGMENT = 'AB_TESTING_SEGMENT';
export const AB_TESTING_SEGMENT_ERROR = 'AB_TESTING_SEGMENT_ERROR';
