/* eslint-disable react/jsx-no-leaked-render */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-newline */
import React, { useEffect, useState } from 'react';
import MobileBackButtonController from 'mobile/MobileBackButtonController';
import ProgramDashboard from 'programs/dashboard/ProgramDashboard';
import ProfilePage from 'profiles/page/ProfilePage';
import ManageProfileNav from 'profiles/navigation/ManageProfileNav';
import local from 'services/localization/local';
import Logo from 'shared/logos/svg-mark/SvgMark.jsx';
import InvitesDashboardSection from 'dashboard/InvitesDashboardSection';
import './newDashboard.scss';
import NewDashboardCircle from 'dashboard/NewDashboardCircle';
import { getProfilePreferences } from 'profiles/action-creators';
import { getUniqueId } from 'services/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { getProfileId } from 'app/selectors';
import { Icon, LinkWrapper, Loading, ModalWrapper, ProfileLink } from 'shared';
import { routePaths } from 'services/routing';
import BadgeNotificationSection from './BadgeNotificationSection';
import { useHistory } from 'react-router-dom';
import { resetPassword } from 'identity/action-creators';
import { loadGoldPayment, loadPlatinumPayment } from 'products/products-actions';
import { getSelfProfileId } from 'profiles/list/selectors';
import { getIdentityId } from 'identity/selectors';
import { Feature, OrganizationFeatureType } from 'services/enums';
import { loadApplicationFeatures } from 'features/features-actions';
import { isIphone, openMobileRoute } from 'mobile/helpers';
import { getConnectedDevices, getDevicesLoaded } from 'devices/devices-selectors.js';
import { AppleHealthType } from 'services/constants';
import Appointment from 'appointment/DashboardAppointment';
import { getDashboardAppointment } from 'appointment/appointment-selector';
import { loadDashboardAppointment } from 'appointment/action-creators';
import { getOrganizationInvites } from 'organizations/organizations-selectors';
import { getActiveUsersProgramInvites } from 'programs/programs-selectors';
import { loadWhiteLabels } from 'organizations/organizations-actions';
import { loadChatOrganizationDetail, loadUnReadChatMessageCount } from 'chat/action-creators';


export default function NewDashboard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const profileId = useSelector(getProfileId);
  const selfProfileId = useSelector(getSelfProfileId);
  const identityId = useSelector(getIdentityId);
  const email = useSelector(state => state.identity.email);
  const resetPasswordSuccess = useSelector(state => state.identity.resetPasswordSuccess);
  const { isForcePasswordReset } = useSelector(state => state.identity.self);
  const [isOpenPasswordResetModal, setIsOpenPasswordResetModal] = useState(false);
  const deviceList = useSelector(state => getConnectedDevices(state, profileId));
  const appleDevice = deviceList && deviceList !== undefined && deviceList.find(device => device.type === AppleHealthType);
  const appleDeviceStatus = appleDevice ? appleDevice.validicDeviceStatusSynced === false ? null : appleDevice.connected : null;
  const devicesLoaded = useSelector(state => getDevicesLoaded(state, profileId));
  const { data, loading, hasError } = useSelector(state => getDashboardAppointment(state));
  const permissions = useSelector(state => getActiveUsersProgramInvites(state));
  const result = useSelector(getOrganizationInvites);
  const appointmentCount = 1;
  const organizationId = result?.data && result?.data.length > 0 ? result.data[0]?.organizationId : permissions && permissions.length > 0 ? permissions[0]?.organizationId : null;
  const chatFeatureType = OrganizationFeatureType.Chat;

  useEffect(() => {
    dispatch(getProfilePreferences(profileId, getUniqueId()));
    dispatch(loadGoldPayment(selfProfileId));
    dispatch(loadPlatinumPayment(selfProfileId));
    dispatch(loadApplicationFeatures(identityId, [Feature.CalciumCoach]));
    dispatch(loadDashboardAppointment(profileId, appointmentCount));
    dispatch(loadChatOrganizationDetail(profileId, chatFeatureType));
    if (organizationId) {
      dispatch(loadWhiteLabels(profileId, organizationId));
    }
  }, [profileId, organizationId]);

  useEffect(() => {
    if (devicesLoaded && isIphone) {
      openMobileRoute(`updateapplehealthstatus?profileId=${profileId}&appleHealthConnected=${appleDeviceStatus}`);
    }
  }, [devicesLoaded]);

  useEffect(() => {
    (async () => {
      dispatch(loadUnReadChatMessageCount(profileId));
    })();
  }, []);

  if (isForcePasswordReset === true && isOpenPasswordResetModal !== true)
    setIsOpenPasswordResetModal(true);

  function resetThePassword() {
    dispatch(resetPassword({ email }));
  }

  if (resetPasswordSuccess === true) {
    // eslint-disable-next-line react/jsx-no-undef
    history.push(routePaths.signout);
  }

  return (
    <ProfilePage
      id="newdashboard-page"
      centerElement={
        <LinkWrapper to={routePaths.healthIndex} id="hc-logo">
          <div className='hc-logo-width'> <Logo /> </div>
        </LinkWrapper>
      }
      primaryAction={
        <LinkWrapper to={routePaths.healthBadgeSummary} data-testid='achievements-badge' id="star-badge" className="badgeColor">
          <Icon symbol="badgeStar"></Icon>
        </LinkWrapper>
      }
      titleTabText={local.healthDashboardHeader}>
      <MobileBackButtonController exitApp />

      <div className="invite-list">
        <section id="dashboard-invites-section">
          <BadgeNotificationSection />
          <InvitesDashboardSection />
        </section>
      </div>

      <NewDashboardCircle />

      {data && !loading && !hasError &&
        <div className="lil-chart-section">
          <Appointment data={data} loading={loading} hasError={hasError}></Appointment>
        </div>}
      {loading &&
        <section id="program-section">
          <div className='program-dashboard-tile'>
            <Loading></Loading>
          </div>
        </section>}

      <div className="lil-chart-section">
        <ProgramDashboard />
      </div>

      <ManageProfileNav />

      <ul className="scrollview-list">
        <li className="scrollview-list-item">
          <LinkWrapper
            id="health-care-link"
            className='with-padding with-chevron'
            to={routePaths.healthInfo}>
            <Icon symbol="healthprograms" />
            <span>{local.dashboard.healthInfo}</span>
          </LinkWrapper>
        </li>
      </ul>

      <ModalWrapper className='card-modal-wrapper upgrade-pathway-modal' isOpen={isOpenPasswordResetModal}>
        <div className='card-modal'>
          <div className="content">
            <div>{local.forceResetPasswordText1}</div>
            <br />
            <div>{local.resetPasswordSuccessSubtitle} </div>
            <br />
            <div>{local.forceResetPasswordText2} <a mailto='support@calciumhealth.com' >support@calciumhealth.com</a></div>


            <div className="sticky-button-section action" onClick={resetThePassword}>
              <div className="button blue">{local.forceResetPasswordActionButton}</div>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </ProfilePage>
  );
}
