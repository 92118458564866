import React from 'react';
import PropTypes from 'prop-types';
import { ProfileLink, Icon } from 'shared';
import ProgramSubsriptionLink from './ProgramSubsriptionLink';
import { getSubscription } from 'preferences/billing/Subscription';
class ProgramListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSubscriptionLink: false,
      userSubscription: getSubscription()
    };
    this.closeConfirm = this.closeConfirm.bind(this);
    this.openSubscription = this.openSubscription.bind(this, false);
  }

  closeConfirm() {
    if (this.props.closeConfirm) {
      this.props.closeConfirm();
    }
  }

  openSubscription() {
    this.setState({ openSubscriptionLink: !this.state.openSubscriptionLink });
  }

  render() {
    const { id, logName, to, params, programInfo: { name, iconPath, durationInDays, premiumType } } = this.props;
    const isPremiumPathway = premiumType && premiumType > 0;
    const hasAccess = (isPremiumPathway && this.state.userSubscription?.type >= premiumType);
    console.log(hasAccess,premiumType);
    const renderIcon = () => {
      if (iconPath) {
        return <img className='icon-image' src={iconPath} />;
      } else {
        return <figure className='icon-image'></figure>;
      }
    };

    const renderProgram = () => {
      return (
        <div className="program-text">
          <small className="program-title">{name}</small>
          <small className="program-description">{durationInDays} days</small>
        </div>
      );
    };

    return (
      <React.Fragment>
        <li className="program card-list-item">
          {(isPremiumPathway && !hasAccess) ?
            <a id={id} className='program-list-item-link' onClick={this.openSubscription}>
              {renderIcon()}
              {renderProgram()}
              {this.state.openSubscriptionLink === true ? <ProgramSubsriptionLink isPathway={true} isConfirmOpenModel={this.state.openSubscriptionLink} closeModel={this.openSubscription} content='Upgrade to a premium tier to access this advanced pathway.'></ProgramSubsriptionLink> : null}
            </a> :
            <ProfileLink id={id} logName={logName} className='program-list-item-link' to={to} params={params}>
              {renderIcon()}
              {renderProgram()}
            </ProfileLink>
          }
          {this.props.showCloseButton ?
            <div style={{ cursor: "pointer", color: "#049FDA", marginTop: '8px' }} onClick={this.closeConfirm}>
              <Icon symbol="times" />
            </div> :
            <div className='premium-label'>
              <span>{isPremiumPathway ? 'Premium' : 'Free'}</span>
            </div>
          }
        </li>
      </React.Fragment>
    );
  }
}

ProgramListItem.propTypes = {
  id: PropTypes.string.isRequired,
  logName: PropTypes.string,
  to: PropTypes.string.isRequired,
  params: PropTypes.shape().isRequired,
  programInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string
  }).isRequired,
  action: PropTypes.node
};

export default ProgramListItem;
