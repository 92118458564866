import LilChart from 'health/lil-charts/LilChart';
import useObservationData from 'health/observations/hooks/useObservationData';
import { getBmis, getCurrentBmi, getCurrentBmiValueFormatted } from 'health/selectors-bmi';
import React from 'react';
import { useSelector } from 'react-redux';
import { LilChartDataPointCount } from 'services/constants';
import { ObservationType } from 'services/enums';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { Icon } from 'shared';
import styleVars from 'styles/_variables.scss';

export default function BodyMeasurementsLilChart() {
  const [loading, loadError] = useObservationData([ObservationType.Height, ObservationType.Weight], LilChartDataPointCount);
  const bmis = useSelector(state => getBmis(state));
  const currentBmi = getCurrentBmi(bmis);
  const currentBmiValue = getCurrentBmiValueFormatted(currentBmi);

  if (!currentBmi) {
    return null;
  }

  return (
    <LilChart
      metricIcon={<Icon symbol="body" className="hero" size="3em" />}
      data={bmis}
      noDataText={local.noBodyMeasurementsData}
      color={styleVars.colorTeal}
      title={local.tileBodyMeasurementsTitle}
      metricValue={currentBmiValue}
      path={{ to: routePaths.healthBodyMeasurements }}
      noDataPath={{ to: routePaths.healthBodyMeasurements }}
      loading={loading}
      loadError={loadError} />
  );
}
