import React from 'react';
import { useSelector } from 'react-redux';
import OrganizationInvitesListItem from './OrganizationInvitesListItem';
import { getOrganizationInvites } from 'organizations/organizations-selectors';
import { getActiveUsersProgramInvites } from '../../programs/programs-selectors';


export default function OrganizationInvitesList() {
  const { data } = useSelector(getOrganizationInvites);
  const permissions = useSelector(state => getActiveUsersProgramInvites(state));

  if (data && data.length < 1 && permissions && permissions.length < 1) {
    return null;
  }

  return (
    <div>
      {
        data && data.length > 0 &&
        data.map(invite => {
          return (
            <OrganizationInvitesListItem
              key={invite.organizationMembershipId}
              membershipId={invite.organizationMembershipId}
              organizationId={invite.organizationId}
              organizationName={invite.organizationName}
              organizationDescription={invite.organizationDescription}
              role={invite.role}
              profileId={invite.profileId}
              rootOrganizationName={invite.rootOrganizationName}
              isGroupInvite={true} />
          );
        })
      }
      {
        permissions && permissions.length > 0 &&
        permissions.map(invite => {
          return (
            <OrganizationInvitesListItem
              key={invite.memberId}
              membershipId={invite.memberId}
              organizationId={invite.organizationId}
              organizationName={invite.organizationName}
              organizationDescription={invite.organizationDescription}
              role={invite.role}
              profileId={invite.profileId}
              rootOrganizationName={invite.rootOrganizationName}
              isGroupInvite={false} />
          );
        })
      }
    </div>

  );
}
