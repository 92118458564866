import React, { useEffect } from 'react';
import { ModalWrapper} from 'shared';
import local from 'services/localization/local';
import { NavLink } from 'react-router-dom';
import { routePaths } from 'services/routing';
import { signOut } from 'services/auth0-service';
import { isMobile, openMobileRoute } from 'mobile/helpers';

function SignoutDeleteAccountModal() {
  const DELETE_PROFILE_DELAYED_SIGNOUT = 10000;
  useEffect(() => {
    setTimeout(() => {
      if (isMobile) {
        openMobileRoute('signout');
      } else {
        signOut();
      }
    }, DELETE_PROFILE_DELAYED_SIGNOUT);
  }, []);

  return (
    <ModalWrapper isOpen>
      <div id="delete-account-modal" data-testid="delete-account" className="modal-container">
        <div className="modal-header">
          <div></div>
          <div className='modal-title'>
            {local.formatString(local.signoutHeaderMessage)}
          </div>
        </div>
        <div className="modal-body">
          <div className="verify-successMsg">
            <div className="error-msg-title">
              {local.formatString(local.signoutMessage)}
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <NavLink id="cancel-delete-account-signout" className='btn btn-outline-dark small-btn' to={routePaths.signout}>
            <span>{local.formCancel}</span>
          </NavLink>
        </div>
      </div>
    </ModalWrapper>
  );
}

export default SignoutDeleteAccountModal;
