export const PROFILE_MISSING = 'PROFILE_MISSING';
export const PROFILE_STATUSRESET = 'PROFILE_STATUSRESET';

export const PROFILE_REQUESTED = 'PROFILE_REQUESTED';
export const PROFILE_LOADED = 'PROFILE_LOADED';
export const PROFILE_LOADERROR = 'PROFILE_LOADERROR';

export const PROFILE_SAVEREQUESTED = 'PROFILE_SAVEREQUESTED';
export const PROFILE_SAVED = 'PROFILE_SAVED';
export const PROFILE_NEWOTHERSAVED = 'PROFILE_NEWOTHERSAVED';
export const PROFILE_SELFCREATED = 'PROFILE_SELFCREATED';
export const PROFILE_SAVEERROR = 'PROFILE_SAVEERROR';

export const PROFILE_LISTREQUESTED = 'PROFILE_LISTREQUESTED';
export const PROFILE_LISTLOADED = 'PROFILE_LISTLOADED';
export const PROFILE_LISTERROR = 'PROFILE_LISTERROR';

export const PROFILE_ONBOARDED = 'PROFILE_ONBOARDED';

export const PROFILE_REMOVED = 'PROFILE_REMOVED';

export const PROFILE_UPDATED = 'PROFILE_UPDATED';

export const PROFILE_PREFERENCES_LOAD ='PROFILE_PREFERENCE_LOAD';

export const PROFILE_PREFERENCES_CREATE_UPDATE='PROFILE_PREFERENCES_CREATE_UPDATE';

export const PROFILE_DATA_UPDATED = 'PROFILE_DATA_UPDATED';
