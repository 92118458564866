import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loading, Page, ProfileRedirect, ErrorSection } from 'shared';
import { routePaths } from 'services/routing';
import { getUniqueId } from 'services/helpers';
import { getProfile } from 'profiles/list/selectors';
import local from 'services/localization/local';
import { getRequest } from 'app/request/request-selectors';
import { submitInviteCode, loadOrganizationInvites } from 'organizations/organizations-actions';
import { OrganizationMemberStatus } from 'services/enums';
import { getInviteByMembershipId, getInviteCodeFromRoute } from 'organizations/organizations-selectors';
import OrganizationInviteCodeForm from './OrganizationInviteCodeForm';
import './invite-code.scss';


export default function OrganizationInviteDetailsPage(props) {
  const dispatch = useDispatch();
  const currentProfile = useSelector(getProfile);
  const [requestId] = useState(getUniqueId());
  const inviteCode = getInviteCodeFromRoute(props);
  const { success, processing, result, hasError, error } = useSelector(state => getRequest(state, requestId));
  const invite = useSelector(state => getInviteByMembershipId(state, (result || {}).organizationMemberId));
  const submit = useCallback(values  => {  dispatch(submitInviteCode(values, requestId)); }, [requestId]);

  useEffect(() => {
    if (success) {
      dispatch(loadOrganizationInvites());
    }
  }, [success]);

  if (success && invite) {
    const params = {
      organizationId: invite.organizationId,
      membershipId: invite.organizationMembershipId,
      hasProperties: (result.properties && result.properties.length > 0) || null
    };

    return <ProfileRedirect to={routePaths.organizationsInviteDetails} params={params} />;
  }

  if (hasError && error.status === 409 && error.body.organizationMemberStatus === OrganizationMemberStatus.Pending) {
    const params = {
      organizationId: error.body.organizationId,
      membershipId: error.body.organizationMemberId
    };

    return <ProfileRedirect to={routePaths.organizationsInviteDetails} params={params} />;
  }
  let bodyContent = <Loading />;
  if (!success) {
    bodyContent = (
      <div id='invite-code-content'>
        <div>{local.organizations.inviteCodeIntro}</div>
        <OrganizationInviteCodeForm inviteCode={inviteCode} submit={submit} processing={processing} />
        {hasError && error.status === 404 && (<ErrorSection>{local.organizations.inviteCodeNotFound}</ErrorSection>)}
        {hasError  && error.body.organizationMemberStatus === OrganizationMemberStatus.Active && error.status === 409   && (<ErrorSection> {local.formatString(local.organizations.organizationMembershipAlreadyExists, (<strong>{currentProfile.name}</strong>))}</ErrorSection>)}
      </div>
    );
  }

  return (
    <Page
      id='organization-invite-code'
      centerElement={local.organizations.organizationInviteHeader}
      backPath={{ to: routePaths.people }}>
      {bodyContent}
    </Page>
  );
}
