/* eslint-disable react/jsx-newline */
/* eslint-disable react/prop-types */
import React,{useEffect} from 'react';
import { Loading, Page} from 'shared';
import './AppointmentDetail.scss';
import moment from 'moment';
import { CancelledAppointment } from 'services/constants';
import local from 'services/localization/local';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileId } from 'app/selectors';
import { loadAppointmentDetails } from './action-creators';
import { getAppointmentDetails } from './appointment-selector';
import { getQueryStringParams } from 'services/url-service';

export default function AppointmentDetailsPage(props){
  const query = getQueryStringParams(location);
  const dispatch = useDispatch();
  let data = props.location?.state?.backpath?.appointment;
  const profileId = useSelector(getProfileId);
  const { appointment} = useSelector(state => getAppointmentDetails(state));

  if(!data && query?.id){
    useEffect(()=>{
      dispatch(loadAppointmentDetails(profileId, query?.id));
    },[profileId]);
  }

  if(!data && query?.id){
    data = appointment;
  }

  if(data){
    return (
      <Page
        id="notifications-page"
        backPath={{to:props.location?.state?.backpath?.fromPath}}
        centerElement={data.provider}
        readOnlyProfile>
        <div className='appointment-details-container' >
          {data.appointmentStatus === CancelledAppointment &&
          <div className='appointment-cancelled'>
            <div className='appointment-cancelled-title'>{local.appointment.cancelledAppointment}</div>

            <div className='appointment-cancelled-reason'>{local.appointment.cancelledReason} {data.cancelledReason}. <span>{local.appointment.provider}: {data.provider}.</span></div>

            <div className='appointment-cancelled-time'>{local.appointment.cancelled} {moment(data.cancelledDate).format('ddd')} {moment(data.cancelledDate).format('MMM')} {moment(data.cancelledDate).format('Do')}, {moment(data.cancelledDate).format('hh:mm A')}</div>
          </div>}

          <div className='appointment-info'>
            <div className={data.appointmentStatus === CancelledAppointment ? 'appointment-status cancelled' : 'appointment-status booked'}><span>{data.appointmentStatus}</span></div>

            <div className='drName'>{data.doctorName}</div>

            <small className='provider-name'>{local.appointment.provider} : {data.provider}</small>

            <div className='appointment-type'><small>{data.consultationType}</small></div>

            <div className='appointment-description-que'>{local.appointment.when}</div>

            <div className='appointment-description-ans'>{moment(data.appointmentStartDate).format('ddd')} {moment(data.appointmentStartDate).format('MMM')} {moment(data.appointmentStartDate).format('Do')}, {moment(data.appointmentStartDate).format('hh:mm A')}</div>

            <div className='appointment-description-que'>{local.appointment.where}</div>

            <div className='appointment-description-ans'>{data.location}</div>

            <div className='appointment-description-que'>{local.appointment.appointmentReason}</div>

            <div className='appointment-description-ans'>{data.reason}</div>

            <div className='appointment-description-que'>{local.appointment.description}</div>

            <div className='appointment-description-ans'>{data.description}</div>

            <div className='appointment-description-que'>{local.appointment.patientInstruction}</div>

            <div className='appointment-description-ans'>{data.patientInstruction}</div>
          </div>
        </div>
      </Page>
    );
  }
  else{
    return(
      <Loading></Loading>
    );
  }
}
