import React from 'react';
import { LinkWrapper } from 'shared';
import { routePaths } from 'services/routing';
import local from 'services/localization/local';

export default function AllProgramsLink() {
  return (
    <LinkWrapper id="all-programs" to={routePaths.programs}>
      {local.dashboard.allResults}
    </LinkWrapper>
  );
}
