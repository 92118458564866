/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-max-depth */
import React, { useEffect,useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProfileId, getHealthScore } from 'app/selectors';
import { loadHealthScore, loadHealthScoreTrendData } from '../health/action-creators';
import { routePaths, programsContexts, buildPath } from 'services/routing';
import { Icon, Logo, Page, LinkWrapper , FormattedText,Button } from 'shared';
import 'dashboard/HealthIndex.scss';
import {
  buildStyles,
  CircularProgressbarWithChildren
} from 'react-circular-progressbar';
import AnimatedProgressProvider from './AnimatedProgressProvider';
import { easeQuadInOut } from 'd3-ease';
import local from 'services/localization/local';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { loadSuggestedPathways , setContext } from 'programs/programs-actions';
import * as selectors from 'programs/programs-selectors';
import PropTypes from 'prop-types';
import HealthIndexTrendChart from './HealthIndexTrendChart';
import {getHealthScoreTrends} from 'health/health-selectors.js';
import moment from 'moment';

export default function HealthIndex(props) {
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const healthIndex = useSelector(getHealthScore);
  const [show,setShow]=useState(props.location?.state?.backpath == undefined);
  const endDate =  moment();
  const startDate =moment().subtract(30, 'days');
  const data = useSelector(state => getHealthScoreTrends(state,profileId,startDate,endDate));

  useEffect(() => {
    if (profileId) {
      dispatch(loadHealthScore(profileId));
      dispatch(loadHealthScoreTrendData(profileId));
      dispatch(loadSuggestedPathways(profileId));
    }
  }, [profileId]);

  useEffect(() => {
    dispatch(setContext(programsContexts.healthIndex));
  }, []);

  const programStatus = useSelector(state => selectors.getSuggestedPathways(state,profileId));

  const trimDescription = (description) =>{
    let trimmedDescription = description.substr(0, 257);
    return trimmedDescription = trimmedDescription.substr(0, Math.min(trimmedDescription.length, trimmedDescription.lastIndexOf(' '))).concat('...');
  };
  const loadPathways = ()=>{

    if(programStatus !== null && programStatus.data !== undefined &&  programStatus.data !== null){
      return  programStatus.data.map((program)=>(
        <LinkWrapper key={program.programId} to={buildPath(routePaths.programsEnroll, {programId: program.programId})} params={{profileId}} backpath={routePaths.healthIndex} id={`program-link-${program.id}`}>
          <ul className='card-list box-width'>
            <li className='card-list-item'>
              <div className="program-tile">
                {/* <div className='hero-image' style={{ backgroundImage: `url(${program.headerImagePath})` }} /> */}
                <div className="program-enrollment-info">
                  <img className='icon-image' src={program.iconPath} />
                  <strong className="program-title" title={program.name}>{program.name}</strong>
                  {/* <ProgramEnrollButton programInfoId={program.id} /> */}
                </div>
                <FormattedText text={program.description.length > 258 ? trimDescription(program.description) : program.description} />
              </div>
            </li>
          </ul>
        </LinkWrapper>

      ));
    }
  };
  return (
    <Page
      id='health-index-component'
      backPath={{to:props.location?.state?.backpath ?? routePaths.root}}
      centerElement={<Logo />}>
      <div
        data-testid='health-index-today'
        className='healthIndexToday'>
        {local.healthIndex.healthIndexToday}
      </div>
      {show &&
      <div className='healthIndex-Progress-Index'>
        <AnimatedProgressProvider
          valueStart={0}
          valueEnd={healthIndex !== null ? healthIndex : 0}
          duration={1}
          easingFunction={easeQuadInOut}
          repeat={false}>
          {(value) => {
            const roundedValue = Math.round(value);
            return (
              <CircularProgressbarWithChildren
                value={value}
                maxValue={1000}
                circleRatio={0.5}
                styles={buildStyles({
                  rotation: 1 / 2 + 1 / 4,
                  pathTransition: 'none',
                  pathColor: '#959494',
                  trailColor: '#e8ecee'
                })}>
                <div className='healthIndex-content'>
                  <div className='healthIndex-count'>
                    {roundedValue === 0 ? 0 : roundedValue}
                  </div>
                  <div className='healthIndex-total'>
                    {local.totalHealthScore}
                  </div>
                </div>
              </CircularProgressbarWithChildren>
            );
          }}
        </AnimatedProgressProvider>
      </div>}
      {!show &&
      <div className='healthIndex-trendChart' >
        <HealthIndexTrendChart data={data} />
      </div>}

      <div className='healthIndex-tab-view'>
        <div className="btn-group" data-toggle="buttons" role="radiogroup" aria-labelledby="flight-options">
          <Button id="btnToday" className={show ? 'btn btn-primary' : 'btn'} onClick={() => setShow(true)} role="radio">{local.healthIndex.todayTitle}</Button>
          <Button id="btnPastMonth" className={show ? 'btn' : 'btn btn-primary'} onClick={() =>setShow(false)} role="radio">{local.healthIndex.pastMonthTitle}</Button>
        </div>
      </div>

      <div className='healthIndex-list'>
        <div className='health-index-heading'>
          {local.healthIndex.improveAccuracyOfHealthIndex}
        </div>

        <ul className='card-list'>
          <li className='card-list-item'>
            <LinkWrapper
              className='with-chevron with-padding'
              id='edit-profile-link'
              to={routePaths.peopleEdit}
              backpath={routePaths.healthIndex}>
              <Icon symbol='editprofile' />
              <div className='healthIndex-wrapper'>
                <div className='healthIndex-header'>
                  {local.healthIndex.isProfileAccurate}
                </div>
                <div className='healthIndex-Subheader'>
                  {local.healthIndex.ageAndGender}
                </div>
              </div>
            </LinkWrapper>
          </li>
          <li className='card-list-item'>
            <LinkWrapper
              className='with-chevron with-padding'
              id='health-devices-link'
              to={routePaths.healthDevices}
              backpath={routePaths.healthIndex}>
              <Icon symbol='devices' />
              <div className='healthIndex-wrapper'>
                <div className='healthIndex-header'>
                  {local.healthIndex.connectYourDevice}
                </div>
                <div className='healthIndex-Subheader formatted-text'>
                  <ul>
                    <span>{local.healthIndex.activityTrackers}</span>
                    {/* <li>{local.healthIndex.smartScales}</li>
                    <li>{local.healthIndex.bloodPressure}</li> */}
                  </ul>
                </div>
              </div>
            </LinkWrapper>
          </li>
          <li className='card-list-item'>
            <LinkWrapper
              className='with-chevron with-padding'
              style={{ padding: 15 }}
              id='health-data-link'
              to={routePaths.health}
              backpath={routePaths.healthIndex}>
              <svg
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
                className='ui-icon undefined health-data-index-icon'
                fill='currentcolor'>
                <g>
                  <g>
                    <path d='M20,6h-4V4c0-1.1-0.9-2-2-2h-4C8.9,2,8,2.9,8,4v2H4C2.9,6,2,6.9,2,8v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8 C22,6.9,21.1,6,20,6z M10,4h4v2h-4V4z M20,20H4V8h16V20z' />

                    <polygon points='13,10 11,10 11,13 8,13 8,15 11,15 11,18 13,18 13,15 16,15 16,13 13,13' />
                  </g>
                </g>
              </svg>
              <div className='healthIndex-wrapper'>
                <div className='healthIndex-header'>
                  {local.healthIndex.addMoreInformation}
                </div>
                <div className='healthIndex-Subheader'>
                  {local.healthIndex.heightWeight}
                </div>
              </div>
            </LinkWrapper>
          </li>
        </ul>
      </div>
      <div className="suggested_pathway">
        <span className="pathway-text">{local.suggestedPathways}</span>
        {/* <small><Link to={routePaths.programsList}>{local.showAll}</Link></small> */}
      </div>
      <div className="carousel-div">
        <Carousel interval="3000" transitionTime="500" centerMode showThumbs={false} >
          {loadPathways()}
        </Carousel>
      </div>
    </Page>
  );
}

HealthIndex.propTypes = {
  location: PropTypes.shape()
};


