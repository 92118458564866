import React, { useEffect, useState } from 'react';
import { getProfileId } from 'app/selectors';
import { helpScoutBeaconId } from 'app/config';
import { useSelector } from 'react-redux';
import * as beaconService from 'services/helpscout-beacon-service';
import { Redirect } from 'react-router';
import local from 'services/localization/local';
import { localStoragePaths } from 'services/constants';

export default function FeedBackLink() {
  const name = useSelector(state => state.profile.self.name);
  const email = useSelector(state => state.identity.email);
  const profileId = useSelector(getProfileId);
  const [isBeaconInitialized, setBeaconInitialized] = useState(false);
  const [isBeaconOpen, setBeaconOpen] = useState(false);
  let redirect = '';

  if (localStorage.getItem(localStoragePaths.redirectUrl)) {
    redirect = localStorage.getItem(localStoragePaths.redirectUrl);
  }

  useEffect(() => {
    if (isBeaconInitialized) {
      beaconService.logout();
      beaconService.setIdentity(name, email);
      beaconService.prefillUserInformation(name, email);
    }
  }, [name, email, profileId]);

  function handleSupportClick() {
    if (redirect && isBeaconInitialized) {
      localStorage.removeItem(localStoragePaths.redirectUrl);
      return (<Redirect to={redirect} />);
    }

    if (isBeaconOpen)
      return;

    if (!isBeaconInitialized) {
      beaconService.initialize(helpScoutBeaconId);
      beaconService.setDefaultConfiguration();
      beaconService.setIdentity(name, email);
      beaconService.prefillUserInformation(name, email);
      beaconService.prefillSubjectAndMessage(local.helpscout.heathSync.subject, local.helpscout.heathSync.message);
      beaconService.navigateAsk();
      beaconService.addCallbackEvent('close', onBeaconClose);
      setBeaconInitialized(true);
    }
    beaconService.open();
  }

  function onBeaconClose() {
    setBeaconOpen(false);
  }

  return (
    <div>
      {handleSupportClick()}
    </div>
  );
}
