import { any } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import local from 'services/localization/local';
import { Avatar, Page } from 'shared';
import { getChatComponentProps,getChatParticipants } from './chat-selector';
import './chat.scss';
import React, { useEffect, useState } from 'react';
import ChatCompositePage from 'chat/ChatCompositePage';
import ChatEmergencyTextPage from 'chat/ChatEmergencyTextPage';
import { loadGetParticipants,loadChatOrganizationDetail } from 'chat/action-creators';
import { OrganizationFeatureType, WhiteLabelProperties } from 'services/enums';
import { localStoragePaths , ImageTypes, InviteBtnColor, InviteColor, InviteFontColor } from 'services/constants';
import { loadWhiteLabels } from 'organizations/organizations-actions';
import { getWhiteLabel } from 'organizations/organizations-selectors';
import { Redirect } from 'react-router';
import { baseUrl } from 'services/url-service';

export default function ChatPage() {

  const dispatch = useDispatch();

  const chatFeatureType = OrganizationFeatureType.Chat;
  const userName = useSelector(state => state.profile.self.firstName) + ' ' + useSelector(state => state.profile.self.lastName);
  const profileId = useSelector(state => state.profile?.self?.id);
  const participantDetail = useSelector(state => getChatParticipants(state));
  const [chat911TextClosed, setChat911TextClosed] = useState(localStorage.getItem(localStoragePaths.chat911TextClosed));
  const avatar = [];
  var orgName = any;
  var rootOrganizationId = any;
  let redirect = {baseUrl};

  const { whiteLabel } = useSelector(state => getWhiteLabel(state));
  const inviteBgColor = whiteLabel?.find(x => x.key == WhiteLabelProperties.InviteBgColorCode)?.value; // white
  const inviteBtnColor = whiteLabel?.find(x => x.key == WhiteLabelProperties.InviteButtonColorCode)?.value; // yello
  const logoUrl = whiteLabel?.find(x => x.key == WhiteLabelProperties.LogoUrl)?.value;
  const inviteFontColor = whiteLabel?.find(x => x.key == WhiteLabelProperties.InviteFontColorCode)?.value;

  const { organizationDetail } = useSelector(state => getChatComponentProps(state));

  useEffect(() => {
    dispatch(loadChatOrganizationDetail(profileId, chatFeatureType));
  }, [profileId]);

  function renderLogo() {
    if (logoUrl && (logoUrl.endsWith(ImageTypes.png) || logoUrl.endsWith(ImageTypes.jpg))) {
      return (<img className='branding-logo' src={logoUrl}></img>);
    }
  }

  for (let i = 0; i < organizationDetail.length; i++) {
    var avatarContext = any;
    orgName = organizationDetail[i].item2;
    rootOrganizationId = organizationDetail[i].item1;
    if (whiteLabel && whiteLabel.length > 0) {
      avatarContext = orgName ? (
        <div>
          <figure className="avatar" style={{backgroundColor:inviteBgColor}} >
            <div className="initials">{renderLogo()}</div>
          </figure>
        </div>
      ) : <div></div>;
    }
    else {
      avatarContext = orgName ? (
        <Avatar profile={{ name: orgName, theme: 5 }} />
      ) : <div></div>;
    }
    avatar.push(avatarContext);
    break;
  }

  useEffect(() => {
    dispatch(loadWhiteLabels(profileId, rootOrganizationId));
    inviteBgColor && document.documentElement.style.setProperty(InviteColor, inviteBgColor);
    inviteBtnColor && document.documentElement.style.setProperty(InviteBtnColor, inviteBtnColor);
    inviteFontColor && document.documentElement.style.setProperty(InviteFontColor, inviteFontColor);

    if(participantDetail.length == 0)
    {
      dispatch(loadGetParticipants(rootOrganizationId, userName));
    }
  }, [profileId, rootOrganizationId]);

  function handle911TextClick() {
    localStorage.removeItem(localStoragePaths.chat911TextClosed);
    setChat911TextClosed('False');
  }

  return (
    <Page
      id="chat-page" readOnlyProfile
      centerElement={local.nav.chat}
      subHeader={
        <div className="chat-header">
          <div className="org-avatar">
            <div className='chat-branding-bg' style={{backgroundColor:inviteBtnColor}}></div>
            {avatar}
          </div>
          <div className="org-name">{orgName}</div>
        </div>
      }>
      <div className='chat-outer'>
        {chat911TextClosed == 'True' &&
          <ChatEmergencyTextPage onHandle911TextClick={handle911TextClick}></ChatEmergencyTextPage>}
          {participantDetail?.token && <ChatCompositePage participantDetail={participantDetail} profileId={profileId}></ChatCompositePage>}
          {organizationDetail.length == 0 && <Redirect to={redirect} />}
      </div>
    </Page>
  );
}
