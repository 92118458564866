import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import local from 'services/localization/local';
import { CardList, ProfileLink } from 'shared';
import { loadActiveOrganizations } from 'organizations/organizations-actions';
import { getOrganizationsForSelf } from 'organizations/organizations-selectors';
import { getIdentityId } from 'identity/selectors';
import { routePaths } from 'services/routing';
import OrganizationListItem from './OrganizationsListItem';
import './org-list-item.scss';

export default function OrganizationsList() {
  const identityId = useSelector(getIdentityId);
  const { data, loaded, hasError } = useSelector(getOrganizationsForSelf);
  const dispatch = useDispatch();
  const isBeta = useSelector(state => state.identity.self.showBetaFeatures);
  const showCreateLink = isBeta && false; // Reactivate once profiles-api is updated to support social group creation

  useEffect(() => {
    dispatch(loadActiveOrganizations());
  }, [identityId]);

  if (!loaded) {
    return null;
  }

  return (
    <div data-testid='org-list-container'>
      <div>
        <h4>{local.organizations.title}</h4>
        {showCreateLink && (
          <ProfileLink
            id="create-group-link"
            to={routePaths.organizationsCreate}>
            {local.organizations.createGroupLink}
          </ProfileLink>
        )}
        {data.length > 0 && (
          <CardList testId='org-list'>
            {
              data.map(org => {
                return (
                  <OrganizationListItem
                    key={org.id}
                    organizationId={org.id}
                    organizationName={org.name} />
                );
              })
            }
          </CardList>
        )}
        <ProfileLink
          id="join-group-link"
          className="button btn btn-secondary"
          to={routePaths.organizationsJoinCode}>
          {local.organizations.joinGroupLink}
        </ProfileLink>
      </div>
      {hasError && (
        <div className='errorMessage'>{local.organizations.loadError}</div>
      )}
    </div>
  );
}
