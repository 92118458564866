import React from 'react';
import PropTypes from 'prop-types';
import { CircleDotFigure } from 'shared';
import moment from 'moment';
import { ObservationDetailType} from 'services/enums';
import local from 'services/localization/local';
import { useSelector } from 'react-redux';
import { getActiveNutritionGoal, getNutritionsData } from './nutrition-selector';
import { GetManufacturerName } from 'services/helpers';


function NutritionList({profileId, today}) {
  const nutritionData = useSelector(state => getNutritionsData(state, profileId));
  const activeGoal = useSelector(state => getActiveNutritionGoal(state, profileId));
  const content = local.observation.nutritions;
  const contentDevice = local.deviceName;

  const renderMacroNutrient = (observationDetail, label, goal) =>{
    return(observationDetail &&
      <div>
        <span className='nutri-daily-grams'>{`${label} (${observationDetail.valueInGrams} g)`}</span>
        <span>
          <span className='nutri-daily-actual'>{`${observationDetail.valueInPercentage}%`}</span>
          {goal >= 0 && <small> {content.actual} / {goal.toFixed(0)}% {content.goal}</small>}
        </span>
      </div>
    );
  };
  return (
    <ul className='scrollview-list'>
      {nutritionData.map((nutrition, index) => nutrition.value > 0 &&
      // eslint-disable-next-line react/no-array-index-key
      <li key={index} className="scrollview-list-item">

        <div className='data-row'>
          <div className='item-data item-actions'>
            <div className='d-flex align-items-center ml-2'>
              <CircleDotFigure />
              <div className='d-flex justify-content-between cal-data'>
                <div>
                  <span className='cal-value'>{`${nutrition.value} ${content.cal}`}</span>
                  {activeGoal ? <span> / {activeGoal.calories} cal goal</span> : null}
                </div>
                <div className='nutri-date'>{nutrition.date == today ? content.today : moment(nutrition.date).format('MM/DD/YY')}</div>
              </div>
            </div>
            <div className='macro-nutri-wrapper'>
              {renderMacroNutrient(nutrition[ObservationDetailType.Carbohydrate], content.carbohydrate, activeGoal?.carbs)}
              {renderMacroNutrient(nutrition[ObservationDetailType.Fat], content.fat, activeGoal?.fat)}
              {renderMacroNutrient(nutrition[ObservationDetailType.Protein], content.protein, activeGoal?.protein)}
            </div>

            <section className="item-full-width-section">{
              nutrition.deviceDisplayName ? `${contentDevice.sync} ${nutrition.deviceDisplayName}${GetManufacturerName(nutrition.manufacturer)}` : `${contentDevice.sync} ${contentDevice.device}`
            }
            </section>
          </div>
        </div>
      </li> )}
    </ul>
  );
}

NutritionList.propTypes = {
  profileId: PropTypes.string.isRequired,
  today: PropTypes.string.isRequired
};

export default NutritionList;
