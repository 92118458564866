import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmunizationListItem  from 'health/observations/immunization/listing/ImmunizationListItem';
import ImmunizationListEHR from 'health/observations/immunization/listing/ImmunizationListEHR';
import { useDispatch, useSelector } from 'react-redux';
import { resetUnreadMedicalRecordsCount } from 'health/action-creators';
import { getProfileId } from 'app/selectors';
import { FhirResourceTypes } from 'services/enums';
import { getUnreadMedicalRecordsCount } from 'health/health-selectors';

export default function ImmunizationList(props) {
  const { config } = props;
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const data = useSelector(state=>state.health?.immunization?.data);
  const unreadMedicalRecordsCount = useSelector(state => getUnreadMedicalRecordsCount(state, profileId, FhirResourceTypes.Immunization));

  useEffect(()=>{
    if(unreadMedicalRecordsCount> 0)
      dispatch(resetUnreadMedicalRecordsCount(profileId, FhirResourceTypes.Immunization));
  },[profileId, unreadMedicalRecordsCount]);

  return (
    <ul className='scrollview-list immunization-container'>
      {
        data.map(x => (
          x.attachedFileData ?
            <ImmunizationListItem key={x.id || x.clientId} config={config} observation={x} />
            : <ImmunizationListEHR key={x.remoteId} data={x}></ImmunizationListEHR>
        ))
      }
    </ul>
  );
}

ImmunizationList.propTypes = {
  config: PropTypes.shape().isRequired
};
