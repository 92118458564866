/* eslint-disable no-shadow */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { TextField } from 'shared/form-fields';
import { Button, LoadingIcon } from 'shared';
import * as validators from 'services/fields/field-validators';
import local from 'services/localization/local';

import { getAuthConnection, resetConnection, loading } from 'auth-connections/auth-connection-actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { signIn } from '../../identity/action-creators';
import { authConnection } from 'auth-connections/auth-connection-selector';
import './register.scss';

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false
    };
    this.showOrHidePassword = this.showOrHidePassword.bind(this);
    this.reset = this.reset.bind(this);
    this.checkConnection = this.checkConnection.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.resetConnection();
  }

  componentWillUnmount() {
    const { change, untouch } = this.props;
    change('password', null);
    untouch('password');
  }

  showOrHidePassword()
  {
    this.setState({
      showPassword : this.state.showPassword ? false : true
    });
  }

  checkConnection(event) {
    const { actions } = this.props;
    if (event && event.email && event.email.length > 0) {
      this.setState({ email: event.email });
      const domain = event.email.substring(event.email.lastIndexOf('@') + 1);
      actions.getAuthConnection(domain);
    }
  }

  reset() {
    const { actions } = this.props;
    actions.resetConnection();
  }

  render() {
    const { handleSubmit, pristine, registering, submitting, privacyPolicySection, authConnection, actions } = this.props;
    const isRegistering = registering || submitting || authConnection.loading;
    const isSubmitDisabled = pristine || registering;
    var icon =<i className={this.state.showPassword? 'fa fa-eye fa-eye-slash eye-slash-icon' : 'fa fa-fw fa-eye eye-icon'} onClick={this.showOrHidePassword}></i>;

    if (authConnection && authConnection.connection && authConnection.connection.length > 0) {
      actions.signIn(this.state.email, null, authConnection.connection);
    }

    return (
      <form className="p-0" onSubmit={authConnection.connectionNotFound ? handleSubmit : handleSubmit(this.checkConnection)} >
        <div className='edit-inputType-register-wrapper'>
          <Field
            label={local.formEmailLabel}
            name="email"
            type="email"
            autoComplete="username email"
            component={TextField}
            disabled={authConnection.connectionNotFound}
            validate={[validators.required, validators.email]} />
          {authConnection.connectionNotFound &&
            <Button
              id="editButton"
              onClick={this.reset}
              color="transparent"
              className="edit-inputType-register-btn">
              {local.edit}
            </Button>}
        </div>
        {authConnection.loading && <LoadingIcon />}
        {(authConnection.connectionNotFound) && <Field
          label={local.formPasswordRequirementLabel}
          name="password"
          component={TextField}
          type={this.state.showPassword ? 'text' : 'password'}
          autoComplete="new-password"
          disabled={isRegistering}
          validate={[validators.required, validators.minLength8]}
          children={icon} />}
        <Button
          id="registerSubmitButton"
          submit
          disabled={isSubmitDisabled}
          color="blue"
          className="w-100 btn btn-primary">
          {authConnection.connectionNotFound ? local.join : local.formContinueButton}
        </Button>
        {privacyPolicySection}
      </form>
    );
  }
}

RegisterForm.displayName = 'RegisterForm';
RegisterForm.propTypes = {
  registering: PropTypes.bool.isRequired,
  privacyPolicySection: PropTypes.node.isRequired,
  // redux form
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  untouch: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    getAuthConnection: PropTypes.func.isRequired,
    signIn: PropTypes.func.isRequired,
    resetConnection : PropTypes.func.isRequired
  }),
  authConnection: PropTypes.shape({
    connectionNotFound: PropTypes.bool.isRequired,
    connection: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired
  })
};

function mapDispatchToProps(dispatch) {
  const actions = { getAuthConnection, signIn, resetConnection, loading };
  return { actions: bindActionCreators(actions, dispatch) };
}
function mapStateToProps(state) {
  return {
    authConnection: authConnection(state)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'signin-register-reset',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(RegisterForm));
