import React from 'react';
import PropTypes from 'prop-types';
import configs from 'health/observations/observation-configs';
import Page404 from 'app/page404/Page404';

function ObservationConfigRenderer(props) {
  const { configId } = props.match.params;

  const config = configs[configId];

  if (!config) {
    return <Page404 />;
  }

  return config.renderPageComponent({ config });
}

ObservationConfigRenderer.propTypes = {
  // router
  match: PropTypes.shape({
    params: PropTypes.shape({
      configId: PropTypes.string.isRequired
    })
  })
};

export default ObservationConfigRenderer;
