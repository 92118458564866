import React from 'react';
import PropTypes from 'prop-types';
import { CircleDotFigure} from 'shared';
import VitalsDataRowContainer from 'health/vitals/VitalsDataRowContainer';
import { connect } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ObservationType } from 'services/enums';
class GenericObservationListItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete() {
    this.props.config.deleteData(this.props);
  }

  render() {
    const { config, observation } = this.props;
    const color = observation.isThirdParty ? 'blue' : 'grayLight';

    if(observation.observationType == ObservationType.Sleep && !observation.value)
      return null;

    return (
      <li className="scrollview-list-item">
        <div className="data-row">
          <VitalsDataRowContainer
            visual={<CircleDotFigure color={color} />}
            handleDelete={this.handleDelete}
            timestamp={config.timestampFormatter(this.props)}
            recordedAt={observation.recordedAt}
            notes={observation.notes}
            healthDataSource={observation.healthDataSource}
            id={observation.id}
            observationType={observation.observationType}
            source={observation.source}
            deviceDisplayName={observation.deviceDisplayName}
            disableActions={config.disableActions}
            value={config.valueFormatter(this.props)}
            manufacturer={observation.manufacturer} />
        </div>
      </li>
    );
  }
}

GenericObservationListItem.propTypes = {
  config: PropTypes.shape().isRequired,
  observation: PropTypes.shape().isRequired
};

export default connect()(GenericObservationListItem);
