import React from 'react';
import { Field } from 'redux-form';
import { TextField } from 'shared/form-fields';
import local from 'services/localization/local';
import * as validators from 'services/fields/field-validators';
import * as normalizers from 'services/fields/field-normalizers';

class LogHeightFields extends React.Component {
  render() {
    return (
      <div className="form-row">
        <Field
          name="feet"
          type="number"
          autoFocus
          label={local.formHeightFeetLabel}
          component={TextField}
          normalize={normalizers.heightFeet}
          validate={[validators.number, validators.minValue0, validators.maxValue10]} />
        <Field
          name="inches"
          type="number"
          label={local.formHeightInchesLabel}
          component={TextField}
          normalize={normalizers.heightInches}
          validate={[validators.number, validators.minValue0, validators.maxValue120]} />
      </div>
    );
  }
}

LogHeightFields.displayName = 'LogHeightFields';

export default LogHeightFields;
