import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProfileId } from 'app/selectors';
import { RequestStatus, Page } from 'shared';
import { loadProfileById } from 'profiles/action-creators';
import { getProfile } from 'profiles/list/selectors';
import { getUniqueId } from 'services/helpers';
import { getQueryStringParams } from 'services/url-service';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import * as actions from './connect-actions';
import ConfirmIdentityForm from './ConfirmIdentityForm';
import ConfirmIdentityFailed from './ConfirmIdentityFailed';
import ConfirmIdentitySsnPage from './ConfirmIdentitySsnPage';
import ConfirmIdentitySuccess from './ConfirmIdentitySuccess';
import * as selectors from './connect-selectors';
import { updateProfileDetails } from 'identity/action-creators';
import './connect.scss';
import moment from 'moment';

function ConfirmIdentityPage({ location }) {
  const [loadProfileRequestId, setloadProfileRequestId] = useState();
  const [profileRequestId, setProfileRequestId] = useState();
  const [questionsRequestId, setQuestionsRequestId] = useState();
  const [answersRequestId, setAnswersRequestId] = useState();
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const profileData = useSelector(getProfile);
  const verified = useSelector(state => selectors.getIdentityVerifiedStatus(state, profileId));
  const isProcessing = useSelector(state => selectors.getVerificationProcessing(state, profileRequestId, questionsRequestId));
  const failed = useSelector(state => selectors.getVerifyIdentityFailed(state, profileId, answersRequestId, profileRequestId));
  const content = local.healthData.connect.identityVerification;
  const { requireSsn } = getQueryStringParams(location);
  const identityVerificationStatus = useSelector(state => selectors.getIdentityVerificationStatus(state, profileId));

  useEffect(() => {
    setloadProfileRequestId(getUniqueId());
    setProfileRequestId(getUniqueId());
    setQuestionsRequestId(getUniqueId());
    setAnswersRequestId(getUniqueId());
  }, [profileId]);

  useEffect(() => {
    if (loadProfileRequestId) {
      dispatch(loadProfileById(profileId, loadProfileRequestId));
    }
  }, [loadProfileRequestId]);

  const verifyProfileDetails = useCallback(profile => {
    const newProfileInfo = {
      profileId: profileData.id,
      firstName: profile.firstName,
      lastName:profile.lastName,
      currentZipCode:profile.zipCode,
      dateOfBirth:profile.dateOfBirth
    };

    if(profileData.firstName != profile.firstName || profileData.lastName != profile.lastName || profileData.currentAddress.zipCode != profile.zipCode
      || moment(profileData.dateOfBirth).format(moment.HTML5_FMT.DATE) != moment(profile.dateOfBirth).format(moment.HTML5_FMT.DATE)){
      dispatch(updateProfileDetails(profileData, newProfileInfo, profileData.id));
    }
    dispatch(actions.updateIdentityVerifiedStatus(profileId, false));
    dispatch(actions.verifyProfileDetails(newProfileInfo, profileRequestId, answersRequestId, requireSsn));
  }, [profileRequestId, answersRequestId, profileData]);

  const verifyProfileWithSsn = useCallback((ssn4 ) => {
    dispatch(actions.updateIdentityVerifiedStatus(profileId, false));
    dispatch(actions.verifyProfileWithSsn(profileId, ssn4, profileRequestId, answersRequestId));
  }, [profileId, profileRequestId, answersRequestId]);

  if(identityVerificationStatus?.isIdentityVerificationRequired != true)
    return <Redirect to={routePaths.health} />;

  if (verified?.isIdentityVerified) {
    return <ConfirmIdentitySuccess redirectTo={location?.state?.backpath} />;
  }

  if (failed.isFailed) {
    return <ConfirmIdentityFailed syncResponseCode={failed.syncResponseCode} />;
  }

  if (profileData.needSsn4) {
    return <ConfirmIdentitySsnPage submit={verifyProfileWithSsn} isProcessing={isProcessing} />;
  }

  function parseProfile(profile = {}) {
    const { firstName, lastName, dateOfBirth, currentAddress = {} } = profile;
    const {  zipCode } = currentAddress;
    return {
      firstName: firstName || '',
      lastName: lastName || '' ,
      zipCode: zipCode || '',
      dateOfBirth: dateOfBirth ? moment(profile.dateOfBirth).format(moment.HTML5_FMT.DATE) : ''
    };
  }
  const initialValues = parseProfile(profileData);

  return (
    <Page
      id="confirm-identity-page"
      backPath={{ to: routePaths.health }}
      centerElement={content.verifyIdentiyHeader}>
      <RequestStatus requestId={loadProfileRequestId}>
        <ConfirmIdentityForm
          initialValues={initialValues}
          onSubmit={verifyProfileDetails}
          profileData={profileData}
          isProcessing={isProcessing} />
      </RequestStatus>
    </Page>
  );
}
ConfirmIdentityPage.propTypes = {
  location: PropTypes.shape().isRequired
};

export default withRouter(ConfirmIdentityPage);
