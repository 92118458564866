import moment from 'moment';
import { profilesApiRoot as apiRoot, authClientId } from 'app/config';
import { httpGet, httpPost, httpPatch, httpPut, httpDelete, buildUrl, parseSuccess, parseError, parseRegisterError } from './api';

export const getIdentity = (state, branchData) => {
  const url = buildUrl(apiRoot, '/identities', { marketingSourceData: branchData });
  return httpGet(url, state)
    .then(parseSuccess);
};

export const registerUser = (request, state) => {
  return httpPost(buildUrl(apiRoot, '/identities/register', {verifyByCode: true}), request, state)
    .catch(parseRegisterError);
};

export const UpdateUserPassword = (password, state) => {
  var request = {
    password,
    authZeroId: state.identity?.self?.authZeroId
  };
  return httpPost(buildUrl(apiRoot, '/identities/update-password'), request, state)
    .catch(parseError);
};

export const resendVerificationEmail = (state) => {
  return httpPost(buildUrl(apiRoot, '/identities/verify-email', {includeVerificationCode: true}), { auth0ClientId: authClientId }, state)
    .catch(parseError);
};

export const updatePreference = (body, state) => {
  return httpPatch(`${apiRoot}/identities/updatepreference`, body, state);
};

export const updateUserName = (profileId, isForceRegister, body, state) => {
  return httpPatch(`${apiRoot}/profiles/update-name/${profileId}?isForceRegister=${isForceRegister}`, body, state);
};

export const updateUserProfile = (profileId, body, state) => {
  return httpPatch(`${apiRoot}/profiles/update-dob-gender/${profileId}`, body, state);
};

export const updateForceRegistration = (profileId, body, state) => {
  return httpPatch(`${apiRoot}/identities/update-forceRegistration/${profileId}`, body, state)
    .catch(parseError);
};

export const getProfile = state =>
  httpGet(`${apiRoot}/me`, state)
    .then(parseSuccess);

export const addProfile = (profile, state) => {
  const utcOffsetMinutes = moment().utcOffset();
  return httpPost(`${apiRoot}/profiles`, { ...profile, utcOffsetMinutes }, state)
    .then(parseSuccess);
};

export const updateSelfProfile = (profile, state) =>
  httpPatch(`${apiRoot}/me`, profile, state);

export const createProfileInvite = (request, state) =>
  httpPost(`${apiRoot}/profiles/invites`, request, state)
    .catch(parseError);

export const deleteProfileInvite = (inviteId, state) =>
  httpDelete(`${apiRoot}/profiles/invites/${inviteId}`, null, state)
    .catch(parseError);

export const respondToProfileInvite = (request, state) =>
  httpPost(`${apiRoot}/profiles/invites/respond`, request, state)
    .catch(parseError);

export const revokeSharingAccess = (request, profileId, state) =>
  httpPost(`${apiRoot}/profiles/${profileId}/revoke`, request, state)
    .catch(parseError);

export const getIncomingProfileInvites = state =>
  httpGet(`${apiRoot}/profiles/invites`, state)
    .then(parseSuccess);

export const getIncomingProfileInvitesCount = state =>
  httpGet(`${apiRoot}/profiles/invites-count`, state)
    .then(parseSuccess);

export const getInviteStatuses = (profileId, state) =>
  httpGet(`${apiRoot}/profiles/${profileId}/access`, state)
    .then(parseSuccess);

export const getProfileList = state =>
  httpGet(`${apiRoot}/profiles/related`, state)
    .then(parseSuccess);

export const getProfileById = (profileId, state) =>
  httpGet(`${apiRoot}/profiles/${profileId}`, state)
    .then(parseSuccess);

export const getProfilePreferences = (profileId, state) =>
  httpGet(`${apiRoot}/profiles/preferences/${profileId}`, state)
    .then(parseSuccess);

export const updateProfile = (profile, state) =>
  httpPatch(`${apiRoot}/profiles/${profile.id}`, profile, state)
    .then(parseSuccess);

export const updateProfileAndCurrentAddress = (profile, state) =>
  httpPatch(`${apiRoot}/profiles/update-profile-address/${profile.profileId}`, profile, state)
    .then(parseSuccess);

export const verifyProfile = (profile, requireSsn, state) => {
  var url = buildUrl(apiRoot, `/profiles/verify/${profile.profileId}`, { requireSsn });

  return httpPatch(url, profile, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const verifyProfileBySsn = (profileId, ssn4, state) =>
  httpPost(`${apiRoot}/profiles/verify/${profileId}/ssn`, { ssn4 }, state)
    .then(parseSuccess)
    .catch(parseError);

export const answerQuestions = (profileId, answeredQuestions, state) =>
  httpPost(`${apiRoot}/profiles/verify/${profileId}`, { answeredQuestions }, state)
    .then(parseSuccess)
    .catch(parseError);

export const hipaaConsent = (profileId, state) =>
  httpPatch(`${apiRoot}/profiles/hipaaconsent/${profileId}`, { hipaaConsentVersion: 2 }, state);

export const getHipaaConsent = (profileId, state) =>
  httpGet(`${apiRoot}/profiles/hipaaconsent/${profileId}`, state)
    .catch(parseError);

export const getMarketplaceList = state =>
  httpGet(`${apiRoot}/marketplace`, state)
    .then(parseSuccess);

export const getOrganizations = (statuses, roles, state) => {
  const url = buildUrl(apiRoot, '/identity/organizations', { memberStatuses: statuses, memberRoles: roles });
  return httpGet(url, state)
    .then(parseSuccess);
};

export const createOrganization = (requestBody, state) => {
  return httpPost(`${apiRoot}/organizations`, requestBody, state)
    .catch(parseError);
};

export const createOrUpdateProfilePreferences = (profileId, preference, state) => {
  var body = {
    blueRingPreference: preference
  };
  return httpPost(`${apiRoot}/profiles/preferences/${profileId}`, body, state)
    .catch(parseError);
};

export const leaveOrganization = (organizationMembershipId, organizationId, state) => {
  const url = buildUrl(apiRoot, `/organizations/${organizationId}/members`);

  return httpDelete(url, { organizationMembershipId: organizationMembershipId }, state)
    .catch(parseError);
};

export const getOrganizationInvites = state =>
  httpGet(`${apiRoot}/identity/organizations/invites`, state)
    .then(parseSuccess);

export const getRecentPendingInviteOrganizationMember = state =>
  httpGet(`${apiRoot}/identity/organizations/recent-Invite`, state)
    .catch(parseError)
    .then(parseSuccess);

export const getOrganizationInvitesCount = state =>
  httpGet(`${apiRoot}/identity/organizations/invites-count`, state)
    .then(parseSuccess);

export const submitOrganizationInviteCode = (state, inviteCode) => {
  return httpPost(`${apiRoot}/organizations/invite-code`, {inviteCode : inviteCode , profileId :state.selectedProfile.id} , state)
    .catch(parseError)
    .then(parseSuccess);
};

export const respondToOrganizationInvite = (state, organizationId, requestBody) => {
  const url = `${apiRoot}/organizations/${organizationId}/members`;

  return httpPost(url, requestBody, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getOrganizationMembersByIdentity = (statuses, roles, organizationId, state) => {
  const url = buildUrl(apiRoot, `/identity/organizations/${organizationId}/members`, { status: statuses, role: roles });
  return httpGet(url, state)
    .then(parseSuccess);
};

export const getOrganizationAdmins = (organizationId, state) => {
  const url = `${apiRoot}/organizations/${organizationId}/admins`;
  return httpGet(url, state)
    .then(parseSuccess);
};

export const sendUpdateEmail = (email, state) => {
  const url = `${apiRoot}/identities/send-email`;
  return httpPost(url, email, state)
    .catch(parseError)
    .then(parseSuccess);
};

export const resendCodeEmail = (state) => {
  const url = `${apiRoot}/identities/resend-email`;
  return httpPost(url, {}, state)
    .catch(parseError)
    .then(parseSuccess);
};


export const updateChangedEmail = (email, verificationCode, state) => {
  const url = `${apiRoot}/identities/verifycode-and-change-email`;
  return httpPost(url, verificationCode, state)
    .catch(parseError)
    .then(parseSuccess);
};

export const loadNewEmail = (state) => {
  const url = `${apiRoot}/identities/change-email/active-request/get`;
  return httpGet(url, state)
    .then(parseSuccess);
};

export const getIsAdminForAnyOrganization = (state) => {
  const url = `${apiRoot}/identities/IsCurrentUserAdmin`;
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const notifyHealthDataSyncError = (errorData, state) => {
  const url = `${apiRoot}/profiles/verify/notify-verification-failed`;
  return httpPost(url, errorData, state)
    .catch(parseError)
    .then(parseSuccess);
};

export const getBadgesSummary = (profileId, state) => {
  var url = buildUrl(apiRoot, `/profiles/badges/summary?profileId=${profileId}`);
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const setBadgeNotified = (profileId, badgeId, state) => {
  const url = buildUrl(apiRoot, '/profiles/badges/mark-notified/', {profileId, badgeId});
  return httpPost(url, {profileId, badgeId},  state)
    .catch(parseError)
    .then(parseSuccess);
};

export const createPayment = (data, state) => {
  const url = `${apiRoot}/payment/create`;
  return httpPost(url, data, state)
    .catch(parseError)
    .then(parseSuccess);
};
export const updatePayment = (data, state) => {
  var url = buildUrl(apiRoot, '/payment/update');
  return httpPut(url, data, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const saveProgramInvite = (transactionId, state) => {
  const url = `${apiRoot}/payment/assign-pathway/${transactionId}`;
  return httpPost(url,{}, state)
    .catch(parseError)
    .then(parseSuccess);
};

export const getPaymentByKey = (keyType, key, itemName, state) => {
  var url = buildUrl(apiRoot, '/payment/retrieve-by-key', {keyType, key, itemName});
  return httpGet(url, state)
    .then(parseSuccess)
    .catch(parseError);
};

export const getAuthConnections = (state, domainName) => {
  const url = buildUrl(apiRoot, '/auth-connections/', { domainName });
  return httpGet(url, state)
    .then(parseSuccess);
};

export const getApplicationFeatures = (application, features, state) =>
  httpPost(`${apiRoot}/application/features`, {application, features}, state)
    .then(parseSuccess)
    .catch(parseError);

export const verifyEmailWithCode = (verificationCode, state) =>
  httpPost(buildUrl(apiRoot, '/identities/verify-email-by-code', { verificationCode }), { auth0ClientId: authClientId }, state)
    .then(parseSuccess)
    .catch(parseError);
