import React from 'react';
import PropTypes from 'prop-types';
import { Line as LineChart } from 'react-chartjs-2';
import moment from 'moment';
import deepMerge from 'deepmerge';
import local from 'services/localization/local';
import styleVars from 'styles/_variables.scss';
import './vitals-chart.scss';

class VitalsChart extends React.Component {
  constructor(props) {
    super(props);
    this.shouldRender = this.shouldRender.bind(this);
    this.buildDataSets = this.buildDataSets.bind(this);
    this.mergeChartSettings = this.mergeChartSettings.bind(this);
  }

  shouldRender() {
    const { values } = this.props;

    if (!values || values.length < 2) {
      return false;
    }

    const range = values.reduce((prev, current) => {
      var currentMoment = moment(current.recordedAt);

      if (!prev.min || currentMoment < prev.min) {
        prev.min = currentMoment;
      }
      if (!prev.max || currentMoment > prev.max) {
        prev.max = currentMoment;
      }

      return prev;
    }, {});

    if (range.min == range.max) {
      return false;
    }

    return true;
  }

  buildDataSets() {
    const { values } = this.props;

    return (canvas) => {
      const ctx = canvas.getContext('2d');
      const gradient = ctx.createLinearGradient(0, 0, 0, 160);
      gradient.addColorStop(0, styleVars.colorWhite);
      gradient.addColorStop(1, styleVars.colorWhiteTrans);

      return {
        datasets: [{
          data: values.map(point => ({ y: point.value, t: point.recordedAt })),
          pointRadius: 0,
          pointBackgroundColor: styleVars.colorGray,
          pointHoverBackgroundColor: styleVars.colorTeal,
          pointHoverRadius: 4,
          pointHitRadius: 30,
          borderWidth: 1,
          backgroundColor: styleVars.colorTeal50,
          borderColor: styleVars.colorTeal,
          fill: 'origin'
        }]
      };
    };
  }

  mergeChartSettings() {
    const { chartSettings, formatter } = this.props;
    const defaultSettings = {
      elements: {
        line: {
          tension: 0 // disables bezier curves
        }
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: true,
            color: styleVars.colorGrayLight,
            drawBorder: true
          },
          type: 'time',
          ticks: {
            fontColor: styleVars.colorGray
          }
        }],
        yAxes: [{
          gridLines: {
            display: true,
            color: styleVars.colorGrayLight,
            drawBorder: true
          },
          ticks: {
            fontColor: styleVars.colorGray,
            callback: formatter,
            maxTicksLimit: 5,
            precision: 0
          }
        }]
      },
      tooltips: {
        borderColor: styleVars.colorGray,
        borderWidth: 1,
        backgroundColor: styleVars.colorWhite,
        bodyFontColor: styleVars.colorGrayDark,
        bodyFontSize: 24,
        bodyFontStyle: 'bold',
        bodyFontFamily: '"Helvetica Nueu", Helvetica, Arial, sans-serif',
        callbacks: {
          title: point => moment(point[0].xLabel).format(`${local.simpleDateFormat} ${local.simpleTimeFormat}`),
          label: point => (formatter && formatter(point.yLabel))
        },
        cornerRadius: 0,
        displayColors: false,
        titleFontColor: styleVars.colorGrayDark,
        titleFontStyle: 'normal'
      }
    };

    const arrayMerge = (destinationArray, sourceArray, options) => {
      if (destinationArray.length === 0) {
        return sourceArray;
      }

      return sourceArray.map((value, index) => deepMerge(destinationArray[index], value, options));
    };

    return deepMerge(defaultSettings, chartSettings, { arrayMerge });
  }

  render() {
    if (!this.shouldRender()) {
      return <div />;
    }

    return <LineChart id="vitals-chart" data={this.buildDataSets()} options={this.mergeChartSettings()} />;
  }
}

VitalsChart.displayName = 'Vitals Chart';
VitalsChart.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape).isRequired,
  chartSettings: PropTypes.shape(),
  formatter: PropTypes.func
};
VitalsChart.defaultProps = {
  chartSettings: {},
  formatter: null
};

export default VitalsChart;
