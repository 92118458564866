import { types } from './action-names';
import * as persistentDataActions from 'app/request/persistent-data-actions';
import * as organization_api from 'services/api/organization-api';
import * as acsAdapter_api from 'services/api/acsAdapter-api';

export function loadChatOrganizationDetail(profileId, featureType) {
  return (dispatch, getState) => {
    const identityId = getState().identity.self.id;
    const action = state => organization_api.getChatOrganizationByProfileId(state, profileId, featureType);
    return dispatch(persistentDataActions.request(action, types.GET_ROOT_ORGANIZATION, identityId));
  };
}

export function loadGetParticipants(rootOrgId, memberName) {
  return (dispatch, getState) => {
    const identityId = getState().identity.self.id;
    const action = state => acsAdapter_api.getParticipantDetails(state, rootOrgId, memberName);
    return dispatch(persistentDataActions.request(action, types.GET_PARTICIPANT_DETAILS, identityId));
  };
}

export function loadUnReadChatMessageCount(profileId) {
  return (dispatch, getState) => {
    const identityId = getState().identity.self.id;
    const action = state => acsAdapter_api.getUnReadChatMessageCount(state, profileId);
    return dispatch(persistentDataActions.request(action, types.GET_UNREAD_COUNT_DETAILS, identityId));
  };
}

export function resetUnReadChatMessageCount(profileId, acsThreadId) {
  return (dispatch, getState) => {
    const identityId = getState().identity.self.id;
    const onSuccess = () => {
      dispatch(loadUnReadChatMessageCount(profileId));
    };

    const action = state => acsAdapter_api.resetUnReadChatMessageCount(state, profileId, acsThreadId)
      .then(onSuccess);

    dispatch(persistentDataActions.request(action, types.RESET_UNREAD_COUNT_DETAILS, identityId));
  };
}
