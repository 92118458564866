import React from 'react';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import ProgramTask from 'programs/details/ProgramTask';
import useEnrollmentOutdated from 'programs/shared/useEnrollmentOutdated';

export default function ProgramDashboardTileAction({ progress, nextTask, profileId, programEnrollmentId }) {
  const { programId, currentTasksComplete, readyToComplete } = progress;
  const enrollmentOutdated = useEnrollmentOutdated(programEnrollmentId);

  if (currentTasksComplete || readyToComplete || !nextTask || enrollmentOutdated) {
    return null;
  }
  else {
    return (
      <React.Fragment>
        <small className='next-task-label'>{local.dashboard.pathways.nextTask}</small>
        <ProgramTask task={nextTask} profileId={profileId} programId={programId} programEnrollmentId={programEnrollmentId} hideDescription />
      </React.Fragment>
    );
  }
}

ProgramDashboardTileAction.propTypes = {
  progress: PropTypes.shape({}).isRequired,
  nextTask: PropTypes.shape({}),
  profileId: PropTypes.string.isRequired,
  programEnrollmentId: PropTypes.string.isRequired
};
