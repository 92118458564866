import { routePaths } from 'services/routing';
import { configIds } from 'health/observations/observation-config-ids';
import local from 'services/localization/local';

export const blueCircleClasses = ['hRecord', 'medicationIcon', 'activity', 'temperature', 'timeline'];

export const accordions = [
  {
    'name': 'medications',
    'text': 'Medications',
    'options': 'medication'
  },
  {
    'name': 'activity',
    'text': 'Activities',
    'options': 'exercise,steps'
  },
  {
    'name': 'heart',
    'text': 'Heart',
    'options': 'bloodPressure,heartRate'
  },
  {
    'name': 'respiratoryorlungs',
    'text': 'Respiratory / Lungs',
    'options': 'bloodOxygen,peakFlow'
  },
  {
    'name': 'bloodsugar',
    'text': 'Blood Sugar',
    'options': 'bloodGlucose,insulin'
  },
  {
    'name': 'myjournal',
    'text': 'My Journal',
    'options': 'journal'
  },
  {
    'name': 'records',
    'text': 'Records',
    'options': 'conditions,encounters,immunizations,labResults,procedures'
  },
  {
    'name': 'bloodPressure',
    'text': 'Body Measurements',
    'options': 'arms,bodyFat,bodyMassIndex,chest,height,hips,temperature,thighs,waist,weight'
  },
  {
    'name': 'Others',
    'text': 'Others',
    'options': 'nutrition,sleep'
  }
];

export const DashboardCircleIcons = {
  'hRecord': {
    'url': routePaths.health,
    'icon': 'hRecord',
    'name': 'hRecord',
    'text': local.healthDataHeader,
    'key': `newDashboard_${local.healthDataHeader}`
  },
  'medication': {
    'url': routePaths.healthMedicationDose,
    'icon': 'medication',
    'name': 'medication',
    'text': local.linkMedications,
    'key': `newDashboard_${local.linkMedications}`
  },
  'exercise': {
    'url': routePaths.healthActivity,
    'icon': 'activityIcon',
    'text': local.linkActivity,
    'name': 'exercise',
    'key': `newDashboard_${local.linkActivity}`
  },
  'temperature': {
    'url': routePaths.healthObservation,
    'icon': 'temperatureIcon',
    'configId': configIds.temperature,
    'name': 'temperature',
    'text': local.observation.temperature.title,
    'key': `newDashboard_${local.observation.temperature.title}`
  },
  'timeline': {
    'url': routePaths.healthTimeline,
    'icon': 'timeline',
    'name': 'timeline',
    'text': local.timeline.title,
    'key': `newDashboard_${local.timeline.title}`
  },
  'steps': {
    'url': routePaths.healthObservation,
    'icon': 'steps',
    'configId': configIds.steps,
    'name': 'steps',
    'text': local.observation.steps.title,
    'key': `newDashboard_${local.observation.steps.title}`
  },
  'arms': {
    'url': routePaths.healthObservation,
    'icon': 'arms',
    'configId': configIds.bodyArms,
    'name': 'arms',
    'text': local.observation.bodyArms.title,
    'key': `newDashboard_${local.observation.bodyArms.title}`
  },
  'chest': {
    'url': routePaths.healthObservation,
    'icon': 'chest',
    'configId': configIds.bodyChest,
    'name': 'chest',
    'text': local.observation.bodyChest.title,
    'key': `newDashboard_${local.observation.bodyChest.title}`
  },
  'height': {
    'url': routePaths.healthObservation,
    'icon': 'heightDashboard',
    'configId': configIds.height,
    'name': 'height',
    'text': local.observation.height.title,
    'key': `newDashboard_${local.observation.height.title}`
  },
  'hips': {
    'url': routePaths.healthObservation,
    'icon': 'hips',
    'configId': configIds.bodyHips,
    'name': 'hips',
    'text': local.observation.bodyHips.title,
    'key': `newDashboard_${local.observation.bodyHips.title}`
  },
  'thighs': {
    'url': routePaths.healthObservation,
    'icon': 'thighs',
    'configId': configIds.bodyThighs,
    'name': 'thighs',
    'text': local.observation.bodyThighs.title,
    'key': `newDashboard_${local.observation.bodyThighs.title}`
  },
  'waist': {
    'url': routePaths.healthObservation,
    'icon': 'waist',
    'configId': configIds.bodyWaist,
    'name': 'waist',
    'text': local.observation.bodyWaist.title,
    'key': `newDashboard_${local.observation.bodyWaist.title}`
  },
  'weight': {
    'url': routePaths.healthObservation,
    'icon': 'weightDashboard',
    'configId': configIds.weight,
    'name': 'weight',
    'text': local.observation.weight.title,
    'key': `newDashboard_${local.observation.weight.title}`
  },
  'bodyMassIndex': {
    'url': routePaths.healthBodyMeasurements,
    'icon': 'bmi',
    'name': 'bodyMassIndex',
    'text': 'Body Mass Index',
    'key': 'newDashboard_Body Mass Index'
  },
  'bodyFat': {
    'url': routePaths.healthObservation,
    'icon': 'bodyFat',
    'configId': configIds.bodyFat,
    'name': 'bodyFat',
    'text': local.observation.bodyFat.title,
    'key': `newDashboard_${local.observation.bodyFat.title}`
  },
  //Heart
  'heartRate': {
    'url': routePaths.healthObservation,
    'icon': 'heartRate',
    'configId': configIds.heartRate,
    'name': 'heartRate',
    'text': local.observation.heartRate.title,
    'key': `newDashboard_${local.observation.heartRate.title}`
  },
  'bloodPressure': {
    'url': routePaths.healthObservation,
    'icon': 'bloodpressure',
    'configId': configIds.bloodPressure,
    'name': 'bloodPressure',
    'text': local.observation.bloodPressure.title,
    'key': `newDashboard_${local.observation.bloodPressure.title}`
  },

  //"Respiratory / Lungs"
  'bloodOxygen': {
    'url': routePaths.healthObservation,
    'icon': 'bloodOxygen',
    'configId': configIds.bloodOxygen,
    'name': 'bloodOxygen',
    'text': local.observation.bloodOxygen.title,
    'key': `newDashboard_${local.observation.bloodOxygen.title}`
  },
  'peakFlow': {
    'url': routePaths.healthObservation,
    'icon': 'peakflowDashboard',
    'configId': configIds.peakFlow,
    'name': 'peakFlow',
    'text': local.observation.peakFlow.title,
    'key': `newDashboard_${local.observation.peakFlow.title}`
  },
  'bloodGlucose': {
    'url': routePaths.healthObservation,
    'icon': 'bloodglucoseDashboard',
    'configId': configIds.bloodGlucose,
    'name': 'bloodGlucose',
    'text': local.observation.bloodGlucose.title,
    'key': `newDashboard_${local.observation.bloodGlucose.title}`
  },
  'insulin': {
    'url': routePaths.healthObservation,
    'icon': 'insulin',
    'configId': configIds.insulin,
    'name': 'insulin',
    'text': local.observation.insulin.title,
    'key': `newDashboard_${local.observation.insulin.title}`
  },

  'journal': {
    'url': routePaths.healthJournal,
    'icon': 'journal',
    'configId': configIds.insulin,
    'name': 'journal',
    'text': local.journal.linkJournals,
    'key': `newDashboard_${local.observation.insulin.title}`
  },

  //Records
  //URL
  'conditions': {
    'url': routePaths.healthConditions,
    'icon': 'conditions',
    'name': 'conditions',
    'text': local.healthData.conditions.title,
    'key': `newDashboard_${local.healthData.conditions.title}`
  }
  ,
  'encounters': {
    'url': routePaths.healthEncounters,
    'icon': 'encounters',
    'name': 'encounters',
    'text': local.healthData.encounters.title,
    'key': `newDashboard_${local.healthData.encounters.title}`
  },
  'immunizations': {
    'url': routePaths.healthImmunizations,
    'icon': 'immunizationsDashboard',
    //    'configId': configIds.,
    'name': 'immunizations',
    'text': local.healthData.immunizations.title,
    'key': `newDashboard_${local.healthData.immunizations.title}`
  },
  'labResults': {
    'url': routePaths.healthLabResults,
    'icon': 'labResults',
    'name': 'labResults',
    'text': 'Diagnostic Reports',
    'key': 'newDashboard_labresults'
  },
  'procedures': {
    'url': routePaths.healthProcedures,
    'icon': 'procedures',
    'name': 'procedures',
    'text': local.healthData.procedures.title,
    'key': `newDashboard_${local.healthData.procedures.title}`
  },
  'sleep': {
    'url': routePaths.healthObservation,
    'icon': 'sleep',
    'configId': configIds.sleep,
    'name': 'sleep',
    'text': local.observation.sleep.title,
    'key': `newDashboard_${local.observation.sleep.title}`
  },
  'nutrition': {
    'url': routePaths.healthObservation,
    'icon': 'nutritionFill',
    'configId': configIds.nutritions,
    'name': 'nutrition',
    'text': local.observation.nutritions.title,
    'key': `newDashboard_${local.observation.nutritions.title}`
  }
};
