import * as actionsNames from './action-names';
import * as persistentDataActions from 'app/request/persistent-data-actions';
import * as Health_Care_Profile_api from 'services/api/healthCareProfile-api';

export function loadDashboardAppointment(profileId, takeCount) {
  return (dispatch, getState) => {
    const identityId = getState().identity.self.id;
    const action = state => Health_Care_Profile_api.getAppointments(state, profileId, takeCount);
    return dispatch(persistentDataActions.request(action, actionsNames.DASHBOARD_APPOINTMENT, identityId));
  };
}

export function loadAppointmentList(profileId, takeCount = -1) {

  return (dispatch, getState) => {
    const identityId = getState().identity.self.id;
    const action = state => Health_Care_Profile_api.getAppointments(state, profileId, takeCount);
    return dispatch(persistentDataActions.request(action, actionsNames.APPOINTMENT_LIST, identityId));
  };
}

export function loadAppointmentDetails(profileId, appointmentId) {

  return (dispatch, getState) => {
    const identityId = getState().identity.self.id;
    const action = state => Health_Care_Profile_api.getAppointmentDetails(state, profileId, appointmentId);
    return dispatch(persistentDataActions.request(action, actionsNames.APPOINTMENT_DETAILS, identityId));
  };
}
