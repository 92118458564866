import React, { useEffect } from 'react';
import NutritionChart from './visualization/NutritionChart';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileId } from 'app/selectors';
import moment from 'moment';
import NutritionList from './NutritionList';
import PropTypes from 'prop-types';
import NutritionGoal from './NutritionGoal';
import { loadNutritionGoals } from 'health/action-creators';


function NutritionPage({nutritions,pathwayStartDate,fromPathway,pathwayEndDate}) {
  const profileId = useSelector(getProfileId);
  const today =  moment().format(moment.HTML5_FMT.DATE);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadNutritionGoals(profileId));
  }, [profileId]);

  return (nutritions && nutritions.length > 0 &&
    <div>
      <div id="nutrition-visualization">
        <NutritionChart profileId={profileId} today={today} pathwayStartDate={pathwayStartDate} fromPathway={fromPathway} nutritions={nutritions} pathwayEndDate={pathwayEndDate} />
        {!fromPathway ? <NutritionGoal profileId={profileId} /> : '' }

      </div>
      {!fromPathway ? <NutritionList profileId={profileId} today={today} /> : '' }
    </div>
  );
}

NutritionPage.propTypes = {
  nutritions: PropTypes.arrayOf(PropTypes.shape).isRequired
};

export default NutritionPage;
