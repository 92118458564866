import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'shared';
import '../dashboardPreferences/dashboardPreferences.scss';
import { DashboardCircleIcons } from '../../dashboard/DashboardCircleIcons.js';


class FavSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blueCircleOption: this.props.blueCirclePreferences.split(',')
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    e.persist();
    this.props.onClick(e.target.id);
    this.forceUpdate();
  }

  render() {
    const data = this.props.data ?? '';
    return data.split(',').map(o => {
      const option = DashboardCircleIcons[o];
      const optionIndex = this.state.blueCircleOption.indexOf(option.name);
      const disable = optionIndex > -1 && optionIndex != this.props.currentIndex;

      return (
        <div key={`opt_${option.name}`} onClick={this.onClick} className={disable ? 'scrollview-list-item disabled' : 'scrollview-list-item'}>
          <small data-testid={`opt_${option.name}`} onClick={this.onClick}><Icon id={option.name} symbol={option.icon} className={disable ? 'disabled' : (this.props.activeOption == option.name ? 'active' : '')} /> </small>

          <li key={`opt_${option.name}`} id={option.name} type="button" className={disable ? 'disabled' : (this.props.activeOption == option.name ? 'active' : '')}>
            {option.text}

            {disable ? <figure><Icon symbol='yes' size="1em" /><small>Selected</small></figure> : ''}
          </li>
        </div>
      );
    });
  }

}

FavSelection.propTypes = {
  blueCirclePreferences: PropTypes.string.isRequired,
  currentIndex:PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  data: PropTypes.string.isRequired,
  activeOption: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default FavSelection;
