import { ObservationType } from 'services/enums';
import { getUnitForObservationType } from 'services/observation-service';
import { loadMultiObservationData } from 'health/action-creators';
import { loadMedicationDoses } from 'health/medication/dose/action-creators';
import { loadActivities } from 'activity/activity-actions';
import { loadJournalEntries } from 'journal/journal-actions';

export function loadTimelineData(dateRangeStart) {
  const observationTypeUnits = getAllObservationTypeUnits();

  return (dispatch, getState) => {
    const profileId = getState().selectedProfile.id;
    dispatch(loadMultiObservationData({ profileId, observationTypeUnits, dateRangeStart }));
    dispatch(loadMedicationDoses({ profileId, dateRangeStart }));
    dispatch(loadActivities({ profileId, dateRangeStart }));
    dispatch(loadJournalEntries({ profileId, dateRangeStart }));
  };
}

const getAllObservationTypeUnits = () => {
  return Object.values(ObservationType).reduce((result, observationType) => {
    if (observationType === 0) {
      return result;
    }

    const unit = getUnitForObservationType(observationType);
    result.push({ observationType, unit});
    return result;
  }, []);
};
