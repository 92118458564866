import React, { useEffect, useState } from 'react';
import { routePaths } from 'services/routing';
import { Button, Page } from 'shared';
import local from 'services/localization/local';
import { Form, Formik } from 'formik';
import { TextField } from 'shared/form-fields/formik';
import { getUniqueId, setLocalStorage } from 'services/helpers';
import { useDispatch, useSelector } from 'react-redux';
import GoalPathways from './GoalPathways';
import { getGoalComponentProps, validation } from './goal-selectors';
import { getProfileId } from 'app/selectors';
import { GoalStatus, InvitedProgramId } from 'services/enums';
import { clearProfileGoals, loadProfileGoals, SaveProfileGoals, updateGoalCategoryId } from './goal-actions';
import { getRequest } from 'app/request/request-selectors';
import { ProfileRedirect } from 'shared/redirect/ProfileRedirect';

export default function CreateGoal(props) {
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const [programs, setPrograms] = useState([]);
  const [uniqueId, setUniqueId] = useState(null);
  const goalName = local.formatString(local.goals.header, props.location?.state?.backpath?.goalName ?? '');
  const {goalCategoryId, profileGoal, goalCategoryData, programInfos, programInfoLoaded, programInfoLoading, profileGoalPrograms, loading} = useSelector(state => getGoalComponentProps(state, props, programs));
  const request = useSelector(state => getRequest(state, uniqueId)) ;
  useEffect(()=>{
    setUniqueId(getUniqueId());
    dispatch(updateGoalCategoryId(goalCategoryId));
    setLocalStorage(InvitedProgramId, null);
    return () =>{
      dispatch(clearProfileGoals(profileId));
    };
  },[]);

  useEffect(() => {
    dispatch(loadProfileGoals(profileId));
  },[profileId]);

  const handleSubmit = (values, { setSubmitting }) => {
    let body = {
      profileId: profileId,
      goalCategoryId: goalCategoryId,
      name: values?.goalName,
      description: values?.description,
      status: GoalStatus.Draft,
      profileGoalPrograms:profileGoalPrograms
    };
    dispatch(SaveProfileGoals(profileId, body, uniqueId));
    setSubmitting(false);
  };

  if(request.success && !request.processing && !request.hasError){
    return <ProfileRedirect to={routePaths.observationConnect} backpath={{goalCategoryId: goalCategoryId}}></ProfileRedirect>;
  }

  return (
    <Page
      id="create-goal"
      loading={programInfoLoading}
      backPath={{to:routePaths.myGoal}}
      centerElement={goalName}>
      <div>
        <Formik
          validationSchema={validation}
          initialValues={{
            goalName: profileGoal?.name || '',
            description: profileGoal?.description || ''
          }}
          onSubmit={handleSubmit}>
          <Form>
            <div className="form-row">
              <TextField
                label={local.goals.name}
                name='goalName' />
            </div>
            <div className="form-row">
              <TextField
                label={local.goals.description}
                multiline
                name='description' />
            </div>
            {!loading && <GoalPathways programInfos={programInfos} loading={programInfoLoading} loaded={programInfoLoaded} updatePrograms={setPrograms} goalCategory={goalCategoryData} profileGoal={profileGoal}></GoalPathways>}
            <div className="form-row" id="save-myGoal-button">
              <Button
                color='blue'
                id='addMyGoal'
                className="btn btn-primary"
                submit>
                {local.goals.continue}
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </Page>
  );
}
