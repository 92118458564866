import React, {useEffect} from 'react';
import {Chart, Bar } from 'react-chartjs-2';
import styleVars from 'styles/_variables.scss';
import { roundToDecimals } from 'services/value-formatter';
import local from 'services/localization/local';
import PropTypes from 'prop-types';
import zoom from 'chartjs-plugin-zoom';

export default function HealthIndexTrendChart(props) {
  let  xAxisMinTicks;
  let xAxisMaxTicks;
  useEffect(()=>{
    Chart.plugins.register(zoom);
  },[]);


  const getData = () => {
    const { data } = props;
    const output = data && data.reduce((result, x) => {
      x && result.labels.push(x.date);
      x && result.data.push(x.dailyHealthScore);
      result.backgroundColor.push(styleVars.colorViolet);
      return result;
    }, { labels: [], data: [], backgroundColor: [] });
    xAxisMinTicks = output && output?.labels &&  output?.labels.length >= 30 && output.labels[output.labels.length-30];
    xAxisMaxTicks =  output && output?.labels &&  output?.labels.length >= 1 && output.labels[output.labels.length-1];
    return {
      labels: output.labels,
      datasets: [{
        data: output.data,
        barPercentage: 0.3,
        categoryPercentage: 1.5,
        backgroundColor: output.backgroundColor,
        hoverBackgroundColor: styleVars.colorBlackDark,
        pointRadius: 0
      }]
    };
  };

  const getOptions = () => {
    const { data } = props;
    return {
      responsive: true,
      tooltips: {
        enabled: true,
        displayColors: true,
        yAlign: 'top',
        backgroundColor: styleVars.colorWhiteOff,
        titleFontColor: styleVars.ColorText,
        borderColor: styleVars.colorGrayLight,
        callbacks: {
          label: function (tooltipItem) {
            const observation = data[tooltipItem.index];
            return [
              `${local.healthIndex.healthIndexTooltip}: ${roundToDecimals(observation.dailyHealthScore,0)}`
            ];
          },
          labelTextColor:function(){
            return styleVars.ColorText;
          }
        }
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          display: true,
          gridLines: {
            display: false
          },
          ticks: {
            min: xAxisMinTicks,
            max: xAxisMaxTicks,
            display: true,
            autoSkip: true,
            maxTicksLimit: 5
          }
        }],
        yAxes: [{
          display: true,
          gridLines: {
            display: true
          },
          ticks: {
            suggestedMin: 0,
            display: true,
            maxTicksLimit: 10
          }
        }]
      },
      plugins: {
        zoom: {
          pan: {
            enabled: true,
            mode: 'x'
          }
        }
      }
    };
  };

  return (
    <Bar
      data={getData()}
      options={getOptions()}
      width={100}
      height={60}
      className="chart-data" />
  );
}
HealthIndexTrendChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape).isRequired
};

