/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import local from 'services/localization/local';
import * as validators from 'services/fields/field-validators';
import { Button, LinkWrapper } from 'shared';
import { TextField } from 'shared/form-fields';
import { resetPassword } from 'identity/action-creators';

class ResetPasswordForm extends React.Component {
  render() {
    const { handleSubmit, pristine, submitting, resetPassword } = this.props;
    const isFormDisabled = submitting;
    const isSubmitDisabled = pristine || isFormDisabled;
    return (
      <form id="reset-password-form" onSubmit={handleSubmit(resetPassword)}>
        <Field
          label={local.formResetEmailLabel}
          name="email"
          type="email"
          component={TextField}
          disabled={isFormDisabled}
          validate={[validators.required, validators.email]} />
        <Button
          id="resetPasswordButton"
          className="btn btn-primary"
          submit
          disabled={isSubmitDisabled}
          color="blue">
          {local.formContinue}
        </Button>
        <div className="secondary-links">
          {local.registerAlreadyHaveAccount} <LinkWrapper id="reset-password-sign-in" to="/">{local.linkSignIn}</LinkWrapper>
        </div>
      </form>
    );
  }
}

ResetPasswordForm.propTypes = {
  // actions
  resetPassword: PropTypes.func.isRequired,
  // redux form
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

const form = reduxForm({
  form: 'signin-register-reset',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(ResetPasswordForm);

const actions = { resetPassword };

export default connect(null, actions)(form);
