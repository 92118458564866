import React from 'react';
import PropTypes from 'prop-types';
import DoseListItem from './DoseListItem';
import { Icon } from 'shared';
import local from 'services/localization/local';
import './doses.scss';
import { dynamicSort } from 'services/helpers';

function DoseListSection(props) {
  const { dose } = props;
  const buttonLabel=dose.isOverdue?local.medicationDoseTake:(dose.isTakenow?local.medicationDoseTakenow:'');
  dose.values.sort(dynamicSort('name'));
  function UpdateTakeDoseData()
  {
    props.UpdateDoseData(dose,buttonLabel);
  }

  function UpdateSkipData()
  {
    props.UpdateDoseData(dose,local.medicationDoseSkip);
  }

  const doseStatus = dose.isOverdue ?
    (
      <span className="overdue-label"><Icon symbol="history"></Icon>

        <span>{`${local.medicationDoseOverdue} ${dose.overdueBy}`}
        </span>

      </span>)
    : dose.isTakenow ?
      <span className="takeNow-label"><Icon symbol="alarm"></Icon>

        <span>{local.medicationDoseTakenow}</span>
      </span> : '';

  return (
    <div className="scrollview-list">
      <div className="time-of-day">
        <div><strong>{dose.category}</strong></div>
        { doseStatus ? <span>{doseStatus}</span> : ''}
      </div>
      <div>
        {dose.values.map(x => <DoseListItem dose={x} key={x.id || 0} />)}
      </div>
      {(dose.isOverdue || dose.isTakenow )&&
      <div className="medication-action buttonGroup">
        {(dose.isOverdue || dose.isTakenow )&& <button onClick={UpdateSkipData} className="skipButton" type="button">{local.medicationDoseSkip}</button>}
        {(dose.isOverdue || dose.isTakenow ) &&  <button onClick={UpdateTakeDoseData} className="takeButton" type="button">{local.medicationDoseTake}</button>}
      </div>}
    </div>
  );
}

DoseListSection.propTypes = {
  dose: PropTypes.oneOfType([PropTypes.object]),
  UpdateDoseData:PropTypes.func
};

export default DoseListSection;
