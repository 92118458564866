import { getUniqueId } from 'services/helpers';
import { HIPAA_CONSENT_ERROR_CODE } from 'services/api/api';
import * as healthActions from 'health/actions';

const GoldProductName = 'Gold';
const PlatinumProductName = 'Platinum';

export const actionNames = (type) => ({
  REQUEST_INITIATED: `REQUEST_INITIATED_${type.toUpperCase()}`,
  REQUEST_SUCCESS: `REQUEST_SUCCESS_${type.toUpperCase()}`,
  REQUEST_ERROR: `REQUEST_ERROR_${type.toUpperCase()}`
});

export function requestInitiated(type, uniqueId, requestId) {
  return {
    type: actionNames(type).REQUEST_INITIATED,
    payload: {
      type,
      uniqueId,
      requestId
    }
  };
}

export function requestSuccess(type, uniqueId, requestId, data) {
  return {
    type: actionNames(type).REQUEST_SUCCESS,
    payload: {
      type,
      uniqueId,
      requestId,
      data
    }
  };
}

export function requestError(type, uniqueId, requestId, error) {
  return {
    type: actionNames(type).REQUEST_ERROR,
    payload: {
      type,
      uniqueId,
      requestId,
      error
    }
  };
}

export function request(method, type, uniqueId, params = {}) {
  const requestId = getUniqueId();

  return (dispatch, getState) => {
    const state = getState();
    dispatch(requestInitiated(type, uniqueId, requestId));

    const onSuccess = result => {
      if ((type === GoldProductName || type === PlatinumProductName) && result) {
        result.message = '';
        localStorage.setItem(type, JSON.stringify(result));
      }
      dispatch(requestSuccess(type, uniqueId, requestId, result));
    };

    const onError = error => {
      if (error && error.status === HIPAA_CONSENT_ERROR_CODE && params.profileId) {
        const retryAction = () => request(method, type, uniqueId, params);
        dispatch(healthActions.requireHipaaConsent(params.profileId, error.body.code, retryAction));
      }

      dispatch(requestError(type, uniqueId, requestId, error));
    };

    return method(state).then(onSuccess).catch(onError);
  };
}
