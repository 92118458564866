import * as actions from './action-names';
import compileReducers from 'services/compile-reducer';
import * as persistentDataReducer from 'app/request/persistent-data-reducer';

export const initialState = {
  [actions.HEALTH_SUMMARY]: persistentDataReducer.initialState
};

const reducers = {
  ...persistentDataReducer.reducers(actions.HEALTH_SUMMARY)
};

export default compileReducers(reducers, initialState);
