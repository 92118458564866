import React from 'react';
import local from 'services/localization/local';
import { ObservationType, Unit } from 'services/enums';
import { routePaths } from 'services/routing';
import { configIds } from 'health/observations/observation-config-ids';
import { baseObservationConfig } from 'health/observations/observation-config-helpers.jsx';
import { GenericObservationDeviceLinkNoData } from 'health/observations/generics';
import SleepVisualizationContainer from 'health/observations/sleep/visualization/SleepVisualizationContainer';
import moment from 'moment';
import { Sleep } from 'shared/fullColorIcons';
import { momentCalendarDayFormat } from 'services/date-service';
import { getSleepSummary } from 'health/observations/sleep/sleep-selectors';
const typeDefinition = {
  type: ObservationType.Sleep,
  typeUnitMap: {
    [ObservationType.Sleep]: Unit.Seconds
  },
  unitLabelMap: {
    [Unit.Seconds]: local.units.seconds
  }
};

export const buildDurationDisplayText = (duration) => {
  let durationObject = [];
  let ClassName='sleep-time-label';
  var daysShortLabel=<span className={ClassName}>{local.daysShortLabel}</span>;
  var hoursLabel=<span className={ClassName}>{local.observation.sleep.hoursLabel}</span>;
  var minutesLabel=<span className={ClassName}>{local.observation.sleep.minutesLabel}</span>;
  if (duration.days() > 0) { durationObject.push(duration.days(),daysShortLabel,' '); }
  if (duration.hours() > 0) { durationObject.push(duration.hours(),hoursLabel,' '); }
  if (duration.minutes() > 0) { durationObject.push(duration.minutes(),minutesLabel,' '); }
  return [durationObject] ;
};

export const displayDurationInTooltip= (duration) => {
  let durationObject = [];
  if (duration.days() > 0) { durationObject.push(duration.days()+local.daysShortLabel); }
  if (duration.hours() > 0) { durationObject.push(duration.hours()+local.observation.sleep.hoursLabel); }
  if (duration.minutes() > 0) { durationObject.push(duration.minutes()+local.observation.sleep.minutesLabel); }

  return `${durationObject.join(' ')}`;
};

export default {
  ...baseObservationConfig,
  ...typeDefinition,
  id: configIds.sleep,
  backPath: { to: routePaths.health },
  centerElement: local.observation.sleep.title,
  noDataText: local.observation.sleep.noDataText,
  iconSymbol: Sleep,
  disableActions:true,
  dataSelector:getSleepSummary,
  valueFormatter: (props) => buildDurationDisplayText(moment.duration(props.observation.value, local.units.seconds)),
  valueFormatterCustom: (props) => displayDurationInTooltip(moment.duration(props.observation.value, local.units.seconds)),
  graphValueFormatter:(props) => displayDurationInTooltip(moment.duration(props.observation.totalSleep, local.units.seconds)),
  renderNoDataComponent: (props) => props && <GenericObservationDeviceLinkNoData message={props.config.noDataText} />,
  timestampFormatter: (props) =>`${moment(props.observation.recordedAt).calendar(null,momentCalendarDayFormat)}`,
  // eslint-disable-next-line react/no-multi-comp
  renderChart: (props) => props.observations && <SleepVisualizationContainer sleep={props.observations} />
};
