import { profileViewDataRoot as apiRoot} from 'app/config';
import { httpGet, httpPost, buildUrl, parseSuccess, parseError} from './api';

export const getUnreadMedicalRecordsCount = (state, profileId) =>
  httpGet(buildUrl(apiRoot,'/api/MedicalRecordLog', {profileId}), state)
    .then(parseSuccess)
    .catch(parseError);

export const resetUnreadMedicalRecordsCount = (state, profileId, fhirResourceType, observationType) =>
  httpPost(buildUrl(apiRoot,'/api/MedicalRecordLog/reset', {profileId, fhirResourceType, observationType}), {}, state)
    .catch(parseError)
    .then(parseSuccess);

export const getOrganizationWhiteLabels = (state, profileId, sourceId) =>
  httpGet(buildUrl(apiRoot,'/api/white-label-config', {profileId, sourceId}), state)
    .then(parseSuccess)
    .catch(parseError);
