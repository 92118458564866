import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextField } from 'shared/form-fields';
import * as validators from 'services/fields/field-validators';

class DateTextFieldWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.getBirthDateValidate = this.getBirthDateValidate.bind(this);
  }

  getBirthDateValidate() {
    const { validate } = this.props;
    if (validate) {
      return [validators.birthdate, ...validate];
    }
    return [validators.birthdate];
  }

  render() {
    const validate = this.getBirthDateValidate() ;
    return (
      <Field
        {...this.props}
        validate={validate}
        component={TextField}
        type='date' />
    );
  }
}

DateTextFieldWrapper.propTypes = {
  validate: PropTypes.arrayOf(PropTypes.shape)
};

export default DateTextFieldWrapper;
