import * as toastActions from 'app/toaster/actions';
import { HIPAA_CONSENT_ERROR_CODE } from 'services/api/api';
import * as healthActions from 'health/actions';

export const actionNames = {
  REQUESTED: 'REQUESTED',
  REQUEST_SUCCESS: 'REQUEST_SUCCESS',
  REQUEST_ERROR: 'REQUEST_ERROR',
  NUTRITION_SAVED: 'NUTRITION_SAVED',
  GLUCOSE_SAVED: 'GLUCOSE_SAVED',
  BLOOD_PRESSURE_GOAL_SAVED: 'BLOOD_PRESSURE_GOAL_SAVED',
  TERRA_URL_SAVED:'TERRA_URL_SAVED',
  OBSERVATION_DEVICE_SAVED: 'OBSERVATION_DEVICE_SAVED'
};

export function requested(requestId, params) {
  return {
    type: actionNames.REQUESTED,
    payload: {
      requestId,
      params
    }
  };
}

export function requestSuccess(requestId, result, params) {
  return {
    type: actionNames.REQUEST_SUCCESS,
    payload: {
      requestId,
      result,
      params
    }
  };
}

export function requestError(requestId, error, params) {
  return {
    type: actionNames.REQUEST_ERROR,
    payload: {
      requestId,
      error,
      params
    }
  };
}

export const requestOptions = {
  showToasts: false,
  processingMessage: null,
  successMessage: null,
  errorMessage: null
};

export function request(method, requestId, params = {}, options = requestOptions) {
  return (dispatch, getState) => {
    dispatch(requested(requestId, params));

    if (options.showToasts) {
      dispatch(toastActions.addProcessingToast(requestId, options.processingMessage));
    }

    const onSuccess = result => {
      dispatch(requestSuccess(requestId, result, params));

      if (options.showToasts) {
        dispatch(toastActions.completeToastWithSuccess(requestId, options.successMessage));
      }
    };

    const onError = error => {
      if (options.requireHipaaVerification) {
        const retryAction = () => request(method, requestId, params, options);
        checkHipaaVerification(dispatch, error, params, retryAction);
      }

      dispatch(requestError(requestId, error, params));

      if (options.showToasts) {
        dispatch(toastActions.completeToastWithError(requestId, options.errorMessage));
      }
    };

    return method(getState()).then(onSuccess).catch(onError);
  };
}

export function checkHipaaVerification(dispatch, error, params, retryAction) {
  if (error && error.status === HIPAA_CONSENT_ERROR_CODE && params.profileId) {
    dispatch(healthActions.requireHipaaConsent(params.profileId, error.body.code, retryAction));
  }
}

export function nutritionSaved(status) {
  return {
    type: actionNames.NUTRITION_SAVED,
    payload: status
  };
}

export function glucoseSaved(status) {
  return {
    type: actionNames.GLUCOSE_SAVED,
    payload: status
  };
}

export function bloodPressureGoalSaved(status) {
  return {
    type: actionNames.BLOOD_PRESSURE_GOAL_SAVED,
    payload: status
  };
}

export function terraUrlSaved(status) {
  return {
    type: actionNames.TERRA_URL_SAVED,
    payload: status
  };
}
