import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import local from 'services/localization/local';
import { routePaths, ProgramsContext } from 'services/routing';
import { Page, DataLoadingStatus, Icon, ProfileRedirect } from 'shared';
import Vote from 'shared/preview/Vote';
import * as selectors from 'programs/programs-selectors';

class ProgramPreview extends React.Component {
  render() {
    const { metricName, loading, loaded, error, programInfo } = this.props;
    const title = programInfo ? programInfo.name : local.loading;
    const description = programInfo ? programInfo.description : '';

    if (loaded && !programInfo) {
      return <ProfileRedirect to={routePaths.programsList} />;
    }

    return (
      <Page
        id="program-preview"
        centerElement={title}
        backPath={{ to: this.context.base }}>
        <div style={{
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px',
          textAlign: 'center',
          minHeight: '100%'
        }}>
          <DataLoadingStatus loading={loading} error={error}>
            <div style={{ marginBottom: '30px' }}>
              <Icon symbol='healthprograms' fill="teal" size="48px" />
            </div>
            <p>
              {description}
            </p>
            <hr />
            <Vote name={title} metricName={metricName} />
          </DataLoadingStatus>
        </div>
      </Page>
    );
  }
}

ProgramPreview.contextType = ProgramsContext;

ProgramPreview.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  metricName: PropTypes.string.isRequired,
  programInfo: PropTypes.shape()
};

function mapStateToProps(state, ownProps) {
  const { metricName, programId } = ownProps.match.params;

  return {
    metricName,
    loading: selectors.getProgramListLoading(state),
    loaded: selectors.getProgramListLoaded(state),
    error: selectors.getProgramListHasError(state),
    programInfo: selectors.getProgramById(state, programId)
  };
}

export default withRouter(connect(mapStateToProps)(ProgramPreview));
