import React, { useEffect, useState } from 'react';
import { Page, NavIcon } from 'shared';
import { Nutrition } from 'shared/fullColorIcons';
import { buildPath, routePaths } from 'services/routing';
import { configIds } from '../observation-config-ids';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileId } from 'app/selectors';
import NutritionGoalForm from './NutritionGoalForm';
import { getActiveNutritionGoal, getWeeklyAverage } from './nutrition-selector';
import { ConvertPercentToGrams, getUniqueId } from 'services/helpers';
import { loadNutritionGoals, loadObservationData, saveNutritionGoal } from 'health/action-creators';
import { getRequestProcessing } from 'app/request/request-selectors';
import { Redirect } from 'react-router';
import moment from 'moment';
import { ObservationType, Unit } from 'services/enums';
import local from 'services/localization/local';
import './Nutrition.scss';
import * as constant from 'services/constants';

function NutritionGoalPage() {
  const [submitRequestId, setSubmitRequestId] = useState();
  const profileId = useSelector(getProfileId);
  const today = moment().format(moment.HTML5_FMT.DATE);
  const activeGoal = useSelector(state => getActiveNutritionGoal(state, profileId));
  const isRequestProcessing = useSelector(state => getRequestProcessing(state, submitRequestId));
  const nutritionSaved = useSelector(state=> state?.request?.nutritionSaved);
  const weeklyAverageData = useSelector(state => getWeeklyAverage(state, profileId, today));
  const backPath = buildPath(routePaths.healthObservation, {configId: configIds.nutritions, profileId});
  const dispatch = useDispatch();
  const [hasError, setHasError] = useState(false);
  const calories = activeGoal?.calories ?? constant.defaultNutritionGoal.cal;
  const content = local.observation.nutritions;

  const [macroDetails, setMacroDetails] = useState ({
    carbs: activeGoal?.carbs,
    fat: activeGoal?.fat,
    protein: activeGoal?.protein,
    unit: activeGoal?.UnitType ?? Unit.Percent
  });

  useEffect(() => {
    dispatch(loadNutritionGoals(profileId));
    dispatch(loadObservationData({profileId, observationType: ObservationType.Nutrition}));
    setSubmitRequestId(getUniqueId());
  }, [profileId]);

  if(nutritionSaved){
    return <Redirect to={backPath} />;
  }

  const getInitialValues = () => {
    let result = {
      calorieGoal:calories,
      carbs: activeGoal?.UnitType === Unit.Grams ? ConvertPercentToGrams(activeGoal?.carbs, activeGoal?.totalGrams) :  activeGoal?.carbs ?? constant.defaultNutritionGoal.carbs,
      protein: activeGoal?.UnitType === Unit.Grams ? ConvertPercentToGrams(activeGoal?.protein, activeGoal?.totalGrams) :  activeGoal?.protein ?? constant.defaultNutritionGoal.protein,
      fat: activeGoal?.UnitType === Unit.Grams ? ConvertPercentToGrams(activeGoal?.fat, activeGoal?.totalGrams) :   activeGoal?.fat ?? constant.defaultNutritionGoal.fat
    };
    return result;
  };

  function onSubmit(input){
    const newValue = {
      calories: Number(input.calorieGoal),
      carbs: macroDetails?.unit === Unit.Percent ? Number(input.carbs) : Number(macroDetails?.carbs),
      protein: macroDetails?.unit === Unit.Percent ? Number(input.protein) : Number(macroDetails?.protein),
      fat: macroDetails?.unit === Unit.Percent ? Number(input.fat) : Number(macroDetails?.fat),
      displayUnit : (macroDetails && macroDetails?.unit) ?? Unit.Percent,
      totalGrams: macroDetails?.unit === Unit.Grams ? Number(input.carbs) + Number(input.protein) + Number(input.fat) : 0
    };

    if(macroDetails.unit === Unit.Percent && newValue.carbs + newValue.protein + newValue.fat != constant.MAX_NUTRITION_PERCENT){
      setHasError(true);
      return;
    }

    dispatch(saveNutritionGoal(profileId, newValue, submitRequestId));
  }

  function setUnitAndValue(data){
    setMacroDetails(data);
  }

  return (
    <Page
      id='Nutri-target-component'
      backPath={{to: backPath}}
      centerElement='Nutrition Goal'
      readOnlyProfile>
      <div className="summary-metricIcon">
        <NavIcon Icon={Nutrition}></NavIcon>
      </div>
      <div className='nutriGoal-Content'>{content.nutritionGoal}</div>
      <div className='nutri-current-goal'>
      </div>
      <NutritionGoalForm
        initialValues={getInitialValues()}
        isProcessing={isRequestProcessing}
        onSubmit={onSubmit}
        setMacroGoal={setUnitAndValue}
        backPath={backPath}
        hasError={hasError}
        unitType={activeGoal?.UnitType}
        weeklyAverageData={weeklyAverageData} />
    </Page>
  );
}

export default NutritionGoalPage;
