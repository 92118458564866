/* eslint-disable react/jsx-child-element-spacing */
import React from 'react';
import PropTypes from 'prop-types';
import local from 'services/localization/local';
import moment from 'moment';
import Logo from 'shared/logos/inline/HcLogoInline';
import { Icon, ExternalLink } from 'shared';
import { privacyPolicyUrl, termsAndConditionsUrl, supportUrl, calciumWebUrl } from 'app/config';
import './non-authenticated-page-wall.scss';

class NonAuthenticatedPageWall extends React.Component {
  componentDidMount() {
    document.title = `${this.props.titleTabText} - ${local.app}`;
  }

  componentWillUnmount() {
    document.title = local.app;
  }

  render() {
    const { id, children, navAction } = this.props;
    const navActionListItem = <li>{navAction}</li>;
    const date = moment().format('YYYY');
    const content = local.nonAuthWallPage;

    return (
      <div id={id}>
        <div id="non-authenticated-page-wall-component">
          <nav>
            <section className="grid">
              <figure>
                <ExternalLink
                  className="nav-link"
                  id="home-hc"
                  logName="home-hc"
                  title="Calcium | Think Healthier."
                  openNewTab
                  href={calciumWebUrl}
                  rel="nofollow noopener"><Logo />
                </ExternalLink>
              </figure>
              <ul>
                {navActionListItem}
              </ul>
            </section>
          </nav>
          <main>
            <section className="grid">
              <div className="form">{children}</div>
              <div className="branding">
                <div className="content">
                  <img src="/static/images/phonehero.png" alt="android iphone hero image" />
                </div>
              </div>
            </section>
          </main>
          <aside>
            <header className="grid"><h1>{content.subhead}</h1></header>
            <section className="grid">
              <p>
                <Icon size="3em" symbol="health" /><br />
                <small>{content.value1}</small>
              </p>
              <p> <Icon size="3em" symbol="temperature" /><br />
                <small>{content.value2}</small>
              </p>
              <p> <Icon size="3em" symbol="observations" /><br />
                <small>{content.value3}</small>
              </p>
              <p> <Icon size="3em" symbol="devices" /><br />
                <small>{content.value4}</small>
              </p>
            </section>
          </aside>
          <footer>
            <span>© {local.copyright}, {date}</span> -
            <span>
              <ExternalLink
                href={privacyPolicyUrl}
                id="privacy-policy"
                logName='sigin-privacy-policy-external-url'
                className='nav-pp'
                openNewTab>
                <span> {local.privacyPolicy} </span>
              </ExternalLink>
            </span> -
            <span>
              <ExternalLink
                href={termsAndConditionsUrl}
                id="eula-text"
                logName='signin-terms-conditions-external-url'
                className='nav-eula'
                openNewTab>
                <span> {local.eula} </span>
              </ExternalLink>
            </span> -
            <span>
              <ExternalLink
                href={supportUrl}
                id="support-text"
                logName='signin-support-external-url'
                className='nav-eula'
                openNewTab>
                <span> {local.support}</span>
              </ExternalLink>
            </span>
          </footer>
        </div>
      </div >
    );
  }
}

NonAuthenticatedPageWall.propTypes = {
  id: PropTypes.string.isRequired,
  titleTabText: PropTypes.string.isRequired,
  children: PropTypes.node,
  navAction: PropTypes.node
};
NonAuthenticatedPageWall.defaultProps = {
  children: null,
  navAction: null
};

export default NonAuthenticatedPageWall;
