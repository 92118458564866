import React from 'react';
import './data-row.scss';
import PropTypes from 'prop-types';
import { Icon } from 'shared';
import local from 'services/localization/local';

export default function ResourceRating(props) {
  return (
    <div className='rating' >
      <span className='rating-text'>{local.resourceRating.helpful}</span>
      <div>
        <span id='like' onClick={() => props.rating != true ? props.onClick(true) : undefined}><Icon symbol={props.rating != null && props.rating == true ? 'ratingThumbsUpFilled' : 'ratingThumbsUpEmpty'} ></Icon></span>
        <span id='disLike' onClick={() => props.rating != false ? props.onClick(false) : undefined}><Icon symbol={props.rating != null && props.rating == false ? 'ratingThumbsDownFilled' : 'ratingThumbsDownEmpty'} ></Icon></span>
      </div>
    </div >
  );

}

ResourceRating.propTypes = {
  onClick: PropTypes.func.isRequired,
  rating: PropTypes.bool
};
