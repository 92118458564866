import { getObservationDevices, postDeviceSurveyNotes, saveObservationDevices } from 'services/api/deviceManager-api';
import {request} from 'app/request/persistent-data-actions';
import {OBSERVATION_CONNECTIONS,CLEAR_CONNECTIONS} from 'profile-observation-connections/action-names';
import { request as requestActions} from 'app/request/request-actions';
import { getUniqueId } from 'services/helpers';

export function loadObservationConnections(profileId, programId) {
  return (dispatch, getState) => {
    const identityId = getState().identity.self.id;
    const action = state => getObservationDevices(state, profileId, programId);
    return dispatch(request(action, OBSERVATION_CONNECTIONS, identityId));
  };
}

export function SaveObservationConnections(profileId, body, programIds, uniqueId){
  return (dispatch) => {
    const onSuccess = () => {
      dispatch(loadObservationConnections(profileId, programIds));
    };
    const action = state => saveObservationDevices(state, profileId, body).then(onSuccess);
    dispatch(requestActions(action, uniqueId,{},{}));
  };
}

export function saveDeviceSurveyNotes(profileId, observationType, notes) {
  return (dispatch) => {
    const action = state => postDeviceSurveyNotes(state, {profileId, observationType, notes});
    dispatch(request(action, getUniqueId()));
  };
}

export function clearObservationConnections() {
  return {
    type: CLEAR_CONNECTIONS
  };
}

