import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { buildPath, routePaths } from 'services/routing';
import { Loading, LoadingIcon, LinkWrapper, SmallError } from 'shared';
import local from 'services/localization/local';
import * as selectors from 'programs/programs-selectors';
import ProgramProgressBar from 'programs/details/ProgramProgressBar';
import PublicHealthBadgeModal from 'health/public-health-badge/PublicHealthBadgeModal';
import ProgramUpgradeModal from 'programs/enrollment/ProgramUpgradeModal';
import ProgramDashboardTileDetails from 'programs/dashboard/ProgramDashboardTileDetails';
import ProgramDashboardTileAction from 'programs/dashboard/ProgramDashboardTileAction';
import './program-dashboard-v1.scss';
import { loadProgramDetails } from 'programs/programs-actions';

export default function ProgramDashboardTileV1(props) {
  const { programEnrollment, profileId } = props;
  const { loaded, loading, hasError } = useSelector(state => selectors.getProgramDetailsStatus(state, programEnrollment?.id));
  const progress = useSelector(state => selectors.getProgramDetails(state, programEnrollment?.id));
  const nextTask = useSelector(state => selectors.getNextTask(state, programEnrollment?.id));
  const [retriesSeconds, setRetriesSeconds] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && loaded && progress.processingTasks) {
      setRetriesSeconds(retriesSeconds * 2);
      const timeoutId = setTimeout(dispatch(loadProgramDetails(programEnrollment.id)), retriesSeconds * 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [programEnrollment.id, loading, loaded]);

  let body = null;
  if (hasError) {
    body = <SmallError text={local.error} />;
  } else if (!progress) {
    body = <Loading />;
  } else {
    body = (
      <React.Fragment>
        <ProgramUpgradeModal programEnrollmentId={programEnrollment.id} />
        <div className="program-tile-details">
          {progress.canAlert && (<PublicHealthBadgeModal programEnrollmentId={programEnrollment.id} />)}
          <LinkWrapper
            id={`program-tile-details-${progress.programId}`}
            className="program-tile-details-link"
            logName='program-tile-details'
            to={buildPath(routePaths.programsDetails, { programId: progress.programId, programEnrollmentId: programEnrollment.id })}
            backpath={{ fromDashboard: true }}
            params={{ profileId }}>
            <div className="program-title-section">
              {programEnrollment && programEnrollment.program.iconPath
                ? <img className="program-icon" src={programEnrollment.program.iconPath} />
                : <div className="program-icon no-image"></div>}
              <div className="program-name chart-title">
                {progress.name}
              </div>
            </div>
            <ProgramProgressBar progress={progress} />
            {(progress.processingTasks ?
              (<div className="loading-progress"><LoadingIcon /> <span>{local.loading}</span></div>) :
              (<ProgramDashboardTileDetails progress={progress} />)
            )}
          </LinkWrapper>
        </div>
        <ProgramDashboardTileAction
          progress={progress}
          nextTask={nextTask}
          profileId={profileId}
          programEnrollmentId={programEnrollment.id} />
      </React.Fragment>
    );
  }

  return (
    <div className='program-dashboard-tile'>
      {body}
    </div>
  );
}

ProgramDashboardTileV1.propTypes = {
  programEnrollment: PropTypes.shape().isRequired,
  profileId: PropTypes.string.isRequired
};
