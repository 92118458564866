import moment from 'moment';
import * as healthDataApi from 'services/api/healthdata-api';
import { ObservationType,Unit,RecurringType } from 'services/enums';
import * as requestActions from 'app/request/request-actions';
import { types } from './stepTarget-types';
import local from 'services/localization/local';

export function saveStepTarget({ profileId, newValue, startDateTime }) {
  const saveRequest = {
    profileId: profileId,
    observationType: ObservationType.Steps,
    value:newValue,
    unit: Unit.Count,
    startDate: moment(startDateTime).format(),
    frequency: RecurringType.Daily,
    isActive: true
  };
  return (dispatch) => {
    const action = state => healthDataApi.postStepTargetGoal(profileId,saveRequest,state);

    const requestOpts = {
      showToasts: true,
      processingMessage: local.saving,
      successMessage: local.saved,
      errorMessage: local.error,
      requireHipaaVerification: true
    };

    dispatch(requestActions.request(action, types.STEP_TARGET_REQUESTS,{},requestOpts));
  };
}
