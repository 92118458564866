import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import withContext from 'app/withContext';
import withNavigate from 'app/withNavigation';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { getUniqueId } from 'services/helpers';
import { IntensityType, HealthDataSource, ActivityType} from 'services/enums';
import { ProfileRedirect, BackAction } from 'shared';
import ProfilePage from 'profiles/page/ProfilePage';
import { saveActivity } from 'activity/activity-actions';
import { getActivityRequest } from 'activity/activity-selectors';
import LogActivityForm from './LogActivityForm';
import { replaceProfileIdInUrl } from 'services/url-service';

export class LogActivity extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  handleSubmit(values) {
    // eslint-disable-next-line no-shadow
    const { actions: { saveActivity }, profileId } = this.props;
    saveActivity({ ...values, profileId }, requestId);
  }

  handleBack() {
    const { navigate } = this.props;
    const { state } = this.props.location;

    const navigateProps = { to: routePaths.healthActivity };

    if(state?.backpath?.fromPath || state?.backpath){
      const path = state?.backpath?.fromPath ?? state?.backpath;
      navigateProps.to = replaceProfileIdInUrl(path, this.props.profileId);
    }

    navigate(navigateProps);
  }

  render() {
    const { centerElement, processing, success } = this.props;
    const { state } = this.props.location;

    if (success) {
      requestId = getUniqueId();

      const navigateProps = { to:routePaths.healthActivity };

      if(state?.backpath?.fromPath)
        navigateProps.to = replaceProfileIdInUrl(state?.backpath?.fromPath, this.props.profileId);
      else if(state?.backpath)
        navigateProps.backpath = replaceProfileIdInUrl(state?.backpath, this.props.profileId);

      return <ProfileRedirect push {...navigateProps} />;
    }

    const initialValues = {
      startDateTime: moment().add(-30, 'minutes').format(moment.HTML5_FMT.DATETIME_LOCAL),
      intensity: `${IntensityType.Medium}`,
      healthDataSource: `${HealthDataSource.SelfReported}`,
      activityType: `${ActivityType.Workout}`
    };

    return (
      <ProfilePage
        id='log-activity-page'
        centerElement={centerElement}
        titleTabText={local.activityTitleTab}
        backAction={<BackAction handleBack={this.handleBack} />}>
        <LogActivityForm
          processing={processing}
          onSubmit={this.handleSubmit}
          handleBack={this.handleBack}
          pathwayContext={state?.backpath?.pathwayContextStatus?.context}
          initialValues={initialValues} />
      </ProfilePage>
    );
  }
}

LogActivity.propTypes = {
  centerElement: PropTypes.string,
  // redux
  processing: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
  // actions
  actions: PropTypes.shape({
    saveActivity: PropTypes.func.isRequired
  }).isRequired,
  // context
  profileId: PropTypes.string.isRequired,
  // navigate
  navigate: PropTypes.func.isRequired,
  location: PropTypes.shape()
};

LogActivity.defaultProps = {
  centerElement: local.activityLogHeaderDefault
};

let requestId = getUniqueId();
function mapStateToProps(state) {
  const request = getActivityRequest(state, requestId);

  return {
    processing: request.processing,
    success: request.success
  };
}

function mapDispatchToProps(dispatch) {
  const actions = { saveActivity };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default withNavigate(withContext(connect(mapStateToProps, mapDispatchToProps)(LogActivity)));
