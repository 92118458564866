import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Line as LineChart } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import withContext from 'app/withContext';
import { getMinMaxHighFrequencyData } from '../bloodGlucose-selector';
import { bindActionCreators } from 'redux';
import {loadGlucoseGoals} from 'health/action-creators';
import { getData,getOptions } from './BloodGlucoseChartHelper';
function BloodGlucoseLineChart({data,activeGoal,minMaxValue,profileId,actions,isHighFrequencyData}) {
  const getActiveGoalValue = (actions,profileId) =>{
    actions.loadGlucoseGoals(profileId);
  };
  useEffect(()=>{
    getActiveGoalValue(actions,profileId);
  }, []);
  const chartData = getData(data,isHighFrequencyData);
  const options = getOptions(data,activeGoal,minMaxValue);
  return (
    <LineChart id="blood-glucose-vitals-chart" data={chartData} options={options} />
  );
}
BloodGlucoseLineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape),
  actions: PropTypes.shape({
    loadGlucoseGoals: PropTypes.func.isRequired
  }).isRequired
};

function mapStateToProps(state, props) {
  return {
    minMaxValue:getMinMaxHighFrequencyData(state,props.profileId,props.selectEnd)
  };
}
function mapDispatchToProps(dispatch) {
  const actions = { loadGlucoseGoals  };
  return { actions: bindActionCreators(actions, dispatch) };
}

export default withContext(connect(mapStateToProps,mapDispatchToProps)(BloodGlucoseLineChart));
