import React from 'react';
import MedicationRoutes from './MedicationRoutes';

class MedicationOverview extends React.Component {
  render() {
    return (
      <MedicationRoutes />
    );
  }
}

export default MedicationOverview;
