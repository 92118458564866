import React, { useEffect } from 'react';
import { Loading, NoDataPlaceholder, Page } from 'shared';
import local from 'services/localization/local';
import { routePaths } from 'services/routing';
import { FhirResourceTypes, healthDataType } from 'services/enums';
import useMarkHealthDataAccessed from 'health/useMarkHealthDataAccessed';
import './encounters.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileId } from 'app/selectors';
import PropTypes from 'prop-types';
import { loadEncounters, resetUnreadMedicalRecordsCount } from 'health/action-creators';
import EncounterListItem from './EncounterListItem';
import { types } from 'health/health-types';
import {getHealthRecords, getUnreadMedicalRecordsCount} from 'health/health-selectors';

export default function EncountersList(props) {
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const { data, loading, hasError } = useSelector(state => getHealthRecords(state, profileId, types.ENCOUNTERS));
  const unreadMedicalRecordsCount = useSelector(state => getUnreadMedicalRecordsCount(state, profileId, FhirResourceTypes.Encounter));

  const content = local.healthData.encounters;

  useMarkHealthDataAccessed(healthDataType.Encounter);

  useEffect(()=>{
    dispatch(loadEncounters(profileId));
  },[profileId]);

  useEffect(()=>{
    if(unreadMedicalRecordsCount> 0)
      dispatch(resetUnreadMedicalRecordsCount(profileId, FhirResourceTypes.Encounter));
  },[profileId, unreadMedicalRecordsCount]);

  const renderEncounters = () => {
    if(loading){
      return(<Loading></Loading>);
    }
    if(!data || data?.length === 0 || hasError){
      return(<NoDataPlaceholder>{content.noRecords}</NoDataPlaceholder>);
    }
    return (data && data.length > 0 &&
      data.map((x) => <EncounterListItem key={x.remoteId} encounter={x}></EncounterListItem>)
    );
  };


  return (
    <Page
      id='encounters-list'
      backPath={{to:props.location?.state?.backpath ?? routePaths.health}}
      centerElement={content.title}>
      <ul className='card-list'>
        {renderEncounters()}
      </ul>
    </Page>
  );
}

EncountersList.propTypes = {
  location: PropTypes.shape()
};
