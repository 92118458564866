import moment from 'moment';
import { defaultStatus } from 'app/request/persistent-data-reducer';
import * as selectors from 'app/request/persistent-data-selectors';
import * as requestQueueSelectors from 'app/request/request-queue-selectors';
import { compareDatesAscending, compareDatesDescending } from 'services/date-service';
import { getAlertSeverityStatus } from 'services/alert-severity-service';
import { templateText } from 'services/template-service';
import { getObservationMetricKey } from 'services/enums-helpers';
import { types } from './programs-types';
import { noImmunizationPathwayId } from 'app/config';
import { ProgramTabCode,ObservationType} from 'services/enums';
import {types as healthTypes} from '../health/health-types';
import { getBloodPressurePathway } from 'health/observations/blood-pressure/blood-pressure-selectors';
import { PathwayDateFormat } from 'services/constants';



export const getProgramStatus = (state, programId) =>
  selectors.getType(state.programs, types.PROGRAM, programId);

export const getEnrollmentsStatus = (state) =>
  selectors.getType(state.programs, types.ENROLLMENTS, state.selectedProfile.id);

export const getPrograms = (state) =>
  selectors.getData(state.programs, types.INFO, state.selectedProfile.id) || [];

export const getPendingInvitedPrograms = (state) =>
  selectors.getData(state.programs, types.PENDING_INVITED_PROGRAMS, state.selectedProfile.id) || [];

export const getProgramById = (state, programId) =>
  getPrograms(state).find(x => x.programId === programId);

export const getProgramEnrollments = (state) =>
  selectors.getData(state.programs, types.ENROLLMENTS, state.selectedProfile.id) || [];

export const getProgramEnrollmentById = (state, programEnrollmentId) =>
  getProgramEnrollments(state).find(x => x.id === programEnrollmentId);

export const getProgramPermissions = (state, organizationMembershipId, hasProperties) => {
  const permissions = selectors.getType(state.programs, types.PERMISSIONS, organizationMembershipId);
  return {
    ...permissions,
    ready: permissions && permissions.loaded && !permissions.loading && !permissions.hasError && (!hasProperties || permissions.data.length > 0)
  };
};

export const getActiveUsersProgramInvites = (state) => {
  const identityId = state.identity && state.identity.self.id;
  const permissions = selectors.getType(state.programs, types.ACTIVE_USERS_PROGRAM_INVITES, identityId);
  return permissions && permissions.data;
};


export const getActiveUsersProgramInvitesNotDismissed = (state) => {
  const identityId = state.identity && state.identity.self.id;
  const inviteCount = selectors.getType(state.programs, types.PENDING_PROGRAM_INVITES_SHOWN_COUNT, identityId);
  let  notDismissedProgramInvites  = 0 ;
  if(inviteCount && inviteCount.data )
  {
    notDismissedProgramInvites = inviteCount.data;
  }
  return notDismissedProgramInvites;
};

export const getActiveUsersProgramInvitesCount = (state) => {
  const activeUsersProgramInvitesCount = getActiveUsersProgramInvites(state);
  return activeUsersProgramInvitesCount ? activeUsersProgramInvitesCount.length : 0;
};

export const getProgramDetails = (state, programEnrollmentId) => {
  const program = selectors.getData(state.programs, types.DETAILS, programEnrollmentId);
  return (program && program.profileId === state.selectedProfile.id) ? program : null;
};

export const getUpgradedObservations = (state, programId) => {
  const upgradedObservation = selectors.getData(state.programs, types.GET_UPGRADED_OBSERVATIONS, programId);
  return upgradedObservation;
};

export const getProgramEnrollmentResourceRatings = (state, programEnrollmentId) => {
  const resourceContentRating = selectors.getData(state.programs, types.RESOURCE_CONTENT_RATING, programEnrollmentId);
  return (resourceContentRating ? resourceContentRating : []);
};
export const createProgramResourceRating = (state, createModel) => {
  selectors.getData(state.programs, types.RESOURCE_CONTENT_RATING, createModel.programEnrollmentId);
  return state.programs.resourceContentRating;
};
export const updateProgramResourceRating = (state, updateModel) => {
  selectors.getData(state.programs, types.RESOURCE_CONTENT_RATING, updateModel.programEnrollmentId);
  return state.programs.resourceContentRating;
};
export const getIsProcessingTasks = (state, programEnrollmentId) => {
  var details = getProgramDetails(state, programEnrollmentId);
  return !!(details && details.processingTasks);
};

export const getProgramTasks = (state, programEnrollmentId) => {
  var details = getProgramDetails(state, programEnrollmentId) || {};

  var tasks = details.tasks || [];
  var now = moment();

  return tasks
    .map(task => {
      const name = templateText(task.name, task.metrics);
      const description = templateText(task.description, task.metrics);
      const availableDateTime = task.availableDateTime ? moment(task.availableDateTime) : null;
      const dueDateTime = task.dueDateTime ? moment(task.dueDateTime) : null;
      const completedTime = task.completedTime ? moment(task.completedTime) : null;
      const expiresDateTime = task.expiresDateTime ? moment(task.expiresDateTime) : null;
      const hasSpecifiedOrder = Number.isInteger(task.order);

      const unavailable = !!(availableDateTime && availableDateTime > now);
      const effectivelyExpired = !task.completed && details.complete;
      const taskExpired = !!(expiresDateTime && expiresDateTime < now);
      const expired = effectivelyExpired || taskExpired;

      const overdue = !!(!task.completed && dueDateTime && dueDateTime < now);
      const current = !!(!task.completed && !unavailable && !expired);

      const hasObservationMetric = !!(getObservationMetric(task));

      return {
        ...task,
        name,
        description,
        availableDateTime,
        current,
        dueDateTime: expired && dueDateTime == null ? moment(details.completeDateTime) : dueDateTime,
        expired,
        completedTime,
        unavailable,
        overdue,
        hasSpecifiedOrder,
        order: hasSpecifiedOrder ? task.order : Number.MAX_SAFE_INTEGER,
        hasObservationMetric
      };
    });
};

const getObservationMetric = (task) => {
  if (task.metrics && task.observationType) {
    const key = getObservationMetricKey(task.observationType);
    if (key) {
      const metric = task.metrics[key];
      return metric;
    }
  }
  return null;
};

export const getNextTask = (state, programEnrollmentId) =>
  getProgramTasks(state, programEnrollmentId)
    .filter(x => !x.completed && !x.unavailable && !x.expired)
    .sort(compareTasks)[0];

export const getAvailableProgramTasksByResourceId = (state, programEnrollmentId, resourceId) =>
  getProgramTasks(state, programEnrollmentId)
    .filter(x => !x.completed && !x.unavailable && x.resourceId === resourceId);

export const getGroupedTasks = (state, programEnrollmentId) =>
  getProgramTasks(state, programEnrollmentId)
    .sort(compareTasks)
    .reduce((groups, task) => {
      groups.total++;

      if (task.completed) {
        groups.completed.push(task);
      } else if (task.unavailable) {
        groups.future.push(task);
      } else if (task.expired) {
        groups.missed.push(task);
      } else {
        groups.current.push(task);
      }

      return groups;
    }, { total: 0, current: [], missed: [], completed: [], future: [] });

export const getFutureTaskSummary = (tasks) => {
  const groups = tasks
    .reduce((prev, task) => {
      if (!task.unavailable) {
        return prev;
      }

      let group = prev[task.groupId];
      if (!group) {
        prev[task.groupId] = {
          ...task,
          quantity: 1
        };
      } else {
        prev[task.groupId] = {
          ...group,
          quantity: group.quantity + 1,
          availableDateTime: group.availableDateTime < task.availableDateTime ? group.availableDateTime : task.availableDateTime
        };
      }

      return prev;
    }, {});

  return Object
    .values(groups)
    .sort(compareFutureTasks);
};

export const compareTasks = (a, b) => {
  const comparer = compareTasksGen(a, b);
  let iterator = { value: null };

  do {
    iterator = comparer.next();
  } while (iterator.value === null);

  return iterator.value;
};

export function* compareTasksGen(a, b) {
  yield compareCompleted(a, b);
  yield compareExpired(a, b);
  yield compareCurrentOverdue(a, b);
  yield compareOrder(a, b);
  return compareTaskFallback(a, b);
}

export function compareCompleted(a, b) {
  if (a.completed && b.completed) {
    return a.completedTime - b.completedTime;
  } else if (a.completed || b.completed) {
    return a.completed ? 1 : -1;
  }

  return null;
}

export function compareExpired(a, b) {
  if (a.expired && b.expired) {
    if (a.dueDateTime.isSame(b.dueDateTime)) {
      return compareOrder(a, b, true);
    }

    return compareTaskFallback(a, b);
  } else if (a.expired || b.expired) {
    return a.expired ? 1 : -1;
  }

  return null;
}

export function compareCurrentOverdue(a, b) {
  if ((a.current && a.overdue) && (b.current && b.overdue)) {
    if (a.dueDateTime.isSame(b.dueDateTime)) {
      return compareOrder(a, b, true);
    }

    return compareTaskFallback(a, b);
  } else if ((a.current && a.overdue) || (b.current && b.overdue)) {
    return (a.current && a.overdue) ? 1 : -1;
  }

  return null;
}

export function compareOrder(a, b, directCompare = false) {
  if (a.hasSpecifiedOrder || b.hasSpecifiedOrder || directCompare) {
    if (a.order === b.order) {
      return compareTaskFallback(a, b);
    }

    return a.order - b.order;
  }

  return null;
}

export const compareTaskFallback = (a, b) => {
  if (a.dueDateTime && b.dueDateTime) {
    return a.dueDateTime - b.dueDateTime;
  }

  return a.name.localeCompare(b.name);
};

export const compareFutureTasks = (a, b) => {
  if (a.unavailable && b.unavailable) {
    return a.availableDateTime - b.availableDateTime;
  }

  return compareTasks(a, b);
};

export const getProgramResources = (state, programEnrollmentId) => {
  var details = getProgramDetails(state, programEnrollmentId) || {};
  var resources = details.resources || [];

  return resources
    .map(resource => {
      const hasSpecifiedOrder = Number.isInteger(resource.order);

      return {
        ...resource,
        hasSpecifiedOrder,
        order: hasSpecifiedOrder ? resource.order : Number.MAX_SAFE_INTEGER
      };
    })
    .sort(compareResources);
};

export const getCurrentEnrollmentTask = (state) =>{
  return state.programs.currentEnrollmentTaskInfo;
};

export const getProgramEnrollmentResourcesWithRating = (state, programEnrollmentId) => {
  return getProgramEnrollmentResourceRatings(state, programEnrollmentId) || {};
};

export const getProgramEnrollmentResourcesRatingStatus = (state, programEnrollmentId) => {
  return selectors.getType(state.programs, types.RESOURCE_CONTENT_RATING, programEnrollmentId);
};

export const createProgramEnrollmentResourcesWithRating = (state, createModel) => {
  var details = createProgramResourceRating(state, createModel) || {};
  return details;
};
export const updateProgramEnrollmentResourcesWithRating = (state, updateModel) => {
  var details = updateProgramResourceRating(state, updateModel) || {};
  return details;
};
export const compareResources = (a, b) => {
  if (a.hasSpecifiedOrder || b.hasSpecifiedOrder) {
    return a.order - b.order;
  } else {
    return a.name.localeCompare(b.name);
  }
};

export const getProgramDetailsStatus = (state, programEnrollmentId) => {
  const type = selectors.getType(state.programs, types.DETAILS, programEnrollmentId);

  if (type?.loaded && type?.data?.profileId !== state.selectedProfile.id) {
    return defaultStatus;
  }

  return {
    loaded: !!type.loaded,
    loading: type.loading,
    hasError: type.hasError
  };
};

export const getProgramDetailsLoading = (state, programEnrollmentId) =>
  !!getProgramDetailsStatus(state, programEnrollmentId).loading;

export const getProgramDetailsHasError = (state, programEnrollmentId) =>
  !!getProgramDetailsStatus(state, programEnrollmentId).hasError;

export const getProgramDetailsPageStatus = (state, programEnrollmentId) => {
  const enrollments = selectors.getType(state.programs, types.ENROLLMENTS, state.selectedProfile.id);
  const programs = selectors.getType(state.programs, types.INFO, state.selectedProfile.id);
  const referrals = selectors.getType(state.programs, types.REFERRALS, programEnrollmentId);
  const details = getProgramDetailsStatus(state, programEnrollmentId);

  return {
    loaded: !!(details.loaded && enrollments.loaded && programs.loaded), // we dont need to have referrals loaded to proceed
    loading: (!details.loaded && details.loading) || enrollments.loading || programs.loading || referrals.loading, // Allow for silent refreshing of details
    error: details.hasError || enrollments.hasError || programs.hasError || referrals.hasError
  };
};

export const getProgramListLoaded = (state) =>
  !!selectors.isLoaded(state.programs, [
    types.INFO,
    types.ENROLLMENTS
  ], state.selectedProfile.id);

export const getProgramListLoading = (state) =>
  selectors.isLoading(state.programs, [
    types.INFO,
    types.ENROLLMENTS
  ], state.selectedProfile.id);

export const getProgramListHasError = (state) =>
  selectors.hasError(state.programs, [
    types.INFO,
    types.ENROLLMENTS
  ], state.selectedProfile.id);

export const getProgramEnrollmentIdFromRoute = (props) => {
  return props && props.match && props.match.params && props.match.params.programEnrollmentId;
};

export const getProgramIdFromRoute = (props) => {
  return props && props.match && props.match.params && props.match.params.programId;
};

export const getCompletedEnrollments = (state, completionCode = 1) =>
  getProgramEnrollments(state)
    .sort((a, b) => compareDatesDescending(a.endDateTime, b.endDateTime))
    .filter(x => x.complete && x.completionCode === completionCode);

export const getCompletedEnrollmentsSummary = (state, completionCode = 1) =>
  getProgramEnrollments(state)
    .sort((a, b) => compareDatesDescending(a.endDateTime, b.endDateTime))
    .filter(x => x.complete && x.completionCode === completionCode).slice(0, 3);

export const getActiveEnrollments = (state) => {
  const enrollments = getProgramEnrollments(state);
  const activeEnrollments = enrollments
    .sort((a, b) => compareDatesAscending(a.endDateTime, b.endDateTime))
    .filter(x => !x.complete);
  return activeEnrollments;
};

export const getActiveEnrollmentIds = (state) => {
  return getActiveEnrollments(state)
    .map(enrollment => enrollment.id);
};
export const getActiveEnrollmentIdsForHome = (state) => {
  return getActiveEnrollments(state).filter(c => c.program.programId != noImmunizationPathwayId)
    .map(enrollment => enrollment.id);
};

export const getActiveEnrollmentForProgramInfoId = (state, programInfoId) => {
  return getActiveEnrollments(state)
    .find(x => x.programInfoId === programInfoId);
};

export const getActiveEnrollmentForProgram = (state, programId) => {
  return getActiveEnrollments(state)
    .find(x => x.program.programId === programId);
};

export const getEnrollsForProgram = (state, programInfoId) =>
  requestQueueSelectors
    .getRequestQueue(state.programs, types.ENROLL)
    .filter(x => x.params && x.params.profileId === state.selectedProfile.id && x.params.programInfoId === programInfoId);

export const isEnrolling = (state, programInfoId) =>
  getEnrollsForProgram(state, programInfoId)
    .some(x => x.processing);

export const getCompleteEnrollmentSuccess = (state, requestId) =>
  !!requestQueueSelectors.getRequestById(state.programs, types.COMPLETE, requestId)
    .success;

export const getSortedPrograms = (state) => {
  const programs = getPrograms(state);
  const enrollments = getProgramEnrollments(state);
  const pendingPrograms = getPendingInvitedPrograms(state);

  let commonPrograms = programs.reduce((accum, program) => {
    program.complete = false;
    accum[program.programId] = program;
    return accum;
  }, {});

  commonPrograms = Object.values(commonPrograms).sort((a, b) => a.name.localeCompare(b.name));

  let uniquePrograms = enrollments.reduce((accum, enrollment) => {
    enrollment.program.complete = enrollment.complete;
    enrollment.program.completionCode = enrollment.completionCode;
    accum[enrollment.program.programId] = enrollment.program;
    return accum;
  }, {});

  uniquePrograms = Object.values(uniquePrograms).sort((a, b) => a.name.localeCompare(b.name));

  let invitedPrograms = pendingPrograms.filter(x=>x.inviteStatus === ProgramTabCode.Invited).reduce((accum, program) => {
    accum[program.programId] = program;
    return accum;
  }, {});

  invitedPrograms = Object.values(invitedPrograms).sort((a, b) => a.name.localeCompare(b.name));

  let declinedPrograms = pendingPrograms.filter(x=>x.inviteStatus === ProgramTabCode.History).reduce((accum, program) => {
    accum[program.programId] = program;
    return accum;
  }, {});

  declinedPrograms = Object.values(declinedPrograms).sort((a, b) => a.name.localeCompare(b.name));

  if(state.profile.self.id !== state.selectedProfile.id)
  {
    invitedPrograms = invitedPrograms.filter(x=>x.inviteStatus !== ProgramTabCode.Invited );
  }

  return {commonPrograms, invitedPrograms, uniquePrograms, declinedPrograms};
};

export const getSortedInvitedPrograms = (state) => {
  const pendingPrograms = getPendingInvitedPrograms(state);

  let invitedPrograms = pendingPrograms.reduce((accum, program) => {
    accum[program.programId] = program;
    return accum;
  }, {});

  invitedPrograms = Object.values(invitedPrograms).sort((a, b) => a.name.localeCompare(b.name));

  return invitedPrograms;
};

const suggestedProgramSeed = Math.random();
export const getSuggestedProgram = (state) => {
  const programs = getPrograms(state)
    .filter(program =>
      !program.preview
      && !getActiveEnrollmentForProgram(state, program.programId)
    );

  const suggestedProgram = Math.floor(suggestedProgramSeed * programs.length);
  return programs[suggestedProgram];
};

export const getPathwayTaskContextWithStatus = (state, programEnrollmentId, taskId) => {
  const contextExpected = !!(taskId);
  const status = {
    loaded: true,
    loading: false,
    error: null,
    required: contextExpected,
    context: null,
    observationMetric: null
  };

  if (programEnrollmentId && contextExpected) {
    const enrollment = getProgramEnrollmentById(state, programEnrollmentId);
    const programDetails = getProgramDetails(state, programEnrollmentId);

    const task = programDetails ? programDetails.tasks.find(t => t.programEnrollmentTaskId === taskId) : null;
    if (task && enrollment && enrollment.program) {
      const observationMetric = getObservationMetric(task);

      status.context = {
        startDate: task.availableDateTime,
        endDate: task.dueDateTime,
        taskName: templateText(task.name, task.metrics),
        pathwayName: programDetails.name,
        pathwayIconPath: enrollment.program.iconPath,
        observationMetric
      };
    } else {
      status.loaded = false;
      status.loading = getProgramDetailsLoading(state, programEnrollmentId);
      status.error = getProgramDetailsHasError(state, programEnrollmentId);
    }
  }

  return status;
};

export const getEnrollmentReferrals = (state, programEnrollmentId) => {
  const referrals = selectors.getData(state.programs, types.REFERRALS, programEnrollmentId);
  return referrals || [];
};

export const getRecommendedPrograms = (state) => {
  const organizationPrograms = selectors.getData(state.programs, types.ORGANIZATION_PROGRAMS, state.selectedProfile.id) || [];
  const activeEnrollments = getActiveEnrollments(state);

  const enrollmentsByProgramId = activeEnrollments.reduce((accum, enrollment) => {
    if (accum[enrollment.program.programId] === undefined) {
      accum[enrollment.program.programId] = [enrollment];
    } else {
      accum[enrollment.program.programId].push(enrollment);
    }
    return accum;
  }, {});

  return organizationPrograms
    .filter(rec =>
      !enrollmentsByProgramId[rec.program.programId]
      || !(enrollmentsByProgramId[rec.program.programId].find(x => x.organizationId === rec.organizationId)))
    .sort((a, b) => {
      return (a.program.name < b.program.name) ? -1 : ((a.program.name > b.program.name) ? 1 : 0);
    });
};

export const getOrganizationProgram = (state, organizationId, programId) => {
  const organizationPrograms = selectors.getData(state.programs, types.ORGANIZATION_PROGRAMS, state.selectedProfile.id) || [];
  return organizationPrograms.find(x => x.organizationId === organizationId && x.program.programId === programId);
};

export const getOrganizationProgramsStatus = (state) => {
  const info = selectors.getType(state.programs, types.ORGANIZATION_PROGRAMS, state.selectedProfile.id);
  return info;
};
/* istanbul ignore next */
export const getMetricAlertInformation = (state, programEnrollmentId) => {
  const enrollment = getProgramEnrollmentById(state, programEnrollmentId);
  const detailsLoading = getProgramDetailsLoading(state, programEnrollmentId);
  const { loading, loaded, hasError, data: severityInfo } = selectors.getType(state.programs, types.SEVERITY, programEnrollmentId);

  if (severityInfo) {
    const areRequestsProcessing = (loading && !loaded) || (detailsLoading) || severityInfo.processing;
    const alertSeverityStatus = getAlertSeverityStatus(severityInfo, severityInfo.processing || areRequestsProcessing, hasError);

    return {
      alertSeverityStatus,
      lastUpdated: severityInfo.lastUpdated,
      incomplete: severityInfo.incomplete,
      processing: severityInfo.processing,
      areRequestsProcessing,
      error: hasError,
      programName: enrollment && enrollment.program.name ? enrollment.program.name : '' ,
      loading
    };
  } else {
    return null;
  }
};

export function getSuggestedPathways(state, profileId) {
  return selectors.getType(state.programs, types.SUGGESTED_PATHWAY, profileId);
}
/* istanbul ignore next */
export function getResourceRatingDetails(state, Id) {
  return selectors.getType(state, types.RESOURCE_CONTENT_RATING, Id);
}
/* istanbul ignore next */
export const getLeaderBoardSummaryDetails = (state) => {
  return selectors.getType(state.programs, types.LEADER_BOARD_SUMMARY, state.selectedProfile.id);
};

export const getHasEnrolledInActiveEventProgram = (state, programId) => {
  return selectors.getType(state.programs, types.HAS_ENROLLED_IN_ACTIVE_EVENT_PROGRAM, state.selectedProfile.id + programId);
};

export const getCompleteAndReEnrollment = (state) =>{
  const loading = state.programs.CompleteReEnrollmentLoading;
  return {loading};
};
export const getHealthObservationData = (state, profileId,pathwayStartDate,observationTypes) => {
  const healthObservationData  = selectors.getType(state.health, healthTypes.PATHWAY_HEALTH_RECORDS, profileId);
  const healthObservationEhrData  = selectors.getType(state.health, healthTypes.HEALTH_DATA_VITALS_OBSERVATIONS, profileId);
  const highFrequencyData  = selectors.getType(state.health, healthTypes.HIGH_FREQUENCY_OBSERVATIONS, profileId);
  let observations =  healthObservationData?.data?.results;

  let filteredData = [];
  healthObservationEhrData?.data?.results?.map((x) => {
    filteredData.push({
      observationType: x.observationType,
      value: x.value,
      unit: x.unit,
      recordedAt: x.recordedAt,
      profileId:x.profileId,
      groupingId:x.groupingId
    });
  });
  observations && observations.map((x) => {
    filteredData.push({
      observationType: x.observationType,
      value: x.value,
      unit: x.unit,
      recordedAt: x.recordedAt,
      profileId:x.profileId,
      groupingId:x.groupingId,
      observationDetails:x.observationDetails
    });
  });

  highFrequencyData?.data?.results?.map((x) => {
    filteredData.push({
      observationType: x.observationType,
      value: x.observationType == ObservationType.BloodGlucose && x.value && x.value > 0 ? x.value.toFixed(1):  x.value,
      unit: x.unit,
      recordedAt: x.recordedAt,
      profileId:x.profileId
    });
  });

  const hasHighFrequencyData =  highFrequencyData?.data?.results?.filter((e)=>{
    return new Date (moment(e.recordedAt).format(PathwayDateFormat)) >= new Date(moment(pathwayStartDate).format(PathwayDateFormat));
  });
  const pathwayData =  filteredData.filter((e)=>{
    return new Date (moment(e.recordedAt).format(PathwayDateFormat)) >= new Date(moment(pathwayStartDate).format(PathwayDateFormat));
  });
  const systolicPathwayData = observationTypes.includes(ObservationType.Systolic) ? pathwayData.filter(x=>x.observationType == ObservationType.Systolic) : [];
  const  diastolicPathwayData = observationTypes.includes(ObservationType.Systolic) ? pathwayData.filter(x=>x.observationType == ObservationType.Diastolic) : [];

  const obsservations = {
    systolicPathwayData: systolicPathwayData && diastolicPathwayData && systolicPathwayData.length >0 && diastolicPathwayData.length > 0 ?  getBloodPressurePathway(systolicPathwayData,diastolicPathwayData):[],
    glucosePathwayData: observationTypes.includes(ObservationType.BloodGlucose) ? pathwayData.filter(x=>x.observationType == ObservationType.BloodGlucose) : [],
    stepsPathwayData: observationTypes.includes(ObservationType.Steps) ? pathwayData.filter(x=>x.observationType == ObservationType.Steps) : [],
    nutritionPathwayData: observationTypes.includes(ObservationType.Nutrition) ? pathwayData.filter(x=>x.observationType == ObservationType.Nutrition) : [],
    isHighFrequencyData : hasHighFrequencyData && hasHighFrequencyData.length > 0 ? true : false
  };
  return obsservations;
};
