import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { TextField, AvatarSelectField, SelectField } from 'shared/form-fields';
import { LinkWrapper, Button } from 'shared';
import local from 'services/localization/local';
import * as validators from 'services/fields/field-validators';
import DateTextFieldWrapper from 'shared/form-fields/DateTextFieldWrapper';

class ProfileForm extends React.Component {
  render() {
    const { name, returnUrl, submitting, pristine, handleSubmit, hideCancel } = this.props;
    const cancel = hideCancel ? null : <LinkWrapper id="profile-form-cancel" className="button btn btn-secondary" to={returnUrl}>{local.formCancel}</LinkWrapper>;

    return (
      <form onSubmit={handleSubmit}>
        <h4>{local.privateProfile}</h4>
        <div className='profile-brief'>{local.privateProfileDescription}</div>
        <div className="form-row">
          <Field
            readOnly={submitting}
            label={local.Registration.firstName}
            autoFocus
            name="firstName"
            component={TextField}
            validate={[validators.required]} />
        </div>
        <div className="form-row">
          <Field
            readOnly={submitting}
            label={local.Registration.lastName}
            name="lastName"
            component={TextField}
            validate={[validators.required]} />
        </div>
        <div className="form-row">
          <DateTextFieldWrapper
            readOnly={submitting}
            label={local.formBirthdateLabel}
            validate={[validators.birthdate]}
            name="dateOfBirth" />
        </div>
        <div className="form-row">
          <Field
            readOnly={submitting}
            label={local.formGenderLabel}
            name="gender"
            component={SelectField}
            placeholder={local.selectPlaceholder}>
            <option value="1">{local.genderMale}</option>
            <option value="2">{local.genderFemale}</option>
            <option value="3">{local.genderOther}</option>
            <option value="0">{local.genderUnknown}</option>
          </Field>
        </div>
        <div className="form-row">
          <Field label={local.formAvatarLabel} component={AvatarSelectField} displayName={name} name="theme" />
        </div>
        <h4 className='mt-2'>{local.publicProfile}</h4>
        <div className="form-row">
          <Field
            readOnly={submitting}
            label={local.displayName}
            name="name"
            component={TextField}
            validate={[validators.required]} />
        </div>
        <br />
        <div className="form-row">
          {cancel}
          <Button
            id="profile-form-submit"
            className="btn btn-primary"
            submit
            color="blue"
            disabled={submitting || pristine}>
            {local.formProfileSave}
          </Button>
        </div>
      </form>
    );
  }
}

ProfileForm.displayName = 'Profile Form';
ProfileForm.propTypes = {
  name: PropTypes.string.isRequired,
  returnUrl: PropTypes.string.isRequired,
  hideCancel: PropTypes.bool,
  // redux form
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired
};

export default reduxForm({
  form: 'profile',
  enableReinitialize: true
})(ProfileForm);
