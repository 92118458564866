import React from 'react';
import { useSelector } from 'react-redux';
import { ObservationNavLink, HealthNavLink } from 'shared';
import { Activities, Allergies, BodyMassIndex, Conditions, Procedures, Encounters, Medication, ReproductiveHealth, Journal, CarePlan} from 'shared/fullColorIcons';
import { routePaths } from 'services/routing';
import local from 'services/localization/local';
import { configIds } from 'health/observations/observation-config-ids';
import { FhirResourceTypes, healthDataType, ObservationType } from 'services/enums';
import PropTypes from 'prop-types';
import { Bmi } from 'services/constants';
import { getObjectKeyByValue } from 'services/enums-helpers';
import { getSubscription, SubsriptionType } from 'preferences/billing/Subscription';

export default function HealthNavigation({enabledData}) {
  const { showFeaturePreviews } = useSelector(state => state.identity.self);
  const subscription = getSubscription();
  const isEnableData = (observationType) => {
    if(!enabledData || enabledData.length <= 0)
      return true;

    const observationKey = getObjectKeyByValue(ObservationType, observationType);
    if(observationKey)
      return enabledData.find(data => data === observationKey);

    const healthDataKey = getObjectKeyByValue(healthDataType, observationType);
    if(healthDataKey)
      return enabledData.find(data => data === healthDataKey);

    return enabledData.find(data => data === observationType);
  };

  return (
    <div className="health-navigation">


      <ul className="scrollview-list">

        {showFeaturePreviews && !enabledData ?
          <li className="scrollview-list-item">
            <HealthNavLink
              icon={Allergies}
              linkContent={local.linkAllergies}
              routePath={routePaths.healthPreview}
              linkId='allergies'
              params={{ metricName: 'allergies', name: local.linkAllergies }} />
          </li> : null}

        {isEnableData(ObservationType.BodyArms) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.bodyArms} />
          </li>}

        {isEnableData(ObservationType.BloodGlucose) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.bloodGlucose} />
          </li>}

        {isEnableData(ObservationType.BloodKetone) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.bloodKetone} />
          </li>}

        {isEnableData(ObservationType.BloodOxygen) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.bloodOxygen} />
          </li>}

        {isEnableData(ObservationType.Systolic) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.bloodPressure} />
          </li>}

        {isEnableData(Bmi) &&
          <li className="scrollview-list-item">
            <HealthNavLink
              icon={BodyMassIndex}
              linkContent={local.bmiHeader}
              routePath={routePaths.healthBodyMeasurements}
              linkId='body-measurements' />
          </li>}

        {isEnableData(ObservationType.BodyFat) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.bodyFat} />
          </li>}

        {isEnableData(healthDataType.CarePlan) &&
          <li className="scrollview-list-item">
            <HealthNavLink
              skipVerification={false}
              icon={CarePlan}
              isPremium={subscription.type == SubsriptionType.Free}
              linkContent={local.healthData.carePlan.linkText}
              routePath={routePaths.healthCarePlan}
              linkId='care-plan'
              healthDataType={healthDataType.CarePlan}
              fhirResourceType={FhirResourceTypes.CarePlan} />
          </li>}

        {isEnableData(ObservationType.BodyChest) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.bodyChest} />
          </li>}

        {isEnableData(healthDataType.Condition) &&
          <li className="scrollview-list-item">
            <HealthNavLink
              skipVerification={false}
              icon={Conditions}
              isPremium={subscription.type == SubsriptionType.Free}
              linkContent={local.healthData.conditions.linkText}
              routePath={routePaths.healthConditions}
              linkId='conditions'
              healthDataType={healthDataType.Condition}
              fhirResourceType={FhirResourceTypes.Condition} />
          </li>}

        {isEnableData(healthDataType.ObservationLabResults) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.labResult} isPremium={subscription.type == SubsriptionType.Free} fhirResourceType={FhirResourceTypes.DiagnosticReport} />
          </li>}

        {isEnableData(healthDataType.Encounter) &&
          <li className="scrollview-list-item">
            <HealthNavLink
              skipVerification={false}
              icon={Encounters}
              isPremium={subscription.type == SubsriptionType.Free}
              linkContent={local.healthData.encounters.linkText}
              routePath={routePaths.healthEncounters}
              linkId='encounters'
              healthDataType={healthDataType.Encounter}
              fhirResourceType={FhirResourceTypes.Encounter} />
          </li>}


        {isEnableData(healthDataType.Activity) &&
          <li className="scrollview-list-item">
            <HealthNavLink
              icon={Activities}
              linkContent={local.linkActivity}
              routePath={routePaths.healthActivity}
              linkId='activity' />
          </li>}

        {isEnableData(ObservationType.Height) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.height} />
          </li>}

        {isEnableData(ObservationType.InstantaneousHeartRate) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.heartRate} />
          </li>}

        {isEnableData(ObservationType.BodyHips) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.bodyHips} />
          </li>}

        {isEnableData(healthDataType.ObservationTypeImmunization) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.immunization} isPremium={subscription.type == SubsriptionType.Free} fhirResourceType={FhirResourceTypes.Immunization} />
          </li>}




        {isEnableData(ObservationType.Insulin) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.insulin} />
          </li>}

        {isEnableData(healthDataType.Medication) &&
          <li className="scrollview-list-item">
            <HealthNavLink
              skipVerification
              icon={Medication}
              isPremium={subscription.type == SubsriptionType.Free}
              linkContent={local.linkMedications}
              routePath={routePaths.healthMedicationDose}
              linkId='medications'
              healthDataType={healthDataType.Medication} />
          </li>}

        {isEnableData(healthDataType.JournalEntry) &&
          <li className="scrollview-list-item">
            <HealthNavLink
              skipVerification
              icon={Journal}
              isPremium={subscription.type == SubsriptionType.Free}
              linkContent={local.journal.linkJournals}
              routePath={routePaths.healthJournal}
              linkId='journals' />
          </li>}

        {isEnableData(ObservationType.Nutrition) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.nutritions} />
          </li>}

        {isEnableData(ObservationType.PeakFlow) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.peakFlow} />
          </li>}

        {isEnableData(healthDataType.Procedure) &&
          <li className="scrollview-list-item">
            <HealthNavLink
              icon={Procedures}
              skipVerification={false}
              isPremium={subscription.type == SubsriptionType.Free}
              linkContent={local.healthData.procedures.linkText}
              routePath={routePaths.healthProcedures}
              linkId='procedures'
              healthDataType={healthDataType.Procedure}
              fhirResourceType={FhirResourceTypes.Procedure} />
          </li>}

        {showFeaturePreviews && !enabledData ?
          <li className="scrollview-list-item">
            <HealthNavLink
              icon={ReproductiveHealth}
              linkContent={local.linkReproductiveHealth}
              routePath={routePaths.healthPreview}
              linkId='reproductivehealth'
              params={{ metricName: 'reproductive', name: local.linkReproductiveHealth }} />
          </li> : null}

        {isEnableData(ObservationType.Sleep) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.sleep} />
          </li>}

        {isEnableData(ObservationType.Steps) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.steps} />
          </li>}

        {isEnableData(ObservationType.BodyTemperature) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.temperature} />
          </li>}

        {isEnableData(ObservationType.BodyThighs) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.bodyThighs} />
          </li>}

        {isEnableData(ObservationType.BodyWaist) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.bodyWaist} />
          </li>}

        {isEnableData(ObservationType.Weight) &&
          <li className="scrollview-list-item">
            <ObservationNavLink configId={configIds.weight} />
          </li>}
      </ul>
    </div >
  );
}

HealthNavigation.propTypes = {
  enabledData: PropTypes.arrayOf(PropTypes.string)
};
