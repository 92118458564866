import moment from 'moment';
import compileReducers from 'services/compile-reducer';
import * as actions from './action-names';
import * as medicationActions from 'health/medication/action-names';

export const initialState = {
  doses: [],
  loading: false,
  loaded: false,
  loadError: false,
  saving: false,
  saved: false,
  saveError: false,
  filter: {
    date: moment().format(moment.HTML5_FMT.DATE),
    medicationId: ''
  }
};

const reducers = {};

reducers[actions.MEDICATION_DOSE_LOADING] = (state) => {
  return {
    ...state,
    loading: true,
    loaded: false,
    loadError: false
  };
};

reducers[actions.MEDICATION_DOSE_LOAD_SUCCESS] = (state, payload) => {
  return {
    ...state,
    loading: false,
    loaded: true,
    loadError: false,
    doses: payload
  };
};

reducers[actions.MEDICATION_DOSE_LOAD_ERROR] = (state) => {
  return {
    ...state,
    loading: false,
    loaded: false,
    loadError: true
  };
};

reducers[actions.MEDICATION_DOSE_SAVING] = (state) => {
  return {
    ...state,
    saving: true,
    saved: false,
    saveError: false
  };
};

reducers[actions.MEDICATION_DOSE_SAVE_SUCCESS] = (state) => {
  return {
    ...state,
    saving: false,
    saved: true,
    saveError: false
  };
};

reducers[actions.MEDICATION_DOSE_SAVE_ERROR] = (state) => {
  return {
    ...state,
    saving: false,
    saved: false,
    saveError: true
  };
};

reducers[actions.MEDICATION_DOSE_SAVE_RESET] = (state) => {
  return {
    ...state,
    saved: false,
    saveError: false
  };
};

reducers[actions.MEDICATION_DOSE_FILTER_UPDATE_MEDICATIONID] = (state, payload) => {
  return {
    ...state,
    filter: {
      ...state.filter,
      medicationId: payload
    }
  };
};

reducers[actions.MEDICATION_DOSE_FILTER_UPDATE_DATE] = (state, payload) => {
  return {
    ...state,
    filter: {
      ...state.filter,
      date: payload
    }
  };
};

reducers[actions.MEDICATION_DOSE_FILTER_RESET_MEDICATIONID] = (state) => {
  return {
    ...state,
    filter: {
      ...state.filter,
      medicationId: initialState.filter.medicationId
    }
  };
};

reducers[actions.MEDICATION_DOSE_FILTER_RESET] = (state) => {
  return {
    ...state,
    filter: initialState.filter
  };
};

reducers[actions.MEDICATION_DOSE_DELETE] = (state, payload) => {
  const updatedDoses = state.doses.map(x => {
    if (x.id === payload) {
      return { ...x, deleted: true };
    }
    return x;
  });

  return {
    ...state,
    doses: updatedDoses
  };
};

reducers[actions.MEDICATION_DOSE_DELETE_UNDO] = (state, payload) => {
  const updatedDoses = state.doses.map(x => {
    if (x.id === payload) {
      return { ...x, deleted: false };
    }
    return x;
  });

  return {
    ...state,
    doses: updatedDoses
  };
};

reducers[medicationActions.MEDICATION_DELETE] = (state, payload) => {
  const updatedDoses = state.doses.map(x => (x.medicationId === payload.medicationId) ?
    { ...x, deleted: true } : x
  );

  return {
    ...state,
    doses: updatedDoses
  };
};

reducers[actions.MEDICATION_NOTIFICATION_DOSE_UPDATE_SUCCESS] = (state, payload) => {
  const notification = payload?.medicationNotification;
  const notificationId = notification && notification.length > 0 ?  notification[0].id : undefined ;
  if (notificationId)
  {
    const updatedNotifications = state?.medicationNotificationDoses?.medicationNotifications?.map(x => {
      if (x.id === notificationId) {
        return { ...notification[0]};
      }
      return x;
    });

    const medicationNotificationDoses =  state?.medicationNotificationDoses;
    const updatedDoses = {...medicationNotificationDoses,medicationNotifications:updatedNotifications};

    return {
      ...state,
      medicationNotificationDoses: updatedDoses
    };
  }
};

reducers[actions.MEDICATION_NOTIFICATION_LOADING] = (state) => {
  return {
    ...state,
    loading: true,
    loaded: false,
    loadError: false
  };
};

reducers[actions.MEDICATION_NOTIFICATION_LOAD_SUCCESS] = (state,payload) => {
  return {
    ...state,
    loading: false,
    loaded: true,
    loadError: false,
    medicationNotificationDoses : payload
  };
};

reducers[actions.MEDICATION_NOTIFICATION_LOAD_ERROR] = (state) => {
  return {
    ...state,
    loading: false,
    loaded: false,
    loadError: true
  };
};
reducers[actions.MEDICATION_NOTIFICATION_UPDATE_SUCCESS] = (state, payload) => {
  return {
    ...state,
    loading: false,
    loaded: true,
    loadError: false,
    doses: payload
  };
};

export default compileReducers(reducers, initialState);
