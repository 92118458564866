import React from 'react';
import local from 'services/localization/local';
import { ObservationType, Unit } from 'services/enums';
import { routePaths } from 'services/routing';
import { configIds } from 'health/observations/observation-config-ids';
import { baseObservationConfig } from 'health/observations/observation-config-helpers.jsx';
import LogLabResult from 'health/observations/lab-results/log/LogLabResult';
import { Labs } from 'shared/fullColorIcons';
import LabResultList from 'health/observations/lab-results/listing/LabResultList';
import { deleteFileObservation, loadLabReportData } from 'health/action-creators';

const typeDefinition = {
  type: ObservationType.LabResult,
  typeUnitMap: {
    [ObservationType.LabResult]: Unit.Unknown
  },
  unitLabelMap: {
    [Unit.Unknown]: local.units.unknown
  }
};

export default {
  ...baseObservationConfig,
  ...typeDefinition,
  id: configIds.labResult,
  backPath: { to: routePaths.health },
  centerElement: local.observation.labResult.title,
  noDataText: local.observation.labResult.tileNoDataText,
  iconSymbol: Labs,
  loadData: (props) => [props.config.type].flat().forEach(x => props.dispatch(loadLabReportData({ observationType: x, profileId: props.profileId }))),
  deleteData: (props,deleteFileObservationModal) =>props.dispatch(deleteFileObservation({ observationType: props.observation.observationType, observationId: props.observation.id, profileId: props.observation.profileId, deleteFileObservationModal: deleteFileObservationModal})),
  // eslint-disable-next-line react/prop-types
  renderListComponent: (props) => props.observations && <LabResultList observations={props.observations} config={props.config} />,
  renderChart: (props) => props.observations > 1 && null,
  // eslint-disable-next-line react/no-multi-comp
  renderLogComponent: (props) => props && <LogLabResult {...props} />
};
