import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import PublicHealthBadge from 'health/public-health-badge/PublicHealthBadge';
import PublicHealthBadgeIcon from './PublicHealthBadgeIcon';
import { ModalWrapper, Button, Icon } from 'shared';
import { loadEnrollmentMetricSeverity } from 'programs/programs-actions';
import { getMetricAlertInformation, getProgramDetailsLoading } from 'programs/programs-selectors';
import { getProfile } from 'profiles/list/selectors';
import './public-health-badge.scss';

export default function PublicHealthBadgeModal({ programEnrollmentId }) {
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const openSheet = () => setIsSheetOpen(true);
  const closeSheet = () => setIsSheetOpen(false);
  const alertInformation = useSelector(state => getMetricAlertInformation(state, programEnrollmentId));
  const detailsLoading = useSelector(state => getProgramDetailsLoading(state, programEnrollmentId));
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();

  useEffect(() => {
    // when the enrollment id changes, make a call to get new data if there is no data
    // or if there isn't an ongoing request and the data already exists
    if (alertInformation) {
      if (detailsLoading && !alertInformation.loading) {
        dispatch(loadEnrollmentMetricSeverity(profile.id, programEnrollmentId));
      }
    } else {
      dispatch(loadEnrollmentMetricSeverity(profile.id, programEnrollmentId));
    }
  }, [programEnrollmentId, detailsLoading]);

  useEffect(() => {
    // when the modal is open
    // or when the response for the severity is marked as processing: true
    // queue a new request to fire five seconds after the prior request finishes
    if (alertInformation) {
      if (!alertInformation.loading && (isSheetOpen || alertInformation.processing)) {
        const FIVE_SECONDS_MS = 3 * 1000;
        const timeoutId = setTimeout(() => dispatch(loadEnrollmentMetricSeverity(profile.id, programEnrollmentId)), FIVE_SECONDS_MS);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [isSheetOpen, alertInformation]);

  if (!alertInformation) {
    return null;
  }

  return (
    <React.Fragment>
      <Button id='toggle-badge-modal' className='public-health-badge-modal-toggle' type='button' onClick={openSheet} color='transparent' data-testid='toggle'>
        <PublicHealthBadgeIcon alertSeverityStatus={alertInformation.alertSeverityStatus} />
      </Button>
      <ModalWrapper className='card-modal-wrapper' isOpen={isSheetOpen} onRequestClose={closeSheet}>
        <div className='card-modal'>
          <div className='content'>
            <PublicHealthBadge
              alertSeverityStatus={alertInformation.alertSeverityStatus}
              lastUpdated={alertInformation.lastUpdated}
              processing={alertInformation.processing}
              areRequestsProcessing={alertInformation.areRequestsProcessing}
              error={alertInformation.error}
              profileName={profile.name}
              programName={alertInformation.programName} />
          </div>
          <Button id='close-badge-modal' className='card-modal-close' onClick={closeSheet} data-testid='public-health-badge-modal-close'><Icon symbol='times' /></Button>
        </div>
      </ModalWrapper>
    </React.Fragment>
  );
}

PublicHealthBadgeModal.propTypes = {
  programEnrollmentId: PropTypes.string.isRequired
};
