import React from 'react';
import PropTypes from 'prop-types';
import withNavigation from 'app/withNavigation';
import { Icon } from 'shared';

class FloatActionButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { to, params, navigate } = this.props;
    navigate({ to, params });
  }

  render() {
    const { tooltip, symbol } = this.props;

    return (
      <button
        tooltip={tooltip}
        onClick={this.handleClick}
        className="float-action-button"
        type="button">
        <Icon symbol={symbol} size="2em" />
      </button>
    );
  }
}

FloatActionButton.propTypes = {
  symbol: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  to: PropTypes.string.isRequired,
  params: PropTypes.shape(),
  // navigate
  navigate: PropTypes.func.isRequired
};

export default withNavigation(FloatActionButton);
