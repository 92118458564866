/* eslint-disable react/prop-types */
import React from 'react';
import ImmunizationListItemHG from './ImmunizationListItemHG';
import './ImmunizationListItem.scss';

export default function ImmunizationListEHR(props) {

  const renderImmunizations = () => {
    return <ImmunizationListItemHG data={props.data}></ImmunizationListItemHG>;
  };


  return (
    <div idi='immunizations-list'>
      <ul className='card-list'>
        {renderImmunizations()}
      </ul>
    </div>
  );
}

