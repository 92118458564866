import local from 'services/localization/local';

export const formatListToString = list => {
  var result = '';

  if (!list || list.length === 0) {
    result = '';
  }
  else if (list.length == 2) {
    result = local.formatString(local.and, list[0], list[1]);
  }
  else if (list.length > 1) {
    const items = list.slice(0, list.length - 1);
    const lastItem = local.formatString(local.and, '', list[list.length - 1]).trim();

    result = [...items, lastItem].join(', ');
  }
  else {
    result = list[0];
  }

  return result;
};
