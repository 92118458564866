import { types } from './connect-types';
import compileReducers from 'services/compile-reducer';
import * as requestQueueReducer from 'app/request/request-queue-reducer';
import * as persistentDataReducer from 'app/request/persistent-data-reducer';

export const initialState = {
  [types.SYNC_HEALTH_RECORDS]: requestQueueReducer.initialState,
  [types.VERIFY_PROFILE_DETAILS]: requestQueueReducer.initialState,
  [types.SYNC_STATUS]: persistentDataReducer.initialState,
  [types.IDENTITY_VERIFICATION_STATUS]: persistentDataReducer.initialState
};

const reducers = {
  ...requestQueueReducer.reducers(types.SYNC_HEALTH_RECORDS),
  ...requestQueueReducer.reducers(types.VERIFY_PROFILE_DETAILS),
  ...persistentDataReducer.reducers(types.SYNC_STATUS),
  ...persistentDataReducer.reducers(types.IDENTITY_VERIFICATION_STATUS)
};

reducers[types.IDENTITY_VERIFIED_STATUS] = (state, payload) =>{
  return {
    ...state,
    [types.IDENTITY_VERIFIED_STATUS]: {
      [payload.profileId]: {
        isIdentityVerified: payload.isIdentityVerified
      }
    }
  };
};

export default compileReducers(reducers, initialState);
