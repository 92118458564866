import moment from 'moment';
import { HIPAA_CONSENT_ERROR_CODE } from 'services/api/api';
import local from 'services/localization/local';
import { getUniqueId } from 'services/helpers';
import { requireHipaaConsent } from 'health/actions';
import * as toastActions from 'app/toaster/actions';
import * as api from 'services/api/healthdata-api';
import * as actions from './actions';

export function loadMedicationDosesForVisualization(profileId) {
  const dateRangeStart = moment().subtract(1, 'days').startOf('day').format();
  const dateRangeEnd = moment().endOf('day').format();

  return loadMedicationDoses({ profileId, dateRangeStart, dateRangeEnd });
}

export function loadMedicationDoses(input) {
  const { profileId, medicationId, dateRangeStart, dateRangeEnd } = input;
  var params = {
    profileId,
    medicationId,
    dateRangeStart,
    dateRangeEnd
  };

  return (dispatch, getState) => {
    dispatch(actions.loadMedicationDoses());

    const onSuccess = response => {
      dispatch(actions.loadMedicationDosesSuccess(response.results));
    };

    const onError = () => {
      dispatch(actions.loadMedicationDosesError());
    };

    return api.getMedicationDoses(params, getState())
      .then(onSuccess).catch(onError);
  };
}

export function saveMedicationDose(input) {
  const { medicationId, profileId, recordedAt, dosage, notes } = input;
  const medicationDose = {
    medicationId,
    profileId,
    recordedAt: moment(recordedAt).format(),
    dosage,
    notes
  };

  return (dispatch, getState) => {
    const state = getState();
    const requestId = getUniqueId();

    dispatch(actions.saveMedicationDose());
    dispatch(toastActions.addProcessingToast(requestId));

    const onSuccess = () => {
      dispatch(actions.saveMedicationDoseSuccess(medicationDose));
      dispatch(toastActions.completeToastWithSuccess(requestId));
    };

    const onError = error => {
      if (error && error.status === HIPAA_CONSENT_ERROR_CODE) {
        const retryAction = () => saveMedicationDose(input);
        dispatch(toastActions.removeToast(requestId));
        dispatch(requireHipaaConsent(profileId, error.body.code, retryAction));
      } else {
        dispatch(toastActions.completeToastWithError(requestId));
      }

      dispatch(actions.saveMedicationDoseError(requestId));
    };

    return api.postMedicationDose(medicationDose, profileId, state)
      .then(onSuccess).catch(onError);
  };
}

export function deleteMedicationDose(input) {
  const { profileId, medicationDoseId } = input;

  return (dispatch, getState) => {
    dispatch(actions.doseDelete(medicationDoseId));

    const onSuccess = () => {
      dispatch(toastActions.popSuccessToast(local.deleted));
    };

    const onError = error => {
      if (error && error.status === HIPAA_CONSENT_ERROR_CODE) {
        const retryAction = () => deleteMedicationDose(input);
        dispatch(requireHipaaConsent(profileId, error.body.code, retryAction));
      } else {
        dispatch(toastActions.popErrorToast());
      }

      dispatch(actions.doseDeleteUndo(medicationDoseId));
    };

    return api.deleteMedicationDose(medicationDoseId, profileId, getState())
      .then(onSuccess, onError).catch(onError);
  };
}

export function getMedicationNotificationData(profileId,searchDate){
  return (dispatch, getState) => {

    dispatch(actions.loadMedicationNotification());

    const onSuccess = response => {
      dispatch(actions.loadMedicationNotificationSuccess(response));
    };

    const onError = () => {
      dispatch(actions.loadMedicationNotificationError());
    };
    return api.loadMedicationNotificationDoses(profileId,searchDate,getState())
      .then(onSuccess).catch(onError);
  };
}

export function updateMedicationNotification(input) {
  const { profileId, scheduleAction, scheduledTime,scheduleLogs } = input;
  const medicationNotification={
    scheduleAction,
    scheduledTime,
    scheduleLogs
  };

  return (dispatch, getState) => {
    dispatch(actions.updateMedicationNotification(medicationNotification));
    dispatch(toastActions.popSuccessToast(local.saving));
    const onSuccess = response => {
      dispatch(toastActions.popSuccessToast(local.saved));
      dispatch(actions.updateMedicationNotificationDose(response));
    };

    const onError = error => {
      if (error && error.status === HIPAA_CONSENT_ERROR_CODE) {
        const retryAction = () => updateMedicationNotification(medicationNotification);
        dispatch(requireHipaaConsent(profileId, error.body.code, retryAction));
      } else {
        dispatch(toastActions.popErrorToast());
      }
    };
    return api.putMedicationNotification(medicationNotification, profileId, getState())
      .then(onSuccess, onError).catch(onError);
  };
}

export function markNotificationAsNotTakenOrNotSkipped(input) {
  const { profileId, medicationNotificationId } = input;

  return (dispatch, getState) => {
    dispatch(actions.doseDelete(medicationNotificationId));

    const onSuccess = () => {
      dispatch(toastActions.popSuccessToast(local.deleted));
    };

    const onError = error => {
      if (error && error.status === HIPAA_CONSENT_ERROR_CODE) {
        const retryAction = () => markNotificationAsNotTakenOrNotSkipped(input);
        dispatch(requireHipaaConsent(profileId, error.body.code, retryAction));
      } else {
        dispatch(toastActions.popErrorToast());
      }
    };
    return api.putMedicationNotificationWhenDeleteDose(medicationNotificationId, profileId, getState())
      .then(onSuccess, onError).catch(onError);
  };
}
