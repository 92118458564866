import React from 'react';
import PropTypes from 'prop-types';
import { ToastStyle } from 'services/enums';

class Toast extends React.Component {
  constructor(props) {
    super(props);
    this.getClassName = this.getClassName.bind(this);
  }

  getClassName() {
    const { toast: { style } } = this.props;

    switch (style) {
      case ToastStyle.Processing:
        return 'notification saving';
      case ToastStyle.Info:
        return 'notification info';
      case ToastStyle.Success:
        return 'notification saved';
      case ToastStyle.Error:
        return 'notification error';
      default:
        return 'notification';
    }
  }

  render() {
    const { toast: { id, text } } = this.props;
    const className = this.getClassName();

    return (
      <div id={id} className="notificationWrapper">
        <span className={className}>{text}</span>
      </div>
    );
  }
}

Toast.propTypes = {
  toast: PropTypes.shape({
    id: PropTypes.string.isRequired,
    style: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired
  })
};

export default Toast;
