import compileReducers from 'services/compile-reducer';
import * as actionNames from './action-names';

export const initialState = {
  isOpen: false
};

const reducers = {};

reducers[actionNames.DRAWER_MENU_OPEN] = (state) => {
  return {
    ...state,
    isOpen: true
  };
};

reducers[actionNames.DRAWER_MENU_CLOSE] = (state) => {
  return {
    ...state,
    isOpen: false
  };
};

export default compileReducers(reducers, initialState);
