import React from 'react';
import local from 'services/localization/local';
import { ObservationType, Unit } from 'services/enums';
import { routePaths } from 'services/routing';
import { configIds } from 'health/observations/observation-config-ids';
import { baseObservationConfig } from 'health/observations/observation-config-helpers.jsx';
import { deleteObservation, loadObservationEhrData } from 'health/action-creators';
import { GenericObservationDeviceLinkNoData } from 'health/observations/generics';
import { roundToDecimals } from 'services/value-formatter';
import { getBloodPressureData } from './blood-pressure-selectors';
import BloodPressureVisualization from './visualization/BloodPressureVisualization';
import LogBloodPressure from './log/LogBloodPressure';
import { BloodPressure } from 'shared/fullColorIcons';

const typeDefinition = {
  type: [ObservationType.Systolic, ObservationType.Diastolic],
  typeUnitMap: {
    [ObservationType.Diastolic]: Unit.MillimetersOfMercury,
    [ObservationType.Systolic]: Unit.MillimetersOfMercury
  },
  unitLabelMap: {
    [Unit.MillimetersOfMercury]: local.units.millimetersOfMercury
  }
};

export default {
  ...baseObservationConfig,
  ...typeDefinition,
  id: configIds.bloodPressure,
  backPath: { to: routePaths.health },
  centerElement: local.observation.bloodPressure.title,
  noDataText: local.observation.bloodPressure.noDataText,
  iconSymbol: BloodPressure,
  loadData: (props) => [props.config.type].flat().forEach(x => props.dispatch(loadObservationEhrData({ observationType: x, profileId: props.profileId }))),
  dataSelector: getBloodPressureData,
  valueFormatterCustom: (props) => `${roundToDecimals(props.observation.value, 2)} ${props.config.unitLabelMap[props.observation.unit]}`,
  valueFormatter: (props) => `${roundToDecimals(props.observation.value, 2)}/${roundToDecimals(props.observation.valueDiastolic, 2)} ${props.config.unitLabelMap[props.observation.unit]}`,
  renderNoDataComponent: (props) => props && <GenericObservationDeviceLinkNoData message={props.config.noDataText} />,
  // eslint-disable-next-line react/no-multi-comp
  renderChart: (props) => props.observations.length > 1 && <BloodPressureVisualization />,
  deleteData: (props) => {
    props.dispatch(deleteObservation({ observationType: props.observation.observationType, observationId: props.observation.id, profileId: props.observation.profileId }));
    props.dispatch(deleteObservation({ observationType: ObservationType.Diastolic, observationId: props.observation.idDiastolic, profileId: props.observation.profileId }));
  },
  // eslint-disable-next-line react/no-multi-comp
  renderLogComponent: (props) => props && <LogBloodPressure {...props} />
};
