import local from 'services/localization/local';

export const getProfileId = (state) => state.selectedProfile.id;

export const getIsApplicationLoading = state => {
  const { identity, profile } = state;
  const isIdentityLoading = identity.loading && !identity.self;

  return !!(isIdentityLoading || profile.loadingList || profile.loading);
};

export const getIsApplicationLoadError = state => {
  const { identity, profile } = state;

  return !!(identity.loadError || profile.loadError || profile.listError);
};

export const validBlueRingPreferences= (blueRingPreference)=>{
  if(blueRingPreference == null || blueRingPreference == '')
    return false;

  var values = blueRingPreference.split(',');
  if(values.length!=5)
    return false;

  for (var i = 0; i < values.length; i++) {
    if (values[i] == ''){
      return false;
    }
  }
  return true;
};

export const getProfileBlueRingPreferences = (state) => {
  const blueRingPreference = state.profile?.profilePreferences?.dashboardPreference?.blueRingPreference ?? '';
  if(validBlueRingPreferences(blueRingPreference))
    return blueRingPreference;
  else
    return '';
};

export const getHealthScore = state => state.health.healthScore;

export const getProfilename = (state) => {
  const content = local.fullScreenModel;
  const profileId = state.selectedProfile.id;
  const selectedProfile = state.profile.list.find(x => x.id === profileId);
  const Name= selectedProfile.name;
  const emailRegex = /.+@.+/;
  if  (Name.match(emailRegex))
    return content.hello;
  else
    return `${content.hi} `+Name;
};

export const HasProfileDemographicName = (state) => {
  const firstName = state.profile?.self?.firstName;
  const lastName = state.profile?.self?.lastName;

  if  ((firstName != null && lastName != null) && (firstName!= undefined && lastName != undefined))
    return true;
  else
    return false;
};

export const getIsForceRegistration =(state) =>state.identity.self.isForceRegistration;

