import moment from 'moment';
import { ObservationType } from 'services/enums';
import { compareDatesDescending } from 'services/date-service';
import { TimelineType, getConfigs } from './timeline-helpers';
import { isActivitiesLoading, isActivitiesLoadError, getActivities } from 'activity/activity-selectors';
import { isJournalEntriesLoading, isJournalEntriesLoadError, getJournalEntries } from 'journal/journal-selectors';
import { getObservationsLoading, getObservationsLoadError } from 'health/observations/observation-selectors';
import { getMedicationDoses } from 'health/medication/dose/selectors';
import { ACTIVITY_KEY, DOSES_KEY, JOURNAL_KEY } from './filter/filter-types';
import * as dataSelectors from 'app/request/persistent-data-selectors';
import * as actions from './action-names';

const observationTypeValues = Object.values(ObservationType).filter(x => x > 0);

export const isTimelineLoading = (state) => {
  const profileId = state.selectedProfile.id;

  const activitiesLoading = isActivitiesLoading(state, profileId);
  const medicationDoseLoading = state.medicationDose.loading;
  const observationsLoading = getObservationsLoading(state, observationTypeValues);
  const journalLoading = isJournalEntriesLoading(state, profileId);

  return activitiesLoading && medicationDoseLoading && observationsLoading && journalLoading;
};

export const isTimelineLoadError = (state) => {
  const profileId = state.selectedProfile.id;

  const activitiesError = isActivitiesLoadError(state, profileId);
  const medicationDoseError = state.medicationDose.loadError;
  const observationsError = getObservationsLoadError(state, observationTypeValues);
  const journalError = isJournalEntriesLoadError(state, profileId);

  return activitiesError && medicationDoseError && observationsError && journalError;
};

const getFilteredObservationTimelineData = (state, profileId) => {
  let configs = getConfigs();
  const filteredTypes = state.timeline.filteredTypes;
  if (filteredTypes.length > 0) {
    configs = configs.filter((x) => {
      return filteredTypes.includes(x.id);
    });
  }
  const result = configs.filter(x=>x.dataSelector != undefined);
  const data = result.reduce((accum, config) => {
    const observationData = config
      .dataSelector(state, { config, profileId })
      .map(observation => ({
        timestamp: observation.recordedAt,
        type: TimelineType.observation,
        data: {
          ...observation
        }
      }));
    accum.push(observationData);
    return accum;
  }, []).flat();

  return data;
};

const getActivityTimelineData = (state, profileId) => {
  return getActivities(state, profileId)
    .map(activity => ({
      timestamp: activity.startDateTime,
      type: TimelineType.activity,
      data: {
        ...activity
      }
    }));
};

const getFilteredActivityTimelineData = (state, profileId) => {
  const filteredTypes = state.timeline.filteredTypes;
  return filteredTypes.length === 0 || filteredTypes.includes(ACTIVITY_KEY)
    ? getActivityTimelineData(state, profileId)
    : [];
};

const getDoseTimelineData = (state, profileId) => {
  return getMedicationDoses(state, profileId)
    .map(dose => ({
      timestamp: dose.recordedAt,
      type: TimelineType.dose,
      data: {
        ...dose
      }
    }));
};

const getFilteredDoseTimelineData = (state, profileId) => {
  const filteredTypes = state.timeline.filteredTypes;
  return filteredTypes.length === 0 || filteredTypes.includes(DOSES_KEY)
    ? getDoseTimelineData(state, profileId)
    : [];
};

const getJournalTimelineData = (state, profileId) => {
  return getJournalEntries(state, profileId)
    .map(journalEntry => ({
      timestamp: journalEntry.recordedAt,
      type: TimelineType.journalEntry,
      data: {
        ...journalEntry
      }
    }));
};

const getFilteredJournalTimelineData = (state, profileId) => {
  const filteredTypes = state.timeline.filteredTypes;
  return filteredTypes.length === 0 || filteredTypes.includes(JOURNAL_KEY)
    ? getJournalTimelineData(state, profileId)
    : [];
};

export const getTimelineData = (state, dateRangeStart) => {
  const profileId = state.selectedProfile.id;
  const activities = getFilteredActivityTimelineData(state, profileId);
  const doses = getFilteredDoseTimelineData(state, profileId);
  const observations = getFilteredObservationTimelineData(state, profileId);
  let journalEntries = getFilteredJournalTimelineData(state, profileId);

  const allData = [...activities, ...doses, ...observations, ...journalEntries]
    .filter(a => moment(a.timestamp) >= dateRangeStart)
    .sort((a, b) => compareDatesDescending(a.timestamp, b.timestamp));

  return allData;
};

export const getHealthSummaryDetails = (state) => {

  const identityId = state.identity && state.identity.self.id;
  const healthSummary = dataSelectors.getType(state.healthSummary, actions.HEALTH_SUMMARY, identityId);

  return {
    loaded: healthSummary.loaded,
    loading: healthSummary.loading,
    hasError: healthSummary.hasError,
    data: healthSummary.data
  };
};

export const getFilter = (state) => state.timeline.filteredTypes;
