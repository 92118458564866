import React, { useEffect, useState } from 'react';
import { routePaths } from 'services/routing';
import { Button, Card, Icon, ModalWrapper, Page } from 'shared';
import { closeRouteInApp, isMobile, openMobileRoute } from 'mobile/helpers';
import { mobileAppRoot } from 'app/config';
import { Application, KeyType, PaymentStatus, RecursiveInterval } from 'services/enums';
import { useSelector, useDispatch } from 'react-redux';
import { initiatePayment, createInvite } from './products-actions';
import './coach.scss';
import { Close, OpenApp, Tid, USD, CoachName } from 'services/constants';
import withNavigation from 'app/withNavigation';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router';
import { getRequestProcessing, getRequestSuccess } from 'app/request/request-selectors';
import { types } from './products-types';
import local from 'services/localization/local';
import { getSelfProfileId } from 'profiles/list/selectors';

function Coach({ navigate }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const selfProfileId = useSelector(getSelfProfileId);
  const inviteSuccess = useSelector(state => getRequestSuccess(state, types.SAVE_PROGRAM_INVITE));
  const inviteProcessing = useSelector(state => getRequestProcessing(state, types.SAVE_PROGRAM_INVITE));
  const [showModal, setShowModal] = useState(true);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isDisableSubmit, setIsDisableSubmit] = useState(false);
  const [annually, quarterly, coachDescription] = ['ANNUALLY', 'QUARTERLY', 'Calcium Health Coach pathway'];
  const content = local.coach;
  const [plans, setPlans] = useState(
    [
      {
        name: annually,
        months: 12,
        originalPrice: 600,
        offerPrice: 120,
        intervalCount: 12,
        isChecked: true
      },
      {
        name: quarterly,
        months: 3,
        originalPrice: 150,
        offerPrice: 45,
        intervalCount: 3,
        isChecked: false
      }
    ]
  );

  useEffect(() => {
    const isCloseParamReceived = new URLSearchParams(location.search).get(Close);
    let transactionId = new URLSearchParams(location.search).get(Tid);

    if (transactionId && transactionId.includes('"')) {
      transactionId = transactionId.split('"')[1];
    }

    if (!transactionId && location.hash && location.hash.includes(Tid) && location.hash.includes('=')) {
      transactionId = new URLSearchParams(location.hash.substring(1)).get(Tid);
    }

    if (transactionId !== null && transactionId !== undefined && transactionId.length > 0) {
      setShowModal(false);
      inviteMember(transactionId);
      setIsPaymentSuccess(true);
    }

    if (isCloseParamReceived !== null && isCloseParamReceived !== undefined) {
      closeRouteInApp();
      openMobileRoute(OpenApp);
    }
  }, []);

  useEffect(() => {
    if(isPaymentSuccess && !inviteProcessing && !inviteSuccess)
      setShowModal(true);
  }, [isPaymentSuccess, inviteSuccess, inviteProcessing]);

  function doPayment() {
    setIsDisableSubmit(true);
    const selectedPlan = plans.find(plan => plan.isChecked);
    if (selectedPlan) {
      const payment = {
        amount: selectedPlan.offerPrice,
        itemName: CoachName,
        currency: USD,
        quantity: 1,
        redirectUri: isMobile ? `${mobileAppRoot}paymentsuccess` : `https://${window.location.host}${routePaths.coach}`,
        description: coachDescription,
        application: Application.MemberApp,
        paymentStatus: PaymentStatus.RequestedToPaymentGateway,
        keyType: KeyType.ProfileId,
        key: selfProfileId,
        isRecursive: true,
        recursiveInterval: RecursiveInterval.Month,
        intervalCount: selectedPlan.intervalCount
      };
      dispatch(initiatePayment(payment));
    }
  }

  function inviteMember(transactionId) {
    dispatch(createInvite(transactionId));
  }

  function onChange(selectedPlan) {
    if (!selectedPlan.isChecked)
      setPlans(plans.map(plan => ({ ...plan, isChecked: plan.name === selectedPlan.name })));
  }

  function toggleModal() {
    setShowModal(false);
    navigate({ to: location.state?.from ?? routePaths.root });
  }

  const getDiscountPercentage = (originalPrice, offerPrice) => Math.round(100 * (originalPrice - offerPrice) / originalPrice);

  if (isPaymentSuccess && inviteSuccess)
    return <Redirect to={routePaths.root} />;

  function renderPlans() {
    return plans.map(plan => (
      <Card key={plan.name} className={plan.isChecked ? 'active' : ''}>
        {/* eslint-disable-next-line react/jsx-no-bind*/}
        <div className='subscribe-card' onClick={() => onChange(plan)}>
          <div>
            <div className='subscribe-amount'>
              <div className='subscribe-amount-old'>
                <del>${plan.originalPrice}</del>
              </div>
              <div className='subscribe-amount-new'>
                <span className='subscribe-amount-newcount'>  ${plan.offerPrice}</span> {content.for} {plan.months} {content.months}
              </div>
            </div>
            <span className='subscribe-discount'>
              {content.save} {getDiscountPercentage(plan.originalPrice, plan.offerPrice)}%
            </span>
          </div>
          <div className="select-icons">
            {plan.isChecked ? <Icon symbol="selectedCircle" fill="blue" /> : <Icon symbol="deselectedCircle" />}
          </div>
        </div>
      </Card>));
  }

  return (
    <Page
      id='calcium-ai-coach-page'
      backPath={{ to: location.state?.from ?? routePaths.root }}
      centerElement={CoachName}
      loading>
      <ModalWrapper isOpen={showModal}>
        <div id="coach-component" data-testid='product-coach-component' className="modal-container">
          <div className="modal-header">
            <div></div>
            <Icon className="center" symbol="coach" />
            <Button className="left" id="modal-cancel-button" onClick={toggleModal}>
              <Icon symbol="times" />
            </Button>
          </div>
          <div className="modal-body">
            <div className='subscribe-detail'>
              <div className='subscribe-header-img'>
                <img src="../static/images/CalciumCoach.png" />
                {/* eslint-disable-next-line react/jsx-max-depth */}
                <h3 className='subscribe-detail-title'>Calcium AI <br /> Coach</h3>
              </div>
              <h6>{content.benefits}:</h6>
              <ul>
                <li>{content.assessment}</li>
                <li>{content.personalized}</li>
                <li>{content.app}</li>
                <li>{content.hipaa}</li>
              </ul>
              <div className='subscribe-ul-subText'>{content.join}</div>
            </div>
            <div className='subscribe'>
              <div className='subscribe-plan-title'>{content.selectPlan}</div>
              {renderPlans()}
            </div>
            <div className='form-row claim-offer-btn'>
              <Button id='subscribe-button' disabled={isDisableSubmit} className="button blue btn btn-primary" onClick={doPayment}>{content.start}</Button>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </Page>
  );
}

Coach.propTypes = {
  navigate: PropTypes.func.isRequired
};

export default withNavigation(Coach);
