export const types = {
  INFO: 'programInfo',
  PROGRAM: 'program',
  ENROLLMENTS: 'enrollments',
  DETAILS: 'programDetails',
  PERMISSIONS: 'permissions',
  ENROLL: 'enroll',
  COMPLETE: 'complete',
  COMPLETETASK: 'completeTask',
  REFERRALS: 'referrals',
  ORGANIZATION_PROGRAMS: 'organizationPrograms',
  SEVERITY: 'programEnrollmentSeverity',
  ACTIVE_USERS_PROGRAM_INVITES : 'activeUsersProgramInvites',
  PENDING_PROGRAM_INVITES_SHOWN_COUNT : 'pendingProgramInvitesShownCount',
  SUGGESTED_PATHWAY:'suggestedPrograms',
  RESOURCE_CONTENT_RATING: 'resourceContentRating',
  LEADER_BOARD_SUMMARY: 'leaderBoardSummary',
  HAS_ENROLLED_IN_ACTIVE_EVENT_PROGRAM: 'hasEnrolledInActiveEventProgram',
  GET_UPGRADED_OBSERVATIONS : 'getUpgradedObservations',
  PENDING_INVITED_PROGRAMS: 'pendingInvitedPrograms',
  DASHBOARD_PROGRAMS:'dashboardPrograms',
  COMPLETE_AND_REENROLLMENT: 'completeAndReEnrollments',
  HEALTH_OBSERVATION_DATA:'healthObservationData',
  HEALTH_VITALS_OBSERVATION_DATA:'healthVitalsObservationData',
  HIGH_FREQUENCY_OBSERVATIONS: 'highFrequencyObservations',
  PROGRAM_INFOS: 'programInfos'
};
