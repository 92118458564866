/* eslint-disable react/jsx-child-element-spacing */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import { NavIcon, Icon, LinkWrapper, Loading} from 'shared';
import local from 'services/localization/local';
import { getObservationIcon } from 'devices/device-helper';
import { CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { loadGoalSummary } from './goal-actions';
import { getProfileId } from 'app/selectors';
import { getGoalSummary, getGoalSummaryProps } from './goal-selectors';
import { buildPath, routePaths } from 'services/routing';

export default function GoalCard({profileGoal, goalCategory}) {
  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const goalCategoryId = profileGoal?.goalCategoryId;
  const [showResults, setShowResults] = useState(false);
  const {data, loading} = useSelector(state => getGoalSummary(state, profileGoal?.goalCategoryId));
  const {goalSummaryModel} = getGoalSummaryProps(profileGoal, goalCategory);

  useEffect(() => {
    dispatch(loadGoalSummary(profileId, goalSummaryModel, goalCategoryId));
  }, [profileId, goalCategoryId]);

  const handleClick = useCallback(() => {
    setShowResults(!showResults);
  }, [showResults]);


  const renderLogo = (primaryObservationType) => {
    return (
      <NavIcon Icon={getObservationIcon(primaryObservationType)}></NavIcon>
    );
  };

  const renderProgressCircle = () => {
    if(loading){
      return <div className='goal-progress ml-2'><Loading></Loading></div>;
    }
    return(
      <div className='goal-progress ml-2'>
        <CircularProgressbar value={data?.value ? data?.value : 0} text={data?.value ? `${data?.value}%` : '0%'} styles={buildStyles({strokeLinecap:'butt', textSize: '30px'})} />
        <div className='progress-status'>{data?.description}</div>
      </div>
    );
  };

  const renderGoals = () =>{
    const primaryObservationType = goalCategory?.goalPrograms?.find(z => z.isPrimaryProgram && z.primaryObservationType !== 0)?.primaryObservationType;
    return(
      <li key={profileGoal.id} className="card-list-item">
        <div className="myGoal-info">
          <div className='myGoal-info-wrapper'>
            <div>
              <div className='myGoal-cardHeader'>
                <figure className="myGoal-image">
                  {renderLogo(primaryObservationType)}
                </figure>
                <span className="myGoal-title">
                  {profileGoal.name}
                </span>
              </div>
              {profileGoal.description ?
                <div className="myGoal-cardBody">
                  {local.goals.Goal} {profileGoal.description}
                </div> : null}
            </div>
            {renderProgressCircle()}
          </div>
        </div>
        <div className='goal-pathway-label' onClick={handleClick}>
          <div className='collapse-expand'>
            <div className='item-action'>
              <a className={'text-color d-flex align-items-center' + ' ' + (showResults ? 'collapse-icon' : 'expand-pathway-icon')}>
                <Icon className="task-section-open-close-indicator" symbol="arrowdown" />
                <span>{local.goals.pathways}</span>
              </a>
            </div>
          </div>
        </div>
        {showResults ?
          <div className="myGoal-info pathway-list">
            {data?.programGoalSummaries?.sort((a, b) => b.isPrimaryProgram - a.isPrimaryProgram || a.programName.localeCompare(b.programName))
              .map((x) => {
                return (
                  <LinkWrapper id={`program-details-${x.programId}`} key={x.programId} backpath={{isFromCreateGoal: true}} to={buildPath(routePaths.programsDetails, { programId:x.programId, programEnrollmentId: x.programEnrollmentId })}>
                    <li key={x.programId}>
                      <div className='myGoal-cardHeader'>
                        <figure className="myGoal-image">
                          <img className="icon-image" src={x.programIconPath || 'https://static.myhc.app/programs/hc_thumb.png'}></img>
                        </figure>
                        <span className="myGoal-title">
                          {x.programName}
                          <div className="myGoal-cardBody">
                            {x.goalSummary}
                          </div>
                        </span>
                      </div>
                    </li>
                  </LinkWrapper>

                );
              })}
          </div> : null}
      </li>
    );
  };

  return(
    <ul className='card-list'>
      {renderGoals()}
    </ul>
  );
}
