import React from 'react';
import PropTypes from 'prop-types';

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.getClassName = this.getClassName.bind(this);
  }

  getClassName() {
    const { rightArrow, className, disabled } = this.props;
    let finalClassName = className || '';

    if (disabled) {
      finalClassName = `${finalClassName} disabled`;
    }

    if (rightArrow) {
      return `right-arrow-button ${finalClassName}`;
    }
    return `left-arrow-button ${finalClassName}`;
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { id, rightArrow, ...rest } = this.props;
    const className = this.getClassName();

    return (
      <button
        {...rest}
        id={id}
        type="button"
        className={className}>
      </button>
    );
  }
}

Button.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  submit: PropTypes.bool,
  rightArrow: PropTypes.bool,
  disabled: PropTypes.bool
};

export default Button;
